const ELIMINATION_OVERVIEW_REQUEST = "ELIMINATION_OVERVIEW_REQUEST";
const ELIMINATION_OVERVIEW_SUCCESS = "ELIMINATION_OVERVIEW_SUCCESS";
const ELIMINATION_OVERVIEW_FAIL = "ELIMINATION_OVERVIEW_FAIL";

const GET_ELIMINATION_LEADERS_REQUEST = "GET_ELIMINATION_LEADERS_REQUEST";
const GET_ELIMINATION_LEADERS_SUCCESS = "GET_ELIMINATION_LEADERS_SUCCESS";
const GET_ELIMINATION_LEADERS_FAIL = "GET_ELIMINATION_LEADERS_FAIL";

const GET_ELIMINATION_TEAMS_REQUEST = "GET_ELIMINATION_TEAMS_REQUEST";
const GET_ELIMINATION_TEAMS_SUCCESS = "GET_ELIMINATION_TEAMS_SUCCESS";
const GET_ELIMINATION_TEAMS_FAIL = "GET_ELIMINATION_TEAMS_FAIL";

const GET_ELIMINATION_POWER_RANKINGS_REQUEST = "GET_ELIMINATION_POWER_RANKINGS_REQUEST";
const GET_ELIMINATION_POWER_RANKINGS_SUCCESS = "GET_ELIMINATION_POWER_RANKINGS_SUCCESS";
const GET_ELIMINATION_POWER_RANKINGS_FAIL = "GET_ELIMINATION_POWER_RANKINGS_FAIL";

const GET_ELIMINATION_RESULTS_REQUEST = "GET_ELIMINATION_RESULTS_REQUEST";
const GET_ELIMINATION_RESULTS_SUCCESS = "GET_ELIMINATION_RESULTS_SUCCESS";
const GET_ELIMINATION_RESULTS_FAIL = "GET_ELIMINATION_RESULTS_FAIL";

export default {
  ELIMINATION_OVERVIEW_REQUEST,
  ELIMINATION_OVERVIEW_SUCCESS,
  ELIMINATION_OVERVIEW_FAIL,
  GET_ELIMINATION_LEADERS_REQUEST,
  GET_ELIMINATION_LEADERS_SUCCESS,
  GET_ELIMINATION_LEADERS_FAIL,
  GET_ELIMINATION_TEAMS_REQUEST,
  GET_ELIMINATION_TEAMS_SUCCESS,
  GET_ELIMINATION_TEAMS_FAIL,
  GET_ELIMINATION_POWER_RANKINGS_REQUEST,
  GET_ELIMINATION_POWER_RANKINGS_SUCCESS,
  GET_ELIMINATION_POWER_RANKINGS_FAIL,
  GET_ELIMINATION_RESULTS_REQUEST,
  GET_ELIMINATION_RESULTS_SUCCESS,
  GET_ELIMINATION_RESULTS_FAIL
}