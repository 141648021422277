import { useCallback, useEffect } from "react";

export const useInfiniteScroll = (
  ref: React.RefObject<HTMLDivElement>,
  handleNextPage: () => void,
  loader: boolean,
  noNextPage: boolean
) => {

  const handleScroll = useCallback(() => {
    if (
      ref.current &&
      Math.ceil(ref.current.scrollTop + ref.current.clientHeight) >=
        ref.current.scrollHeight &&
      !noNextPage &&
      !loader
    ) {
      handleNextPage();
    }
  }, [handleNextPage, loader, noNextPage, ref]);

  useEffect(() => {
    const container = ref.current;
    
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container && typeof handleScroll === "function") {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [noNextPage, loader, ref, handleScroll]);
};
