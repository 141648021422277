/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./DepthChartTabStyle.scss";
import { Row, Col } from "react-bootstrap";
import Title from "../../../Common/Title/Title";
import PinkButton from "../../../Common/Button/PinkButton/PinkButton";
import BlueButton from "../../../Common/Button/BlueButton/BlueButton";
import WhiteButton from "../../../Common/Button/WhiteButton/WhiteButton";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Formations } from "../../../../types";
import { useState } from "react";
import { DepthChartViewFormationModal } from "./DepthChartViewFormationModal";

type Props = {
  DepthChartFormationList: Array<{ sid: number; name: string }>;
  handleClickFormationMenuItem: (item: any) => void;
  currentSelectedFormation: { sid: number; name: Formations };
  handleClickAutoSelect: any;
  handleApplyAll: any;
  handleResetAll: any;
};

const DepthChartFormation = ({
  DepthChartFormationList,
  handleClickFormationMenuItem,
  currentSelectedFormation,
  handleClickAutoSelect,
  handleApplyAll,
  handleResetAll,
}: Props) => {
  const [isFormationViewOpen, setIsFormationViewOpen] = useState(false);
  const isSpecialTeamSelected = currentSelectedFormation.name === 'SPECIAL TEAM';
  
  const menu = (
    <Menu
      className="specialdrop"
      onClick={({ key }) =>
        handleClickFormationMenuItem(
          DepthChartFormationList?.find((scheme) => scheme.name === key)
        )
      }
    >
      {DepthChartFormationList?.map((data: { name: string; sid: number }) => {
        const isDisabled = data.sid < 0;
        return (
           <Menu.Item
              disabled={isDisabled}
              style={isDisabled ? { cursor: "default", color: "#D3D3D3" } : {}}
              key={data.name}
           >
              {data.name}
           </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <>
      <Title text="SELECT DEPTH CHART FORMATION" />
      <Row className="chartFormation">
        <Col xl={4} sm={12}>
          <div className="filterdropdown specialteams">
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {currentSelectedFormation.name} <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </Col>
        <Col xl={8} sm={12} className="d-flex">
          <BlueButton
            text="AUTO SELECT BEST PLAYER"
            onClick={handleClickAutoSelect}
            disabled={isSpecialTeamSelected}
          />
          <WhiteButton 
            text="VIEW FORMATION"
            onClick={() => setIsFormationViewOpen(true)}
            disabled={isSpecialTeamSelected}
          />
          <BlueButton
            text="SAVE"
            disabled={!handleApplyAll}
            onClick={handleApplyAll}
          />
          <PinkButton
            text="RESET ALL"
            disabled={!handleResetAll}
            onClick={handleResetAll}
          />
        </Col>
      </Row>
      <DepthChartViewFormationModal
        formationName={currentSelectedFormation.name}
        visible={isFormationViewOpen}
        onCancel={() => setIsFormationViewOpen(false)}
      />
    </>
  );
};

export default DepthChartFormation;
