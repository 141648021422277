import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { mbToBytes } from "helper/helper";
import useDragAndDrop from "helper/useDragAndDrop";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { File as CustomFile } from "types/file";
import { GroupAvatar } from "../Common";

interface GroupAvatarUploadProps {
  handleUploadAvatar: (file: CustomFile) => void;
  file: CustomFile | string | null;
}
export function GroupAvatarUpload({
  handleUploadAvatar,
  file,
}: GroupAvatarUploadProps) {
  const [isDragActive, setIsDragActive] = useState(false);

  const { getInputProps, getRootProps, open } = useDragAndDrop({
    maxSize: mbToBytes(10),
    maxFiles: 1,
    multiple: false,
    onImagesUpload: (file: CustomFile) => {
      setIsDragActive(false);
      handleUploadAvatar(file);
    },
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    noClick: true,
  });

  return (
    <div className="create-group__form__file-upload">
      <div className="section__header">
        <h5>add group badge</h5>
      </div>
      <div
        {...getRootProps()}
        className={`avatar-upload ${isDragActive && "avatar-upload--ondrag"}`}
      >
        <input {...getInputProps()} />
        <Row>
          <Col xs={12} md={4} xl={3}>
            <GroupAvatar file={file} />
          </Col>
          <Col xs={12} md={8} xl={9}>
            <div className="avatar-upload__text-area">
              <p>Drag an image here</p>
              <p>-or-</p>
              <PinkButton
                onClick={open}
                type="button"
                text="select an image from your computer"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
