import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPassedGroupGames } from "redux/actions/Groups";
import { RootState } from "redux/store";

export const useGroupPassedGames = (groupId: number, page: number, limit: number) => {
  const dispatch = useDispatch();
  const passedGroupGames = useSelector((state: RootState) => state.group.passedGroupGames);

  useEffect(() => {
    if (groupId) {
      dispatch(GetPassedGroupGames(groupId, page, limit));
    }
  }, [groupId, page, limit]);

  return passedGroupGames;
};
