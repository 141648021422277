import LeagueScoreTabByGid from "components/Common/LeagueScoreTab/LeagueScoreByGid"
import { useGameOfWeekID } from "./hooks"

export const LeaderboardGameOfTheWeek = () => {
  const { gameOfWeekID } = useGameOfWeekID();

  if (!gameOfWeekID) {
    return <div>Sorry, there are no data</div>
  }

  return (
    <LeagueScoreTabByGid gameId={gameOfWeekID}/>
  )
}