import { Row, Col } from 'react-bootstrap';
import BlueButton from '../Common/Button/BlueButton/BlueButton';
import { API_URL } from './../../config/index';
import moment from 'moment';
import { Modal } from 'antd';
import React, { useState } from 'react';
import UnderConstruction from './UnderConstruction';
import history from '../../routes/history';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { updateTeamStatus } from 'redux/actions/TeamAction';
import { TeamStatus, UserTeamsList } from 'types';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import BlackButton from 'components/Common/Button/BlackButton/BlackButton';
import { isTextContainsBadWord } from 'helper/helper';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import baseImage from '../../assets/images/homeScreen/franchiseBuild.gif';
import { PreviewModal } from './modal/TeamPreviewModal';
import { OwnFranchiseActivity } from './OwnFranchiseActivity';

type Props = UserTeamsList[0];

const OwnFranchiseList: React.FC<Props> = ({
  id,
  approval,
  bg,
  created,
  engId,
  helmet,
  img,
  logo,
  playerReveal,
  rejectMessage,
  status,
  teamName,
  updateStatus,
  activity,
  ovr,
  winRate,
}) => {
  const dispatch = useDispatch();
  const isTeamReducerLoading = useSelector((state: RootState) => state?.team.loader);

  const handleUpdateTeamStatus = (utid: number, status: TeamStatus) => {
    dispatch(updateTeamStatus(utid, status));
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalOpen = () => {
    setIsModalVisible(false);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handlePlayerReveal = () => {
    history.push(`/teamcreate/${id}`);
  };

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const handleClosePreview = () => {
    setIsPreviewModalOpen(false);
  };

  const teamImageURL = `${API_URL}/images/franchise/userFranchise/${img}`;

  return (
    <>
      <div className="leagueListMain franchiselist">
        <Row>
          <Col sm={12} xl={4} lg={4}>
            <div className="leagueDetailLeft">
              <div className="leagueImage">
                {img ? (
                  <div className="imgWithRatingContainer">
                    <img src={teamImageURL} alt="leagueImage" className="imgFluid" onClick={() => setIsPreviewModalOpen(true)} style={{ cursor: 'pointer' }} />
                  </div>
                ) : (
                  <img src={baseImage} alt="leagueImage" className="imgFluid" />
                )}
              </div>
              <div className="leagueDetail">
                <div className="franchiseName">
                  {teamName ? (
                    <>
                      <h5 style={{ cursor: 'pointer' }} onClick={() => history.push(`/franchiseoverview/${id}`)}>
                        {teamName}
                      </h5>
                      {!!status ? (
                        <HeartFilled className="default-team-heart red-heart" disabled />
                      ) : (
                        <HeartOutlined
                          className="default-team-heart"
                          onClick={() => handleUpdateTeamStatus(engId, TeamStatus.DEFAULT)}
                          disabled={Boolean(isTeamReducerLoading)}
                        />
                      )}
                    </>
                  ) : (
                    <h5>NOT COMPLETED</h5>
                  )}
                </div>
                <div>
                  <span>Established :</span>
                  <span>{moment(created).format('DD/MM/YYYY')}</span>
                </div>
                <div>
                  <span>Team rating :</span>
                  <span>{ovr || 0}</span>
                </div>
                <div>
                  <span>Win % :</span>
                  <span>{winRate || 0}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} xl={8} lg={8}>
            <Row>
              <Col xl={12} lg={12}>
                <div className="leaguedetail_head">
                  <h5>GAMES</h5>
                  {!approval && rejectMessage === null && <p className="statusApprovalNo">{img !== null && 'PENDING APPROVAL'}</p>}
                  {!approval && rejectMessage !== null && <p className="statusApprovalNo">Reject Message: {rejectMessage}</p>}
                </div>
              </Col>
              <Col sm={12} xl={9} lg={9}>
                {img ? (
                  <OwnFranchiseActivity activity={activity} engId={engId}/>
                ) : (
                  <div className="leagueDetailRight blue font14" style={{ textAlign: 'left' }}>
                    YOU MUST COMPLETE THE TEAM CUSTOMIZATION PROCESS BEFORE PROCEEDING TO GAME PLAY.
                  </div>
                )}
              </Col>
              <Col xl={3} lg={3}>
                <div className="detailbtn">
                  {!img ? (
                    <BlackButton text="CUSTOMIZE" onClick={() => history.push(`/customize-franchise/${id}`)} />
                  ) : (
                    !playerReveal && <BlueButton text="PLAYER REVEAL" onClick={handlePlayerReveal} disabled={!engId} />
                  )}

                  {!!img && <BlackButton text="TEAM INFO" onClick={() => history.push(`/franchiseoverview/${id}`)} />}

                  {teamName && !isTextContainsBadWord(teamName) && +updateStatus !== 1 && <PinkButton text="PLAY" onClick={() => history.push(`/league?team=${id}`)} />}

                  {!!updateStatus && <BlueButton text="UPDATE" onClick={() => history.push(`/customize-franchise/${id}?type=update`)} />}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <PreviewModal
          open={isPreviewModalOpen}
          onCancel={handleClosePreview}
          team={{
            helmet,
            image: teamImageURL,
            logo,
            name: teamName,
            bg,
          }}
        />
      </div>

      <Modal
        title="Basic Modal"
        className="trending_main under_construction"
        open={isModalVisible}
        onOk={handleModalOpen}
        onCancel={handleModalClose}
        footer={[<BlueButton onClick={handleModalClose} text="CLOSE WINDOW" />]}
        centered
      >
        <UnderConstruction />
      </Modal>
    </>
  );
};

export default OwnFranchiseList;
