import './DriveChartStyle.scss';
import { Accordion } from 'react-bootstrap';
import AccordianHeader from './Accordian/AccordianHeader/AccordianHeader';
import helmetsmall from '../../assets/images/helmet/helmetsmall.png';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { API_URL } from '../../config';
import FullGameSummary from './Accordian/FullGameSummary/FullGameSummary';
import { useDriveChartData } from './hooks/useDriveChartData';
import { useHelmets } from 'helper/useHelmets';

interface Props {
  lostTeam?: any;
  wonTeam?: any;
}

const DriveChartData = ({ lostTeam, wonTeam }: Props) => {
  const { gid }: any = useParams();
	const helmets = useHelmets([lostTeam?.utid, wonTeam?.utid].filter(Boolean))
	const driveData = useDriveChartData(gid);

	const leftHelmet = helmets?.find((h) => h?.engId === lostTeam?.utid);
	const rightHelmet = helmets?.find((h) => h?.engId === wonTeam?.utid);

  const data: any[] = useMemo(
    () =>
      driveData?.summary?.map(note => {
        return {
          id: 1,
          image: note.t === 0 ? (leftHelmet ? `${API_URL}${leftHelmet.helmet}` : helmetsmall) : rightHelmet ? `${API_URL}${rightHelmet.helmet}` : helmetsmall,
          logo: note.t === 0 ? (leftHelmet ? `${API_URL}${leftHelmet.logo}` : false) : rightHelmet ? `${API_URL}${rightHelmet.logo}` : false,
          header: note?.text,
          headerdigit: `Quarter: ${note?.quarter}. Time: ${note?.time}`,
        };
      }) ?? [],
    [driveData, lostTeam, wonTeam, leftHelmet, rightHelmet],
  );

  return (
    <div className="chartdatamain">
      <h5>DRIVES & PLAY-BY-PLAY</h5>
      <Accordion flush>
        {data.map((note, i) => (
          <Accordion.Item key={i} eventKey={`${i}`}>
            <Accordion.Header>
              <AccordianHeader data={note} />
            </Accordion.Header>
          </Accordion.Item>
        ))}
        <Accordion.Item className="summary" key={'playByPlay'} eventKey={'playByPlay'}>
          <Accordion.Header>
            <AccordianHeader
              data={{
                image: '',
                header: 'Full Game Summary',
                headerdigit: '',
                logo: '',
                team: '',
                teamdigit: '',
                team1: '',
                teamdigit1: '',
								clickable: true,
              }}
            />
          </Accordion.Header>
          <Accordion.Body>
            <FullGameSummary data={driveData?.playByPlay} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default DriveChartData;
