/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { saveDecreaseCount } from "../../helper/helper";
import * as types from "../actions/teamTypes";
import { Avatar, UserTeamsList } from "types";

export interface teamStateIF {
   loader: number;
   loaders: {
      avatarData: boolean;
      userTeamsList: boolean;
      getCityList: boolean;
   }
   loaderSaveAvatar: boolean;
   token: string | null;
   error: string | null;
   success: string | boolean | null;
   resData: any | null;
   avatar: Avatar | null;
   userTeamsList: UserTeamsList | null;
   fetchedAvatar: UserTeamsList[0] | null;
   cityInfo: any | null;
   partnerCode: any | [];
   frechiesSuccess: any | null;
   infoRes: any | null;
   cityList: any | null;
   cityDetails: any | null;
   createFrenchiseData: any | null;
   legendSuccess: any | null;
   verifySucess: any | null;
   previewNFT: any | null;
   leagueEnterFormData: any | null;
   fetchedAllAvatar: any | null;
   requestedUserTeams: UserTeamsList;
}

const initialState: teamStateIF = {
   loader: 0,
   loaders: {
      avatarData: false,
      userTeamsList: false,
      getCityList: false,
   },
   loaderSaveAvatar: false,
   token: null,
   error: null,
   success: null,
   resData: null,
   avatar: null,
   userTeamsList: null,
   fetchedAvatar: null,
   cityInfo: null,
   partnerCode: [],
   frechiesSuccess: null,
   infoRes: null,
   cityList: null,
   cityDetails: null,
   createFrenchiseData: null,
   legendSuccess: null,
   verifySucess: null,
   previewNFT: null,
   leagueEnterFormData: null,
   fetchedAllAvatar: null,
   requestedUserTeams: [],
};

const reducer = (state = initialState, action: any): teamStateIF => {
   switch (action.type) {
      case types.INIT_APP:
         return {
            ...state,
            ...action.payload,
            loaderSaveAvatar: false,
         };
      case types.GETAVATAR_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            loaders: {
               ...state.loaders,
               avatarData: true,
            },
            success: null,
            error: null,
         };
      case types.GETAVATAR_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            loaders: {
               ...state.loaders,
               avatarData: false,
            },
            avatar: action.payload.avatar,
         };
      case types.GETAVATAR_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            loaders: {
               ...state.loaders,
               avatarData: false,
            },
            error: action.error
         };
      case types.SAVEAVATARDATA_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            loaderSaveAvatar: true,
            success: null,
            error: null,
            frechiesSuccess: false,
         };
      case types.SAVEAVATARDATA_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            loaderSaveAvatar: false,
            success: true,
            resData: action.payload.resData,
            frechiesSuccess: true,
         };
      case types.SAVEAVATARDATA_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            loaderSaveAvatar: false,
            error: action.error,
            frechiesSuccess: false,
         };

      case types.UPDATEAVATARDATA_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            success: null,
            error: null,
            resData: null,
         };
      case types.UPDATEAVATARDATA_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            resData: action.payload.resData,
         };
      case types.UPDATEAVATARDATA_FAIL:
         return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };

      case types.USERTEAMSLIST_REQUEST:
         return { 
            ...state, 
            loaders: {
               ...state.loaders,
               userTeamsList: true,
            },
            success: null, 
            error: null 
         };
      case types.USERTEAMSLIST_SUCCESS:
         return {
            ...state,
            loaders: {
               ...state.loaders,
               userTeamsList: false,
            },
            success: true,
            userTeamsList: action.payload.userTeamsList,
         };
      case types.USERTEAMSLIST_FAIL:
         return { 
            ...state, 
            loaders: {
               ...state.loaders,
               userTeamsList: false,
            },
            error: 
            action.error
         };
      case types.ADD_USERTEAMLIST:
         return {
            ...state,
            userTeamsList: state.userTeamsList ? [...state.userTeamsList, action.payload] : [action.payload],
         };

      case types.FETCHSELECTEDAVATAR_REQUEST:
         return { ...state, fetchedAvatar: null, loader: state.loader + 1, success: null, error: null };
      case types.FETCHSELECTEDAVATAR_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            fetchedAvatar: action.payload.fetchedAvatar,
         };
      case types.FETCHSELECTEDAVATAR_FAIL:
         return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };
      case types.ADD_CITYINFO:
         return { ...state, cityInfo: action.payload.cityInfo };
      case types.CLEAN_CITYINFO:
         return { ...state, cityInfo: null };
      case types.GET_PARTNER_CODE_REQUEST:
         return { ...state, loader: state.loader + 1, success: null, error: null };
      case types.GET_PARTNER_CODE_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            partnerCode: action.payload.partnerCode,
         };
      case types.GET_PARTNER_CODE_FAIL:
         return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };

      case types.USERINFO_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            success: null,
            error: null,
            infoRes: null,
         };
      case types.USERINFO_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            infoRes: action.payload.infoRes,
         };
      case types.USERINFO_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
            infoRes: null,
         };

      case types.GETCITYLIST_REQUEST:
         return {
            ...state,
            loaders: {
               ...state.loaders,
               getCityList: true,
            },
            success: null,
            error: null,
            infoRes: null,
         };
      case types.GETCITYLIST_SUCCESS:
         return {
            ...state,
            loaders: {
               ...state.loaders,
               getCityList: false,
            },
            success: true,
            cityList: action.payload.cityList,
         };
      case types.GETCITYLIST_FAIL:
         return {
            ...state,
            loaders: {
               ...state.loaders,
               getCityList: false,
            },
            error: action.error,
            infoRes: null,
            cityList: null,
         };

      case types.GETCITYDETAIL_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            success: null,
            error: null,
            infoRes: null,
         };
      case types.GETCITYDETAIL_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            cityDetails: action.payload.cityDetails,
         };
      case types.GETCITYDETAIL_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
            infoRes: null,
            cityDetails: null,
         };

      case types.GETFRENCHISEDATA_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            success: null,
            error: null,
            infoRes: null,
            createFrenchiseData: null,
         };
      case types.GETFRENCHISEDATA_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            createFrenchiseData: action.payload.createFrenchiseData,
         };
      case types.GETFRENCHISEDATA_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
            infoRes: null,
            cityDetails: null,
         };
      case types.GETFRENCHISEDATA_CLEAR:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: null,
            infoRes: null,
            cityDetails: null,
            createFrenchiseData: null,
         };
      case types.LEGENDMAIL_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            success: null,
            error: null,
            legendSuccess: null,
         };
      case types.LEGENDMAIL_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            legendSuccess: action.payload.legendSuccess,
         };
      case types.LEGENDMAIL_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
            legendSuccess: null,
            cityDetails: null,
         };

      case types.EMAILVEIFICATION_REQUEST:
         return {
            ...state,
            loader: state.loader + 1,
            success: null,
            error: null,
            verifySucess: null,
         };
      case types.EMAILVEIFICATION_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            verifySucess: action.payload.verifySucess,
         };
      case types.EMAILVEIFICATION_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
            legendSuccess: null,
            verifySucess: null,
         };

      case types.RESPONCERESET_REQUEST:
         return {
            ...state,
            success: null,
            error: null,
            frechiesSuccess: false,
         };
      case types.RESPONCERESET_SUCCESS:
         return {
            ...state,
            success: true,
            resData: action.payload.resData,
            frechiesSuccess: false,
         };
      case types.RESPONCERESET_FAIL:
         return {
            ...state,
            error: action.error,
            frechiesSuccess: false,
         };

      case types.PLAYERREVEALAPPROVAL_REQUEST:
         return { ...state, loader: state.loader + 1, success: null, error: null };
      case types.PLAYERREVEALAPPROVAL_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: action.success,
         };
      case types.PLAYERREVEALAPPROVAL_FAIL:
         return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };

      case types.GETPREVIEWNFT_REQUEST:
         return { ...state, loader: state.loader + 1, success: null, error: null };
      case types.GETPREVIEWNFT_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            previewNFT: action.payload.previewNFT,
         };
      case types.GETPREVIEWNFT_FAIL:
         return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };

      case types.ADD_LEAGUE_ENTER_FORM_DATA:
         return {
            ...state,
            leagueEnterFormData: action.payload.leagueEnterFormData,
         };
      case types.FETCHALLSELECTEDAVATAR_REQUEST:
         return { ...state, loader: state.loader + 1, success: null, error: null };
      case types.FETCHALLSELECTEDAVATAR_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            fetchedAllAvatar: action.payload.fetchedAllAvatar,
         };
      case types.FETCHALLSELECTEDAVATAR_FAIL:
         return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };

      case types.UPDATE_TEAM_STATUS_REQUEST:
         return { ...state, loader: state.loader + 1, success: null, error: null };

      case types.UPDATE_TEAM_STATUS_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            userTeamsList: (state.userTeamsList || []).map(team => {
               if (team.engId !== action.payload.utid) return { ...team, status: 0 };
               return { ...team, status: action.payload.status };
            }),
         };
      case types.UPDATE_TEAM_STATUS_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
         };
      case types.GET_USER_TEAMS_REQUEST:
         return { ...state, requestedUserTeams: [], loader: state.loader + 1, success: null, error: null };

      case types.GET_USER_TEAMS_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            requestedUserTeams: action.payload.requestedUserTeams,
         };
      case types.GET_USER_TEAMS_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
         };

      case types.GET_TEAM_NFTS_REQUEST:
         return { ...state, requestedUserTeams: [], loader: state.loader + 1, success: null, error: null };
      case types.GET_TEAM_NFTS_SUCCESS:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            success: true,
            requestedUserTeams: action.payload

            };
      case types.GET_TEAM_NFTS_FAIL:
         return {
            ...state,
            loader: saveDecreaseCount(state.loader),
            error: action.error,
         };
      default:
         return state;
   }
};

export default reducer;
