import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Input from "../Common/Form/Input/Input";
import "./NewPasswordStyle.scss";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import Checkbox from "../Common/Form/Checkbox/Checkbox";

const NewPasswordForm = () => {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [reenteredPassword, setReenteredPassword] = useState<string>("");
  const [msg, setMsg] = useState<string>("");

  useEffect(() => {
    if (newPassword && reenteredPassword && newPassword !== reenteredPassword) {
      setMsg("Passwords do not match");
    } else {
      setMsg("");
    }
  }, [newPassword, reenteredPassword]);

  const handleSubmit = async () => {
    if (!password || !newPassword || !reenteredPassword
    ) {
      setMsg("All Fields Are Required");
      return;
    }

    if (newPassword !== reenteredPassword) {
      setMsg("Passwords do not match");
      return;
    }

    const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegEx.test(newPassword)) {
      setMsg("Password must be 8 characters, use upper/lower and include a special character or number");
      return;
    }

    setMsg("");
  };

  return (
    <>
      <div className="signUpBackground">
        <div className="signUpMain">
          <div className="signupHead">
            <h4>RESET YOUR PASSWORD</h4>
            <p>
              Password must be 8 characters, use upper/lower and include a
              special character or number.
            </p>
          </div>
          <div className="signupForm">
            <Row>
              <Col xl={12} className="mb-3">
                <Input
                  type="password"
                  label="Password"
                  value={password}
                  onChange={setPassword}
                  placeholder="Enter Password"
                />
              </Col>
              <Col xl={12} className="mb-3">
                <Input
                  type="password"
                  label="New Password"
                  value={newPassword}
                  onChange={setNewPassword}
                  placeholder="Enter Password"
                />
              </Col>
              <Col xl={12} className="mb-3">
                <Input
                  type="password"
                  label="Re-enter Password"
                  value={reenteredPassword}
                  onChange={setReenteredPassword}
                  placeholder="Enter Password"
                />
              </Col>

              <p className="error">{msg}</p>
              

              <Col xl={12} className="text-center mt-2">
                <div className="changepassword">
                  <BlueButton onClick={handleSubmit} text="CHANGE PASSWORD" />
                </div>
              </Col>

              <Col xl={6}>
                <Checkbox label="Remember Me" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPasswordForm;
