import ResetPasswordMain from "./../../components/Reset Password/ResetPasswordMain";

const ResetPasswordScreen = (props: any) => {
  const token: any = props.match.params.token;
  return (
    <>
      <ResetPasswordMain token={token} />
    </>
  );
};

export default ResetPasswordScreen;
