import { GroupListItem as GroupListItemI } from "types/groups";
import "./GroupCardItem.scss";
import { GroupAvatar } from "../Common";
import { useHistory } from "react-router-dom";
interface GroupCardItemProps {
  data: GroupListItemI;
}
export function GroupListItem({ data }: GroupCardItemProps) {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(`/group/${data.id}`);
  };

  return (
    <button className="group-item" onClick={handleRedirect}>
      <GroupAvatar file={data.avatar} />
      <p className="group-name">{data.name}</p>
    </button>
  );
}
