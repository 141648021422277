import HomeContainer from "./HomeContainer";
import AnimatedTitle from "../Common/AnimatedTitle/AnimatedTitle";
import { HomeNewsItem } from "./HomeNewsItem";
import history from "routes/history";
import { useNews } from "components/NewsTemplate/hooks/useNews";

const HomeNews: React.FC = () => {
  const news = useNews();

  if (!news) return null;

  return (
    <HomeContainer>
      <div className="news-wrapper">
        <span
          className="c-pointer"
          onClick={() => history.push("/news")}
        >
          <AnimatedTitle
            className="news-title"
            text={"NEWS EVENTS RESULTS"}
            withMiddleDots={true}
          />
        </span>
        <div className="news-card-wrapper">
          {news.data?.map((item) => {
            const mmddyyyy = new Date(item.date).toLocaleDateString();

            return (
              <HomeNewsItem
                key={item.id}
                id={item.id}
                image={item.image}
                title={item.title}
                subtitle={`${mmddyyyy} - ${item.subtitle}`}
              />
            )
          })}
        </div>
      </div>
    </HomeContainer>
  );
}

export default HomeNews;
