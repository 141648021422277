import { Col, Row } from "react-bootstrap"
import history from "routes/history";
import { EliminationDetails } from "types";

type Props = {
  round: EliminationDetails;
  franchiseId: number;
  engId?: number;
}

export const EliminationRoundsDetails: React.FC<Props> = ({
  round,
  franchiseId,
  engId,
}) => {
  const opponentUtid = round.teams.find(teamUtid => teamUtid !== engId);
  const opponentRecord = round.records[0].utid === opponentUtid ? round.records[0] : round.records[1];
  const ourRecord = round.records[0].utid === opponentUtid ? round.records[1] : round.records[0];
  const wonOrLost = round.winner !== opponentUtid ? 'W' : 'L';

  const handleEliminationRoundClick = (gid: number) => {
    history.push(`/stand`, { gid, id: +franchiseId });
  };

  return (
    <Row>
      <Col xs="2">
        {round.round}
      </Col>
      <Col xs="3">
        {round.opponentName} ({opponentRecord.won}-{opponentRecord.tied}-{opponentRecord.lost})
      </Col>
      <Col xs="3">
        {wonOrLost} {round.won.pts}-{round.lost.pts} ({ourRecord.won}-{ourRecord.tied}-{ourRecord.lost})
      </Col>
      <Col xs="2">
        <span
          className="result-card-game-detail-link"
          onClick={() => handleEliminationRoundClick(round.gid)}
        >
          GAME DETAIL
        </span>
      </Col>
    </Row>
  )
}