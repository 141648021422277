import { useState } from 'react';
import { StandingSubTabs } from '../types';

export const useStangingTabs = () => {
  const [tab, setTab] = useState<StandingSubTabs>('DIVISION');

  return {
    tab,
    setTab,
  };
};
