export function formatNumber(number?: number, decimalPlaces = 2): string {
   if (!number) return ""
   const formattedNumber = parseFloat(number.toString()).toFixed(decimalPlaces);
   return formattedNumber.replace(/^0+/, "").replace(/\.$/, "");
}

export function formatNumberWithPad(number: number, padLength: number): string {
  let numberString = number.toString();

  while (numberString.length < padLength) {
    numberString = '0' + numberString;
  }

  return numberString;
}
