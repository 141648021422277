import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";
import { useLoading } from "components/FranchiseOverview/hooks/use-loading";
import { PurchaseTrophy } from "components/PurchaseTrophy/PurchaseTrophy";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export const PurchaseTrophyScreen: React.FC = () => {
  const loader = useLoading();

  return (
    <>
      {loader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <PurchaseTrophy />
      </div>
    </>
  );
}