import Error from "../../../../../assets/images/common/error.svg";

const AssistantCoachNotAllowModal = () =>{
    return(
        <>  
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>The hire is not allowed. you are only allowed 3 assistant 
                coaches. Export or release an assistant coach to 
                complete the hire.</p>
            </div> 
        </>
    );
}

export default AssistantCoachNotAllowModal;