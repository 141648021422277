import "./CircleIconStyle.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  alt?: string;
}

const CircleIcon: React.FC<Props> = ({ icon, alt, onClick, disabled, ...props }) => {
  return (
    <button
      {...props}
      className={`circleIcon ${props.className ?? ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img src={icon} alt={alt ?? 'Icon'} />
    </button>
  )
};

export default CircleIcon;