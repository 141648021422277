/* eslint-disable react-hooks/exhaustive-deps */
import "./SelectLoginModalStyles.scss";
import MetamaskContent from "./content/Metamask/MetamaskContent";
import EmailContent from "./content/Email/EmailContent";

type SelectLoginModalProps = {
  setLoading: (loading: boolean) => void;
};

const SelectLoginModal = ({ setLoading }: SelectLoginModalProps) => {
  return (
    <>
      <div className="select-login-component">
        <h5>
          FRANCHISE OWNERS: SIGN-IN
        </h5>
        <div className="login-options">
          <EmailContent setLoading={setLoading} />
          <MetamaskContent /> 
        </div>
      </div>
    </>
  );
};

export default SelectLoginModal;
