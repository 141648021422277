import { Profile, UserTeamsList } from 'types';
import './Teams.scss';
import { API_URL } from 'config';
import { useSelectedUserTeams } from 'helper/useSelectedUserTeams';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import history from 'routes/history';
import { PreviewModal } from 'components/OwnFranchise/modal/TeamPreviewModal';
import { useState } from 'react';

type Props = { profile: Profile; isOwn: boolean };

export const Teams: React.FC<Props> = ({ profile, isOwn }) => {
  const teams = useSelectedUserTeams(profile.id);

  const [selectedTeam, setSelectedTeam] = useState<UserTeamsList[0] | null>(null);

  return (
    <>
      <div className="profile-teams section">
        <div className="header">
          <h2>TEAMS</h2>
          {isOwn && <PinkButton text="ADD TEAM" onClick={() => history.push('/purchasefranchise')} />}
        </div>
        <div className="profile-teams__images">
          {teams &&
            teams.map((team) => (
              <img
                onClick={() => setSelectedTeam(team)}
                key={team.id}
                src={`${API_URL}/images/franchise/userFranchise/${team.img}`}
                alt={team.teamName}
                className="profile-teams__image"
              />
            ))}
        </div>
      </div>
      <PreviewModal
        open={!!selectedTeam}
        onCancel={() => setSelectedTeam(null)}
        team={{
          helmet: selectedTeam?.helmet || 0,
          image: `${API_URL}/images/franchise/userFranchise/${selectedTeam?.img}`,
          logo: selectedTeam?.logo || 0,
          name: selectedTeam?.teamName || '',
          bg: selectedTeam?.bg || 0,
        }}
      />
    </>
  );
};
