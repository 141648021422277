import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTeamChart, useUploadTeamHistory } from "./hooks";
import Title from "../../../Common/Title/Title";
import PlayerCareerRating from "components/PlayerDetail/PlayerCareerRating";

const StatsTeamRating: React.FC = () => {
  const { franchiseId }: any = useParams();
  const { teamData } = useUploadTeamHistory(franchiseId);
  const { chartData } = useTeamChart(teamData);

  return (
    <div>
      <Title text="TEAM RATING" />
      <Col xl={12}>
        <PlayerCareerRating
          ratingHistory={chartData.map((item) => ({
            date: item.date.getTime(),
            value: item.value,
          }))}
        />
      </Col>
    </div>
  );
};
export default StatsTeamRating;
