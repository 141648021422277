import {Form} from 'react-bootstrap';
import "./InputStyle.scss";

export interface Props{
    label?: any,
    type?: any,
    placeholder?: any,
    onChange?: any,
    value?: any,
    id?: any,
    disabled?: any,
    classNameForm?: string,
    classNameLabel?: string,
    classNameField?: string,
    controlId?: string,
    renderButton?:()=>React.ReactElement
}

const Input: React.FC<Props> = ({
    label,
    type,
    placeholder,
    onChange,
    value,
    id,
    disabled,
    classNameForm,
    classNameLabel,
    classNameField,
    controlId,
    renderButton
}) => (
    <Form.Group className={classNameForm} controlId={controlId || "formBasicEmail"}>
        {label && <Form.Label className={classNameLabel}>{label}</Form.Label>}
        <div style={{display:'flex', alignItems:'center', background:'#f4f7f7', paddingRight:renderButton?'1rem':'0', borderRadius:'1.2rem'}}>
            <Form.Control 
                type={type || "text"}
                placeholder={placeholder} 
                value={value}
                onChange={(e) => onChange(e.target.value)}
                id={id}
                disabled={disabled}
                style={{paddingRight:renderButton?'1rem':'0'}}
                className={classNameField}
            />
            {renderButton && renderButton()}
        </div>
        

    </Form.Group>
);

export default Input;