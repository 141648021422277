import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getMinWithdrawalAmount } from "redux/actions/AppAction";
import { RootState } from "redux/store";

export const useMinWithdrawalAmount = () => {
  const dispatch = useDispatch();

  const minWithdrawalAmount: number | null = useSelector((state: RootState) => state.app.minWithdrawalAmount);
  
  useEffect(() => {
    dispatch(getMinWithdrawalAmount());
  }, []);

  return minWithdrawalAmount;
}