import { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { InfoCircleFilled } from "@ant-design/icons";

import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import OwnFranchiseHeader from "components/OwnFranchise/OwnFranchiseHeader";

import "./PurchaseTrophy.scss";
import { PurchaseTrophyInfo } from "./modals/PurchaseTrophyInfo/PurchaseTrophyInfo";
import { TrophyImageBuilder } from "./components/TrophyImageBuilder/TrophyImageBuilder";
import { Divider } from "antd";
import WalletConnected from "components/PurchaseFranchise/WalletConnected/WalletConnected";
import { TrophyBeforePurchase } from "./types";

export const PurchaseTrophy: React.FC = () => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [trophy, setTrophy] = useState<Partial<TrophyBeforePurchase> | null>(null);

  return (
    <>
      <Container>
        <OwnFranchiseHeader />
        <Card>
          <CardBody>
            <div className="purchase-trophy">
              <h4 className="purchase-trophy__header mb-4">
                CUSTOMIZE & CREATE YOUR TROPHY{" "}
                <InfoCircleFilled onClick={() => setIsInfoModalVisible(true)} />
              </h4>
              <Row>
                <Col xl={7} lg={7}>
                  <TrophyImageBuilder trophy={trophy} setTrophy={setTrophy} />
                </Col>
                <Col className="verticaldivider">
                  <Divider type="vertical" />
                </Col>
                <Col xl={4} lg={4}>
                  <WalletConnected data={trophy} payment/>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
      <PurchaseTrophyInfo
        open={isInfoModalVisible}
        onClose={() => setIsInfoModalVisible(false)}
      />
    </>
  );
};
