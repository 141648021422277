import { getAzureStorageFileUrl } from "helper/helper";
import { File } from "types/file";
import { GroupEmptyAvatar } from "./GroupEmptyAvatar";
import "./GroupAvatar.scss";

interface GroupAvatarProps {
  file?: File | string | null;
}

export function GroupAvatar({ file }: GroupAvatarProps) {
  const fileSrc =
    file && typeof file === "object"
      ? file.src
      : file
      ? getAzureStorageFileUrl(file)
      : file;

  const fileName =
    file && typeof file === "object" ? file.fileName : "group-avatar";

  return (
    <div className="group-avatar__image-wrapper">
      {file && fileSrc ? (
        <img src={fileSrc} alt={fileName} />
      ) : (
        <GroupEmptyAvatar />
      )}
    </div>
  );
}
