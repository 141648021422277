import { useState } from "react";

export function useCompleteRevealModal(onClose?: () => void) {
  const [isVisible, setIsVisible] = useState(false);
  const handleOpen = () => setIsVisible(true);
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsVisible(false);
  };

  return { isVisible, handleOpen, handleClose };
}
