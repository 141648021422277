import { formatNumber } from 'components/PlayerDetail/utils/formatNumber';
import React from 'react';

type Props = {
  rankings?: {
    currentRanking?: number;
    highestRanking?: number;
    lowestRanking?: number;
  };
  position?: string;
  ratingHighPoint?: number;
  ratingLowPoint?: number;
  averageRatingIncrease?: number;
};

export const AdvancedProgressionStats: React.FC<Props> = ({ rankings, ratingHighPoint, ratingLowPoint, averageRatingIncrease }) => (
  <div>
    <div className="achievement">
      <p className="title">{ratingHighPoint} RATING</p>
      <p className="value">Rating high point</p>
    </div>
    <div className="achievement">
      <p className="title">{ratingLowPoint} RATING</p>
      <p className="value">Rating low point</p>
    </div>
    <div className="achievement">
      <p className="title">{formatNumber(averageRatingIncrease)} RATING</p>
      <p className="value">Average rating increase per game played</p>
    </div>
    {!!rankings?.currentRanking && <div className="achievement">
      <p className="title">#{rankings?.currentRanking}</p>
      <p className="value">Current ranking</p>
    </div>}
    {!!rankings?.highestRanking && <div className="achievement">
      <p className="title">#{rankings?.highestRanking}</p>
      <p className="value">Highest ranking</p>
    </div>}
    {!!rankings?.lowestRanking && <div className="achievement">
      <p className="title">#{rankings?.lowestRanking}</p>
      <p className="value">Lowest ranking</p>
    </div>}
  </div>
);
