import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useProfile } from "./hooks";
import { ProfileContent } from "./profile-content";
import { ProfileHeaderWithFriendButton } from "components/Common/Header/ProfileHeader";

export function ProfileMain() {
  const { id } = useParams<{ id: string }>();
  const { profile, isOwn } = useProfile(Number(id)); 
  return (
    <Container style={{ paddingTop: "1.5rem", paddingBottom: "4rem" }}>
      <ProfileHeaderWithFriendButton userId={Number(id)}/>
      {profile && <ProfileContent profile={profile} isOwn={isOwn} />}
    </Container>
  );
}
