import Error from "../../../../assets/images/common/error.svg";

const ReleaseModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Releasing player will remove player from your roster. 
                You will not be able to reverse the decision
                if you proceed.</p>
            </div>  
        </>
    );
}

export default ReleaseModal;