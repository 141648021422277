import { Row, Col } from 'react-bootstrap';

import LeagueScoreList from 'components/Common/LeagueScoreTab/LeagueScoreList';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import history from '../../../../routes/history';
import { getGameSeasonScores } from 'redux/actions/GameEngineAction';
import { mapGameSeasonScoreToLeagueScore } from 'components/Common/LeagueScoreTab/utils/mapGameSeasonScoreToLeagueScore';
import { mapSeasonScores } from 'helper/helper';

const OverviewLeagueLastPlayedGame: React.FC = () => {
  const dispatch = useDispatch();
  const franchiseData: { id: number } = history.location.state as { id: number };
  let { franchiseId }: any = useParams();
  if (!franchiseId) {
    franchiseId = franchiseData?.id;
  }

  const userTeamListData = useSelector((state: RootState) => state.team.userTeamsList);

  const gameSeasonScores = useSelector((state: RootState) => state.gameEngine?.gameSeasonScores);

  const franchise = useMemo(() => {
    return userTeamListData?.find((info: any) => info?.id == franchiseId || info?.engId == franchiseId);
  }, [userTeamListData, franchiseId]);

  const leagueScoreLastGameData = useMemo(() => {
    if (!gameSeasonScores?.data?.[0]) return null;
    console.log('gameSeasonScores', gameSeasonScores?.data[0])

    return mapSeasonScores(gameSeasonScores?.data)[0];
  }, [gameSeasonScores?.data]);

  const noGames = gameSeasonScores && gameSeasonScores?.data && gameSeasonScores?.data?.length === 0;

  useEffect(() => {
    if (!franchise) return;

    const isDataExist = gameSeasonScores && gameSeasonScores?.data?.length === 1 && gameSeasonScores?.data[0]?.tid.includes(franchise?.engId);
    
    if (!isDataExist && !noGames) {
      dispatch(getGameSeasonScores(1, [franchise?.engId], true, "H"));
    }
  }, [franchise, gameSeasonScores, noGames]);

console.log('leagueScoreLastGameData', leagueScoreLastGameData);

  return (
    <div className="mb-5">
      <h5 className="title">LAST GAME PLAYED</h5>
      <Row>
        <Col xl={12}>
          {noGames && 'No Games'}
          {leagueScoreLastGameData && <LeagueScoreList data1={leagueScoreLastGameData} />}
        </Col>
      </Row>
    </div>
  );
};

export default OverviewLeagueLastPlayedGame;
