import { Container } from "react-bootstrap";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { LobbyContent } from "./LobbyContent";
import { ProfileHeader } from "components/Common/Header/ProfileHeader";
import "./LobbyMain.scss"

export function LobbyMain() {
  const user = useSelector((state: RootState) => state.app.resData);

  if (!user) return null;
  
  return (
    <Container style={{ paddingTop: "1.5rem", paddingBottom: "4rem" }} className="lobby">
      <ProfileHeader userId={user?.id} />
      <LobbyContent />
    </Container>
  );
}
