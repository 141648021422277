import { notification } from "antd";
import { post } from "redux/services/Api";

export const setInGameDepthOrPlanAsDefault = async(utid: number, gid: number, depthOrPlan: 'depth' | 'plan') => {
  try {
    const res = await post(
      `/engine/team/${utid}/setGameDepthOrPlanAsDefault?gid=${gid}&depthOrPlan=${depthOrPlan}`,
    ) as { message: string};

    notification.success({
      message: res?.message,
      placement: 'bottomRight'
    })

  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message)
    }

    console.log('ERROR UPDATING')
  }
}