import { API_URL } from "config";
import { NewsActionTypes } from "..";
import { get } from "redux/services/Api";

export const getNewsArticle = (id: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: NewsActionTypes.GET_NEWS_ARTICLE_REQUEST,
        payload: {},
      });

      const data = await get(
        `${API_URL}/user/get-news-article/${id}`
      );

      dispatch({
        type: NewsActionTypes.GET_NEWS_ARTICLE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: NewsActionTypes.GET_NEWS_ARTICLE_FAIL,
      });
    }
  };
};
