/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import PinkButton from "../../Common/Button/PinkButton/PinkButton";
import logo from "../../../assets/images/common/dynastyLogo.png";
import Menu from "../../../assets/images/common/menu.png";
import { useLocation } from "react-router-dom";
import "./NavbarWithOutLoginStyle.scss";
import { Modal } from "antd";
import PlayNowModal from "../../Common/PlayNowModal/PlayNowModal";
import history from "routes/history";
import { useAnalyticsEventTracker } from "components/Common/GoogleAnalytics/useAnalyticsEventTracker";

export interface Props {
  login?: any;
  about?: any;
  setLoading?: any;
}

const NavbarWithOutLogin: React.FC<Props> = ({ login, about, setLoading }) => {
  const gaEventTracker = useAnalyticsEventTracker("Navbar");
  const [navbarMain, setNavbar] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSignUp = queryParams.get("signup") === "true";
  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  // Metamask Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if(isModalVisible){
      document.body.style.overflowY = 'auto';
      document.body.style.width = 'auto';
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isSignUp) {
      showModal();
    }
  }, []);
  
  return (
    <>
      <div>
        <Navbar
          fixed="top"
          collapseOnSelect
          expand="lg"
          className={navbarMain ? "navbarMain active" : "navbarMain"}
        >
          <Container fluid>
            <Navbar.Brand href="/" className="brandLogo">
              <img src={logo} alt="companyLogo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <img src={Menu} alt="menuBarIcon" />
            </Navbar.Toggle>
            <Navbar.Collapse className="responsive-navbar-nav-without-login" id="responsive-navbar-nav">
              <Nav className="me-auto">
                {about && (
                  <>
                    <Nav.Link href="/about">ABOUT</Nav.Link>
                    <Nav.Link href="/news">NEWS</Nav.Link>
                  </>
                )}
              </Nav>
              <Nav className="play-free-button">
                <PinkButton text="PLAY FREE" onClick={() => {
                  gaEventTracker("Play Free button click");
                  history.push("/join-free-game")
                }}/>
              </Nav>
              <Nav>
                {login && <PinkButton text="LOGIN/REGISTER" onClick={showModal} />}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      {/* DRAFT START */}
      <Modal
        title=""
        width="auto"
        className="metamask_connect"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        centered
      >
        <PlayNowModal 
          setLoading={setLoading} 
          closeModal={handleCancel}
          isSignUp={isSignUp}
        />
      </Modal>
    </>
  );
};

export default NavbarWithOutLogin;
