import { useServerSidePagination } from "helper/useServerSidePagination";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonResults } from "redux/actions/Season/actions/SeasonResults";
import { RootState } from "redux/store";

const LIMIT = 10;

export const useSeasonResults = (gid?: string) => {
  const dispatch = useDispatch();
  const {
    handleNextPage,
    handlePrevPage,
    page,
    setPage,
  } = useServerSidePagination();

  const leagueResults = useSelector((state: RootState) => state.gameEngine.leagueResults);


  useEffect(() => {
    if (!gid) return;
    dispatch(getSeasonResults(gid, page, LIMIT));
  }, [gid, page, LIMIT])


  return {
    leagueResults,
    handleNextPage,
    handlePrevPage,
    page,
    setPage,
  }
};
