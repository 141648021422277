import { roundNumberToGivenDigitsAfterDot } from "helper/helper";

type Props = {
  value: string | number;
  digitsAfterDot?: number;
  noDecimal?: boolean
}

export const ValueWrapper: React.FC<Props> = ({ 
  value,
  digitsAfterDot = 1,
  noDecimal,
}) => {
  return (
    <div style={{ marginLeft: "20px" }}>
      {!Number.isNaN(+value) ? roundNumberToGivenDigitsAfterDot(value, digitsAfterDot, noDecimal) : value }
    </div>
  )
}

export const wrapAllAntdTable = (columns: any[], noDecimal = false) => {
  return columns.map((column: any, index: number) => {
    if (index > 0) {
      return ({
        ...column,
        render: (text: string | number) => <ValueWrapper value={text} noDecimal={noDecimal} />
      })
    }

    return column;
  })
}