/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from "react-bootstrap";
import CustomizeFranchiseContent from "./CustomizeFranchiseContent";
import "./CustomizeFranchiseStyle.scss";
import { useState } from "react";
import LoadingScreen from "../Common/LoadingScreen/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getAvatarData } from "./../../redux/actions/TeamAction";
import OwnFranchiseHeader from "../OwnFranchise/OwnFranchiseHeader";

const CustomizeFranchiseMain = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loadingAppState = useSelector((state: RootState) => state.app.loader);
  const loadingTeamState = useSelector((state: RootState) => state.team.loader);
  const loadingTeamStateSaveAvatar = useSelector(
    (state: RootState) => state.team.loaderSaveAvatar
  );
  const loadingGameEngineState = useSelector(
    (state: RootState) => state.gameEngine.loader
  );

  // useEffect(() => {
    // dispatch(getAvatarData());
  // }, []);

  return (
    <>
      {(loading ||
        !!loadingAppState ||
        !!loadingTeamState ||
        !!loadingTeamStateSaveAvatar ||
        !!loadingGameEngineState
        ) && <LoadingScreen />}
      <Container>
        <OwnFranchiseHeader /> 
        <CustomizeFranchiseContent setLoading={setLoading} />
      </Container>
    </>
  );
};

export default CustomizeFranchiseMain;
