import { API_URL } from "config"
import { post } from "redux/services/Api"
import { NotificationSettings } from "types";

export const setNotificationSettings = async(id: number, newValue: NotificationSettings) => {
  try {
    return await post(`${API_URL}/user/disabled-notifications?id=${id}`, newValue);
  } catch (e) {
    console.log(e);
    return null;
  }
}