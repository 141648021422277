/* eslint-disable jsx-a11y/img-redundant-alt */
import { Row, Col } from "react-bootstrap";
import picture1 from "../../assets/images/aboutus/Picture1.png";
import picture2 from "../../assets/images/aboutus/Picture2.png";
import picture3 from "../../assets/images/aboutus/Picture3.png";

const AboutusBody = () => {
  return (
    <>
      <div className="aboutus_body">
        <Row>
          <Col sm={4}>
            <div className="founder">
              <img src={picture1} alt="picture" />
              <h5>Founder</h5>
              <h6>
                {" "}
                <a
                  href="https://www.linkedin.com/in/barry-resnik-6837a89/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Barry Resnik | LinkedIn
                </a>
              </h6>
              <p>
                Initiating the action and looking to connect on the deep ball.
              </p>
            </div>
          </Col>
          <Col sm={4}>
            <div className="founder">
              <img src={picture2} alt="picture" />
              <h5>Founder</h5>
              <h6>
                {" "}
                <a
                  href="https://www.linkedin.com/in/perfectofficeworkout"
                  target="_blank"
                  rel="noreferrer"
                >
                  Derek Gorney | LinkedIn
                </a>
              </h6>
              <p>
                Game planning, play calling and keeping our head in the game.
              </p>
            </div>
          </Col>
          <Col sm={4}>
            <div className="founder">
              <img src={picture3} alt="picture" />
              <h5>Head of Development</h5>
              <h6>
                {" "}
                <a
                  href="https://www.linkedin.com/in/mike-marshall-69a16b6/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mike Marshall | LinkedIn
                </a>
              </h6>
              <p>Getting dirty in the trenches.</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AboutusBody;
