import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getTeamLeadersScored } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useTeamLeadersScored() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamLeadersScored());
  }, []);

  const teamLeadersScored: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.teamLeadersScored);

  return { teamLeadersScored };
}