import { UseSearchUsers } from "components/Common/UserSearch";
import { useFriends } from "components/Profile/hooks/useFriends";
import { useUserSearchByName } from "helper/useUserSearchByName";
import { useMemo } from "react";
import { FoundUser } from "types/groups";

export const useInviteFriends = (): {
  searchUsers: () => UseSearchUsers<FoundUser>;
  users: FoundUser[],
  invitedIds: number[]
} => {
  const { acceptedFriends, outcomingRequests } = useFriends();
  const { foundUsers, handleSearch } = useUserSearchByName();
  const invitedIds = useMemo(() => outcomingRequests?.map((user) => user.id) || []
    , [outcomingRequests])

  // Filter already accepted frieds
  const users = useMemo(() => foundUsers.filter((foundUser) =>
    !acceptedFriends?.some((friend) => friend.id === foundUser.id)
  ), [acceptedFriends, foundUsers]);

  return {
    searchUsers: () => ({ users, handleSearch }),
    invitedIds,
    users,
  };
};