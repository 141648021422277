import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeeRates } from 'redux/actions/AppAction';
import { RootState } from 'redux/store';

export const useFeeRates = () => {
  const dispatch = useDispatch();
  const feeRates = useSelector((state: RootState) => state.app.feeRates);
  const count = useRef(0);

  useEffect(() => {
    if (!feeRates && count.current < 3) {
      count.current += 1;
      dispatch(getFeeRates());
    }
  }, [feeRates]);

  const PAYPAL_FEE_AMOUNT = feeRates?.PAYPAL_FEE_AMOUNT;
  const PAYPAL_FEE_PERCENT = feeRates?.PAYPAL_FEE_PERCENT;
  const WALLET_TRANSACTION_FEE = feeRates?.WALLET_TRANSACTION_FEE;

  return { PAYPAL_FEE_AMOUNT, PAYPAL_FEE_PERCENT, WALLET_TRANSACTION_FEE };
};
