import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  isWithoutCorner?: boolean;
  isYourTeam?: boolean;
};

const HomeContainer = ({
  children,
  isWithoutCorner = false,
  isYourTeam = false,
}: Props) => {
  return (
    <section
      className={
        isWithoutCorner
          ? "home-container"
          : isYourTeam
            ? "home-container-corner-team"
            : "home-container-corner"
      }
    >
      {children}
    </section>
  );
};

export default HomeContainer;
