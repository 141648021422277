import { combineReducers } from "redux";
import AppReducer, { appStateIF } from "./AppReducer";
import TeamReducer, { teamStateIF } from "./TeamReducer ";
import GameEngineReducer, { GameEngineStateIF } from "./GameEngineReducer";
import SettingReducer, { settingStateIF } from "./SettingReducer";
import ProfileReducer, { ProfileStateIF } from "./ProfileReducer";
import GroupReducer, { GroupsState } from "./GroupsReducer";

export interface ReducerStateIF {
  app: appStateIF;
  team: teamStateIF;
  gameEngine: GameEngineStateIF;
  setting: settingStateIF;
  profile: ProfileStateIF;
  group: GroupsState;
}
export default combineReducers({
  app: AppReducer,
  team: TeamReducer,
  gameEngine: GameEngineReducer,
  setting: SettingReducer,
  profile: ProfileReducer,
  group: GroupReducer,
});
