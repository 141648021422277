import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useIsEliminationRound = (h2hGid?: number) => {
  const [eliminationId, setEliminationId] = useState<number | null>(null);
  const leagueType = eliminationId ? "E" : "H";

  const pregameOverview = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview
  );

  useEffect(() => {
    if (
      h2hGid &&
      pregameOverview &&
      +pregameOverview?.gid === +h2hGid &&
      "eliminationId" in pregameOverview
    ) {
      setEliminationId(pregameOverview?.eliminationId);
    }
  }, [h2hGid, pregameOverview]);

  return { eliminationId, leagueType };
};
