/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Input from "../Common/Form/Input/Input";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import { resetPassword } from "../../redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { validPassword } from "../../helper/helper";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";

const ResetPasswordForm = ({ token }: any) => {
  const errorData = useSelector((state: RootState) => state.app.error);
  const successData = useSelector((state: RootState) => state.app.success);
  const loading = useSelector((state: RootState) => state.app.loader);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwdError, setPwdError] = useState(false);
  const [conPwdError, setConPwdError] = useState(false);
  const [error, setError] = useState(errorData);
  const [success, setsuccess] = useState(successData);
  const [errorMsg, setErrorMsg] = useState<any>("");

  const dispatch = useDispatch();

  const handleResetPassword = () => {
    setErrorMsg("");
    if (password == "" || confirmPassword == "") {
      setErrorMsg("All Fields Are Required");
    } else if (!validPassword.test(password)) {
      setPwdError(true);
      setConPwdError(false);
    } else if (password !== confirmPassword) {
      setPwdError(false);
      setConPwdError(true);
    } else {
      setPwdError(false);
      setConPwdError(false);
      dispatch(resetPassword(token, password));
    }
  };

  useEffect(() => {
    setError(errorData);
    setsuccess(successData);
    if (success) {
      setPassword("");
      setConfirmPassword("");
    }
  }, [success, errorData, successData]);
  return (
    <>
      {!!loading && <LoadingScreen />}
      <div className="signUpBackground signinBackground">
        <div className="signUpMain">
          <div className="signupHead">
            <h4>RESET YOUR PASSWORD</h4>
          </div>
          <p className="signupDescription">
            Password must be 8 characters, use upper/lower and include a special
            character or number.
          </p>
          <div className="signupForm signinForm">
            <Row>
              <Col xl={12} className="mb-3">
                <Input
                  type="password"
                  label="Password"
                  value={password}
                  onChange={setPassword}
                  placeholder="Enter New Password"
                />
              </Col>
              {pwdError && (
                <p className="error">Password does not satisfy requirements</p>
              )}
              <Col xl={12} className="mb-3">
                <Input
                  type="password"
                  label="Conform Password"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder="Re-enter Password"
                />
              </Col>
              {conPwdError && <p className="error">Passwords don't match</p>}
              {error && <p className="error">{error}</p>}
              {success && <p className="success">{success}</p>}
              {errorMsg && <p className="error">{errorMsg}</p>}
              <Col xl={12} className="text-center mt-2">
                <BlueButton
                  text="Reset Password"
                  onClick={handleResetPassword}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
