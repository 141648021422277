import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { Container } from "react-bootstrap";
import "./AboutusStyle.scss";
import AboutusHeader from "./AboutusHeader";
import AboutusBody from "./AboutusBody";
import AboutusFooter from "./AboutusFooter";

const AboutusMain = () => {
  return (
    <>
      <div className="about-main">
        <Container>
          <Card>
            <CardBody>
              <h5 className="about_head">ABOUT US</h5>
              <AboutusHeader />
              <AboutusBody />
              <AboutusFooter />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default AboutusMain;
