import { FacebookShareButton } from "react-share";

type Props = {
  url: string;
  hashtag: string;
  children?: React.ReactNode;
  disabled?: boolean;
};

export const FacebookShare: React.FC<Props> = ({
  url,
  hashtag,
  children,
  disabled,
}) => {

  return (
    <FacebookShareButton
      url={url}
      hashtag={hashtag}
      disabled={disabled}
    >
      {children}
    </FacebookShareButton>

  );
};
