import { FormationItem } from "./FormationItem";
import { FormationPlaceholder } from "./FormationPlaceholder";
import { useSelectedDepthData } from "./hooks/useSelectedDepthData";

export const F4DL3LB2S2CB: React.FC = () => {
  const formationData = useSelectedDepthData('4 DL, 3 LB, 2 S, 2 CB');

  if (
    !formationData 
      || !formationData.CB
      || !formationData.DL
      || !formationData.S
      || !formationData.LB) {
    return <div/>;
  }

  return (
    <div className="formation-container">
      <div className="formation-row">
        <FormationItem 
          player={formationData.CB[0]}
          color="grey"
        />
        <FormationPlaceholder/>
        <FormationItem 
          player={formationData.DL[0]}
          color="black"
        />
        <FormationItem
          player={formationData.DL[1]}
          color="black"
        />
        <FormationItem
          player={formationData.DL[2]}
          color="black"
        />
        <FormationItem
          player={formationData.DL[3]}
          color="black"
        />
        <FormationPlaceholder/>
        <FormationItem 
          player={formationData.CB[1]}
          color="grey"
        />
      </div>
      <div className="formation-row">
        <FormationPlaceholder/>
        <FormationItem 
          player={formationData.LB[0]}
          color="white"
        />
        <FormationItem 
          player={formationData.LB[1]}
          color="white"
        />
        <FormationItem 
          player={formationData.LB[2]}
          color="white"
        />
        <FormationPlaceholder/>
      </div>
      <div className="formation-row">
        <FormationPlaceholder />
        <FormationItem 
          player={formationData.S[0]}
          color="grey"
        />
        <FormationItem 
          player={formationData.S[1]}
          color="grey"
        />
        <FormationPlaceholder />
      </div>
    </div>
  );
};
