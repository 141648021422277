import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';
export interface Props {
  data?: any;
  team?: any;
}

const Team1Defense = ({ data , team }: Props) =>{
    const scoreColumns:any = [
        {
          title: "",
          dataIndex: "title",
          fixed: "left",
          width: 150,
        },
        {
          title: "TOT",
          dataIndex: "tot",
        },
        {
          title: "SOLO",
          dataIndex: "solo",
        },
        {
          title: "TFL",
          dataIndex: "tfl",
        },
        {
          title: "PD",
          dataIndex: "pd",
        },
        {
          title: "INT",
          dataIndex: "int",
        },
        {
          title: "FF",
          dataIndex: "ff",
        },
    ];
    let tablefooter:any={
      key:700,
      title:"TEAM",
      tot: team.stat.defTckLoss || team.stat.defTckLoss === 0 ? team.stat.defTckLoss : 0+team.stat.defTckAst || team.stat.defTckAst === 0 ? team.stat.defTckAst : 0,
      solo: team.stat.defTckSolo || team.stat.defTckSolo === 0 ? team.stat.defTckSolo :"---",
      tfl: team.stat.defTckLoss || team.stat.defTckLoss === 0 ? team.stat.defTckLoss : "---",
      pd: team.stat.defPssDef || team.stat.defPssDef === 0 ? team.stat.defPssDef :"---",
      int: team.stat.defInt || team.stat.defInt === 0? team.stat.defInt :"---",
      ff: team.stat.defFmbFrc || team.stat.defFmbFrc === 0? team.stat.defFmbFrc :"---",
    }
    const scoreData =data?.map((d: any,index:any) => {
      const info = {
        key: index,
        title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)} >{d.name ? d.name : "---"}</span></>,
        tot: d.stat.defTckLoss || d.stat.defTckLoss === 0 ? d.stat.defTckLoss :0+d.stat.defTckAst || d.stat.defTckAst === 0 ? d.stat.defTckAst :0,
        solo: d.stat.defTckSolo || d.stat.defTckSolo === 0 ? d.stat.defTckSolo :"---",
        tfl: d.stat.defTckLoss || d.stat.defTckLoss === 0 ? d.stat.defTckLoss : "---",
        pd: d.stat.defPssDef || d.stat.defPssDef === 0 ? d.stat.defPssDef :"---",
        int: d.stat.defInt || d.stat.defInt === 0? d.stat.defInt :"---",
        ff: d.stat.defFmbFrc || d.stat.defFmbFrc === 0? d.stat.defFmbFrc :"---",
      }
      return info;
    })
    if(Array.isArray(scoreData) && scoreData.length){
      scoreData.push(tablefooter)
    }
    return(
        <>
            <div className="scorecommontable standingmain mt-3" >
                <Title text="DEFENSE" />
                <Table
                    columns={scoreColumns}
                    pagination={false}
                    dataSource={scoreData}
                    size="small"
                    scroll={{ x: 500 }}
                />
            </div>
        </>
    );
}

export default Team1Defense;