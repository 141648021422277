export function transformObject<T>(sourceObject: Record<string, number | null>, callback?: (arg: string) => T) {
   const transformedObject: Record<string, T> = {};

   Object.keys(sourceObject).forEach((key) => {
      const originalValue = sourceObject[key];
      const value = (originalValue !== null) 
         ? (typeof originalValue === 'number' && originalValue !== 0)
            ? originalValue.toFixed(1)
            : originalValue === 0
            ? originalValue.toString()
            : originalValue
         : "n/a";
      if (callback) {
          const transformedValue = callback(value);
          transformedObject[key] = transformedValue;
      } else {
        transformedObject[key] = value as T;
      }
   });

   return transformedObject;
}