import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import Input from "components/Common/Form/Input/Input";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InviteTooltip } from "./InviteTooltip";
import { Fragment } from "react";
import "./InvitePlayers.scss";
import { FoundUsersList } from "./FoundUsersList";
import { UserNamesAutoComplete } from "components/Common/UserNamesAutoComplete/UserNamesAutoComplete";
import { useUserNameAutoComplete } from "helper/useUserNameAutoComplete";

type Props = {
  title: string;
  users: string[];
  setUsers: React.Dispatch<React.SetStateAction<string[]>>;
};

export const InvitePlayers: React.FC<Props> = ({ title, users, setUsers }) => {
  const [idString, setIdString] = useState("");
  const { foundUserNames, handleSearch} = useUserNameAutoComplete();

  const handleAddUser = (givenName?: string) => {
    const username = givenName || idString;

    if (username.length && !users.includes(username)) {
      setUsers((currentUsers) => {
        const newUsers = [...currentUsers];
        newUsers.push(username);
        return newUsers;
      });

      setIdString("");
    }
  };

  const handleUserClick = (user: string) => {
    setUsers((currentUsers) => {
      const newUsers = [...currentUsers];
      const indexToDelete = newUsers.findIndex((username) => username === user);
      if (indexToDelete !== -1) {
        newUsers.splice(indexToDelete, 1);
      }

      return newUsers;
    });
  };

  useEffect(() => {
    handleSearch(idString);
  }, [idString]);

  return (
    <Row>
      <Col md={12}>
        <div className="add-user-form-container">
          <UserNamesAutoComplete
            popoverContent={<FoundUsersList users={foundUserNames} handleAddUser={handleAddUser}/>}
            isOpen={!!foundUserNames.length}
          >
            <Input
                type="text"
                label={title}
                placeholder="ENTER GM DYNASTY USERNAME, GROUP NAME OR EXTERNAL EMAIL"
                value={idString}
                onChange={setIdString}
                controlId="find-existing-players"
                classNameForm="mb-4"
              />
          </UserNamesAutoComplete>
        </div>
        <span>Added users: </span>
        {!!users.length && (
          <span>
            {users.map((user, index) => {
              return (
                <Fragment key={user + String(index)}>
                  <InviteTooltip text="Remove">
                    <span
                      className="username-or-email"
                      onClick={() => handleUserClick(user)}
                    >
                      {user}
                    </span>
                  </InviteTooltip>

                  {index !== users.length - 1 && ", "}
                </Fragment>
              );
            })}
          </span>
        )}
      </Col>
      <Col md={12}>
        <BlueButton
          text="ADD USER"
          onClick={() => handleAddUser()}
          className="add-user-button"
        />
      </Col>
    </Row>
  );
};
