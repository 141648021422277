import { Table } from 'antd';
import { getSeasonTableColumns } from '../constants';
import { SeasonInfo } from 'screens/Season/types';
import { prepareTeams } from '../helper';
import { useSeasonConferenceStats } from 'screens/Season/hooks/useSeasonConferenceStats';
import { useEffect, useState } from 'react';
import { SeasonTeamStats } from './types';
import { defaultStats } from './constants';

type Props = {
  info: SeasonInfo;
};

export const Conference: React.FC<Props> = ({ info }) => {
  const { stats } = useSeasonConferenceStats(info?.id);
  const [teamsByConference, setTeamsByConference] = useState<Record<string, any[]>>({});

  useEffect(() => {
    const statsObj = stats.reduce((acc: Record<string, SeasonTeamStats>, stat) => {
      acc[stat._id] = stat;
      return acc;
    }, {});
    

    const newTeamsByConference: Record<string, any[]> = {};
    info?.conferences?.forEach((conference) => {
      const conferenceTitle = conference?.title;
      const teams = (conference?.divisions?.flatMap(division =>
        division?.teams?.map(team => {
          if (!team || !team.team) return null;

          const statsForTeam = statsObj[team.team.utid] || defaultStats;
          return {
            utid: team.team?.utid,
            name: team.team?.name,
            ...statsForTeam
          };
        })
      ) || []).filter(Boolean);

      newTeamsByConference[conferenceTitle] = teams;
    });

    setTeamsByConference(newTeamsByConference);
  }, [stats, info]);

  return (
    <>
      {info?.conferences?.map((conference, index) => {
        const conferenceTitle = conference?.title;
        const teams = teamsByConference[conferenceTitle] || [];

        return (
          <Table
            columns={getSeasonTableColumns(conferenceTitle)}
            sortDirections={['descend', 'ascend']}
            pagination={false}
            dataSource={prepareTeams(teams)}
            size="small"
            className="ratingsection mb-4"
            key={`${index}-${conferenceTitle}`}
          />
        );
      })}
    </>
  );
};
