import { get } from "redux/services/Api";
import types from "../actionTypes";

export const getEliminationLeaders = (gid: number) => {
  return async (dispatch: any, getState: any) => {

    try {
      dispatch({
        type: types.GET_ELIMINATION_LEADERS_REQUEST,
        payload: {},
      });

      const res: any = await get(
        `/engine/games/elimination/${gid}/eliminationLeaders`,
      );

      dispatch({
        type: types.GET_ELIMINATION_LEADERS_SUCCESS,
        payload: { eliminationLeaders: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ELIMINATION_LEADERS_FAIL,
        error: error,
      });
    }
  };
};