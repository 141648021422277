import FadeInWhenVisible from "components/FadeInWhenVisible/FadeInWhenVisible";
import { Link } from "react-router-dom";

type Props = {
  id: number;
  image: string;
  title: string;
  subtitle: string;
  overrideLink?: string;
};

export const HomeNewsItem: React.FC<Props> = ({
  id,
  image,
  title,
  subtitle,
  overrideLink
}) => {
  const link = overrideLink || `/news/${id}`;

  return (
    <FadeInWhenVisible>
      <div className="news-card">
        <Link to={link}>
          <div className="image-container">
            <img src={image} alt="news_img" className="news-image" />
            <div className="gradient-overlay"></div>
          </div>
        </Link>
        <Link
          className="news-card-title d-block mb-3"
          to={link}
        >
          {subtitle}
        </Link>
        <Link to={link} className="news-description-card">
          {title}
        </Link>
      </div>
    </FadeInWhenVisible>
  );
};
