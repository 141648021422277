import { useEffect, useState } from 'react';
import { CountdownTimer } from 'components/Common/Countdown/CountdownTimer';

type Props = {
  startAt: string | Date | undefined;
};

export const ScheduleHeader: React.FC<Props> = ({ startAt }) => {
  const [eliminationSceduleHeader, setEliminationScheduleHeader] = useState<React.ReactElement>(<></>);

  useEffect(() => {
    if (startAt) {
      const diff = Date.parse(new Date(startAt).toString()) - Date.now();
      if (diff > 0) {
        if (diff < 1800000) {
          setEliminationScheduleHeader(
            <>
              <span>GAME WILL START IN </span>
              <CountdownTimer targetDate={new Date(startAt)} />
            </>,
          );
        } else {
          setEliminationScheduleHeader(
            <>
              <span>GAME WILL START AT </span>
              {new Date(startAt).toLocaleString()}
            </>,
          );
        }
        const i = setTimeout(() => {
          setEliminationScheduleHeader(<>ELIMINATION SCHEDULE</>);
        }, diff);

        return () => {
          clearTimeout(i);
        };
      } else {
        setEliminationScheduleHeader(<>ELIMINATION SCHEDULE</>);
      }
    }
  }, [startAt]);

  return eliminationSceduleHeader;
};
