import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';
export interface Props {
  data?: any;
  team?: any;
}

const Team1Receiving = ({ data , team }: Props) =>{
    const scoreColumns:any = [
        {
          title: "",
          dataIndex: "title",
          fixed: "left",
          width: 150,
        },
        {
          title: "CAT",
          dataIndex: "car",
        },
        {
          title: "YDS",
          dataIndex: "yds",
        },
        {
          title: "AVG",
          dataIndex: "avg",
        },
        {
          title: "TD",
          dataIndex: "td",
        },
        {
          title: "FUM",
          dataIndex: "fum",
        },
    ];
    let tablefooter:any={
      key: 800,
      title: "TEAM",
      car: team.stat.rec || team.stat.rec === 0 ? team.stat.rec :"---",
      yds: team.stat.recYds || team.stat.recYds === 0 ? team.stat.recYds :"---",
      avg: (team.stat.recYds && team.stat.rec)? team.stat.recYds/ team.stat.rec:"---",
      td: team.stat.recTD || team.stat.recTD === 0 ? team.stat.recTD :"---",
      fum: team.stat.fmb || team.stat.fmb === 0? team.stat.fmb :"---",
      tavg: 0,

    };
    const scoreData =data?.map((d: any,index:any) => {
      const info = {
        key: index,
        title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)} >{d.name ? d.name : "---"}</span></>,
        car: d.stat.rec || d.stat.rec === 0 ? d.stat.rec :"---",
        yds: d.stat.recYds || d.stat.recYds === 0 ? d.stat.recYds :"---",
        avg: (d.stat.recYds && d.stat.rec)? d.stat.recYds/ d.stat.rec:"---",
        td: d.stat.recTD || d.stat.recTD === 0 ? d.stat.recTD :"---",
        fum: d.stat.fmb || d.stat.fmb === 0? d.stat.fmb :"---",
    }
    tablefooter.tavg += (info.avg==="---"?0:1);
    info.avg = (info.avg==="---"?"---":Number(info.avg).toFixed(2))
    return info;
    })
    if(Array.isArray(scoreData) && scoreData.length){
      tablefooter.avg=(tablefooter.avg/tablefooter.tavg).toFixed(2)
      scoreData.push(tablefooter)
    }

    return(
        <>
            <div className="scorecommontable standingmain mt-3" >
                <Title text="RECEIVING" />
                <Table
                    columns={scoreColumns}
                    pagination={false}
                    dataSource={scoreData}
                    size="small"
                    scroll={{ x: 500 }}
                />
            </div>
        </>
    );
}

export default Team1Receiving;