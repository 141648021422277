import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect, useMemo, useState } from "react";
import { API_URL } from "../../../../config";
import helmetBlue from "../../../../assets/images/helmet/helmetBlue.png";
import helmetRed from "../../../../assets/images/helmet/helmetRed.png";
import { clearInterval, setInterval, setTimeout } from "worker-timers";
import {
  PregameStatus,
  readyGamePlay,
} from "../../../../redux/actions/GameEngineAction";
import history from "../../../../routes/history";
import { fetchAllSelectedAvatar } from "../../../../redux/actions/TeamAction";
import _ from "lodash";
import { getStringDate } from "helper/helper";
import { InGameButton } from "./In-GameButton";

export enum STATUS {
  LETS_PLAY = "LET'S PLAY",
  NOT_READY = "NOT READY",
  READY = "READY",
}

const useScoring = (gid: number) => {
  const [scoring, setScoring] = useState<any>([]);

  function retrievePlayByPlay() {
    const url = `${API_URL}/engine/games/h2h/${gid}/playByPlay`;

    fetch(url).then((result: any) => {
      return result.json().then((res: any) => {
        if (!res?.scoring) {
          return setTimeout(retrievePlayByPlay, 5_000);
        }

        return setScoring(res?.scoring);
      });
    }).catch((e) => {
      console.log('Error in retrievePlayByPlay', e);
    });
  }

  useEffect(() => {
    retrievePlayByPlay();
  }, []);

  return scoring;
};

export const useOverviewRating = ({
  gid,
  isNotRefreshable = false,
}: {
  gid: number;
  isNotRefreshable?: boolean;
}): { isInProgress: boolean; isCompleted: boolean; data: any[] } => {
  const dispatch = useDispatch();

  const pregameOverview = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview
  );

  const teamsList = useSelector(
    (state: RootState) => state?.team?.userTeamsList
  );
  const avatarsList = useSelector(
    (state: RootState) => state?.team?.fetchedAllAvatar
  );
  const teamHelmetData = useSelector(
    (state: RootState) => state?.team?.avatar?.helmet
  );
  const helmetLogos = useSelector(
    (state: RootState) => state?.team?.avatar?.logo
  );

  const currentTeams = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview?.teams
  );
  const scoring = useScoring(gid);

  const [isInProgress, setIsInProgress] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [buttonStates, setButtonStates] = useState([false, false]);

  const leftTeamData = teamsList?.filter(
    (data: any) => data?.engId === currentTeams?.[0]?.utid
  )[0];

  const rightTeamData = teamsList?.filter(
    (data: any) => data?.engId === currentTeams?.[1]?.utid
  )[0];

  const currentFranchiseId = useMemo(() => {
    const currentFranchise = teamsList?.find(
      (team: any) =>
        team?.engId === leftTeamData?.engId ||
        team?.engId === rightTeamData?.engId
    );
    if (currentFranchise) {
      return currentFranchise.engId;
    }

    return -1;
  }, [teamsList, rightTeamData, leftTeamData]);

  const [leftUserInitialState, setFirstUserInitialState] = useState<any>();
  const [rightUserInitialState, setSecondUserInitialState] = useState<any>();
  // const [intervalId, setIntervalId] = useState<number | null>(null);
  const [wasReadyClick, setWasReadyClick] = useState(false);

  useEffect(() => {
    dispatch(PregameStatus(gid));
    if (!isNotRefreshable) {
      const i = setInterval(() => {
        dispatch(PregameStatus(gid));
      }, 10000);
      return () => {
        clearInterval(i);
      };
    }
  }, []);

  useEffect(() => {
    const [
      { ready: isLeftUserReady = false } = {},
      { ready: isRightUserReady = false } = {},
    ] = (pregameOverview && pregameOverview.ready) || [];

    if (currentTeams?.[0]?.utid === currentFranchiseId) {
      setSecondUserInitialState(STATUS.NOT_READY);
      if (isLeftUserReady) {
        setFirstUserInitialState(STATUS.READY);
        setButtonStates([true, true]);
      } else {
        setFirstUserInitialState(STATUS.LETS_PLAY);
        setButtonStates([false, true]);
      }

      if (isRightUserReady) {
        setSecondUserInitialState(STATUS.READY);
      }
    } else if (currentTeams?.[1]?.utid === currentFranchiseId) {
      setFirstUserInitialState(STATUS.NOT_READY);
      if (isRightUserReady) {
        setSecondUserInitialState(STATUS.READY);
        setButtonStates([true, true]);
      } else {
        setSecondUserInitialState(STATUS.LETS_PLAY);
        setButtonStates([true, false]);
      }

      if (isLeftUserReady) {
        setFirstUserInitialState(STATUS.READY);
      }
    }
  }, [pregameOverview, currentTeams, currentFranchiseId]);

  useEffect(() => {
    if (
      _.isEmpty(pregameOverview?.end) ||
      pregameOverview?.secondsRemaining === null
    ) {
      return;
    }

    const pregameEnd = pregameOverview?.end;
    const currentTime = new Date();

    const isPregameInProgress = pregameOverview?.status === "inProgress";
    const isPregameCompleted = pregameOverview?.status === "complete";
    const isPregameOver =
      currentTime > pregameEnd || pregameOverview?.secondsRemaining <= 0;

    if (isPregameInProgress || (isPregameOver && !isPregameCompleted)) {
      setIsInProgress(() => true);
    } else {
      setIsInProgress(() => false);
    }

    if (pregameOverview?.status === "pregame") {
      setIsCompleted(() => false);
    }
    if (pregameOverview?.status === "complete") {
      setIsCompleted(() => true);
      setIsInProgress(() => false);
    }
  }, [
    pregameOverview,
    pregameOverview?.status,
    pregameOverview?.end,
    pregameOverview?.secondsRemaining,
  ]);

  useEffect(() => {
    const leftUserState = pregameOverview?.ready?.[0]?.ready;
    const rightUserState = pregameOverview?.ready?.[1]?.ready;
    if (leftUserState === true && rightUserState === true) {
      // if (intervalId != null) {
      // 	clearInterval(intervalId);
      // }
      if (wasReadyClick) {
        history.push(`/gameplay?id=${gid}`);
      }
    }
  }, [pregameOverview]);

  const isUserPlayingWithHimself: boolean = useMemo(() => {
    if (teamsList && teamsList?.length && pregameOverview && pregameOverview?.teams) {
      const userTeamIds = teamsList.map((team: any) => team?.engId).filter((d: any) => !!d);
      const gameOverviewTeamIds = [pregameOverview.teams[0].utid, pregameOverview.teams[1].utid].filter((d: any) => !!d);
      if (gameOverviewTeamIds.every((element: any) => userTeamIds.includes(element))) {
        return true;
      }
    }
    return false;
  } , [pregameOverview, teamsList]);

  const userReadyHandler = async () => {
    if (isUserPlayingWithHimself) {
      dispatch(readyGamePlay([currentTeams?.[0]?.utid, currentTeams?.[1]?.utid], gid));
      setWasReadyClick(true);
    } else {
      setWasReadyClick(true);
      if (currentTeams?.[0]?.utid === currentFranchiseId) {
        setButtonStates((prev) => [true, prev[1]]);
        setFirstUserInitialState(STATUS.READY);
        dispatch(readyGamePlay(currentTeams?.[0]?.utid, gid));
      } else {
        setSecondUserInitialState(STATUS.READY);
        setButtonStates((prev) => [prev[0], true]);
        dispatch(readyGamePlay(currentTeams?.[1]?.utid, gid));
      }
    }
  };

  const leftTeamHelmetId = useMemo(
    () =>
      avatarsList?.filter((data: any) => {
        return data?.engId === currentTeams?.[0]?.utid;
      }),
    [pregameOverview, avatarsList, currentTeams]
  );
  const rightTeamHelmetId = useMemo(
    () =>
      avatarsList?.filter((data: any) => {
        return data?.engId === currentTeams?.[1]?.utid;
      }),
    [pregameOverview, avatarsList, currentTeams]
  );

  useEffect(() => {
    if (
      (!leftTeamHelmetId?.length || !rightTeamHelmetId?.length) &&
      currentTeams
    ) {
      dispatch(
        fetchAllSelectedAvatar([
          currentTeams?.[0]?.utid,
          currentTeams?.[1]?.utid,
        ])
      );
    }
  }, [pregameOverview]);

  const leftTeamLogo = useMemo(
    () =>
      helmetLogos?.filter((data: any) => {
        return data?.id === leftTeamHelmetId?.[0]?.logo;
      }),
    [helmetLogos, leftTeamHelmetId]
  );

  const rightTeamLogo = useMemo(
    () =>
      helmetLogos?.filter((data: any) => {
        return data?.id === rightTeamHelmetId?.[0]?.logo;
      }),
    [helmetLogos, rightTeamHelmetId]
  );

  const leftTeamHelmet = useMemo(
    () =>
      teamHelmetData?.filter((data: any) => {
        return data?.id === leftTeamHelmetId?.[0]?.helmet;
      }),
    [teamHelmetData, leftTeamHelmetId]
  );

  const rightTeamHelmet = useMemo(
    () =>
      teamHelmetData?.filter((data: any) => {
        return data?.id === rightTeamHelmetId?.[0]?.helmet;
      }),
    [rightTeamHelmetId, teamHelmetData]
  );

  const date = new Date().toLocaleDateString();
  const Time = new Date().toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });

  const myTeamData = !!currentTeams 
  ? (currentTeams[0].utid === currentFranchiseId ? currentTeams[0] : currentTeams[1])
  : null;

  const depthChartSettingName = myTeamData?.isDepthsSame ? 'Default' : InGameButton({ gid, utid: myTeamData?.utid, depthOrPlan: 'depth' });
  const depthChartSettingDate = myTeamData?.depthUpdatedAt ? getStringDate(new Date(myTeamData?.depthUpdatedAt)) : 'NEVER';
  const gamePlanSettingName = myTeamData?.isGamePlansSame ? 'Default' : InGameButton({ gid, utid: myTeamData?.utid, depthOrPlan: 'plan' });
  const gamePlanSettingDate = myTeamData?.gamePlanUpdatedAt ? getStringDate(new Date(myTeamData?.gamePlanUpdatedAt)) : 'NEVER';

  const data = useMemo(
    () => [
      {
        id: 1,
        leftHelmet: `${API_URL}${leftTeamHelmet?.[0]?.url}` ?? helmetBlue,
        rightHelmet: `${API_URL}${rightTeamHelmet?.[0]?.url}` ?? helmetRed,
        leftHelmetLogo: `${API_URL}${leftTeamLogo?.[0]?.url}` ?? "",
        rightHelmetLogo: `${API_URL}${rightTeamLogo?.[0]?.url}` ?? "",
        leftFranchiseName: currentTeams?.[0]?.name ?? "FRANCHISE NAME",
        rightFranchiseName: currentTeams?.[1]?.name ?? "FRANCHISE NAME",
        leftTeamRating: currentTeams?.[0]?.ovr ?? "52",
        rightTeamRating: currentTeams?.[1]?.ovr ?? "52",
        leftNftImage: currentTeams?.[0]?.avatar,
        rightNftImage: currentTeams?.[1]?.avatar,
        remainingTime:
          pregameOverview?.secondsRemaining >= 0
            ? pregameOverview?.secondsRemaining
            : 60 * 10, // 10min
        team1q1: scoring?.teams?.[0]?.stat?.ptsQtrs?.[0] ?? 0,
        team1q2: scoring?.teams?.[0]?.stat?.ptsQtrs?.[1] ?? 0,
        team1q3: scoring?.teams?.[0]?.stat?.ptsQtrs?.[2] ?? 0,
        team1q4: scoring?.teams?.[0]?.stat?.ptsQtrs?.[3] ?? 0,
        team1t: scoring?.teams?.[0]?.stat?.pts ?? 0,
        team2q1: scoring?.teams?.[1]?.stat?.ptsQtrs?.[0] ?? 0,
        team2q2: scoring?.teams?.[1]?.stat?.ptsQtrs?.[1] ?? 0,
        team2q3: scoring?.teams?.[1]?.stat?.ptsQtrs?.[2] ?? 0,
        team2q4: scoring?.teams?.[1]?.stat?.ptsQtrs?.[3] ?? 0,
        team2t: scoring?.teams?.[1]?.stat?.pts ?? 0,
        gameName: `GAME ${gid} | ${date}  | ${Time} `,
        settingname: depthChartSettingName,
        updatedate: `Updated: ${depthChartSettingDate}`,
        setting2name: gamePlanSettingName,
        update2date: `Updated: ${gamePlanSettingDate}`,
        offensivePlanName: myTeamData?.offensiveGamePlan,
        defensivePlanName: myTeamData?.defensiveGamePlan,
        leftUserState: leftUserInitialState,
        rightUserState: rightUserInitialState,
        leftButtonState: buttonStates?.[0],
        rightButtonState: buttonStates?.[1],
        userReadyHandler,
        currentTeams,
        GID: gid,
        isUserPlayingWithHimself
      },
    ],
    [
      pregameOverview,
      currentTeams,
      scoring,
      gid,
      leftUserInitialState,
      rightUserInitialState,
      buttonStates,
      userReadyHandler,
      isUserPlayingWithHimself
    ]
  );

  return { isInProgress, isCompleted, data };
};
