import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllLeagueAPI } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";

export const useAllGamesData = () => {
  const dispatch = useDispatch();

  const gamesData = useSelector(
    (state: RootState) => state.gameEngine.AllLeagueAPI
  );

  useEffect(() => {
    dispatch(AllLeagueAPI());
  }, []);

  return gamesData;
}