import { Table } from "antd";
import history from "../../routes/history";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { formatKeyWithSpaces } from "./utils/formatKey";
import { transformObject } from "./utils/transformObject";
import { alignAllColumnsToCenter } from "components/FranchiseOverview/TabDetail/DepthChartTab/helper";

export type RecentGame = {
  date: string;
  stats: Record<string, number | null>;
  lostPts: number;
  wonPts: number;
  isWinner: boolean;
  gid: number;
  teams: [number, number];
};

type Props = {
  recentGames: RecentGame[];
};

const PlayerCareerRecentGame: React.FC<Props> = ({ recentGames }) => {
  const userTeamList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );
  if (!recentGames.length) return null;
  const activeLeaguesColumns = [
    {
      title: "",
      dataIndex: "title",
      width: 250,
    },
    ...Object.keys(recentGames[0].stats).map((key) => ({
      title: formatKeyWithSpaces(key),
      dataIndex: key,
    })),
  ];
  const activeLeaguesData = recentGames.map((game, index) => {
    const franchiseId = userTeamList?.find(
      (team: any) =>
        team?.engId === game?.teams[0] || team?.engId === game?.teams[1]
    )?.id;

    return {
       key: index,
       title: (
          <div>
             <span>{new Date(game?.date || 0).toLocaleDateString()}</span>
             &nbsp;|&nbsp;
             <span className="bold">{game?.isWinner ? "W" : "L"}</span>{" "}
             <span>
                {game?.isWinner
                   ? `${game?.wonPts || 0}-${game?.lostPts || 0}`
                   : `${game?.lostPts || 0}-${game?.wonPts || 0}`}
             </span>
             &nbsp;|&nbsp;
             {game?.gid ? (
                <span
                   className="seasonLink"
                   onClick={() => history.push("/stand", { gid: game?.gid, id: franchiseId })}
                   style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                   {game?.gid}
                </span>
             ) : (
                <span>N/A</span>
             )}
          </div>
       ),
       ...transformObject(game.stats, (value) => (
          <span>{isNaN(parseInt(value)) ? value : parseInt(value).toFixed(0)}</span>
       )),
    };
  });
  return (
    <div className="standingmain" style={{ marginBottom: 0 }}>
      <Table
        prefixCls="career-stats-table ant-table"
        columns={alignAllColumnsToCenter(activeLeaguesColumns)}
        pagination={false}
        dataSource={activeLeaguesData}
        size="small"
      />
    </div>
  );
};

export default PlayerCareerRecentGame;
