import { PlayersStockReportPlayer } from 'types';
import { getPositionFullName } from 'helper/helper';
import './PlayersStockReport.scss';

type Props = {
  data: PlayersStockReportPlayer;
  index: number;
};

const PlayersStockReportPlayerItem: React.FC<Props> = ({ data, index }) => {
  const diff = `${data.diff > 0 ? '+' : ''}${data.diff}`;
  return (
    <div className="players-stock-report-item">
      <div className="leader-list-row">
        <div className="leader-list-row-order">{index}.</div>
        <div className="leader-list-row-name">
          <a href={`/playerdetail/${data.pid}`}>{`${data.firstName} ${data.lastName}`}</a>
        </div>
        <div className="leader-list-row-role">{getPositionFullName(data.pos)}</div>
        <div className="leader-list-row-points">{diff}</div>
      </div>
    </div>
  );
};

export default PlayersStockReportPlayerItem;
