import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import AboutusMain from "../../components/Aboutus/AboutusMain";

const AboutScreen = () =>{
    return(
        <>
            <div className="pagelayout">
                <AboutusMain />
                <WithLoginNavbar />
            </div>
        </>
    );
}

export default AboutScreen;