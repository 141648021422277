import StatsFranchisePerformance from 'components/FranchiseOverview/TabDetail/StatsTab/StatsFranchisePerformance';
import StatsTeamLeader from 'components/FranchiseOverview/TabDetail/StatsTab/StatsTeamLeader';
import FranchiseCareerRating from './FranchiseCareerRating';
import OverviewLeagueTrophies from 'components/FranchiseOverview/TabDetail/OverviewTab/OverviewLeagueTrophies';
import { TeamTendencies } from './TeamTendencies/TeamTendencies';

type Props = {
  engId: number;
  withRating?: boolean;
};

export const OverviewTab: React.FC<Props> = ({ engId, withRating = true }) => (
  <>
    <StatsFranchisePerformance engId={engId} />
    <OverviewLeagueTrophies engId={engId} />
    <StatsTeamLeader engId={engId} />
    <TeamTendencies engId={engId} />
    {withRating && <FranchiseCareerRating />}
  </>
);
