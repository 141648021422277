import { useEffect, useState } from "react";
import "./ViewPopover.scss";
import { getTeamOwner } from "helper/helper";
import { Loading } from "../LoadingScreen/Loading";
import history from "routes/history";

type Props = {
  utid: number;
  teamName: string;
};

export const ViewPopoverContent: React.FC<Props> = ({
  utid,
  teamName,
}) => {
  const [ownerData, setOwnerData] = useState<{
    userName: string;
    userId: number;
  } | null>(null);

  useEffect(() => {
    getTeamOwner(utid)
      .then((data) => {
        if (JSON.stringify(data) !== "{}") {
          //@ts-ignore
          setOwnerData(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [utid]);

  if (!ownerData) {
    return <Loading additionCN="view-popover-loader" />;
  }

  return (
    <div className="view-popover-content">
      <div
        className="link-like"
        onClick={() => history.push(`/profile/${ownerData.userId}`)}
      >
        View {ownerData.userName}'s Profile
      </div>
      <div
        className="link-like"
        onClick={() => history.push(`/franchisedetail/${utid}`)}
      >
        View {teamName} Details
      </div>
    </div>
  );
};
