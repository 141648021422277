export const offensiveColumns = [
  {
    title: 'OFFENSIVE FORMATIONS',
    dataIndex: 'name',
  },
  {
    title: '',
    dataIndex: 'value',
  },
];

export const defensiveColumns = [
  {
    title: 'DEFENSIVE FORMATIONS',
    dataIndex: 'name',
  },
  {
    title: '',
    dataIndex: 'value',
  },
];

export const gameScriptsColumns = [
  {
    title: 'GAME SCRIPTS',
    dataIndex: 'name',
  },
  {
    title: '',
    dataIndex: 'value',
  },
];
