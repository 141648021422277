import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanPlayerLeaderTypeData,
  getPlayerLeaders,
  setPlayerLeaderType,
} from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import Title from "components/Common/Title/Title";
import { LeaderboardLeadersBySkill } from "./LeaderboardLeadersBySkill";
import { Modal } from "antd";
import { useViewMorePlayers } from "./hooks";
import { LeaderboardPlayerLeaderList } from "./LeaderboardPlayerLeaderList";
import { Row } from "react-bootstrap";

export interface Player {
  firstName: string;
  lastName: string;
  position: string;
  teamName: string;
  utid: number;
  pid: number;
  value: number;
}

export enum SkillEnum {
  PASSING_YARDS = "pssYds",
  PARRING_TDS = "prTD",
  RUSHING_YARDS = "rusYds",
  RUSHING_TDS = "rusTD",
  RECEIVING = "recYds",
  RECEIVING_TDS = "recTD",
  TACKLES = "tgt",
  INTERCEPTIONS = "defInt",
  SACKS = "gs",
  TACKLES_FOR_LOSS = "defTckLoss",
}

export function LeaderboardPlayersLeaders() {
  const dispatch = useDispatch();
  const leaders = useSelector(
    (state: RootState) => state.gameEngine.player_leaders
  );

  const { playersType, playersData } = useViewMorePlayers();

  useEffect(() => {
    dispatch(getPlayerLeaders());
  }, []);

  return (
    <>
      <Title text="PLAYERS LEADERS" />
      {leaders && (
        <Row>
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.PASSING_YARDS] ?? []}
              skill={SkillEnum.PASSING_YARDS}
              title={"Passing yards"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.PARRING_TDS] ?? []}
              skill={SkillEnum.PARRING_TDS}
              title={"Total Passing TDs"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.RUSHING_YARDS] ?? []}
              skill={SkillEnum.RUSHING_YARDS}
              title={"Rushing yards"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.RUSHING_TDS] ?? []}
              skill={SkillEnum.RUSHING_TDS}
              title={"Rushing TDs"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.RECEIVING] ?? []}
              skill={SkillEnum.RECEIVING}
              title={"Receiving yards"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.RECEIVING_TDS] ?? []}
              skill={SkillEnum.RECEIVING_TDS}
              title={"Receiving TDs"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.TACKLES] ?? []}
              skill={SkillEnum.TACKLES}
              title={"Tackles"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.INTERCEPTIONS] ?? []}
              skill={SkillEnum.INTERCEPTIONS}
              title={"Interceptions"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.SACKS] ?? []}
              skill={SkillEnum.SACKS}
              title={"Sacks"}
            />
            <LeaderboardLeadersBySkill
              players={leaders[SkillEnum.TACKLES_FOR_LOSS] ?? []}
              skill={SkillEnum.TACKLES_FOR_LOSS}
              title={"Tackles for loss"}
            />
        </Row>
      )}
      <Modal
        open={!!playersType}
        footer={null}
        onCancel={() => {
          dispatch(setPlayerLeaderType(null));
          dispatch(cleanPlayerLeaderTypeData());
        }}
        className="view-more-players-modal"
        width={680}
        centered={true}
      >
        <div className="scroll-container">
          {playersData && <LeaderboardPlayerLeaderList players={playersData} />}
        </div>
      </Modal>
    </>
  );
}

export function getFullNameOfPlayer(player: any): string {
  return `${player?.firstName} ${player?.lastName}`?.toUpperCase();
}
