import { Emoji, EmojiPath, emojiPartualPaths } from "./Emoji";

type Props = {
  onClick: (emoji: string) => void;
}

export const EmojiList: React.FC<Props> = ({ onClick }) => (
  <div style={{ maxWidth: "228px"}}>
    {emojiPartualPaths.map((emoji, index) => (
      <span
        key={index}
        role="img"
        aria-label="smile"
        className="emoji-picker-item"
        onClick={() => onClick(emoji)}
      >
        <Emoji path={emoji as EmojiPath}/>
      </span>
    ))}
  </div>
);