import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EliminationActions } from 'redux/actions/Elimination';
import { RootState } from 'redux/store';

export const useEliminationPowerRankings = (gid: number) => {
  const dispatch = useDispatch();
  const eliminationPowerRankings = useSelector((state: RootState) => state.gameEngine.powerRankings);

  useEffect(() => {
    dispatch(EliminationActions.getEliminationPowerRankings(gid));
  }, [gid]);

  return eliminationPowerRankings;
};
