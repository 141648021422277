import LeagueHeader from 'components/Common/LeagueHeader/LeagueHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { UserTeamsList } from 'types';
import { useSeasonInfo } from './hooks/useSeasonInfo';
import { shortenString } from 'helper/helper';

type Props = {
  gid: string;
};

export const SeasonHeader: React.FC<Props> = ({ gid }) => {
  const teamList: UserTeamsList | null | undefined = useSelector((state: RootState) => state.team.userTeamsList);
  const { seasonInfo } = useSeasonInfo(gid);

  if (!teamList || !seasonInfo) return null;

  const utids = seasonInfo.conferences.reduce((acc, conf) => {
    const divisions = conf.divisions.reduce((accDiv, div) => {
      const teams = div.teams.map((team) => team.team?.utid).filter(Boolean) as unknown as number[];
      accDiv.push(...teams);
      return accDiv;
    }, [] as number[]);

    acc.push(...divisions);
    return acc;
  }, [] as number[]);

  const league = {
    ...seasonInfo,
    id: shortenString(gid, 18),
    started: seasonInfo?.startAt,
    gameTime: new Date(),
    type: "S" as "S"
  };

  const franchise = teamList.find((team) => utids.includes(team.engId));

  return <LeagueHeader league={league} franchise={franchise} />;
};
