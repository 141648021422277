import LeagueOverviewLeader from '../../../Common/LeagueOverviewTab/LeagueOverviewLeader';

export interface Props {
  data?: any;
}

const LeagueOverviewTeamLeaders: React.FC<any> = ({ data }) => (
  <div className="teamleadermain">
    <LeagueOverviewLeader {...data} />
  </div>
);

export default LeagueOverviewTeamLeaders;
