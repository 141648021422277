import "./DraftScreenStyle.scss";
import DraftMain from "../../components/Draft/DraftMain";

const DraftScreen = () =>{
    return(
        <>
            <div className="pagelayout" >
                <DraftMain />
            </div>
        </>
    );
}

export default DraftScreen;