import OwnFranchiseHeader from 'components/OwnFranchise/OwnFranchiseHeader';
import { Container } from 'react-bootstrap';
import Card from 'antd/lib/card/Card';
import CardBody from 'components/Common/Card/CardBody';
import Title from 'components/Common/Title/Title';
import { MarketplaceTeams } from './MarketplaceTeams/MarketplaceTeams';

export const MarketplaceMain: React.FC = () => (
  <Container>
    <OwnFranchiseHeader />
    <Card>
      <CardBody>
        <Title text="TEAMS" />
        <MarketplaceTeams />
      </CardBody>
    </Card>
  </Container>
);
