import "./LeagueOverviewStyle.scss";
import Title from "../Title/Title";
import { useEliminationResults } from "./hooks/useEliminationResults";
import { LeagueOverviewResultsItem } from "./LeagueOverviewResultsItem";

type Props = {
  gid: number;
}

const LeagueOverviewResults: React.FC<Props> = ({ gid }) => {
  const results = useEliminationResults(gid);

  return (
    <>
      <div className="resulttitle">
        <Title text="league results" />
      </div>
      {(!!results?.data && !!results?.data?.length) && (
        results?.data.map(game => (
          <LeagueOverviewResultsItem game={game}/>
        ))
      )}
    </>
  );
};

export default LeagueOverviewResults;
