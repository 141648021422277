import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { redirectOnGameEnd } from "../helper/RedirectOnGameEnd";
import { useDispatch, useSelector } from "react-redux";
import { getInviteCreditsPromoAmounts } from "redux/actions/AppAction";
import { InvitePromoCreditsAmounts } from "redux/reducers/AppReducer";

export interface GameEndProps {
  gameOverview: any;
  franchise: any;
  queryStringGameId: any;
}

export function useInviteFriendPopup() {
  const [inviteFriendPopUp, setInviteFriendPopup] = useState(false);
  const history = useHistory();
  const inviteCreditsPromo = useSelector(
    (state: any) => state.app.invitePromoCreditsAmounts
  ) as InvitePromoCreditsAmounts | null;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInviteCreditsPromoAmounts());
  }, []);

  function handleCancelInvitePopup(params: GameEndProps) {
    redirectOnGameEnd({ ...params, history });
    handleInvitePopupClose();
  }

  function handleInvitePopupClose() {
    setInviteFriendPopup(false);
  }

  function handleOpenInvitePopup() {
    setInviteFriendPopup(true);
  }

  return {
    inviteFriendPopUp,
    handleCancelInvitePopup,
    handleOpenInvitePopup,
    inviteCreditsPromo,
  };
}
