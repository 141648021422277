import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EliminationActions } from "redux/actions/Elimination";
import { RootState } from "redux/store";

export const useEliminationOverview = (gid?: number) => {
  const dispatch = useDispatch();
  const eliminationData = useSelector(
    (state: RootState) => state.gameEngine.eliminationOverview
  );

  useEffect(() => {
    if (gid) {
      dispatch(EliminationActions.eliminationOverview(gid));
    }
  }, [gid]);

  return eliminationData;
};
