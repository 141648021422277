import { ViewMoreStringTypes } from "./LeaderboardContent";
import { LeaderboardTeamLeadersList } from "./LeaderboardTeamLeadersList";
import { useWeeklyLeadersRatings } from "./hooks";

export const LeaderboardWeeklyLeadersRatings = () => {
  const { weeklyLeadersRatings } = useWeeklyLeadersRatings();

  if (!weeklyLeadersRatings) {
    return <div>Sorry, there are no data</div>;
  }

  return (
    <LeaderboardTeamLeadersList
      leadersList={weeklyLeadersRatings}
      viewMoreType={ViewMoreStringTypes["WEEKLY-LEADERS-RATINGS"]}
    />
  );
};
