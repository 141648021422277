import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { validEmail, validName } from "../../../helper/helper";
import moment from "moment/moment";
import {
  checkEmailOrUsernameAvailable,
  signupAndLogin,
} from "../../../redux/actions/AuthAction";
import { Col, Row } from "react-bootstrap";
import { Form, Modal } from "antd";
import SignupScreenFirstStep from "../../../screens/SignupScreen/SignupScreenFirstStep";
import PinkButton from "../Button/PinkButton/PinkButton";
import SignupScreenThirdStep from "../../../screens/SignupScreen/SignupScreenThirdStep";
import SignupScreenSecondStep from "../../../screens/SignupScreen/SignupScreenSecondStep";
import "./SignUpStepFormModal.scss";
import BlueButton from "../Button/BlueButton/BlueButton";
import UnderageModal from "../UnderageModal/UnderageModal";
import WhiteButton from "../Button/WhiteButton/WhiteButton";
import { debounce } from "lodash";
import { useInviteFriendPopup } from "components/GamePlay/hooks/useInviteFriendPopup";
import { useInvitationInfo } from "helper/useInvitationInfo";
import { useAnalyticsEventTracker } from "../GoogleAnalytics/useAnalyticsEventTracker";
import { ContinueSignUp } from "./types";
import history from "routes/history";
import { LogIn } from "../LogIn/LogIn";

type Props = {
  msg: string;
  setMsg: (value: string) => void;
  currentStep: number;
  setCurrentStep: (value: any) => void;
};

const SignUpStepFormModal: React.FC<Props> = ({
  msg,
  setMsg,
  currentStep,
  setCurrentStep,
}) => {
  const dispatch = useDispatch();
  const { inviteCode, inviteType } = useInvitationInfo();
  const gaEventTracker = useAnalyticsEventTracker("SIGN UP");

  const signupError = useSelector((state: RootState) => state.app.signError);
  const isSuccess = useSelector((state: RootState) => state.app.success);
  const metaMaskData = useSelector(
    (state: RootState) => state.app.metaMaskData
  );

  const [isAgeModalVisible, setIsAgeModalVisible] = useState(false);
  const [year, setYear] = useState(1990);
  const [month, setMonth] = useState<number | null>();
  const [date, setDate] = useState<number | null>();
  const [userAge, setUserAge] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reenteredPassword, setReenteredPassword] = useState("");
  const [datePicker, setDatePicker] = useState(
    new Date(`01-01-${new Date().getFullYear() - 20}`)
  );

  const [validationFinished, setValidationFinished] = useState(false);
  const [isEmailValidating, setIsEmailValidating] = useState(false);
  const [isUsernameValidating, setIsUsernameValidating] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState<boolean | null>(null);
  const [usernameAvailable, setUsernameAvailable] = useState<boolean | null>(
    null
  );
  //@ts-ignore
  const existingData: ContinueSignUp | null = history?.location?.state?.continueSignUp || null;

  useEffect(() => {
    if (existingData) {
      setCurrentStep(0);
      setUsername(existingData.userName);
      setEmail(existingData.email);
    }
  }, [existingData]);

  useEffect(() => {
    signupError && setMsg(signupError);
  }, [signupError]);

  useEffect(() => {
    typeof isSuccess === 'string' && setMsg(isSuccess);
    // setCurrentStep(3);
  }, [isSuccess]);

  useEffect(() => {
    if (currentStep == 0) {
      setMsg("");
      if (!usernameAvailable && username) {
        setMsg("Username is already taken");
      } else if (!emailAvailable && email) {
        setMsg("Email is already taken");
      }
    }
  }, [emailAvailable, usernameAvailable]);

  useEffect(() => {
    setValidationFinished(false);
    if (!!email.length) {
      setIsEmailValidating(true);
    }

    debouncedValidationEmail();
    return () => debouncedValidationEmail.cancel();
  }, [email]);

  useEffect(() => {
    setValidationFinished(false);
    if (!!username.length) {
      setIsUsernameValidating(true);
    }
    debouncedValidationUserName();
    return () => debouncedValidationUserName.cancel();
  }, [username]);

  useEffect(() => {
    if (
      year &&
      month &&
      date &&
      country &&
      username &&
      email &&
      password &&
      reenteredPassword
    ) {
      if (password !== reenteredPassword) {
        setMsg("Passwords do not match");
      } else {
        setMsg("");
      }
    }
  }, [
    year,
    month,
    date,
    country,
    username,
    email,
    password,
    reenteredPassword,
  ]);

  const validateEmail = async () => {
    try {
      if (email.length > 0) {
        const emailResp = await checkEmailOrUsernameAvailable(email);

        setEmailAvailable(emailResp.success);
        setValidationFinished(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsEmailValidating(false);
    }
  };

  const validateUsername = async () => {
    try {
      if (username.length > 0) {
        setIsUsernameValidating(true);

        const usernameResp = await checkEmailOrUsernameAvailable(username);

        setUsernameAvailable(usernameResp.success);
        setValidationFinished(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUsernameValidating(false);
    }
  };

  const debouncedValidationEmail = debounce(validateEmail, 2000);
  const debouncedValidationUserName = debounce(validateUsername, 2000);

  const handleAgeModalOk = () => {
    setIsAgeModalVisible(false);
  };

  const handleAgeModalCancel = () => {
    setIsAgeModalVisible(false);
  };

  const handleNextStep = () => {
    if (currentStep === 0) {
      if (username.length === 0) {
        setMsg("Username is required");
        return;
      }

      if (!validName.test(username)) {
        setMsg("Only letters and numbers are permitted");
        return;
      }

      if (!validEmail.test(email)) {
        setMsg("Please enter a valid email");
        return;
      }

      if (!usernameAvailable) {
        setMsg("Username is already taken");
        return;
      }

      if (!emailAvailable) {
        setMsg("Email is already taken");
        return;
      }

      if (!validationFinished) {
        return;
      }
    } else if (currentStep === 1) {
      if (
        !year ||
        !month ||
        !date /* ||
        !country*/
      ) {
        setMsg("All fields are required");
        return;
      }
      const userAge = moment(`${year}-${month}-${date}`, "YYYY-M-D");

      const eighteenYearsAgo = moment().subtract(18, "year");
      if (userAge.isAfter(eighteenYearsAgo)) {
        setIsAgeModalVisible(true);
        return;
      }
      setUserAge(userAge.format("YYYY-MM-DD"));
    }
    setMsg("");
    setCurrentStep((prevState: number) => prevState + 1);
  };

  const submitHandler = async () => {
    if (password !== reenteredPassword) {
      setMsg("Passwords do not match");
      return;
    }

    const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegEx.test(password)) {
      setMsg(
        // "Password must be 8 characters, use upper/lower and include a special character or number"
        "Password must be at least 8 characters long and contain at least one letter or number"
      );
      return;
    }

    if (
      !userAge ||
      // !country ||
      !username ||
      !email ||
      !password
    ) {
      setMsg("All fields are required");
      return;
    }

    setMsg("");

    gaEventTracker("SIGN UP button click");
    const img = existingData?.avatar;

    dispatch(
      signupAndLogin({
        userName: username,
        email,
        password,
        metaMask: metaMaskData?.[0],
        birthDate: userAge,
        country,
        inviteCode: inviteCode || undefined,
        inviteType,
        img
      })
    );
  };

  const { inviteCreditsPromo } = useInviteFriendPopup();

  const showCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <SignupScreenFirstStep
              username={username}
              setUsername={setUsername}
              email={email}
              setEmail={setEmail}
              usernameLoader={isUsernameValidating}
              emailLoader={isEmailValidating}
            />
            <p className="error">{msg}</p>
            <Col xl={12} className="formControls">
              <div className="controls-btn">
                <PinkButton
                  text="NEXT"
                  onClick={handleNextStep}
                  disabled={
                    (currentStep === 0 && !validationFinished) ||
                    isEmailValidating ||
                    isUsernameValidating
                  }
                />
              </div>
              <a href="#" onClick={() => setCurrentStep(3)}>
                ALREADY HAVE AN ACCOUNT?
              </a>
            </Col>
          </>
        );
      case 1:
        return (
          <>
            <SignupScreenSecondStep
              month={month}
              setMonth={setMonth}
              year={year}
              setYear={setYear}
              setDate={setDate}
              date={date}
              setDatePicker={setDatePicker}
              datePicker={datePicker}
              setCountry={setCountry}
              country={country}
            />
            <p className="error">{msg}</p>
            <Col xl={12} className="formControls">
              <Row xl={12} className="controls-buttons">
                <div className="controls-btn">
                  <PinkButton text="NEXT" onClick={handleNextStep} />
                </div>
                <div className="controls-btn">
                  <WhiteButton
                    text="BACK"
                    onClick={() => {
                      setMsg("");
                      setCurrentStep(0);
                    }}
                  />
                </div>
              </Row>
              <a href="" onClick={() => setCurrentStep(3)}>
                ALREADY HAVE AN ACCOUNT?
              </a>
            </Col>
          </>
        );
      case 2:
        return (
          <>
            <SignupScreenThirdStep
              password={password}
              setReenteredPassword={setReenteredPassword}
              setPassword={setPassword}
              reenteredPassword={reenteredPassword}
              isFromModal={true}
            />
            <p className="error">{msg}</p>
            <Col xl={12} className="formControls">
              <Row xl={12} className="controls-buttons">
                <div className="controls-btn">
                  <PinkButton text="SIGN UP" onClick={submitHandler} />
                </div>
                <div className="controls-btn">
                  <WhiteButton
                    text="BACK"
                    onClick={() => {
                      setMsg("");
                      setCurrentStep(1);
                    }}
                  />
                </div>
              </Row>
              <a href="#" onClick={() => setCurrentStep(3)}>
                ALREADY HAVE AN ACCOUNT?
              </a>
            </Col>
          </>
        );
    }
  };

  return (
    <>
      {/* {!!loading && <LoadingScreen />} */}
      <div className="sign-up-form-modal">
        {currentStep !== 3 && (
          <>
            <div className="step-indicator">
              <div
                className={`step-indicator-item ${
                  currentStep === 0 ? "active" : "inactive"
                }`}
              />
              <div
                className={`step-indicator-item ${
                  currentStep === 1 ? "active" : "inactive"
                }`}
              />
              <div
                className={`step-indicator-item ${
                  currentStep === 2 ? "active" : "inactive"
                }`}
              />
            </div>
            <h5>ACQUIRE A FRANCHISE</h5>
            <p>
              <span>
                {currentStep === 0
                  ? "YEAR-ROUND FOOTBALL GAMING ACTION AWAITS!"
                  : currentStep === 1
                  ? "CONNECT, COMPETE AND WIN PRIZES!"
                  : "EXPERIENCE  OWNING & MANAGING A TEAM!"}
              </span>
              {/* NEW OWNERS <span>GET A {inviteCreditsPromo?.receiverCredits}$ ACCOUNT CREDIT</span> */}
            </p>
          </>
        )}
        {currentStep === 3 && <h2>FRANCHISE OWNERS: SIGN-IN</h2>}
        <div className="signupForm signinForm">
          <Form layout="vertical">
            <Row>
              {currentStep < 3 && showCurrentStep()}
              {currentStep === 3 && <LogIn redirectToSignUp={() => setCurrentStep(0)}/>}
            </Row>
          </Form>
        </div>
      </div>
      <Modal
        open={isAgeModalVisible}
        onOk={handleAgeModalOk}
        onCancel={handleAgeModalCancel}
        key={"AgeModal"}
        footer={[
          <p className="didNot" key="back"></p>,
          <BlueButton onClick={handleAgeModalCancel} text="DONE" />,
        ]}
        centered
      >
        <UnderageModal />
      </Modal>
    </>
  );
};

export default SignUpStepFormModal;
