import Profile from "../../assets/images/common/userName.png";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { metaMaskWalletId } from "../../helper/helper";
import moment from "moment";
import LeagueDetailPurchaseFranchise from "../Common/LeagueDetail/LeagueDetailPurchaseFranchise";
import { BASE_URL } from "../../config";

const NoFranchisesHeader = () => {
  const resData = useSelector((state: RootState) => state.app.resData);
  let protfolioName = resData?.userName ? resData?.userName : "---";
  let metaMaskID = resData?.metaMaskID
    ? metaMaskWalletId(resData?.metaMaskID)?.toUpperCase()
    : "----";
  let email = resData?.email ? resData?.email : "----";
  let joined = resData?.createdAt
    ? moment(resData?.createdAt).format("L")
    : "----";

  const data1 = [
    {
      id: resData?.id,
      profileimage: resData?.avatar
        ? `${BASE_URL}/images/avatar/${resData?.avatar}`
        : Profile,
      protfolioname: protfolioName,
      usertitle: "USER NAME:",
      username: protfolioName,
      currentleaguetitle: "CURRENT LEAGUE:",
      currentleague: "0",
      joinedtitle: "JOINED:",
      joined: joined,
      franchisestitle: "NO. OF FRANCHISES:",
      franchises: "0",
      wallettitle: "WALLET:",
      wallet: metaMaskID,
      mintedplayertitle: "MINTED PLAYERS:",
      mintedplayer: "0",
      emailtite: "EMAIL:",
      email: email,
      fundsplaytitle: "FUNDS-IN-PLAY:",
      fundsplay: "0",
      win: "TOTAL WINS",
      windigit: "---",
      winpercentage: "WIN %",
      winpercentagedigit: "---",
      champs: "TITLES",
      champsdigit: "---",
    },
  ];
  return (
    <>
      {data1.map((d: any, i: any) => (
        <LeagueDetailPurchaseFranchise data={d} key={i} />
      ))}
    </>
  );
};

export default NoFranchisesHeader;
