import image from "../../../assets/images/homeScreen/franchiseBuild.gif";

const FranchiseSalesModal: React.FC = () => {
  return (
    <div className="startdraftmain">
      <h5>START BUILDING YOUR FOOTBALL DYNASTY</h5>
      <h4 className="mb-4">TIME TO CUSTOMIZE YOUR TEAM</h4>
      <div className="message-and-image-container">
        <img src={image} alt="customize-gif" />
        <p>
          Congratulations! As a first-time owner your initial team is FREE!
          Simply follow the easy customization process and begin competing.
          Additional teams can be acquired at a minimal cost.
        </p>
      </div>
    </div>
  );
};

export default FranchiseSalesModal;
