import Title from "components/Common/Title/Title"
import { LeaderboardTeamLeadersList, TeamLeaderItem } from "./LeaderboardTeamLeadersList"
import { forwardRef } from "react"

type Props = {
  viewMoreData: TeamLeaderItem[],
  viewMoreLoader: boolean,
  noNextPage: boolean,
}

export const ModalContainer = forwardRef<HTMLDivElement, Props>(({ 
  viewMoreData,
  viewMoreLoader,
  noNextPage,
},
containerRef
) => {

  if (!viewMoreData) {
    return (
      <Title text="Sorry, there are no data"/>
    )
  }
  
  return (
    <div className="scroll-container" ref={containerRef}>
      <div style={{width: "540px", marginLeft: "20px"}}>
        <LeaderboardTeamLeadersList leadersList={viewMoreData}/>
        {viewMoreLoader && <Title text="Loading..." />}
        {noNextPage && <Title text="No more data available" />}
      </div>
    </div>
  )
})