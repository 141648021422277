import { Col, Row } from "react-bootstrap";

type Props = {
  handleClick: (e: any) => void;
  modalData: any;
};

export const ColorSelectorDialog: React.FC<Props> = ({
  handleClick,
  modalData,
}) => {
  return (
    <div className="verificationContent choose-color" style={{ maxWidth: "340px"}}>
      <h5>{modalData?.title}</h5>
      <Row className="justify-content-center">
        {modalData?.data?.map((e: any) => {
          let bg: any = `#${e.name}`;
          return (
            <Col
              xl={6}
              className="colorfill"
              style={{
                background: bg,
              }}
              onClick={() => handleClick(e)}
            ></Col>
          );
        })}
      </Row>
    </div>
  );
};
