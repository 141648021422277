import GamePlayer from 'components/GamePlay/GamePlayer';
import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import { useParams } from 'react-router-dom';

const Replay: React.FC = () => {
  const { gid }: any = useParams();

  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      {gid && <GamePlayer gameId={+gid} replayMode />}
    </div>
  );
};

export default Replay;
