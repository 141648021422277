import { GroupFormContainer } from "components/Group";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export function CreateGroupScreen() {
  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      <GroupFormContainer />
    </div>
  );
}
