import { post } from "redux/services/Api";

export type LobbyGroup = {
  name: string;
  avatar: string;
  memberCount: number;
  totalPrestigePoints: number | string | null;
  id: number;
}

export const getLobbyGroups = async(page = 1, limit = 5) => {
  try {
    return (await post<{ data: LobbyGroup[]}>("/lobby/getGroups", { page, limit })).data;
  } catch (e) {
    return [];
  }
}