/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Title from "../Common/Title/Title";
import { Divider, Table } from "antd";

const DraftProspect = () => {
  const offensiveLineColumns = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "RANKS",
      dataIndex: "ranks",
    },
    {
      title: "POS",
      dataIndex: "pos",
    },
    {
      title: "COLLEGE",
      dataIndex: "college",
    },
    {
      title: "HEIGHT",
      dataIndex: "height",
    },
    {
      title: "WEIGHT",
      dataIndex: "weight",
    },
    {
      title: "GRADE",
      dataIndex: "grade",
    },
  ];
  const offensiveLinekData = [
    {
      key: "1",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "2",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "3",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "4",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "5",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "6",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "7",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "8",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "9",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
    {
      key: "10",
      title: "PAT PETERS",
      ranks: "1",
      pos: "RB",
      college: "MINNESOTA",
      height: "6'1",
      weight: "234",
      grade: "64",
    },
  ];
  return (
    <>
      <div className="standingmain mt-4">
        <Title text="PROSPECTS" />
        <Table
          columns={offensiveLineColumns}
          pagination={false}
          dataSource={offensiveLinekData}
          size="small"
        />
      </div>
    </>
  );
};

export default DraftProspect;
