import { Spin } from "antd";
import "./BlueButtonStyle.scss";
import React from "react";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: any;
  onClick?: any;
  disabled?: any;
  isLoading?: boolean;
  icon?: React.ReactNode;
}

const BlueButton = ({
  text,
  onClick,
  disabled,
  isLoading,
  icon,
  ...props
}: Props) => {
  return (
    <>
      <button
        {...props}
        className={`blueButton ${props.className || ""}`}
        onClick={onClick}
        disabled={disabled}
      >
        <div>
          {icon && <p className="icon-wrapper">{icon}</p>}
          {isLoading ? <Spin size="small" /> : text}
        </div>
      </button>
    </>
  );
};

export default BlueButton;
