import { useState } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { logout } from 'redux/actions/AuthAction';
import { useDispatch } from 'react-redux';

export const useDeleteAccount = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteAccountRequest = async () => {
    setIsLoading(true);
    const res = await axios.delete<{ message?: string }>('/user/delete-account');
    setIsLoading(false);
    if (res.data.message) {
      notification.success({
        message: res.data.message,
      });
    } else {
      notification.error({
        message: 'Something went wrong',
      });
    }
    dispatch(logout());
  };

  return { isLoading, handleDeleteAccountRequest };
};
