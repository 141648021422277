import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FoundUser } from 'types/groups';
import { useDebouncedValue } from 'helper/useDebouncedValue';
import './UserSearch.style.scss';
import { DefaultInviteUserList, DefaultUserSearchInput } from './DefaultUsersSearch';

interface UserSearchTemplateProps<T extends FoundUser> {
  users: T[];
  renderInput: {
    value: string;
    onChange: (value: string) => void;
    render?: () => React.ReactElement;
  };
  renderUsersList: {
    users: T[];
    onInviteClick: (userId: number) => void;
    invitedIds: number[];
    renderUsersListLabel?: () => React.ReactElement;
    render?: () => React.ReactElement;
  };
}
interface UseSearchUsers<T extends FoundUser> {
  users: T[];
  handleSearch: (value: string) => void;
}

interface RenderUserSearchProps<T extends FoundUser> {
  users: T[];
  onChange: (value: string) => void;
  value: string;
}
interface UserSearchProps<T extends FoundUser> {
  useSearchUsers: () => UseSearchUsers<T>;
  renderUsersSearch: (props: RenderUserSearchProps<T>) => React.ReactElement;
}

const UserSearch = <T extends FoundUser>({ useSearchUsers, renderUsersSearch }: UserSearchProps<T>) => {
  const [userNameString, setUserNameString] = useState('');
  const debouncedSearch = useDebouncedValue(userNameString, 300);
  const { users, handleSearch } = useSearchUsers();

  useEffect(() => {
    handleSearch(debouncedSearch);
  }, [debouncedSearch]);

  return renderUsersSearch({ users, onChange: setUserNameString, value: userNameString });
};

const UserSearchTemplate = <T extends FoundUser>({
  users,
  renderInput: { 
    render: renderInputUI = () => <DefaultUserSearchInput {...props} />, 
    ...props 
  },
  renderUsersList: { 
    render: renderUsersLisUI = () => <DefaultInviteUserList {...rest} />, 
    ...rest
  },
}: UserSearchTemplateProps<T>) => {
  const membersListClasses = users && users.length ? ['search_user__list', 'search_user__list--active'] : ['search_user__list'];
  return (
    <Row>
      <Col md={12}>{renderInputUI()}</Col>
      <Col md={12} className="search_user__list_container mb-3">
        <Col xs={12} className={membersListClasses.join(' ')}>
          {renderUsersLisUI()}
        </Col>
      </Col>
    </Row>
  );
};

export { type UseSearchUsers, UserSearch, UserSearchTemplate, type UserSearchTemplateProps };
