const GET_INVITED_GAMES_REQUEST = "GET_INVITED_GAMES_REQUEST";
const GET_INVITED_GAMES_FAIL = "GET_INVITED_GAMES_FAIL";
const GET_INVITED_GAMES_SUCCESS = "GET_INVITED_GAMES_SUCCESS";
const GET_INVITED_NUMBER_GAMES_SUCCESS = "GET_INVITED_NUMBER_GAMES_SUCCESS";

export default {
  GET_INVITED_GAMES_REQUEST,
  GET_INVITED_GAMES_SUCCESS,
  GET_INVITED_NUMBER_GAMES_SUCCESS,
  GET_INVITED_GAMES_FAIL,
};
