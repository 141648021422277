import "./WhiteButtonStyle.scss";

export interface Props{
    text?: any;
    onClick?: any;
    disabled?: boolean;
}

const WhiteButton: React.FC<Props> = ({text , onClick, disabled }) => (
    <button 
        className="whiteButton" 
        onClick={onClick} 
        disabled={!!disabled}
    >
        {text}
    </button>
)

export default WhiteButton;