import { useSelector } from "react-redux";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import LeagueOverviewEliminationMain from "../../components/LeagueOverviewElimination/LeagueOverviewEliminationMain";
import { RootState } from "redux/store";
import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";

const LeagueOverviewEliminationScreen: React.FC = () => {
  const loader = useSelector((state: RootState) => state.gameEngine.loader);

  return (
    <>
      {!!loader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <LeagueOverviewEliminationMain />
      </div>
    </>
  );
};

export default LeagueOverviewEliminationScreen;
