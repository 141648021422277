import lbhlogo from "../../assets/images/aboutus/lbhlogo.png";

const AboutusHeader = () =>{
    return(
        <>
            <div className="aboutus_header" >
                <img src={lbhlogo} alt="lbhlogo" />
                <p>Diversify Games is an independent game developer focused on the emerging segment of play-to-earn games.  We recognize 
                that the gaming ecosystem is changing rapidly and that offering ownership over in-game assets is the new requirement. 
                Join us, as we build a stronger gaming community and help diversify your gaming portfolio. 
                And let’s have fun along the way!</p>
            </div>
        </>
    );
}

export default AboutusHeader;