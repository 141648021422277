import "./LeagueStandingScreenStyle.scss";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import LeagueStandingMain from "../../components/LeagueStanding/LeagueStandingMain";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import LoadingScreen from "./../../components/Common/LoadingScreen/LoadingScreen";

const LeagueStandingScreen: React.FC = () => {
  const gameEngineLoader = useSelector((state: RootState) => state.gameEngine.loader);
  const teamLoader = useSelector((state: RootState) => state.team.loader);

  const loader = (!!gameEngineLoader || !!teamLoader);

  return (
    <div className="pagelayout">
      {!!loader && <LoadingScreen />}
      <WithLoginNavbar />
      <LeagueStandingMain />
    </div>
  );
};

export default LeagueStandingScreen;
