import { API_URL } from "config";
import { get } from "redux/services/Api";

export const getPath = async(trophyId: number, mode: "full" | "cup") => {
  try {
    const trophyPath: { path: string } | undefined = await get(`${API_URL}/user/get-trophy-image/${trophyId}?mode=${mode}`);
    if (!trophyPath) return null;

    if (mode === "full") {
      return `${API_URL}/images/trophyAssets/userTrophies/${trophyPath.path}`;
    }
    if (mode === "cup") {
      return `${API_URL}/images/trophyAssets/${trophyPath.path}`;
    }

    return null;
  } catch (e) {
    console.error('Error getting trophy path', e);
    return null;
  }
};
