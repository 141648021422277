/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Stickers from "../../../assets/images/league/leagueImage.jpg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import moment from "moment";
import FranchiseHeaderDetail from "./FranchiseHeaderDetail";
import { useEffect, useMemo, useState } from "react";
import { API_URL } from "../../../config";
import { getTeamLeaguesHistory } from "../../../redux/actions/GameEngineAction";
import { useCityList } from "helper/useCityList";

type Props = {
  franchise?: {
    id?: number;
    utid?: number;
    engId?: number;
  };

  arrow?: 'up' | 'down';
}

const FranchiseHeader = ({ franchise: propsFranchise, arrow }: Props) => {
  const dispatch = useDispatch();
  const { franchiseId }: any = useParams();
  const [franchise, setFranchise] = useState<any>(null);
  
  const userTeamList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const resData = useSelector((state: RootState) => state.app.resData);
  const cityList = useCityList();

  useEffect(() => {
    if (propsFranchise?.utid) {
      setFranchise(userTeamList?.find((info: any) => info.engId == propsFranchise?.utid));
    } else if (propsFranchise?.engId) {
      setFranchise(userTeamList?.find((info: any) => info.engId == propsFranchise?.engId));
    } else if (propsFranchise?.id) {
      setFranchise(userTeamList?.find((info: any) => info.id == propsFranchise?.id));
    } else if (franchiseId) {
      setFranchise(userTeamList?.find((info: any) => info.id == franchiseId));
    }
  }, [franchiseId, propsFranchise, userTeamList])

  const teamData = useSelector((state: RootState) => state.gameEngine.teamLeaguesHistory);
  
  const arrowDirection = useMemo(() => {
    if (teamData) {
      const ratingHistory = teamData?.ratingHistory || [];
      const len = ratingHistory.length;
      for (let i = len - 1; i > 0; i--) {
        if (ratingHistory[i]?.rating > ratingHistory[i - 1]?.rating) {
          return 'up';
        }
        if (ratingHistory[i] < ratingHistory[i - 1]) {
          return 'down';
        }
      }
    }
  }, [teamData]);

  useEffect(() => {
    if (franchise) {
      dispatch(getTeamLeaguesHistory(franchise?.engId));
    }
  }, [franchise]);

  const franchiseImage = useMemo(() => 
    franchise?.img ? `${API_URL}/images/franchise/userFranchise/${franchise?.img}` : Stickers
  , [franchise]);

  const data = useMemo(() => ([
    {
      franchiseId,
      ownername: resData?.userName || "N/A",
      profileimage: franchiseImage,
      teamName: franchise?.teamName || "N/A",
      founded: franchise?.created
        ? moment(franchise?.created).format("L")
        : "N/A",
      city: cityList?.find((data: any) => data?.id == franchise?.city)?.name || "N/A",
      titles: 0, // ToDo
      currentLeague: 0, // ToDo
      mintedPlayers: 0, // ToDo
      walletBalance: resData?.walletBalance ?? 0,
      winnings: '$0.00', // ToDo
      teamWinningPercentage: teamData?.winRate ?? "N/A",
      teamFanBase: franchise?.teamRating ?? 'N/A',
      teamRating: teamData?.ovr ?? franchise?.teamRating ?? "N/A",
    },
  ]), [franchise, teamData]);

  return (
    <>
      {data.map((d: any, i: any) => (
        <FranchiseHeaderDetail data={d} key={i} arrow={arrowDirection} />
      ))}
    </>
  );
};

export default FranchiseHeader;
