import './PlayersStockReport.scss';
import PlayersStockReportPlayerItem from './PlayersStockReportPlayerItem';
import { PlayersStockReportPlayer } from 'types';
import PlayersStockReportPlayerLeader from './PlayersStockReportPlayerLeader';
const d = {
  franchiseId: '2017',
  leagueperson: 'Bryce Webb',
  leagueimage: '/static/media/RB.a2bd4fc99111764f61c2.png',
  leaguename: 'RUSHING',
  leaguedigit: 48.2,
  leaguedigitname: 'YPG',
  id: 554708819,
};

type Props = {
  data: PlayersStockReportPlayer[]
}

const PlayersStockReportGroup: React.FC<Props> = ({ data }) => {
  return (
    <>
      <PlayersStockReportPlayerLeader data={data[0]} />
      {data.map((player, index) => (
        index === 0 ? null :
        <PlayersStockReportPlayerItem key={player.pid} data={player} index={index} /> 
      ))
      }
    </>
  );
};

export default PlayersStockReportGroup;
