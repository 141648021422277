import { ActiveGame } from "types";

export type MappedGameItem = {
  pregame?: ActiveGame[];
  pending?: ActiveGame[];
  inProgress?: ActiveGame[];
};

type MappedGames = {
  H: MappedGameItem;
  E: MappedGameItem;
  S: MappedGameItem;
};

export const prepareActiveGames = (activeGames: ActiveGame[]) => {
  return activeGames.reduce(
    (acc: MappedGames, g) => {
      if (g.type === 'H') {
        if (g.status === 'pregame') {
          return {
            ...acc,
            H: {
              ...acc.H,
              pregame: [...(acc.H.pregame || []), g],
            },
          };
        }

        if (g.status === '') {
          return {
            ...acc,
            H: {
              ...acc.H,
              pending: [...(acc.H.pending || []), g],
            },
          };
        }

        if (g.status === 'inProgress') {
          return {
            ...acc,
            H: {
              ...acc.H,
              inProgress: [...(acc.H.inProgress || []), g],
            },
          };
        }
      } else if (g.type === 'E') {
        if (['waitingForStart', 'waitingForPlayers'].includes(g.status)) {
          return {
            ...acc,
            E: {
              ...acc.E,
              pending: [...(acc.E.pending || []), g],
            },
          };
        }

        return {
          ...acc,
          E: {
            ...acc.E,
            inProgress: [...(acc.E.inProgress || []), g],
          },
        };
      } else if (g.type === 'S') {
        if (g.status === 'pending') {
          return {
            ...acc,
            S: {
              ...acc.S,
              pending: [...(acc.S.pending || []), g],
            },
          };
        }

        return {
          ...acc,
          S: {
            ...acc.S,
            inProgress: [...(acc.S.inProgress || []), g],
          },
        };
      }

      return acc;
    },
    {
      H: {} as MappedGameItem,
      E: {} as MappedGameItem,
      S: {} as MappedGameItem,
    },
  );
}