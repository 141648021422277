import { Modal } from "antd";

type Props = {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}

export const LeaveGameModal: React.FC<Props> = ({ open, onCancel, onOk }) => (
  <Modal
    title="Confirm your action"
    open={open}
    onCancel={onCancel}
    onOk={onOk}
    centered
    width="400px"
    okText="LEAVE"
    cancelText="CANCEL"
    className="leave-game-modal"
  >
    <div className="mb-3 font18">
      <div className="mb-3">
        If you leave this game, money spent to enter it will be refunded,
        but the fee or promo funds spent will be lost. 
      </div>
      <div>
        Are you sure you want to leave the game?
      </div>
    </div>
  </Modal>
);