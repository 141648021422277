import { Modal } from "antd";
import GamePlanMain from "components/FranchiseOverview/TabDetail/GamePlan/GamePlanMain";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getTeamSpecificGamePlan,
  putTeamGameSpecificPlan,
} from "redux/actions/GameEngineAction";
import { TeamGamePlan } from "types";
import styles from "./ChangeStrategyModal.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
  gid: number;
  utid: number;
};

export const ChangeStrategyModal: React.FC<Props> = ({
  open,
  onClose,
  utid,
  gid,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamSpecificGamePlan(utid, gid));
  }, [open, utid, gid]);

  const updateGamePlan = (gamePlanSchemes: TeamGamePlan["schemes"]) => {
    dispatch(putTeamGameSpecificPlan(gid, utid, gamePlanSchemes));
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null} width={"60vw"}>
      <h4 className={styles.text}>
        <strong>CHANGE STRATEGY</strong>
      </h4>
      <p className={styles.text}>
        Now, between quarters, customize your team's lineup to adapt to changing
        game conditions and outmaneuver your opponents. Select from a range of
        formations to keep them guessing and secure victory! You have two minutes to do that.
      </p>
      <GamePlanMain updateGamePlan={updateGamePlan} />
    </Modal>
  );
};
