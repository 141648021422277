/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from "react-bootstrap";
import "./SettingStyle.scss";
// import SettingHeader from "./SettingHeader";
import SettingTab from "./SettingTab";
import OwnFranchiseHeader from "../OwnFranchise/OwnFranchiseHeader";
import LoadingScreen from "../Common/LoadingScreen/LoadingScreen";
import { useSettingsMainLoading } from "./hooks/use-settings-main-loading";

const SettingMain: React.FC = () => {
  const isLoading = useSettingsMainLoading()

  return (
    <>
      {isLoading && <LoadingScreen />}
      <Container>
        <div className="">
          {/* <SettingHeader />
           */}
          <OwnFranchiseHeader />
          <SettingTab />
        </div>
      </Container>
    </>
  );
};

export default SettingMain;
