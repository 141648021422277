import { Table } from "antd";
import { getTableValue, isNum } from "helper/helper";

type Props = {
  teamStat?: any;
};

const teamstatColumns = [
  {
    title: "SEASON",
    dataIndex: "leftscore",
  },
  {
    title: "AVERAGE PER GAME",
    dataIndex: "position",
  },
  {
    title: "HISTORY",
    dataIndex: "rightscore",
  },
];

const FullTeamAvarageGame: React.FC<Props> = ({ teamStat }) => {
  const teamstatData = [
    {
      key: "7",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Total Plays</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.totalPlays)}</span>
      ),
    },
    {
      key: "8",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Total Yards</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.totalYds)}</span>
      ),
    },
    {
      key: "9",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Total Drives</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.drives)}</span>
      ),
    },
    {
      key: "10",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Yards per Play</span>,
      rightscore: (
        <span className="weight">
          {isNum(teamStat?.totalYds) && isNum(teamStat?.totalPlays)
            ? (teamStat.totalYds / teamStat.totalPlays).toFixed(2)
            : "-"}
        </span>
      ),
    },
    {
      key: "11",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Passing</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.pssYds)}</span>
      ),
    },
    {
      key: "12",
      leftscore: "-",
      position: "Comp-Att",
      rightscore:
        isNum(teamStat?.pss) && isNum(teamStat?.pssCmp)
          ? `${teamStat.pssCmp.toFixed(2)}-${teamStat.pss.toFixed(2)}`
          : "-",
    },
    {
      key: "13",
      leftscore: "-",
      position: "Yards per pass",
      rightscore:
        isNum(teamStat?.pssYds) && isNum(teamStat?.pss)
          ? (teamStat.pssYds / teamStat.pss).toFixed(2)
          : "-",
    },
    {
      key: "14",
      leftscore: "-",
      position: "Interceptions thrown",
      rightscore: getTableValue(teamStat?.pssInt),
    },
    {
      key: "15",
      leftscore: "-",
      position: "Sacks-Yards Lost",
      rightscore:
        isNum(teamStat?.pssSk) && isNum(teamStat?.pssSkYds)
          ? `${teamStat?.pssSk.toFixed(2)}-${teamStat?.pssSkYds.toFixed(2)}`
          : "-",
    },
    {
      key: "16",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Rushing</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.rusYds)}</span>
      ),
    },
    {
      key: "17",
      leftscore: "-",
      position: "Rushing Attempts",
      rightscore: getTableValue(teamStat?.rus),
    },
    {
      key: "18",
      leftscore: "-",
      position: "Yards per rush",
      rightscore: getTableValue(teamStat?.rusYds),
    },
    {
      key: "20",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Penalties</span>,
      rightscore: (
        <span className="weight">
          {isNum(teamStat?.pen) && isNum(teamStat?.penYds)
            ? `${teamStat?.pen.toFixed(2)}-${teamStat?.penYds.toFixed(2)}`
            : "-"}
        </span>
      ),
    },
    {
      key: "21",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Turnovers</span>,
      rightscore: (
        <span className="weight">
          {isNum(teamStat?.fmbLost) && isNum(teamStat?.pssInt)
            ? (teamStat.fmbLost + teamStat.pssInt).toFixed(2)
            : "-"}
        </span>
      ),
    },
    {
      key: "22",
      leftscore: "-",
      position: "Fumbles lost",
      rightscore: getTableValue(teamStat?.fmbLost),
    },
    {
      key: "23",
      leftscore: "-",
      position: "Interceptions thrown",
      rightscore: getTableValue(teamStat?.pssInt),
    },
    {
      key: "24",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Defensive / Special Teams TDs</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.stdefTD)}</span>
      ),
    },
    {
      key: "25",
      leftscore: <span className="weight">-</span>,
      position: <span className="weight">Possession</span>,
      rightscore: (
        <span className="weight">{getTableValue(teamStat?.timePos)}</span>
      ),
    },
  ];

  return (
    <Table
      columns={teamstatColumns}
      pagination={false}
      dataSource={teamstatData}
      size="small"
      className="teamstattable averagepergame"
      tableLayout="fixed"
    />
  );
};

export default FullTeamAvarageGame;
