/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Row, Col } from "react-bootstrap";
//import "./FranchiseDetailStyle.scss";
import "./LeagueDetailStyle.scss";
import { useHistory } from "react-router-dom";

export interface Props {
  data?: any;
  arrow?: any;
  rating?: any;
  teamrating?: any;
}

const LeagueDetailPurchaseFranchise: React.FC<Props> = ({ data }) => {
  const history = useHistory();

  const handleRedirectToProfile = () => {
    history.push(`/profile/${data?.id}`);
  };

  return (
    <div className="franchiseMain">
      <Row>
        <Col xl={2} lg={2}>
          <div className="franchiseProfile">
            <button onClick={handleRedirectToProfile}>
              <img
                src={data.profileimage}
                alt="profile"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                }}
              />
            </button>
          </div>
        </Col>
        <Col xl={6} lg={6}>
          <div className="franchiseDetail">
            <h5 className="c-pointer" onClick={handleRedirectToProfile}>{data.protfolioname}</h5>
            <Row>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.usertitle}</span>
                <span>
                  <button onClick={handleRedirectToProfile}>
                    {data.username}
                  </button>
                </span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.currentleaguetitle}</span>
                <span>{data.currentleague}</span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.joinedtitle}</span>
                <span>{data.joined}</span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.franchisestitle}</span>
                <span>{data.franchises}</span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.wallettitle}</span>
                <span>{data.wallet}</span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.mintedplayertitle}</span>
                <span>{data.mintedplayer}</span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.emailtite}</span>
                <span>
                  <button onClick={handleRedirectToProfile}>
                    {data.email}
                  </button>
                </span>
              </Col>
              <Col xl={6} md={6} className="franchiseDetailContent">
                <span>{data.fundsplaytitle}</span>
                <span>{data.fundsplay}</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={4} lg={4}>
          <div className="franchiseBoxSection">
            <div className="franchiseBoxBack">
              <p>{data.win}</p>
              <h5>{data.windigit}</h5>
            </div>
            <div className="franchiseBoxBack">
              <p>{data.winpercentage}</p>
              <h5>{data.winpercentagedigit}</h5>
            </div>
            <div className="franchiseBoxBack">
              <p>{data.champs}</p>
              <h5>{data.champsdigit}</h5>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LeagueDetailPurchaseFranchise;
