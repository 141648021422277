/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import WithLoginNavbar from "../layout/WithLoginNavbar/WithLoginNavbar";
import DraftResultHead from "./DraftResultHead";
import DraftResultPlayer from "./DraftResultPlayer";
import DraftResultRoster from "./DraftResultRoster";
import DraftResultSquad from "./DraftResultSquad";
import "./DraftResultStyle.scss";
import { Container } from "react-bootstrap";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import Title from "../Common/Title/Title";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import DraftResultModal from "./DraftResultModal";
import { Modal } from "antd";
import { useState } from "react";

const DraftResultMain = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <WithLoginNavbar />
      <Container className="pb-5">
        <DraftResultHead />
        <Card>
          <CardBody>
            <Title text="DRAFT RESULTS" />
            <h5 className="rosterspots">ROSTER SPOTS EXCEEDED BY : 7</h5>
            <DraftResultPlayer />
            <DraftResultRoster />
            <DraftResultSquad />
            <BlueButton onClick={showModal} text="modal" />
          </CardBody>
        </Card>
      </Container>

      {/* DRAFT START */}
      <Modal
        title="Basic Modal"
        className="draftcommon"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}>
        <DraftResultModal />
      </Modal>
    </>
  );
};

export default DraftResultMain;
