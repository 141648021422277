import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { GameAnimationsActions } from "redux/actions/GameAnimations";
import { RootState } from "redux/store";

export const useAnimationNames = () => {
  const dispatch = useDispatch();
  const animationNames: string[] | null = useSelector((state: RootState) => state.app.animationNames);

  useEffect(() => {
    dispatch(GameAnimationsActions.getAllAnimationNames());
  }, []);

  return animationNames || [];
}