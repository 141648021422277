import { RenderHelmet } from "components/Common/RenderHelmet/RenderHelmet";
import { UserTeamsList } from "types"

type Props = {
  selectedTeam?: UserTeamsList[0];
}

export const OpponentTeamInfo: React.FC<Props> = ({
  selectedTeam
}) => {
  if (!selectedTeam) {
    return null;
  }

  return (
    <div className="selected-team-info-container">
      <RenderHelmet
        {...selectedTeam}
        size={256}
      />
      <h4>{selectedTeam.teamName}</h4>
      <div className="selected-team-article">
        <p>
          By <strong>GMDynasty</strong>
          <br/>
          Own a GM Dynasty Franchise and own a piece of the action! 
          All franchises include a full roster of players to get you in the game. 
          Owners can manage their roster, create game-day strategies, and engage in competitive play.
        </p>
      </div>
      <div className="selected-team-article">
        <strong>Stats</strong>
      </div>
      <div className="selected-team-article">
        <div className="selectet-team-article-stat">
          <span>Fan Rating</span>
          <span>{selectedTeam.teamRating}</span>
        </div>
        <div className="selectet-team-article-stat">
          <span>Rating</span>
          <span>{selectedTeam.baseRate}</span>
        </div>
      </div>
    </div>
  )
}