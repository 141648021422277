import { useEffect, useState } from "react";
import { LobbyInProgressGamesResponse, getLobbyInProgressGames } from "../helper";

export const useLobbyInProgressGames = (page: number, limit: number) => {
  const [lobbyInProgressGames, setLobbyInProgressGames] = useState<LobbyInProgressGamesResponse>({
    avatarsList: [],
    games: [],
  } as LobbyInProgressGamesResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [noNextPage, setNoNextPage] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLobbyInProgressGames(page, limit)
      .then((lobbyInProgressGamesRes) => {
        if (!lobbyInProgressGamesRes) return new Error('No games');

        if (lobbyInProgressGamesRes.games.length === 0) {
          setNoNextPage(true);
        } else {
          setNoNextPage(false);
        }

        setLobbyInProgressGames((prev) => {
          const listCopy = {
            avatarsList: [...prev.avatarsList],
            games: [...prev.games],
          };
          for (const game of lobbyInProgressGamesRes.games) {
            if (!prev.games.some((u) => game.gid === u.gid)) listCopy.games.push(game);
          }
          for (const avatar of lobbyInProgressGamesRes.avatarsList) {
            if (!prev.avatarsList.some((a) => avatar.id === a.id)) listCopy.avatarsList.push(avatar);
          }

          return listCopy;
        });
      })
      .catch((e) => { })
      .finally(() => setIsLoading(false));
  }, [page, limit]);

  return { ...lobbyInProgressGames, isLoading, noNextPage };
};
