import { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import history from "../../routes/history";
import "../FranchiseOverview/FranchiseOverviewStyle.scss";

export enum LeagueHeadTabMainMenuTabs {
  "OVERVIEW" = "OVERVIEW",
  "DEPTH-CHART" = "DEPTH-CHART",
  "GAME-PLAN" = "GAME-PLAN",
}

type Props = {
  defaultActiveKey?: LeagueHeadTabMainMenuTabs
}

const LeagueHeadTabMainMenu: React.FC<Props> = ({ defaultActiveKey }) => {
  const historyState: any = history?.location?.state;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");
  const [tabKey, setTabKey] = useState<LeagueHeadTabMainMenuTabs | undefined>(
    validatedTab(tabParam) ?? defaultActiveKey
  );

  function handleTabChange(key: LeagueHeadTabMainMenuTabs) {
    setTabKey(key);
  }

  useEffect(() => {
    if (tabParam && tabParam !== tabKey) {
      setTabKey(validatedTab(tabParam) ?? defaultActiveKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabParam]);


  useEffect(() => {
    if (tabKey) {
      history.replace(`/leagueheadtohead?tab=${tabKey}`, historyState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  return (
    <div className="standingTabbarMain">
      <Tab.Container
        id="left-tabs-example"
        mountOnEnter={true}
        unmountOnExit
        defaultActiveKey={defaultActiveKey}
        activeKey={tabKey}
        onSelect={(key) => handleTabChange((key as LeagueHeadTabMainMenuTabs) || LeagueHeadTabMainMenuTabs.OVERVIEW)}
      >
        <div className="standingTabbar">
          <Card>
            <CardBody>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="OVERVIEW">
                    OVERVIEW
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="DEPTH-CHART">
                    DEPTH CHART
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="GAME-PLAN">
                    GAME PLAN
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </CardBody>
          </Card>
        </div>
      </Tab.Container>
    </div>
  );
};

export function validatedTab(queryTab: string | null): LeagueHeadTabMainMenuTabs | null {
  if (!queryTab) return null;
  const validOptions = Object.values(LeagueHeadTabMainMenuTabs) as string[];
  const isValid = validOptions.includes(queryTab);
  return isValid ? (queryTab as LeagueHeadTabMainMenuTabs) : null;
}

export default LeagueHeadTabMainMenu;
