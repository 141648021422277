import { getSeasonTeams } from './SeasonTeams';
import { getSeasonTeamStats } from './SeasonTeamStats';
import { getSeasonInfo } from './SeasonInfo';
import { getSeasonLeaders } from './SeasonLeaders';
import { getSeasonWeeksWithGames } from './SeasonWeeksWithGames';
import { getSeasonResults } from './SeasonResults';

export default {
  getSeasonTeams,
  getSeasonInfo,
  getSeasonLeaders,
  getSeasonWeeksWithGames,
  getSeasonTeamStats,
  getSeasonResults,
};
