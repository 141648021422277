/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Table } from "antd";
import { Row, Col } from "react-bootstrap";
// import BlueButton from "../../Common/Button/BlueButton/BlueButton";
import logo from "../../../assets/images/setting/logo.png";
import * as htmlToImage from "html-to-image";
import { useState } from "react";

const TransactionSlipModal = ({ receiptData, resData }: any) => {
  const Columns = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "",
      dataIndex: "content",
    },
  ];
  const Data = [
    {
      key: "1",
      title: "RECEIPT TO:",
      content:
        resData?.firstname && resData?.lastname
          ? resData.firstname + " " + resData.lastname
          : resData.userName,
    },
    {
      key: "2",
      title: "USER ID :",
      content: resData?.id,
    },
    {
      key: "3",
      title: "EMAIL :",
      content: resData?.email,
    },
  ];

  // ACCOUNT
  const Account = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "",
      dataIndex: "content",
    },
  ];
  const AccountData = [
    {
      key: "1",
      title: "DATE:",
      content: receiptData?.created.slice(0, 10),
    },
    {
      key: "2",
      title: "TRANSACTION ID :",
      content: receiptData?.gmdTransactionId,
    },
    {
      key: "3",
      title: "WALLET :",
      content: receiptData?.metamaskAccount
        ? "Metamask"
        : receiptData?.emailAddress
        ? "Email"
        : "GMD Wallet",
    },
    {
      key: "3",
      title: "DESCRIPTION :",
      content: receiptData?.orderType,
    },
  ];

  // ACCOUNT1
  const Account1 = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "",
      dataIndex: "content",
    },
  ];
  const Account1Data = [
    {
      key: "1",
      title: "FRANCHISE FEE:",
      content: `$ ${receiptData.amount}`,
    },
    {
      key: "2",
      title: "TOTAL (USD) :",
      content: `$ ${receiptData.amount}`,
    },
  ];

  const captureDiv = document.getElementById(
    "transactionslip_main"
  ) as HTMLDivElement;

  const [pdf, setPdf] = useState();

  async function handlePDF() {
    console.log(
      "🚀 ~ file: TransactionSlipModal.tsx ~ line 104 ~ TransactionSlipModal ~ captureDiv",
      captureDiv
    );
    const pdf1: any = await htmlToImage.toSvg(captureDiv, {
      width: 350,
      height: 800,
      pixelRatio: 1,
    });

    setPdf(pdf1);

    console.log(
      "🚀 ~ file: TransactionSlipModal.tsx ~ line 111 ~ handlePDF ~ pdf",
      pdf
    );
  }

  return (
    <>
      <div id="transactionslip_main">
        <div className="transactionslip_main">
          <Row>
            <Col xl={8}>
              <img src={logo} alt="gmlogo" />
              <Table
                columns={Columns}
                pagination={false}
                dataSource={Data}
                size="small"
                className="depthcharttable"
              />
              <div className="account_info">
                <h5>ACCOUNT INFORMATIONS</h5>
                <Table
                  columns={Account}
                  pagination={false}
                  dataSource={AccountData}
                  size="small"
                  className="depthcharttable"
                />
              </div>
              <div className="account_info">
                <h5>ACCOUNT INFORMATIONS</h5>
                <Table
                  columns={Account1}
                  pagination={false}
                  dataSource={Account1Data}
                  size="small"
                  className="depthcharttable"
                />
              </div>
              {/* <BlueButton text="PRINT" onClick={handlePDF} /> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TransactionSlipModal;
