import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHeadToHeadOverview } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";

export const useH2HOverview = (gid: number) => {
  const dispatch = useDispatch();
  const h2hOverview = useSelector((state: RootState) => state.gameEngine.headToHeadOverview);

  useEffect(() => {
    dispatch(getHeadToHeadOverview(gid));
  }, [gid]);

  return h2hOverview;
};
