import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';
export interface Props {
  data?: any;
  team?: any;
}

const Team1Punting = ({ data , team }: Props) =>{
    const scoreColumns:any = [
        {
          title: "",
          dataIndex: "title",
          fixed: "left",
          width: 150,
        },
        {
          title: "ATT",
          dataIndex: "att",
        },
        {
          title: "YDS",
          dataIndex: "yds",
        },
        {
          title: "AVG",
          dataIndex: "avg",
        },
        // {
        //   title: "AVG R",
        //   dataIndex: "avgr",
        // },
        // {
        //   title: "NET AVG",
        //   dataIndex: "netavg",
        // },
        {
          title: "TB",
          dataIndex: "tb",
        },
    ];
    let tablefooter:any={
      key:500,
      title:"TEAM",
      att: team.stat.pnt || team.stat.pnt === 0 ? team.stat.pnt :"---",
      yds: team.stat.pntYds || team.stat.pntYds === 0 ? team.stat.pntYds :"---",
      avg: (team.stat.pntYds && team.stat.pnt)? team.stat.pntYds/ team.stat.pnt:"---",
      tb: team.stat.pntTB || team.stat.pntTB === 0? team.stat.pntTB :"---",
      tavg:0,
    }
    const scoreData = data?.map((d: any,index:any) => {
      const info = {
        key: index,
        title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)} >{d.name ? d.name : "---"}</span></>,
        att: d.stat.pnt || d.stat.pnt === 0 ? d.stat.pnt :"---",
        yds: d.stat.pntYds || d.stat.pntYds === 0 ? d.stat.pntYds :"---",
        avg: (d.stat.pntYds && d.stat.pnt)? (d.stat.pntYds/ d.stat.pnt).toFixed(2):"---",
        tb: d.stat.pntTB || d.stat.pntTB === 0? d.stat.pntTB :"---",
    }
    tablefooter.tavg += (info.avg==="---"?0:1);
    return info;
    })
    if(Array.isArray(scoreData) && scoreData.length){
      tablefooter.avg=(tablefooter.avg/tablefooter.tavg).toFixed(2)
      scoreData.push(tablefooter)
    }
    return(
        <>
            <div className="scorecommontable standingmain mt-3" >
                <Title text="PUNTING" />
                <Table
                    columns={scoreColumns}
                    pagination={false}
                    dataSource={scoreData}
                    size="small"
                    scroll={{ x: 500 }}
                />
            </div>
        </>
    );
}

export default Team1Punting;