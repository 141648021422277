import { Modal } from "antd";
import "./OffensiveNameModal.scss";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  onChangeName: () => void;
}

export const OffensiveNameModal: React.FC<Props> = ({ 
  open,
  onClose,
  onOk,
  onChangeName
 }) => {
  const title = <h2 className="offensive-name-modal-title error">WARNING</h2>;
  const [isSure, setIsSure] = useState(false);
  const handleClose = () => {
    setIsSure(false);
    onClose();
  }
  
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      title={title}
      className="offensive-name-modal"
    >
      <h4 className="offensive-name-modal-text">
        Your name contains abusive content. We highly recommend changing your Team name. Our platform is a fun, safe environment for everyone. If you continue there is a review process and your team could take up to 48 hours for final approval or denial
      </h4>
      <div className="offensive-name-modal-buttons mb-4">
        <BlueButton 
          text="CHANGE NAME" 
          className="offensive-name-modal-button" 
          onClick={onChangeName}
        />
        <PinkButton 
          text="SUBMIT" 
          className="offensive-name-modal-button" 
          onClick={() => setIsSure(true)}
          disabled={isSure}
        />
      </div>
      {isSure && (
        <>
          <div
            className="offensive-name-modal-title error mb-4"
          >
            You will be subject to a 48 hour waiting period
            and GMDynasty reserves the right to refuse any team name
          </div>
          <div className="offensive-name-modal-buttons">
            <PinkButton text="CONFIRM" onClick={onOk}/>
          </div>
        </>
      )}
    </Modal>
  )
}