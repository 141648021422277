import { useEffect, useState } from 'react';
import { get } from 'redux/services/Api';
import { GameSummaryPbp } from '../Accordian/FullGameSummary/FullGameSummary';
import { GameEvent } from 'components/GamePlay/GamePlayer';

type Summary = Pick<GameEvent, 'quarter' | 'time' | 'text' | 't'>[];

type DriveSummary = {
  summary: Summary;
  playByPlay: GameSummaryPbp;
};

export const useDriveChartData = (gid: number) => {
  const [driveData, setDriveData] = useState<DriveSummary | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await get<DriveSummary>(`/engine/games/h2h/${gid}/driveSummary`);
        if (res) {
          setDriveData(res);
        }
      } catch (e) {}
    })();
  }, [gid]);

  return driveData;
};
