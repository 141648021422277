import { GameEndProps } from "../hooks/useInviteFriendPopup";

export function redirectOnGameEnd({
  gameOverview,
  franchise,
  queryStringGameId,
  history,
}: GameEndProps & { history: any }) {
  if (gameOverview) {
    if (gameOverview?.eliminationId && !franchise?.id) {
      history.push(`/leagueelimination?gid=${gameOverview.eliminationId}`);
    } else {
      history.push(`/stand`, {
        gid: queryStringGameId,
        id: franchise?.id,
      });
    }
  }
}
