import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useFetchGroup } from "./hooks/useFetchGroup";
import { GroupHeader } from "../Common";
import { GroupMain } from "../GroupMain/GroupMain";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";

export function GroupOverview() {
  const { groupId } = useParams<{ groupId: string }>();
  const isGroupLoading = useSelector((state: RootState) => state.group.loaders.getGroup);
  const groupInfo = useSelector((state: RootState) => state.group.groupInfo);
  const isSameGroup = groupInfo && groupInfo.id === Number(groupId);
  useFetchGroup(groupId);

  return (
    <>
      {(!isSameGroup || isGroupLoading) && <LoadingScreen />}
      <Container style={{ paddingTop: "1.5rem", paddingBottom: "4rem" }}>
        {!!groupInfo &&
          <>
            <GroupHeader />
            <GroupMain />
          </>
        }
      </Container>
    </>
  )
}
