import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { useStangingTabs } from './hooks/useStangingTabs';
import { StandingTabs } from './StandingTabs';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';
import { Conference } from './SubTabs/Conference';
import { Division } from './SubTabs/Division';
import { PlayoffBracket } from './SubTabs/PlayoffBracket/PlayoffBracket';

export const Standings: React.FC = () => {
  const { setTab, tab } = useStangingTabs();
  const { seasonInfo } = useSeasonInfo();

  return (
    <Card>
      <CardBody>
        <h5 className="title">LEAGUE STANDINGS</h5>
        <StandingTabs selectedTab={tab} setSelectedTab={setTab} />
        <div className="mb-4"></div>
        {!!seasonInfo && (
          <>
            {tab === 'CONFERENCE' && <Conference info={seasonInfo} />}
            {tab === 'DIVISION' && <Division info={seasonInfo} />}
            {tab === 'PLAYOFF BRACKET' && <PlayoffBracket seasonId={seasonInfo.id}/> } 
          </>
          )}
      </CardBody>
    </Card>
  );
};
