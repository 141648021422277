import { Col } from "react-bootstrap";
import Input from "../../components/Common/Form/Input/Input";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { LabelWithStatus } from "./LabelWithStatus";

type Props = {
  password: string;
  setPassword: (value: string) => void;
  reenteredPassword: string;
  setReenteredPassword: (value: string) => void;
  isFromModal?: boolean;
};

const SignupScreenThirdStep: React.FC<Props> = ({
  password,
  setPassword,
  reenteredPassword,
  setReenteredPassword,
  isFromModal,
}) => {
   const passStatus = (!!password.length  && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password)) 
      ? <CheckOutlined className="green-anticon"/>
      : !!password.length
         ? <CloseOutlined className="red-anticon"/>
         : <CheckOutlined className="opacity-0"/>;
   const repeatPassStatus = (!!password.length && !!reenteredPassword.length && password !== reenteredPassword) 
      ? <CloseOutlined className="red-anticon"/> 
      : (!!password.length 
         && !!reenteredPassword.length 
         && password === reenteredPassword 
         && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(reenteredPassword)) 
         ? <CheckOutlined className="green-anticon"/>
         : <CheckOutlined className="opacity-0"/>;

   return (
      <>
         <Col xl={isFromModal ? 12 : 6} className="password">
            <Input
               type="password"
               label={<LabelWithStatus label="Password *" status={passStatus}/>}
               value={password}
               onChange={setPassword}
               placeholder="Enter Password"
               classNameForm="password_input_container"
               classNameLabel="d-block"
            />
         </Col>
         <Col xl={isFromModal ? 12 : 6} className="reenter_password">
            <Input
               type="password"
               label={<LabelWithStatus label="Re-enter password *" status={repeatPassStatus}/>}
               value={reenteredPassword}
               onChange={setReenteredPassword}
               placeholder="Re-enter password"
               classNameLabel="d-block"
            />
         </Col>
      </>
   );
};

export default SignupScreenThirdStep;
