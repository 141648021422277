import { ProfileRequestProps } from "./PlayerProfileMain";
import { notification } from "antd";
import { API_URL } from "config";
import { useState } from "react";
import { post } from "redux/services/Api";

export const useProcessPromt = () => {
  const [loader, setLoader] = useState(false);
  const processPromt = async (profileData: ProfileRequestProps): Promise<string | null> => {
    try {
      setLoader(true);
      const res = await post<{ message: string }>(`${API_URL}/user/generate-player-profile`, { ...profileData });

      setLoader(false);
      return res.message;
    } catch (error) {
      notification.error({
        placement: "bottomRight",
        message: "Error sending request"
      })
      setLoader(false);
      return null;
    }
  }

  return { processPromt, loader };
};