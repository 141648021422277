import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import Dropdown from 'components/Common/Form/Dropdown/Dropdown';
import { Col, Row } from 'react-bootstrap';
import { useProgressionDropdown } from '../hooks/useProgressionDropdown';
import PlayerCareerRating from '../PlayerCareerRating';
import { AdvancedProgressionStats } from '../AdvancedProgressionStats';
import { getAverageRatingIncrement } from '../utils/getAverageRatingIncrement';

export function ProgressionTab({
  playerMappedRatingHistory,
  rankings,
  position,
}: {
  playerMappedRatingHistory: any[];
  rankings?: {
    currentRanking: number;
    highestRanking: number;
    lowestRanking: number;
  };
  position?: string;
}) {
  const { selectedTimeline, timelineOptions, handleSelectTimeline, ratingsHistory } = useProgressionDropdown(playerMappedRatingHistory);

  const ratingHighPoint = Math.max(...ratingsHistory.map((record) => record?.value));
  const ratingLowPoint = Math.min(...ratingsHistory.map((record) => record?.value));
  const averageIncrease = getAverageRatingIncrement(ratingsHistory);

  if (!Boolean(playerMappedRatingHistory?.length)) {
    return (
      <Card>
        <CardBody>
          <div className="playerDetailTab">
            <div className="tab__section">
              <h5 className="tab__section_heading">NO RATING HISTORY</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card>
      <CardBody>
        <div className="playerDetailTab">
          <div className="tab__section">
            <h5 className="tab__section_heading">PLAYER RATING PROGRESSION</h5>
            <Row className="franchiseDropdown rating-range__selector">
              <Col xl={4} lg={4}>
                <Dropdown value={selectedTimeline} dropDownOptions={timelineOptions} onChange={handleSelectTimeline} />
              </Col>
            </Row>
            <PlayerCareerRating ratingHistory={ratingsHistory} />
          </div>
          <div className="tab__section">
            <h5 className="tab__section_heading">PROGRESSION ADVANCED STATS</h5>
            <AdvancedProgressionStats
              rankings={rankings}
              position={position}
              ratingHighPoint={ratingHighPoint}
              ratingLowPoint={ratingLowPoint}
              averageRatingIncrease={averageIncrease}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
