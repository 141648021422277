import { Helmet, HelmetProvider } from "react-helmet-async";

type Props = {
  description: string;
  title: string;
  lang?: string;
};

const SEO: React.FC<Props> = ({ description, lang, title }) => (
  <HelmetProvider>
    <Helmet
      htmlAttributes={{
        lang: lang ? lang : "en",
      }}
      title={title}
    >
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="GM Dynasty" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  </HelmetProvider>
);

export default SEO;
