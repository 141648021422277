import { Modal } from "antd";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { League } from "components/Common/LeagueHeader/LeagueHeader";
import { InvitePlayers } from "components/LeagueEnter/CreateElimination/InvitePlayers";
import { getFullGameType, getPrizePool } from "helper/helper";
import { useFeeRates } from "helper/useFeeRates";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailInvitation } from "redux/actions/AuthAction";
import { RootState } from "redux/store";

type Props = {
  open: boolean;
  onClose: () => void;
  league: League;
};

export const InviteToLeagueModal: React.FC<Props> = ({
  open,
  onClose,
  league,
}) => {
  const dispatch = useDispatch();
  const { WALLET_TRANSACTION_FEE } = useFeeRates();
  const [playersToInvite, setPlayersToInvite] = useState<string[]>([]);
  let prizePool = 0;
  if (["E", "S"].includes(league.type) && WALLET_TRANSACTION_FEE !== undefined) {
    prizePool = getPrizePool(
      league.fee,
      100 - WALLET_TRANSACTION_FEE,
      league?.numberOfTeams || league?.totalTeams
    );
  } else if (league.type === "H") {
    prizePool = getPrizePool(league.fee, league.payoutPct);
  }

  const userName = useSelector(
    (state: RootState) => state.app.resData?.userName
  );

  const numberOfTeams = league?.numberOfTeams || league?.totalTeams || 2;
  const teamsString = `${league.teams?.length || 0}/${numberOfTeams}`;

  const urls = window?.location?.origin;
  const joinCode = league?.joinCode;
  const joinCodeWithType =
    joinCode && league && league && league.type
      ? `${league.type}-${joinCode}`
      : "";
  const SecondUserLink =
    joinCode && league.gid
      ? `${urls}/league?joincode=${joinCodeWithType}&gid=${league.gid}`
      : "";

  const SendEmailInvitations = async () => {
    const newdata = {
      USERNAME: userName,
      LEAGUETYPE: league?.type,
      LEAGUENAME: league?.name,
      ENTRYFEE: league?.fee,
      PRIZEPOOL: prizePool,
      TEAMRATING: `${league?.ratingRangeMin} to ${league?.ratingRangeMax}`,
      leagueid: league?.gid || league?.seasonId,
      TO: playersToInvite,
      LINK: SecondUserLink,
    };
    dispatch(sendEmailInvitation(newdata));
    setPlayersToInvite([]);
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      width="100vw"
      centered
    >
      <div className="modals" style={{ width: "75vw" }}>
        <h2 className="modal-invite-title">INVITE OTHER TO JOIN LEAGUE</h2>

        <div
          className="modal-game-info-container"
          style={{ boxSizing: "border-box" }}
        >
          <div className="modal-game-prize-pool">
            <span>$</span>
            <p>{prizePool}</p>
          </div>
          <div className="modal-game-info">
            <div className="modal-game-info-type">
              <p>{`${getFullGameType(league.type)} : ${league.name}`}</p>
            </div>
            <div className="modal-game-info-detail">
              <p>
                TEAMS:{" "}
                <span className="modal-game-info-value">{teamsString}</span>
              </p>
              <p>
                PRIZE POOL:{" "}
                <span className="modal-game-info-value">$ {prizePool}</span>
              </p>
              <p>
                TEAM RATING:{" "}
                <span className="modal-game-info-value">{`${league.ratingRangeMin} TO ${league.ratingRangeMax}`}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="modal-invite-section">
          <h2 className="modal-invite-section-title">
            SELECT INDIVIDUAL TO INVITE
          </h2>

          <div className="modal-invite-input">
            <InvitePlayers
              title=""
              users={playersToInvite}
              setUsers={setPlayersToInvite}
            />
          </div>
          <div className="modal-invite-button">
            <BlueButton
              onClick={SendEmailInvitations}
              type="button"
              text="SEND INVITATION"
              disabled={!playersToInvite.length}
            />
          </div>
          <p className="modal-invite-code">INVITE CODE: {joinCode}</p>
          <p className="modal-invite-code">LEAGUE ID: {league.gid} </p>
        </div>
      </div>
    </Modal>
  );
};
