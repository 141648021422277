import { useEffect, useState } from 'react';
import './ImageFlip.css';

type Props = {
  imageFront: string | undefined,
  imageBack: string | undefined,
}

const ImageFlip = ({ imageFront, imageBack }: Props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFlipped(prev => !prev);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (!imageFront || !imageBack) {
    return null;
  }

  return (
    <div className="flip-container-anim">
      <div className={`flip-card ${isFlipped ? 'flipped' : ''}`}>
        {imageFront && <img src={imageFront} alt="Front" className="front-img" />}
        {imageBack && <img src={imageBack} alt="Back" className="back-img" />}
      </div>
    </div>
  );
};

export default ImageFlip;