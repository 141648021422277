import React from "react";

interface Props {
  minutes: string | number;
  seconds: string | number;
}

const ShowCounter = ({ minutes, seconds }: Props) => {
  if (Number(minutes) + Number(seconds) <= 0) {
    return (
      <>
        <span>{0}</span>:<span>{0}</span>
      </>
    );
  } else {
    return (
      <>
        <span>{minutes}</span>:<span>{seconds}</span>
      </>
    );
  }
};

export default ShowCounter;
