import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TeamNFTJSON } from './types';
import { get } from 'redux/services/Api';
import { API_URL, CONTRACT_ADDRESS } from 'config';
import { notification, Spin } from 'antd';
import { Row, Col } from 'react-bootstrap';
import CardBody from 'components/Common/Card/CardBody';
import Card from 'components/Common/Card/Card';
import './TeamNFTInfo.scss';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { ThunderboltOutlined } from '@ant-design/icons';

export const TeamNFTInfo: React.FC = () => {
  const avatar = useSelector((state: RootState) => state.team.fetchedAvatar);
  const [loading, setLoading] = useState(false);
  const [nftJsonData, setNftJsonData] = useState<TeamNFTJSON | null>(null);
  const attribtes = nftJsonData?.attributes || null;

  const defaultAttributes = attribtes?.filter((a) => !('display_type' in a)) || [];
  const numberAttributes = attribtes?.filter((a) => 'display_type' in a && a.display_type === 'number') || [];
  const boostNumberAttributes = attribtes?.filter((a) => 'display_type' in a && a.display_type === 'boost_number') || [];

  useEffect(() => {
    if (!avatar) return;

    (async () => {
      try {
        setLoading(true);
        const nftJson = await get<TeamNFTJSON>(`${API_URL}/images/nfts/${avatar.jsonFile}`);
        setNftJsonData(nftJson);
      } catch (e) {
        notification.error({ message: 'Failed to fetch NFT data' });
      } finally {
        setLoading(false);
      }
    })();
  }, [avatar]);

  if (loading) {
    return <Spin />;
  }

  if (!avatar || !nftJsonData) return null;

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xl={4}>
            <img className={'team-nft-image'} src={nftJsonData.image} alt={avatar.teamName} />
          </Col>
          <Col xl={8}>
            <BlueButton className="mb-4 mt-4" text="MAKE AN OFFER" disabled />
            <div>
              {Object.entries(nftJsonData)
                .filter(([key]) => !['image', 'date', 'attributes'].includes(key))
                .map(([key, value]) => (
                  <div key={key}>
                    <h6>{key.toUpperCase()}</h6>
                    <p>{value as string}</p>
                  </div>
                ))}
              {!!defaultAttributes.length && (
                <>
                  <h6>TRAITS</h6>
                  <div className="simple-row-container mb-4">
                    <Row>
                      {defaultAttributes.map((a, i) => (
                        <Col key={i} xs={4}>
                          <div className="trait">
                            <p className="trait-name">{a.trait_type}</p>
                            <p className="trait-value">{a.value}</p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}
              {!!numberAttributes.length && (
                <>
                  <h6>STATS</h6>
                  <div className="mb-4">
                    {numberAttributes.map((a, i) => (
                      <Row>
                        <Col key={i} xs={6}>
                          <p>{a.trait_type}</p>
                        </Col>
                        <Col key={i} xs={6}>
                          <p style={{ textAlign: 'end' }}>{a.value}</p>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </>
              )}
              {!!boostNumberAttributes.length && (
                <>
                  <h6>BOOSTS</h6>
                  <div className="simple-row-container">
                    {boostNumberAttributes.map((a, i) => (
                      <div key={i} className="boost">
                        <div className="boost-thunderbolt">
                          <ThunderboltOutlined style={{ fontSize: 32, color: '#fff' }} />
                        </div>
                        <p className="boost-name">{a.trait_type}</p>
                        <p className="boost-value">{a.value}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
        <BlueButton
          className="mt-4"
          onClick={() => window.open(`https://opensea.io/assets/matic/${CONTRACT_ADDRESS}/${avatar.nftTokenId}`, '_blank')}
          text="VIEW AT OPENSEA"
        />
      </CardBody>
    </Card>
  );
};
