import { Tab, Nav } from 'react-bootstrap';
import Card from '../Common/Card/Card';
import CardBody from '../Common/Card/CardBody';
import BoxScoreMain from '../BoxScore/BoxScoreMain';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import LoadingScreen from '../Common/LoadingScreen/LoadingScreen';
import { useLocation, useParams } from 'react-router-dom';
import TeamStatData from '../TeamStat/TeamStatData';
import FullTeamStatRating from '../FullTeamStat/FullTeamStatRating';
import BoxScoreHeader from '../BoxScore/BoxScoreHeader';
import './BoxScore.scss';
import DriveChartData from 'components/DriveChart/DriveChartData';
import history from 'routes/history';
import { useLoading } from 'components/FranchiseOverview/hooks/use-loading';
import { useH2HOverview } from './hooks/useH2HOverview';

enum Tabs {
  'SCORE' = 'SCORE',
  'DRIVE-CHART' = 'DRIVE-CHART',
  'TEAM-STATS' = 'TEAM-STATS',
}

const GameResultsHeadTabMain: React.FC = () => {
  const { gid }: any = useParams();

  const loader = useLoading();
  const h2hOverview = useH2HOverview(+gid);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get('tab');
  const [tabKey, setTabKey] = useState<Tabs>(validatedTab(tabParam) ?? Tabs.SCORE);

  const teams = h2hOverview?.teams ? Object.values(h2hOverview?.teams) : [];

  const team1: any = teams.find((t: any) => t?.utid === h2hOverview?.teams[0]?.utid);
  const team2: any = teams.find((t: any) => t?.utid === h2hOverview?.teams[1]?.utid);

  useEffect(() => {
    history.replace(`/game-results/${gid}?tab=${tabKey}`);
  }, [tabKey, gid]);

  const handleTabChange = (key: Tabs) => {
    setTabKey(key);
  }

  const handleRedirectToReplay = () => {
    history.push(`/replay/${gid}`);
  };

  return (
    <>
      {!!loader && <LoadingScreen />}
      <Container>
        <BoxScoreHeader />
        <div className="standingTabbarMain game-results-tabbar mb-5">
          <Tab.Container 
            id="left-tabs-example" 
            defaultActiveKey={Tabs.SCORE} 
            activeKey={tabKey} 
            onSelect={(key) => handleTabChange((key as Tabs) || Tabs.SCORE)}
            mountOnEnter
            unmountOnExit
          >
            <div className="standingTabbar">
              <Card>
                <CardBody>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link onClick={handleRedirectToReplay}>REPLAY</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={Tabs.SCORE}>SCORE</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={Tabs['DRIVE-CHART']}>DRIVE CHART</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={Tabs['TEAM-STATS']}>TEAM STATS</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </CardBody>
              </Card>
            </div>
            <FullTeamStatRating lostTeam={team1} wonTeam={team2} />
            <Tab.Content>
              <Tab.Pane eventKey={Tabs.SCORE}>
                <BoxScoreMain team1={team1} team2={team2} />
              </Tab.Pane>
              <Tab.Pane eventKey={Tabs['DRIVE-CHART']}>
                <DriveChartData lostTeam={team1} wonTeam={team2} />
              </Tab.Pane>
              <Tab.Pane eventKey={Tabs['TEAM-STATS']}>
                <TeamStatData lostTeam={team1} wonTeam={team2} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
};

function validatedTab(queryTab: string | null): Tabs | null {
  if (!queryTab) return null;
  const validOptions = Object.values(Tabs) as string[];
  const isValid = validOptions.includes(queryTab);
  return isValid ? (queryTab as Tabs) : null;
}

export default GameResultsHeadTabMain;
