import { useHandleScreenSize } from "helper/useHandleScreenSize";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGroupsList } from "redux/actions/Groups";
import { RootState } from "redux/store";
import { PaginationRequest } from "types/pagination";

const getPageSize = (pageWidth: number) => {
  if (pageWidth < 768) {
    return 4;
  } else {
    return 6;
  }
};

export function useGroupsList(userId?: number) {
  const dispatch = useDispatch();
  const { groupsList, groupsInvitations } = useSelector(
    (state: RootState) => state.profile
  );
  const { pageWidth } = useHandleScreenSize();
  const [paginationState, setPaginationState] = useState<PaginationRequest>({
    page: 1,
    limit: getPageSize(pageWidth),
  });

  useEffect(() => {
    if (pageWidth) {
      setPaginationState((prevState) => ({
        ...prevState,
        page: 1,
        limit: getPageSize(pageWidth),
      }));
    }
  }, [pageWidth]);

  useEffect(() => {
    if (!userId) return;

    dispatch(GetGroupsList(userId, paginationState));
  }, [userId, paginationState]);

  const handleUpdatePage = (page: number) => {
    setPaginationState(() => ({
      ...paginationState,
      page,
    }));
  };

  return { groupsList, groupsInvitations, handleUpdatePage, paginationState };
}
