import { notification } from "antd";
import { post } from "redux/services/Api";
import { OffensiveStrategy } from "types";
import history from "routes/history";
import { saveTrialGameData } from "components/layout/WithLoginNavbar/helper";

export const postPlayTrialGame = async (teams: [number, number], strategy: OffensiveStrategy) => {
  try {
    const data = await post(
      `/engine/leagues/head2head/createTrialGame`,
      { teams, strategy },
    ) as { success: boolean, id: number};

    if (data.success) {
      saveTrialGameData(data.id);
      history.push(`/gameplay?id=${data.id}`);
    }
  } catch (e: any) {
    console.log ('ERROR CREATING FREE TRIAL GAME: ', e);
    notification.error({
      placement: 'bottomRight',
      message: "Error creating game",
    })
  }
};