export const frontOfficeColumns = [
  {
    title: "NAME",
    dataIndex: "franchiseStaff",
  },
  {
    title: "POS",
    dataIndex: "pos",
  },
  {
    title: "EXP",
    dataIndex: "exp",
  },
  {
    title: "DEF",
    dataIndex: "def",
  },
  {
    title: "OFF",
    dataIndex: "off",
  },
  {
    title: "SPC",
    dataIndex: "spc",
  },
  {
    title: "RATING",
    dataIndex: "rating",
  },
  {
    title: "STATUS",
    dataIndex: "status",
  },
  {
    title: "",
    dataIndex: "buttons",
  },
];
