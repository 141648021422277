import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getTeamLeadersWinrate } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useTeamLeadersWinrate() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamLeadersWinrate());
  }, []);

  const teamLeadersWinrate: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.teamLeadersWinrate);

  return { teamLeadersWinrate };
}