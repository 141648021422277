import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import LeagueHeadToHeadMain from "../../components/LeagueHeadToHead/LeagueHeadToHeadMain";

const LeagueOverviewHeadToHeadScreen: React.FC = () => (
    <div className="pagelayout" >
        <WithLoginNavbar />
        <LeagueHeadToHeadMain />
    </div>
);

export default LeagueOverviewHeadToHeadScreen;