import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const GroupDescription: React.FC = () => {
  const group = useSelector((state: RootState) => state.group.groupInfo);

  return (
    <div className="section">
        <div className="header">
          <h2>DESCRIPTION</h2>
        </div>
        {group?.description}
    </div>
  )
};
