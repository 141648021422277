import { Popover } from "antd";
import { Badge } from "react-bootstrap";

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  isFullWidth?: boolean;
}

export const UnselectedPopover: React.FC<Props> = ({
  content,
  open,
  children,
  handleClose,
  isFullWidth = false
}) => (
  <Popover content={<h6 className="pink">{content}</h6>} placement="right" open={open} >
    <Badge onMouseEnter={handleClose} id="badge-with-no-styling" className={isFullWidth ? "w-100" : ""}>
      {children}
    </Badge>
  </Popover>
)