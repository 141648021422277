import { Modal, Typography } from 'antd';
import { InviteFriendForm } from './InviteFriendForm';
import "./FriendInviteModal.style.scss";

interface FriendInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FriendInviteModal = ({ isOpen, onClose }: FriendInviteModalProps) => {
  return (
    <Modal open={isOpen} onCancel={onClose} footer={null} className='friend-invite__modal-wrapper' width="">
      <div>
        <Typography.Title level={3} style={{ textTransform: 'uppercase' }}>
          {`INVITE FRIEND`}
        </Typography.Title>
      </div>
      <InviteFriendForm />
    </Modal>
  );
};
