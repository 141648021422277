import { PlayFreeMain } from "components/PlayFree/PlayFreeMain";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import { useLoading } from "components/FranchiseOverview/hooks/use-loading";
import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";

const PlayFreeScreen: React.FC = () => {
  const loader = useLoading();

  return (
    <>
      {loader && <LoadingScreen/>}
      <div className="pagelayout" >
          <WithLoginNavbar />
          <PlayFreeMain />
      </div>
    </>
    
  );
}

export default PlayFreeScreen;