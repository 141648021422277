import Error from "../../assets/images/common/error.svg";

const DraftResultModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>YOU HAVE TO MAKE ADDITIONAL ROSTER MOVES TO MEET ROSTER REQUIREMENTS. PLEASE MAKE SURE ALL  ARE ON THE ACTIVE ROSTER OR TAXI SQUAD. YOU WILL NOT BE ABLE TO MAKE ADDITIONAL ROSTER MOVES UNTIL REQUIREMENTS ARE MET </p>
            </div> 
        </>
    );
}

export default DraftResultModal;