import { Popover, Badge } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import footballBall from "../../assets/images/common/football-ball.svg";
import "../layout/WithLoginNavbar/WithLoginNavbarStyle.scss";
import { TeamActiveGamesList } from "./TeamActiveGamesList";

const TeamActiveGamesDialog: React.FC = () => {
  const activeGames = useSelector(
    (state: RootState) => state.gameEngine.TeamActiveGames
  );

  return (
    <Popover
      content={<TeamActiveGamesList activeGames={activeGames}/>}
      trigger={!!activeGames && !!activeGames.length ? "click" : ""}
      placement="bottom"
      style={{ position: "fixed" }}
    >
      <Badge
        style={{ right: "10px" }}
        count={activeGames.length || 0}
        size="small"
      >
        <img
          src={footballBall}
          alt="footballBall"
          style={{ cursor: "pointer", height: "25px", width: "25px" }}
        />
      </Badge>
    </Popover>
  );
};

export default TeamActiveGamesDialog;
