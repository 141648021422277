import facebook from "../../assets/images/newsTemplate/facebook-icon-blue.svg";
import twitter from "../../assets/images/newsTemplate/twitter-icon-blue.svg";
import link from "../../assets/images/newsTemplate/link-icon-blue.svg";
import email from "../../assets/images/newsTemplate/email-icon-blue.svg";
import moment from "moment";
import React from "react";
import { NewsRecord } from "types/news";

type Props = {
    newsData: NewsRecord;
}

const NewsTemplateContent: React.FC<Props> = ({ newsData }) => {
  return (
    <section className="banner-corner">
      <div className="newsTemplateContentContainer">
        <div className="newsTemplateContent">
          <div className="newsTemplateContentDescription">
            <div className="dataAndAuthor">
              <p className="date">
                {moment(newsData.date).format("MMMM DD, YYYY").toUpperCase()}
              </p>{" "}
              | <p>{newsData.author}</p>
            </div>
            <div className="contacts">
              <a href={process.env.REACT_APP_FACEBOOK_URL}>
                <img src={facebook} alt="facebook" />
              </a>
              <a href={process.env.REACT_APP_TWITTER_URL}>
                <img src={twitter} alt="twitter" />
              </a>
              <a href={`mailto:${process.env.REACT_APP_NEWS_EMAIL}`}>
                <img src={email} alt="email" />
              </a>
              <a href={""}>
                <img src={link} alt="link" />
              </a>
            </div>
          </div>
          {newsData.paragraphs.map((paragraph) => (
            <>
              {paragraph.title && <h2>{paragraph.title}</h2>}
              <pre>{paragraph.content}</pre>
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsTemplateContent;
