import { Row , Col } from "react-bootstrap";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import player from "../../assets/images/franchiseOverview/teamLeaders.png";

const DraftPlayerDetail = () =>{
    const data = [
        {
            id: 1,
            player_header: "Height",
            player_info: "6'1",
        },
        {
            id: 2,
            player_header: "Weight",
            player_info: "234",
        },
        {
            id: 3,
            player_header: "40 YD",
            player_info: "65",
        },
        {
            id: 4,
            player_header: "Bench",
            player_info: "76",
        },
        {
            id: 5,
            player_header: "Vert",
            player_info: "82",
        },
        {
            id: 6,
            player_header: "Cone",
            player_info: "23",
        },
    ]
    const data1 = [
        {
            id: 7,
            player_header: "Prush",
            player_info: "34",
        },
        {
            id: 8,
            player_header: "Cov",
            player_info: "34",
        },
        {
            id: 9,
            player_header: "Rstop",
            player_info: "34",
        },
    ]
    return(
        <>
            <div className="prospect_playerdetail" >
                <h5 className="prospect_title" >PROSPECT DRAFT</h5>
                <div className="prospect_subtitle">
                    <div className="prospect_subsection">
                        <span>GENERAL MANAGER :</span>
                        <span>NAME OF GM (RATING 22)</span>
                    </div>
                    <div className="prospect_subsection">
                        <span>PICKS :</span>
                        <span>9 , 41 , 73 , 105 , 137 , 169 , 201</span>
                    </div>
                </div>
                <div className="prospect_playersection">
                    <Row>
                        <Col xl={4} >
                            <div className="player_detailmain" >
                                <img src={player} alt="playerimage" />
                                <div className="player_detail" >
                                    <h5>WALTER PAYTON</h5>
                                    <p>MINNESOTA STATE</p>
                                    <p>LINEBACKER</p>
                                    <p>rank: 3</p>
                                    <BlueButton text="DRAFT" />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} >
                            <div className="player_info">
                                <ul>
                                    <Row>
                                        {data.map((d: any,i: any) => (
                                            <Col xl={2} lg={2} md={4} sm={6} className="player_infosection" >
                                                <li>
                                                    <h6>{d.player_header}</h6>
                                                    <p>{d.player_info}</p>
                                                </li>
                                            </Col>
                                        ))}
                                        <div className="border" ></div>
                                        {data1.map((d: any,i: any) => (
                                            <Col xl={2} lg={2} md={4} sm={6} className="player_infosection player_infosection1" >
                                                <li>
                                                    <h6>{d.player_header}</h6>
                                                    <p>{d.player_info}</p>
                                                </li>
                                            </Col>
                                        ))}
                                    </Row>
                                </ul>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} >
                            <div className="player_grade">
                                <div>
                                    <h6>78</h6>
                                    <p>GRADE</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );  
}

export default DraftPlayerDetail; 