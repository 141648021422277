import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { API_URL } from "config";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import io, { Socket } from "socket.io-client";
import "./Chat.scss";
import { padStart } from "lodash";
import { notification } from "antd";
import { useCountdown } from "helper/useCoundown";
import { SendOutlined, SmileOutlined } from "@ant-design/icons";
import { EmojiPickerPopover } from "./EmojiPickerPopover";
import { EmojiAnimation } from "./EmojiAnimation";
import { Emoji, EmojiPath } from "./Emoji";

type Props = { gameId: number, headerTitle?: string };
type Message = {
  user: number | string;
  message: string;
  timestamp: number;
  type?: "emoji";
};

const Chat: React.FC<Props> = ({ gameId, headerTitle }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [userId, setUserId] = useState<number | string>(0);
  const [timeToNextMsg, setTimeToNextMsg] = useState<number | null>(null);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const userName = useSelector(
    (state: RootState) => state?.app?.resData?.userName
  );
  const messageBoxRef = useRef<HTMLDivElement>(null);

  const [, time] = useCountdown(new Date(timeToNextMsg || 0));

  const handleSendMessage = (passedMessage?: string, type?: "emoji" | null) => {
    if (socket && (message || passedMessage) && +time <= 0) {
      const preparedMessage: Message = {
        user: userId,
        message: message,
        timestamp: Date.now(),
      };
      if (type && passedMessage) {
        preparedMessage.message = passedMessage;
        preparedMessage.type = type;
      }
      socket.emit("message", preparedMessage);
      setMessage("");
      setTimeToNextMsg(Date.now() + 7000);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 100) {
      setMessage(e.target.value);
    } else {
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: "Message length cannot exceed 100 characters",
      });
    }
  };

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (userName) {
      setUserId(userName);
    } else {
      const randomId = Math.floor(Math.random() * 1000000);
      setUserId(randomId);
    }
  }, [userName]);

  useEffect(() => {
    const socketInstance = io(API_URL as string, {
      query: { room: gameId },
    });

    socketInstance.on("connect", () => {
      console.log("Connected to server");
    });
    socketInstance.emit("joinRoom", gameId);

    socketInstance.on("message", (data: { user: string; message: Message }) => {
      setMessages((prevMessages) => [...prevMessages, data.message]);
    });

    setSocket(socketInstance);

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [gameId]);

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [messages.length]);

  const shouldEmojiAnimationBeShown =
    messages?.at(-1)?.type === 'emoji' &&
    messages.at(-1)?.timestamp &&
    Date.now() - (messages.at(-1)?.timestamp || 0) < 3000;

  return (
    <div className="game-chat">
      <h4>{headerTitle || "GAME CHAT"}</h4>
      <div>
        <div className="messages" ref={messageBoxRef}>
          {messages.length === 0 && <h6>Message history is empty</h6>}
          {messages.map((msg, index) => {
            const time = new Date(msg.timestamp);
            const timeString = `${padStart(
              time.getHours().toString(),
              2,
              "0"
            )}:${padStart(time.getMinutes().toString(), 2, "0")}`;
            const isMessageMine = msg.user === userId;

            const userName = isMessageMine
              ? "ME"
              : typeof msg.user === "number"
              ? `GUEST-${msg.user}`
              : msg.user;

            const msgContent =
              msg?.type === "emoji" ? (
                <Emoji path={msg.message as EmojiPath} dimensions={{ width: "32px", height: "32px"}}/>
              ) : (
                msg.message
              );

            return (
              <div
                key={index}
                className={
                  isMessageMine
                    ? "message-container message-right"
                    : "message-container message-left"
                }
              >
                <div className="message">
                  <div className="message-header">
                    <div className="message-sender">{userName}</div>
                    <div className="message-time">{timeString}</div>
                  </div>
                  <div className="message-content">{msgContent}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="game-chat-footer">
          <input
            className="message-input"
            type="text"
            value={message}
            placeholder="Type a message!"
            onChange={handleInputChange}
            onKeyDown={handleEnterKeyDown}
          />
          <EmojiPickerPopover
            isOpen={isEmojiPickerVisible}
            sendSelectedEmoji={(emoji) => {
              handleSendMessage(emoji, "emoji");
              setIsEmojiPickerVisible(false);
            }}
          >
            <BlueButton
              disabled={+time > 0}
              className="emoji-picker-button"
              text={<SmileOutlined />}
              onClick={() => setIsEmojiPickerVisible((prev) => !prev)}
            />
          </EmojiPickerPopover>
          <BlueButton
            disabled={!message || +time > 0}
            className="send-button"
            onClick={handleSendMessage}
            text={+time > 0 ? time : <SendOutlined />}
          />
        </div>
      </div>
      {shouldEmojiAnimationBeShown && (
        <EmojiAnimation emoji={messages.at(-1)?.message as EmojiPath} />
      )}
    </div>
  );
};

export default Chat;
