import { Row, Col } from "react-bootstrap";
import { API_URL } from "config";
import { Profile } from "types";
import ProfileImageBackup from "../../../../assets/images/common/userName.png";
import "./profile-header.scss";

export interface ProfileHeaderProps{
    profile:Profile;
    renderAddFriendButton?:(props:Profile)=>React.ReactElement|null;
    friendsLength:number;
  }

export function ProfileHeaderTemplate({ profile, friendsLength, renderAddFriendButton }: ProfileHeaderProps) {
    const profileImg = profile?.avatar
      ? `${API_URL}/images/avatar/${profile?.avatar}`
      : ProfileImageBackup;
  
    if (!profile) return null;
  
    return (
      <div className="franchiseMain">
        <Row>
          <Col md={3} xl={2} lg={2}>
            <div className="franchiseProfile">
              <img
                src={profileImg}
                alt="profile"
                width="90px"
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50%",
                }}
              />
            </div>
          </Col>
          <Col md={6} xl={6} lg={6}>
            <div className="franchiseDetail">
              <div className="profile_username_container">
                <h5>{profile.username}</h5>
                {renderAddFriendButton && renderAddFriendButton(profile)}
              </div>
              <Row>
                <Col xl={6} md={6} className="franchiseDetailContent">
                  <span>USERNAME:</span>
                  <span>{profile.username}</span>
                </Col>
                <Col xl={6} md={6} className="franchiseDetailContent">
                  <span>NO. OF FRANCHISES:</span>
                  <span>{profile.franchises}</span>
                </Col>
                <Col xl={6} md={6} className="franchiseDetailContent">
                  <span>JOINED:</span>
                  <span>{`${new Date(profile.joined).getMonth() + 1}/${
                    new Date(profile.joined).getDay() + 1
                  }/${new Date(profile.joined).getFullYear()}`}</span>
                </Col>
                <Col xl={6} md={6} className="franchiseDetailContent">
                  <span>CHAMPIONSHIPS:</span>
                  <span>{profile.championships}</span>
                </Col>
                <Col xl={6} md={6} className="franchiseDetailContent">
                  <span>FRIENDS:</span>
                  <span>{friendsLength}</span>
                </Col>
                <Col xl={6} md={6} className="franchiseDetailContent">
                  <span>TROPHIES:</span>
                  <span>{profile.trophies}</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={3} xl={4} lg={4}>
            <div className="franchiseBoxSection">
              <div className="franchiseBoxBack">
                <p>PRESTIGE:</p>
                <h5>{profile.prestige}</h5>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }