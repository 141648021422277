import { League } from 'components/Common/LeagueHeader/LeagueHeader';
import history from '../../../routes/history';
import './ResultCardStyle.scss';
import { useParams } from 'react-router';
import { getEliminationWinnings, getPrizePool } from 'helper/helper';
import { Col, Row } from 'react-bootstrap';
import { DownOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { EliminationDetails, UserTeamsList } from 'types';
import { loadEliminationDetails } from './helper/LoadEliminationDetails';
import { EliminationRoundsDetails } from './EliminationRoundsDetail';

type Game = {
  gid: number;
  name: string;
  lost: Record<string, any>;
  won: Record<string, any>;
  simCompletedAt: string;
  teams: Record<any, any>;
  payoutPct?: number;
  payouts?: [number, number, number];
  fee: number;
  timestamp: Date;
  type: League['type'];
  numberOfTeams?: number;
  lastRound: any;
  thirdPlaceGame: any;
};

type Props = {
  game: Game;
  franchise: UserTeamsList[0];
  WALLET_TRANSACTION_FEE?: number;
};

const ResultCard: React.FC<Props> = ({ game, franchise, WALLET_TRANSACTION_FEE }) => {
  const [eliminationDetails, setEliminationDetails] = useState<EliminationDetails[] | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { franchiseId }: any = useParams();
  const isWon = game.won?.utid === franchise.engId;
  const wonOrLostString = isWon ? 'WON' : 'LOST';

  const onClick = () => {
    history.push(`/stand`, { gid: game.gid, id: +franchiseId });
  };

  const isE = game.type === 'E';
  const isH = game.type === 'H';

  const secondPlaceTeam = isE ? game.lastRound[0].teams?.find((id: number) => id !== game.lastRound[0].winner) : null;
  const thirdPlaceTeam = game?.thirdPlaceGame ? game.thirdPlaceGame.winner : null;

  const wonString =
    isH
      ? `${wonOrLostString}: ${game.won?.pts} TO ${game.lost?.pts}`
      : isE
      ? game?.lastRound?.[0]?.winner === franchise?.engId
        ? 'WON'
        : secondPlaceTeam && franchise?.engId && secondPlaceTeam === franchise?.engId
        ? '2ND PLACE'
        : thirdPlaceTeam && franchise?.engId && thirdPlaceTeam === franchise?.engId
        ? '3ND PLACE'
        : 'LOST'
      : 'N/A';

  const payoutPct = isH ? game.payoutPct : isE && WALLET_TRANSACTION_FEE ? 100 - WALLET_TRANSACTION_FEE : undefined;

  const wonIndex = wonString === 'WON' ? 0 : wonString === '2ND PLACE' ? 1 : wonString === '3ND PLACE' ? 2 : null;

  const prizePool = getPrizePool(game.fee, payoutPct, game?.numberOfTeams);
  const eliminationWinnings = isE && game.payouts ? getEliminationWinnings(game.payouts, wonIndex, prizePool) : null;
  const h2hWinnings = isH && wonOrLostString === 'WON' ? prizePool : 0;

  const handleShowEliminationDetails = async () => {
    if (isLoading || !game?.gid || !franchise?.engId) {
      return;
    }

    const isFirstClick = eliminationDetails === null;
    if (isFirstClick) {
      setIsLoading(true);
      const data = await loadEliminationDetails(game.gid, franchise.engId);
      setEliminationDetails(data);
      setIsLoading(false);
    }

    setIsOpen(p => !p);
  };

  return (
    <div className="result-card-container">
      <div className="h2h-result-card">
        <Row>
          <Col md={6} lg={2} xs={12} sm={12}>
            <p 
              className={`game-detail game-name ${isE ? "game-type-e" : ""}`} 
              onClick={() => isE && history.push(`/leagueelimination?gid=${game.gid}`)}
            >
              {game.name}
            </p>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-date">{new Date(game.timestamp).toLocaleDateString()}</span>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-record">{wonString}</span>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-winnings">FEE (${game.fee})</span>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-winnings">WON (${isH ? h2hWinnings : eliminationWinnings})</span>
          </Col>
          {isH && (
            <Col md={3} lg={2} xs={12} sm={12}>
              <button onClick={onClick} className="box-score-button">
                GAME DETAIL
              </button>
            </Col>
          )}
          {isE && (
            <Col md={3} lg={2} xs={12} sm={12}>
              <DownOutlined
                className={isOpen ? 'result-card-game-detail-arrow result-card-game-detail-arrow-rotated' : 'result-card-game-detail-arrow'}
                style={isLoading ? { opacity: 0.5 } : {}}
                onClick={handleShowEliminationDetails}
              />
            </Col>
          )}
        </Row>

        <Row className={isOpen ? "elimination-details elimination-details-opened" : "elimination-details"}>
          {eliminationDetails?.map((round) => (
            <EliminationRoundsDetails round={round} engId={franchise.engId} franchiseId={franchiseId} key={franchise.engId} />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default ResultCard;
