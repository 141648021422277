import FranchisePerformanceMain from "../../components/FranchisePerformance/FranchisePerformanceMain";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";

const FranchisePerformanceScreen = () =>{
    return(
        <>
            <div className="pagelayout">
                <WithLoginNavbar />
                <FranchisePerformanceMain />
            </div>
        </>
    );
}

export default FranchisePerformanceScreen;