import { ViewMoreStringTypes } from "./LeaderboardContent";
import { LeaderboardTeamLeadersList } from "./LeaderboardTeamLeadersList";
import { useTeamLeadersWins } from "./hooks";

export const LeaderboardTeamLeadersWins = () => {
  const { teamLeadersWins } = useTeamLeadersWins();

  if (!teamLeadersWins) {
    return <div>Sorry, there are no data</div>;
  }

  return (
    <LeaderboardTeamLeadersList
      leadersList={teamLeadersWins}
      viewMoreType={ViewMoreStringTypes["TEAM-LEADERS-WINS"]}
    />
  );
};
