import { LeagueTeam } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';
import { Row } from 'react-bootstrap';
import { LeagueTeamItem } from './LeagueTeamItem';
import './LeagueTeams.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

type Props = {
  teams?: LeagueTeam[] | null;
  handleNextPage?: () => void;
  handlePrevPage?: () => void;
  isNextPage?: boolean;
  isPrevPage?: boolean;
  numberOfTeams: number;
};

export const LeagueTeams: React.FC<Props> = ({ teams, handleNextPage, handlePrevPage, isNextPage, isPrevPage, numberOfTeams }) => (
  <>
    <h5 className="title">LEAGUE TEAMS ({numberOfTeams})</h5>
    {!!teams && !!teams?.length ? (
      <div className="league-team-main-container">
        <button className="league-team-controls" onClick={handlePrevPage} disabled={!isPrevPage}>
          <LeftOutlined style={{ color: '#fff' }} />
        </button>
        <Row>
          {teams.map((team) => (
            <LeagueTeamItem team={team} key={team.engId}/>
          ))}
        </Row>
        <button className="league-team-controls" onClick={handleNextPage} disabled={!isNextPage}>
          <RightOutlined style={{ color: '#fff' }} />
        </button>
      </div>
    ) : (
      <span>Sorry, there are no teams data</span>
    )}
  </>
);
