import { post } from "redux/services/Api";

export async function createInvites(
  userId: number,
  emails: string[],
  message: string
): Promise<{ success: boolean; message: string | undefined }> {
  const body = { userId, emails, message };
  try {
    const response = await post("/user/create-email-invites", body);
    if (!response) return { success: false, message: undefined };
    return { success: true, message: undefined };
  } catch (error: any) {
    return { success: false, message: error.response.data.message };
  }
}
