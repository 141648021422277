import { saveDecreaseCount } from "helper/helper";
import { Profile } from "types";
import { FriendWithNetworkStatus } from "types/friendships";
import {
  FriendsActions,
  FriendsActionTypes,
} from "redux/actions/Profile/Friends";
import { ActionTypes, ProfileActionTypes } from "../actions/Profile";
import { GroupInvitation, GroupListItem } from "types/groups";
import { PaginationResponse } from "types/pagination";
import { GroupActions, GroupActionTypes } from "redux/actions/Groups";

export interface ProfileStateIF {
  loader: number;
  loaders: {
    groupList: boolean,
    friends: boolean,
    getGroupInvitations: boolean;
  }
  data: Profile | null;
  friends: FriendWithNetworkStatus[] | null;
  friendsRequests: FriendWithNetworkStatus[] | null;
  groupsList: PaginationResponse<GroupListItem> | null;
  groupsInvitations: GroupInvitation[] | null;
  error: string | null;
}

const initialState: ProfileStateIF = {
  loader: 0,
  loaders: {
    groupList: false,
    friends: false,
    getGroupInvitations: false,
  },
  data: null,
  friends: null,
  friendsRequests: null,
  groupsList: null,
  groupsInvitations: null,
  error: null,
};

const reducer = (
  state = initialState,
  action: ProfileActionTypes | FriendsActions | GroupActions
): ProfileStateIF => {
  switch (action.type) {
    case ActionTypes.GET_PROFILE_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        error: null,
      };
    case ActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        data: action.payload.profile,
      };
    case ActionTypes.GET_PROFILE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        data: null,
        error: action.error,
      };
    case FriendsActionTypes.GET_FRIENDS_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          friends: true,
        },
        error: null,
      };
    case FriendsActionTypes.GET_FRIENDS_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          friends: false,
        },
        friends: action.payload.friends,
        friendsRequests: action.payload.friendsRequests,
      };
    case FriendsActionTypes.GET_FRIENDS_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          friends: false,
        },
        friends: null,
        error: action.error,
      };
    case FriendsActionTypes.UPDATE_FRIENDS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        friends: action.payload.friends,
        friendsRequests: action.payload.friendsRequests,
      };
    case FriendsActionTypes.UPDATE_FRIEND_INVITE_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case FriendsActionTypes.UPDATE_FRIEND_INVITE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case FriendsActionTypes.UPDATE_FRIEND_INVITE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case FriendsActionTypes.UPDATE_FRIEND_NETWORK_STATUS:
      return {
        ...state,
        friends:
          state.friends?.map((friend) => {
            if (friend.id === action.payload.friendId) {
              return {
                ...friend,
                networkStatus: action.payload.status,
              };
            }
            return friend;
          }) || null,
        friendsRequests:
          state.friendsRequests?.map((friend) => {
            if (friend.id === action.payload.friendId) {
              return {
                ...friend,
                networkStatus: action.payload.status,
              };
            }
            return friend;
          }) || null,
      };
    case GroupActionTypes.GET_GROUPS_LIST_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          groupList: true,
        },
        error: null,
      };
    case GroupActionTypes.GET_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          groupList: false,
        },
      };
    case GroupActionTypes.GET_GROUPS_LIST_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          groupList: false,
        },
        error: action.error,
      };
    case GroupActionTypes.GET_GROUP_INVITATIONS_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupInvitations: true,
        },
        error: null,
      };
    case GroupActionTypes.GET_GROUP_INVITATIONS_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupInvitations: false,
        },
      };
    case GroupActionTypes.GET_GROUP_INVITATIONS_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupInvitations: false,
        },
        error: action.error,
      };
    case GroupActionTypes.UPDATE_GROUPS_LIST:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        groupsList: action.payload.groupsList,
        groupsInvitations: action.payload.groupsInvitations
      };
    case GroupActionTypes.UPDATE_GROUP_IN_PROFILE_GROUPS_LIST:
      if (!state.groupsList) return state;

      const newNlist: GroupListItem[] = state.groupsList?.data.map((group) => {
        if (group.id !== action.payload.id) return group;
        return {
          ...action.payload,
          role: group.role,
        };
      }) || []
      return {
        ...state,
        groupsList: {
          ...state.groupsList,
          data: newNlist
        },
      };
    case GroupActionTypes.ADD_GROUP_INVITATION:
      return {
        ...state,
        groupsInvitations: [...(state.groupsInvitations ?? []), action.payload],
      }
    case GroupActionTypes.UPDATE_GROUP_INVITATIONS:
      return {
        ...state,
        groupsInvitations: action.payload,
      }
    default:
      return state;
  }
};

export default reducer;
