import ground from "../../assets/images/gamePlay/ground.png";
import football from "../../assets/images/gamePlay/football.png";
import { useEffect, useRef } from "react";
import { Button } from "antd";
import "./AnimationStyle.scss";
import { CloseOutlined } from "@ant-design/icons";

const factor = {
  speed: 1.3,
  size: 1,
  shadowOffset: 50,
}

interface GroundProps {
  yardLine?: number;
  teamNum?: number;
  animationOverlay?: string | null;
  closeAnimationViewer?: () => void;
}
const Ground = ({
  yardLine,
  teamNum,
  animationOverlay,
  closeAnimationViewer,
}: GroundProps) => {
  const ballRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (yardLine && typeof teamNum === "number" && ballRef.current) {
      let adjLeft: number = 50;
      ballRef.current.classList.remove("football-ball-animation");

      // adjust for endzones (keep ball in field of play) ,and make sure the ball is going the right
      // direction based on which team is on offense
      if (teamNum === 0) adjLeft = Math.floor(10 + (yardLine / 100.0) * 80 - 4);
      else adjLeft = Math.floor(90 - (yardLine / 100.0) * 80 - 4);

      // ball transotion
      const prevAdjLeft = Number(ballRef.current.style.left.replace("%", "")) || adjLeft;
      const delta = Math.abs((prevAdjLeft - adjLeft) / 80);

      // set ball position and animation props
      ballRef.current.style.cssText = `
        left: ${adjLeft}%;
        --duration: ${(factor.speed * delta).toFixed(2)}s;
        --maxSize: ${(factor.size + delta).toFixed(1)};
        --shadowOffset: ${(8 + delta * factor.shadowOffset).toFixed(0)}px;
      `;

      // Restart animation
      void ballRef.current.offsetWidth;
      ballRef.current.classList.add("football-ball-animation");
    }
  }, [yardLine, teamNum]);

  return (
    <>
      <div id="field" className="groundmain">
        <img src={ground} alt="ground" />
        <img
          className="football-ball"
          src={football}
          alt="football"
          id="gamePlay-ball"
          ref={ballRef}
        />
        {animationOverlay && (
          <div className="animationViewer">
            <img src={animationOverlay} alt="animation" />
            <Button
              className="closeAnimationViewer"
              icon={<CloseOutlined />}
              shape="round"
              onClick={
                closeAnimationViewer ? () => closeAnimationViewer() : undefined
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Ground;
