import { Col, Divider, Row, Space } from "antd";
import {
  InGameLeaderBoard,
  LeaderPassing,
  LeaderReceiving,
  LeaderRowProps,
  LeaderRushing,
  LeaderStatsProps,
} from "types";
import { mapPlayerLeaderStatRow, mapPlayerLeaderTitle } from "../helper";

const titleMaps = {
  passing: "Passing Yards",
  rushing: "Rushing Yards",
  receiving: "Receiving Yards",
};

interface GameLeadersProps {
  leaderboard: InGameLeaderBoard | null;
}

export const GameLeaders = ({ leaderboard }: GameLeadersProps) => {
  if (!leaderboard) {
    return (
      <div className="quarter-stats__wrapper">
        <h5 className="quarter-stats__title">Game Leaders</h5>
        <div className="leaders-rows-wrapper">
          <LeaderRow title={titleMaps["passing"]} teams={[null, null]} />
          <LeaderRow title={titleMaps["rushing"]} teams={[null, null]} />
          <LeaderRow title={titleMaps["rushing"]} teams={[null, null]} />
        </div>
      </div>
    );
  }

  return (
    <div className="quarter-stats__wrapper">
      <h5 className="quarter-stats__title">Game Leaders</h5>
      <div className="leaders-rows-wrapper">
        {Object.keys(leaderboard).map((key) => (
          <LeaderRowWihTeamsHOK
            key={key}
            data={leaderboard[key as keyof typeof leaderboard] as any}
            type={key as keyof typeof leaderboard}
          />
        ))}
      </div>
    </div>
  );
};

function LeaderRowWihTeamsHOK<
  T extends LeaderPassing | LeaderReceiving | LeaderRushing
>({ data, type }: LeaderStatsProps<T>) {
  const teams = data.reduce((acc, current) => {
    const team = current
      ? {
          player: mapPlayerLeaderTitle(current.firstName, current.lastName),
          statsRows: mapPlayerLeaderStatRow(type, current),
        }
      : null;
    acc.push(team);
    return acc;
  }, [] as Array<{ player: string; statsRows: string } | null>);

  return <LeaderRow title={titleMaps[type]} teams={teams} />;
}

function LeaderRow({ title, teams }: LeaderRowProps) {
  const team1 = teams[0];
  const team2 = teams[1];
  return (
    <Row>
      <Col xs={18} offset={3}>
        <div className="row__wrapper">
          <p className="row__title">{title}</p>
          <Row>
            <Col xs={11}>
              <div className="row__cell row__cell-left">
                {team1 ? (
                  <>
                    <p className="row__cell__title row__cell__text">{`${team1.player}`}</p>
                    <p className="row__cell__sub-title row__cell__text">
                      {`${team1.statsRows}`}
                    </p>
                  </>
                ) : (
                  <p className="row__cell__empty">- - -</p>
                )}
              </div>
            </Col>
            <Col xs={2}>
              <Divider type="vertical" style={{ height: "50px" }} />
            </Col>
            <Col xs={11}>
              <div className="row__cell row__cell-right">
                {team2 ? (
                  <>
                    <p className="row__cell__title row__cell__text">{`${team2.player}`}</p>
                    <p className="row__cell__sub-title row__cell__text">
                      {`${team2.statsRows}`}
                    </p>
                  </>
                ) : (
                  <p className="row__cell__empty">- - -</p>
                )}
              </div>
            </Col>
          </Row>
          {/* <Space size="small"></Space> */}
        </div>
        <Divider dashed />
      </Col>
    </Row>
  );
}
