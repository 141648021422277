import { API_URL } from 'config';
import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { LeagueTeam } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';

export const getSeasonTeams = (gid: string, page = 1, limit = 6) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_TEAMS_REQUEST,
        payload: {},
      });

      let res: Pick<LeagueTeam, 'utid' | 'region'>[] = await get(`/engine/leagues/seasons/season/${gid}/teamList?page=${page}&limit=${limit}`);

      const preparedEngIds = res.map((data) => data.utid).join(',');

      let helmetsData: Omit<LeagueTeam, 'utid' | 'region'>[] = await get(
        `${API_URL}/user/get-helmet/${preparedEngIds}`,
        {},
        {
          'Access-Control-Allow-Origin': '*',
        },
      );

      const result = res.map((data, i) => ({
        ...data,
        ...(helmetsData.find(helmet => helmet.engId === data.utid) || {})
      })) as LeagueTeam[];

      dispatch({
        type: types.GET_SEASON_TEAMS_SUCCESS,
        payload: { leagueTeams: result },
      });
    } catch (error: any) {
      console.log("SEASON TEAMS ERROR - ", error)
      dispatch({
        type: types.GET_SEASON_TEAMS_FAIL,
        error: error,
      });
    }
  };
};
