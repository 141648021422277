import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { LeagueResult } from 'types';

type Response = {
  data: LeagueResult[];
  hasNextPage: boolean;
}

export const getSeasonResults = (gid: string, page = 1, limit = 10) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_RESULTS_REQUEST,
        payload: {},
      });

      const leagueResults = await get<Response>(`/engine/leagues/seasons/season/${gid}/results?page=${page}&limit=${limit}`);

      dispatch({
        type: types.GET_SEASON_RESULTS_SUCCESS,
        payload: { leagueResults },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_RESULTS_FAIL,
        error: error,
      });
    }
  };
};
