import { GoogleOutlined } from '@ant-design/icons';
import { useGoogleAuth } from './hooks/useGoogleAuth';

type Props = {
  rememberMe: boolean;
}

export const SignInWithGoogle: React.FC<Props> = ({ rememberMe }) => {
  const { googleLogIn } = useGoogleAuth(rememberMe);

  return (
    <button onClick={() => googleLogIn()}>
      <GoogleOutlined />
      {" "}
      Sign in with Google
    </button>
  );
};
