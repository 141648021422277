import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { GroupDescription } from "../GroupDescription/GroupDescription";
import { GroupMembers } from "../GroupMembers/GroupMembers";
import { GroupTrophies } from "../GroupTrophies/GroupTrophies";
import { GroupLeagues } from "../GroupLeagues/GroupLeagues";
import { GroupPassedGames } from "../GroupPassedGames/GroupPassedGames";

export const GroupMain: React.FC = () => (
  <Card>
    <CardBody>
      <GroupDescription />
      <GroupPassedGames />
      <GroupLeagues />
      <GroupTrophies />
      <GroupMembers />
    </CardBody>
  </Card>
);
