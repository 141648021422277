import { ReactComponent as SocialGroupIcon } from "../../../../assets/images/common/socialGroup.svg";
import "./GroupEmptyAvatar.scss";

export function GroupEmptyAvatar() {
  return (
    <div className="group-avatar-empty">
      <SocialGroupIcon className="group-icon" />
    </div>
  );
}
