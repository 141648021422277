import { useDispatch, useSelector } from "react-redux";
import { GameAnimationFile } from "redux/actions/GameAnimations/types";
import { RootState } from "redux/store";
import { useEffect, useState } from "react";
import { GameAnimationsActions } from "redux/actions/GameAnimations";

export function useGameAnimationsEvents(packageName?: string) {
  const gameAnimations = useSelector(
    (state: RootState) => state.app.gameAnimations
  ) as Array<GameAnimationFile>;
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(GameAnimationsActions.getActiveGameAnimations(packageName));
  }, [packageName]);

  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const getAnimationFileUrlByGameEvent = (gameEvent: string) => {
    if (!gameAnimations) return null;
    const animationFile = gameAnimations.find(
      (animation) => animation.event_type === gameEvent
    );
    if (!animationFile) return null;
    if (!animationFile.url.length) return null;
    
    return animationFile.url;
  };

  const handleSetFileUrl = (url: string | null) => {
    setFileUrl(() => url);
  };

  return { fileUrl, getAnimationFileUrlByGameEvent, handleSetFileUrl };
}
