import { API_URL } from "config";
import { Row, Col } from "react-bootstrap";

type Props = {
  handleClickLogo: (e: any) => void;
  modalDataLogo: any;
};

export const LogoSelectorDialog: React.FC<Props> = ({
  handleClickLogo,
  modalDataLogo,
}) => {
  return (
    <div className="verificationContent choose-color" style={{ maxWidth: "340px" }}>
      <h5>{modalDataLogo?.title}</h5>
      <div className="selectlogolist">
        <Row>
          {modalDataLogo?.data.map((e: any) => (
            <Col xl={3} lg={3} md={3} sm={3} xs={6}>
              <img
                src={`${API_URL}${e.url}`}
                alt={e.name}
                className={`bg-${e.name} `}
                onClick={() => handleClickLogo(e)}
                width="60px"
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
    // </Modal>
  );
};
