import Title from "components/Common/Title/Title";
import { LeaderboardTeamOfWeek } from "./LeaderboardTeamOfWeek";
import { LeaderboardGameOfTheWeek } from "./LeaderboardGameOfTheWeek";
import { LeaderboardWeeklyLeaders } from "./LeaderboardWeeklyLeaders";
import { WeekPlayers } from "./WeekPlayers";

export const LeaderboardWeeklyStars = () => {
  return (
    <>
      <Title text="TEAM OF THE WEEK" />
      <LeaderboardTeamOfWeek />
      <Title text="PLAYERS OF THE WEEK" />
      <WeekPlayers />
      <Title text="GAME OF THE WEEK" />
      <LeaderboardGameOfTheWeek />
      <Title text="WEEKLY LEADERS" />
      <LeaderboardWeeklyLeaders />
    </>
  );
};
