import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamLeaguesHistory } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";

export default function useUploadTeamHistory(franchiseId: string) {
  const dispatch = useDispatch();

  const userTeamListData = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const secondTteamId = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview?.teams?.[1]?.utid
  );
  const teamData = useSelector(
    (state: RootState) => state.gameEngine.teamLeaguesHistory
  );

  useEffect(() => {
    const team = userTeamListData?.find(
      (team: any) => team?.id === Number(franchiseId)
    );

    const teamId = team?.engId ?? secondTteamId;
    if (teamId) {
      dispatch(getTeamLeaguesHistory(teamId));
    }
  }, [franchiseId, userTeamListData]);

  return { teamData };
}
