import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminGroups } from "redux/actions/Groups";
import { RootState } from "redux/store";

export const useAdminGroups = (userId?: number) => {
  const dispatch = useDispatch();
  const adminGroupsList = useSelector((state: RootState) => state.group.adminGroupsList);

  useEffect(() => {
    if (userId) {
      dispatch(GetAdminGroups(userId));
    }
  }, [userId]);

  return adminGroupsList || [];
};
