type Props = {
  users: string[];
  handleAddUser: (givenName?: string) => void;
};

export const FoundUsersList: React.FC<Props> = ({ users, handleAddUser }) => {
  return (
    <>
      {users.map((user) => (
        <div
          className="found-user-list"
          key={user}
          onClick={() => handleAddUser(user)}
        >
          {user}
        </div>
      ))}
    </>
  );
};
