const PrivacyContent: React.FC = () => (
    <div className="privacy_content">
      <h5>Privacy Policy</h5>
      <div className="privacy_subcontent">
        <h6>Last updated : 24/01/2024</h6>
        <p>
          Diversify Games, Inc. operating as GM Dynasty ("us", "we" or "our")
          operates the website http://gmdynasty.com (the "Site"). This page
          informs you of our policies regarding the collection, use and
          disclosure of Personal Information we receive from users of the Site.
        </p>
        <p>
          GM Dynasty protects and respects the privacy of its customers, users
          and website visitors. We use your Personal Information only for
          providing and improving the Site and to operate the game effectively. By
          using the Site, you agree to the collection and use of information in
          accordance with this policy.
        </p>
        <p>
          When you are asked to provide Personal Information, you may decline.
          But if you choose not to provide data that is necessary to enable us
          to make the game available to you, you may not be able to sign up for
          or play the game, and/or certain game features may be limited.
        </p>
      </div>
      <div className="privacy_subcontent">
        <h6>About our Privacy Policy</h6>
        <p>
          Personal Information is information which may be used to reasonably
          identify you. For example, your name, address, date of birth, gender,
          email address, telephone number is generally considered to be Personal
          Information. Personal Information may also include information we
          collect about your individual preferences and web usage.
        </p>
        <p>
          This policy applies to your Personal Information when you use our
          Website, and interact generally with us but does not apply to Third
          Party Sites. We are not responsible for the privacy policies or
          content of Third Party Sites. For the avoidance of doubt, unless
          stated otherwise, this policy will govern our collection of your
          Personal Information irrespective of the forum.
        </p>
        <p>
          This policy may be updated from time to time and the most up-to-date
          version will be published on our Website. Your continued usage of our
          Website and/or services will be taken to indicate your acceptance of
          the terms of this privacy policy.
        </p>
      </div>
    </div>
);

export default PrivacyContent;
