/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./CreateFranchiseStyle.scss";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  updateAvatarData,
} from "./../../../redux/actions/TeamAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { addCityInfo } from "../../../redux/actions/TeamAction";
import PurchaseFranchiseContent from "components/PurchaseFranchise/Common/PurchaseFranchiseContent";
import BlackButton from "../Button/BlackButton/BlackButton";

const CreateFranchise: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParamsType = new URLSearchParams(search).get("type");

  const cityInfo = useSelector((state: RootState) => state.team.cityInfo);
  const userFranchiseList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const { franchiseId }: any = useParams();

  const franchise = useMemo(
    () =>
      userFranchiseList?.find(
        (team: { id: number }) => team?.id === +franchiseId
      ),
    [franchiseId, userFranchiseList]
  );

  const actionType: 'update' | 'create' = useMemo(() => {
    if (searchParamsType === 'update' && franchise?.rejectMessage?.length && franchise?.updateStatus == 1) {
      return 'update';
    }

    return 'create';
  }, [searchParamsType, franchise?.rejectMessage, franchise?.updateStatus]);

  async function updateAvatar() {
    dispatch(updateAvatarData({ ...cityInfo, franchiseId: +franchiseId, type: actionType }));
    dispatch(
      addCityInfo(
        null,
        null,
        null,
        null,
        null,
        null,
        "city Randomly selected"
      )
    );
  }

  return (
    <div className="purchasefranchisemain customizefranchisemain">
      {franchise && (
        <PurchaseFranchiseContent
          customizeData={{
            baseRate: actionType === 'update' ? +franchise.teamRating : "00",
            cityId: franchise.city,
          }}
        />
      )}
      {cityInfo && (
        <div className="submit-button-container">
          <BlackButton text="SUBMIT" onClick={updateAvatar}/>
        </div>
      )}
      </div>
  );
};

export default CreateFranchise;
