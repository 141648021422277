import { get } from "redux/services/Api";
import { InvitedGamesTypes } from "..";

export const getInvitedGames = (userId: number | undefined, number: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: InvitedGamesTypes.GET_INVITED_GAMES_REQUEST,
        payload: {},
      });
      if (!userId) throw new Error("User ID is not provided");

      const string = number ? `?number=${number}` : "";

      const data: { invitedGames: number } | [] = await get(
        `/engine/games/${userId}/invited` + string,
      );

      if (number && !Array.isArray(data)) {
        dispatch({
          type: InvitedGamesTypes.GET_INVITED_NUMBER_GAMES_SUCCESS,
          payload: { invitedGamesNumber: data.invitedGames },
        });
      } else {
        dispatch({
          type: InvitedGamesTypes.GET_INVITED_GAMES_SUCCESS,
          payload: { invitedGames: data },
        });
      }
    } catch (error: any) {
      dispatch({
        type: InvitedGamesTypes.GET_INVITED_GAMES_FAIL,
        error: error.response?.data.error,
      });
    }
  };
};
