/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import player1 from '../../assets/images/franchiseTeamCreation/player1.png';
import Slider from 'react-slick';
import { useEffect, useState } from 'react';
import { Fireworks } from 'fireworks-js/dist/react';
import './CarouselStyle.scss';
import './CarouselTestStyle.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { API_URL } from './../../config/index';

const PlayerRevealCarousel: React.FC<any> = ({
  speed,
  currentActiveIndex,
  setCurrentActiveIndex,
  setDataListLength,
  setGmLength,
  gmCurrentIndex,
  setGmCurrentIndex,
  setHcLength,
  hcCurrentIndex,
  setHcCurrentIndex,
  setCurrentCarousel,
}) => {
  const teamPlayersReveal = useSelector((state: RootState) => state.gameEngine.teamPlayersReveal);

  const [currentSpeed, setCurrentSpeed] = useState(6000);

  const settings = {
    className: 'center',
    centerMode: true,
    arrows: false,
    infinite: false,
    centerPadding: '60px',
    dots: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: currentSpeed ? currentSpeed : 6000,
    slidesToShow: 3,
    // cssEase: "linear",
    pauseOnHover: false,
    variableWidth: true,
    afterChange: (current: any) => {
      setCurrentCarousel(current);
      //Player
      let currentIsPlayer = Data[current]?.position !== 'GM' && Data[current]?.position !== 'AG' && Data[current]?.position !== 'HC' && Data[current]?.position !== 'AC';
      currentIsPlayer ? setCurrentActiveIndex(currentActiveIndex + 1) : setCurrentActiveIndex(currentActiveIndex);
      //gm
      let currentIsGM = Data[current]?.position === 'GM' || Data[current]?.position === 'AG';
      currentIsGM ? setGmCurrentIndex(gmCurrentIndex + 1) : setGmCurrentIndex(gmCurrentIndex);
      //gm
      let currentIsHC = Data[current]?.position === 'HC' || Data[current]?.position === 'AC';
      currentIsHC ? setHcCurrentIndex(hcCurrentIndex + 1) : setHcCurrentIndex(hcCurrentIndex);
    },
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const Data = teamPlayersReveal?.map((player: any) => {
    let obj = {
      img: player1,
      position: player?.ratings?.[0]?.pos,
      revealCode: player?.ratings?.[0]?.ovr,
      type: player?.ratings?.[0]?.skills?.length > 0 ? 'Super Skill' : 'player',
      skill: player?.ratings?.[0]?.skills?.length > 0 ? player?.ratings?.[0]?.skills?.[0] : null,
      name: `${player?.firstName} ${player?.lastName}`,
      jerseyNumber: player?.stats?.[0]?.jerseyNumber ? `# ${player?.stats?.[0]?.jerseyNumber}` : '',
    };
    return obj;
  });

  useEffect(() => {
    const player = Data?.filter((info: any) => info.position !== 'GM' && info.position !== 'AG' && info.position !== 'HC' && info.position !== 'AC');
    setDataListLength(player?.length);
    //gm
    let gm = Data?.filter((info: any) => info.position === 'GM' || info.position === 'AG');
    // let AG = Data?.filter((info:any) =>  info.position === "AG")
    // let GM = Data?.filter((info:any) =>  info.position === "GM")
    setGmLength(gm?.length);
    //hc
    let hc = Data?.filter((info: any) => info.position === 'HC' || info.position === 'AC');
    // let AC = Data?.filter((info:any) => info.position === "AC")
    // let HC = Data?.filter((info:any) => info.position === "HC")
    setHcLength(hc?.length);

    // console.log("🚀 ~ All Team Members", Data)
    // console.log("🚀 ~ only Player", player?.length)
    // console.log("🚀 ~ AG", AG)
    // console.log("🚀 ~ GM", GM)
    // console.log("🚀 ~ Total GMs", gm?.length)
    // console.log("🚀 ~ AC", AC)
    // console.log("🚀 ~ HC", HC)
    // console.log("🚀 ~ Total COACHES ", hc?.length)
  }, [Data]);
  // console.log("🚀 ~ Data", Data)

  // set count
  useEffect(() => {
    //Player
    let currentIsPlayer = Data?.[0]?.position !== 'GM' && Data?.[0]?.position !== 'AG' && Data?.[0]?.position !== 'HC' && Data?.[0]?.position !== 'AC';
    currentIsPlayer ? setCurrentActiveIndex(currentActiveIndex + 1) : setCurrentActiveIndex(currentActiveIndex);
    //gm
    let currentIsGM = Data?.[0]?.position === 'GM' || Data?.[0]?.position === 'AG';
    currentIsGM ? setGmCurrentIndex(gmCurrentIndex + 1) : setGmCurrentIndex(gmCurrentIndex);
    //gm
    let currentIsHC = Data?.[0]?.position === 'HC' || Data?.[0]?.position === 'AC';
    currentIsHC ? setHcCurrentIndex(hcCurrentIndex + 1) : setHcCurrentIndex(hcCurrentIndex);
  }, []);

  useEffect(() => {
    speed && setCurrentSpeed(speed * 900);
    console.log('🚀 ~ speed', speed);
    console.log('🚀 ~ speed * 900', speed * 900);
  }, [speed]);

  // get player img
  let img: any = document.getElementsByClassName('slick-slide slick-active slick-current')?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0];

  let name: any = document.getElementsByClassName('slick-slide slick-active slick-current')?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[1];

  useEffect(() => {
    if (img?.alt == 'player') {
      let fireworksDiv: any = document.getElementsByClassName('fireworksStyle')?.[0];

      fireworksDiv && fireworksDiv.classList.add('fireworksNone');
    }

    if (img?.alt == 'superstar' || img?.alt == 'Super Skill') {
      switch (currentSpeed) {
        case 5400:
          setCurrentSpeed(5500);
          break;
        case 4500:
          setCurrentSpeed(4700);
          break;
        case 3600:
          setCurrentSpeed(6504);
          break;
        case 2700:
          setCurrentSpeed(6503);
          break;
        case 1800:
          setCurrentSpeed(6502);
          break;
        default:
          break;
      }

      img.classList.remove('player-image2');
      name.classList.remove('player-image2');

      let fireworksDiv: any = document.getElementsByClassName('fireworksStyle')?.[0];

      fireworksDiv && fireworksDiv.classList.remove('fireworksNone');

      let flipDiv: any = document.getElementsByClassName('slick-slide slick-active slick-current')?.[0]?.children?.[0]?.children?.[0]?.children?.[1]?.children?.[0];

      flipDiv && flipDiv.classList.remove('player-image2');
      flipDiv && flipDiv?.children?.[1]?.children?.[0]?.classList.remove('player-type');

      flipDiv && flipDiv?.children?.[0]?.children?.[0]?.classList.remove('front-playername-none');

      flipDiv && flipDiv?.children?.[0]?.children?.[0]?.classList.add('front-playername');

      flipDiv && flipDiv?.children?.[0]?.children?.[0]?.children?.[0]?.classList.remove('front-skill-none');

      flipDiv && flipDiv?.children?.[0]?.children?.[0]?.children?.[0]?.classList.add('front-skill');

      setTimeout(() => {
        img.classList.add('player-image2');
        name.classList.add('player-image2');

        let flipDiv: any = document.getElementsByClassName('slick-slide slick-active slick-current')?.[0]?.children?.[0]?.children?.[0]?.children?.[1]?.children?.[0];

        flipDiv && flipDiv.classList.add('player-image2');
        flipDiv && flipDiv?.children?.[1]?.children?.[0]?.classList.add('player-type');
      }, 3000);

      setTimeout(() => {
        let fireworksDiv: any = document.getElementsByClassName('fireworksStyle')?.[0];

        fireworksDiv && fireworksDiv.classList.add('fireworksNone');
      }, 5000);
    }
  }, [img]);

  useEffect(() => {
    setTimeout(() => {
      switch (currentSpeed) {
        case 5500:
          setCurrentSpeed(5400);
          break;
        case 4700:
          setCurrentSpeed(4500);
          break;
        case 6504:
          setCurrentSpeed(3600);
          break;
        case 6503:
          setCurrentSpeed(2700);
          break;
        case 6502:
          setCurrentSpeed(1800);
          break;
        default:
          break;
      }
    }, 4000);
  }, [currentSpeed]);

  // bluer left div

  let leftDiv: any = document.getElementsByClassName('slick-slide slick-active')?.[0];

  useEffect(() => {
    if (leftDiv) {
      let leftImg: any = document.getElementsByClassName('slick-slide slick-active')?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0];

      if (leftImg) {
        leftImg.style.webkitFilter = 'blur(0px)';
      }
    }
  }, [leftDiv]);

  // const checkSuperStar = Data?.filter((list: any) => list.revealCode >= 70);
  // console.log("🚀 ~ checkSuperStar", checkSuperStar);

  // const checkSuperSkill = Data?.filter((list: any) => list.skill !== null);
  // console.log("🚀 ~ checkSuperSkill", checkSuperSkill);

  // const checkSuper_Star_Skill = Data?.filter(
  //   (list: any) => list.skill !== null && list.revealCode >= 70
  // );
  // console.log("🚀 ~ checkSuper_Star_Skill", checkSuper_Star_Skill);

  // const checkNotSuperStar  = Data?.filter((list: any) => list.revealCode <= 70)
  // console.log("🚀 ~ checkNotSuperStar", checkNotSuperStar)

  return (
    <div className="playerreveal-slider carousel_main">
      <Slider {...settings}>
        {Data?.map((list: any, i: any) => (
          <div className="carousel_image" key={`list_${list?.revealCode}_${i}`}>
            <div className="player_image_animation">
              <img
                src={`${API_URL}/images/player/${list?.position}.png`}
                alt={list.revealCode >= 70 ? 'superstar' : list?.type ? list?.type : 'player'}
                className="player-image player-image2"
              />
              <h6 className="player-reveal-name player-image player-image2">{`${list?.name}`}</h6>
              <span></span>
            </div>
            <div className="flip-container">
              <div className="flipper player-image2">
                <div className="front">
                  <h2 className="front-playername-none">
                    {list?.revealCode}
                    <span className="front-skill-none">{list.revealCode >= 70 ? ' | superstar' : list?.type ? ` | ${list?.type} - ${list?.skill}` : 'player'}</span>
                  </h2>
                </div>
                <div className="back">
                  <span className="player-type">
                    {list?.revealCode}
                    {list.revealCode >= 70 ? ' | superstar' : list?.type ? ` | ${list?.type}  - ${list?.skill}` : 'player'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="playerdetail" key="b_1"></div>
        <div className="playerdetail" key="b_2"></div>
      </Slider>
      <Fireworks className="fireworksStyle" />
    </div>
  );
};

export default PlayerRevealCarousel;
