import './FullPlayerStatStyle.scss';
import { Container, Col } from 'react-bootstrap';
import Card from '../Common/Card/Card';
import CardBody from '../Common/Card/CardBody';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PregameOverview, getTeamGameDepthChart } from '../../redux/actions/GameEngineAction';
import { RootState } from '../../redux/store';
import LoadingScreen from './../Common/LoadingScreen/LoadingScreen';
import { useParams } from 'react-router-dom';
import FullTeamStatHeader from '../FullTeamStat/FullTeamStatHeader';
import LeagueOverviewRating from 'components/LeagueHeadToHead/TabDetail/OverviewTab/LeagueOverviewRating';
import { formationConfig, getFormationConfig, positionColumns } from 'components/DefensiveDepthChart/depthChartConfig';
import DepthChartPosition from 'components/FranchiseOverview/TabDetail/DepthChartTab/DepthChartPosition';
import { Position } from 'types';
import { preparePlayersDepth } from './helper';
import LeagueHeadTabMainMenu from 'components/LeagueHeadToHead/LeagueHeadTabMainMenu';
import { useScrollTop } from 'helper/useScrollTop';
import { FullPlayerStatContent } from './FullPlayerStatContent';

const FullPlayerStatMain: React.FC = () => {
  const dispatch = useDispatch();
  const { franchiseId, gid }: any = useParams();

  const gameEngineStateLoader: any = useSelector((state: RootState) => state.gameEngine.loader);

  const teamDepthChart = useSelector((state: RootState) => state.gameEngine.teamDepthChart);

  const [preparedPlayers, setPreparedPlayers] = useState<any[]>([]);

  useEffect(() => {
    if (teamDepthChart) {
      setPreparedPlayers(preparePlayersDepth(teamDepthChart));
    } else {
      setPreparedPlayers([]);
    }
  }, [teamDepthChart]);

  useEffect(() => {
    dispatch(PregameOverview(gid));
    dispatch(getTeamGameDepthChart(franchiseId, gid, 'fullplayerstat'));
  }, [gid, franchiseId]);

  useScrollTop();

  return (
    <>
      {!!gameEngineStateLoader && <LoadingScreen />}
      <Container>
        <FullTeamStatHeader />
        <LeagueHeadTabMainMenu />
        <Col xl={12} className="mb-3">
          <LeagueOverviewRating gid={gid} />
        </Col>
        <div className="pb-4">
          <Card>
            <CardBody>
              <FullPlayerStatContent preparedPlayers={preparedPlayers} />
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default FullPlayerStatMain;
