import * as types from "./settingTypes";
import { get } from "./../services/Api";

export const getSetting = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GETSETTING_REQUEST,
        payload: {},
      });
      const res: any = await get("/setting");
      dispatch({
        type: types.GETSETTING_SUCCESS,
        payload: { setting: res.setting },
      });
    } catch (error: any) {
      dispatch({
        type: types.GETSETTING_FAIL,
        error: error,
      });
    }
  };
};
