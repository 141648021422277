/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Route, Redirect, RouteProps } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { logout } from '../redux/actions/AuthAction';
import store, { RootState } from './../redux/store/index';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { userTeamsList } from 'redux/actions/TeamAction';

function AuthRoute({ component: Component, ...rest }: RouteProps & { component: any }) {
  const token = localStorage.getItem('token');
  const SecondUserLinkdata = useSelector((state: RootState) => state.app.urlData);
  const [awaitAuth, setAwaitAuth] = useState(true);

  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);
  const resData = useSelector((state: RootState) => state.app.resData);

  useEffect(() => {
    const isAdditionalDataLoaded = userTeams?.some(t => 'utid' in t);
    const isOptionsNeeded = ['/ownfranchise', '/league', '/group/:groupId'].includes(rest.path as string);
    if (
      (!resData?.id || (userTeams 
        && (
          (isAdditionalDataLoaded && isOptionsNeeded) || 
          (!isAdditionalDataLoaded && !isOptionsNeeded)
        ))
      )
    ) return;

    store.dispatch(userTeamsList(
      { withActivity: isOptionsNeeded, withStats: isOptionsNeeded }
    ));
  }, [rest.path, userTeams, resData?.id]);

  useEffect(() => {
    const SecondUserLink: any = [];
    if (SecondUserLinkdata?.split('?')[0] === 'league') {
      SecondUserLink.push(SecondUserLinkdata);
      localStorage.setItem('UserData', SecondUserLink);
    }
    if (token) {
      setAwaitAuth(false);
      const decodedToken: any = jwt_decode(token);
      if (decodedToken.exp < Date.now() / 1000 || !('exp' in decodedToken)) {
        store.dispatch(logout());
      }
    } else setAwaitAuth(true);
  }, [token]);

  if (awaitAuth) {
    if (token) {
      return <></>;
    } else {
      store.dispatch(logout());
    }
  }

  return <Route {...rest} render={(props) => (token ? <Component {...props} /> : <Redirect to="/signin" />)} />;
}

export default AuthRoute;
