import { get } from "../../../services/Api";
import { WeekPlayersActionTypes } from "../index";
import { WeekPlayerResponse } from "../types";

export const getWeekPlayers = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: WeekPlayersActionTypes.GET_WEEKLY_PLAYERS_REQUEST,
        payload: {},
      });
      const players = await get<WeekPlayerResponse>(
        `/engine/players/playersOfTheWeek`,
      );

      dispatch({
        type: WeekPlayersActionTypes.GET_WEEKLY_PLAYERS_SUCCESS,
        payload: { players },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: WeekPlayersActionTypes.GET_WEEKLY_PLAYERS_SUCCESS,
        error: error?.response?.data?.error ?? "Unexpected error",
      });
    }
  };
};
