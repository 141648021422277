/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Title from "../Common/Title/Title";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Divider, Table } from "antd";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import WhiteButton from "../Common/Button/WhiteButton/WhiteButton";

const DraftResultRoster = () => {
  const offensiveLineColumns = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "PICK",
      dataIndex: "pick",
    },
    {
      title: "POS",
      dataIndex: "pos",
    },
    {
      title: "HEIGHT",
      dataIndex: "height",
    },
    {
      title: "EXP",
      dataIndex: "exp",
    },
    {
      title: "WEIGHT",
      dataIndex: "weight",
    },
    {
      title: "RATING",
      dataIndex: "rating",
    },
    {
      title: "STATUS",
      dataIndex: "status",
    },
    {
      title: "",
      dataIndex: "action",
    },
  ];
  const offensiveLinekData = [
    {
      key: "1",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: (
        <div className="overviewBtn">
          <BlueButton text="RELEASE" />
          <WhiteButton text="DEMOTE" />
        </div>
      ),
    },
    {
      key: "2",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: (
        <div className="overviewBtn">
          <BlueButton text="RELEASE" />
          <WhiteButton text="DEMOTE" />
        </div>
      ),
    },
    {
      key: "3",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: (
        <div className="overviewBtn">
          <BlueButton text="RELEASE" />
          <WhiteButton text="DEMOTE" />
        </div>
      ),
    },
    {
      key: "4",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: (
        <div className="overviewBtn">
          <BlueButton text="RELEASE" />
          <WhiteButton text="DEMOTE" />
        </div>
      ),
    },
    {
      key: "5",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: (
        <div className="overviewBtn">
          <BlueButton text="RELEASE" />
          <WhiteButton text="DEMOTE" />
        </div>
      ),
    },
    {
      key: "6",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: "",
    },
    {
      key: "7",
      title: "MITCH TRAP",
      pick: "1",
      pos: "QB",
      height: "6'1",
      exp: "1",
      weight: "234",
      rating: "82",
      status: "OPEN",
      action: "",
    },
  ];
  return (
    <>
      <div className="rostermain standingmain mt-4">
        <Row className="mb-2">
          <Col md={6} sm={12} className="tableTitleRowStart">
            <Title text="ACTIVE ROSTER" />
          </Col>
          <Col className="tableTitleRowEnd">
            <h6>53 OF 53 ROSTER SPOTS FILLED</h6>
          </Col>
        </Row>
        <Table
          columns={offensiveLineColumns}
          pagination={false}
          dataSource={offensiveLinekData}
          size="small"
        />
      </div>
    </>
  );
};

export default DraftResultRoster;
