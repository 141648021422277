export const formationConfig = {
  "2 RB, 1 WR, 2 TE": {
    QB: ["QB1"],
    RB: ["HB1", "HB2", "FB1", "FB2"],
    WR: ["WR1", "WR2"],
    OL: ["OG1", "OG2", "OT1", "OT2", "OC1", "OG3", "OT3", "OC2"],
    TE: ["TE1", "TE2", "TE3"],
  },
  "2 RB, 2 WR, 1 TE": {
    QB: ["QB1"],
    RB: ["HB1", "HB2", "FB1", "FB2"],
    WR: ["WR1", "WR2", "WR3"],
    OL: ["OG1", "OG2", "OT1", "OT2", "OC1", "OG3", "OT3", "OC2"],
    TE: ["TE1", "TE2"],
  },
  "1 RB, 2 WR, 2 TE": {
    QB: ["QB1"],
    RB: ["RB1", "RB2"],
    WR: ["WR1", "WR2", "WR3"],
    OL: ["OG1", "OG2", "OT1", "OT2", "OC1", "OG3", "OT3", "OC2"],
    TE: ["TE1", "TE2", "TE3"],
  },
  "1 RB, 3 WR, 1 TE": {
    QB: ["QB1"],
    RB: ["RB1", "RB2"],
    WR: ["WR1", "WR2", "WR3", "WR4"],
    OL: ["OG1", "OG2", "OT1", "OT2", "OC1", "OG3", "OT3", "OC2"],
    TE: ["TE1", "TE2"],
  },
  "1 RB, 4 WR": {
    QB: ["QB1"],
    RB: ["RB1", "RB2"],
    WR: ["WR1", "WR2", "WR3", "WR4", "WR5"],
    OL: ["OG1", "OG2", "OT1", "OT2", "OC1", "OG3", "OT3", "OC2"],
    TE: [],
  },
  "4 DL, 3 LB, 2 S, 2 CB": {
    DL: ["DE1", "DL1", "DL2", "DE2", "DE3", "DL3"],
    LB: ["LB1", "LB2", "LB3", "LB4"],
    CB: ["CB1", "CB2", "CB3"],
    S: ["S1", "S2", "S3"],
  },
  "3 DL, 4 LB, 2 S, 2 CB": {
    DL: ["DE1", "DL1", "DE2", "DE3", "DL2"],
    LB: ["LB1", "LB2", "LB3", "LB4", "LB5"],
    CB: ["CB1", "CB2", "CB3"],
    S: ["S1", "S2", "S3"],
  },
  "4 DL, 2 LB, 2 S, 3 CB": {
    DL: ["DE1", "DL1", "DL2", "DE2", "DE3", "DL3"],
    LB: ["LB1", "LB2", "LB3"],
    CB: ["CB1", "CB2", "CB3", "CB4"],
    S: ["S1", "S2", "S3"],
  },
  "4 DL, 1 LB, 2 S, 4 CB": {
    DL: ["DE1", "DL1", "DL2", "DE2", "DE3", "DL3"],
    LB: ["LB1", "LB2"],
    CB: ["CB1", "CB2", "CB3", "CB4", "CB5"],
    S: ["S1", "S2", "S3"],
  },
  "3 DL, 3 LB, 2 S, 3 CB": {
    DL: ["DE1", "DL1", "DE2", "DE3", "DL2"],
    LB: ["LB1", "LB2", "LB3", "LB4"],
    CB: ["CB1", "CB2", "CB3", "CB4"],
    S: ["S1", "S2", "S3"],
  },
  "3 DL, 2 LB, 2 S, 4 CB": {
    DL: ["DE1", "DL1", "DE2", "DE3", "DL2"],
    LB: ["LB1", "LB2", "LB3"],
    CB: ["CB1", "CB2", "CB3", "CB4", "CB5"],
    S: ["S1", "S2", "S3"],
  },
  "ANY": {
    QB: ["QB", "QB", "QB"],
    RB: ["RB", "RB", "RB", "RB"],
    WR: ["WR", "WR", "WR", "WR", "WR", "WR"],
    OL: ["OL", "OL", "OL", "OL", "OL", "OL", "OL", "OL", "OL", "OL"],
    TE: ["TE", "TE", "TE"],
    DL: ["DL", "DL", "DL", "DL", "DL", "DL", "DL", "DL", "DL"],
    LB: ["LB", "LB", "LB", "LB", "LB", "LB"],
    CB: ["CB", "CB", "CB", "CB", "CB", "CB"],
    S: ["S", "S", "S", "S"],
    K: ["K"],
    P: ["P"],
    HC: ["HC"],
    AC: ["AC", "AC", "AC"],
    GM: ["GM"],
    AG: ["AG", "AG", "AG"],
  },
  "SPECIAL TEAM": {
    P: ["P1"],
    K: ["K1"],
    // KR: [],
  },
};

export const getFormationNameBySid = (sid: number) => {
  return Object.keys(formationConfig)[sid] as keyof typeof formationConfig;
};

export const getSidByFormationName = (name: keyof typeof formationConfig) => {
  return Object.keys(formationConfig).findIndex(
    (formation) => formation === name
  );
};

const empty = {
  QB: [] as string[],
  RB: [] as string[],
  WR: [] as string[],
  OL: [] as string[],
  TE: [] as string[],
  DL: [] as string[],
  LB: [] as string[],
  CB: [] as string[],
  S: [] as string[],
  K: [] as string[],
  P: [] as string[],
  KR: [] as string[],
};

export const getFormationConfig = (key: string) => {
  return formationConfig[key as keyof typeof formationConfig]
    ? { ...empty, ...formationConfig[key as keyof typeof formationConfig] }
    : empty;
};

const nameMap = {
  hgt: "AGL",
  endu: "EXP",
  kpw: "KPOW",
  kac: "KACC",
  ppw: "PPOW",
  pac: "PACC",
}

const commonLeftColumns = [
  "ht",
  "wt",
  "spd",
  "str",
  "agl",
  "endu",
];

const commonRightColumns = [
  "ovr",
  "skill",
]

const getColumns = (list: string[]) => {
  const stringColumn = ["ht", "skill"];
  const result: any[] = [
    {
      title: "",
      dataIndex: "position",
      fixed: "left",
      width: 150,
    },
  ];
  list.forEach((column) => {
    const columnDef: any = {
      title: column in nameMap ? nameMap[column as keyof typeof nameMap] : column.toUpperCase(),
      dataIndex: column,
      width: 80,
      sorter: stringColumn.includes(column)
        ? (a: any, b: any) => a[column].localeCompare(b[column])
        : (a: any, b: any) => a[column] - b[column],
    };
    // if (defaultSortColumn === column) columnDef.defaultSortOrder = 'descend'
    result.push(columnDef);
  });
  return result;
};



export const quaterBackColumns = getColumns([
  ...commonLeftColumns,
  "pass",
  "run",
  ...commonRightColumns,
]);

export const runningBackColumns = getColumns([
  ...commonLeftColumns,
  "run",
  "rec",
  "blk",
  ...commonRightColumns,
]);

export const receivingColumns = runningBackColumns;
export const tightEndColumns = getColumns([
  ...commonLeftColumns,
  "run",
  "pass",
  "blk",
  ...commonRightColumns,
]);

export const offensiveLineColumns = getColumns([
  ...commonLeftColumns,
  "pblk",
  "rblk",
  ...commonRightColumns,
]);

export const defensiveLineColumns = getColumns([
  ...commonLeftColumns,
  "prush",
  "cov",
  "rstop",
  "tackl",
  ...commonRightColumns,
]);

export const kickReturnerColumns = getColumns([
  ...commonLeftColumns,
  "kick",
  ...commonRightColumns,
]);

export const punterColumns = getColumns([
  ...commonLeftColumns,
  "ppw",
  "pac",
  ...commonRightColumns,
]);

export const placeKickerColumns = getColumns([
  ...commonLeftColumns,
  "kpw",
  "kac",
  ...commonRightColumns,
]);

export const safetyColumns = getColumns([
  ...commonLeftColumns,
  "prush",
  "cov",
  "rstop",
  "tackl",
  ...commonRightColumns,
]);

export const cornerBackColumns = safetyColumns;
export const lineBackerColumns = safetyColumns;

export type positions =
  | "QB"
  | "RB"
  | "WR"
  | "TE"
  | "OL"
  | "DL"
  | "LB"
  | "CB"
  | "S"
  | "K"
  | "P"
  | "KR";

export const positionColumns = {
  QB: { title: "quarterback", columns: quaterBackColumns, scheme: "offensive" },
  RB: {
    title: "running back",
    columns: runningBackColumns,
    scheme: "offensive",
  },
  WR: { title: "receiving", columns: receivingColumns, scheme: "offensive" },
  TE: { title: "tight end", columns: tightEndColumns, scheme: "offensive" },
  OL: {
    title: "offensive",
    columns: offensiveLineColumns,
    scheme: "offensive",
  },
  DL: {
    title: "defensive",
    columns: defensiveLineColumns,
    scheme: "defensive",
  },
  LB: { title: "line backer", columns: lineBackerColumns, scheme: "defensive" },
  CB: { title: "corner back", columns: cornerBackColumns, scheme: "defensive" },
  S: { title: "safety", columns: safetyColumns, scheme: "defensive" },
  K: { title: "place kicker", columns: placeKickerColumns, scheme: "special" },
  P: { title: "punter", columns: punterColumns, scheme: "special" },
  KR: {
    title: "kick returner",
    columns: kickReturnerColumns,
    scheme: "special",
  },
};
