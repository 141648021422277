import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';
export interface Props {
  data?: any;
  team?: any;
}

const Team1Kicking = ({ data , team }: Props) =>{
  const scoreColumns:any = [
    {
      title: "",
      dataIndex: "title",
      fixed: "left",
      width: 150,
    },
    {
      title: "FG",
      dataIndex: "fg",
    },
    {
      title: "FG%",
      dataIndex: "fg1",
    },
    {
      title: "XP",
      dataIndex: "xp",
    },
    {
      title: "XP%",
      dataIndex: "xp1",
    },
    {
      title: "LNG",
      dataIndex: "lng",
    },
    {
      title: "OVR50",
      dataIndex: "ovr",
    },
  ];
  let teamsumfg = ((team.stat.fg0?team.stat.fg0:0)+(team.stat.fg20?team.stat.fg20:0)+(team.stat.fg30?team.stat.fg30:0)+(team.stat.fg40?team.stat.fg40:0)+(team.stat.fg50?team.stat.fg50:0));
  let teamsumfga = ((team.stat.fga0?team.stat.fga0:0)+(team.stat.fga20?team.stat.fga20:0)+(team.stat.fga30?team.stat.fga30:0)+(team.stat.fga40?team.stat.fga40:0)+(team.stat.fga50?team.stat.fga50:0));
  let tablefooter:any={
    key:500,
    title:"TEAM",
    fg: teamsumfg+"/"+teamsumfga,
    fg1: (teamsumfg && teamsumfga)? (teamsumfg/ teamsumfga)*100:"---",
    xp: team.stat.xp+"/"+team.stat.xpa,
    xp1: (team.stat.xp && team.stat.xpa)? (team.stat.xp/ team.stat.xpa)*100:"---",
    lng: team.stat.fgLng || team.stat.fgLng === 0? team.stat.fgLng :"---",
    ovr: team.stat.fg50 || team.stat.fg50 === 0? team.stat.fg50 :"---",
  };
  const scoreData = data?.map((d: any,index:any) => {
    let sumfg = ((d.stat.fg0?d.stat.fg0:0)+(d.stat.fg20?d.stat.fg20:0)+(d.stat.fg30?d.stat.fg30:0)+(d.stat.fg40?d.stat.fg40:0)+(d.stat.fg50?d.stat.fg50:0));
    let sumfga = ((d.stat.fga0?d.stat.fga0:0)+(d.stat.fga20?d.stat.fga20:0)+(d.stat.fga30?d.stat.fga30:0)+(d.stat.fga40?d.stat.fga40:0)+(d.stat.fga50?d.stat.fga50:0));
    let info = {
      key: index,
      title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)} >{d.name ? d.name : "---"}</span></>,
      fg: sumfg+"/"+sumfga,
      fg1: (sumfg && sumfga)? (sumfg/ sumfga)*100:"---",
      xp: d.stat.xp+"/"+d.stat.xpa,
      xp1: (d.stat.xp && d.stat.xpa)? (d.stat.xp/ d.stat.xpa)*100:"---",
      lng: d.stat.fgLng || d.stat.fgLng === 0? d.stat.fgLng :"---",
      ovr: d.stat.fg50 || d.stat.fg50 === 0? d.stat.fg50 :"---",
    }
    if(info.fg1 !== "---"){
      info.fg1=Number(info.fg1).toFixed(2)+"%";
    }
    if(info.xp1 !== "---"){
      info.xp1=Number(info.xp1).toFixed(2)+"%";
    }
    return info;
  })
  if(Array.isArray(scoreData) && scoreData.length){
    if(tablefooter.fg1 !== "---"){
      tablefooter.fg1=Number(tablefooter.fg1).toFixed(2)+"%";
    }
    if(tablefooter.xp1 !== "---"){
      tablefooter.xp1=Number(tablefooter.xp1).toFixed(2)+"%";
    }
    scoreData.push(tablefooter)
  }

    return(
        <>
            <div className="scorecommontable standingmain mt-3" >
                <Title text="KICKING" />
                <Table
                    columns={scoreColumns}
                    pagination={false}
                    dataSource={scoreData}
                    size="small"
                    scroll={{ x: 500 }}
                />
            </div>
        </>
    );
}

export default Team1Kicking;