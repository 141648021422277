import { API_URL } from 'config';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import './RenderHelmet.scss';
import history from 'routes/history';

type Props = {
  helmet?: number | string;
  logo?: number | string;
  mirror?: boolean;
  size?: number;
  engId: number;
};

export const RenderHelmet: React.FC<Props> = ({ helmet, logo, mirror, size, engId }) => {
  const avData = useSelector((state: RootState) => state.team.avatar);

  const helmetURL = typeof helmet === 'string' ? helmet : avData?.helmet?.find((h: { id: number }) => h.id === helmet)?.url;
  const logoURL = typeof logo === 'string' ? logo : avData?.logo?.find((h: { id: number }) => h.id === logo)?.url;

  if (!helmetURL || !logoURL) {
    return <div />;
  }

  let styles: React.HTMLAttributes<HTMLDivElement>['style'] = { backgroundImage: `url(${API_URL}${helmetURL})` };
  if (mirror) {
    styles = { ...styles, transform: 'scaleX(-1)' };
  }
  if (size) {
    styles = { ...styles, width: size, height: (size * 1889) / 1500 };
  }
  if (engId) {
    styles = { ...styles, cursor: 'pointer' };
  }

  const handleClick = () => {
    if (engId) {
      history.push(`/franchisedetail/${engId}`);
    }
  };

  return (
    <div className="render-helmet-container" style={styles} onClick={handleClick}>
      <img className="render-logo" src={`${API_URL}${logoURL.replace('/logo', '/logo/full')}`} alt="helmetsmall" />
    </div>
  );
};
