import NavbarWithOutLogin from "components/layout/WithOutLoginNavbar/NavbarWithOutLogin";
import NewsTemplateBanner from "./NewsTemplateBanner";
import NewsTemplateContent from "./NewsTemplateContent";
import { useParams } from "react-router-dom";
import { useNewsArticle } from "./hooks/useNewsArticle";

const NewsTemplateMain: React.FC = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const article = useNewsArticle(+newsId);

  return (
    <>
      <NavbarWithOutLogin login />
      {article && (
        <>
          <NewsTemplateBanner
            title={article.title}
            subtitle={article.subtitle}
            setLoading
          />
          <NewsTemplateContent newsData={article} />
        </>
      )}
    </>
  );
};

export default NewsTemplateMain;
