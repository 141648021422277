import { SeasonInfo } from 'screens/Season/types';
import types from '../actionTypes';
import { API_URL } from 'config';
import { get } from 'redux/services/Api';

export const getSeasonInfo = (gid: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_INFO_REQUEST,
        payload: {},
      });
      const seasonInfo: SeasonInfo = await get(
        `${API_URL}/engine/leagues/seasons/season/${gid}`,
      );
      dispatch({
        type: types.GET_SEASON_INFO_SUCCESS,
        payload: { seasonInfo },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_INFO_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
}
