import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import WhiteButton from 'components/Common/Button/WhiteButton/WhiteButton';
import { useHandleRedirect } from 'helper/useHandleRedirect';
import { Col, Row } from 'react-bootstrap';
import { useHandleGroupParticipation } from './hooks/useHandleGroupParticipation';
import { GroupMemershipStatus } from 'types/groups';
import { useState } from 'react';
import { GroupInviteModal } from 'components/Group/GroupInvite/GroupInviteModal';

const buttonMemberTextMap: Record<GroupMemershipStatus, string> = {
  [GroupMemershipStatus.APPROVED]: 'LEAVE',
  [GroupMemershipStatus.REQUESTED]: 'REQUESTED / CANCEL',
  [GroupMemershipStatus.INVITED]: 'INVITED / CANCEL',
  [GroupMemershipStatus.REJECTED]: 'REJECTED',
};

interface GroupHeaderCtasButtonsProps {
  isOwner: boolean;
  isAdmin: boolean;
  isMember: boolean;
  membershipStatus?: GroupMemershipStatus;
  groupId?: number;
}

export function GroupHeaderCtasButtons({ isAdmin, isMember, isOwner, membershipStatus, groupId }: GroupHeaderCtasButtonsProps) {
  const { handleRedirect } = useHandleRedirect();
  const { handleJoin, handleLeave } = useHandleGroupParticipation();

  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  return (
    <div className="group-header__actions-row">
      <Row className="justify-content-end">
        {isMember ? (
          <>
            {membershipStatus !== GroupMemershipStatus.REQUESTED && (
              <Col xs={6} md={5} lg={4}>
                <PinkButton text="INVITE" onClick={() => setInviteModalOpen(true)} />
              </Col>
            )}
            {(isAdmin || isOwner) && (
              <Col xs={6} md={5} lg={4}>
                <WhiteButton text="EDIT" onClick={() => handleRedirect(`/group-edit/${groupId}`)} />
              </Col>
            )}
            {!isOwner && membershipStatus && (
              <Col xs={6} md={5}>
                <PinkButton
                  text={buttonMemberTextMap[membershipStatus]}
                  onClick={() => groupId && handleLeave(groupId)}
                  disabled={!groupId || membershipStatus === GroupMemershipStatus.REJECTED}
                />
              </Col>
            )}
          </>
        ) : (
          <Col xs={12} md={7} lg={6}>
            <PinkButton text="REQUEST MEMBERSHIP" onClick={() => groupId && handleJoin(groupId)} disabled={!groupId} />
          </Col>
        )}
      </Row>
      <GroupInviteModal isOpen={inviteModalOpen} onClose={() => setInviteModalOpen(false)} />
    </div>
  );
}
