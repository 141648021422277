import { post } from "redux/services/Api";
import { InvitationActionTypes } from "..";

export const confirmInvitation = ({
  inviteCode,
  inviteType,
  onSuccess,
  onError,
}: {
  inviteCode?: string;
  inviteType: string;
  onSuccess: () => void;
  onError: () => void;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: InvitationActionTypes.CONFIRM_INVITATION_CODE_REQUEST,
        payload: {},
      });

      await post("/confirm-invitation", {
        inviteCode,
        inviteType,
      });

      onSuccess();
      dispatch({
        type: InvitationActionTypes.CONFIRM_INVITATION_CODE_SUCCESS,
        payload: {},
      });
    } catch (error: any) {
      dispatch({
        type: InvitationActionTypes.CONFIRM_INVITATION_CODE_FAIL,
        error: error.response?.data.error,
      });
      onError();
    }
  };
};
