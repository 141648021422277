import { Player, getFullNameOfPlayer } from "./LeaderboardPlayersLeaders";

type Props = {
   player: Player;
   index: number;
};

export const LeaderboardPlayerLeaderListItem: React.FC<Props> = ({ player, index }) => {
   return (
      <div className="leader__list__item">
         <div className="leader__list__item__order">{index + 1}</div>
         <div className="leader__list__item__text-container">
            <a href={`/playerdetail/${player?.pid}?tab=career`} className="name-link">
               <span className="leader__list__item__text">{getFullNameOfPlayer(player)}</span>
            </a>
            <a href={`/franchisedetail/${player.utid}`} className="name-link">
            <span className="leader__list__item__text">{player?.teamName?.toUpperCase()}</span>
                 </a>
         </div>
         <div className="leader__list__item__text">{player.value}</div>
      </div>
   );
};
