import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export function useLoading() {
  const gameEngineStateLoader = useSelector(
    (state: RootState) => state.gameEngine.loader
  );
  const teamStateLoader = useSelector((state: RootState) => state.team.loader);
  const appLoader = useSelector((state: RootState) => state.app.loader);
  const settingLoader = useSelector((state: RootState) => state.setting.loader);
  return !!(gameEngineStateLoader || teamStateLoader || appLoader || settingLoader);
}
