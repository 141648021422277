import { TeamDepthChartEntry } from "types";

type Props = {
  color: 'grey' | 'black' | 'white';
  player: TeamDepthChartEntry;
  position?: string;
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
}

export const FormationItem: React.FC<Props> = ({
  color,
  player,
  position,
  style
}) => {
  return (
    <div style={style} className="formation-row-item">
      <div className={`formation-row-header formation-row-header-${color}`}>{position || player.pos}</div>
      <div className="formation-row-player">
        <div className="name-container">
          <div className="full-name">{player.firstName}</div>
          <div className="full-name">{player.lastName}</div>
        </div>
        <div className="rating">{Math.round(+player.ratings.ovr)}</div>
      </div>
    </div>
  )
}