import moment from 'moment';
import { useMemo, useState } from 'react';

export enum ProgressionTimeline {
  ONE_MONTH = 'one_month',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  LAST_TEN_GAMES = 'last_ten_games',
  LAST_TWENTY_GAMES = 'last_twenty_games',
  LAST_THIRTY_GAMES = 'last_thirty_games',
}

type SelectOption = { id: number; option: string; value: ProgressionTimeline };

export function useProgressionDropdown(playerMappedRatingHistory: any[]) {
  const [selectedTimeline, setSelectedTimeline] = useState<ProgressionTimeline>(ProgressionTimeline.ONE_MONTH);
  const handleSelectTimeline = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;

    setSelectedTimeline(value as ProgressionTimeline);
  };

  const timelineOptions: SelectOption[] = [
    {
      id: 1,
      value: ProgressionTimeline.LAST_TEN_GAMES,
      option: 'Last 10 games',
    },
    {
      id: 2,
      value: ProgressionTimeline.LAST_TWENTY_GAMES,
      option: 'Last 20 games',
    },
    { id: 3, value: ProgressionTimeline.ONE_MONTH, option: '1 month' },
    { id: 4, value: ProgressionTimeline.THREE_MONTHS, option: '3 months' },
  ];

  const ratingsHistory = useMemo(
    () =>
      playerMappedRatingHistory.filter((record, i, arr) => {
        const recordDate = moment(record.date);
        const currentDate = moment();
        if (selectedTimeline === ProgressionTimeline.ONE_MONTH) {
          return recordDate.isAfter(currentDate.subtract(1, 'month'));
        }
        if (selectedTimeline === ProgressionTimeline.THREE_MONTHS) {
          return recordDate.isAfter(currentDate.subtract(3, 'month'));
        }
        if (selectedTimeline === ProgressionTimeline.LAST_TEN_GAMES) {
          return i >= arr.length - 10;
        }
        if (selectedTimeline === ProgressionTimeline.LAST_TWENTY_GAMES) {
          return i >= arr.length - 20;
        }
        return true;
      }),
    [playerMappedRatingHistory, selectedTimeline],
  );

  return { selectedTimeline, handleSelectTimeline, timelineOptions, ratingsHistory };
}
