import BoxScoreRating from "../Common/BoxScoreRating/BoxScoreRating";
import helmetBlue from "../../assets/images/helmet/helmetBlue.png";
import helmetRed from "../../assets/images/helmet/helmetRed.png";
import { API_URL } from "./../../config/index";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useMemo } from "react";
import { useHelmets } from "helper/useHelmets";

export interface Props {
  lostTeam?: any;
  wonTeam?: any;
}

const FullTeamStatRating = ({ lostTeam, wonTeam }: Props) => {
  const teams = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview?.teams
  );  
  const helmets = useHelmets([lostTeam?.utid, wonTeam?.utid].filter(Boolean));
  const lostHelmet = helmets?.find((h) => h?.engId === lostTeam?.utid);
  const wonHelmet = helmets?.find((h) => h?.engId === wonTeam?.utid);

  let { gid } = useParams<{ gid: string }>();

  const currentTeams = useMemo(() => {
    const result = {
      0: null,
      1: null,
    };
    
    if (teams) {
      if (teams[0]?.utid === lostTeam?.utid) {
        result[0] = teams[0];
        result[1] = teams[1];
      } else {
        result[0] = teams[1];
        result[1] = teams[0];
      }

      return result;
    }

    return teams;
  }, [lostTeam?.utid, teams]);

  const data: any[] = [
    {
      id: 1,
      GID: gid,
      leftHelmet: lostHelmet ? `${API_URL}${lostHelmet.helmet}` : helmetBlue,
      leftHelmetLogo: `${API_URL}${lostHelmet?.logo}`,
      currentTeams,
      leftFranchiseName:
        lostTeam?.teamName || lostTeam?.name || "FRANCHISE NAME",
      leftTeamRating: lostTeam?.ovr ? lostTeam?.ovr : "- - -",
      rightTeamRating: wonTeam?.ovr ? wonTeam?.ovr : "- - -",
      rightFranchiseName:
        wonTeam?.teamName || wonTeam?.name || "FRANCHISE NAME",
      rightHelmet: wonHelmet ? `${API_URL}${wonHelmet.helmet}` : helmetRed,
      rightHelmetLogo: `${API_URL}${wonHelmet?.logo}`,
      team1q1:
        lostTeam?.stat?.ptsQtrs?.[0] || lostTeam?.stat?.ptsQtrs?.[0] === 0
          ? lostTeam?.stat?.ptsQtrs?.[0]
          : "- -",
      team1q2:
        lostTeam?.stat?.ptsQtrs?.[1] || lostTeam?.stat?.ptsQtrs?.[1] === 0
          ? lostTeam?.stat?.ptsQtrs?.[1]
          : "- -",
      team1q3:
        lostTeam?.stat?.ptsQtrs?.[2] || lostTeam?.stat?.ptsQtrs?.[2] === 0
          ? lostTeam?.stat?.ptsQtrs?.[2]
          : "- -",
      team1q4:
        lostTeam?.stat?.ptsQtrs?.[3] || lostTeam?.stat?.ptsQtrs?.[3] === 0
          ? lostTeam?.stat?.ptsQtrs?.[3]
          : "- -",
      team1t: lostTeam?.stat?.ptsQtrs
        ? lostTeam?.stat?.ptsQtrs?.reduce((a: number, b: number) => a + b, 0)
        : "- -",
      team2q1:
        wonTeam?.stat?.ptsQtrs?.[0] || wonTeam?.stat?.ptsQtrs?.[0] === 0
          ? wonTeam?.stat?.ptsQtrs?.[0]
          : "- -",
      team2q2:
        wonTeam?.stat?.ptsQtrs?.[1] || wonTeam?.stat?.ptsQtrs?.[1] === 0
          ? wonTeam?.stat?.ptsQtrs?.[1]
          : "- -",
      team2q3:
        wonTeam?.stat?.ptsQtrs?.[2] || wonTeam?.stat?.ptsQtrs?.[2] === 0
          ? wonTeam?.stat?.ptsQtrs?.[2]
          : "- -",
      team2q4:
        wonTeam?.stat?.ptsQtrs?.[3] || wonTeam?.stat?.ptsQtrs?.[3] === 0
          ? wonTeam?.stat?.ptsQtrs?.[3]
          : "- -",
      team2t: wonTeam?.stat?.ptsQtrs
        ? wonTeam?.stat?.ptsQtrs?.reduce((a: number, b: number) => a + b, 0)
        : "- -",
    },
  ];

  if (wonTeam?.stat?.ptsQtrs?.length 
      && wonTeam?.stat?.ptsQtrs?.length > 4
      && lostTeam?.stat?.ptsQtrs?.length 
      && lostTeam?.stat?.ptsQtrs?.length > 4) {
        data[0].team1ot = lostTeam?.stat?.ptsQtrs
        ? lostTeam?.stat?.ptsQtrs?.slice(4)?.reduce((a: number, b: number) => a + b, 0)
        : "- -"
        data[0].team2ot = wonTeam?.stat?.ptsQtrs
        ? wonTeam?.stat?.ptsQtrs?.slice(4)?.reduce((a: number, b: number) => a + b, 0)
        : "- -"
  }

  return (
    <>
      {data.map((d: any, i: any) => (
        <BoxScoreRating key={`BoxScoreRating${i}`} ratingtable data={d} />
      ))}
    </>
  );
};

export default FullTeamStatRating;
