import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import LeaguePaymentMain from "../../components/LeaguePayment/LeaguePaymentMain";
import LoadingScreen from "../../components/Common/LoadingScreen/LoadingScreen";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const LeaguePaymentScreen: React.FC = () =>{
    const gameEngineStateLoader = useSelector(
        (state: RootState) => state.gameEngine.loader
      );
    
    return (
        <>
        {!!gameEngineStateLoader && <LoadingScreen />}
            <div className="pagelayout" >
                <WithLoginNavbar />
                <LeaguePaymentMain />
            </div>
        </>
    );
}

export default LeaguePaymentScreen;
