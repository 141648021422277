import { useInvitationInfo } from "helper/useInvitationInfo";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { confirmInvitation } from "redux/actions/Invitation/actions/ConfirmInvitation";
import history from "../../routes/history";

export function InvitationConfirm() {
  const { inviteCode, inviteType } = useInvitationInfo();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inviteCode) {
      history.push("/");
      return;
    }
    dispatch(
      confirmInvitation({
        inviteCode,
        inviteType,
        onSuccess: () =>
          history.push(
            `/?signup=true&invite_code=${inviteCode}&type=${inviteType}`
          ),
        onError: () => history.push("/"),
      })
    );
  }, []);
  return <></>;
}
