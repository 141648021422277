import { useRef } from 'react';
import { useInfiniteScroll } from 'helper/useInfiniteScroll';
import { useServerSidePagination } from 'helper/useServerSidePagination';
import { GamesTable, GamesTableTemplate } from 'components/Common/GamesTable/GamesTable';
import { GameInProgress } from './GameInProgress';
import { useLobbyInProgressGames } from './hooks/useLobbyInProgressGames';
import "./LobbyGames.scss"

const IN_PROGRESS_GAMES_REP_PAGE = 5;

export const LobbyGamesContent = () => {
  const ref = useRef(null);
  const { page, handleNextPage } = useServerSidePagination();
  const lobbyInProgressGames = useLobbyInProgressGames(page, IN_PROGRESS_GAMES_REP_PAGE);
  useInfiniteScroll(ref, handleNextPage, lobbyInProgressGames.isLoading, lobbyInProgressGames.noNextPage);

  return (
    <GamesTable
      useFetchGames={() => lobbyInProgressGames}
      renderGamesTable={({ games, avatarsList, isLoading, noGames }) => (
        <GamesTableTemplate
          ref={ref}
          gamesData={{ games, avatarsList, isLoading, noGames }}
          renderHeading={() => <h5>GAMES IN PROGRESS</h5>}
          renderContent={() => (
            <>
              {isLoading && <h5>Loading...</h5>}
              {noGames && !isLoading && <h5>No games</h5>}
              {games.map((game) => (
                <GameInProgress
                  key={game.gid}
                  game={game}
                  avatarsList={avatarsList}
                />
              ))}
            </>
          )}
        />
      )}
    />
  );
}

