import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CreateGroup, EditGroup, GetGroup } from "redux/actions/Groups";
import { RootState } from "redux/store";
import { File as CustomFile } from "types/file";
import { GroupRequest, LeaguaCreationAccess } from "types/groups";

const leaguaCreationBooleanMap = {
  [LeaguaCreationAccess.ALL]: true,
  [LeaguaCreationAccess.ADMIN]: false,
};

type GroupForm = Pick<GroupRequest, "name" | "description"> & {
  manualApprove: boolean;
  leaquaCreationAll: boolean;
};

export function useGroupForm(groupId?: number) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.app.resData);
  const group = useSelector((state: RootState) => state.group.groupInfo);

  const [form] = Form.useForm();

  const [groupAvatarFile, setGroupAvatarFile] = useState<
    CustomFile | string | null
  >(null);

  useEffect(() => {
    if (groupId) {
      dispatch(GetGroup(groupId));
    }
  }, [groupId]);

  useEffect(() => {
    if (!group) return;
    if (!groupId) return;
    form.setFieldsValue({
      name: group.name,
      description: group.description,
      manualApprove: !group.autoApprove,
      leaquaCreationAll:
        leaguaCreationBooleanMap[group.leaguaCreation] || false,
    });

    if (group.avatar) {
      setGroupAvatarFile(group.avatar);
    }
  }, [group, groupId]);

  const handleUploadGroupAvatar = (file: CustomFile) => {
    setGroupAvatarFile(file);
  };

  const onFinish = (values: GroupForm) => {
    const valuesWithAvatar = {
      name: values.name,
      description: values.description,
      autoApprove: !values.manualApprove,
      leaguaCreation: values.leaquaCreationAll
        ? LeaguaCreationAccess.ALL
        : LeaguaCreationAccess.ADMIN,
      avatar:
        typeof groupAvatarFile !== "string" && groupAvatarFile
          ? groupAvatarFile
          : undefined,
    };

    if (groupId) {
      dispatch(
        EditGroup(groupId, valuesWithAvatar, {
          onSuccess: () => {
            history.push(`/group/${groupId}`);
          },
          onError: (msg) => message.error(msg),
        })
      );
    } else {
      dispatch(
        CreateGroup(valuesWithAvatar, {
          onSuccess: (msg) => {
            history.push(`/profile/${user?.id}`);
          },
          onError: (msg) => message.error(msg),
        })
      );
    }
  };

  return {
    groupAvatarFile,
    handleUploadGroupAvatar,
    onFinish,
    form,
  };
}
