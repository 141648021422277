import "./YouTubeEmded.scss";

type Props = {
  width?: string;
  height?: string;
  title?: string; 
  id: string;
  className?: string;
}

export const YouTubeEmded: React.FC<Props> = ({
  width = "100%",
  height = "100%",
  title = "",
  id,
  className
}) => {
  let containerClassName = "youtubeembed";
  if (className) {
    containerClassName += ` ${className}`;
  }
  
  return (
    <div className={containerClassName}>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${id}`}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  )
}