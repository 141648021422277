import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import ContactMain from '../../components/Contact/ContactMain';

const ContactScreen: React.FC = () => (
  <div className="pagelayout">
    <WithLoginNavbar />
    <ContactMain />
  </div>
);

export default ContactScreen;
