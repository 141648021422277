/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './DepthChartTabStyle.scss'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { RootState } from './../../../../redux/store'
import DefensiveDepthChartMain from '../../../DefensiveDepthChart/DefensiveDepthChartMain'
import { TeamDepthChartList } from '../../../../types'

interface Props {
  updateDepthChart: (depthCharts: TeamDepthChartList) => void
}

const DepthChartTabMain = ({ updateDepthChart }: Props) => {
  // get state
  const teamDepthChart = useSelector((state: RootState) => state.gameEngine.teamDepthChart)
  const teamGamePlanData = useSelector((state: RootState) => state.gameEngine.teamGamePlan)

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Row>
      <Col xl={12} className='mb-5'>
        <DefensiveDepthChartMain 
          teamDepthCharts={teamDepthChart}
          teamGamePlan={teamGamePlanData}
          updateDepthChart={updateDepthChart}
        />
      </Col>
    </Row>
  )
}

export default DepthChartTabMain
