import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getGameOfWeekId } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";

export default function useTeamWeakData() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGameOfWeekId());
  }, []);

  const gameOfWeekID = useSelector((state: RootState) => state.gameEngine.gameOfWeekID);

  return { gameOfWeekID };
}