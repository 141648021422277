import { useSelector } from "react-redux";
import { formations } from "../../DepthChartViewFormationModal";
import { RootState } from "redux/store";
import { TeamDepthChartList, TeamGamePlan } from "types";

export const useSelectedDepthData = (selectedFormation: keyof typeof formations) => {
  const teamDepthChart: TeamDepthChartList | null = useSelector(
    (state: RootState) => state.gameEngine.teamDepthChart
  );
  const teamGamePlanData: TeamGamePlan | null = useSelector(
    (state: RootState) => state.gameEngine.teamGamePlan
  );

  const possibleFormations = [];
  if (teamGamePlanData) {
    possibleFormations.push(...teamGamePlanData.schemes.offense.sets);
    possibleFormations.push(...(teamGamePlanData.schemes.defense.sets.map((set) => {
      const setCopy = JSON.parse(JSON.stringify(set));
      setCopy.sid += teamGamePlanData.schemes.offense.sets.length;
      return setCopy;
    })));
  }

  const selectedFormationData = possibleFormations.find((formation) => formation.name === selectedFormation);
  let selectedDepthChart: TeamDepthChartList[0] | null = null;

  if (selectedFormationData && teamDepthChart) {
    selectedDepthChart = teamDepthChart[selectedFormationData.sid];
  }

  return selectedDepthChart;
}