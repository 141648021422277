import { RenderHelmet } from "components/Common/RenderHelmet/RenderHelmet";
import OffensiveStrategy from "components/GamePlanSpecific/OffensiveStrategy";
import { UserTeamsList, OffensiveStrategy as OffStrat } from "types"

type Props = {
  selectedTeam?: UserTeamsList[0];
  offensiveStrategy: OffStrat;
  updateOffensiveStrategy: (key: keyof OffStrat, value: number) => void
}

export const SelectedTeamInfo: React.FC<Props> = ({
  selectedTeam,
  offensiveStrategy,
  updateOffensiveStrategy
}) => {
  if (!selectedTeam) {
    return null;
  }

  return (
    <div className="selected-team-info-container">
      <RenderHelmet
        {...selectedTeam}
        mirror={true}
        size={256}
      />
      <h4>{selectedTeam.teamName}</h4>
      <OffensiveStrategy
        offensiveStrategy={offensiveStrategy}
        updateOffensiveStrategy={updateOffensiveStrategy}
      />
    </div>
  )
}