import { CategoryScale, Chart, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Col } from 'react-bootstrap';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type Props = {
  ratingHistory: {
    date: number;
    value: number;
  }[];
};

const FranchiseCareerRating: React.FC<Props> = ({ ratingHistory }) => {
  if (!ratingHistory.length) return <h5>No rating history</h5>;
  const labels = ratingHistory.map((data) => {
    const date = new Date(data?.date || 0);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    return `${month}-${day}`;
  });

  const data = {
    labels,
    datasets: [
      {
        label: 'Rating History',
        data: ratingHistory.map((data) => data?.value || 0),
        fill: false,
        backgroundColor: '#384c6c',
        borderColor: '#384c6c',
        borderWidth: 1,
      },
    ],
  };
  return (
    <Col xl={12}>
      <Line options={{ responsive: true }} data={data} />
    </Col>
  );
};

export default FranchiseCareerRating;
