import { memo } from "react";
import { ScoreDigit, ScoreDigits } from "./ScoreDigits";
import "./Scoreboard.scss";

export interface Props {
  className?: string;
  timer?: string;
  scoreLeft?: number;
  scoreRight?: number;
  isBallInLeft?: number;
  tolLeft?: number;
  tolRight?: number;
  down?: number;
  toGo?: number;
  ballOn?: number;
  quarterCount?: number;
}

const Scoreboard: React.FC<Props> = memo(({
  timer,
  scoreLeft,
  scoreRight,
  isBallInLeft,
  tolLeft,
  tolRight,
  down,
  toGo,
  ballOn,
  quarterCount,
}) => {
  const timerDigist = timer?.split(':') || [];
  return (
    <div className="scoreboard__container">
      <div className="scoreboard__line">
        <div className="scoreboard__score">
          <p>HOME</p>
          <ScoreDigits twoDigits={scoreLeft} />
        </div>
        <div className="scoreboard__own-football">
          <div className={isBallInLeft === 0 ? "dottedball" : ""}></div>
        </div>
        <div className="scoreboard__time">
          <ScoreDigits twoDigits={timerDigist[0]} size="large" />
          <span className={`colon ${timer ? '' : 'off'}`} >:</span>
          <ScoreDigits twoDigits={timerDigist[1]} size="large" />
        </div>
        <div className="scoreboard__own-football">
          <div className={isBallInLeft === 1 ? "dottedball" : ""}></div>
        </div>
        <div className="scoreboard__score">
          <p>GUEST</p>
          <ScoreDigits twoDigits={scoreRight} />
        </div>

      </div>
      <div className="scoreboard__line">
        <div className="scoreboard__score little bottom-title">
          <ScoreDigit digit={tolLeft} size="little" />
          <p>T.O.L</p>
        </div>
        <div className="scoreboard__score small">
          <p>DOWN</p>
          <ScoreDigit digit={down} size="small" />
        </div>
        <div className="scoreboard__score small">
          <p>TO GO</p>
          <ScoreDigits twoDigits={toGo} size="small" />
        </div>
        <div className="scoreboard__score small">
          <p>BALL ON</p>
          <ScoreDigits twoDigits={ballOn} size="small" />
        </div>
        <div className="scoreboard__score small">
          <p>QTR</p>
          <ScoreDigit digit={quarterCount} size="small" />
        </div>
        <div className="scoreboard__score little bottom-title">
          <ScoreDigit digit={tolRight} size="little" />
          <p>T.O.L</p>
        </div>
      </div>
    </div>
  )
});

export default Scoreboard;