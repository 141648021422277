import { useState } from "react";
import { findUserName } from "./helper";

export const useUserNameAutoComplete = () => {
  const [foundUserNames, setFoundUserNames] = useState<string[]>([]);

  const handleSearch = (value: string) => {
    if (!!value.length) {
      findUserName(value).then((res) => {
        if (res?.length) {
          setFoundUserNames(res);
        }
      })
    } else {
      setFoundUserNames([]);
    }
  }

  return { foundUserNames, handleSearch };
}