import { League } from 'components/Common/LeagueHeader/LeagueHeader';

export const isLeaveAllowed = (history: NonNullable<League['history']>, userTeams: number[]) => {
  try {
    const userHistory = history
      .filter((r) => userTeams.some((team) => r.desc.includes(team.toString())))
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    const leaveAllowedAt = new Date(userHistory[0].timestamp).getTime() + 5 * 60 * 1000;

    const isPossible = userHistory.length > 0 && leaveAllowedAt - Date.now() < 0;
    const timeout = !isPossible ? leaveAllowedAt - Date.now() : null;

    return { isPossible, timeout };
  } catch (e) {
    return { isPossible: false, timeout: null}
  }
};
