import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export function useSettingsMainLoading(){
  const createPayoutLoader = useSelector((state: RootState) => state.app.createPayoutLoader);
  const savePaymentDataLoader = useSelector((state: RootState) => state.app.savePaymentDataLoader);
  const gameEngineDataLoader = useSelector((state:RootState) => state.gameEngine?.loader) === 1;
  const teamLoader = useSelector((state:RootState) => state.team?.loader) === 1;

  const isLoading = savePaymentDataLoader || createPayoutLoader || gameEngineDataLoader || teamLoader

  return isLoading
}