import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrophiesByTeamUtid } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';

export const useTeamTrophies = (utid?: number) => {
  const dispatch = useDispatch();
  const trophies = useSelector((state: RootState) => state.gameEngine.teamTrophies);

  useEffect(() => {
    if (utid) {
      dispatch(getTrophiesByTeamUtid(utid));
    }
  }, [utid]);

  return trophies;
};
