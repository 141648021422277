import { useEffect, useState } from "react";
import { useGMDComputerTeams } from "./hooks/useGMDComputerTeams";
import { TeamSelector } from "./TeamSelector";
import "./PlayFree.scss";
import { SelectedTeamInfo } from "./SelectedTeamInfo";
import { Col, Row } from "react-bootstrap";
import { OpponentTeamInfo } from "./OpponentTeamInfo";
import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { OffensiveStrategy } from "types";
import { postPlayTrialGame } from "./helpers/PostPlayTrialGame";
import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { useFreeGame } from "components/layout/WithLoginNavbar/hooks/useFreeGame";
import history from "routes/history";

export const PlayFreeMain: React.FC = () => {
  const [selectedTeamId, setSelectedTeamId] = useState(0);
  const [opponentTeamId, setOpponentTeamId] = useState(0);
  const [offensiveStrategy, setOffensiveStrategy] = useState<OffensiveStrategy>({
    "1stDownRun": 50,
    deviation: 50,
    goOnFourth: 50,
    insideRuns: 50,
    longYdsRun: 50,
    pctPassGun: 50,
    shortYdsRun: 50,
  });
  const { gid } = useFreeGame();
  useEffect(() => {
    if (gid) {
      history.push(`/gameplay?id=${gid}`);
    }
  }, [gid]);

  const updateOffensiveStrategyValue = (key: keyof OffensiveStrategy, value: number) => {
    setOffensiveStrategy((prevStrategy) => ({
      ...prevStrategy,
      [key]: value,
    }));
  };
  const data = useGMDComputerTeams();

  useEffect(() => {
    if (selectedTeamId && data) {
      const idsWithoutSelected = data.filter((team) => team.engId !== selectedTeamId).map((team) => team.engId);
      const randomIndex = Math.floor(Math.random() * (idsWithoutSelected.length));
      setOpponentTeamId(idsWithoutSelected[randomIndex]);
    }
  }, [selectedTeamId, data]);

  return (
    <Card>
      <CardBody>
      <div className="play-free-main">
        {data && (
          <>
            <h4 className="play-free-header">PLAY FOR FREE</h4>
            <h6 className="play-free-header">SELECT TEAM AND COMPETE!</h6>
            <Row>
              <Col xl={3} xs={12}>
                <SelectedTeamInfo 
                  selectedTeam={data.find(team => team.engId === selectedTeamId)}
                  offensiveStrategy={offensiveStrategy}
                  updateOffensiveStrategy={updateOffensiveStrategyValue}
                />
              </Col>
              <Col xl={6} xs={12}>
                <div className="play-free-main-column-content-centered">
                  <TeamSelector
                    selectedTeamId={selectedTeamId}
                    setSelectedTeamId={setSelectedTeamId}
                    opponentTeamId={opponentTeamId}
                    teams={data}
                  />
                </div>
              </Col>
              <Col xl={3} xs={12}>
                <OpponentTeamInfo 
                  selectedTeam={data.find(team => team.engId === opponentTeamId)}
                />
              </Col>
            </Row>
            <div className="play-now-button-container">
              <PinkButton 
                text="PLAY NOW!" 
                disabled={selectedTeamId === 0}
                onClick={() => postPlayTrialGame([selectedTeamId, opponentTeamId], offensiveStrategy)} 
              />
            </div>
          </>
        )}
      </div>
      </CardBody>
    </Card>
  );
};
