import { Select } from "antd";

const RACE = [
  'White',
  'Caucasian',
  'Black',
  'Asian',
  'Indigenous',
  'Pacific Islander',
  'Mixed',
  'Hispanic',
  'Middle Eastern'
];
const BACKGROUND = [
  'Fearless overachiever',
  'Street-smart hustler',
  'Reformed criminal',
  'Survivor of broken family',
  'Legacy, father played',
  'Bullied, inner strength',
  'Neglected, self-reliance',
  'Poverty to prosperity.',
  'Abuse, resilience',
  'Homeless to hopeful',
  'Troubled, redemption',
  'Childhood illness triumph',
  'Substance abuse recovery',
  'Learning disability triumph',
  'Broken, rebuilt self',
  'Inner-city escapee',
  'Parental incarceration survivor',
  'Refugee, resilience'
];
const LOCATION = [
  'New York City', 'Miami',
  'Chicago', 'Midwest',
  'Atlanta', 'Rust belt',
  'New England', 'Southern California',
  'Rocky Mountains', 'Las Vegas',
  'Canada', 'Pacific Northwest',
  'Texas', 'Bayou',
  'Outer banks', 'Hawaii',
  'Central States', 'Philadelphia',
  'Dallas', 'Mexico',
  'Corn Belt', 'Gotham',
  'Appalachia', 'Great Lakes',
  'Overseas'
];
const FAMILY_SOCIAL_STATUS = [
  'Wealthy',
  'Upper Class',
  'Affluent',
  'Middle Class',
  'Lower Middle Class',
  'Blue Colar',
  'Working poor',
  'Poor',
  'Poverty'
];
const STUDENT = ['Good', 'Bad', 'Average', 'Top Of Class'];
const TROUBLE = ['None', 'Mild', 'Always'];
const STORY = ['Feel Good', 'Hard Luck', 'Average Joe'];
const POSITION = [
  { value: 'DL', label: 'Defensive Line' },
  { value: 'QB', label: 'Quarterback' },
  { value: 'RB', label: 'Running Back' },
  { value: 'WR', label: 'Wide Receiver' },
  { value: 'OL', label: 'Offensive Line' },
  { value: 'TE', label: 'Tight End' },
  { value: 'CB', label: 'Cornerback' },
  { value: 'S', label: 'Safety' },
  { value: 'K', label: 'Kicker' },
  { value: 'HC', label: 'Head Coach' },
  { value: 'GM', label: 'General Manager' },
];
const FAMILY = [
  'Two parents',
  'Single mom',
  'Only child',
  'Single dad',
  'Raised by grandmother',
  'Twins',
  'Adopted',
  'Extended family',
  'Many siblings'
];
const FOOTBALL_BACKGROUND = [
  'Overlooked underdog',
  '5 star recruit',
  'Underrated high schooler',
  'Small-town standout',
  'Late bloomer talent',
  'Unrecruited college prospect',
  'Converted track athlete',
  'Freshman phenom',
  'Backup turned starter',
  'Former walk-on',
  'Small college surprise',
  'Canadian Football League',
  'Open tryout success'
];
const EDUCATION = [
  'Highschool Dropout',
  'Communications major',
  'Criminal Science major',
  'Business major',
  'Unfinished degree',
  'Graduate degree',
  'Bachelor of Science'
];
const ACCOMPLISHMENTS = [
  'All-Academic Team',
  'All American',
  'Team Captain',
  'Team MVP',
  'National MVP',
  'None',
  '4 year starter',
  'Most improved player',
  'Community Leader Award',
  'Spirit Award'
];
const INSPIRATION = [
  'Wonders of the natural world.',
  'Human Resilience',
  'Art and Creativity',
  'Acts of Kindness',
  'Positive Role Models',
  'Personal Growth and achievement',
  'Love and Relationships',
  'History and Legacy',
  'Innovations and Discoveries',
  'Leadership and Courage',
  'Philanthropy and Service',
  'Community and Unity',
  'Mentorship and Guidance',
  'Spirituality and Faith',
  'Wisdom and Knowledge',
  'Exploration and New Discoveries',
  'Hope and Optimism'
];
const RELATIONSHIP_STATUS = [
  'Single',
  'Committed Relationship',
  'Engaged',
  'Married',
  'Divorced',
  'Separated',
  'Widowed',
  'Open Relationship',
  'Multiple romantic partners',
  'Dating',
  'Its Complicated',
  'Friends with Benefits',
  'Cohabiting/ Living Together'
];
const BACKGROUND_MOOD = [
  'Joyful', 'Gloomy',
  'Suspenseful', 'Mysterious',
  'Whimsical', 'Romantic',
  'Eerie', 'Hopeful',
  'Tense', 'Humorous',
  'Surreal', 'Reflective',
  'Futuristic', 'Nostalgic',
  'Action-Packed', 'Tragic',
  'Empowering', 'Chaotic',
  'Enthralling:'
];

const PLAYER_PROFILE_CONSTANTS = {
  RACE,
  BACKGROUND,
  LOCATION,
  FAMILY_SOCIAL_STATUS,
  STUDENT,
  TROUBLE,
  STORY,
  POSITION,
  FAMILY,
  FOOTBALL_BACKGROUND,
  EDUCATION,
  ACCOMPLISHMENTS,
  INSPIRATION,
  RELATIONSHIP_STATUS,
  BACKGROUND_MOOD
}

const getOptionsByConstantName = (constantName: keyof typeof PLAYER_PROFILE_CONSTANTS) => {
  return (
    PLAYER_PROFILE_CONSTANTS[constantName].map((constant) => (
      typeof constant === 'object' ? (
        <Select key={constant.value} value={constant.value}>{constant.label}</Select>
      ) : (
        <Select key={constant} value={constant}>{constant}</Select>
      )
    ))
  )
}

const getRandomOptionByConstantName = (constantName: keyof typeof PLAYER_PROFILE_CONSTANTS) => {
  const options = PLAYER_PROFILE_CONSTANTS[constantName];
  const randomIndex = Math.floor(Math.random() * options.length);
  return options[randomIndex];
}

export const PlayerProfileOptions = {
  getOptionsByConstantName,
  getRandomOptionByConstantName,
  PLAYER_PROFILE_CONSTANTS
}
