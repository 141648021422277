import Title from "components/Common/Title/Title"
import { Col, Row } from "react-bootstrap"
import { LeaderboardTeamLeadersWins } from "./LeaderboardTeamLeadersWins"
import { LeaderboardTeamLeadersPlayed } from "./LeaderboardTeamLeadersPlayed"
import { LeaderboardTeamLeadersScored } from "./LeaderboardTeamLeadersScored"
import { LeaderboardTeamLeadersConsWins } from "./LeaderboardTeamLeadersConsWins"
import { LeaderboardTeamLeadersWinRate } from "./LeaderboardTeamLeadersWinRate"
import { LeaderboardTeamLeadersPlayoffs } from "./LeaderboardTeamLeadersPlayoffs"

export const LeaderboardTeamLeaders = () => {
  return (
    <>
      <Title text="TEAM LEADERS"/>
      <Row>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Title text="WINS"/>
            <LeaderboardTeamLeadersWins />
            <Title text="MOST POINTS SCORED"/>
            <LeaderboardTeamLeadersScored />
            <Title text="HIGHEST WINNING %"/>
            <LeaderboardTeamLeadersWinRate />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Title text="MOST GAMES PLAYED"/>
            <LeaderboardTeamLeadersPlayed />
            <Title text="MOST CONSECUTIVE WINS"/>
            <LeaderboardTeamLeadersConsWins />
            <Title text="MOST PLAYOFF WINS"/>
            <LeaderboardTeamLeadersPlayoffs />
        </Col>
      </Row>
    </>
  )
}