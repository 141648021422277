import { useDispatch } from "react-redux";
import { setInGameDepthOrPlanAsDefault } from "./helpers/SetInGameDepthOrPlanAsDefault";
import "./In-GameButton.scss";
import { PregameOverview } from "redux/actions/GameEngineAction";
import { EliminationTeamsTooltip } from "components/FranchiseOverview/TabDetail/OverviewTab/EliminationTeamsTooltip";

type Props = {
  gid: number;
  utid: number;
  depthOrPlan: 'depth' | 'plan';
}

export const InGameButton: React.FC<Props> = ({
  gid,
  utid,
  depthOrPlan
}) => {
  const dispatch = useDispatch();

  return (
    <EliminationTeamsTooltip
      text="SAVE AS DEFAULT"
      containerClassName="in-game-button-tooltip"
    >
      <span 
        onClick={() => {
          setInGameDepthOrPlanAsDefault(utid, gid, depthOrPlan);
          dispatch(PregameOverview(gid));
        }}
        className="in-game-button"
      >
        In-Game
      </span>
    </EliminationTeamsTooltip>
  )
}