import { Col } from "react-bootstrap";
import Input from "../../components/Common/Form/Input/Input";
import { LabelWithStatus } from "./LabelWithStatus";
import { Spin } from "antd";

type Props = {
  username: string;
  setUsername: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  usernameLoader: boolean;
  emailLoader: boolean;
};

const SignupScreenFirstStep: React.FC<Props> = ({
  username,
  setUsername,
  email,
  setEmail,
  usernameLoader,
  emailLoader,
}) => (
  <>
    <Col xl={12} className="loginuser">
      <Input
        type="text"
        label={<LabelWithStatus label="Username *" status={usernameLoader && <Spin className="red-spin" />} />}
        value={username}
        onChange={setUsername}
        placeholder="Enter Username"
        classNameLabel="d-block"
      />
    </Col>
    <Col xl={12} className="loginuser">
      <Input
        type="email"
        label={<LabelWithStatus label="Email *" status={emailLoader && <Spin className="red-spin"/>} />}
        value={email}
        onChange={setEmail}
        placeholder="Enter Email"
        classNameLabel="d-block"
      />
    </Col>
  </>
);

export default SignupScreenFirstStep;
