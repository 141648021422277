import LeagueOverviewLeader from '../../../Common/LeagueOverviewTab/LeagueOverviewLeader';
import { Row, Col } from 'react-bootstrap';
import { useEliminationLeaders } from 'helper/useEliminationLeaders';
import { getTeamLeadersForFranchiseOverview } from 'helper/helper';
// import { getTeamLeadersForEliminationOverview } from 'helper/helper';

type Props = {
  gid: number;
};

const EliminationLeagueLeader: React.FC<Props> = ({ gid }) => {
  const eliminationLeaders = useEliminationLeaders(gid);
  const tableData = eliminationLeaders ? getTeamLeadersForFranchiseOverview(eliminationLeaders) : [];

  if (!tableData.length) {
    return null;
  }

  return (
    <Row>
      <h5 className="title">LEAGUE LEADERS</h5>
      {tableData.map((d) => (
        <Col xl={6}>
          <LeagueOverviewLeader {...d} />
        </Col>
      ))}
    </Row>
  );
};

export default EliminationLeagueLeader;
