import { GroupInvitations } from "components/Group/GroupInvitations";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export function GroupInvitationsScreen() {
  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      <GroupInvitations />
    </div>
  );
}
