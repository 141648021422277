import { LeagueOverviewResultsItem } from "components/Common/LeagueOverviewTab/LeagueOverviewResultsItem";
import Title from "components/Common/Title/Title";
import { useSeasonResults } from "./hooks/useSeasonResults";
import { useSeasonInfo } from "screens/Season/hooks/useSeasonInfo";
import { PaginationPages } from "components/HistoricalResults/PaginationPages";

export const Results: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();
  const {
    handleNextPage,
    handlePrevPage,
    leagueResults,
    page,
    setPage,
  } = useSeasonResults(seasonInfo?.id);

  return (
    <>
      <div className="resulttitle mb-4">
        <Title text="league results" />
        <PaginationPages 
          page={page}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          nextDisabled={!leagueResults?.hasNextPage}
          onPageClick={(e, shift) => {
            const pageToSet = +(e.target as HTMLElement).innerText + (shift || 0);
            setPage(pageToSet);
          }}
         />
      </div>
      {(!!leagueResults && !!leagueResults?.data?.length) && (
        leagueResults?.data?.map(game => (
          <LeagueOverviewResultsItem game={game} key={game.gid}/>
        ))
      )}
    </>
  );
};

