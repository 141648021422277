import { DL } from './DL';
import { QB } from './QB';
import { RB } from './RB';
import { WR } from './WR';
import { OL } from './OL';
import { TE } from './TE';
import { CB } from './CB';
import { S } from './S';
import { K } from './K';
import { HC } from './HC';
import { GM } from './GM';

export default {
  DL,
  QB,
  RB,
  WR,
  OL,
  TE,
  CB,
  S,
  K,
  HC,
  GM,
  P: K,
  AG: GM,
  AC: HC
};
