export const purchaseAmountTable = [
  {
    title: "PURCHASE",
    dataIndex: "purchase",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
  },
];
