import { Container } from "react-bootstrap";
import LeagueEnteredView from "./LeagueEnteredView";
import LeagueAvailableMain from "./LeagueAvailableMain";
import "./LeagueEnterStyle.scss";
import OwnFranchiseHeader from "../OwnFranchise/OwnFranchiseHeader";

const LeagueEnterMain: React.FC = () => (
  <Container>
    <OwnFranchiseHeader />
    <LeagueEnteredView />
    <LeagueAvailableMain />
  </Container>
);

export default LeagueEnterMain;
