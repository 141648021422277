import { GroupInviteMember } from "components/Group/GroupInvite/GroupInviteMember";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export function GroupInviteScreen() {
  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      <GroupInviteMember />
    </div>
  );
}
