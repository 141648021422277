import ProfileImageBackup from "../../../assets/images/common/userName.png";
import OnlineIndicator from "../../../assets/images/common/wifi.svg";
import history from "routes/history";
import { API_URL } from "config";
import { GroupMemberUserWithNetworkStatus } from "types/groups";
import { NetworkStatus } from "types/user";

export function GroupMember({ data }: { data: Partial<GroupMemberUserWithNetworkStatus> }) {
  const isOnline = Boolean(data.networkStatus === NetworkStatus.OnLine);
  const handleNavigation = () => history.push(`/profile/${data.id}`);

  const profileImg = data?.avatar
    ? `${API_URL}/images/avatar/${data?.avatar}`
    : ProfileImageBackup;

  return (
    <div onClick={handleNavigation} className="friend">
      <div className="img__container">
        <img src={profileImg} alt="profile" />
        {isOnline && (
          <div className="indicator">
            <img src={OnlineIndicator} alt="online" />
          </div>
        )}
      </div>
      <p>{data.userName}</p>
    </div>
  );
}
