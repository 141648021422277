import { Modal } from "antd";
import { useInviteFriendsForm } from "../InviteFriends/hooks/use-invite-friends-form";
import { InviteFriendsModalContent } from "components/InviteFriends/InviteFriendsModalContent";

export function InviteFriendPopup({
  isModalOpen,
  handleCloseModal,
  receiverCredits,
  senderCredits,
  gameId,
}: {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  receiverCredits?: number;
  senderCredits?: number;
  gameId: number;
}) {
  const {
    emails,
    handleEmailsChange,
    message,
    handleMessageChange,
    handleSubmit,
    errors,
    isLoading
  } = useInviteFriendsForm();

  let title = "Get your Friends in the game";
  if (senderCredits) {
    title += ` and get $${senderCredits}`;
  }
  if (receiverCredits) {
    title += ` and your Friends get $${receiverCredits}!!`;
  }

  return (
    <Modal
      open={isModalOpen}
      className="verification PreviewModal"
      footer={[]}
      onCancel={handleCloseModal}
    >
      <InviteFriendsModalContent
        emails={emails}
        errors={errors}
        handleEmailsChange={handleEmailsChange}
        handleMessageChange={handleMessageChange}
        handleSubmit={handleSubmit}
        message={message}
        title={title}
        isLoading={isLoading}
        gameId={gameId}
      />
    </Modal>
  );
}