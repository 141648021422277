import Card from "../../../Common/Card/Card";
import CardBody from "../../../Common/Card/CardBody";
import { Row, Col } from "react-bootstrap";
import LeagueOverviewTeamLeaders from "./LeagueOverviewTeamLeaders";
import LeagueOverviewTeamStat from "./LeagueOverviewTeamStat";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Sticker1 from "../../../../assets/images/player/QB.png";
import Sticker2 from "../../../../assets/images/player/RB.png";
import Sticker3 from "../../../../assets/images/player/WR.png";
import Sticker4 from "../../../../assets/images/player/LB.png";
import { Link } from "react-router-dom";
import history from "../../../../routes/history";

const LeagueOverviewMain: React.FC = () => {
  const UserData = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview
  );

  const teams = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview?.teams
  );
  const data2 = [
    {
      id: UserData?.teams?.[1]?.leaders?.passLeader?.pid,
      leagueimage: Sticker1,
      leaguename: "PASSING",
      leagueperson: `${UserData?.teams?.[1]?.leaders?.passLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.passLeader?.firstName
        } ${UserData?.teams?.[1]?.leaders?.passLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.passLeader?.lastName
        }`,
      leaguedigit: Math.round(UserData?.teams?.[1]?.leaders?.passLeader?.avgValue),
      leaguedigitname: "YPG",
    },
    {
      id: UserData?.teams?.[1]?.leaders?.rushLeader?.pid,
      leagueimage: Sticker2,
      leaguename: "RUSHING",
      leagueperson: `${UserData?.teams?.[1]?.leaders?.rushLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.rushLeader?.firstName
        } ${UserData?.teams?.[1]?.leaders?.rushLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.rushLeader?.lastName
        }`,
      leaguedigit: Math.round(UserData?.teams?.[1]?.leaders?.rushLeader?.avgValue),
      leaguedigitname: "YPG",
    },
    {
      id: UserData?.teams?.[1]?.leaders?.recLeader?.pid,
      leagueimage: Sticker3,
      leaguename: "RECEIVING",
      leagueperson: `${UserData?.teams?.[1]?.leaders?.recLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.recLeader?.firstName
        } ${UserData?.teams?.[1]?.leaders?.recLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.recLeader?.lastName
        }`,
      leaguedigit: Math.round(UserData?.teams?.[1]?.leaders?.recLeader?.avgValue),
      leaguedigitname: "YPG",
    },
    {
      id: UserData?.teams?.[1]?.leaders?.tckLeader?.pid,
      leagueimage: Sticker4,
      leaguename: "TACKLES",
      leagueperson: `${UserData?.teams?.[1]?.leaders?.tckLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.tckLeader?.firstName
        } ${UserData?.teams?.[1]?.leaders?.tckLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[1]?.leaders?.tckLeader?.lastName
        }`,
      leaguedigit: UserData?.teams?.[1]?.leaders?.tckLeader?.avgValue,
      leaguedigitname: "TPG",
    },
  ];
  const data1 = [
    {
      id: UserData?.teams?.[0]?.leaders?.passLeader?.pid,
      leagueimage: Sticker1,
      leaguename: "PASSING",
      leagueperson: `${UserData?.teams?.[0]?.leaders?.passLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.passLeader?.firstName
        } ${UserData?.teams?.[0]?.leaders?.passLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.passLeader?.lastName
        }`,
      leaguedigit: Math.round(UserData?.teams?.[0]?.leaders?.passLeader?.avgValue),
      leaguedigitname: "YPG",
    },
    {
      id: UserData?.teams?.[0]?.leaders?.rushLeader?.pid,
      leagueimage: Sticker2,
      leaguename: "RUSHING",
      leagueperson: `${UserData?.teams?.[0]?.leaders?.rushLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.rushLeader?.firstName
        } ${UserData?.teams?.[0]?.leaders?.rushLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.rushLeader?.lastName
        }`,
      leaguedigit: Math.round(UserData?.teams?.[0]?.leaders?.rushLeader?.avgValue),
      leaguedigitname: "YPG",
    },
    {
      id: UserData?.teams?.[0]?.leaders?.recLeader?.pid,
      leagueimage: Sticker3,
      leaguename: "RECEIVING",
      leagueperson: `${UserData?.teams?.[0]?.leaders?.recLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.recLeader?.firstName
        } ${UserData?.teams?.[0]?.leaders?.recLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.recLeader?.lastName
        }`,
      leaguedigit: Math.round(UserData?.teams?.[0]?.leaders?.recLeader?.avgValue),
      leaguedigitname: "YPG",
    },
    {
      id: UserData?.teams?.[0]?.leaders?.tckLeader?.pid,
      leagueimage: Sticker4,
      leaguename: "TACKLES",
      leagueperson: `${UserData?.teams?.[0]?.leaders?.tckLeader?.firstName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.tckLeader?.firstName
        } ${UserData?.teams?.[0]?.leaders?.tckLeader?.lastName === undefined
          ? ""
          : UserData?.teams?.[0]?.leaders?.tckLeader?.lastName
        }`,
      leaguedigit: UserData?.teams?.[0]?.leaders?.tckLeader?.avgValue,
      leaguedigitname: "TPG",
    },
  ];

  const FirstTeamStats = [
    {
      key: "1",
      POINT_PER_GAME:
        UserData?.teams?.[0]?.ppg !== undefined ? UserData?.teams?.[0]?.ppg : 0,
      TOTAL_YARDS:
        UserData?.teams?.[0]?.ydsPg !== undefined
          ? UserData?.teams?.[0]?.ydsPg
          : 0,
      YARDS_PER_PLAY:
        UserData?.teams?.[0]?.ydsPp !== undefined
          ? UserData?.teams?.[0]?.ydsPp
          : 0,
      PASSING_YARDS:
        UserData?.teams?.[0]?.pssYdsPg !== undefined
          ? UserData?.teams?.[0]?.pssYdsPg
          : 0,
      COMP_ATT: `${UserData?.teams?.[0]?.pssCmpPg !== undefined
          ? Math.round(UserData?.teams?.[0]?.pssCmpPg)
          : 0
        } - ${UserData?.teams?.[0]?.pssAttPg !== undefined
          ? Math.round(UserData?.teams?.[0]?.pssAttPg)
          : 0
        }`,
      YARD_PER_PASS:
        UserData?.teams?.[0]?.ydsPp !== undefined
          ? UserData?.teams?.[0]?.ydsPp
          : 0,
      RUSHING_YARDS:
        UserData?.teams?.[0]?.rusYdsPg !== undefined
          ? UserData?.teams?.[0]?.rusYdsPg
          : 0,
      RUSHING_ATTEMPTS:
        UserData?.teams?.[0]?.rusPg !== undefined
          ? UserData?.teams?.[0]?.rusPg
          : 0,
      YARDS_PER_RUSHING:
        UserData?.teams?.[0]?.ydsPerRush !== undefined
          ? UserData?.teams?.[0]?.ydsPerRush
          : 0,
    },
  ];
  const SecondTeamStats = [
    {
      key: "1",
      POINT_PER_GAME:
        UserData?.teams?.[1]?.ppg !== undefined ? UserData?.teams?.[1]?.ppg : 0,
      TOTAL_YARDS:
        UserData?.teams?.[1]?.ydsPg !== undefined
          ? UserData?.teams?.[1]?.ydsPg
          : 0,
      YARDS_PER_PLAY:
        UserData?.teams?.[1]?.ydsPp !== undefined
          ? UserData?.teams?.[1]?.ydsPp
          : 0,
      PASSING_YARDS:
        UserData?.teams?.[1]?.pssYdsPg !== undefined
          ? UserData?.teams?.[1]?.pssYdsPg
          : 0,
      COMP_ATT: `${UserData?.teams?.[1]?.pssCmpPg !== undefined
          ? Math.round(UserData?.teams?.[1]?.pssCmpPg)
          : 0
        } - ${UserData?.teams?.[1]?.pssAttPg !== undefined
          ? Math.round(UserData?.teams?.[1]?.pssAttPg)
          : 0
        }`,
      YARD_PER_PASS:
        UserData?.teams?.[1]?.ydsPp !== undefined
          ? UserData?.teams?.[1]?.ydsPp
          : 0,
      RUSHING_YARDS:
        UserData?.teams?.[1]?.rusYdsPg !== undefined
          ? UserData?.teams?.[1]?.rusYdsPg
          : 0,
      RUSHING_ATTEMPTS:
        UserData?.teams?.[1]?.rusPg !== undefined
          ? UserData?.teams?.[1]?.rusPg
          : 0,
      YARDS_PER_RUSHING:
        UserData?.teams?.[1]?.ydsPerRush !== undefined
          ? UserData?.teams?.[1]?.ydsPerRush
          : 0,
    },
  ];
  return (
    <div className="mt-3">
      <Card>
        <CardBody>
          <Row>
            <Col xl={6} lg={6}>
              <h5>TEAM LEADERS</h5>
              {data1.map((d: any, i: any) => (
                <LeagueOverviewTeamLeaders data={d} key={i} />
              ))}
              <Link to={`/fullplayerstat/${UserData?.teams?.[0]?.utid}/${UserData?.gid}`} className="fullplayerstat" >Full player stats</Link>
              <LeagueOverviewTeamStat
                gid={UserData?.gid}
                data={FirstTeamStats}
                ownerId={teams?.[0]?.ownerId}
                utid={UserData?.teams?.[0]?.utid}
              />
            </Col>
            <Col xl={6} lg={6}>
              <h5>TEAM LEADERS</h5>
              {data2.map((d: any, i: any) => (
                <LeagueOverviewTeamLeaders data={d} key={i} />
              ))}
              <Link
                to={{ 
                  pathname: `/fullplayerstat/${UserData?.teams?.[1]?.utid}/${UserData?.gid}`,
                  state: history?.location?.state,
                }}
                className="fullplayerstat"
              >
                Full player stats
              </Link>
              <LeagueOverviewTeamStat
                gid={UserData?.gid}
                data={SecondTeamStats}
                ownerId={teams?.[1]?.ownerId}
                utid={UserData?.teams?.[1]?.utid}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default LeagueOverviewMain;
