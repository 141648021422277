import LegendFranchiseMain from "../../components/LegendFranchise/LegendFranchiseMain";
import NavbarWithOutLogin from "../../components/layout/WithOutLoginNavbar/NavbarWithOutLogin";

const LegendFranchiseScreen = () => {
  return (
    <>
      <div className="pagelayout">
        <NavbarWithOutLogin about login />
        <LegendFranchiseMain />
      </div>
    </>
  );
};

export default LegendFranchiseScreen;
