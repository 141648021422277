import Title from 'components/Common/Title/Title';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';
import { useSeasonDivisonStats } from './hooks/useDivisionStandings';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Table, TableProps } from 'antd';

const columns: TableProps<any>['columns'] = [
  {
    title: '',
    dataIndex: 'teamName',
  },
  {
    title: 'W',
    dataIndex: 'won',
    align: 'center',
  },
  {
    title: 'L',
    dataIndex: 'lost',
    align: 'center',
  },
  {
    title: 'T',
    dataIndex: 'tied',
    align: 'center',
  },
];

type Props = {
  handleRedirectToStandings: () => void;
}

export const SeasonDivisionStats: React.FC<Props> = ({ handleRedirectToStandings }) => {
  const { seasonInfo } = useSeasonInfo();
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);
  const allDivisions = seasonInfo?.conferences?.flatMap((conference) => conference?.divisions);

  const divisionId = allDivisions?.find((div) => div.teams.some((t) => userTeams?.some((ut) => ut.engId === t.team?.utid)))?.id;

  const divisionStandings = useSeasonDivisonStats(divisionId);

  if (!divisionStandings) return null;

  return (
    <div>
      <Title text="Division Standings" />
      <div className="rankStandTable rankingTable">
        <Table columns={columns} pagination={false} dataSource={divisionStandings.map(s => ({ ...s, tied: s?.tied || 0 }))} size="small" />
      </div>
      <span className="link-style" onClick={handleRedirectToStandings}>FULL STANDINGS</span>
    </div>
  );
};
