import { SubTabs } from "components/Common/SubTabs/SubTabs";
import { StandingSubTabs } from "./types";

type Props = {
  selectedTab: StandingSubTabs;
  setSelectedTab: (tab: StandingSubTabs) => void;
}

export const StandingTabs: React.FC<Props> = ({ selectedTab, setSelectedTab }) => (
  <SubTabs 
    selectedTab={selectedTab}
    setSelectedTab={setSelectedTab}
    tabList={["DIVISION", "CONFERENCE", "PLAYOFF BRACKET"]}
  />
);
