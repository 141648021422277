import "./ProtfolioNoFranchisesStyle.scss";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import NoFranchises from "../../components/NoFranchises/NoFranchisesMain";
const ProtfolioNoFranchisesScreen = () => {
  return (
    <>
      <div className="pagelayout">
        <WithLoginNavbar />
        <NoFranchises />
      </div>
    </>
  );
};

export default ProtfolioNoFranchisesScreen;
