import { useServerSidePagination } from "helper/useServerSidePagination";
import { LobbyList } from "../common/LobbyList";
import { useLobbyUsers } from "./hooks/useLobbyUsers";
import { LobbyListItemType } from "../common/types";
import { API_URL } from "config";
import { useInfiniteScroll } from "helper/useInfiniteScroll";
import { useRef } from "react";
import fallbackImage from "../../../assets/images/common/userName.png";

const USERS_PER_PAGE = 3;

export const LobbyUsers: React.FC = () => {
  const ref = useRef(null);
  const { page, handleNextPage } = useServerSidePagination();
  const { lobbyUsers, isLoading, noNextPage } = useLobbyUsers(page, USERS_PER_PAGE);
  useInfiniteScroll(ref, handleNextPage, isLoading, noNextPage);


  const lobbyListItems: LobbyListItemType[] = lobbyUsers.map((user) => ({
    image: user?.avatar ? `${API_URL}/images/avatar/${user.avatar}` : fallbackImage,
    name: user.username,
    text: `${user.teamName} | ${user.teamRating}`,
    rightNumber: user.totalPrestigePoints || "0",
    id: user.id,
  }));

  return <LobbyList title="ACTIVE USERS" lobbyListItems={lobbyListItems} ref={ref}/>;
};
