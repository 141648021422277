import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { SeasonScheduleList } from "./SeasonScheduleList/SeasonScheduleList";
import { useSeasonInfo } from "screens/Season/hooks/useSeasonInfo";

export const Schedule: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();

  if (!seasonInfo) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <SeasonScheduleList weeks={seasonInfo.groupStage.weeks} />
      </CardBody>
    </Card>
  )
};
