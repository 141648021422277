import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function validatedTab(queryTab: string | null, validOptions: string[]): string | null {
  if (!queryTab) return null;
  const isValid = validOptions.includes(queryTab);
  return isValid ? queryTab : null;
}

export const useQueryTabs = <T>(possibleTabs: string[]) => {
  const history = useHistory();
  const location = useLocation();

  const currentRoute = location.pathname;

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const tabParam = queryParams.get('tab');

  const redirectBackParam = queryParams.get('redirectBack');

  const [tabKey, setTabKey] = useState<(typeof possibleTabs)[number]>(validatedTab(tabParam, possibleTabs) || possibleTabs[0]);
  const [redirectBack] = useState<string | null>(redirectBackParam);

  const updateURL = useCallback(
    (newTabKey: (typeof possibleTabs)[number], newRedirectBack: string | null) => {
      const newSearch = new URLSearchParams(queryParams);
      newSearch.set('tab', newTabKey);
      if (newRedirectBack) {
        newSearch.set('redirectBack', newRedirectBack);
      } else {
        newSearch.delete('redirectBack');
      }
      history.replace(`${currentRoute}?${newSearch.toString()}`);
    },
    [history, queryParams, currentRoute],
  );

  function handleTabChange(key: (typeof possibleTabs)[number]) {
    setTabKey(key);
  }

  useEffect(() => {
    updateURL(tabKey, redirectBack);
  }, [tabKey, redirectBack, history, updateURL]);

  return { tabKey, handleTabChange } as unknown as { tabKey: T; handleTabChange: (key: T) => void };
};
