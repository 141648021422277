import { Modal } from "antd"
import AddNewFunds from "components/Setting/TabContent/WalletTab/AddNewFunds";
import "./AddFundsModal.scss";

type Props = {
  open: boolean;
  onClose: () => void;
  depositMessage?: string;
}

export const AddFundsModal: React.FC<Props> = ({ open, onClose, depositMessage }) => {
  
  return (
    <Modal
      title={null}
      open={open}
      onCancel={onClose}
      centered={true}
      footer={null}
    >
      <div className="add-funds-modal-container">
        <AddNewFunds closeModalMethod={onClose} depositMessage={depositMessage}/>  
      </div>
    </Modal>
  )
}