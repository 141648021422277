import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { metaMaskLoginRequest } from "redux/actions/AuthAction";
import { isMobile } from "react-device-detect";
import { useMetamask } from "use-metamask";
import Web3 from "web3";

export default function useMetamaskLogin(rememberMe = false) {
  const dispatch = useDispatch();
  const { connect, metaState } = useMetamask();

  useEffect(() => {
    if (!metaState.isConnected) {
      (async () => {
        try {
          if (connect && Web3) {
            await connect(Web3);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  const handleMetamaskLogin = async () => {
    if (metaState.isAvailable) {
      dispatch(metaMaskLoginRequest(metaState.account, rememberMe));
    } else {
      if (isMobile) {
        window.location.href = "https://metamask.app.link/dapp/gmdynasty.com/";
      } else {
        window.open("https://metamask.app.link/dapp/gmdynasty.com/");
        window.location.reload();
      }
    }
  };

  return {
    handleMetamaskLogin,
  };
}
