export const bottomValuesByRound = (round: number) => {
  switch (round) {
    case 0: 
      return -35;

    case 1:
      return -19;
  
    case 2:
      return -45;
    
    case 3:
      return -100;
    
    case 4:
      return -215;

    case 5:
      return -465;
  
    default:
      return -19;
  }
}