import { Modal } from "antd"
import { PlayerProfileMain } from "components/MintRoster/components/PlayerProfile/PlayerProfileMain";
import { Roster } from "components/MintRoster/interface";

type Props = {
  open: boolean;
  onClose: () => void;
  roster: Roster | null;
  rosterDescription: string;
  setRosterDescription: (value: string) => void;
}

export const ProfileCreationModal: React.FC<Props> = ({ open, onClose, roster, rosterDescription, setRosterDescription }) => {
  return (
    <Modal
      open={open}
      footer={null}
      width={"75vw"}
      onCancel={onClose}
      centered
    >
      <PlayerProfileMain
        roster={roster}
        rosterDescription={rosterDescription}
        setRosterDescription={setRosterDescription}
        onClose={onClose}
      />
    </Modal>
  )
}