/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./CustomizeFranchiseStyle.scss";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import { Row, Col } from "react-bootstrap";
import CreateFranchise from "./../Common/CreateFranchise/CreateFranchise";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { useMetamask } from "use-metamask";
import { metaMaskWalletId } from "../../helper/helper";
import { Divider, Table } from "antd";
import { useState, useEffect } from "react";
import {
  getCreateFrenchiseDetail,
  getCityDetail,
} from "../../redux/actions/TeamAction";
import { useLocation, useParams } from "react-router-dom";
import Web3 from "web3";

const CustomizeFranchiseContent = ({ setLoading }: any) => {
  const { franchiseId }: any = useParams();
  const { search } = useLocation();
  const searchParamsType = new URLSearchParams(search).get("type");
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.app.resData);
  const franchiseData = useSelector(
    (state: RootState) => state.team.createFrenchiseData
  );
  const cityDetails = useSelector((state: RootState) => state.team.cityDetails);

  const [balance, setBalance] = useState();
  const [baseRate, setBaseRate] = useState<any>(1);
  const [cityName, setCityName] = useState();
  const [baseRateTo, setBaseRateTo] = useState<any>(5);
  const [fanRating, setFanRating] = useState<any>("");
  const { connect, metaState } = useMetamask();

  useEffect(() => {
    if (cityDetails?.length > 0) {
      setCityName(cityDetails?.[0]?.name);
      setBaseRateTo(cityDetails?.[0]?.BaseRatingTo);
    }
  }, [cityDetails]);

  useEffect(() => {
    if (userData?.metaMaskID && metaState.isAvailable && !metaState.isConnected) {
      (async () => {
        try {
          if (connect !== undefined && Web3 !== undefined) {
            await connect(Web3);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [metaState.isAvailable, metaState.isConnected, userData?.metaMaskID]);

  useEffect(() => {
    dispatch(getCreateFrenchiseDetail(franchiseId));
  }, [franchiseId]);

  useEffect(() => {
    if (franchiseData) {
      dispatch(getCityDetail(franchiseData?.[0]?.city));
    }
  }, [franchiseData]);

  useEffect(() => {
    if (cityDetails && searchParamsType !== 'update' && franchiseData && franchiseData[0] && franchiseData[0].baseRate) {
      setFanRating(franchiseData[0].baseRate);
      setBaseRate(cityDetails?.[0]?.BaseRatingTo);
    }
  }, [cityDetails, searchParamsType, franchiseData]);

  useEffect(() => {
    if (searchParamsType === 'update' && franchiseData?.[0]?.baseRate && franchiseData?.[0]?.teamRating) {
      setFanRating(franchiseData?.[0]?.baseRate);
      setBaseRate(franchiseData?.[0]?.teamRating);
    }
  }, [searchParamsType, franchiseData]);

  useEffect(() => {
    const { account, isConnected, web3 } = metaState;
    if (account.length && isConnected && web3) {
      (async () => {
        let _balance: any;
        if (web3?.eth) {
          _balance = await metaState.web3.eth.getBalance(metaState?.account?.[0]);
        } else {
          _balance = await metaState.web3.getBalance(metaState?.account?.[0]);
        }
        const bal: any = (_balance / 10 ** 18).toFixed(3);
        setBalance(bal);
      })();
    }
  }, [metaState]);

  const metaMaskId = userData?.metaMaskID && metaState?.account?.[0]
    ? metaMaskWalletId(metaState?.account?.[0])
    : 'Not connected';

  const offensiveLineColumns = [
    {
      title: "CITY",
      dataIndex: "city",
    },
    {
      title: cityName,
      dataIndex: "atlanta",
    },
  ];

  const offensiveLinekData = [
    {
      key: "1",
      city: "FAN RATING :",
      atlanta: baseRate,
    },
    {
      key: "2",
      city: "TEAM RATING :",
      atlanta: fanRating,
    },
  ];

  return (
    <>
      <div className="mb-5 customize-main">
        <Card>
          <CardBody>
            <Row>
              <Col xl={7} lg={7}>
                <CreateFranchise />
              </Col>
              {searchParamsType === 'update' && (
                <>
                  <Col className="verticaldivider">
                    <Divider type="vertical" />
                  </Col>
                  <Col xl={4} lg={4}>
                    <div className="franchisecity">
                      <h5>FRANCHISE INFORMATION</h5>
                      <Table
                        columns={offensiveLineColumns}
                        pagination={false}
                        dataSource={offensiveLinekData}
                        size="small"
                        className="mt-3 paymentprice"
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default CustomizeFranchiseContent;
