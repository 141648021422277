import Card from "../../../Common/Card/Card";
import CardBody from "../../../Common/Card/CardBody";
import { Row, Col } from "react-bootstrap";
import LeagueOverviewResults from "../../../Common/LeagueOverviewTab/LeagueOverviewResults";
import LeagueOverviewStanding from "../../../Common/LeagueOverviewTab/LeagueOverviewStanding";
import EliminationLeagueLeader from "./EliminationLeagueLeader";
import { EliminationLeagueTeams } from "./EliminationLeagueTeams";
import { EliminationLeagueInfo } from "./EliminationLeagueInfo";
import { EliminationPowerRank } from "components/Common/LeagueOverviewTab/EliminationPowerRank";

type Props = {
  gid: number;
}

const OverviewTabMain: React.FC<Props> = ({ gid }) => (
  <Row>
    <Col xl={12} className="mb-4">
      <Card>
        <CardBody>
          <EliminationLeagueInfo />
        </CardBody>
      </Card>
    </Col>
    <Col xl={12} className="mb-4">
      <Card>
        <CardBody>
          <EliminationLeagueTeams gid={gid} />
          <EliminationLeagueLeader gid={gid} />
        </CardBody>
      </Card>
    </Col>
    <Col xl={8}>
      <Card>
        <CardBody>
          <LeagueOverviewResults gid={gid}/>
        </CardBody>
      </Card>
    </Col>
    <Col xl={4} className="pb-3">
      <Card>
        <CardBody>
          <LeagueOverviewStanding />
          <EliminationPowerRank gid={gid}/>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default OverviewTabMain;
