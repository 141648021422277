import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { useLoading } from "components/FranchiseOverview/hooks/use-loading";
import React, { useEffect, useState } from "react";
import Joyride, { Step } from 'react-joyride'
import { useLocation } from "react-router-dom";
import { useGameJoinTutorial } from "./hooks/useGameJoinTutorial";

const StepsWrapper: React.FC<{ content: React.ReactNode, callback: () => void }> = ({ content, callback }) => {
  return (
    <div>
      <div className="mb-4" style={{ display: 'flex', alignItems: 'start'}}>
        <PinkButton className="font12" text="SKIP" onClick={() => callback()}/>
      </div>
      {content}
    </div>
  )
} 

const getOwnFranshisSteps = (callBack: () => void): Step[] => {
  const OwnFranchiseSteps: Step[] = [
    {
      target: '.games-link',
      content: (
        <p>
          <p
            className="mb-5"
          >
              Press <span style={{ backgroundColor: "#384c6c", color: 'white', padding: '4px'}}>GAMES</span> link in header to see all games available to join
          </p>

          <p><strong>OR</strong></p>
          
          <PinkButton
            onClick={callBack}
            text="SKIP TUTORIAL"
          />

          <p
            className="mt-4 font14"
          >
            *You can always start this tutorial again from user settings.
          </p>
          
        </p>
      ),
      hideFooter: true,
      hideCloseButton: true,
    },
  ];

  return OwnFranchiseSteps;
}

const LeagueSteps: Step[] = [
    {
      target: "div.franchiseDropdown div div select",
      content: 'Select a team from the dropdown to join game with that team',
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "#select-game-fee",
      content: 'You can filter games by their entry fee.',
      disableScrolling: true,
      spotlightClicks: false,
    },
    {
      target: "#select-game-type",
      content: 'You can filter games by their type.',
      disableScrolling: true,
    },
    {
      target: "#select-game-level",
      content: 'You can filter games by their level.',
      disableScrolling: true,
    },
    {
      target: ".preparedLeague",
      content: 'Here is an example of a game where the opponent is waiting for you to join.',
      disableScrolling: true,
      spotlightClicks: false,
    },
    {
      target: "#preparedLeagueJoinButton",
      content: (
        <p>
          Click <PinkButton text="JOIN"/> to join the game.
        </p>
      ),
      disableScrolling: true,
      hideFooter: true,
    },
];

const LeagueHeadToHeadSteps: Step[] = [
  {
    target: ".gametime",
    content: 'This is the amount of time left for game to start.',
    disableScrolling: true,
    disableBeacon: true,
  },
  {
    target: "div.standingTabbarMain.mb-5",
    content: 'You are able to see different stats about your team and opponent\'s one.',
    disableScrolling: true,
  },
  {
    target: "div.standingTabbar div div div",
    content: 'You can set up your depth chart and game plan before the game starts.',
    disableScrolling: true,
    spotlightClicks: false,
  },
  {
    target: "#letsplay-button",
    content: (
      <p>
        You can also press the <strong>LET'S PLAY</strong> button to start the game immediately. 
        <br/>
        If you opponent did not press his READY button, your will have to wait till game starts and will see <strong>READY</strong> text insted of <strong>LET'S PLAY</strong>.
        <br/>
        <br/>
        Enjoy!
      </p>
    ),
    disableScrolling: true,
  },
];

export const GameJoinTutorial: React.FC = () => {
  const location = useLocation();
  const loader = useLoading();
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);

  const { disableTutorials, showTutorial } = useGameJoinTutorial();

  useEffect(() => {
    if (location.pathname === '/ownfranchise' && !loader) {
      setSteps(getOwnFranshisSteps(disableTutorials));
      setRun(true)
    } else if (location.pathname === '/league' && !loader) {
      setSteps(LeagueSteps.map((step) => ({ ...step, content: <StepsWrapper content={step.content} callback={disableTutorials} /> })));
      setRun(true)
    } else if (location.pathname === '/leagueheadtohead' && !loader) {
      setRun(true)
      setSteps(LeagueHeadToHeadSteps.map((step) => ({ ...step, content: <StepsWrapper content={step.content} callback={disableTutorials} /> })));
    }
  }, [location.pathname, loader, disableTutorials]);

  useEffect(() => {
    if (loader) {
      setRun(false);
    } else {
      setRun(true)
    }
  }, [loader]);

  if (!showTutorial) {
    return null;
  }

  return (
    <div>
      <Joyride
        key={location.pathname}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#d1403c',
            arrowColor: '#d1403c',
            beaconSize: 40,
            zIndex: 1000,
          }
        }}
        locale={{
          back: <ArrowLeftOutlined />,
          close: <CloseOutlined />,
          last: <CheckOutlined />,
          next: <ArrowRightOutlined />,
          open: 'CLICK TO OPEN TUTORIAL'
        }}
        continuous
        spotlightClicks={true}
        disableOverlayClose
        callback={(data: any) => {
          if (['/leagueheadtohead', '/gameplay'].includes(location.pathname) && data?.status === 'finished') {
            disableTutorials();
          }
        }}
        run={run}
      />
    </div>
  )
}