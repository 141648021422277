import { useEffect, useState } from 'react';
import { get } from 'redux/services/Api';
import { SeasonScores } from '../types';
import { addHelmetsForScores } from 'helper/helper';
import { HelmetData } from 'react-helmet-async';

export type SeasonScoresWithHelmets = SeasonScores & {
  helmets: (HelmetData | undefined)[];
}

export const useScoresForWeek = (weekId: string) => {
  const [data, setData] = useState<SeasonScoresWithHelmets[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!weekId.length) {
        return;
      }
      try {
        setData(null);
        setLoading(true);
        const response = await get<SeasonScores[]>(`/engine/leagues/seasons/season/week/${weekId}/scores`);
        const resWithAvatars = await addHelmetsForScores(response);
        console.log(resWithAvatars);
        setData(resWithAvatars);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();
  }, [weekId]);

  return { data, loading };
};
