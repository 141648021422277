type Props = {
  rating: number;
  ratingChange: number;
}

export const RatingWithChange: React.FC<Props> = ({ rating, ratingChange }) => {
  const getChange = () => {
    if (ratingChange > 0) {
      return (
        <span className="ratingWithChange__change ratingWithChange__change--up">
           &nbsp;(+{ratingChange})
        </span>
      );
    } else if (ratingChange < 0) {
      return (
        <span className="ratingWithChange__change ratingWithChange__change--down">
           &nbsp;(-{ratingChange})
        </span>
      );
    }
    return null;
  };

  return (
    <span className="ratingWithChange">
      <span className="ratingWithChange__rating">{rating}</span>
      {getChange()}
    </span>
  );
}