import CircleIcon from "./CircleIcon";
import "./CirclePink.scss";
import CircleCutSvg from "../../../../assets/images/common/scissors.svg"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
}

const CircleCutIcon: React.FC<Props> = (props) => (
  <CircleIcon {...props} icon={CircleCutSvg} className="circlePink" />
);

export default CircleCutIcon;