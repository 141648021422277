import "./TeamStatStyle.scss";
import { Table } from "antd";
import history from "./../../routes/history";
import { useMemo } from "react";

type Props = {
  lostTeam: any;
  wonTeam: any;
};

const TeamStatData: React.FC<Props> = ({ lostTeam, wonTeam }) => {
  const lostTotalPlays = useMemo(
    () =>
      (lostTeam?.stat?.pss || 0) +
      (lostTeam?.stat?.rus || 0) +
      (lostTeam?.stat?.xpa || 0) +
      (lostTeam?.stat?.fga0 || 0) +
      (lostTeam?.stat?.fga20 || 0) +
      (lostTeam?.stat?.fga30 || 0) +
      (lostTeam?.stat?.fga40 || 0) +
      (lostTeam?.stat?.fga50 || 0),
    [lostTeam]
  );

  const wonTotalPlays = useMemo(
    () =>
      (wonTeam?.stat?.pss || 0) +
      (wonTeam?.stat?.rus || 0) +
      (wonTeam?.stat?.xpa || 0) +
      (wonTeam?.stat?.fga0 || 0) +
      (wonTeam?.stat?.fga20 || 0) +
      (wonTeam?.stat?.fga30 || 0) +
      (wonTeam?.stat?.fga40 || 0) +
      (wonTeam?.stat?.fga50 || 0),
    [wonTeam]
  );

  const lostTotalYards = useMemo(
    () => (lostTeam?.stat?.pssYds || 0) + (lostTeam?.stat?.rusYds || 0),
    [lostTeam]
  );

  const wonTotalYards = useMemo(
    () => (wonTeam?.stat?.pssYds || 0) + (wonTeam?.stat?.rusYds || 0),
    [wonTeam]
  );

  const teamstatColumns = [
    {
      title: (
        <div className="franchiselabel">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/franchisedetail/${lostTeam?.utid}`)}
          >
            {lostTeam?.teamName}
          </span>
        </div>
      ),
      dataIndex: "leftscore",
    },
    {
      title: "",
      dataIndex: "position",
    },
    {
      title: (
        <div className="franchiselabel">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/franchisedetail/${wonTeam?.utid}`)}
          >
            {wonTeam?.teamName}
          </span>
        </div>
      ),
      dataIndex: "rightscore",
    },
  ];
  const teamstatData = [
    {
      key: "7",
      leftscore: <span className="weight">{lostTotalPlays}</span>,
      position: <span className="weight">Total Plays</span>,
      rightscore: <span className="weight">{wonTotalPlays}</span>,
    },
    {
      key: "8",
      leftscore: <span className="weight">{lostTotalYards}</span>,
      position: <span className="weight">Total Yards</span>,
      rightscore: <span className="weight">{wonTotalYards}</span>,
    },
    {
      key: "9",
      leftscore: (
        <span className="weight">
          {lostTeam?.stat?.drives ? lostTeam?.stat?.drives : 0}
        </span>
      ),
      position: <span className="weight">Total Drives</span>,
      rightscore: (
        <span className="weight">
          {wonTeam?.stat?.drives ? wonTeam?.stat?.drives : 0}
        </span>
      ),
    },
    {
      key: "10",
      leftscore: (
        <span className="weight">
          {(lostTotalYards / lostTotalPlays).toFixed(2) || 0}
        </span>
      ),
      position: <span className="weight">Yards per Play</span>,
      rightscore: (
        <span className="weight">
          {(wonTotalYards / wonTotalPlays).toFixed(2) || 0}
        </span>
      ),
    },
    {
      key: "11",
      leftscore: (
        <span className="weight">
          {lostTeam?.stat?.pssYds ? lostTeam?.stat?.pssYds : 0}
        </span>
      ),
      position: <span className="weight">Passing</span>,
      rightscore: (
        <span className="weight">
          {wonTeam?.stat?.pssYds ? wonTeam?.stat?.pssYds : 0}
        </span>
      ),
    },
    {
      key: "12",
      leftscore: (
        <span>
          {(lostTeam?.stat?.pssCmp || 0) + "-" + (lostTeam?.stat?.pss || 0)}
        </span>
      ),
      position: "Comp-Att",
      rightscore: (
        <span>
          {(wonTeam?.stat?.pssCmp || 0) + "-" + (wonTeam?.stat?.pss || 0)}
        </span>
      ),
    },
    {
      key: "13",
      leftscore: (
        <span>
          {Number(
            (lostTeam?.stat?.pssYds || 0) / (lostTeam?.stat?.pss || 0)
          ).toFixed(2)}
        </span>
      ),
      position: "Yards per pass",
      rightscore: (
        <span>
          {Number(
            (wonTeam?.stat?.pssYds || 0) / (wonTeam?.stat?.pss || 0)
          ).toFixed(2)}
        </span>
      ),
    },
    {
      key: "14",
      leftscore: lostTeam?.stat?.pssInt
        ? lostTeam?.stat?.pssInt
        : 0,
      position: "Interceptions thrown",
      rightscore: wonTeam?.stat?.pssInt ? wonTeam?.stat?.pssInt : 0,
    },
    {
      key: "15",
      leftscore: (
        <span>
          {(lostTeam?.stat?.pssSk || 0) + "-" + (lostTeam?.stat?.pssSkYds || 0)}
        </span>
      ),
      position: "Sacks-Yards Lost",
      rightscore:
        (wonTeam?.stat?.pssSk || 0) + "-" + (wonTeam?.stat?.pssSkYds || 0),
    },
    {
      key: "16",
      leftscore: (
        <span className="weight">
          {lostTeam?.stat?.rusYds ? lostTeam?.stat?.rusYds : 0}
        </span>
      ),
      position: <span className="weight">Rushing</span>,
      rightscore: (
        <span className="weight">
          {wonTeam?.stat?.rusYds ? wonTeam?.stat?.rusYds : 0}
        </span>
      ),
    },
    {
      key: "17",
      leftscore: lostTeam?.stat?.rus ? lostTeam?.stat?.rus : 0,
      position: "Rushing Attempts",
      rightscore: wonTeam?.stat.rus ? wonTeam?.stat.rus : 0,
    },
    {
      key: "18",
      leftscore: (
        <span>
          {Number(
            (lostTeam?.stat?.rusYds || 0) / (lostTeam?.stat?.rus || 0)
          ).toFixed(2)}
        </span>
      ),
      position: "Yards per rush",
      rightscore: (
        <span>
          {Number(
            (wonTeam?.stat?.rusYds || 0) / (wonTeam?.stat?.rus || 0)
          ).toFixed(2)}
        </span>
      ),
    },
    {
      key: "20",
      leftscore: (
        <span className="weight">
          {(lostTeam?.stat?.pen || 0) + "-" + (lostTeam?.stat?.penYds || 0)}
        </span>
      ),
      position: <span className="weight">Penalties</span>,
      rightscore: (
        <span className="weight">
          {(wonTeam?.stat?.pen || 0) + "-" + (wonTeam?.stat?.penYds || 0)}
        </span>
      ),
    },
    {
      key: "21",
      leftscore: (
        <span className="weight">
          {(lostTeam?.stat?.fmbLost || 0) + (lostTeam?.stat?.pssInt || 0)}
        </span>
      ),
      position: <span className="weight">Turnovers</span>,
      rightscore: (
        <span className="weight">
          {(wonTeam?.stat?.fmbLost || 0) + (wonTeam?.stat?.pssInt || 0)}
        </span>
      ),
    },
    {
      key: "22",
      leftscore: lostTeam?.stat?.fmbLost || 0,
      position: "Fumbles lost",
      rightscore: wonTeam?.stat?.fmbLost || 0,
    },
    {
      key: "23",
      leftscore: (
        <span>{(lostTeam?.stat?.prTD || 0) + (lostTeam?.stat?.krTD || 0)}</span>
      ),
      position: <span className="weight">Defensive / Special Teams TDs</span>,
      rightscore: (
        <span>{(wonTeam?.stat?.prTD || 0) + (wonTeam?.stat?.krTD || 0)}</span>
      ),
    },
    {
      key: "24",
      leftscore: (
        <span className="weight">
          {lostTeam?.stat?.timePos
            ? lostTeam?.stat?.timePos.toFixed(2).replace(".", ":")
            : "-"}
        </span>
      ),
      position: <span className="weight">Possession</span>,
      rightscore: (
        <span className="weight">
          {wonTeam?.stat?.timePos
            ? wonTeam?.stat?.timePos.toFixed(2).replace(".", ":")
            : "-"}
        </span>
      ),
    },
  ];

  return (
    <div className="statdatamain">
      <h5>TEAM STATS & COMPARISON</h5>
      <div className="mb-3">
        <Table
          columns={teamstatColumns}
          pagination={false}
          dataSource={teamstatData}
          size="small"
          className="teamstattable"
        />
      </div>
    </div>
  );
};

export default TeamStatData;
