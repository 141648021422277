import { Fragment } from 'react';
import { StatisticalAchievementsType } from './types';
import history from 'routes/history';

export const map = {
  attack: {
    passingYds: 'PASSING YARDS',
    rushingYds: 'RUSHING YARDS',
    receivingYds: 'RECEIVING YARDS',
    passingTds: 'PASSING TDS',
    rushingTds: 'RUSHING TDS',
    receivingTds: 'RECEIVING TDS',
  },
  defense: {
    sacks: 'SACKS',
    interceptions: 'INTERCEPTIONS',
    fumbleForced: 'FUMBLE FORCED',
    tackles: 'TACKLES',
    tacklesForLoss: 'TACKLES FOR LOSS',
    passesDefended: 'PASSES DEFENDED',
  },
  kicker: {
    fieldGolesMade: 'FIELD GOALS MADE',
    fieldGolesAttempted: 'FIELD GOALS ATTEMPTED',
    longestFieldGoal: 'LONGEST FIELD GOAL',
    longestPunt: 'LONGEST PUNT',
    mostPuntYards: 'MOST PUNT YARDS',
  },
};

type Props = {
  statisticalAchievements?: StatisticalAchievementsType;
};

export const StatisticalAchievements: React.FC<Props> = ({ statisticalAchievements }) => (
  <>
    {statisticalAchievements?.type &&
      Object.entries(map[statisticalAchievements.type as keyof typeof map]).map(([key, name], index) =>
        !!statisticalAchievements[key as keyof typeof statisticalAchievements] ? (
          <div className="achievement" key={index}>
            <p className="achievent_title">
              {statisticalAchievements[key as keyof typeof statisticalAchievements]} {name}
            </p>
            {statisticalAchievements.gameSummary}{' '}
            {statisticalAchievements?.gid && (
              <span
                className="name-link"
                onClick={() =>
                  history.push('/stand', {
                    gid: statisticalAchievements.gid,
                  })
                }
              >
                Game №{statisticalAchievements.gid}
              </span>
            )}
          </div>
        ) : (
          <Fragment key={index} />
        ),
      )}
  </>
);
