import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGroupName } from "redux/actions/Groups";
import { RootState } from "redux/store";

export const useGroupName = (id?: number) => {
  const dispatch = useDispatch();

  const groupData = useSelector((state: RootState) => state.group.groupName);

  useEffect(() => {
    if (!id) return;
    dispatch(GetGroupName(id));
  }, [id]);

  return groupData;
};