import { Row, Col } from 'react-bootstrap';
import image from '../../assets/images/homeScreen/franchiseBuild.gif';
import React from 'react';
import BlackButton from 'components/Common/Button/BlackButton/BlackButton';
import history from 'routes/history';

export const PurchaseTeam: React.FC = () => (
  <div className="leagueListMain franchiselist mb-5">
    <Row>
      <Col sm={12} xl={4} lg={4}>
        <div className="leagueDetailLeft">
          <div className="leagueImage">
            <div className="imgWithRatingContainer">
              <img src={image} alt="leagueImage" className="imgFluid" style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <div className="leagueDetail">
            <h5>ADD A TEAM!</h5>
          </div>
        </div>
      </Col>
      <Col sm={12} xl={8} lg={8}>
        <Row>
          <Col xl={6} lg={6}>
            <div className="leaguedetail_head">
              <h5>PARTICIPATE IN MORE LEAGUES!</h5>
            </div>
          </Col>
          <Col sm={12} xl={9} lg={9}>
            <div className="leagueDetailRight blue font14" style={{ textAlign: 'left' }}>
              CREATE ADDITIONAL TEAMS TO ADD TO YOUR OWNER'S PORTFOLIO. TEST NEW STRATEGIES, ENGAGE IN MORE CONTESTS OR SIMPLY EXPLORE YOUR CREATIVE LIMITS OF DESIGNING A
              NEW TEAM. ALL TEAMS ARE MINTED NFTS THAT ARE OWNED BY YOU.
            </div>
          </Col>
          <Col xl={3} lg={3} className="create-team-button-container">
            <BlackButton text="CREATE" onClick={() => history.push('/purchasefranchise')} />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
