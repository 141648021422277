import "./CardStyle.scss";

type Props = {
    children?: React.ReactNode;
}

const CardBody: React.FC<Props> = ({ children }) => (
    <div className="cardbody">
        {children}
    </div>
);

export default CardBody;