import { League } from "components/Common/LeagueHeader/LeagueHeader";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { leaveGame, leaveGameDataClean } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import history from "routes/history";
import { notification } from "antd";

export const useLeaveGame = (GID?: number) => {
  const dispatch = useDispatch();

  const leaveGameData = useSelector((state: RootState) => state.gameEngine.leaveGameData);

  const handleLeaveGame = (gid: number, type: League['type'], engId: number) => {
    dispatch(leaveGame(gid, type, engId));
  }

  const cleanLeaveGameData = useCallback(() => {
    dispatch(leaveGameDataClean());
  }, [])

  useEffect(() => {
    if (
       leaveGameData &&
       leaveGameData.gid &&
       leaveGameData.gid === GID &&
       !leaveGameData.error
    ) {
       cleanLeaveGameData();
       history.push("/league");
    } else if (
       leaveGameData &&
       leaveGameData.gid &&
       leaveGameData.gid === GID &&
       leaveGameData.error
    )
       notification.error({
          message: leaveGameData.error,
          placement: "bottomRight",
       });
 }, [GID, cleanLeaveGameData, leaveGameData]);

  return { handleLeaveGame }
}