import player1 from "../../assets/images/homeScreen/team_player_1.png";
import player2 from "../../assets/images/homeScreen/team_player_2.png";
import player3 from "../../assets/images/homeScreen/team_player_3.png";
import HomeContainer from "./HomeContainer";
import AnimatedTitle from "../Common/AnimatedTitle/AnimatedTitle";
import PlayersAnimation from "../Common/PlayersAnimation/PlayersAnimation";

const HomeYourTeam = () => {
  return (
    <div className="your-team-banner">
      <HomeContainer isYourTeam={true}>
        <div className="your-team-section">
          <div className="your-team-banner-info">
            <div>
              <AnimatedTitle
                className="your-team-banner-title"
                text={"Your Team"}
              />
            </div>
            <p>
              Ever imagined running a football franchise? Now's your chance!
              Experience the exhilaration of owning and managing a team,
              drafting players, and making blockbuster trades. Best of all, core
              gameplay pieces can be wholly owned, traded and sold as NFTs.
            </p>
          </div>
          <PlayersAnimation>
            <div className="your-team-banner-players">
              <img src={player1} alt="player1" className="player1" />
              <img src={player3} alt="player3" className="player3" />
              <img src={player2} alt="player2" className="player2" />
            </div>
          </PlayersAnimation>
        </div>
      </HomeContainer>
    </div>
  );
};

export default HomeYourTeam;
