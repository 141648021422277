import FranchiseDetailMain from "../../components/FranchiseDetail/FranchiseDetailMain";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";

const FranchiseDetailScreen: React.FC = () => (
    <div className="pagelayout" >
        <WithLoginNavbar />
        <FranchiseDetailMain />
    </div>
)

export default FranchiseDetailScreen;