import { useGroupMembers } from '../Common/hooks';
import { useUserSearchByName } from 'helper/useUserSearchByName';
import { FoundUser, Group, GroupMemershipStatus } from 'types/groups';
import { useHandleGroupParticipation } from '../Common/GroupHeader/hooks/useHandleGroupParticipation';
import { UseSearchUsers, UserSearch, UserSearchTemplate } from 'components/Common/UserSearch';

const useInviteGroupsMembers = (): { searchUsers: () => UseSearchUsers<FoundUser>; invitedIds: number[]; group: Group | null } => {
  const { group, invitedMembers } = useGroupMembers();
  const { foundUsers, handleSearch } = useUserSearchByName();
  const invitedIds = invitedMembers?.map((member) => member.user.id) || [];

  const existMembersIds =
    group?.members
      .filter((member) => member.status === GroupMemershipStatus.REJECTED || member.status === GroupMemershipStatus.APPROVED)
      .map((member) => member.user.id) || [];

  const filteredUsers = foundUsers.filter((user) => !existMembersIds.includes(user.id));

  return {
    searchUsers: () => ({ users: filteredUsers, handleSearch: handleSearch }),
    invitedIds,
    group,
  };
};

export const InviteMemberForm = () => {
  const { handleInvite } = useHandleGroupParticipation();
  const { searchUsers, invitedIds, group } = useInviteGroupsMembers();

  const handleInviteClick = (userId: number) => {
    if (!group) return;
    handleInvite(group.id, userId);
  };

  return (
    <UserSearch
      useSearchUsers={searchUsers}
      renderUsersSearch={({ onChange, value, users }) => (
        <UserSearchTemplate 
          users={users} 
          renderInput={{ onChange, value }} 
          renderUsersList={{ 
            invitedIds, 
            users, 
            onInviteClick: handleInviteClick, 
          }} 
        />
      )}
    />
  );
};
