import OwnFranchiseHeader from "components/OwnFranchise/OwnFranchiseHeader"
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar"
import { Container } from "react-bootstrap"
import { LeaderboardContent } from "./LeaderboardContent"

export const LeaderboardMain = () => (
  <Container>
    <WithLoginNavbar />
    <OwnFranchiseHeader />
    <LeaderboardContent />
  </Container>
);