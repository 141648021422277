import "./ProtfolioOwnFranchiseScreenStyle.scss";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import OwnFranchiseMain from "../../components/OwnFranchise/OwnFranchiseMain";
import LoadingScreen from "./../../components/Common/LoadingScreen/LoadingScreen";
import { useLoading } from "components/FranchiseOverview/hooks/use-loading";

const ProtfolioOwnFranchiseScreen: React.FC = () => {
  const loading = useLoading();

  return (
    <>
      {loading && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <OwnFranchiseMain />
      </div>
    </>
  );
};

export default ProtfolioOwnFranchiseScreen;
