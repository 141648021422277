import './EmailLoginFormStyles.scss';
import { Col, Row } from 'react-bootstrap';
import { Modal, Tooltip } from 'antd';
import Input from '../Form/Input/Input';
import Checkbox from '../Form/Checkbox/Checkbox';
import BlueButton from '../Button/BlueButton/BlueButton';
import { useEffect, useRef, useState } from 'react';
import { loginRequest } from '../../../redux/actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordModal from '../ForgotPassword/ForgotPasswordModal';
import { RootState } from '../../../redux/store';
import PinkButton from '../Button/PinkButton/PinkButton';

type Props = {
  isFromModal?: boolean;
  rememberMe: boolean;
  toggleRememberMe: () => void;
};
const EmailLoginForm = ({ isFromModal, rememberMe, toggleRememberMe }: Props) => {
  const dispatch = useDispatch();

  const signinError = useSelector((state: RootState) => state.app.signError);

  const [msg, setMsg] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotModalVisible, setIsForgotModalVisible] = useState(false);

  useEffect(() => {
    setMsg(signinError || '');
  }, [signinError]);

  const closeForgotModal = () => {
    setIsForgotModalVisible(false);
  };

  const showForgotModal = () => {
    setIsForgotModalVisible(true);
  };

  const handleLogin = () => {
    if (!username || !password) {
      setMsg('All Fields Are Required');
    } else {
      setMsg('');
      dispatch(loginRequest({ userName: username, password, rememberMe }));
    }
  };

  const checkboxRef = useRef<HTMLLabelElement | null>(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const box = checkboxRef.current;

    if (box) {
      const mouseenter = () => {
        setIsHovered(true);
      }
      box.addEventListener('mouseenter', mouseenter);

      const mouseleave = () => {
        setIsHovered(false);
      }

      box.addEventListener('mouseleave', mouseleave);

      return () => {
        box?.removeEventListener('mouseenter', mouseenter);
        box?.removeEventListener('mouseleave', mouseleave);
      }
    }
  }, []);

  return (
    <>
      <div className="login-form-component">
        <Row>
          <Col xl={12} lg={12}>
            <Row>
              <Col xl={12} className="mb-3">
                <Input
                  type="username"
                  label="Username or Email *"
                  value={username}
                  onChange={setUsername}
                  placeholder="Enter Username or Email"
                  classNameForm="email-login-form"
                />
              </Col>
              <Col xl={12} className="mb-3">
                <Input type="password" label="Password*" value={password} onChange={setPassword} placeholder="Enter Password" classNameForm="email-login-form" />
              </Col>

              <p className="error mb-3 login-error">{msg}</p>

              <Col xl={6} lg={6} md={6} sm={6} className="text-start">
                <Tooltip
                  open={isHovered}
                  title={
                      <p>This will extend your session duration up to 30 days</p>
                  }
                  trigger={['hover']}
                  placement='bottom'
                  color="#d1403c"
                >
                  <Checkbox label="Remember Me" checked={rememberMe} onClick={toggleRememberMe} ref={checkboxRef}/>
                </Tooltip>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} className="text-end">
                <p className="lost-password-button" onClick={showForgotModal}>
                  LOST PASSWORD HELP
                </p>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} className="loginButton">
                {isFromModal ? (
                  <PinkButton className="login-button-pink" text="LOGIN" onClick={handleLogin} />
                ) : (
                  <BlueButton className="login-button" text="LOGIN" onClick={handleLogin} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Modal open={isForgotModalVisible} className="forgot-password-modal" onOk={closeForgotModal} onCancel={closeForgotModal} footer={null} centered>
        <ForgotPasswordModal />
      </Modal>
    </>
  );
};

export default EmailLoginForm;
