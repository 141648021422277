import { Player } from "./LeaderboardPlayersLeaders";
import { LeaderboardPlayerLeaderListItem } from "./LeaderboardPlayerLeaderListItem";

type Props = {
  players: Player[];
}

export const LeaderboardPlayerLeaderList: React.FC<Props> = ({ players }) => {
  if (!players) {
    return (
      <div>Sorry, there are no data</div>
    )
  }

  return(
      <div className="leader__list">
        {players.map((player, idx) => <LeaderboardPlayerLeaderListItem player={player} index={idx} key={idx}/>)}
      </div>
  )
}