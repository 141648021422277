import "./NoFranchisesStyle.scss";
import NoFranchisesHeader from "./NoFranchisesHeader";
import NoFranchisesContent from "./NoFranchisesContent";
import { Container } from "react-bootstrap";

const NoFranchises = () =>{
    return(
        <>
            <Container>
                <NoFranchisesHeader />
                <NoFranchisesContent />
            </Container>
        </>
    );
}

export default  NoFranchises;