import { Table } from "antd";
import { tableColumns } from "./constants/tableColumns";
import "./GroupPassedGames.scss";
import { useGroupHeader } from "components/Group/Common/GroupHeader/hooks/useGroupHeader";
import { useGroupPassedGames } from "./hooks/useGroupPassedGames";
import { useServerSidePagination } from "helper/useServerSidePagination";
import { Pagination } from "components/Profile/components/common";
import { Link } from "react-router-dom";
import { getFullGameType } from "helper/helper";

const GAMES_PER_PAGE = 3;

export const GroupPassedGames: React.FC = () => {
  const groupData = useGroupHeader();
  const { handleNextPage, handlePrevPage, page } = useServerSidePagination();
  const gamesData = useGroupPassedGames(
    groupData?.groupId || 0,
    page,
    GAMES_PER_PAGE
  );

  const preparedGames = gamesData?.games?.map(game => ({
    ...game,
    key: game.leagueId,
    date: new Date(game.date).toLocaleDateString(),
    type: getFullGameType(game.type),
    leagueId: game?.leagueId ? (
      game?.type === "H" ? (
         <Link to={`/game-results/${game?.leagueId}?tab=DRIVE-CHART`} className="seasonLink">
            {game?.leagueId}
         </Link>
      ) : game.type === "E" ? (
         <Link to={`/leagueelimination?gid=${game?.leagueId}`} className="seasonLink">
            {game?.leagueId}
         </Link>
      ) : (
         "N/A"
      )
   ) : (
      "N/A"
   ),
  })) || [];

  if (!preparedGames.length) {
    return null;
  }

  return (
    <div className="section">
      <div className="section__header">
        <h5>GROUP LEAGUE RESULTS</h5>
        <div className="right">
          <Pagination
            hasNextPage={!!gamesData?.hasNextPage}
            hasPreviousPage={page > 1}
            page={page}
            setNextPage={handleNextPage}
            setPreviousPage={handlePrevPage}
          />
        </div>
      </div>
      <div className="section__body">
        <Table
          className="group-passed-games__table"
          columns={tableColumns}
          dataSource={preparedGames}
          size="small"
          pagination={false}
        />
      </div>
    </div>
  );
};
