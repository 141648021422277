import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTrophyList } from "redux/actions/TrophyAssets/actions/GetTrophyAssets";
import { SimpleUserTrophy } from "redux/actions/TrophyAssets/types";
import { RootState } from "redux/store";

export const useUserTrophies = (groupId?: number): SimpleUserTrophy[] => {
  const dispatch = useDispatch();
  const resData = useSelector((state: RootState) => state.app.resData);
  const userTrophies: SimpleUserTrophy[] | null = useSelector(
    (state: RootState) => state.app.userTrophies
  );

  useEffect(() => {
    if (resData?.id) {
      dispatch(getUserTrophyList(resData.id, groupId));
    }
  }, [resData?.id, groupId]);

  return userTrophies || [];
};
