import { getFullHelmets } from 'helper/helper';
import { get } from 'redux/services/Api';
import { EliminationOverview } from 'types';

export const getElimination = async (gid: number) => {
  try {
    const res = await get<EliminationOverview>(`/engine/games/elimination/${gid}/gameOverview`);

    if (res?.teams && res?.teams?.length) {
      const helmetsData = await getFullHelmets(res.teams);

      if (helmetsData) {
        res.helmets = helmetsData;
      }
    }

    return res;
  } catch (e) {
    return null;
  }
};
