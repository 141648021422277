import { ActionsCallbacks } from "types";
import { Dispatch } from "redux";
import {
  Group,
  GroupInvitation,
  GroupMemershipStatus,
  GroupRequest,
  GroupUpdateRequest,
  UpdateGroupMemberNetworkStatusData,
} from "types/groups";
import store, { RootState } from "redux/store";
import { Api } from "../../../libs/ws/Api";
import { GroupActionTypes, GroupActions } from "./action.types";
import { notify } from "helper/helper";
import { get, post, put } from "redux/services/Api";
import { GroupGames, GroupPassedGames } from "./types";
import { PaginationRequest } from "types/pagination";
import { WSResponseHandler } from "../WSResponseHandler/WSResponseHandler";
import { WebsocketGetawayResult } from "libs/ws/api.types";

export const CreateGroup = (
  groupData: GroupRequest,
  cbs?: ActionsCallbacks
) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    if (getState().group.loaders.createGroup) return;
    const token: string = localStorage.getItem("token") || "";

    try {
      dispatch({ type: GroupActionTypes.CREATE_GROUP_REQUEST });
      const response: WebsocketGetawayResult<Group> = await post<any>(
        `/user/group`,
        groupData,
        { Authorization: `Bearer ${token}` }
      );
      WSResponseHandler({
        ...response,
        event: "createGroup",
      });

      if (response.status === 'success') {
        cbs?.onSuccess?.(response.message);
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.CREATE_GROUP_FAIL,
          error: err.message,
        });
        cbs?.onError?.(err.message);
      }
    }
  };
};

export const EditGroup = (
  groupId: number,
  grouUpdateData: GroupUpdateRequest,
  cbs?: ActionsCallbacks
) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      if (getState().group.loaders.editGroup) return;

      dispatch({ type: GroupActionTypes.EDIT_GROUP_REQUEST });
      const response: WebsocketGetawayResult<Group> = await put(
        `/user/group/${groupId}`,
        grouUpdateData,
      );

      WSResponseHandler({
        ...response,
        event: "editGroup",
      });

      if (response.status === 'success') {
        cbs?.onSuccess?.();
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.EDIT_GROUP_FAIL,
          error: err.message,
        });
        cbs?.onError?.(err.message);
      }
    }
  };
};

export const JoinGroup = (groupId: number, cbs?: ActionsCallbacks) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.JOIN_GROUP_REQUEST });
      await api.ready;
      const sentResult = await api.api.groups.joinGroup(groupId);
      if (sentResult.status === "error") {
        throw Error(sentResult.message)
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.JOIN_GROUP_FAIL,
          error: err.message,
        });
        notify('error', err.message)
      }
    }
  };
};

export const InviteGroup = (groupId: number, userId: number, cbs?: ActionsCallbacks) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.INVITE_GROUP_REQUEST });
      await api.ready;
      const sentResult = await api.api.groups.inviteGroup(groupId, userId);
      if (sentResult.status === "error") {
        throw Error(sentResult.message)
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.INVITE_GROUP_FAIL,
          error: err.message,
        });
        notify('error', err.message)
      }
    }
  };
};

export const GetGroupInvitations = () => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    if (getState().profile.loaders.getGroupInvitations) return;

    try {
      dispatch({ type: GroupActionTypes.GET_GROUP_INVITATIONS_REQUEST });
      const response = await get<any>(
        `/user/group-invitations`,
      );
      WSResponseHandler({
        event: "getGroupsInvitations",
        ...response.data,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUP_INVITATIONS_FAIL,
          error: err.message,
        });
      }
      dispatch({
        type: GroupActionTypes.GET_GROUP_INVITATIONS_FAIL,
        error: "can't get group invitations",
      });
    }
  };
};
export const UpdateGroupMembership = (
  groupId: number,
  userId: number,
  status: GroupMemershipStatus,
  cbs?: ActionsCallbacks
) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {

    try {
      dispatch({ type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_REQUEST });
      const response = await put<any>(
        `/user/group-membership/${groupId}`,
        {
          userId,
          status,
        },
      );
      WSResponseHandler({
        event: "updateGroupMembership",
        ...response.data,
      });

      if (response.status === "success") {
        cbs?.onSuccess?.(response.message);
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_FAIL,
          error: err.message,
        });
        notify('error', err.message)
        cbs?.onError?.(err.message);
      }
    }
  };
};

export const LeaveGroup = (groupId: number, cbs?: ActionsCallbacks) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.LEAVE_GROUP_REQUEST });
      await api.ready;
      const sentResult = await api.api.groups.leaveGroup(groupId);
      if (sentResult.status === "error") {
        throw Error(sentResult.message)
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.LEAVE_GROUP_FAIL,
          error: err.message,
        });
        notify('error', err.message)
      }
    }
  };
};

export const GetGroup = (groupId: number) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    if (getState().group.loaders.getGroup) return;

    try {
      dispatch({ type: GroupActionTypes.GET_GROUP_REQUEST });
      const response = await get<any>(
        `/user/group/${groupId}`,
      );
      WSResponseHandler({
        event: "getGroup",
        ...response,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUP_FAIL,
          error: err.message,
        });
      }
      dispatch({
        type: GroupActionTypes.GET_GROUP_FAIL,
        error: "can't get group info",
      });
    }
  };
};

export const UpdateGroup = (group: Group) => {
  return async (dispatch: Dispatch<GroupActions>) => {
    dispatch({
      type: GroupActionTypes.UPDATE_GROUP,
      payload: group,
    });
    const { id, avatar, name } = group;
    dispatch({
      type: GroupActionTypes.UPDATE_GROUP_IN_PROFILE_GROUPS_LIST,
      payload: {
        id,
        avatar,
        name,
      },
    });

  };
};

export const GetPaginatedGroupTrophies = (
  groupId: number,
  page: number,
  limit: number
) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    if (getState().group.loaders.getGroupTrophies) return;

    try {
      dispatch({ type: GroupActionTypes.GET_GROUP_TROPHIES_REQUEST });

      const response = await get<any>(
        `/user/group-trophies/${groupId}`,
        { page, limit },
      );
      WSResponseHandler({
        event: "getGroupTrophiesPaginated",
        ...response.data,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUP_TROPHIES_FAIL,
          error: err.message,
        });
      }
    }
  };
};

export const GetAdminGroups = (userId: number) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_REQUEST });
      await api.ready;
      const sentResult = await api.api.groups.getAdminGroupsList(userId);
      if (sentResult.status === "error") {
        throw Error(sentResult.message)
      }
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_FAIL,
          error: err.message,
        });
        notify('error', err.message)
      }
    }
  };
};

export const GetGroupToCreateLeague = (userId: number) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_REQUEST });
      await api.ready;
      api.api.groups.getGroupsToCreateLeague(userId);
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_FAIL,
          error: err.message,
        });
      }
    }
  };
};

export const GetGroupGames = (groupId: number, page: number, limit: number) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.GET_GROUP_GAMES_REQUEST });
      const response = await get<GroupGames>(
        `/engine/games/groups/${groupId}/groupGames?page=${page}&limit=${limit}`,
      );

      dispatch({
        type: GroupActionTypes.GET_GROUP_GAMES_SUCCESS,
      });
      dispatch({
        type: GroupActionTypes.UPDATE_GROUP_GAMES,
        payload: response,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUP_GAMES_FAIL,
          error: err.message,
        });
      }
    }
  };
};
export const UpdateGroupMemberNetworkStatus = (
  data: UpdateGroupMemberNetworkStatusData
) => {
  return async (dispatch: Dispatch<GroupActions>) => {
    dispatch({
      type: GroupActionTypes.UPDATE_GROUP_MEMBER_NETWORK_STATUS,
      payload: { ...data },
    });
  };
};

export const HandleUpdateGroupMemberNetworkStatus = (
  data: UpdateGroupMemberNetworkStatusData
) => {
  store.dispatch(UpdateGroupMemberNetworkStatus(data));
};

export const GetPassedGroupGames = (
  groupId: number,
  page: number,
  limit: number
) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: GroupActionTypes.GET_GROUP_PASSED_GAMES_REQUEST });
      const response = await get<GroupPassedGames>(
        `/engine/games/groups/${groupId}/groupPassedGames?page=${page}&limit=${limit}`,
      );

      dispatch({
        type: GroupActionTypes.GET_GROUP_PASSED_GAMES_SUCCESS,
      });
      dispatch({
        type: GroupActionTypes.UPDATE_GROUP_PASSED_GAMES,
        payload: response,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUP_PASSED_GAMES_FAIL,
          error: err.message,
        });
      }
    }
  };
};

export const GetGroupsList = (
  userId: number,
  pagination: PaginationRequest
) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    if (getState().profile.loaders.groupList) return;

    try {
      dispatch({ type: GroupActionTypes.GET_GROUPS_LIST_REQUEST });
      const response = await get<any>(`/user/group-list/${userId}`);

      WSResponseHandler({
        event: "getGroupList",
        ...response.data,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUPS_LIST_FAIL,
          error: err.message,
        });
      }
    }
  };
};

export const HandleGroupInvitation = (data: GroupInvitation) => {
  store.dispatch(AddGroupInvitation(data));
}

export const AddGroupInvitation = (data: GroupInvitation) => {
  return async (dispatch: Dispatch<GroupActions>) => {
    dispatch({
      type: GroupActionTypes.ADD_GROUP_INVITATION,
      payload: data,
    });
  };
};

export const GetGroupName = (groupId: number) => {
  return async (
    dispatch: Dispatch<GroupActions>,
    getState: () => RootState,
    api: Api
  ) => {
    if (getState().group.loaders.getGroupName) return;

    try {
      dispatch({ type: GroupActionTypes.GET_GROUP_NAME_REQUEST });
      const response = await get<any>(
        `/user/group-name/${groupId}`,
      );
      WSResponseHandler({
        event: "getGroupName",
        ...response,
      });
    } catch (err) {
      if (err instanceof Error) {
        dispatch({
          type: GroupActionTypes.GET_GROUP_NAME_FAIL,
          error: err.message,
        });
      }
      dispatch({
        type: GroupActionTypes.GET_GROUP_NAME_FAIL,
        error: "can't get group info",
      });
    }
  };
};
