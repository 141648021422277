/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link } from "react-router-dom";

const UnderConstruction = () => {
  return (
    <>
      <h5>UNDER CONSTRUCTION</h5>
      <p>
        We are currently completing the functionality to build your team roster.
        Newly purchased franchises will generate the following assets:
      </p>
      <ul>
        <li>
          53 players: 3 QB, 4 RB, 6 WR, 10 OL, 3 TE, 9 DL, 6 LB, 6 CB, 4 S, 1 P
          and 1 K.{" "}
        </li>
        <li>1 Head Coach, 3 assistants</li>
        <li>1 General Manager, 3 assistants</li>
      </ul>
      <p>
        When the functionality is completed the button you previously clicked
        will display – PLAYER REVEAL. Simply click the button and the reveal
        process will begin
      </p>
    </>
  );
};

export default UnderConstruction;
