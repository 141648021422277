import { saveDecreaseCount } from "helper/helper";
import { GroupActionTypes, GroupActions } from "redux/actions/Groups";
import { GroupGames, GroupPassedGames } from "redux/actions/Groups/types";
import { PaginatedTrophies } from "redux/actions/TrophyAssets/types";
import { Group, GroupDropdownItem, GroupName } from "types/groups";

export interface GroupsState {
  loader: number;
  loaders: {
    createGroup: boolean;
    editGroup: boolean;
    getGroup: boolean;
    getGroupTrophies: boolean;
    getGroupName: boolean;
  }
  groupInfo: Group | null;
  error: string | null;
  groupTrophies: PaginatedTrophies | null;
  groupsForLeagues: GroupDropdownItem[] | null;
  adminGroupsList: GroupDropdownItem[] | null;
  groupGames: GroupGames | null;
  passedGroupGames: GroupPassedGames | null;
  groupName: GroupName | null;
}

const initialState: GroupsState = {
  loader: 0,
  loaders: {
    createGroup: false,
    getGroup: false,
    getGroupTrophies: false,
    editGroup: false,
    getGroupName: false,
  },
  groupInfo: null,
  error: null,
  groupTrophies: null,
  groupsForLeagues: null,
  adminGroupsList: null,
  groupGames: null,
  passedGroupGames: null,
  groupName: null,
};

const reducer = (state = initialState, action: GroupActions): GroupsState => {
  switch (action.type) {
    case GroupActionTypes.CREATE_GROUP_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        loaders: {
          ...state.loaders,
          createGroup: true,
        },
        error: null,
      };
    case GroupActionTypes.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        loaders: {
          ...state.loaders,
          createGroup: false,
        },
      };
    case GroupActionTypes.CREATE_GROUP_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        loaders: {
          ...state.loaders,
          createGroup: false,
        },
        error: action.error,
      };
    // @TODO why update if group not opened
    case GroupActionTypes.UPDATE_GROUP:
      return {
        ...state,
        groupInfo: action.payload,
      };
    case GroupActionTypes.GET_GROUP_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroup: true,
        },
        error: null,
      };
    case GroupActionTypes.GET_GROUP_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroup: false,
        },
      };
    case GroupActionTypes.GET_GROUP_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroup: false,
        },
        error: action.error,
      };
    case GroupActionTypes.JOIN_GROUP_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        error: null,
      };
    case GroupActionTypes.JOIN_GROUP_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.JOIN_GROUP_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case GroupActionTypes.INVITE_GROUP_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        error: null,
      };
    case GroupActionTypes.INVITE_GROUP_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.INVITE_GROUP_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case GroupActionTypes.LEAVE_GROUP_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        error: null,
      };
    case GroupActionTypes.LEAVE_GROUP_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.LEAVE_GROUP_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case GroupActionTypes.EDIT_GROUP_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        loaders: {
          ...state.loaders,
          editGroup: true,
        },
        error: null,
      };
    case GroupActionTypes.EDIT_GROUP_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        loaders: {
          ...state.loaders,
          editGroup: false,
        },
      };
    case GroupActionTypes.EDIT_GROUP_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        loaders: {
          ...state.loaders,
          editGroup: false,
        },
        error: action.error,
      };
    case GroupActionTypes.GET_GROUP_TROPHIES_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupTrophies: true,
        },
        loader: state.loader + 1,
      };
    case GroupActionTypes.GET_GROUP_TROPHIES_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupTrophies: false,
        },
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.GET_GROUP_TROPHIES_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupTrophies: false,
        },
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.UPDATE_GROUP_TROPHIES:
      return {
        ...state,
        groupTrophies: action.payload,
      };
    case GroupActionTypes.GET_ADMIN_GROUPS_LIST_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case GroupActionTypes.GET_ADMIN_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.GET_ADMIN_GROUPS_LIST_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.UPDATE_ADMIN_GROUPS_LIST:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        adminGroupsList: action.payload,
      };
    case GroupActionTypes.GET_GROUPS_FOR_LEAGUES_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case GroupActionTypes.GET_GROUPS_FOR_LEAGUES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.GET_GROUPS_FOR_LEAGUES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.UPDATE_GROUPS_FOR_LEAGUES:
      return {
        ...state,
        groupsForLeagues: action.payload,
      };
    case GroupActionTypes.GET_GROUP_GAMES_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case GroupActionTypes.GET_GROUP_GAMES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.GET_GROUP_GAMES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.UPDATE_GROUP_GAMES:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        groupGames: action.payload,
      };
    case GroupActionTypes.GET_GROUP_PASSED_GAMES_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case GroupActionTypes.GET_GROUP_PASSED_GAMES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.GET_GROUP_PASSED_GAMES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.UPDATE_GROUP_PASSED_GAMES:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        passedGroupGames: action.payload,
      };
    case GroupActionTypes.UPDATE_GROUP_MEMBER_NETWORK_STATUS:
      if (!state.groupInfo) return state;
      return {
        ...state,
        groupInfo: {
          ...state.groupInfo,
          members: state.groupInfo?.members.map((member) => {
            if (member.user.id === action.payload.memberId) {
              return {
                ...member,
                user: {
                  ...member.user,
                  networkStatus: action.payload.status
                }
              }
            }
            return member;
          }) || [],
        }
      };
    case GroupActionTypes.GET_GROUP_NAME_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupName: true,
        },
        loader: state.loader + 1,
      };
    case GroupActionTypes.GET_GROUP_NAME_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupName: false,
        },
        groupName: action.payload,
        loader: saveDecreaseCount(state.loader),
      };
    case GroupActionTypes.GET_GROUP_NAME_FAIL:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getGroupName: false,
        },
        loader: saveDecreaseCount(state.loader),
      };
    default:
      return state;
  }
};
export default reducer;
