import { useServerSidePagination } from 'helper/useServerSidePagination';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastSeasonResults } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';

export const useLastSeasonResults = (engId?: number, limit = 5) => {
  const dispatch = useDispatch();
  const { page, handleNextPage, handlePrevPage, setPage } = useServerSidePagination();

  const lastSeasonResults = useSelector((state: RootState) => state.gameEngine.lastSeasonResults);

  useEffect(() => {
    if (engId) {
      dispatch(getLastSeasonResults(engId, limit, page));
    }
  }, [engId, page, limit]);

  return { lastSeasonResults, handleNextPage, handlePrevPage, page, setPage };
};
