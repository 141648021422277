/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import "./SignupStyle.scss";
import { Divider } from "antd";
import Input from "../Common/Form/Input/Input";
import { Row, Col } from "react-bootstrap";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import Checkbox from "../Common/Form/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { metaMaskLoginRequest, signup } from "../../redux/actions/AuthAction";
import { RootState } from "../../redux/store";
import { validEmail, validPassword } from "./../../helper/helper";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";
import { Link } from "react-router-dom";

const MetaMaskSignupForm = () => {
  const errorData = useSelector((state: RootState) => state.app.error);
  const successData = useSelector((state: RootState) => state.app.success);
  const loading = useSelector((state: RootState) => state.app.loader);
  const metaMaskData = useSelector(
    (state: RootState) => state.app.metaMaskData
  );

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userNameErr, setUserNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [conPwdError, setConPwdError] = useState(false);
  const [metaMask, setMetaMask] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(errorData);
  const [success, setSuccess] = useState(successData);
  const [isDisabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const handelSignup = () => {
    if (
      username == "" ||
      email == "" ||
      password == "" ||
      confirmPassword == ""
    ) {
      setErrorMsg("All Fields Are Required");
    } else if (!validEmail.test(email)) {
      setUserNameErr(false);
      setEmailErr(true);
      setPwdError(false);
      setConPwdError(false);
      setErrorMsg("");
    } else if (!validPassword.test(password)) {
      setUserNameErr(false);
      setEmailErr(false);
      setPwdError(true);
      setConPwdError(false);
      setErrorMsg("");
    } else if (password !== confirmPassword) {
      setUserNameErr(false);
      setEmailErr(false);
      setPwdError(false);
      setConPwdError(true);
      setErrorMsg("");
    } else {
      setUserNameErr(false);
      setEmailErr(false);
      setPwdError(false);
      setConPwdError(false);
      setErrorMsg("");
      dispatch(signup(username, email, password, metaMaskData?.[0]));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch(metaMaskLoginRequest(metaMaskData?.[0]));
    }
    if (metaMaskData?.[0]) {
      setDisabled(false);
    }
  }, [metaMaskData?.[0], success]);

  useEffect(() => {
    if (success) {
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    }
    setError(errorData);
    setSuccess(successData);
  }, [success, errorData, successData]);

  setTimeout(() => {
    errorMsg && setErrorMsg("");
    error && setError("");
    setUserNameErr(false);
    setEmailErr(false);
    setPwdError(false);
    setConPwdError(false);
    setErrorMsg("");
  }, 10000);

  return (
    <>
      {!!loading && <LoadingScreen />}
      <div className="signUpBackground">
        <div className="signUpMain">
          <div className="signupHead">
            <h4>CREATE YOUR ACCOUNT DYNASTY ACCOUNT</h4>
            {metaMaskData?.[0] && (
              <h6 className="success">
                MetaMask account Id: {metaMaskData?.[0]}
              </h6>
            )}
            {isDisabled && (
              <h6 className="error">
                MetaMask Account Not Found please{" "}
                <Link to="/signin">signin</Link> with MetaMask
              </h6>
            )}
          </div>
          <Divider> OR </Divider>
          <p className="signupDescription">
            Please fill out this form, and we'll send you a welcome email so you
            can verify your email address and sign in. Password must be 8
            characters, use upper/lower and include a special character or
            number.
          </p>
          <div className="signupForm">
            <Row>
              <Col xl={6} className="mb-3">
                <Input
                  type="text"
                  label="Username*"
                  value={username}
                  onChange={setUsername}
                  placeholder="Enter Username"
                />
                {userNameErr && (
                  <p className="error">Your Username is invalid</p>
                )}
              </Col>
              <Col xl={6} className="mb-3">
                <Input
                  type="email"
                  label="Email*"
                  value={email}
                  onChange={setEmail}
                  placeholder="Enter Email"
                />
                {emailErr && <p className="error">Your email is invalid</p>}
              </Col>
              <Col xl={6} className="mb-3">
                <Input
                  type="password"
                  label="Password*"
                  value={password}
                  onChange={setPassword}
                  placeholder="Enter Password"
                />
                {pwdError && (
                  <p className="error">
                    password does not satisfy requirements
                  </p>
                )}{" "}
              </Col>
              <Col xl={6} className="mb-3">
                <Input
                  type="password"
                  label="Re-enter Password*"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder="Enter Password"
                />
                {conPwdError && <p className="error">Passwords don't match</p>}
              </Col>
              {error && <p className="error">{error}</p>}
              {errorMsg && <p className="error">{errorMsg}</p>}
              <Col xl={12} className="text-center signupsubmit mt-2">
                <BlueButton
                  onClick={handelSignup}
                  text="SUBMIT"
                  disabled={isDisabled}
                />
              </Col>
              <Col xl={12}>
                <Checkbox label="Remember Me" />
              </Col>
              <Col xl={12}>
                <p className="terms">
                  By continuing you agree to the GM Dynasty{" "}
                  <a href="#">terms of use</a> and{" "}
                  <a href="#">privacy policy</a>.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetaMaskSignupForm;
