import AnotherTeamHeader from 'components/FullTeamStat/AnotherTeamHeader';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTeamOverview, UserRecordAPI } from 'redux/actions/GameEngineAction';
import { fetchSelectedAvatar } from 'redux/actions/TeamAction';
import { RootState } from 'redux/store';
import { TeamNFTInfo } from './TeamNFTInfo';

export const TeamNFTInfoMain = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const ID = +id;

  const avatar = useSelector((state: RootState) => state.team.fetchedAvatar);

  useEffect(() => {
    if (ID) {
      dispatch(fetchSelectedAvatar(ID, 'id'));
    }
  }, [ID]);

  useEffect(() => {
    if (ID === avatar?.id) {
      dispatch(UserRecordAPI(avatar.engId));
      dispatch(getTeamOverview(1, avatar.engId));
    }
  }, [ID, avatar]);

  return (
    <Container>
      <AnotherTeamHeader />
      <TeamNFTInfo />
    </Container>
  );
};
