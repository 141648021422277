import Error from "../../../../assets/images/common/error.svg";

const ExportModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Exporting player will remove player from your roster. 
                The player will reside in your wallet and can be re-
                imported to the taxi squad, space permitting. </p>
            </div>  
        </>
    );
}

export default ExportModal;