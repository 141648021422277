import { ComputedStats, WeekPlayer } from "redux/actions/Leaderboard/types";
import { returnCorrectImage } from "../LeaderboardLeadersBySkill";

const StatsLabels = {
  defTckSolo: "TAK",
  defSk: "SACKS",
  defInt: "INT",
  recYds: "YDS",
  rec: "REC",
  recTD: "TDS",
  rusTD: "TDS",
  prTD: "TDS",
  krTD: "TDS",
  rusYds: "YDS",
  pssTD: "TDS",
  qbW: "WINS",
  rus: "ATT",
  fg0: "FGA",
  fg20: "FGA",
  fg30: "FGA",
  fg40: "FGA",
  fg50: "FGA",
  fga0: "FGM",
  fga20: "FGM",
  fga30: "FGM",
  fga40: "FGM",
  fga50: "FGM",
  fgLng: "LNG",
};

interface WeekPlayerItemProps {
  isLoading?: boolean;
  player: WeekPlayer;
  positionLabel?: string;
}

export function WeekPlayerItem({
  isLoading,
  player,
  positionLabel,
}: WeekPlayerItemProps) {
  const countedStats = Object.keys(player.computedStats).reduce(
    (acc, statKey) => {
      const statLabel = StatsLabels[statKey as keyof typeof StatsLabels];
      if (!statLabel) return acc;
      if (!acc[statLabel]) {
        acc[statLabel] = 0;
      }
      acc[statLabel] += player.computedStats[statKey as keyof ComputedStats];
      return acc;
    },
    {} as { [key: string]: number }
  );

  return (
     <div className="player-item d-flex">
        <img
           style={{ height: 80, width: 60 }}
           src={returnCorrectImage(player.leaderStat)}
           alt={player.leaderStat}
        />
        <div className="player-item__right-content">
           <p>{positionLabel}</p>
           <div style={{ fontSize: ".85rem" }}>
              <div className="player-item__subtext-row">
                 <a href={`/playerdetail/${player.pid}?tab=career`} className="name-link">
                    <span>{`${player.firstname} ${player.lastName}`}</span>
                 </a>
                 <div className="divider" />
                 <a href={`/franchisedetail/${player.utid}`} className="name-link">
                    <span>{player.team}</span>
                 </a>
              </div>
              <div className="d-flex" style={{ marginTop: ".25rem" }}>
                 {Object.keys(countedStats).map((stat, index) => (
                    <div className="player-item__subtext-row" key={index}>
                       <span>
                          {`
                    ${countedStats[stat]}
                    ${stat}
                  `}
                       </span>
                       {index !== Object.keys(countedStats).length - 1 && (
                          <div className="divider" />
                       )}
                    </div>
                 ))}
              </div>
           </div>
        </div>
     </div>
  );
}
