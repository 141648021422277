import { useEffect, useRef, useState } from 'react';
import players from './svgs';
import { Form, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import './PlayerCreation.scss';
import { disabledOptions } from './constants';
import { Roster } from 'components/MintRoster/interface';
import html2canvas from 'html2canvas';
import { PropertySelector } from 'components/PurchaseTrophy/components/PropertySelector/PropertySelector';
import { PATH_TO_TROPHY_FOLDER } from 'components/PurchaseTrophy/components/TrophyPreview/TrophyPreview';
import { SelectorPopover } from 'components/PurchaseTrophy/components/SelectorPopover/SelectorPopover';
import { TrophyAssetsObject } from 'redux/actions/TrophyAssets/types';
import { getColorFromBgName, getSelectorTitle } from 'components/PurchaseTrophy/helper';
import { useTrophyAssets } from 'components/PurchaseTrophy/hooks/useTrophyAssets';
import { UserTeamsList } from 'types';

type Props = {
  team: UserTeamsList[0] | null;
  roster: Roster | null;
  setFrontImage: (value: string | undefined) => void;
}

const renderTooltip = ({ title = "Enable Splash", ...props }: { title: string }) => (
  <Tooltip id="button-tooltip" {...props}>
    {title}
  </Tooltip>
);

export const PlayerCreation: React.FC<Props> = ({ roster, setFrontImage }) => {
  const [mainColor, setMainColor] = useState('#99243e');
  const [secondaryColor, setSecondaryColor] = useState('#ffffff');
  const [ballColor, setBallColor] = useState('#ad4643');
  const [skinColor, setSkinColor] = useState('#f6c9b8');
  const [bootsColor, setBootsColor] = useState('#434343');
  const [splashColor, setSplashColor] = useState('#99243e');
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>();
  const [isUnselectedError, setIsUnselectedError] = useState(false);
  const [background, setBackground] = useState<
    TrophyAssetsObject["bg"][0] | null
  >(null);
  const [texture, setTexture] = useState<
    TrophyAssetsObject["texture"][0] | null
  >(null);
  const [isSplashEnabled, setIsSplashEnabled] = useState(true);

  const handleSplashColorChange = (color: string) => {
    setSplashColor(color);
    setIsSplashEnabled(true);
  };
  const trophyAssets = useTrophyAssets();

  const disabledValues = disabledOptions[roster?.pos as keyof typeof players || 'QB'] || [];

  const ref = useRef<HTMLCanvasElement>(null);

  const handleBackgroundSelect = (backgroundValue: TrophyAssetsObject["bg"][0]) => {
    setBackground(backgroundValue);
    setTexture(null);
    const bgColor = `#${getColorFromBgName(backgroundValue?.filename || '')}`;

    const canvas = document.createElement('canvas');
    if (canvas) {
      canvas.width = 100;
      canvas.height = 100;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL('image/png');
        setBackgroundImage(imageDataUrl);
      }

    };
  };

  const handleTextureSelect = (texture: TrophyAssetsObject["texture"][0]) => {
    setTexture(texture);

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = `${PATH_TO_TROPHY_FOLDER}${texture.filename}`;
    const bgColor = `#${getColorFromBgName(background?.filename || '')}`;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      if (canvas) {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.fillStyle = bgColor;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
          const imageDataUrl = canvas.toDataURL('image/png');
          setBackgroundImage(imageDataUrl);
        }
      }
    };
  };

  const props = {
    backgroundImage,
    mainColor,
    ballColor,
    skinColor,
    secondaryColor,
    bootsColor,
    splashColor: isSplashEnabled ? splashColor : 'rgba(0, 0, 0, 0)',
    splash: true,
    logoImage: undefined,
    ref,
  };

  useEffect(() => {
    const canvasElement = ref?.current;

    if (canvasElement) {
      html2canvas(canvasElement).then((canvas) => {
        const imageDataUrl = canvas.toDataURL('image/png');
        setFrontImage(imageDataUrl);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainColor,
    secondaryColor,
    ballColor,
    skinColor,
    bootsColor,
    splashColor,
    backgroundImage,
    roster?.pid,
    isSplashEnabled,
  ]);

  const position = roster?.pos as keyof typeof players || 'QB';
  const Component = players[position];

  if (!Component) {
    console.error(`Component for position "${position}" is not defined.`);
    return null;
  }

  const hideUnselectedError = () => {
    setIsUnselectedError(false);
  };

  return (
    <>
      <Stack gap={2} direction="horizontal">
        <Component {...props} key={texture?.filename || background?.filename} />
        <Stack gap={2}>
          <label className="d-flex gap-2 justify-content-between align-items-center">
            <strong className="fs-6">1.Main Color</strong>
            <Form.Control
              className="height-auto p-2"
              type="color"
              value={mainColor}
              onChange={(e) => setMainColor(e.target.value)}
              disabled={disabledValues.includes('mainColor')}
            />
          </label>
          <label className="d-flex gap-2 justify-content-between align-items-center">
            <strong className="fs-6">2. Secondary Color</strong>
            <Form.Control
              className="height-auto p-2"
              type="color"
              value={secondaryColor}
              onChange={(e) => setSecondaryColor(e.target.value)}
              disabled={disabledValues.includes('secondaryColor')}
            />
          </label>
          <label className="d-flex gap-2 justify-content-between align-items-center">
            <strong className="fs-6">3. Ball Color</strong>
            <Form.Control
              className="height-auto p-2"
              type="color"
              value={ballColor}
              onChange={(e) => setBallColor(e.target.value)}
              disabled={disabledValues.includes('ballColor')}
            />
          </label>
          <label className="d-flex gap-2 justify-content-between align-items-center">
            <strong className="fs-6">4. Skin Color</strong>
            <Form.Control
              className="height-auto p-2"
              type="color"
              value={skinColor}
              onChange={(e) => setSkinColor(e.target.value)}
              disabled={disabledValues.includes('skinColor')}
            />
          </label>
          <label className="d-flex gap-2 justify-content-between align-items-center">
            <strong className="fs-6">5. Boots Color</strong>
            <Form.Control
              className="height-auto p-2"
              type="color"
              value={bootsColor}
              onChange={(e) => setBootsColor(e.target.value)}
              disabled={disabledValues.includes('bootsColor')}
            />
          </label>
          <label className="d-flex gap-2 justify-content-between align-items-center">
            <strong className="fs-6">6. Splash Color</strong>
            <div className="position-relative">
              <Form.Control
                className="height-auto p-2"
                type="color"
                value={splashColor}
                onChange={(e) => handleSplashColorChange(e.target.value)}
                disabled={disabledValues.includes('splashColor')}
              />
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({ title: isSplashEnabled ? "Disable Splash" : "Enable Splash" })}
              >
                <Form.Check
                  className="checkbox-position"
                  type="checkbox"
                  checked={isSplashEnabled}
                  onChange={(e) => setIsSplashEnabled(e.target.checked)}
                />
              </OverlayTrigger>
            </div>
          </label>
          <PropertySelector
            selectorName="7. Select Background"
            unselectedText="Please, Select Background"
            currentSelectedId={background?.id || 0}
            currentSelectedValue={background?.name}
            currentSelectedImgUrl={
              background?.filename
                ? `${PATH_TO_TROPHY_FOLDER}${background?.filename}`
                : undefined
            }
            toggleSelector={() => { }}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: any) => setBackground(value)}
            SelectorComponent={
              <SelectorPopover
                title="Select Background"
                onSelect={handleBackgroundSelect}
                imageArray={trophyAssets?.bg || []}
                mode="bg"
              />
            }
            isColor
          />
          <PropertySelector
            selectorName="8. Select Texture"
            unselectedText="Please, Select Texture"
            currentSelectedId={texture?.id || 0}
            currentSelectedValue={getSelectorTitle(texture, "Texture")}
            currentSelectedImgUrl={
              texture?.filename
                ? `${PATH_TO_TROPHY_FOLDER}${texture?.filename}`
                : undefined
            }
            toggleSelector={() => { }}
            isUnselectedErrorShown={isUnselectedError}
            hideUnselectedError={hideUnselectedError}
            handleSelection={(value: TrophyAssetsObject["texture"][0]) => setTexture(value)}
            SelectorComponent={
              <SelectorPopover
                title="Select Texture"
                onSelect={handleTextureSelect}
                imageArray={trophyAssets?.texture || []}
                mode="image"
                imageBG={
                  background?.filename
                    ? `#${getColorFromBgName(background.filename)}`
                    : "#384c6c"
                }
              />
            }
            imageBG={
              background?.filename
                ? `#${getColorFromBgName(background.filename)}`
                : "#384c6c"
            }
          />
        </Stack>
      </Stack>
    </>
  );
};
