//@ts-nocheck
import React, { useEffect } from 'react';
import store from './redux/store';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import Routes from './routes';
import 'antd/dist/antd.css';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import history from './routes/history';
import jwt_decode from 'jwt-decode';
import { logout, setData } from './redux/actions/AuthAction';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { deleteCookies } from './helper/helper';
import { GoogleOAuthProvider } from '@react-oauth/google';

const token: string = localStorage.getItem('token') || '';

if (token) {
  try {
    const decodedToken: any = jwt_decode(token);
    if (decodedToken.exp < Date.now() / 1000 || !('exp' in decodedToken)) {
      store.dispatch(logout());
    } else {
      store.dispatch(setData(token));
    }
  } catch (e) {
    store.dispatch(logout());
  }
}

const App: React.FC = () => {
  const appVersion = 'appVersion';
  const currentVersion = process.env.REACT_APP_VERSION ?? '0.1.0';

  useEffect(() => {
    const lastVersion = localStorage.getItem(appVersion);
    if (lastVersion === null || lastVersion !== currentVersion) {
      localStorage.clear();
      localStorage.setItem(appVersion, currentVersion);
      deleteCookies();
    }
  }, [currentVersion]);

  useEffect(() => {
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then((persistent) => {
        if (persistent) {
          console.log('Storage will be persisted.');
        } else {
          console.log('Storage permission denied.');
        }
      });
    } else {
      console.log('Persistent storage API not supported in this browser.');
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
