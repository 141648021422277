import GamePlanSlider from '../Common/GamePlanSlider/GamePlanSlider'
import { Menu, Dropdown } from 'antd'
import { DownOutlined, LockOutlined } from '@ant-design/icons'
import { OffensiveStrategy as OffStrat } from "../../types";

type Props = {
  offensiveStrategy: OffStrat;
  updateOffensiveStrategy: (key: keyof OffStrat, value: number) => void
}

const OffensiveStrategy: React.FC<Props> = ({
  offensiveStrategy,
  updateOffensiveStrategy
}) => {
  const data = [
    {
      id: 1,
      sliderName: 'DEVIATE FROM BASE SCHEME',
      slidervalue: offensiveStrategy.deviation,
      sliderleftname: 'LESS',
      sliderrightname: 'MORE',
      sliderKeyName: 'deviation',
      onChange: updateOffensiveStrategy
    },
    {
      id: 2,
      sliderName: '1ST DOWN PLAY SELECTION',
      slidervalue: offensiveStrategy["1stDownRun"],
      sliderleftname: 'PASS',
      sliderrightname: 'RUN',
      sliderKeyName: '1stDownRun',
      onChange: updateOffensiveStrategy
    },
    {
      id: 3,
      sliderName: 'SHORT PLAY SELECTION',
      slidervalue: offensiveStrategy.shortYdsRun,
      sliderleftname: 'PASS',
      sliderrightname: 'RUN',
      sliderKeyName: 'shortYdsRun',
      onChange: updateOffensiveStrategy
    },
    {
      id: 3,
      sliderName: 'LONG PLAY SELECTION',
      slidervalue: offensiveStrategy.longYdsRun,
      sliderleftname: 'PASS',
      sliderrightname: 'RUN',
      sliderKeyName: 'longYdsRun',
      onChange: updateOffensiveStrategy
    },
    {
      id: 4,
      sliderName: <>4TH DOWN "GO FOR IT"</>,
      slidervalue:  offensiveStrategy.goOnFourth,
      sliderleftname: 'RUN',
      sliderrightname: 'PASS',
      sliderKeyName: 'goOnFourth',
      onChange: updateOffensiveStrategy
    },
    {
      id: 5,
      sliderName: 'RUSHING PLAYS',
      slidervalue: offensiveStrategy.insideRuns,
      sliderleftname: 'INTERIOR',
      sliderrightname: 'SWEEPS',
      sliderKeyName: 'insideRuns',
      onChange: updateOffensiveStrategy
    },
    {
      id: 6,
      sliderName: 'QUARTERBACK SNAP',
      slidervalue: offensiveStrategy.pctPassGun,
      sliderleftname: 'UNDER CENTER',
      sliderrightname: 'SHOTGUN',
      sliderKeyName: 'pctPassGun',
      onChange: updateOffensiveStrategy
    },
  ]

  const menu = (
    <Menu className='specialdrop'>
      <Menu.Item>
          1 RB / 4 WR
      </Menu.Item>
      <Menu.Item>
          2 RB / 2 WR / 1 TE <LockOutlined />
      </Menu.Item>
      <Menu.Item>
          1 RB / 3 WR / 1 TE <LockOutlined />
      </Menu.Item>
      <Menu.Item>
          1 RB / 2 WR / 2 TE <LockOutlined />
      </Menu.Item>
      <Menu.Item>
          2 RB / 1 WR / 2 TE <LockOutlined />
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <div className='gameplan_main_section'>
        <div className='dropdown_gameplan'>
          <h5>SELECT BASE OFFENSIVE SCHEME</h5>
          <div className='filterdropdown specialteams'>
            <Dropdown overlay={menu}>
              <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                1 RB / 4 WR <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
        {data.map((d: any, i: any) => (
          <GamePlanSlider data={d} key={i} />
        ))}
      </div>
    </>
  )
}

export default OffensiveStrategy
