/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import FullTeamStatMain from "../../components/FullTeamStat/FullTeamStatMain";
import LoadingScreen from "../../components/Common/LoadingScreen/LoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import {
  getFullTeamStats,
} from "../../redux/actions/GameEngineAction";
import { useParams } from "react-router-dom";
import { useScrollTop } from "helper/useScrollTop";

const FullTeamStatScreen: React.FC = () => {
  const teamStatLoader = useSelector((state: RootState) => state.gameEngine.FullTeamStatsLoader);
  const dispatch = useDispatch();
  const { gid, utid }: any = useParams();

  useEffect(() => {
    dispatch(getFullTeamStats(utid));
  }, []);

  useScrollTop();

  return (
    <>
      {teamStatLoader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <FullTeamStatMain gid={gid}/>
      </div>
    </>
  );
};

export default FullTeamStatScreen;
