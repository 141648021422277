import Error from "../../assets/images/common/error.svg";

const BelowTeamRatingModal: React.FC = () => (
    <div className="startdraftmain" >
        <img src={Error} alt="erroricon" />
        <p>The franchise selected can not be entered into this league since it does 
        not meet the game level requirement. Please select a league that 
        matches your franchise rating.</p>
    </div> 
);

export default BelowTeamRatingModal;
