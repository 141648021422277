import ProfileImageBackup from "../../../assets/images/common/userName.png";
import history from "routes/history";
import { API_URL } from "config";
import { FoundUser } from "types/groups";

export function FoundUserProfile({ data }: { data: Partial<FoundUser> }) {
  const handleNavigation = () => history.push(`/profile/${data.id}`);

  const profileImg = data?.avatar
    ? `${API_URL}/images/avatar/${data?.avatar}`
    : ProfileImageBackup;

  return (
    <div onClick={handleNavigation} className="search_user__profile" title="Profile">
      <div className="img__container">
        <img src={profileImg} alt="profile" />
      </div>
      <p>{data.userName}</p>
    </div>
  );
}
