import { SpecialFeatureCode, SpecialFeatureDisplayName } from "types";
import ACCURATE_PASSER from "../assets/images/specialFeatures/Pa.png";
import ATHLETIC from "../assets/images/specialFeatures/A.png";
import DEEP_PASSER from "../assets/images/specialFeatures/Pd.png";
import EXPLOSIVE from "../assets/images/specialFeatures/X.png";
import HANDS from "../assets/images/specialFeatures/H.png";
import PASS_BLOCKER from "../assets/images/specialFeatures/Bp.png";
import PASS_LOCKDOWN from "../assets/images/specialFeatures/L.png";
import PASS_RUSHER from "../assets/images/specialFeatures/PR.png";
import RUN_BLOCKER from "../assets/images/specialFeatures/Br.png";
import RUN_STOPPER from "../assets/images/specialFeatures/RS.png";
import SMART_PASSER from "../assets/images/specialFeatures/Ps.png";

export const specialFeatures = {
   [SpecialFeatureCode.ACCURATE_PASSER]: SpecialFeatureDisplayName.ACCURATE_PASSER,
   [SpecialFeatureCode.ATHLETIC]: SpecialFeatureDisplayName.ATHLETIC,
   [SpecialFeatureCode.DEEP_PASSER]: SpecialFeatureDisplayName.DEEP_PASSER,
   [SpecialFeatureCode.EXPLOSIVE]: SpecialFeatureDisplayName.EXPLOSIVE,
   [SpecialFeatureCode.HANDS]: SpecialFeatureDisplayName.HANDS,
   [SpecialFeatureCode.PASS_BLOCKER]: SpecialFeatureDisplayName.PASS_BLOCKER,
   [SpecialFeatureCode.PASS_LOCKDOWN]: SpecialFeatureDisplayName.PASS_LOCKDOWN,
   [SpecialFeatureCode.PASS_RUSHER]: SpecialFeatureDisplayName.PASS_RUSHER,
   [SpecialFeatureCode.RUN_BLOCKER]: SpecialFeatureDisplayName.RUN_BLOCKER,
   [SpecialFeatureCode.RUN_STOPPER]: SpecialFeatureDisplayName.RUN_STOPPER,
   [SpecialFeatureCode.SMART_PASSER]: SpecialFeatureDisplayName.SMART_PASSER,
};

export const specialFeatureImageSources = {
   [SpecialFeatureCode.ACCURATE_PASSER]: ACCURATE_PASSER,
   [SpecialFeatureCode.ATHLETIC]: ATHLETIC,
   [SpecialFeatureCode.DEEP_PASSER]: DEEP_PASSER,
   [SpecialFeatureCode.EXPLOSIVE]: EXPLOSIVE,
   [SpecialFeatureCode.HANDS]: HANDS,
   [SpecialFeatureCode.PASS_BLOCKER]: PASS_BLOCKER,
   [SpecialFeatureCode.PASS_LOCKDOWN]: PASS_LOCKDOWN,
   [SpecialFeatureCode.PASS_RUSHER]: PASS_RUSHER,
   [SpecialFeatureCode.RUN_BLOCKER]: RUN_BLOCKER,
   [SpecialFeatureCode.RUN_STOPPER]: RUN_STOPPER,
   [SpecialFeatureCode.SMART_PASSER]: SMART_PASSER,
};