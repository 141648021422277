import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import GameResultsHeadTabMain from "../../components/LeagueHeadToHead/GameResultsHeadTabMain";

const LeagueOverviewGameResultsScreen: React.FC = () => (
  <div className="pagelayout">
    <WithLoginNavbar />
    <GameResultsHeadTabMain />
  </div>
);

export default LeagueOverviewGameResultsScreen;
