import { useHistory } from "react-router-dom";

export function useHandleRedirect() {
  const history = useHistory();

  const handleRedirect = (path: string) => {
    history.push(path);
  };
  return { handleRedirect };
}
