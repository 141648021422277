import { Row, Col } from "react-bootstrap";
import Input from "../../Common/Form/Input/Input";
import { useEffect, useState } from "react";
import BlueButton from "../Button/BlueButton/BlueButton";
import './ForgotPasswordModalStyles.scss';
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordEmail } from "../../../redux/actions/AuthAction";
import { RootState } from "../../../redux/store";
import { cleanForgotError, cleanSuccess } from "../../../redux/actions/AppAction";

const ForgotPasswordModal = () => {
  const dispatch = useDispatch();
  
  const successMessage = useSelector((state: RootState) => state?.app?.success);
  const errorMessage = useSelector((state: RootState) => state?.app?.forgotError);
  
  const [email, setEmail] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  useEffect(() => {
    typeof successMessage === "string" && setSuccess(successMessage);
    errorMessage && setError(errorMessage);
  }, [successMessage, errorMessage]);

  useEffect(() => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    setSubmitDisabled(!isValidEmail);
  }, [email]);

  const handleSubmit = () => {
    dispatch(cleanSuccess());
    dispatch(cleanForgotError());
    dispatch(forgotPasswordEmail(email));
  };

  return (
    <div className="forgot-password-component">
      <h5>FORGOT YOUR PASSWORD</h5>
      <p>
        Don't worry, we can help you. Give us the email address you use to log in to GM Dynasty
        and we'll send you instructions for resetting your password.
      </p>
      <Row>
        <Col xl={12}>
          <Input
            classNameForm="forgot-password-form"
            type="email"
            label="Email address:"
            value={email}
            onChange={setEmail}
            placeholder="Email"
          />
          {submitDisabled && email && (
            <p className="error">Please enter a valid email address.</p>
          )}
          {success && <p className="success">{success}</p>}
          {error && <p className="error">{error}</p>}
        </Col>
        <Col xl={12} lg={12} md={12} sm={12}>
          <BlueButton
            className="login-button"
            text="SUBMIT"
            disabled={submitDisabled}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPasswordModal;
