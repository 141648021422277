import { Container } from 'react-bootstrap';
import { SeasonHeader } from './SeasonHeader';
import { useParams } from 'react-router-dom';
import { useSeasonInfo } from './hooks/useSeasonInfo';
import { useEffect } from 'react';
import { SeasonContent } from './SeasonContent';

export const SeasonMain: React.FC = () => {
  const { gid }: { gid: string } = useParams();

  const { loadSeasonInfo } = useSeasonInfo(gid);

  useEffect(() => {
    loadSeasonInfo();
  }, [gid]);

  return (
    <Container>
      <SeasonHeader gid={gid} />
      <SeasonContent />
    </Container>
  );
};
