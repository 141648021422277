import Title from "components/Common/Title/Title"
import { Col, Row } from "react-bootstrap"
import { LeaderboardWeeklyLeadersWins } from "./LeaderboardWeeklyLeadersWins";
import { LeaderboardWeeklyLeadersRatings } from "./LeaderboardWeeklyLeadersRatings";

export const LeaderboardWeeklyLeaders = () => {
  return (
      <Row>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <Title text="WINS"/>
        <LeaderboardWeeklyLeadersWins />
      </Col>
      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        <Title text="LARGEST RATING INCREASE"/>
        <LeaderboardWeeklyLeadersRatings />
      </Col>
    </Row>
  )
}