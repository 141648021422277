import { useEffect, useState } from "react";
import { LobbyUser, getLobbyUsers } from "../helper";

export const useLobbyUsers = (page: number, limit: number) => {
  const [lobbyUsers, setLobbyUsers] = useState<LobbyUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noNextPage, setNoNextPage] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLobbyUsers(page, limit)
      .then((lobbyUsersRes) => {
        if (lobbyUsersRes.length === 0) {
          setNoNextPage(true);
        } else {
          setNoNextPage(false);
        }
        setLobbyUsers((prev) => {
          const listCopy = [...prev];
          for (const user of lobbyUsersRes) {
            if (!prev.some((u) => user.username === u.username)) listCopy.push(user);
          }
          return listCopy;
        });
      })
      .catch((e) => {})
      .finally(() => setIsLoading(false));
  }, [page, limit]);

  return { lobbyUsers, isLoading, noNextPage };
};
