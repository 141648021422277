import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SeasonActions } from "redux/actions/Season";
import { RootState } from "redux/store";

export const useSeasonLeaders = (gid?: string) => {
  const dispatch = useDispatch();
  const seasonLeaders = useSelector(
    (state: RootState) => state.gameEngine.seasonLeaders
  );

  useEffect(() => {
    if (gid) {
      dispatch(SeasonActions.getSeasonLeaders(gid));
    }
  }, [gid])

  return seasonLeaders;
};
