import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Card from '../../../Common/Card/Card';
import CardBody from '../../../Common/Card/CardBody';
import GamePlanOffensiveStrategy from './GamePlanOffensiveStrategy';
import GamePlanDefensivestretegy from './GamePlanDefensivestretegy';
import { RootState } from '../../../../redux/store';
import { TeamGamePlan } from '../../../../types';

type Props = {
  updateGamePlan: any;
};

const GamePlanMain: React.FC<Props> = ({ updateGamePlan }) => {
  const teamGamePlanData: TeamGamePlan | null = useSelector((state: RootState) => state.gameEngine.teamGamePlan);

  const [offensiveDataProp, setOffensiveDataProp] = useState<any>();
  const [defensiveData, setDefensiveData] = useState<any>();
  const [specialData, setSpecialData] = useState<any>();

  useEffect(() => {
    setOffensiveDataProp(teamGamePlanData?.schemes?.offense?.sets);
    setDefensiveData(teamGamePlanData?.schemes?.defense?.sets);
    setSpecialData(teamGamePlanData?.schemes?.special);
  }, [teamGamePlanData]);

  return (
    <Row>
      <Col xl={12} className="mb-5">
        <Card>
          <CardBody>
            <Row>
              <Col xl={6} lg={6}>
                <GamePlanOffensiveStrategy
                  OffensiveDataProp={offensiveDataProp}
                  teamGamePlanData={teamGamePlanData}
                  updateGamePlan={updateGamePlan}
                  selectedSid={teamGamePlanData?.schemes?.offense?.selectedSid}
                />
              </Col>
              <Col xl={6} lg={6}>
                <GamePlanDefensivestretegy
                  DefensiveDataProp={defensiveData}
                  SpecialDataProp={specialData}
                  teamGamePlanData={teamGamePlanData}
                  updateGamePlan={updateGamePlan}
                  selectedSid={teamGamePlanData?.schemes?.defense?.selectedSid}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GamePlanMain;
