import history from "routes/history";

type Props = { 
  to: string; 
  children: React.ReactNode; 
  params?: Record<string, any> | any 
};

export const StyledLink: React.FC<Props> = ({ to, children, params }) => (
  <span 
    className="link-like" 
    style={{ color: 'inherit' }} 
    onClick={() => history.push(to, params)}
  >
    {children}
  </span>
);
