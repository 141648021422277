import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTrophyListWithPagination } from "redux/actions/TrophyAssets/actions/GetTrophyAssets";
import { RootState } from "redux/store";

export const useTrophiesWithPagination = (
  userId: number,
  page: number,
  limit: number
) => {
  const dispatch = useDispatch();

  const userTrophiesPagination = useSelector(
    (state: RootState) => state.app.userTrophiesPagination
  );

  useEffect(() => {
    dispatch(getUserTrophyListWithPagination(userId, page, limit));
  }, [userId, page, limit]);

  return userTrophiesPagination;
};
