import metamask from "../../../../../assets/images/common/metamaskicon.svg";
import { useMetamaskLogin } from "../../hooks";

const MetamaskContent = () => {
  const { handleMetamaskLogin } = useMetamaskLogin();
  return (
    <div className="login-option-component">
      <img src={metamask} alt="metamask" className="login-option-image" />
      <div className="login-option-footer">
        <button className="login-with-button" onClick={handleMetamaskLogin}>
          LOGIN WITH METAMASK
        </button>
        <p>
          If you wish to create a MetaMask Wallet account?{" "}
          <a href="https://metamask.io/" target="_blank" rel="noreferrer">
            Click here!
          </a>
        </p>
      </div>
    </div>
  );
};

export default MetamaskContent;
