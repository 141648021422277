import { formatNumber } from "./utils/formatNumber";

export function AdvancedProgressionStats({
  rankings,
  position,
  ratingHighPoint,
  ratingLowPoint,
  averageRatingIncrease,
}: {
  rankings?: {
    currentRanking: number;
    highestRanking: number;
    lowestRanking: number;
  };
  position?: string;
  ratingHighPoint?: number;
  ratingLowPoint?: number;
  averageRatingIncrease?: number;
}) {
  return (
    <div>
      <div className="achievement">
        <p className="title">{ratingHighPoint} RATING</p>
        <p className="value">Rating high point</p>
      </div>
      <div className="achievement">
        <p className="title">{ratingLowPoint} RATING</p>
        <p className="value">Rating low point</p>
      </div>
      <div className="achievement">
        <p className="title">{formatNumber(averageRatingIncrease)} RATING</p>
        <p className="value">Average rating increase per game played</p>
      </div>
      <div className="achievement">
        <p className="title">#{rankings?.currentRanking}</p>
        <p className="value">
          Current ranking among <span>{position}</span>
        </p>
      </div>
      <div className="achievement">
        <p className="title">#{rankings?.highestRanking}</p>
        <p className="value">
          Highest ranking among <span>{position}</span>
        </p>
      </div>
      <div className="achievement">
        <p className="title">#{rankings?.lowestRanking}</p>
        <p className="value">
          Lowest ranking among <span>{position}</span>
        </p>
      </div>
    </div>
  );
}
