/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tab, Nav } from "react-bootstrap";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import OverviewTabMain from "./TabDetail/OverviewTab/OverviewTabMain";
import ScheduleTabMain from "./TabDetail/ScheduleTab/ScheduleTabMain";
import { useLocation } from "react-router-dom";
import { copyLinkToClipboard } from "helper/helper";
import { ShareAltOutlined } from "@ant-design/icons";
import { EliminationScoreTab } from "./EliminationScoreTab";

const LeagueEliminationTabMain: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gid: any = queryParams.get('gid');
  const numGid: number = gid ? +gid : 0;
  
  return (
    <div className="standingTabbarMain mb-4">
      <Tab.Container 
        id="left-tabs-example" 
        defaultActiveKey="third" 
        mountOnEnter
        unmountOnExit
      >
        <div className="standingTabbar">
          <Card>
            <CardBody>
              <div className="nav-menu-container">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">OVERVIEW</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">SCORE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">SCHEDULE</Nav.Link>
                  </Nav.Item>
                </Nav>
              <button className="nav-menu-share-button" onClick={() => copyLinkToClipboard()}><ShareAltOutlined /> SHARE</button>
              </div>
            </CardBody>
          </Card>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <OverviewTabMain gid={numGid}/>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <EliminationScoreTab eliminationId={numGid} />
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            <ScheduleTabMain gid={numGid}/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default LeagueEliminationTabMain;
