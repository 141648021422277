import { eliminationOverview } from "./EliminationOverview";
import { getEliminationLeaders } from "./EliminationLeaders";
import { getEliminationTeams } from "./EliminationTeams";
import { getEliminationPowerRankings } from "./EliminationPowerRankings";
import { getEliminationResults } from "./EliminationResults";

export default { 
  eliminationOverview, 
  getEliminationLeaders,
  getEliminationTeams,
  getEliminationPowerRankings,
  getEliminationResults
 };