import { alignAllColumnsToCenter } from "components/FranchiseOverview/TabDetail/DepthChartTab/helper";
import { sumObjectsByKeys } from "helper/helper";
import { Table } from "antd";
import { CoachCareerStatsType } from "./types";

type Props = {
  careerStats: CoachCareerStatsType;
};

export const CoachCareerStats: React.FC<Props> = ({ careerStats }) => {
  const totalStats = sumObjectsByKeys([careerStats.elimination, careerStats.h2h]);
  const activeLeaguesColumns: any = [
    {
      title: "",
      dataIndex: "title",
      width: 250,
    },
    {
      title: "",
      dataIndex: "value",
      width: 250,
    },
  ];
  const activeLeaguesData = [
    {
      key: "1",
      title: <span><strong>Total Record</strong></span>,
      value: <strong>{totalStats.wins}-{totalStats.loses}-0 ({(totalStats.wins / (totalStats.wins + totalStats.loses) * 100).toFixed(2)}%)</strong>
    },
    {
      key: "2",
      title: "ELIMINATION",
      value: `${careerStats.elimination.wins}-${careerStats.elimination.loses}-0 (${(careerStats.elimination.wins / (careerStats.elimination.wins + careerStats.elimination.loses) * 100).toFixed(2)}%)`
    },
    {
      key: "3",
      title: "HEAD-TO-HEAD",
      value: `${careerStats.h2h.wins}-${careerStats.h2h.loses}-0 (${(careerStats.h2h.wins / (careerStats.h2h.wins + careerStats.h2h.loses) * 100).toFixed(2)}%)`
    },
    {
      key: "4",
      title: "SEASON",
      value: "0-0-0 (0%)"
    },
    {
      key: "5",
      title: <strong>Total Championships</strong>,
      value: ""
    },
    {
      key: "6",
      title: "Total Eliminations",
      value: careerStats.elimination.tourneysPlayed,
    },
    {
      key: "7",
      title: "Total Championships",
      value: "0",
    }
  ];
  
  return (
    <div className="standingmain" style={{ marginBottom: 0 }}>
      <Table
        prefixCls="career-stats-table ant-table"
        columns={alignAllColumnsToCenter(activeLeaguesColumns)}
        pagination={false}
        dataSource={activeLeaguesData}
        showHeader={false}
        size="small"
      />
    </div>
  );
};
