import { Tab, Nav } from 'react-bootstrap';
import { copyLinkToClipboard } from 'helper/helper';
import { ShareAltOutlined } from '@ant-design/icons';
import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { Standings } from './Tabs/Standings/Standings';
import { Overview } from './Tabs/Overview/Overview';
import { Schedule } from './Tabs/Schedule/Schedule';
import { Scores } from './Tabs/Scores/Scores';
import { useQueryTabs } from 'helper/useQueryTabs';

export enum SeasonTabs {
  OVERVIEW = 'OVERVIEW',
  SCORES = 'SCORES',
  SCHEDULE = 'SCHEDULE',
  STANDINGS = 'STANDINGS',
}

export const SeasonContent: React.FC = () => {
  const { handleTabChange, tabKey } = useQueryTabs<SeasonTabs>(Object.values(SeasonTabs));

  return (
    <div className="standingTabbarMain mb-4">
      <Tab.Container
        id="left-tabs-example"
        activeKey={tabKey}
        defaultActiveKey={SeasonTabs.OVERVIEW}
        mountOnEnter
        unmountOnExit
        onSelect={(key) => handleTabChange((key as SeasonTabs) || SeasonTabs.OVERVIEW)}
      >
        <div className="standingTabbar">
          <Card>
            <CardBody>
              <div className="nav-menu-container">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey={SeasonTabs.OVERVIEW}>{SeasonTabs.OVERVIEW}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={SeasonTabs.SCORES}>{SeasonTabs.SCORES}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={SeasonTabs.SCHEDULE}>{SeasonTabs.SCHEDULE}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={SeasonTabs.STANDINGS}>{SeasonTabs.STANDINGS}</Nav.Link>
                  </Nav.Item>
                </Nav>
                <button className="nav-menu-share-button" onClick={() => copyLinkToClipboard()}>
                  <ShareAltOutlined /> SHARE
                </button>
              </div>
            </CardBody>
          </Card>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={SeasonTabs.OVERVIEW}>
            <Overview handleRedirectToStandings={() => handleTabChange(SeasonTabs.STANDINGS)} />
          </Tab.Pane>
          <Tab.Pane eventKey={SeasonTabs.SCORES}>
            <Scores />
          </Tab.Pane>
          <Tab.Pane eventKey={SeasonTabs.SCHEDULE}>
            <Schedule />
          </Tab.Pane>
          <Tab.Pane eventKey={SeasonTabs.STANDINGS}>
            <Standings />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
