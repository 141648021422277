import "./LeagueHeadStyle.scss";
import LeagueHeadTabMain from "./LeagueHeadTabMain";
import { Container } from "react-bootstrap";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const LeagueHeadToHeadMain: React.FC = () => {
  const gameEngineSatateLoader = useSelector(
    (state: RootState) => state.gameEngine.loader
  );
  return (
    <>
      {!!gameEngineSatateLoader && <LoadingScreen />}
      <Container>
        <LeagueHeadTabMain />
      </Container>
    </>
  );
};

export default LeagueHeadToHeadMain;
