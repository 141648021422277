import { useDispatch } from "react-redux";
import { UpdateGroupMembership, JoinGroup, LeaveGroup, InviteGroup, GetGroupInvitations } from "redux/actions/Groups";
import { ActionsCallbacks } from "types";
import { GroupMemershipStatus } from "types/groups";

export const useHandleGroupParticipation = () => {
  const dispatch = useDispatch();

  const handleJoin = (groupId: number) => {
    dispatch(JoinGroup(groupId));
  };

  const handleInvite = (groupId: number, userId: number) => {
    dispatch(InviteGroup(groupId, userId));
  };

  const handleAcceptJoinGroup = (groupId: number, userId: number, cbs?: ActionsCallbacks) => {
    dispatch(UpdateGroupMembership(groupId, userId, GroupMemershipStatus.APPROVED, cbs));
  };

  const handleRejectJoinGroup = (groupId: number, userId: number, cbs?: ActionsCallbacks) => {
    dispatch(UpdateGroupMembership(groupId, userId, GroupMemershipStatus.REJECTED, cbs));
  };

  const handleAcceptInviteGroup = async (groupId: number, userId: number, cbs?: ActionsCallbacks) => {
    dispatch(UpdateGroupMembership(
      groupId,
      userId,
      GroupMemershipStatus.APPROVED,
      {
        onSuccess: () => dispatch(GetGroupInvitations()),
        ...cbs,
      })
    );
  };

  const handleRejectInviteGroup = async (groupId: number, userId: number, cbs?: ActionsCallbacks) => {
    dispatch(UpdateGroupMembership(
      groupId,
      userId,
      GroupMemershipStatus.REJECTED,
      {
        onSuccess: () => dispatch(GetGroupInvitations()),
        ...cbs,
      })
    );
  };


  const handleLeave = (groupId: number) => {
    dispatch(LeaveGroup(groupId));
  }

  return {
    handleJoin,
    handleLeave,
    handleInvite,
    handleAcceptJoinGroup,
    handleRejectJoinGroup,
    handleAcceptInviteGroup,
    handleRejectInviteGroup,
  };
};
