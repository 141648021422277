import { formationConfig, getFormationConfig, positionColumns } from 'components/DefensiveDepthChart/depthChartConfig';
import DepthChartPosition from 'components/FranchiseOverview/TabDetail/DepthChartTab/DepthChartPosition';
import { Position } from 'types';

type Props = {
  preparedPlayers: any[];
};

export const FullPlayerStatContent: React.FC<Props> = ({ preparedPlayers }) => (
  <>
    {Object.keys(positionColumns)
      .filter((position) => position in formationConfig['ANY'])
      .map((position) => {
        const key = position as Position;
        return (
          <DepthChartPosition
            key={key}
            position={key}
            title={positionColumns[key].title.toUpperCase()}
            columns={positionColumns[key].columns}
            slots={getFormationConfig('ANY')[key]}
            players={preparedPlayers?.filter((p: any) => p?.ratings?.pos === key) || []}
          />
        );
      })}
  </>
);
