/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./HowitWorksStyle.scss";
import { Link } from "react-router-dom";
import close from "../../assets/images/common/close.svg";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import { useState } from "react";
import { Modal } from "antd";
import SelectLoginModal from "../Common/SelectLoginModal/SelectLoginModal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";

const HowitWorksMain: React.FC = () => {
  const loadingState = useSelector((state: RootState) => state.app.loader);

  const [loading, setLoading] = useState(false);
  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [afterChangedActiveSlide, setAfterChangedActiveSlide] = useState(0);
  const [currentImgAlt, setCurrentImgAlt] = useState("list1");
  const video = "https://www.youtube.com/watch?v=xyQHMjYJkxg";
  //const video = "../../video/gm.mp4";

  const settings = {
    focusOnSelect: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: true,
        },
      },
    ],
    beforeChange: (current: any, next: any) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: (current: any) => {
      setAfterChangedActiveSlide(current);
      let currentImg: any = document.getElementsByClassName(
        "slick-slide slick-active slick-current"
      )?.[0]?.children?.[0]?.children?.[0]?.children?.[0];

      setCurrentImgAlt(currentImg?.alt);
    },
  };

  // Metamask Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {(!!loadingState || loading) && <LoadingScreen />}
      <div className="signUpBackground">
        {/* <video autoPlay controls data-autoplay="true" data-loop="true" >
          <source src={video} type="video/mp4"  ></source>
        </video> */}
        {/* <video  loop autoPlay>
            <source src= { covervid } type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
            <source src={ covervidtwo } type="video/ogg" />Your browser does not support the video tag. I suggest you upgrade your browser.
        </video> */}
        <div className="signUpMain howworksmain">
          <Link to="/">
            <img src={close} alt="close" className="close-icon" />
          </Link>
          <h5>HOW IT WORKS</h5>
          {/* <div className="howworksslider">
            <div className="computer">
              <img src={computer} alt="computer" />
            </div>
            <Slider {...settings}>
              <div>
                <img src={list1} alt="list1" />
              </div>
              <div>
                <img src={list2} alt="list2" />
              </div>
              <div>
                <img src={list3} alt="list3" />
              </div>
              <div>
                <img src={list4} alt="list4" />
              </div>
              <div>
                <img src={list5} alt="list5" />
              </div>
              <div>
                <img src={list6} alt="list6" />
              </div>
              <div>
                <img src={list7} alt="list7" />
              </div>
              <div>
                <img src={list8} alt="list8" />
              </div>
              <div>
                <img src={list9} alt="list9" />
              </div>
            </Slider>
          </div> */}
          <div className="howworksslider computer_video">
            {/* <div className="computer ">
              <img src={computer} alt="computer" />
              <video autoPlay loop playsInline width="100%" >
                <source src={video} type="video/mp4"  ></source>
              </video>
            </div> */}
            <div className="computer ">
              {/* <video autoPlay loop playsInline width="100%" >
                <source src={video} type="video"  ></source>
              </video> */}
              <iframe
                src="https://www.youtube.com/embed/xyQHMjYJkxg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                width="100%"
                height="270px"
              />
            </div>
          </div>
          <div className="howworkscontent">
            <h6>GET STARTED WITH GM DYNASTY</h6>
            <ul>
              {currentImgAlt == "list1" ? (
                <li className="bold">
                  1. Acquire a franchise via GM Dynasty or OpenSea
                </li>
              ) : (
                <li>1. Acquire a franchise via GM Dynasty or OpenSea</li>
              )}
              {currentImgAlt == "list2" ? (
                <li className="bold">
                  2. Sit back as your franchise is allocated 53 player, 3
                  coaches and 3 GMs
                </li>
              ) : (
                <li>
                  2. Sit back as your franchise is allocated 53 player, 3
                  coaches and 3 GMs
                </li>
              )}
              {currentImgAlt == "list3" ? (
                <li className="bold">
                  3. Set depth charts, offensive formations and defensive
                  schemes
                </li>
              ) : (
                <li>
                  3. Set depth charts, offensive formations and defensive
                  schemes
                </li>
              )}
              {currentImgAlt == "list4" ? (
                <li className="bold">
                  4. Enter leagues and play against other teams. Multiple league
                  types available
                </li>
              ) : (
                <li>
                  4. Enter leagues and play against other teams. Multiple league
                  types available
                </li>
              )}
              {currentImgAlt == "list5" ? (
                <li className="bold">
                  5. Game day! Watch the action unfold as your strategies are
                  put to test.
                </li>
              ) : (
                <li>
                  {" "}
                  5. Game day! Watch the action unfold as your strategies are
                  put to test.
                </li>
              )}
              {currentImgAlt == "list6" ? (
                <li className="bold">
                  6. Win your league and earn prizes and other incentives.
                </li>
              ) : (
                <li>
                  6. Win your league and earn prizes and other incentives.
                </li>
              )}
              {currentImgAlt == "list7" ? (
                <li className="bold">
                  7. Develop and improve your roster via the draft or player
                  acquisition.
                </li>
              ) : (
                <li>
                  7. Develop and improve your roster via the draft or player
                  acquisition.
                </li>
              )}
              {currentImgAlt == "list8" ? (
                <li className="bold">
                  8. Track and manage your players progression.
                </li>
              ) : (
                <li>8. Track and manage your players progression.</li>
              )}
              {currentImgAlt == "list9" ? (
                <li className="bold">
                  9. Mint, own and sell your player assets (NFTs).
                </li>
              ) : (
                <li>9. Mint, own and sell your player assets (NFTs).</li>
              )}
              {currentImgAlt == "list10" ? (
                <li className="bold">
                  10. Mint, own and sell your player assets (NFTs).
                </li>
              ) : (
                <li>
                  10. In-depth Analysis:{" "}
                  <a href="https://gmdbe.azurewebsites.net/files/GMDynasty_WhitePaper.pdf">
                    GMDynasty Whitepaper
                  </a>
                </li>
              )}
            </ul>
            <BlueButton text="ACQUIRE A FRANCHISE" onClick={showModal} />
          </div>
        </div>
      </div>

      {/* DRAFT START */}
      <Modal
        title=""
        width="auto"
        className="metamask_connect"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[<BlueButton onClick={handleCancel} text="START DRAFT" />]}
        centered
      >
        <SelectLoginModal setLoading={setLoading} />
      </Modal>
    </>
  );
};

export default HowitWorksMain;
