import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSelectedAvatar } from "redux/actions/TeamAction";
import { RootState } from "redux/store";
import { GameSummaryTeam, UserTeamsList } from "types";

export function useTeamsLogos(teams: Array<GameSummaryTeam> | undefined) {
  const dispatch = useDispatch();
  const [helmetNumbers, setHelmetNumbers] = useState<any>();
  const [helmetLogos, setHelmetLogos] = useState<any>();

  const avatarsList: UserTeamsList | null | undefined = useSelector(
    (state: RootState) => state?.team?.fetchedAllAvatar
  );

  const ids = [teams?.[0]?.utid, teams?.[1]?.utid].filter(Boolean) as number[];

  useEffect(() => {
    if (
      teams &&
      teams.length &&
      ids.length &&
      !avatarsList?.every((avatar) => ids.some((id) => avatar.engId === id))
    ) {
      dispatch(fetchAllSelectedAvatar(ids));
    }
  }, [teams, avatarsList, ids]);

  useEffect(() => {
    if (avatarsList && avatarsList?.length && teams) {
      const left = avatarsList?.find(
        (data: any) => +data.engId === +teams[0].utid
      );
      const right = avatarsList?.find(
        (data: any) => +data.engId === +teams[1].utid
      );

      if (left && right) {
        setHelmetLogos({
          leftLogoNumber: left.logo,
          rightLogoNumber: right.logo,
        });
        setHelmetNumbers({
          leftHelmetNumber: left.helmet,
          rightHelmetNumber: right.helmet,
        });
      }
    }
  }, [avatarsList, teams]);

  const avData = useSelector((state: RootState) => state.team.avatar);

  const helmets = useMemo(() => {
    if (
      avData &&
      avData.logo &&
      avData.helmet &&
      helmetNumbers &&
      helmetLogos &&
      helmetNumbers.leftHelmetNumber &&
      helmetLogos.leftLogoNumber &&
      helmetNumbers.rightHelmetNumber &&
      helmetLogos.rightLogoNumber
    ) {
      const leftHelmetUrl = avData.helmet.find(
        (h: { id: number }) => h.id === helmetNumbers.leftHelmetNumber
      )?.url;
      const rightHelmetUrl = avData.helmet.find(
        (h: { id: number }) => h.id === helmetNumbers.rightHelmetNumber
      )?.url;

      const leftHelmetLogo = avData.logo.find(
        (h: { id: number }) => h.id === helmetLogos.leftLogoNumber
      )?.url;
      const rightHelmetLogo = avData.logo.find(
        (h: { id: number }) => h.id === helmetLogos.rightLogoNumber
      )?.url;

      const leftHelmet =
        leftHelmetUrl && require(`../../../assets${leftHelmetUrl}`);
      const rightHelmet =
        rightHelmetUrl && require(`../../../assets${rightHelmetUrl}`);

      return { leftHelmet, rightHelmet, leftHelmetLogo, rightHelmetLogo };
    }

    return {
      leftHelmet: null,
      rightHelmet: null,
      leftHelmetLogo: null,
      rightHelmetLogo: null,
    };
  }, [helmetNumbers, helmetLogos, avData]);

  return { helmets };
}
