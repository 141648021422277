import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsArticle } from "redux/actions/News/actions/getNewsArticle";
import { RootState } from "redux/store";
import { NewsRecord } from "types/news";

export const useNewsArticle = (id?: number) => {
  const dispatch = useDispatch();
  const newsArticle: NewsRecord | null = useSelector(
    (state: RootState) => state.app.newsArticle
  );

  useEffect(() => {
    if (id) {
      dispatch(getNewsArticle(id));
    }
  }, [id]);

  return newsArticle;
};
