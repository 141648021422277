import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import Title from "../Title/Title";
import LeagueScoreList from "./LeagueScoreList";
import "./LeagueScoreStyle.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getGameSeasonScoresByGid } from "../../../redux/actions/GameEngineAction";
import history from "../../../routes/history";
import { useEffect, useMemo } from "react";
import { mapSeasonScores } from "helper/helper";

type Props = {
  gameId?: number;
};

const LeagueScoreTabByGid: React.FC<Props> = ({ gameId }) => {
  const dispatch = useDispatch();
  const data: { gid: number } = history.location.state as { gid: number };
  const GID = gameId || data?.gid;

  const gameSeasonScoresByGid = useSelector(
    (state: RootState) => state.gameEngine.gameSeasonScoresByGid
  );

  const data1 = useMemo(() => {
    return gameSeasonScoresByGid?.length
      ? mapSeasonScores(gameSeasonScoresByGid)
      : [];
  }, [gameSeasonScoresByGid]);

  

  useEffect(() => {
    if (GID) {
      dispatch(getGameSeasonScoresByGid(GID));
    }
  }, [GID]);

  return (
    <Card>
      <CardBody>
        {!gameId && <Title text="GAME SCORE" />}
        {data1.length > 0 && <LeagueScoreList data1={data1[0]} />}
      </CardBody>
    </Card>
  );
};

export default LeagueScoreTabByGid;
