import { Link } from "react-router-dom";
import close from "../../assets/images/common/close.svg";
import "./LegendFranchiseStyle.scss";
import { Row, Col, Form } from "react-bootstrap";
import city from "../../assets/images/legend/city.png";
import { legendEmail } from "../../redux/actions/TeamAction";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";

const LegendFranchiseMain: React.FC = () => {
  const [email, SetEmail] = useState("");
  const [error, SetError] = useState("");
  const [successRes, SetSuccessRes] = useState("");
  const legendsRes = useSelector(
    (state: RootState) => state.team.legendSuccess
  );
  const dispatch = useDispatch();
  const onSubmit = () => {
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      SetError("Enter Valid Email Address.");
    } else {
      dispatch(legendEmail(email));
      SetSuccessRes("Submit Successfully.");
    }
  };
  useEffect(() => {
    if (legendsRes) {
      SetEmail("");
      SetSuccessRes("");
    } else {
      //SetSuccessRes("");
    }
  }, [legendsRes]);
  const emailOnChange = (e: any) => {
    SetError("");
    SetEmail(e.target.value);
  };
  return (
    <>
      <div className="signUpBackground">
        <div className="signUpMain howworksmain legendmain">
          <Link to="/">
            <img src={close} alt="close" className="close-icon" />
          </Link>
          <h5>LIMITED TIME</h5>
          <h6>LEGEND FRANCHISES</h6>
          <Row>
            <Col md={4}>
              <div className="cityimage">
                <img src={city} alt="city" />
              </div>
            </Col>
            <Col md={8}>
              <div className="legendcontent">
                <p>
                  Join the ranks of George Halas, Tim Mara and Curly Lambeau as
                  founding football franchise owners. Build your team, build
                  your playbook and start building your legend.{" "}
                </p>
                <ul>
                  <li>Limited edition NFT, only 40 available.</li>
                  <li>
                    One-of-a-kind, with each franchise having a unique hometown.
                    First come, first served on hometown choice.
                  </li>
                  <li>
                    Guaranteed participation in the first trophy challenge
                    league – Dynasty Bowl 1.
                  </li>
                  <li>
                    Inclusion in the GM Dynasty Hall of Fame. You will always be
                    recognized even if you sell the franchise.
                  </li>
                  <li>
                    Color customization of franchise NFT – stand out from the
                    crowd
                  </li>
                  <li>
                    Receive a Leather Bucket Head NFT. Visit
                    leatherbucketheads.com.
                  </li>
                </ul>
                <p>
                  If interested, simply enter your email below and we will
                  contact you.{" "}
                </p>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={emailOnChange}
                    placeholder="Enter Email Address"
                  />
                  {error && <p className="error">{error}</p>}
                  {successRes && <p className="success">{successRes}</p>}
                </Form.Group>
                <button className="submit-btn" onClick={onSubmit}>
                  SUBMIT
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default LegendFranchiseMain;
