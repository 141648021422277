export enum TeamStatPriod {
  ALL_TIME = 'All_time',
  LAST_GAME = 'Last_game',
  LAST_10_GAMES = 'Last_10_games',
  LAST_ELIMINATION_LEAGUE = 'Last_Elimination_League',
  LAST_SEASON_LEAGUE = 'Last_Season_League',
}

export const TEAM_STAT_PERIOD_OPTIONS = [
  { value: TeamStatPriod.ALL_TIME, option: 'All Time' },
  { value: TeamStatPriod.LAST_GAME, option: 'Last game' },
  { value: TeamStatPriod.LAST_10_GAMES, option: 'Last 10 games' },
  { value: TeamStatPriod.LAST_ELIMINATION_LEAGUE, option: 'Last Elimination League' },
  { value: TeamStatPriod.LAST_SEASON_LEAGUE, option: 'Last Season League' },
]