import { ViewMoreStringTypes } from "./LeaderboardContent";
import { LeaderboardTeamLeadersList } from "./LeaderboardTeamLeadersList";
import { useTeamLeadersWinrate } from "./hooks";

export const LeaderboardTeamLeadersWinRate = () => {
  const { teamLeadersWinrate } = useTeamLeadersWinrate();

  if (!teamLeadersWinrate) {
    return <div>Sorry, there are no data</div>;
  }

  return (
    <LeaderboardTeamLeadersList
      leadersList={teamLeadersWinrate}
      viewMoreType={ViewMoreStringTypes["TEAM-LEADERS-WINRATE"]}
    />
  );
};
