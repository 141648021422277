import "./TitleStyle.scss";

export interface Props{
    text: string;
    className?: string;
}

const Title: React.FC<Props> = ({ text, className }) => {
    const titleClass = className ? `title ${className}`: "title";
    return <h5 className={titleClass} >{text}</h5>
}

export default Title;