import Dropdown from 'components/Common/Form/Dropdown/Dropdown';
import { Row, Col } from 'react-bootstrap';
import { TEAM_STAT_PERIOD_OPTIONS, TeamStatPriod } from './constatns';
import { useEffect, useMemo, useState } from 'react';
import history from '../../../../routes/history';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPlayersStockReport } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';
import PlayersStockReport from 'components/Common/PlayersStockReport/PlayersStockReport';
import { PlayersStockReportPlayer } from 'types';

const OverviewLeaguePlayersStockReport: React.FC = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState<TeamStatPriod>(TeamStatPriod.ALL_TIME);

  const selectFeeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriod(e.target.value as TeamStatPriod);
  };

  const franchiseData: { id: number } = history.location.state as { id: number };
  let { franchiseId }: any = useParams();

  if (!franchiseId) {
    franchiseId = franchiseData?.id;
  }

  const userTeamListData = useSelector((state: RootState) => state.team.userTeamsList);
  const playersStockReport = useSelector((state: RootState) => state.gameEngine.playersStockReport);
  const isLoading = useSelector((state: RootState) => state.gameEngine.loaders.playersStockReport);

  const franchise = useMemo(() => {
    return userTeamListData?.find((info: any) => info?.id == franchiseId || info?.engId == franchiseId);
  }, [userTeamListData, franchiseId]);

  const report = useMemo(() => {
    if (isLoading) {
      const loadingPlayers: PlayersStockReportPlayer[] = new Array(5).fill(0).map((_item, index) => ({
        pid: index,
        firstName: 'Loading...',
        lastName: '',
        diff: 0,
      }));
      return {
        top5: loadingPlayers,
        bottom5: loadingPlayers,
      };
    }
    
    if (playersStockReport) {
      return playersStockReport;
    }

    const emptyPlayers: PlayersStockReportPlayer[] = new Array(5).fill(0).map((_item, index) => ({
      pid: index,
      firstName: 'N/A',
      lastName: '',
      diff: 0,
    }));

    return {
      top5: emptyPlayers,
      bottom5: emptyPlayers,
    };
  }, [isLoading, playersStockReport]);

  useEffect(() => {
    if (franchise) {
      dispatch(getPlayersStockReport(franchise.engId, period));
    }
  }, [franchise, period]);

  return (
    <div className="mb-5">
      <h5 className="title">PLAYERS STOCK REPORT</h5>
      <Col xs={12} md={4}>
        <Dropdown dropDownOptions={TEAM_STAT_PERIOD_OPTIONS} value={period} onChange={selectFeeChanged} />
      </Col>

        <Row >
          <Col xl={12}>
            <PlayersStockReport gainers={report.top5} loosers={report.bottom5} />
          </Col>
        </Row>
    </div>
  );
};

export default OverviewLeaguePlayersStockReport;
