import types from '../actionTypes';
import { API_URL } from 'config';
import { get } from 'redux/services/Api';
import { SeasonPlayerLeader } from 'types';

export const getSeasonLeaders = (gid: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_LEADERS_REQUEST,
        payload: {},
      });
      const seasonLeaders = await get<SeasonPlayerLeader[]>(
        `${API_URL}/engine/leagues/seasons/season/${gid}/leaders`,
      );
      dispatch({
        type: types.GET_SEASON_LEADERS_SUCCESS,
        payload: { seasonLeaders },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_LEADERS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
}
