import './NewsStyle.scss';
import { Container } from 'react-bootstrap';
import NewsHeader from './NewsHeader';
import Card from '../Common/Card/Card';
import CardBody from '../Common/Card/CardBody';
import { useServerSidePagination } from 'helper/useServerSidePagination';
import { Pagination } from 'components/Profile/components/common';
import { useNews } from 'components/NewsTemplate/hooks/useNews';
import { HomeNewsItem } from 'components/Home/HomeNewsItem';

const PAGE_SIZE = 12;

const NewsMain: React.FC = () => {
  const { page, handleNextPage, handlePrevPage } = useServerSidePagination();
  const news = useNews(page, PAGE_SIZE);

  if (!news) return null;

  return (
    <Container>
      <NewsHeader />
      <div className="newsmain">
        <Card>
          <CardBody>
            <div className="accordian_main">
              <div className="section">
                <div className="section__header">
                  <h5>NEWS AND HEADLINES</h5>
                  <div className="right">
                    <Pagination page={page} setNextPage={handleNextPage} setPreviousPage={handlePrevPage} hasNextPage={!!news?.hasNextPage} hasPreviousPage={page > 1} />
                  </div>
                </div>
              </div>
              <div className="news-wrapper">
                <div className="news-card-wrapper">
                  {news.data.map((d, i: number) => {
                    const mmddyyyy = new Date(d.date).toLocaleDateString();
                    return <HomeNewsItem key={i} id={d.id} image={d.image} title={d.title} subtitle={`${mmddyyyy} - ${d.subtitle}`} />;
                  })}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default NewsMain;
