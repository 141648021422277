import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';
export interface Props {
  data?: any;
  team?: any;
}

const Team1Rushing = ({ data , team }: Props) =>{
    const scoreColumns:any = [
        {
          title: "",
          dataIndex: "title",
          fixed: "left",
          width: 150,
        },
        {
          title: "CAR",
          dataIndex: "car",
        },
        {
          title: "YDS",
          dataIndex: "yds",
        },
        {
          title: "AVG",
          dataIndex: "avg",
        },
        {
          title: "TD",
          dataIndex: "td",
        },
        {
          title: "LNG",
          dataIndex: "lng",
        },
        {
          title: "FUM",
          dataIndex: "fum",
        },
    ];
    let tablefooter:any={
      key: 800,
      title: "TEAM",
      car: team.stat.rus || team.stat.rus === 0 ? team.stat.rus :"---",
      yds: team.stat.rusYds || team.stat.rusYds === 0 ? team.stat.rusYds :"---",
      avg: (team.stat.rusYds && team.stat.rus)? team.stat.rusYds/ team.stat.rus:"---",
      tavg: 0,
      td:team.stat.rusTD || team.stat.rusTD === 0 ? team.stat.rusTD :"---",
      lng: team.stat.rusLng || team.stat.rusLng === 0? team.stat.rusLng :"---",
      fum: team.stat.fmb || team.stat.fmb === 0? team.stat.fmb :"---",
    };
    const scoreData = data?.map((d: any,index:any) => {
      const info = {
        key: index,
        title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)} >{d.name ? d.name : "---"}</span></>,
        car: d.stat.rus || d.stat.rus === 0 ? d.stat.rus :"---",
        yds: d.stat.rusYds || d.stat.rusYds === 0 ? d.stat.rusYds :"---",
        avg: (d.stat.rusYds && d.stat.rus)? d.stat.rusYds/ d.stat.rus:"---",
        td: d.stat.rusTD || d.stat.rusTD === 0 ? d.stat.rusTD :"---",
        lng: d.stat.rusLng || d.stat.rusLng === 0? d.stat.rusLng :"---",
        fum: d.stat.fmb || d.stat.fmb === 0? d.stat.fmb :"---",
    }
    tablefooter.tavg += (info.avg==="---"?0:1);
    info.avg = (info.avg==="---"?"---":Number(info.avg).toFixed(2))
    return info;
    })
    if(Array.isArray(scoreData) && scoreData.length){
      tablefooter.avg=(tablefooter.avg/tablefooter.tavg).toFixed(2)
      scoreData.push(tablefooter)
    }

    return(
        <>
            <div className="scorecommontable standingmain mt-3" >
                <Title text="RUSHING" />
                <Table
                    columns={scoreColumns}
                    pagination={false}
                    dataSource={scoreData}
                    size="small"
                    scroll={{ x: 500 }}
                />
            </div>
        </>
    );
}

export default Team1Rushing;