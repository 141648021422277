import { get } from "redux/services/Api";
import { TRIAL_GAME_DATA_KEY, TrialGameData } from "../types";

export const checkIfGameFinished = async (gid: number) => {
  try {
    const response = await get(
      `/engine/games/h2h/${gid}/checkIfTrialGameFinished`,
    ) as { finished: boolean };

    return response.finished;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export const saveTrialGameData = (gid: number) => {
  const record: TrialGameData = { gid, timestamp: new Date().toISOString() };
  localStorage.setItem(TRIAL_GAME_DATA_KEY, JSON.stringify(record));
}

export const getTrialGameData = () => {
  const record = localStorage.getItem(TRIAL_GAME_DATA_KEY);
  if (!record) {
    return null;
  }
  return JSON.parse(record) as TrialGameData;
}

export const deleteTrialGameData = () => {
  localStorage.removeItem(TRIAL_GAME_DATA_KEY);
}