import Title from "components/Common/Title/Title";
import "./CreateElimination.scss";
import { Row, Col } from "react-bootstrap";
import Dropdown from "components/Common/Form/Dropdown/Dropdown";
import { dropdownKeys } from "./CreateEliminationDropdownKeys";
import { useEffect, useState } from "react";
import Input from "components/Common/Form/Input/Input";
import { InvitePlayers } from "./InvitePlayers";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { useHandleCreateForm } from "./hooks/useHandleCreateForm";
import { useAnimationNames } from "./hooks/useAnimationNames";
import { DownOutlined } from "@ant-design/icons";
import { useUserTrophies } from "helper/useUserTrophies";
import { DatePicker, Tooltip, notification } from "antd";
import history from "routes/history";
import { isTextContainsBadWord } from "helper/helper";
import { useGroupOptions } from "./hooks/useGroupOptions";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

type Props = {
  selectedGroupId?: number;
};

export const CreateLeague: React.FC<Props> = ({ selectedGroupId }) => {
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);
  const animationNames = useAnimationNames();
  const groupOptions = useGroupOptions();
  const SelectedTeam = useSelector(
    (state: RootState) => state.team.leagueEnterFormData
  );

  const gameModes = [
    {
      id: 1,
      option: "Select game mode",
      value: null,
    },
    ...animationNames.map((name, index) => ({
      id: 1 + index,
      option: name.toUpperCase(),
      value: name,
    })),
  ];

  const {
    entryFee,
    gameMode,
    name,
    numberOfTeams,
    paceOfPlay,
    pregameTime,
    ratingRangeMax,
    ratingRangeMin,
    leagueType,
    trophyId,
    groupId,
    setName,
    updateEntryFee,
    updateNumberOfTeams,
    updatePaceOfPlay,
    updatePregameTime,
    updateGameMode,
    updateRatingRangeMin,
    updateRatingRangeMax,
    updateLeagueType,
    updateTrophyId,
    updateGroupId,
    handleCreateGame,
    wholeStartDate,
    setWholeStartDate,
  } = useHandleCreateForm(invitedUsers);
  const trophies = useUserTrophies(groupId || undefined);

  const trophiesOptions = [
    { id: 0, option: "NO TROPHY", value: null },
    ...trophies
      .filter(
        (trophy) => !isTextContainsBadWord(trophy.trophyName) || trophy.approval
      )
      .map((trophy) => ({
        id: trophy.id,
        option: trophy.trophyName,
        value: trophy.id,
      })),
  ];

  const [isClicked, setIsClicked] = useState(false);

  const handleCreateGameClick = () => {
    setIsClicked(true);
    handleCreateGame();
  };

  useEffect(() => {
    if (groupOptions.length > 1 && selectedGroupId) {
      updateGroupId(selectedGroupId);
    }
  }, [groupOptions.length, selectedGroupId, updateGroupId]);

  useEffect(() => {
    setIsClicked(false);
  }, [
    entryFee,
    gameMode,
    name,
    numberOfTeams,
    paceOfPlay,
    pregameTime,
    ratingRangeMax,
    ratingRangeMin,
    groupId,
    trophyId,
    SelectedTeam?.franchise?.id
  ]);

  const eliminationOptionsCN = leagueType === "E"
      ? "advanced-options elimination-options advanced-options-open "
      : "advanced-options elimination-options";

  return (
    <div className="elimination-customization-container">
      <div className="elimination-customization-header">
        <Title text="LEAGUE CREATION" />
        <p>
          Create and customize your own league tournament. It has never been
          easier to challenge your friends and rivals to a game of GM Dynasty.
        </p>
      </div>
      <Row>
        <Col className="mb-4" xs={12} md={6}>
          <Dropdown
            label="League type:"
            dropDownOptions={dropdownKeys.leagueType}
            value={leagueType}
            onChange={updateLeagueType}
            isOutlinedRed={isClicked && !leagueType}
          />
        </Col>
        <Col className="mb-4" xs={12} md={6}>
          <Dropdown
            label="Game mode:"
            dropDownOptions={gameModes}
            value={gameMode}
            onChange={updateGameMode}
            isOutlinedRed={isClicked && !gameMode}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4" xs={12} md={6}>
          <Dropdown
            label="Entry fee:"
            dropDownOptions={dropdownKeys.gameFee}
            value={entryFee}
            onChange={updateEntryFee}
            isOutlinedRed={
              isClicked && (entryFee === null || entryFee === undefined)
            }
          />
        </Col>
      </Row>
      <Row className={eliminationOptionsCN}>
        <Col className="mb-4" xs={12} md={6}>
          <Row>
            <Col>
              <label className="form-label" htmlFor="tournament-start-date-picker">
                Select Start Date And Time ({Intl.DateTimeFormat().resolvedOptions().timeZone})
              </label>
              <DatePicker
                value={wholeStartDate}
                onChange={(date) => {
                  if (date && date.toDate().getTime() > new Date().getTime() + 1000 * 60 * 5) {
                    setWholeStartDate(date);
                  } else {
                    notification.error({
                      message: 'Invalid date',
                      description: 'Please select a start time at least 5 minutes in the future.',
                      placement: 'bottomRight',
                    });
                  }
                }}
                showTime={{ use12Hours: true }}
                showNow={false}
                use12Hours={true}
                showSecond={false}
                minuteStep={5}
                format="MM-DD-YYYY hh:mm A"
                className="tournament-start-date-picker"
                id="tournament-start-date-picker"
              />
            </Col>
          </Row>
        </Col>
        <Col className="mb-4" xs={12} md={6}>
          <Dropdown
            label="Number of teams:"
            dropDownOptions={dropdownKeys.numberOfTeams}
            value={numberOfTeams}
            onChange={updateNumberOfTeams}
            isOutlinedRed={isClicked && !numberOfTeams}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4" xs={12} md={6}>
          <Input
            type="text"
            label="Contest Name:"
            placeholder="CREATE NAME"
            value={name}
            onChange={setName}
            classNameLabel="contest-name-label"
            classNameField={isClicked && !name ? "form-select-outlined" : ""}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4" xs={12} md={12}>
          <div
            className="elimination-customization-title advanced-options-toggler"
            onClick={() => setIsAdvancedOptionsOpen((prev) => !prev)}
          >
            <p>ADVANCED OPTIONS</p>
            <DownOutlined
              className={
                isAdvancedOptionsOpen
                  ? "result-card-game-detail-arrow result-card-game-detail-arrow-rotated"
                  : "result-card-game-detail-arrow"
              }
            />
          </div>
        </Col>
      </Row>
      <div
        className={
          isAdvancedOptionsOpen
            ? "advanced-options advanced-options-open"
            : "advanced-options"
        }
      >
        <Row>
          <Col className="mb-4" xs={12} md={6}>
            <Row>
              <Col>
                <Dropdown
                  label="Rating range from:"
                  dropDownOptions={dropdownKeys.getRatingValuesFrom(ratingRangeMax)}
                  value={ratingRangeMin}
                  onChange={updateRatingRangeMin}
                  isOutlinedRed={isClicked && !ratingRangeMin}
                />
              </Col>
              <Col>
                <Dropdown
                  label="Rating range to:"
                  dropDownOptions={dropdownKeys.getRatingValuesTo(ratingRangeMin)}
                  value={ratingRangeMax}
                  onChange={updateRatingRangeMax}
                  isOutlinedRed={isClicked && !ratingRangeMax}
                />
              </Col>
            </Row>
          </Col>
          <Col className="mb-4" xs={12} md={6}>
            <Dropdown
              label="Group Association:"
              dropDownOptions={groupOptions}
              value={groupId}
              onChange={updateGroupId}
            />
          </Col>
        </Row>
        <div className={eliminationOptionsCN}>
          <Row>
            <Col className="mb-4" xs={12} md={6}>
              <Dropdown
                label="Pace of play:"
                dropDownOptions={dropdownKeys.paseOfPlay}
                value={paceOfPlay}
                onChange={updatePaceOfPlay}
                isOutlinedRed={isClicked && !paceOfPlay}
              />
            </Col>
            <Col className="mb-4" xs={12} md={6}>
              <Dropdown
                label="Pre-game allotted time:"
                dropDownOptions={dropdownKeys.pregameTime}
                value={pregameTime}
                onChange={updatePregameTime}
                isOutlinedRed={isClicked && !pregameTime}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-4" xs={12} md={6}>
              <Dropdown
                label="Trophy Prize:"
                dropDownOptions={trophiesOptions}
                value={trophyId}
                onChange={updateTrophyId}
              />
              <Tooltip
                title="Player profile with trophies coming soon!"
                trigger={["hover"]}
                placement="bottom"
              >
                <p
                  onClick={() => history.push("/purchase-trophy")}
                  className="link-like"
                  style={{ zIndex: 99999}}
                >
                  Purchase a trophy
                </p>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>

      <Row>
        <Col className="mb-4" xs={12} md={12}>
          <h6 className="elimination-customization-title">
            INVITE YOUR COMPETITORS
          </h6>
          <InvitePlayers
            title=""
            users={invitedUsers}
            setUsers={setInvitedUsers}
          />
        </Col>
      </Row>
      <h6 className="mb-4">
        All invited competitors will receive an email with a link to this
        league.
      </h6>

      <div className="elimination-customization-container-footer">
        <BlueButton
          text="CREATE GAME!"
          onClick={handleCreateGameClick}
          disabled={isClicked}
        />
      </div>
    </div>
  );
};
