export enum FriendshipsStatus {
  REQUESTED = "requested",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export interface Friendships {
  user_id1: number;
  user_id2: number;
  status: FriendshipsStatus;
}

export interface Friend {
  id: number;
  userName: string;
  email: string;
  avatar: string;
  status: FriendshipsStatus;
}

export interface FriendWithNetworkStatus extends Friend {
  networkStatus: "online" | "offline";
}

export type FriendshipInvite = {
  users: { id: number; userName: string }[];
  status: FriendshipsStatus;
};

export type FriendsList = {
  friends: FriendWithNetworkStatus[];
  friendsRequests: FriendWithNetworkStatus[];
};

export interface UpdateNetworkStatus {
  friendId: number;
  status: "online" | "offline";
}

export type SenderAndRecepientFriends = {
  friendsOfSender: FriendsList;
  friendsOfRecepient: FriendsList;
  friendId: number;
};
