/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import "../OwnFranchise/TrendingStyle.scss";
import { BASE_URL } from "../../config";
import { PurchaseTeam } from "./PurchaseTeam";

const NoFranchisesContent = () => {
  let path = `${BASE_URL}/files/whitepaper.pdf`;
  return (
    <div className="mb-5 nofranchise">
      <Card>
        <CardBody>
          <div className="nofranchisesbutton">
            <h6 className="mb-4">CUSTOMIZE & CREATE YOUR TEAM</h6>
            <PurchaseTeam />
            <p>
              The first step in participating in GM Dynasty game play is to
              acquire a football franchise. All franchises are unique NFTs
              that can be owned and sold by their owner. It is also your entry
              point to start building your roster and joining competitive
              leagues. Now is your chance to act and transact like a real
              franchise owner and prove to everyone you are a dynasty builder.
              Need more information? Download our comprehensive guide to GM
              Dynasty.{" "}
              <a href={path} download>
                DOWNLOAD WHITEPAPER
              </a>
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default NoFranchisesContent;
