import {
  FacebookOutlined,
  MailOutlined,
  RedditOutlined,
  ShareAltOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { copyLinkToClipboard } from "helper/helper";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
export const ShareGamePlay: React.FC = () => {
  const link = window.location.href;
  let title = "";
  if (link.includes("gameplay")) {
    title = "I am playing a game on GM Dynasty!";
  } else if (link.includes("replay")) {
    title = "Check out a game replay on GM Dynasty!";
  }

  return (
    <div className="share-gameplay">
      <h4 className="share-gameplay-header">Share</h4>
      <div className="share-options-container">
        <TwitterShareButton
          url={link}
          title={title}
          className="share-gameplay__button"
          hashtags={["GMDynasty"]}
          via="GMDynasty2"
        >
          <TwitterOutlined />
        </TwitterShareButton>

        <FacebookShareButton
          url={link}
          title={title}
          className="share-gameplay__button"
          hashtag="#GMDynasty"
        >
          <FacebookOutlined />
        </FacebookShareButton>

        <WhatsappShareButton
          url={link}
          className="share-gameplay__button"
          title={title}
        >
          <WhatsAppOutlined />
        </WhatsappShareButton>

        <RedditShareButton
          url={link}
          className="share-gameplay__button"
          title={title}
        >
          <RedditOutlined />
        </RedditShareButton>

        <EmailShareButton
          url={link}
          className="share-gameplay__button"
          subject={title}
          body={title}
        >
          <MailOutlined />
        </EmailShareButton>

        <ShareAltOutlined
          className="share-gameplay__button"
          onClick={() => copyLinkToClipboard()}
        />
      </div>
    </div>
  );
};
