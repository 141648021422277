import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHelmets } from 'redux/actions/AppAction';
import { RootState } from 'redux/store';

export const useHelmets = (engIds: number[]) => {
  const dispatch = useDispatch();
  const helmets = useSelector((state: RootState) => state.app.helmets);
  const loadedHelmets = helmets?.map((helmet) => helmet.engId) || [];
  const doLoad = !!engIds.length && engIds.some((engId) => !loadedHelmets.includes(engId));
  const loadingRef = useRef(false);

  useEffect(() => {
    if (doLoad && engIds.length && !loadingRef.current) {
      loadingRef.current = true;
      dispatch(getHelmets(engIds));
    }
  }, [engIds, doLoad]);

  useEffect(() => {
      loadingRef.current = false;
  },[helmets])

  return helmets;
};
