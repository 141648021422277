import { DragEventHandler, useCallback } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { message } from "antd";
import { getMediaExtensionFromFileName, bytesToMb } from "./helper";
import { File as CustomFile } from "types/file";

export default function useDragAndDrop({
  maxSize,
  maxFiles,
  multiple,
  onImagesUpload,
  onDragEnter,
  onDragLeave,
  noClick,
}: {
  maxSize: number;
  multiple: boolean;
  maxFiles: number;
  onImagesUpload: (file: CustomFile) => void;
  onDragEnter?: DragEventHandler;
  onDragLeave?: DragEventHandler;
  noClick: boolean;
}) {
  const onError = (e: FileRejection[]) => {
    if (e[0]?.errors[0]?.code === "file-too-large") {
      const errorMessage = e[0]?.errors[0]?.message.split(`${maxSize} bytes`);
      errorMessage.push(`${bytesToMb(maxSize!).toFixed(1)} MB`);
      message.error(errorMessage.join(" "));
      return;
    }

    if (e[0]?.errors[0]?.code === "file-invalid-type") {
      message.warn(e[0]?.errors[0]?.message);
      return;
    }

    if (e[0]?.errors[0]?.code === "too-many-files") {
      message.warn(`You can upload a maximum of ${maxFiles} files`);
      return;
    }
    message.warn(e[0]?.errors[0]?.message);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files

    acceptedFiles.map((file: File) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target != null) {
          const target = (e.target as FileReader).result as string;
          const img = new Image();
          img.src = target;
          img.addEventListener("load", () => {
            const fileExtension = getMediaExtensionFromFileName(file.name);
            onImagesUpload({
              src: target,
              fileName: file.name,
              extension: fileExtension,
            });
          });
        }
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: { "image/*": [".jpeg", ".png", ".jpg", ".svg", ".gif"] },
    maxSize,
    maxFiles,
    onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected: onError,
    multiple,
    noClick,
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  };
}
