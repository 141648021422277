
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import { JoinGameNavigation } from "./JoinGameNavigation";


export const JoinGameScreen: React.FC = () => (
  <div className="pagelayout">
      <WithLoginNavbar />
      <JoinGameNavigation />
  </div>
)
