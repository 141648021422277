export const API_URL = process.env.REACT_APP_API_URL;
export const GAME_ENGINE_API_URL = process.env.REACT_APP_GAME_ENGINE_API_URL;
export const WALLET_ADDR = process.env.REACT_APP_WALLET_ADDR;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const MORALIS_API_KEY = process.env.REACT_APP_MORALIS_API_KEY;
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
export const CONTRACT_CHAIN = process.env.REACT_APP_CONTRACT_CHAIN;

export const DONT_MATCH_MM_TEAMS = process.env.REACT_APP_NO_MM_MATCH ?? "true";
export const GAMEPLAY_ENV : string | undefined = process.env.REACT_APP_LIVE_ENVIRONMENT ?? "LOCAL";

export const QUARTER_PAUSE: number = process.env.QUARTER_PAUSE ? +process.env.QUARTER_PAUSE : 2;