import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';

export interface Props {
  data?: any;
  team?: any;
}
const Team1Passing = ({ data , team }: Props) =>{
    const scoreColumns:any = [
        {
          title: "",
          dataIndex: "title",
          fixed: "left",
          width: 150,
        },
        {
          title: "C/ATT",
          dataIndex: "att",
        },
        {
          title: "YDS",
          dataIndex: "yds",
        },
        {
          title: "AVG",
          dataIndex: "avg",
        },
        {
          title: "TD",
          dataIndex: "td",
        },
        {
          title: "INT",
          dataIndex: "int",
        },
        {
          title: "SACKS",
          dataIndex: "sacks",
        },
    ];
    let tablefooter:any={
    key: 800,
    title: "TEAM",
    att: ((team.stat.pssCmp || team.stat.pssCmp === 0) && (team.stat.pss || team.stat.pss === 0)) ? (`${team.stat.pssCmp}/${team.stat.pss}`) :"---",
    yds: team.stat.pssYds || team.stat.pssYds === 0 ? team.stat.pssYds :"---",
    avg: (team.stat.pssYds && team.stat.pss)? team.stat.pssYds/ team.stat.pss:"---",
    tavg: 0,
    td:team.stat.pssTD || team.stat.pssTD === 0 ? team.stat.pssTD :"---",
    int: team.stat.pssInt || team.stat.pssInt === 0? team.stat.pssInt :"---",
    sacks:0
  };
    const scoreData = data?.map((d: any,index:any) => {
      const info = {
        key: index,
        title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)}>{d.name ? d.name : "---"}</span></>,
        att: ((d.stat.pssCmp || d.stat.pssCmp === 0) && (d.stat.pss || d.stat.pss === 0)) ? (`${d.stat.pssCmp}/${d.stat.pss}`) :"---",
        yds: d.stat.pssYds || d.stat.pssYds === 0 ? d.stat.pssYds :"---",
        avg: (d.stat.pssYds && d.stat.pss)? d.stat.pssYds/ d.stat.pss:"---",
        td: d.stat.pssTD || d.stat.pssTD === 0 ? d.stat.pssTD :"---",
        int: d.stat.pssInt || d.stat.pssInt === 0? d.stat.pssInt :"---",
        sacks: d.stat.pssSk || d.stat.pssSk === 0? d.stat.pssSk :"---",
    }
    tablefooter.tavg += (info.avg==="---"?0:1);
    info.avg = (info.avg==="---"?"---":Number(info.avg).toFixed(2))
    return info;
    })
    if(Array.isArray(scoreData) && scoreData.length){
      tablefooter.avg=(tablefooter.avg/tablefooter.tavg).toFixed(2)
      scoreData.push(tablefooter)
    }

    return (
      <div className="scorecommontable standingmain" >
        <Title text="PASSING" />
        <Table
          columns={scoreColumns}
          pagination={false}
          dataSource={scoreData}
          size="small"
          scroll={{ x: 500 }}
        />
      </div>
    );
}

export default Team1Passing;