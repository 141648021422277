export type PTCLStat = {
  percentage: number;
  field: string;
};

export type PTCL = PTCLStat[];

export function getPTCL(ptcl?: PTCL, key?: string): number {
  if (!ptcl || !key) return 0;
  const stat = ptcl.find((el) => el.field === key);
  if (!stat) return 0;
  return Math.round(stat.percentage);
}
