/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./DefensiveDepthChartStyle.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import DepthChartFormation from "../FranchiseOverview/TabDetail/DepthChartTab/DepthChartFormation";
import DepthChartPosition from "../FranchiseOverview/TabDetail/DepthChartTab/DepthChartPosition";
import {
  formationConfig,
  getFormationConfig,
  positionColumns,
} from "./depthChartConfig";
import {
  Formations,
  Position,
  TeamDepthChart,
  TeamDepthChartEntry,
  TeamDepthChartList,
  TeamGamePlan,
} from "../../types";

type Props = {
  teamDepthCharts: TeamDepthChartList | null;
  teamGamePlan: TeamGamePlan | null;
  updateDepthChart: (positions: TeamDepthChartList) => void;
};

const DefensiveDepthChartMain: React.FC<Props> = ({
  teamDepthCharts,
  teamGamePlan,
  updateDepthChart,
}) => {
  const [depthChartPositions, setDepthChartPositions] =
    useState<TeamDepthChart | null>(null);
  const changedPositions = useRef<TeamDepthChart>({});
  // set formaction Drop Down
  const [currentSelectedFormation, setCurrentSelectedFormation] = useState<{
    sid: number;
    name: Formations;
  }>({ name: "1 RB, 4 WR", sid: 1 });

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
    //dispatch(getTeamOverview(2, 26))
  }, []);

  // set Depth chart Data
  useEffect(() => {
    let selectedDepth = {...(teamDepthCharts || [])[currentSelectedFormation.sid]};
    if (JSON.stringify(selectedDepth) === '{}') {
      selectedDepth = {...(teamDepthCharts || []).at(1) };
    }
    setDepthChartPositions({
      ...selectedDepth,
      ...changedPositions.current,
    });
  }, [teamDepthCharts, currentSelectedFormation.sid]);

  // useEffect(() => {
  //   changedPositions.current = {};
  //   setDepthChartPositions({
  //     ...(teamDepthCharts || [])[currentSelectedFormation.sid],
  //   });
  // }, [currentSelectedFormation]);

  const slots = useMemo(
    () => getFormationConfig(currentSelectedFormation.name),
    [currentSelectedFormation]
  );

  // // set DepthChart Formation List
  // useEffect(() => {
  //   setDepthChartFormationList([])
  //   const createList: any = []
  //   teamGamePlan?.schemes?.offense?.sets?.map((data: any) => {
  //     createList.push({
  //       name: data.name,
  //       sid: data.sid,
  //       type: 'offense',
  //     })
  //   })

  //   teamGamePlan?.schemes?.defense?.sets?.map((data: any) => {
  //     createList.push({
  //       name: data.name,
  //       sid: data.sid,
  //       type: 'defense',
  //     })
  //   })

  //   setDepthChartFormationList(createList)
  // }, [teamGamePlan])

  // handle Click Formation Menu Item
  const handleClickFormationMenuItem = (formation: {
    sid: number;
    name: Formations;
  }) => {
     // sid < 0 used in section title
     if (formation.sid < 0) return;
     setCurrentSelectedFormation(formation);
     // todo apply scheme to the list of player. done
     // pass # of players required to each section. done
     // unify sections -> single component, same props, only different data done
     // implement autoselect
     // inside single component: implement manual selection
     // console.log('Current Select Formation Key ===> ', key)
     // console.log('Current Select Formation data ===> ', currentFormation)
  };

  const handleClickAutoSelect = useCallback(() => {
    // Object.keys(positions.current).forEach(())
    for (const key in (teamDepthCharts || [])[currentSelectedFormation.sid]) {
      changedPositions.current[key as Position] = [
        ...(teamDepthCharts || [])[currentSelectedFormation.sid]![
          key as Position
        ]!,
      ].sort((a, b) => b?.ratings?.ovr - a?.ratings?.ovr);
    }
    setDepthChartPositions({ ...changedPositions.current });
    // const formationConfig = getFormationConfig(currentSelectedFormation.name)
    // Object.keys(formationConfig).forEach(group => {
    //   teamDepthChart?[group].forEach(player => player.pos)
    // })
  }, [teamDepthCharts, currentSelectedFormation]);

  const applyChanges = (position: Position | "all") => {
    if (position === "all") {
      updateDepthChart(
        teamDepthCharts!.map((depth, sid) =>
          currentSelectedFormation.sid === sid
            ? { ...depth, ...changedPositions.current! }
            : depth
        )
      );
      changedPositions.current = {};
    } else {
      updateDepthChart(
        teamDepthCharts!.map((depth, sid) =>
          currentSelectedFormation.sid === sid
            ? { ...depth, [position]: changedPositions.current[position]! }
            : depth
        )
      );
      delete changedPositions.current![position];
    }
  };

  const resetChanges = (position: Position | "all") => {
    if (position === "all") {
      changedPositions.current = {}; //...teamDepthChart}
    } else {
      delete changedPositions.current![position]; //teamDepthChart![position]
    }
    setDepthChartPositions({
      ...(teamDepthCharts || [])[currentSelectedFormation.sid],
      ...changedPositions.current,
    });
  };

  const handlePositionsChange = useCallback(
    (position: Position, newPositions: TeamDepthChartEntry[]) => {
      // console.log('handlePositionsChange', newPositions)
      changedPositions.current![position] = newPositions;
      setDepthChartPositions({
        ...(teamDepthCharts || [])[currentSelectedFormation.sid],
        ...changedPositions.current,
      });
    },
    [teamDepthCharts, currentSelectedFormation]
  );

  const depthChartFormationList: Array<{ sid: number; name: string }> = [];
  if (teamGamePlan) {
    depthChartFormationList.push({ name: 'OFFENSIVE', sid: -1 })
    depthChartFormationList.push(...teamGamePlan.schemes.offense.sets);
    depthChartFormationList.push({ name: "DEFENSIVE", sid: -2 });
    depthChartFormationList.push(...teamGamePlan.schemes.defense.sets.map(set => {
      const setCopy: typeof set = JSON.parse(JSON.stringify(set));

      setCopy.sid += teamGamePlan.schemes.offense.sets.length;


      return setCopy;
    }))
  }
  // special team formation is not implemented yet
  depthChartFormationList.push({ name: "SPECIALS", sid: -10 });
  depthChartFormationList.push({ name: "SPECIAL TEAM", sid: 10 });

  // todo: save handler?
  return (
    <Card>
      <CardBody>
        <DepthChartFormation
          DepthChartFormationList={depthChartFormationList}
          handleClickFormationMenuItem={handleClickFormationMenuItem}
          handleClickAutoSelect={handleClickAutoSelect}
          currentSelectedFormation={currentSelectedFormation}
          handleApplyAll={
            Object.keys(changedPositions.current).length > 0 &&
            (() => applyChanges("all"))
          }
          handleResetAll={
            Object.keys(changedPositions.current).length > 0 &&
            (() => resetChanges("all"))
          }
        />
        {Boolean(formationConfig[currentSelectedFormation.name]) &&
          Object.keys(positionColumns)
            .filter(
              (position) =>
                position in formationConfig[currentSelectedFormation.name]
            )
            .map((position) => {
              const key = position as Position;
              return (
                <DepthChartPosition
                  key={key}
                  position={key}
                  title={positionColumns[key].title.toUpperCase()}
                  columns={positionColumns[key].columns}
                  slots={slots[key]}
                  players={
                    (depthChartPositions && depthChartPositions[key]) || []
                  }
                  setDepthChartPositions={handlePositionsChange}
                  handleReset={changedPositions.current[key] && resetChanges}
                  handleApply={changedPositions.current[key] && applyChanges}
                />
              );
            })}
      </CardBody>
    </Card>
  );
};

export default DefensiveDepthChartMain;
