import { Col } from "react-bootstrap";
import { Form, Select, Space } from "antd";
import { getDaysInMonth, months } from "../../helper/helper";
import DatePicker from "react-multi-date-picker";

type Props = {
  month: number | null | undefined;
  setMonth: (value: number | null) => void;
  year: number;
  setYear: (value: number) => void;
  date: number | null | undefined;
  setDate: (value: number | null) => void;
  datePicker: any;
  setDatePicker: (value: any) => void;
  country: string | undefined;
  setCountry: (value: string) => void;
};

const SignupScreenSecondStep = ({
  month,
  setMonth,
  year,
  date,
  setDate,
  datePicker,
  setYear,
  setDatePicker,
  country,
  setCountry,
}: Props) => {
  const days = Array.from(
    { length: getDaysInMonth(year, month || 1) },
    (_, index) => ({
      value: String(index + 1).padStart(2, "0"),
      label: String(index + 1).padStart(2, "0"),
    })
  );

  const handleMonthChange = (value: any) => {
    setMonth(value);
    setDate(null);
  };
  return (
    <>
      <Col xl={4} md={4}>
        <Form.Item label="Select your date of birth*">
          <Select
            placeholder="Select Month"
            value={months.find((m) => m.value === month?.toString())}
            onChange={handleMonthChange}
          >
            {months.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={4} md={4} className="select_date_year">
        <Form.Item label="Select Date">
          <Select placeholder="Select Day" value={date} onChange={setDate}>
            {days.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={4} md={4} className="select_date_year">
        <Form.Item label="Select Year">
          <Space direction="vertical">
            <DatePicker
              className="ant-select-selector"
              onlyYearPicker
              value={datePicker}
              maxDate={new Date(`01-01-${new Date().getFullYear()}`)}
              onChange={(e: any) => {
                setYear(e?.year);
                setDatePicker(e);
              }}
              placeholder="Select Year"
            />
          </Space>
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} style={{ minHeight: "98px" }}>
        {/* <Form.Item label="Select Country*">
          <Select
            placeholder="Select Country"
            value={country}
            onChange={setCountry}
          >
            <Select.Option value="India">India</Select.Option>
            <Select.Option value="USA">USA</Select.Option>
            <Select.Option value="UK">UK</Select.Option>
            <Select.Option value="Singapore">Singapore</Select.Option>
            <Select.Option value="Canada">Canada</Select.Option>
          </Select>
        </Form.Item> */}
      </Col>
    </>
  );
};

export default SignupScreenSecondStep;
