import { useState } from "react";
import { findUserByNameSubstring } from "./helper";
import { FoundUser } from "types/groups";

export const useUserSearchByName = () => {
  const [foundUsers, setFoundUsers] = useState<FoundUser[]>([]);

  const handleSearch = (value: string) => {
    if (!!value.length) {
      findUserByNameSubstring(value).then((res) => {
        if (res?.length) {
          setFoundUsers(res);
        }
      })
    } else {
      setFoundUsers([]);
    }
  }

  return { foundUsers, handleSearch };
}