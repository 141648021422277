const GET_TROPHY_ASSETS_REQUEST = "GET_TROPHY_ASSETS_REQUEST";
const GET_TROPHY_ASSETS_FAIL = "GET_TROPHY_ASSETS_FAIL";
const GET_TROPHY_ASSETS_SUCCESS = "GET_TROPHY_ASSETS_SUCCESS";

const GET_USER_TROPHIES_REQUEST = "GET_USER_TROPHIES_REQUEST";
const GET_USER_TROPHIES_FAIL = "GET_USER_TROPHIES_FAIL";
const GET_USER_TROPHIES_SUCCESS = "GET_USER_TROPHIES_SUCCESS";

const GET_USER_TROPHIES_PAGINATION_REQUEST = "GET_USER_TROPHIES_PAGINATION_REQUEST";
const GET_USER_TROPHIES_PAGINATION_FAIL = "GET_USER_TROPHIES_PAGINATION_FAIL";
const GET_USER_TROPHIES_PAGINATION_SUCCESS = "GET_USER_TROPHIES_PAGINATION_SUCCESS";

export default {
  GET_TROPHY_ASSETS_REQUEST,
  GET_TROPHY_ASSETS_FAIL,
  GET_TROPHY_ASSETS_SUCCESS,
  GET_USER_TROPHIES_REQUEST,
  GET_USER_TROPHIES_FAIL,
  GET_USER_TROPHIES_SUCCESS,
  GET_USER_TROPHIES_PAGINATION_REQUEST,
  GET_USER_TROPHIES_PAGINATION_FAIL,
  GET_USER_TROPHIES_PAGINATION_SUCCESS
};
