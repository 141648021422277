/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import "./PurchaseFranchiseStyle.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { API_URL } from "../../../config";
import { City } from "types";
import { EditOutlined } from "@ant-design/icons";
import { Preview, PreviewData } from "./Preview";
import { ColorSelectorDialog } from "../Dialogs/ColorSelectorDialog";
import { LogoSelectorDialog } from "../Dialogs/LogoSelectorDialog";
import { Input, Popover } from "antd";
import {
  isTextContainsBadWord,
  removeMultipleWhitespacesBetweenWords,
  transparentBase64,
  validName,
} from "helper/helper";
import SelectCityModal from "../Modal/SelectCityModal";
import { addCityInfo } from "redux/actions/TeamAction";
import { useLocation } from "react-router-dom";
import BlackButton from "components/Common/Button/BlackButton/BlackButton";
import { UnselectedPopover } from "./UnselectedPopover";
import { OffensiveNameModal } from "components/Common/CreateFranchise/OffensiveNameModal/OffensiveNameModal";

type PartialPreviewData = Partial<PreviewData>;
type ModalDataSelectors = {
  title: string;
  data: Partial<PreviewData["bg"]>[];
};

type Props = {
  customizeData?: {
    baseRate: number | string;
    cityId: number;
  };
};

const PurchaseFranchiseContent: React.FC<Props> = ({ customizeData }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const shouldNextButtonBeShown =
    location.pathname.includes("purchasefranchise");

  const dataList = useSelector((state: RootState) => state.team.avatar);

  const [teamName, setTeamName] = useState("");
  const [city, setCity] = useState<City | null>(null);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [modalData, setModalData] = useState<ModalDataSelectors | null>(null);
  const [modalDataLogo, setModalDataLogo] = useState<ModalDataSelectors | null>(
    null
  );
  const [currentData, setCurrentData] = useState<PartialPreviewData | null>(
    null
  );
  const [currentUserSelectedData, setCurrentUserSelectedData] =
    useState<PartialPreviewData | null>(null);
  const [teamNameError, setTeamNameError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isTeamNameOffensive, setIsTeamNameOffensive] = useState(false);
  const [isUnselectedErrorShown, setIsUnselectedErrorShown] = useState(false);
  const [isNextPressedWithOffensiveName, setIsNextPressedWithOffensiveName] = useState(false);

  const cityList: City[] | undefined = useSelector(
    (state: RootState) => state.team.cityList
  );

  function handleBackground(data: ModalDataSelectors) {
    setModalData(data);
  }
  function handleHelmet(data: ModalDataSelectors) {
    setModalData(data);
  }
  function handleLogo(data: ModalDataSelectors) {
    setModalDataLogo(data);
  }

  function handleTeamName(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.value;
    if (!validName.test(name)) {
      setTeamNameError("Only letters and numbers are permitted");
      return;
    }
    if (name?.length > 25) {
      setTeamNameError("You have reached maximum limit of characters allowed");
    } else {
      setTeamName(name?.toUpperCase());
      setTeamNameError("");
    }
  }

  function handleSelection(data: PreviewData["helmet"]) {
    if (data.type === "helmet") {
      dataList?.knobs?.map((x: PreviewData["helmet"]) => {
        if (x.name === data.name) {
          setCurrentData((curr) => {
            if (curr) {
              return {
                ...curr,
                [x.type]: x,
                [data.type]: data,
              };
            }
            return null;
          });
          setCurrentUserSelectedData((curr) => {
            if (curr) {
              return {
                ...curr,
                [x.type]: x,
                [data.type]: data,
              };
            }

            return {
              [x.type]: x,
              [data.type]: data,
            };
          });
        }
      });
    } else {
      setCurrentData((curr) => {
        if (curr) {
          return { ...curr, [data.type]: data };
        }

        return null;
      });
      setCurrentUserSelectedData((curr) => {
        if (curr) {
          return { ...curr, [data.type]: data };
        }

        return {
          [data.type]: data,
        };
      });
    }
  }

  const updateData = (
    curr: PartialPreviewData | null
  ): PartialPreviewData | null => {
    if (city) {
      let cityData = dataList?.city?.find((x) => x.url === city.image);
      if (city.id === -2) {
        cityData = {
          ...city,
          colorName: "",
          created: "",
          type: "city",
          url: "",
        };
      }
      if (cityData) {
        cityData = {
          ...cityData,
          name: city.name,
        };
      }
      if (cityData && !curr) {
        return {
          city: cityData,
        };
      }
      if (curr) {
        if (cityData) {
          return {
            ...curr,
            city: cityData,
          };
        }

        return {
          ...curr,
        };
      }

      return null;
    }

    return null;
  };

  const handleSelectCity = () => {
    if (city) {
      setCurrentData(updateData);
      setCurrentUserSelectedData(updateData);
    }
    setisModalVisible(false);
  };

  const handleDataSelection = () => {
    if (
      currentUserSelectedData &&
      currentUserSelectedData?.city &&
      currentUserSelectedData?.bg &&
      currentUserSelectedData?.helmet &&
      currentUserSelectedData?.knobs &&
      currentUserSelectedData?.logo &&
      teamName.length &&
      city
    ) {
      dispatch(
        addCityInfo(
          city.id,
          city.name,
          city.price,
          city.baseRatingFrom,
          city.BaseRatingTo,
          city.image,
          "You select City",
          { ...(currentUserSelectedData as PreviewData), teamName }
        )
      );
    } else {
      dispatch(addCityInfo(null, null, null, null, null, null, null));
    }
  };

  useEffect(() => {
    if (!shouldNextButtonBeShown) {
      handleDataSelection();
    }
  }, [handleDataSelection, shouldNextButtonBeShown]);

  useEffect(() => {
    if (dataList) {
      setCurrentData({
        bg: dataList?.bg[16],
        helmet: dataList?.helmet?.[16],
        city: { ...dataList?.city[9], name: "NOT SELECTED", id: -1 },
        knobs: dataList?.knobs[16],
        logo: {
          url: "",
          id: -1,
          name: "NOT SELECTED",
          type: "logo",
          colorName: "",
          created: "",
        },
      });
    }
  }, [dataList]);

  useEffect(() => {
    if (isTextContainsBadWord(teamName)) {
      setIsTeamNameOffensive(true);
    } else {
      setIsTeamNameOffensive(false);
    }
  }, [teamName]);

  useEffect(() => {
    if (customizeData?.cityId && cityList) {
      const cityData = cityList.find((x) => x.id === customizeData.cityId);
      const cityPictureData = dataList?.city?.find(
        (x) => x.url === cityData?.image
      );
      if (cityData && cityPictureData) {
        setCity(() => cityData);
        setCurrentData((prevData) => ({
          ...prevData,
          city: cityPictureData,
        }));
        setCurrentUserSelectedData((prevData) => ({
          ...prevData,
          city: cityPictureData,
        }));
      }
    }
  }, [customizeData?.cityId, cityList]);

  return (
    <>
      <div className="purchasefranchisemain">
        <Row>
          <Col xs={12} xl={6} className="mb-4">
            {currentData &&
              currentData?.city &&
              currentData?.bg &&
              currentData?.helmet &&
              currentData?.knobs &&
              currentData?.logo && (
                <div className="purchase-preview-container">
                  <Preview
                    imageData={currentData as PreviewData}
                    teamName={teamName}
                    fanBase={customizeData?.baseRate}
                  />
                </div>
              )}
          </Col>
          <Col xs={6}>
            <Row className="align-items-center choosehead">
              <Col
                lg={7}
                sm={7}
                xs={12}
                className="d-inline-flex align-items-center"
              >
                <h6 className="colorhead">
                  <strong>
                    1. Select{!!customizeData?.cityId && "ed"} city
                  </strong>
                  <br />
                  {currentUserSelectedData?.city?.name || "NOT SELECTED"}
                </h6>
              </Col>
              {!customizeData?.cityId && (
                <Col>
                  <div className="color-choose">
                    <div style={{ height: "52px", width: "62px" }} />
                    <UnselectedPopover
                      open={isUnselectedErrorShown && !currentUserSelectedData?.city?.name}
                      content="Please, select a city"
                      handleClose={() => setIsUnselectedErrorShown(false)}
                    >
                      <EditOutlined
                        className="iconpicker"
                        onClick={() => setisModalVisible(true)}
                      />
                    </UnselectedPopover>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="align-items-center logo-select choosehead">
              <Col
                lg={7}
                sm={7}
                xs={12}
                className="d-inline-flex align-items-center"
              >
                <h6 className="colorhead">
                  <strong>2. Select Team Logo</strong>
                  <br />
                  {currentUserSelectedData?.logo?.name
                    ? currentUserSelectedData.logo.name.toUpperCase()
                    : "NOT SELECTED"}
                </h6>
              </Col>
              <Col>
                <div className="color-choose">
                  <div className="logo-box">
                    {currentData?.logo?.id !== -1 ? (
                      <img
                        src={
                          currentData?.logo?.id !== -1
                            ? `${API_URL}${currentData?.logo?.url}`
                            : transparentBase64
                        }
                        alt={currentData?.logo?.name}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                  <UnselectedPopover
                    open={isUnselectedErrorShown && currentData?.logo?.id === -1}
                    content="Please, select a logo"
                    handleClose={() => setIsUnselectedErrorShown(false)}
                  >
                    <Popover
                      content={
                        <LogoSelectorDialog
                          handleClickLogo={handleSelection}
                          modalDataLogo={modalDataLogo}
                        />
                      }
                      trigger={["click"]}
                      placement="right"
                    >
                      <Badge id="badge-with-no-styling">
                        <EditOutlined
                          className="iconpicker"
                          onClick={() =>
                            dataList && handleLogo({
                              title: "Select Logo",
                              data: dataList?.logo,
                            })
                          }
                        />
                      </Badge>
                    </Popover>
                  </UnselectedPopover>
                </div>
              </Col>
            </Row>
            <strong>3. Select team colors</strong>
            <Row className="align-items-center choosehead">
              <Col
                lg={7}
                sm={7}
                xs={12}
                className="d-inline-flex align-items-center"
              >
                <h6 className="colorhead">
                  HELMET -{" "}
                  {currentUserSelectedData?.helmet?.colorName
                    ? currentUserSelectedData.helmet.colorName.toUpperCase()
                    : "NOT SELECTED"}
                </h6>
              </Col>
              <Col>
                <div className="color-choose">
                  <div
                    style={{ background: `#${currentData?.helmet?.name}` }}
                    className="colorview"
                  />
                  <UnselectedPopover
                    open={isUnselectedErrorShown && !currentUserSelectedData?.helmet?.colorName}
                    content="Please, select a helmet color"
                    handleClose={() => setIsUnselectedErrorShown(false)}
                  >
                    <Popover
                      content={
                        <ColorSelectorDialog
                          handleClick={handleSelection}
                          modalData={modalData}
                        />
                      }
                      trigger={["click"]}
                      placement="right"
                    >
                      <Badge id="badge-with-no-styling">
                        <EditOutlined
                          className="iconpicker"
                          onClick={() =>
                            dataList && handleHelmet({
                              title: "Helmet",
                              data: dataList?.helmet,
                            })
                          }
                        />
                      </Badge>
                    </Popover>
                  </UnselectedPopover>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center choosehead">
              <Col
                lg={7}
                sm={7}
                xs={12}
                className="d-inline-flex align-items-center"
              >
                <h6 className="colorhead">
                  BACKGROUND -{" "}
                  {currentUserSelectedData?.bg?.colorName
                    ? currentUserSelectedData.bg.colorName.toUpperCase()
                    : "NOT SELECTED"}
                </h6>
              </Col>
              <Col>
                <div className="color-choose">
                  <div
                    style={{ background: `#${currentData?.bg?.name}` }}
                    className="colorview"
                  />
                  <UnselectedPopover
                    open={isUnselectedErrorShown && !currentUserSelectedData?.bg?.colorName}
                    content="Please, select a background color"
                    handleClose={() => setIsUnselectedErrorShown(false)}
                  >
                    <Popover
                      content={
                        <ColorSelectorDialog
                          handleClick={handleSelection}
                          modalData={modalData}
                        />
                      }
                      trigger={["click"]}
                      placement="right"
                    >
                      <Badge id="badge-with-no-styling">
                        <EditOutlined
                          className="iconpicker"
                          onClick={() =>
                            dataList && handleBackground({
                              title: "Background",
                              data: dataList?.bg,
                            })
                          }
                        />
                      </Badge>
                    </Popover>
                  </UnselectedPopover>
                </div>
              </Col>
            </Row>
            <strong>4. Select Team Name</strong>
            <div className="createFranchise-form-label">
              <UnselectedPopover
                content="Please, enter a team name"
                open={isUnselectedErrorShown && !teamName}
                handleClose={() => setIsUnselectedErrorShown(false)}
                isFullWidth
              >
                <Input
                  placeholder="ENTER NAME"
                  value={teamName}
                  onChange={handleTeamName}
                  onBlur={() =>
                    setTeamName((prevName) =>
                      removeMultipleWhitespacesBetweenWords(prevName)
                    )
                  }
                />
              </UnselectedPopover>
              {teamNameError && <p className="error">{teamNameError}</p>}
              {errorMessage && <p className="error">{errorMessage}</p>}
              {isTeamNameOffensive && (
                <p className="error">
                  WARNING: TEAM NAME IS POSSIBLY OFFENSIVE
                </p>
              )}
            </div>
          </Col>
        </Row>
        {shouldNextButtonBeShown && (
          <div className="next-button-container">
            <BlackButton
              text="NEXT"
              disabled={isUnselectedErrorShown}
              onClick={() => {
                if (isTeamNameOffensive) {
                  setIsNextPressedWithOffensiveName(true);
                  return;
                }
                handleDataSelection();
                setIsUnselectedErrorShown(true);
              }}
            />
          </div>
        )}
      </div>
      <SelectCityModal
        city={city}
        setCity={setCity}
        visible={isModalVisible}
        setVisible={setisModalVisible}
        handlePurchaseCity={handleSelectCity}
      />
      <OffensiveNameModal
        open={isNextPressedWithOffensiveName}
        onClose={() => setIsNextPressedWithOffensiveName(false)}
        onOk={() => {handleDataSelection(); setIsNextPressedWithOffensiveName(false)}}
        onChangeName={() => {setTeamName(""); setIsNextPressedWithOffensiveName(false);}}
      />
    </>
  );
};

export default PurchaseFranchiseContent;
