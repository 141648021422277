type FormErrorsProps = {
  errors: string[];
};

export function FormErrors({ errors }: FormErrorsProps) {
  return (
    <>
      {errors.map((error) => (
        <div className="error_message">{error}</div>
      ))}
    </>
  );
}
