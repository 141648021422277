import './StatsTabStyle.scss';
import { Row, Col } from 'react-bootstrap';
import { Table } from 'antd';
import Title from '../../../Common/Title/Title';
import React, { useMemo } from 'react';
import { eliminationColumns, headToheadColumns, seasonColumns } from './constants';
import { useTeamPerformance } from './hooks/useTeamPerformance';

type Props = {
  engId?: number;
};

const StatsFranchisePerformance: React.FC<Props> = ({ engId }) => {
  const teamPerformance = useTeamPerformance(engId);

  const h2hStatsData = useMemo(() => {
    const gamesPlayed = teamPerformance?.h2hWon !== undefined && teamPerformance?.h2hLost !== undefined ? teamPerformance?.h2hWon + teamPerformance?.h2hLost : 'N/A';

    return [
      {
        headTohead: 'HEAD-TO-HEAD PLAYED :',
        value: gamesPlayed,
      },
      {
        headTohead: 'RECORD :',
        value: teamPerformance?.h2hWon !== undefined && teamPerformance?.h2hLost !== undefined ? `${teamPerformance?.h2hWon}-${teamPerformance?.h2hLost}-0` : 'N/A',
      },
      {
        headTohead: 'ROI (WINNING-FEES) :',
        value: 'N/A',
      },
    ];
  }, [teamPerformance]);

  const eliminationData = useMemo(() => {
    return [
      {
        elimination: 'ELIMINATION PLAYED :',
        value:
          typeof teamPerformance?.elimWon === 'number' && typeof teamPerformance?.elimLost === 'number' ? teamPerformance?.elimWon + teamPerformance?.elimLost : 'N/A',
      },
      {
        elimination: 'RECORD :',
        value:
          typeof teamPerformance?.elimWon === 'number' && typeof teamPerformance?.elimLost === 'number'
            ? `${teamPerformance?.elimWon}-${teamPerformance?.elimLost}-0`
            : 'N/A',
      },
      {
        elimination: 'FINALS REACHED :',
        value: typeof teamPerformance?.finalsReached === 'number' ? teamPerformance?.finalsReached : 'N/A',
      },
      {
        elimination: 'CHAMPIONSHIPS :',
        value: typeof teamPerformance?.elimWon === 'number' ? teamPerformance?.elimWon : 'N/A',
      },
      {
        elimination: 'BEST RESULTS :',
        value: 'N/A',
      },
      {
        elimination: 'ROI (WINNIG-FEES) :',
        value: 'N/A',
      },
    ];
  }, [teamPerformance]);

  const seasonData = useMemo(() => {
    const bestRecord = teamPerformance?.season?.bestRecord;
    const worstRecord = teamPerformance?.season?.worstRecord;
    const seasonsCount = teamPerformance?.season?.seasonsPlayed;
    const championships = teamPerformance?.season?.championships;
    const playoff = teamPerformance?.playOff;
    return [
      {
        season: 'SEASONS PLAYED :',
        value: seasonsCount || 'N/A',
      },
      {
        season: 'REGULAR SEASON RECORD :',
        value: `${teamPerformance?.season?.totalW || 0}-${teamPerformance?.season?.totalL || 0}-${teamPerformance?.season?.totalT || 0}`,
      },
      {
        season: 'PLAYOFF RECORD :',
        value: `${playoff?.w || 0}-${playoff?.l || 0}-${playoff?.t || 0}`,
      },
      {
        season: 'CHAMPIONSHIPS :',
        value: championships || 'N/A',
      },
      {
        season: 'BEST RECORD :',
        value: `${bestRecord?.w || 0}-${bestRecord?.l || 0}-${bestRecord?.t || 0}`,
      },
      {
        season: 'WORST RECORD :',
        value: `${worstRecord?.w || 0}-${worstRecord?.l || 0}-${worstRecord?.t || 0}`,
      },
      {
        season: 'ROI (WINNING-FEES) :',
        value: 'N/A',
      },
    ];
  }, [teamPerformance]);

  return (
    <div className="mb-4">
      <Title text="FRANCHISE PERFORMANCE" />
      <Row>
        <Col xl={4} lg={4}>
          <div className="statsTable standingmain mt-2">
            <Table columns={seasonColumns} pagination={false} dataSource={seasonData.map((data, i) => ({ ...data, key: i }))} size="small" />
          </div>
        </Col>
        <Col xl={4} lg={4}>
          <div className="statsTable standingmain mt-2">
            <Table
              columns={eliminationColumns}
              pagination={false}
              dataSource={eliminationData.map((data, i) => ({
                ...data,
                key: i,
              }))}
              size="small"
            />
          </div>
        </Col>
        <Col xl={4} lg={4}>
          <div className="statsTable standingmain mt-2">
            <Table
              columns={headToheadColumns}
              pagination={false}
              dataSource={h2hStatsData.map((data, i) => ({
                ...data,
                key: i,
              }))}
              size="small"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StatsFranchisePerformance;
