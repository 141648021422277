import { useLocation } from "react-router-dom";
import BoxScoreRating from "../../../Common/BoxScoreRating/BoxScoreRating";
import { useOverviewRating } from "./useOverviewRating";

type Props = {
  gid: number;
};

const LeagueOverviewRating = ({ gid }: Props) => {
  const location = useLocation();
  const isChartPlanHidden = location.pathname !== "/leagueheadtohead";
  const { isInProgress, isCompleted, data } = useOverviewRating({
    gid,
    isNotRefreshable: isChartPlanHidden,
  });
  if (!isInProgress && !isCompleted) {
    return (
      <BoxScoreRating
        gameplayRedirect
        gametime
        chartplan
        readyButtons
        data={data[0]}
      />
    );
  }

  if (isInProgress) {
    return (
      <BoxScoreRating
        chartplan={!isChartPlanHidden}
        watchingButton
        data={data[0]}
      />
    );
  }
  if (isCompleted) {
    return (
      <BoxScoreRating
        chartplan={!isChartPlanHidden}
        ratingtable
        data={data[0]}
      />
    );
  }
  return <div></div>;
};

export default LeagueOverviewRating;
