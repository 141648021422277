import { Slider } from 'antd';
import { OffensiveStrategy } from 'types';

export interface Props {
    data: {
        sliderName: string;
        slidervalue: number;
        sliderleftname: string;
        sliderrightname: string;
        sliderKeyName: keyof OffensiveStrategy;
        onChange: (key: keyof OffensiveStrategy, value: number) => void;
        min?: number;
        max?: number;
    };
}

const GamePlanSlider: React.FC<Props> = ({ data }) => (
    <div className="slider_main" >
        <h5>{data.sliderName}</h5>
        <Slider defaultValue={data.slidervalue} onChange={(value) => data.onChange(data.sliderKeyName, value)} min={data?.min} max={data?.max}/>
        <span>{data.sliderleftname}</span>
        <span>{data.sliderrightname}</span>
    </div>
);

export default GamePlanSlider;