import { FriendRequestMain } from "components/FriendRequest";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export function FriendRequestScreen(){
    return (
      <div className="pagelayout">
         <WithLoginNavbar />
         <FriendRequestMain />
      </div>
    )
}