import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGroupGames } from "redux/actions/Groups";
import { RootState } from "redux/store";

export const useGroupGames = (
  groupId?: number,
  page?: number,
  limit?: number
) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.group.groupGames);

  useEffect(() => {
    if (groupId && page && limit) {
      dispatch(GetGroupGames(groupId, page, limit));
    }
  }, [groupId, page, limit]);

  return data;
};
