import { useState } from "react";
import { Input, Select, Button, Spin, Card } from "antd";
import { useProcessPromt } from "./useProcessPromt";
import { PlayerProfileOptions } from "./PlayerProfileConstants";
import CardBody from "components/Common/Card/CardBody";
import { Roster } from "components/MintRoster/interface";
import { Stack } from "react-bootstrap";

export type ProfileRequestProps = {
  firstName: string;
  lastName: string;
  race: string;
  background: string;
  location: string;
  familySocialStatus: string;
  student: string;
  trouble: string;
  story: string;
  position: string;
  family: string;
  footballBackground: string;
  education: string;
  accomplishments: string;
  inspiration: string;
  relationshipStatus: string;
  backgroundMood: string;
};

const { Option } = Select;

type Props = {
  roster: Roster | null;
  rosterDescription: string;
  setRosterDescription: (value: string) => void;
  onClose: () => void;
}

export const PlayerProfileMain: React.FC<Props> = ({ roster, rosterDescription, setRosterDescription, onClose }) => {
  const [firstName, setFirstName] = useState(roster?.firstName || '');
  const [lastName, setLastName] = useState(roster?.lastName || '');
  const [position, setPosition] = useState(roster?.pos || "");

  const [race, setRace] = useState('');
  const [background, setBackground] = useState('');
  const [location, setLocation] = useState('');
  const [familySocialStatus, setFamilySocialStatus] = useState('');
  const [student, setStudent] = useState('');
  const [trouble, setTrouble] = useState('');
  const [story, setStory] = useState('');
  const [family, setFamily] = useState('');
  const [footballBackground, setFootballBackground] = useState('');
  const [education, setEducation] = useState('');
  const [accomplishments, setAccomplishments] = useState('');
  const [inspiration, setInspiration] = useState('');
  const [relationshipStatus, setRelationshipStatus] = useState('');
  const [backgroundMood, setBackgroundMood] = useState('');

  const { processPromt, loader: submitLoader } = useProcessPromt();

  const playerProfile: ProfileRequestProps = {
    firstName,
    lastName,
    race,
    background,
    location,
    familySocialStatus,
    student,
    trouble,
    story,
    position,
    family,
    footballBackground,
    education,
    accomplishments,
    inspiration,
    relationshipStatus,
    backgroundMood
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  const handleRaceChange = (value: string) => {
    setRace(value);
  };

  const handleBackgroundChange = (value: string) => {
    setBackground(value);
  };

  const handleLocationChange = (value: string) => {
    setLocation(value);
  };

  const handleFamilySocialStatusChange = (value: string) => {
    setFamilySocialStatus(value);
  };

  const handleStudentChange = (value: string) => {
    setStudent(value);
  };

  const handleTroubleChange = (value: string) => {
    setTrouble(value);
  };

  const handleStoryChange = (value: string) => {
    setStory(value);
  };

  const handlePositionChange = (value: string) => {
    setPosition(value);
  };

  const handleFamilyChange = (value: string) => {
    setFamily(value);
  }

  const handleFootballBackgroundChange = (value: string) => {
    setFootballBackground(value);
  }

  const handleEducationChange = (value: string) => {
    setEducation(value);
  }

  const handleAcomplishmentsChange = (value: string) => {
    setAccomplishments(value);
  }

  const handleInspirationChange = (value: string) => {
    setInspiration(value);
  }

  const handleRelationshipStatusChange = (value: string) => {
    setRelationshipStatus(value);
  }

  const handleBackgroundMoodChange = (value: string) => {
    setBackgroundMood(value);
  }

  const handleSubmit = async () => {
    setRosterDescription('');
    const response = await processPromt(playerProfile);
    if (response) {
      setRosterDescription(response);
      onClose();
    }
  };

  const getRandomOption = (constantName: keyof typeof PlayerProfileOptions.PLAYER_PROFILE_CONSTANTS) => {
    const option = PlayerProfileOptions.getRandomOptionByConstantName(constantName);
    return typeof option === 'string' ? option : option.value;
  };

  const isSomeFieldEmpty = Object.values(playerProfile).some((value) => value.length === 0);
  const handleRandomize = () => {

    setRace(getRandomOption('RACE'));
    setBackground(getRandomOption('BACKGROUND'));
    setLocation(getRandomOption('LOCATION'));
    setFamilySocialStatus(getRandomOption('FAMILY_SOCIAL_STATUS'));
    setStudent(getRandomOption('STUDENT'));
    setTrouble(getRandomOption('TROUBLE'));
    setStory(getRandomOption('STORY'));
    // setPosition(getRandomOption('POSITION'));
    setFamily(getRandomOption('FAMILY'));
    setFootballBackground(getRandomOption('FOOTBALL_BACKGROUND'));
    setEducation(getRandomOption('EDUCATION'));
    setAccomplishments(getRandomOption('ACCOMPLISHMENTS'));
    setInspiration(getRandomOption('INSPIRATION'));
    setRelationshipStatus(getRandomOption('RELATIONSHIP_STATUS'));
    setBackgroundMood(getRandomOption('BACKGROUND_MOOD'));
  }

  return (
    <Card>
      <CardBody>
        <form>
          <Stack gap={2}>
            <label>
              First Name:
              <Input
                value={firstName}
                onChange={(e) => handleFirstNameChange(e.target.value)}
                placeholder="Enter first name"
                disabled={true}
              />
            </label>

            <label>
              Last Name:
              <Input
                value={lastName}
                onChange={(e) => handleLastNameChange(e.target.value)}
                placeholder="Enter last name"
                disabled={true}
              />
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Position:</span>
              <Select value={position} onChange={handlePositionChange} disabled={true}>
                <Option value="">Select Position</Option>
                {PlayerProfileOptions.getOptionsByConstantName('POSITION')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Race:</span>
              <Select value={race} onChange={handleRaceChange}>
                <Option value="">Select Race</Option>
                {PlayerProfileOptions.getOptionsByConstantName('RACE')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Background:</span>
              <Select value={background} onChange={handleBackgroundChange}>
                <Option value="">Select Background</Option>
                {PlayerProfileOptions.getOptionsByConstantName('BACKGROUND')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Location:</span>
              <Select value={location} onChange={handleLocationChange}>
                <Option value="">Select Location</Option>
                {PlayerProfileOptions.getOptionsByConstantName('LOCATION')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Family Social Status:</span>
              <Select
                value={familySocialStatus}
                onChange={handleFamilySocialStatusChange}
              >
                <Option value="">Select Family Social Status</Option>
                {PlayerProfileOptions.getOptionsByConstantName('FAMILY_SOCIAL_STATUS')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Student:</span>
              <Select value={student} onChange={handleStudentChange}>
                <Option value="">Select Student</Option>
                {PlayerProfileOptions.getOptionsByConstantName('STUDENT')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Trouble:</span>
              <Select value={trouble} onChange={handleTroubleChange}>
                <Option value="">Select Trouble</Option>
                {PlayerProfileOptions.getOptionsByConstantName('TROUBLE')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Story:</span>
              <Select value={story} onChange={handleStoryChange}>
                <Option value="">Select Story</Option>
                {PlayerProfileOptions.getOptionsByConstantName('STORY')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Family:</span>
              <Select value={family} onChange={handleFamilyChange}>
                <Option value="">Select Family</Option>
                {PlayerProfileOptions.getOptionsByConstantName('FAMILY')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Football Background:</span>
              <Select value={footballBackground} onChange={handleFootballBackgroundChange}>
                <Option value="">Select Football Background</Option>
                {PlayerProfileOptions.getOptionsByConstantName('FOOTBALL_BACKGROUND')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Education:</span>
              <Select value={education} onChange={handleEducationChange}>
                <Option value="">Select Education</Option>
                {PlayerProfileOptions.getOptionsByConstantName('EDUCATION')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Accomplishments:</span>
              <Select value={accomplishments} onChange={handleAcomplishmentsChange}>
                <Option value="">Select Accomplishments</Option>
                {PlayerProfileOptions.getOptionsByConstantName('ACCOMPLISHMENTS')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Inspiration:</span>
              <Select value={inspiration} onChange={handleInspirationChange}>
                <Option value="">Select Inspiration</Option>
                {PlayerProfileOptions.getOptionsByConstantName('INSPIRATION')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Relationship Status:</span>
              <Select value={relationshipStatus} onChange={handleRelationshipStatusChange}>
                <Option value="">Select Relationship Status</Option>
                {PlayerProfileOptions.getOptionsByConstantName('RELATIONSHIP_STATUS')}
              </Select>
            </label>

            <label className="d-flex gap-2 justify-content-between align-items-center">
              <span className="flex-shrink-0">Background Mood:</span>
              <Select value={backgroundMood} onChange={handleBackgroundMoodChange}>
                <Option value="">Select Background Mood</Option>
                {PlayerProfileOptions.getOptionsByConstantName('BACKGROUND_MOOD')}
              </Select>
            </label>
          </Stack>

          <Stack direction="horizontal" gap={2} className="mt-4 justify-content-end align-items-center">
            <Button
              type="default"
              onClick={handleRandomize}
            >
              RANDOMIZE
            </Button>

            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={isSomeFieldEmpty || submitLoader}
            >
              {submitLoader
                ? <Spin />
                : rosterDescription.length > 0
                  ? 'REGENERATE'
                  : 'GENERATE'}
            </Button>
          </Stack>
        </form>
      </CardBody>
    </Card>
  );
};