import { Button } from "antd";
import { useInviteFriendsForm } from "./hooks/use-invite-friends-form";
import "./InviteFriendsStyle.scss";
import { CloseOutlined } from "@ant-design/icons";
import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";
import { InviteFriendsModalContent } from "./InviteFriendsModalContent";

export function InviteFriends() {
  const {
    emails,
    handleEmailsChange,
    message,
    handleMessageChange,
    handleSubmit,
    errors,
    isLoading,
    handleRedirect,
  } = useInviteFriendsForm();

  return (
    <div className="invite__friends">
      <div className="container">
        <div className="invite__friends-close__button">
          <Button
            size="small"
            icon={<CloseOutlined />}
            onClick={handleRedirect}
            aria-label="Close"
          />
        </div>
        <InviteFriendsModalContent 
          emails={emails}
          errors={errors}
          handleEmailsChange={handleEmailsChange}
          handleMessageChange={handleMessageChange}
          handleSubmit={handleSubmit}
          message={message}
          isLoading={isLoading}
        />
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
}
