import Error from "../../../../../assets/images/common/error.svg";

const HeadCoachModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Promotion to head coach is not allowed. you are only allowed
                1 head coach. Demote or release the head coach prior to
                making a promotion.</p>
            </div> 
        </>
    );
}

export default HeadCoachModal;