import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { LeagueResult } from 'types';

export const getEliminationResults = (gid: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_ELIMINATION_RESULTS_REQUEST,
        payload: {},
      });

      const res = await get<LeagueResult[]>(`/engine/games/elimination/${gid}/eliminationResults`);

      dispatch({
        type: types.GET_ELIMINATION_RESULTS_SUCCESS,
        payload: { eliminationResults: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ELIMINATION_RESULTS_FAIL,
        error: error,
      });
    }
  };
};
