const GET_ACTIVE_GAME_ANIMATIONS_REQUEST = "GET_ACTIVE_GAME_ANIMATIONS_REQUEST";
const GET_ACTIVE_GAME_ANIMATIONS_FAIL = "GET_ACTIVE_GAME_ANIMATIONS_FAIL";
const GET_ACTIVE_GAME_ANIMATIONS_SUCCESS = "GET_ACTIVE_GAME_ANIMATIONS_SUCCESS";

const GET_GAME_ANIMATION_NAMES_REQUEST = "GET_GAME_ANIMATION_NAMES_REQUEST";
const GET_GAME_ANIMATION_NAMES_FAIL = "GET_GAME_ANIMATION_NAMES_FAIL";
const GET_GAME_ANIMATION_NAMES_SUCCESS = "GET_GAME_ANIMATION_NAMES_SUCCESS";

export default {
  GET_ACTIVE_GAME_ANIMATIONS_REQUEST,
  GET_ACTIVE_GAME_ANIMATIONS_FAIL,
  GET_ACTIVE_GAME_ANIMATIONS_SUCCESS,
  GET_GAME_ANIMATION_NAMES_REQUEST,
  GET_GAME_ANIMATION_NAMES_FAIL,
  GET_GAME_ANIMATION_NAMES_SUCCESS
};
