import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitedGames } from 'redux/actions/InvitedGames/actions/InvitedGames';
import { RootState } from 'redux/store';
import { EliminationGame, H2HGame } from 'types';

export const useInvitedGames = (userId?: number, number: boolean = false) => {
  const dispatch = useDispatch();

  const invitedGames: Partial<H2HGame & EliminationGame>[] | undefined = useSelector((state: RootState) => state.gameEngine.invitedGames);
  const invitedGamesNumber: number | undefined = useSelector((state: RootState) => state.gameEngine.invitedGamesNumber);

  useEffect(() => {
    if (!userId) return;
    dispatch(getInvitedGames(userId, number));
  }, [userId, number]);

  return { invitedGames, invitedGamesNumber };
};
