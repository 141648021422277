import { Row, Col } from 'react-bootstrap';
import './PlayersStockReport.scss'
import PlayersStockReportGroup from './PlayersStockReportGroup';
import { PlayersStockReportPlayer } from 'types';

export type PlayersStockReportProps = {
  gainers: PlayersStockReportPlayer[];
  loosers: PlayersStockReportPlayer[];
}

const PlayersStockReport: React.FC<PlayersStockReportProps> = ({gainers, loosers}) => {
  return (
    <Col xs={12} md={12} xl={8} className='players-stock-report'>
      <Row>
        <Col xs={12} md={6}>
          <h5>Gainers</h5>
          <PlayersStockReportGroup data={gainers}/>
        </Col>
        <Col xs={12} md={6}>
          <h5>Loosers</h5>
          <PlayersStockReportGroup data={loosers}/>
        </Col>
      </Row>
    </Col>
  );
};

export default PlayersStockReport;
