import { motion } from "framer-motion";

type Props = {
  text: string;
  className: string;
  withMiddleDots?: boolean;
};
const AnimatedTitle = ({ text, className, withMiddleDots }: Props) => {
  const item = {
    hidden: {
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
    visible: {
      y: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
  };

  const splitWords = text.split(" ");

  // Create storage array
  const words: any[] = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }

  words.map((word, index) => {
    return index !== words.length - 1 && word.push("\u00A0");
  });

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={container}
      viewport={{ once: true }}
    >
      {words.map((word, i) => {
        return (
          <span style={{ whiteSpace: "nowrap" }} key={i}>
            {words[i].flat().map((element: string, j: number) => {
              return (
                <span
                  style={{
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                  key={j}
                >
                  <motion.span
                    style={{ display: "inline-block" }}
                    className={className}
                    variants={item}
                  >
                    {withMiddleDots
                      ? element === "\u00A0"
                        ? "\u00A0\u00B7\u00A0"
                        : element
                      : element}
                  </motion.span>
                </span>
              );
            })}
          </span>
        );
      })}
    </motion.div>
    // <AnimatePresence>
    //   <motion.div
    //     variants={boxVariants}
    //     initial="out"
    //     whileInView="in"
    //     viewport={{ once: true }}
    //   >
    //     {letters.map((letter, index) => (
    //       <motion.span
    //         layout
    //         key={index}
    //         className={className}
    //         whileInView="visible"
    //         initial="hidden"
    //         variants={iconVariants}
    //       >
    //         {withMiddleDots
    //           ? letter === " "
    //             ? "\u00A0\u00B7\u00A0"
    //             : letter
    //           : letter}
    //       </motion.span>
    //     ))}
    //   </motion.div>
    // </AnimatePresence>
  );
};

export default AnimatedTitle;
