import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFranchisePerformance } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';

export const useTeamPerformance = (utid?: number) => {
  const dispatch = useDispatch();
  const franchisePerformance = useSelector((state: RootState) => state.gameEngine.franchisePerformance);

  useEffect(() => {
    if (utid) {
      dispatch(getFranchisePerformance(utid));
    }
  }, [utid]);

  return franchisePerformance;
};
