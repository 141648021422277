/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { get, post } from "../services/Api";
import * as types from "./teamTypes";
import { isTextContainsBadWord } from "./../../helper/helper";
import history from "./../../routes/history";
import {
   CONTRACT_CHAIN,
   MORALIS_API_KEY,
   DONT_MATCH_MM_TEAMS,
} from "../../config";
import { CONTRACT_ADDRESS } from "./../../config/index";
import { TeamStatus, UserTeamsList } from "types";
import { PreviewData } from "components/PurchaseFranchise/Common/Preview";
import { notification } from "antd";
import { RootState } from "redux/store";

export const getAvatarData = (forceUpdate?: boolean) => {
   return async (dispatch: any, getState: () => RootState) => {
      const teamState = getState().team;
      if ((teamState.avatar || teamState.loaders.avatarData) && !forceUpdate) return;
      try {
         dispatch({
            type: types.GETAVATAR_REQUEST,
            payload: {},
         });
         const res: any = await get("/user/avatar-data");
         dispatch({
            type: types.GETAVATAR_SUCCESS,
            payload: { avatar: res.message },
         });
      } catch (error: any) {
         dispatch({
            type: types.GETAVATAR_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const saveAvatarData = (
   currentData: any,
   teamName: any,
   baseRate: any,
   frenchiseID: any,
   currentdateTime: any,
   searchParamsType: any
) => {
   return async (dispatch: any, getState: any) => {
      const userName = getState().app.resData?.userName;
      const userId = getState().app.resData?.id;
      const formdata = new FormData();
      formdata.append("userName", userName);
      formdata.append("bg", currentData.bg.id);
      formdata.append("helmet", currentData.helmet.id);
      formdata.append("logo", currentData.logo.id);
      formdata.append("city", currentData.city.id);
      formdata.append("knobs", currentData.knobs.id);
      formdata.append("teamName", teamName);
      formdata.append("baseRate", baseRate);
      formdata.append("id", frenchiseID);
      formdata.append("ownerId", userId);
      formdata.append("created", currentdateTime);
      formdata.append("updated", currentdateTime);
      formdata.append("actionType", searchParamsType);

      try {
         dispatch({
            type: types.SAVEAVATARDATA_REQUEST,
            payload: {},
         });
         const res: any = await post("/user/create-avatar", formdata, {
            "Content-Type": "multipart/form-data",
         });

         dispatch(userTeamsList());

         dispatch({
            type: types.SAVEAVATARDATA_SUCCESS,
            payload: { frechiesSuccess: res.message },
         });

         history.push(`/ownfranchise?toReveal=${frenchiseID}`);
      } catch (error: any) {
         dispatch({
            type: types.SAVEAVATARDATA_FAIL,
            error: error.response?.data?.error
               ? error.response?.data?.error
               : "Something went wrong",
         });
      }
   };
};

export const updateAvatarData = (currentData: any) => {
   return async (dispatch: any, getState: any) => {
      const id = getState().app.resData?.id;

      try {
         dispatch({
            type: types.UPDATEAVATARDATA_REQUEST,
            payload: {},
         });
         const res: any = await post("/user/update-avatar", { ...currentData, userId: id }, {});
         dispatch({
            type: types.UPDATEAVATARDATA_SUCCESS,
            payload: { resData: res.message },
         });
         dispatch(userTeamsList());
         history.push("/ownfranchise");
      } catch (error: any) {
         dispatch({
            type: types.UPDATEAVATARDATA_FAIL,
            error: error.response?.data?.error,
         });
         notification.error({
            message: "Error updating (creating) franchise avatar",
            description: error.response?.data?.error,
            placement: "bottomRight",
         });
      }
   };
};

export const userTeamsList = (
   { 
      withStats = false, 
      withActivity = false 
   }: { 
      withStats: boolean, 
      withActivity: boolean
   } = { 
      withStats: false, 
      withActivity: false
   }) => {
   return async (dispatch: any, getState: any) => {
      if (getState().team.loaders.userTeamsList) return;
      
      const resData = getState().app.resData;

      const userId: number | undefined = resData?.id;

      if (!userId) {
         return null;
      }

      try {
         dispatch({
            type: types.USERTEAMSLIST_REQUEST,
            payload: {},
         });

         const fetchChainData: any = resData?.metaMaskID
            ? await get(
               `https://deep-index.moralis.io/api/v2/${resData?.metaMaskID}/nft/${CONTRACT_ADDRESS}?chain=${CONTRACT_CHAIN}&format=decimal`,
               {},
               {
                  "X-API-Key": MORALIS_API_KEY,
               }
            ).catch(() => ({ result: [] }))
            : { result: [] };

         const teams = await get<{ data: UserTeamsList }>(`/user/get-avatar-list/${userId}`);

         if (withActivity) {
            const activityData = await get<Record<string, any>>(`/engine/owner/${userId}/activity`);
            teams.data.forEach(t => {
               const teamActivity = activityData[t.engId];
               if (teamActivity) {
                  t.activity = teamActivity;
               }
            })
         }

         const nonApproved = teams?.data?.filter((info: any) => info.approval === 0);

         const ownFranchisesNft: any = [];
         const newFranchisesNft: any = [];

         // skip MetaMask team lookup for local testing
         if (DONT_MATCH_MM_TEAMS == "true") {
            ownFranchisesNft.push(...teams?.data?.filter((info: any) => info.approval != 0));
         } else {
            fetchChainData?.result?.map((info: any) => {
               let find = teams?.data?.find((d: any) => d.nftTokenId === info.token_id);
               if (find) {
                  ownFranchisesNft?.push(find);
               } else {
                  let data = {
                     nftTokenId: info.token_id,
                     newUserID: userId,
                  };
                  newFranchisesNft?.push(data);
               }
            });
         }

         let dataList: UserTeamsList = [];
         if (newFranchisesNft?.length > 0) {
            console.log("🚀 ~ In if new Franchises Nft List ***", newFranchisesNft);
            const callTransferFranchise: any = await post(
               `/user/transfer-franchise`,
               newFranchisesNft
            );
            
            if (callTransferFranchise?.data) {
               dataList = [...callTransferFranchise.data, ...nonApproved];
            } else {
               dataList = [...ownFranchisesNft, ...nonApproved];
            }
         } else {
            dataList = [...ownFranchisesNft, ...nonApproved];
         }
         if (withStats && dataList?.length) {
            await addStats(dataList);
         }

         dispatch({
            type: types.USERTEAMSLIST_SUCCESS,
            payload: { userTeamsList: dataList },
         });
      } catch (error: any) {
         console.warn(error);
         dispatch({
            type: types.USERTEAMSLIST_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

const addStats = async (dataList: UserTeamsList) => {
   const idsString = dataList
      ?.map(t => t.engId)
      ?.filter(Boolean)
      .join(",");

   if (!idsString?.length) {
      return;
   }

   const teamsStats = await get<{ utid: number, ovr: number, winRate: number }[]>(
      `/engine/team/${idsString}/ratingStats`
   );

   teamsStats?.forEach(team => {
      const foundIndex = dataList?.findIndex(userTeams => userTeams.engId === team.utid);
      if (foundIndex !== -1) {
         dataList[foundIndex] = { ...dataList[foundIndex], ...team };
      }
   });
};

export const fetchSelectedAvatar = (id: number, mode: 'utid' | 'id' = 'utid') => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.FETCHSELECTEDAVATAR_REQUEST,
            payload: {},
         });
         const res: any = await get(mode === 'utid' ? `/user/get-avatar/${id}` : `/user/get-franchise/${id}`);

         dispatch({
            type: types.FETCHSELECTEDAVATAR_SUCCESS,
            payload: { fetchedAvatar: res.data },
         });
      } catch (error: any) {
         dispatch({
            type: types.FETCHSELECTEDAVATAR_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const addCityInfo = (
   id: number | null,
   name: string | null,
   price: number | string | null,
   baseRating: number | null,
   to: number | null,
   image: string | null,
   type: string | null,
   franchiseData?: PreviewData & { teamName: string },
) => {
   return async (dispatch: any, getState: any) => {
      let data = null;
      if (name !== null && price !== null) {
         data = {
            id,
            name,
            price,
            baseRating,
            to,
            type,
            image,
            franchiseData
         };
      }

      dispatch({
         type: types.ADD_CITYINFO,
         payload: { cityInfo: data },
      });
   };
};

export const cleanCityInfo = () => {
   return async (dispatch: any, getState: any) => {
      dispatch({
         type: types.CLEAN_CITYINFO,
      });
   };
}

export const getPartnerCode = (code: any) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.GET_PARTNER_CODE_REQUEST,
            payload: {},
         });
         const res: any = await get(`/partner/${code}`);

         dispatch({
            type: types.GET_PARTNER_CODE_SUCCESS,
            payload: { partnerCode: res },
         });
      } catch (error: any) {
         dispatch({
            type: types.GET_PARTNER_CODE_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const getCreateFrenchiseDetail = (frenchiseID: any) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.GETFRENCHISEDATA_REQUEST,
            payload: {},
         });
         const res: any = await get(`/user/getFrenchiseDetails/${frenchiseID}`);

         dispatch({
            type: types.GETFRENCHISEDATA_SUCCESS,
            payload: { createFrenchiseData: res.message },
         });
      } catch (error: any) {
         dispatch({
            type: types.GETFRENCHISEDATA_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const getCityDetail = (cityId: any) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.GETCITYDETAIL_REQUEST,
            payload: {},
         });
         const res: any = await get(`/user/getCityDetails/${cityId}`);

         dispatch({
            type: types.GETCITYDETAIL_SUCCESS,
            payload: { cityDetails: res.message },
         });
      } catch (error: any) {
         dispatch({
            type: types.GETCITYDETAIL_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const addUserInfo = (birthdate: any, country: any, id: any) => {
   return async (dispatch: any, getState: any) => {
      const userName = getState().app.resData?.userName;
      const formdata = new FormData();
      formdata.append("country", country);
      formdata.append("birthDate", birthdate);
      formdata.append("userId", id);

      try {
         dispatch({
            type: types.USERINFO_REQUEST,
            payload: {},
         });
         const res: any = await post("/user/infoData", formdata, {
            "Content-Type": "multipart/form-data",
         });
         dispatch({
            type: types.USERINFO_SUCCESS,
            payload: { infoRes: res.message },
         });
      } catch (error: any) {
         dispatch({
            type: types.USERINFO_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const getCityList = () => {
   return async (dispatch: any, getState: any) => {
      if (getState().team.loaders.getCityList) return;

      try {
         dispatch({
            type: types.GETCITYLIST_REQUEST,
            payload: {},
         });
         const res: any = await get("/user/cities");
         dispatch({
            type: types.GETCITYLIST_SUCCESS,
            payload: { cityList: res.message },
         });
      } catch (error: any) {
         dispatch({
            type: types.GETCITYLIST_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const legendEmail = (email: any) => {
   return async (dispatch: any, getState: any) => {
      const formdata = new FormData();
      formdata.append("email", email);

      try {
         dispatch({
            type: types.LEGENDMAIL_REQUEST,
            payload: {},
         });
         const res: any = await post("/user/legends-franchise-email", formdata);
         dispatch({
            type: types.LEGENDMAIL_SUCCESS,
            payload: { legendSuccess: res.EmailList },
         });
      } catch (error: any) {
         dispatch({
            type: types.LEGENDMAIL_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const verifyEmail = (token: any) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.EMAILVEIFICATION_REQUEST,
            payload: {},
         });
         const res: any = await get(`verify-email/${token}`);
         dispatch({
            type: types.EMAILVEIFICATION_SUCCESS,
            payload: { verifySucess: res.message },
         });
      } catch (error: any) {
         dispatch({
            type: types.EMAILVEIFICATION_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const createResReset = () => {
   return async (dispatch: any, getState: any) => {
      dispatch({
         type: types.RESPONCERESET_REQUEST,
      });
      dispatch({
         type: types.GETFRENCHISEDATA_CLEAR,
      })
   };
};

export const playerRevealApproval = (teamId: any) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.PLAYERREVEALAPPROVAL_REQUEST,
            payload: {},
         });
         const res: any = await get(`/user/player-reveal-approval/${teamId}`);
         dispatch({
            type: types.PLAYERREVEALAPPROVAL_SUCCESS,
            payload: {},
            success: res?.message,
         });
      } catch (error: any) {
         dispatch({
            type: types.PLAYERREVEALAPPROVAL_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const getPreviewNFT = (
   bg: any,
   city: any,
   helmet: any,
   knobs: any,
   logo: any,
   teamName: any,
   baseRate: any
) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.GETPREVIEWNFT_REQUEST,
            payload: {},
         });
         const res: any = await post("/user/get-preview-NFT", {
            bg,
            city,
            helmet,
            knobs,
            logo,
            teamName,
            baseRate,
         });
         dispatch({
            type: types.GETPREVIEWNFT_SUCCESS,
            payload: { previewNFT: res.previewNFT },
         });
      } catch (error: any) {
         console.log("🚀 ~ error", error);
         dispatch({
            type: types.GETPREVIEWNFT_FAIL,
            error: error,
         });
      }
   };
};

export const addLeagueEnterFormData = (data: any) => {
   return async (dispatch: any, getState: any) => {
      dispatch({
         type: types.ADD_LEAGUE_ENTER_FORM_DATA,
         payload: { leagueEnterFormData: data },
      });
   };
};

export const fetchAllSelectedAvatar = (engIds: any) => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.FETCHALLSELECTEDAVATAR_REQUEST,
            payload: {},
         });
         const formdata = new FormData();
         formdata.append(`engId`, engIds);
         const res: any = await post("/user/get-all-avatar", formdata);
         dispatch({
            type: types.FETCHALLSELECTEDAVATAR_SUCCESS,
            payload: { fetchedAllAvatar: res.data },
         });
      } catch (error: any) {
         dispatch({
            type: types.FETCHALLSELECTEDAVATAR_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const gmdComputerTeams = () => {
   return async (dispatch: any, getState: any) => {
      try {
         dispatch({
            type: types.USERTEAMSLIST_REQUEST,
            payload: {},
         });

         const rawTeams: any = await get("/user/get-gmd-computer-teams");

         dispatch({
            type: types.USERTEAMSLIST_SUCCESS,
            payload: { userTeamsList: rawTeams },
         });
      } catch (error: any) {
         console.warn(error);
         dispatch({
            type: types.USERTEAMSLIST_FAIL,
            error: error.response?.data?.error,
         });
      }
   };
};

export const updateTeamStatus = (utid: number, status: TeamStatus) => {
   return async (dispatch: any) => {
      try {
         dispatch({ type: types.UPDATE_TEAM_STATUS_REQUEST });
         await post(
            "/user/update-team-status",
            { utid, status },
         );
         dispatch({ type: types.UPDATE_TEAM_STATUS_SUCCESS, payload: { utid, status } });
      } catch (error) {
         dispatch({
            type: types.UPDATE_TEAM_STATUS_FAIL,
            error: "Failed to update team with new status",
         });
      }
   };
};

export const getUserTeams = (userId: number) => {
   return async (dispatch: any) => {
      try {
         dispatch({ type: types.GET_USER_TEAMS_REQUEST });
         const res: { data: UserTeamsList } = await get(`/user/get-avatar-list/${userId}`);
         dispatch({ type: types.GET_USER_TEAMS_SUCCESS, payload: { requestedUserTeams: res.data } });
      } catch (error) {
         dispatch({ type: types.GET_USER_TEAMS_FAIL, error: "Failed to get user teams" });
      }
   };
}

export const getTeamNFTS = (page: number = 1, limit: number = 20, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
   return async (dispatch: any) => {
      try {
         dispatch({ type: types.GET_TEAM_NFTS_REQUEST });
         setLoading && setLoading(true);
         const res = await get<UserTeamsList>(`/user/get-team-nft-list?page=${page}&limit=${limit}`);
         dispatch({ type: types.GET_TEAM_NFTS_SUCCESS, payload: res });
         setLoading && setLoading(false);
      } catch (error) {
         dispatch({ type: types.GET_TEAM_NFTS_FAIL, error: "Failed to get team NFTs" });
      }
   };
};
