import { Modal } from "antd";
import { CreateLeague } from "components/LeagueEnter/CreateElimination/CreateLeague";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedGroupId?: number;
};

export const CreateGroupLeagueModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedGroupId,
}) => {
  return (
    <Modal open={isOpen} onCancel={onClose} footer={null} centered width="">
      <CreateLeague selectedGroupId={selectedGroupId} />
    </Modal>
  );
};
