/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./FranchiseOverviewStyle.scss";
import { Container } from "react-bootstrap";
import FranchiseOverviewTabMain from "./FranchiseOverviewTabMain";
import { useDispatch } from "react-redux";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";
import React, { useEffect } from "react";
import { getCityList } from "../../redux/actions/TeamAction";
import FranchiseHeader from "../Common/FranchiseHeader/FranchiseHeader";
import { useLoading } from "./hooks/use-loading";

const FranchiseOverviewMain: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  
  useEffect(() => {
    dispatch(getCityList());
  }, []);

  return (
    <>
      {!!isLoading && <LoadingScreen />}
      <Container>
        <FranchiseHeader />
        <FranchiseOverviewTabMain />
      </Container>
    </>
  );
};

export default FranchiseOverviewMain;
