import LeagueOverviewLeader from 'components/Common/LeagueOverviewTab/LeagueOverviewLeader';
import { getTeamLeadersForFranchiseOverview } from 'helper/helper';
import { Row, Col } from 'react-bootstrap';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';
import { useSeasonLeaders } from 'screens/Season/hooks/useSeasonLeaders';

export const SeasonLeaders: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();
  const leaders = useSeasonLeaders(seasonInfo?.id);

  const data = getTeamLeadersForFranchiseOverview(
    Object.fromEntries(
      leaders
        .map((l) => {
          const spaceIndex = l.name.indexOf(' ');
          const lastName = l.name.substring(spaceIndex + 1);
          const firstName = l.name.substring(0, spaceIndex);

          const res = {
            ...l,
            lastName,
            firstName,
            avgValue: 0,
            position: l.pos,
            gp: 0,
          };
          
          //@ts-ignore
          delete res.name;
          //@ts-ignore
          delete res.pos;

          return res;
        })
        .map((l, i) => [i, l]),
    ),
  null, true);

  return (
    <div className="leaderlistmain">
        <h5 className="title">LEAGUE LEADERS</h5>
        <Row>
          {data?.map((d, i) => (
            <Col key={i} xl="4">
              <LeagueOverviewLeader {...d} />
            </Col>
          ))}
        </Row>
      </div>
  );
};
