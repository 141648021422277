import { Container } from "react-bootstrap"
import bannerImage from "../../assets/images/homeScreen/2.png";
import "./NewsTemplateStyle.scss";

const NewsTemplateBanner = ({ 
    title,
    subtitle,
    setLoading 
}: {
    title: string,
    subtitle: string,
    setLoading: any
}) => {
  return (
    <>
      <div className="newsTemplateBannerMain">
        <div className="banner">
          <div className="slider">
            <div className="imageWrapper">
              <img src={bannerImage} alt="bannerImage" className="bannerImage" />
              <div className="gradientOverlay"></div>
            </div>
            <div className="bannerInfoMain">
              <Container fluid={"sm"}>
                <div className="bannerInfo">
                  <h2>{title}</h2>
                  <p>{subtitle}</p>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsTemplateBanner