import { WebsocketGetawayResult } from "libs/ws/api.types";
import store from "redux/store";
import { GroupActionTypes } from "../Groups";
import { notify } from "helper/helper";
import { Group, GroupInvitation, UpdateGroupListPayload } from "types/groups";
// import { FriendsActionTypes } from "../Profile"; // TODO: Replace strings with these types, now it causes an error

const ActionMap = {
  getGroup: (response: WebsocketGetawayResult<Group>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_FAIL,
        error: response.message,
      });
    }
  },
  getGroupTrophiesPaginated: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_TROPHIES_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP_TROPHIES,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_TROPHIES_FAIL,
        error: response.message,
      });
    }
  },
  getGroupsToCreateLeague: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUPS_FOR_LEAGUES,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_FAIL,
        error: response.message,
      });
    }
  },
  createGroup: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.CREATE_GROUP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data,
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.CREATE_GROUP_FAIL,
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  joinGroup: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.JOIN_GROUP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data,
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.JOIN_GROUP_FAIL,
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  inviteGroup: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.INVITE_GROUP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data,
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.INVITE_GROUP_FAIL,
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  getGroupsInvitations: (response: WebsocketGetawayResult<GroupInvitation[]>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_INVITATIONS_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP_INVITATIONS,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_INVITATIONS_FAIL,
        error: response.message,
      });
    }
  },
  updateGroupMembership: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data,
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_FAIL,
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  leaveGroup: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success") {
      store.dispatch({
        type: GroupActionTypes.LEAVE_GROUP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data!,
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.LEAVE_GROUP_FAIL,
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  getAdminGroupsList: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_ADMIN_GROUPS_LIST,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_FAIL,
        error: response.message,
      });
    }
  },
  editGroup: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.EDIT_GROUP_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUP,
        payload: response.data!,
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.EDIT_GROUP_FAIL,
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  getGroupList: (response: WebsocketGetawayResult<UpdateGroupListPayload>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_GROUPS_LIST_SUCCESS,
      });
      store.dispatch({
        type: GroupActionTypes.UPDATE_GROUPS_LIST,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_GROUPS_LIST_FAIL,
        error: response.message,
      });
    }
  },
  getFriends: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: "GET_FRIENDS_SUCCESS",
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: "GET_FRIENDS_FAIL",
        error: response.message,
      });
    }
  },
  sendFriendshipRequest: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: "FRIEND_INVITE_SUCCESS",
      });
      store.dispatch({
        type: "UPDATE_FRIENDS",
        payload: {
          friends: response.data.friendsOfSender.friends,
          friendsRequests: response.data.friendsOfSender.friendsRequests,
        },
      });
      notify('success', response.message);
      return;
    }
    
    store.dispatch({
      type: "FRIEND_INVITE_FAIL",
      error: response.message,
    });
    notify('error', response?.message ?? 'No data');
  },
  updateFriendship: (response: WebsocketGetawayResult<any>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: "UPDATE_FRIENDS",
        payload: response.data,
      });
      store.dispatch({
        type: "UPDATE_FRIEND_INVITE_SUCCESS",
      });
      notify('success', response.message);
    }
    if (response.status === "error") {
      store.dispatch({
        type: "UPDATE_FRIEND_INVITE_FAIL",
        error: response.message,
      });
      notify('error', response.message);
    }
  },
  getGroupName: (response: WebsocketGetawayResult<{ name: string, id: number}>) => {
    if (response.status === "success" && response.data) {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_NAME_SUCCESS,
        payload: response.data,
      });
    }
    if (response.status === "error") {
      store.dispatch({
        type: GroupActionTypes.GET_GROUP_NAME_FAIL,
        error: response.message,
      });
    }
  },
};

export const WSResponseHandler = (res: WebsocketGetawayResult<any>) => {
  if (res.event && res.event in ActionMap) {
    ActionMap[res.event as keyof typeof ActionMap](res);
  }
};
