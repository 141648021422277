import { useSeasonInfo } from "screens/Season/hooks/useSeasonInfo";
import { useSeasonPowerRankings } from "./hooks/useSeasonPowerRankings";
import OverviewLeaguePowerrank from "components/Common/LeagueOverviewTab/OverviewLeaguePowerrank";

export const SeasonPowerRankings: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();
  const powerRankings = useSeasonPowerRankings(seasonInfo?.id);

  return (
    <OverviewLeaguePowerrank powerRanking={powerRankings}/>
  )
};
