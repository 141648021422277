import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import FranchiseTeamCreationMain from '../../components/FranchiseTeamCreation/FranchiseTeamCreationMain';

const FranchiseTeamCreation: React.FC = () => (
  <div className="pagelayout">
    <WithLoginNavbar />
    <FranchiseTeamCreationMain />
  </div>
);

export default FranchiseTeamCreation;
