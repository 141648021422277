import Title from "../Common/Title/Title";
import { Table } from "antd";

const Awards = () =>{
    const activeLeaguesColumns = [
        {
            title: "",
            dataIndex: "title",
        },
        {
            title: ".",
            dataIndex: "won",
        },
    ];
    const activeLeaguesData = [
        {
            key: "1",
            title: (
                <>
                  <span>9/22/2021</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <span className="pink">LL1234567</span>
                </>
            ),
            won: "Offensive Player Of The Year",
        },
        {
            key: "2",
            title: (
                <>
                  <span>9/22/2021</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <span className="pink">LL1234567</span>
                </>
            ),
            won: "League MVP",
        },
    ];
    return(
        <>
            <div className="awards_main" >
                <div className="carrerstat_header" >
                    <Title text="AWARDS" />
                </div>
                <div className="carrerstat_table" >
                    <Table
                        columns={activeLeaguesColumns}
                        pagination={false}
                        dataSource={activeLeaguesData}
                        size="small"
                    />
                </div>
            </div>
        </>
    );
}

export default Awards;