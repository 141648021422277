import { Switch } from 'antd';
import "./NotificationStyle.scss";
import { useNotificationSettings } from './hooks/useNotificationSettings';
import { NotificationSettings } from 'types';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import WhiteButton from 'components/Common/Button/WhiteButton/WhiteButton';

const NotificationMain = () => {
    const { 
        notificationSettings, 
        setNotificationSettings, 
        isAnyValueChanged,
        resetSettings,
        updateNotificationSettings,
        loader
    } = useNotificationSettings();

    if (!notificationSettings) {
        return <div/>;
    }

    const updateSetting = (key: keyof NotificationSettings, value: boolean) => {
        setNotificationSettings((prevSettings) => {
            if (!prevSettings) {
                return null;
            }
            const copy = { ...prevSettings };
            copy[key] = value;
            return copy;
        })
    }

    return (
        <div className="notificationsetting" >
            <div className="notificationsettingsection" >
                <h5>A Transaction Has Been Completed</h5>
                <Switch checked={notificationSettings.transactionReciept} onChange={(newValue) => updateSetting('transactionReciept', newValue)}/>
            </div>
            <div className="notificationsettingsection" >
                <h5>Your Leagues Have Ended</h5>
                <Switch checked={notificationSettings.leagueEnd} onChange={(newValue) => updateSetting('leagueEnd', newValue)}/>
            </div>
            <div className="notificationsettingsection" >
                <h5>League Invites</h5>
                <Switch checked={notificationSettings.leagueInvites} onChange={(newValue) => updateSetting('leagueInvites', newValue)} />
            </div>
            <div className="notificationsettingsection" >
                <h5>League Started</h5>
                <Switch checked={notificationSettings.leagueStarted} onChange={(newValue) => updateSetting('leagueStarted', newValue)} />
            </div>
            <div className="notificationsettingsection" >
                <h5>Tournament Round Started</h5>
                <Switch checked={notificationSettings.eliminationRoundStarted} onChange={(newValue) => updateSetting('eliminationRoundStarted', newValue)}/>
            </div>
            {/* ALL THE EMAILS BELOW ARE NOT IMPLEMENTED YET */}
            {/* <div className="notificationsettingsection notificationsettingsection__disabled" >
                <h5>A Draft Is Pending</h5>
                <Switch defaultChecked={false} disabled/>
            </div>
            <div className="notificationsettingsection notificationsettingsection__disabled" >
                <h5>Nfts No Longer Connected</h5>
                <Switch defaultChecked={false} disabled/>
            </div>
            <div className="notificationsettingsection notificationsettingsection__disabled" >
                <h5>Monthly And Annual Performance Summaries</h5>
                <Switch defaultChecked={false} disabled/>
            </div>
            <div className="notificationsettingsection notificationsettingsection__disabled" >
                <h5>General Updates And Announcements</h5>
                <Switch defaultChecked={false} disabled/>
            </div>
            <div className="notificationsettingsection notificationsettingsection__disabled" >
                <h5>My Settings Have Been Updated</h5>
                <Switch defaultChecked={false} disabled />
            </div> */}
            <div className="notificationsettingsection" >
                <h5>Required System Emails (password reset, transaction confirmation, etc)</h5>
                <Switch defaultChecked disabled />
            </div>

            <div className="update-button-container">
                {isAnyValueChanged && (
                    <>
                        <BlueButton text="UPDATE" onClick={updateNotificationSettings} disabled={loader}/>
                        <WhiteButton text="CANCEL" onClick={resetSettings} disabled={loader}/>
                    </>
                )}
            </div>
        </div>
    );
}

export default NotificationMain;