type Props = {
   page: number;
   hasNextPage: boolean;
   hasPreviousPage: boolean;
   setPreviousPage?: () => void;
   setNextPage?: () => void;
};

export const Pagination: React.FC<Props> = ({
   page,
   hasNextPage,
   hasPreviousPage,
   setNextPage,
   setPreviousPage,
}) => (
   <div className="pagination">
      <button onClick={setPreviousPage} disabled={!hasPreviousPage} className="icon">
         <span>{"<"}</span>
      </button>
      <p>PAGE {page}</p>
      <button onClick={setNextPage} disabled={!hasNextPage} className="icon">
         <span>{">"}</span>
      </button>
   </div>
);
