import { LeaderPassing, LeaderReceiving, LeaderRushing } from 'types';
import comments from '../data/comments.json';

export function mapPlayerLeaderTitle(firstName: string, lastName: string) {
  return `${firstName.substring(0, 1)}. ${lastName}`;
}

export function mapPlayerLeaderStatRow<T extends LeaderPassing | LeaderReceiving | LeaderRushing>(type: 'passing' | 'rushing' | 'receiving', stats: T) {
  if (type === 'passing') {
    const stat = stats as LeaderPassing;
    return `${stat.pssCmp || 0}/${stat.pss || 0}, ${stat.pssYds || 0} YDS, ${stat.pssTD || 0} TD, ${stat.pssInt || 0} INT`;
  }

  if (type === 'rushing') {
    const stat = stats as LeaderRushing;
    return `${stat.rus || 0} CAR, ${stat.rusYds || 0} YDS, ${stat.rusTD || 0} TD`;
  }

  if (type === 'receiving') {
    const stat = stats as LeaderReceiving;
    return `${stat.rec || 0} REC, ${stat.recYds || 0} YDS, ${stat.recTD || 0} TD`;
  }

  return '---';
}

export const getRandomComment = (type: keyof typeof comments = 'neutral') => {
  if (!(type in comments)) return null;
  const randomIndex = Math.floor(Math.random() * comments[type].length);
  return comments[type][randomIndex];
};

export const hasChance = (chance: number) => {
  const randomNum = Math.floor(Math.random() * chance);
  return randomNum === 0;
};
