import "./LeagueEliminationStyle.scss";
import { Container } from "react-bootstrap";
import LeagueEliminationTabMain from "./LeagueEliminationTabMain";
import LeagueEliminationHeader from "./LeagueEliminationHeader";

const LeagueOverviewEliminationMain: React.FC = () => (
  <Container>
    <LeagueEliminationHeader />
    <LeagueEliminationTabMain />
  </Container>
);

export default LeagueOverviewEliminationMain;
