export enum WeekPlayersLabels {
  "DeffensiveTackles" = "Deffensive Tackles",
  "Quarterback" = "Quarterback",
  "TightEnd" = "Tight End",
  "Receivers" = "Receivers",
  "Safeties" = "Safeties",
  "Linebacker" = "Linebacker",
  "RunningBacks" = "Running Backs",
  "CornerBacks" = "Corner Backs",
  "Kicker" = "Kicker",
}

export enum PlayersPositions {
  "DL" = "DL",
  "QB" = "QB",
  "TE" = "TE",
  "WR" = "WR",
  "S" = "S",
  "L" = "L",
  "R" = "R",
  "CB" = "CB",
  "RB" = "RB",
  "PK" = "PK",
}

interface Stats {
  defTckSolo: number;
  defSk: number;
  defInt: number;
  recYds: number;
  rec: number;
  recTD: number;
  rusYds: number;
  pssTD: number;
  qbW: number;
  rus: number;
  xp: number;
  xpa: number;
  fgLng: number;
}

export type DefenseStats = Pick<Stats, "defTckSolo" | "defSk" | "defInt">;
export type OffenseStats = Pick<Stats, "recYds" | "rec" | "recTD">;
export type QuarterbackStats = Pick<Stats, "rusYds" | "pssTD" | "qbW">;
export type RunningBacksStats = Pick<Stats, "recYds" | "rus" | "recTD">;
export type KikerStats = Pick<Stats, "xp" | "xpa" | "fgLng">;
export type ComputedStats =
  | DefenseStats
  | OffenseStats
  | QuarterbackStats
  | RunningBacksStats
  | KikerStats;
export type WeekPlayer = {
  pid: number;
  leaderStat: PlayersPositions;
  firstname: string;
  lastName: string;
  team: string;
  computedStats: ComputedStats;
  utid: number;
};

export type WeekPlayerResponse = {
  [WeekPlayersLabels.DeffensiveTackles]?: WeekPlayer;
  [WeekPlayersLabels.Quarterback]?: WeekPlayer;
  [WeekPlayersLabels.TightEnd]?: WeekPlayer;
  [WeekPlayersLabels.Receivers]?: WeekPlayer;
  [WeekPlayersLabels.Safeties]?: WeekPlayer;
  [WeekPlayersLabels.Linebacker]?: WeekPlayer;
  [WeekPlayersLabels.RunningBacks]?: WeekPlayer;
  [WeekPlayersLabels.CornerBacks]?: WeekPlayer;
  [WeekPlayersLabels.Kicker]?: WeekPlayer;
};
