import types from '../actionTypes';
import { API_URL } from 'config';
import { get } from 'redux/services/Api';
import { SeasonScoreWeek } from 'screens/Season/types';

export const getSeasonWeeksWithGames = (seasonId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_WEEKS_WITH_GAMES_REQUEST,
        payload: {},
      });
      const seasonWeeksWithGames = await get<SeasonScoreWeek[]>(
        `${API_URL}/engine/leagues/seasons/season/${seasonId}/weeksWithGames`,
      );
      dispatch({
        type: types.GET_SEASON_WEEKS_WITH_GAMES_SUCCESS,
        payload: { seasonWeeksWithGames },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_WEEKS_WITH_GAMES_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
}
