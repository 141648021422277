import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamNFTS } from 'redux/actions/TeamAction';
import { RootState } from 'redux/store';

export const useTeamNFTS = (page: number = 1, limit: number = 20) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const nfts = useSelector((state: RootState) => state.team.requestedUserTeams);

  useEffect(() => {
    console.log('useTeamNFTS');
    dispatch(getTeamNFTS(page, limit, setLoading));
  }, [page, limit]);

  return { nfts, loading };
};
