import { API_URL } from "config";

export const emojiPartualPaths = [
  "Embarrassing-emote.png",
  "Holding-cup-emote.png",
  "Holding-sign-emote.png",
  "Kissing-emote.png",
  "Thinking-emote.png",
  "Yawning-emote.png",
  "Angry-emote.png",
  "Crying-emote.png",
  "Laugh-emote.png",
  "One-thumbs-up-emote.png",
  "Sweat-emote.png",
  "Two-thumbs-up-emote.png",

];

export type EmojiPath =
  | "Embarrassing-emote.png"
  | "Holding-cup-emote.png"
  | "Holding-sign-emote.png"
  | "Kissing-emote.png"
  | "Thinking-emote.png"
  | "Yawning-emote.png"
  | "Angry-emote.png"
  | "Crying-emote.png"
  | "Laugh-emote.png"
  | "One-thumbs-up-emote.png"
  | "Sweat-emote.png"
  | "Two-thumbs-up-emote.png";

const possibleResolutions = [48, 500, 1000, 3000];

type Props = {
  path: EmojiPath;
  dimensions?: {
    width: string;
    height: string;
  };
};

export const Emoji: React.FC<Props> = ({ path, dimensions }) => {
  return (
    <img
      style={dimensions ? dimensions : { }}
      srcSet={possibleResolutions
        .map((res) => `${API_URL}/images/emojis/${res}px/${path} ${res}w`)
        .join(", ")}
      alt={path}
    ></img>
  );
};
