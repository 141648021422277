import Error from "../../../../assets/images/common/error.svg";

const ActiveRosterModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Player promotion to active roster is not allowed. The
                active roster must be under 53 players to complete
                move. Demote or release a player prior to making a
                promotion.</p>
            </div>  
        </>
    );
}

export default ActiveRosterModal;