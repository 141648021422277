import Error from "../../../../assets/images/common/error.svg";

const PromoteModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Player has been promoted to active squad. (would be great 
                if pop-up would automatically disappear after 5 seconds). 
                If there is no room on the active roster a pop-up on 
                screen 32 will be displayed.
                </p>
            </div>  
        </>
    );
}

export default PromoteModal;