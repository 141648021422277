import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import PlayerDetailMain from "../../components/PlayerDetail/PlayerDetailMain";

const PlayerDetailScreen: React.FC = () => (
    <div className="pagelayout" >
        <WithLoginNavbar />
        <PlayerDetailMain />
    </div>
);

export default PlayerDetailScreen;