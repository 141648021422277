import { LobbyListItemType } from "./types";
import "./LobbyListItem.scss";
import history from "routes/history";

type Props = LobbyListItemType & { squareImage?: boolean };

export const LobbyListItem: React.FC<Props> = ({
  image,
  name,
  text,
  rightNumber,
  squareImage,
  id
}) => {
  return (
    <div className="lobby_list_item">
      <img
        src={image}
        alt="User Avatar"
        className={squareImage ? "square-avatar" : undefined}
        onClick={() => {
          if (squareImage) {
            history.push(`/group/${id}`);
          } else {
            history.push(`/profile/${id}`);
          }
        }}
      />
      <div className="lobby_list_item__right">
        <div className="lobby_list_item__right__start">
          <h6>{name}</h6>
          <span>{text}</span>
        </div>
        <span>{rightNumber}</span>
      </div>
    </div>
  );
};
