import { memo } from "react";
import "./Scoreboard.scss";

interface ScoreDigitsProps {
  twoDigits?: number | string;
  size?: keyof typeof DIGIT_SIZES_CLASSES;
};

export const ScoreDigits: React.FC<ScoreDigitsProps> = memo(({ twoDigits, size }) => {
  let digitsString = ""
  if (twoDigits == null) {
    digitsString = "  ";
  } else if (typeof twoDigits === 'string') {
    digitsString = twoDigits.padStart(2, ' ');
  } else {
    digitsString = Math.trunc(twoDigits).toString().padStart(2, ' ');
  }
  return (
    <div className="scoreboard__numbers">
      <ScoreDigit digit={digitsString[0]} size={size}/>
      <ScoreDigit digit={digitsString[1]} size={size}/>
    </div>
  )
});

const DIGIT_SIZES_CLASSES = {
  little: 'scoreboard__numbers_little',
  small: 'scoreboard__numbers_small',
  middle: 'scoreboard__numbers_middle',
  large: 'scoreboard__numbers_large'
} as const

interface ScoreDigitProps {
  digit?: number | string;
  size?: keyof typeof DIGIT_SIZES_CLASSES;
};

export const ScoreDigit: React.FC<ScoreDigitProps> = memo(({ digit, size ='middle' }) => {

  return (
    <div className={`scoreboard__digit_container ${DIGIT_SIZES_CLASSES[size]}`}>
      <span className="scoreboard__digit">
        {digit}
      </span>
      <span className="scoreboard__digit scoreboard__digit_blank">
        8
      </span>
    </div>
  )
});
