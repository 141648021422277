import FranchiseDetail from "../Common/FranchiseDetail/FranchiseDetail";
import Stickers from "../../assets/images/league/leagueImage.jpg";

const PerformanceHeader = () =>{
    const data1 = [
        {
          id: 1,
          profileimage: Stickers,
          protfolioname: "---",
          usertitle: "POSITION :",
          username: "---",
        //   currentleaguetitle: "STRENGTH :",
        //   currentleague: "---",
          joinedtitle: "HT/WT :",
          joined: "---",
        //   franchisestitle: "SPEED :",
        //   franchises: "---",
          wallettitle: "COLLEGE :",
          wallet: "---",
        //   mintedplayertitle: "EXPLOSIVENESS :",
        //   mintedplayer: "---",
          emailtite: "HIRED",
          email: "---",
        //   fundsplaytitle: "AGILITY",
        //   fundsplay: "---",
          winning: "WINNING %",
          winningdigit: "---",
          win: "OFFENSE",
          windigit: "---",
          winpercentage: "DEFENSE",
          winpercentagedigit: "---",
          champs: "SPEC TEAM",
          champsdigit: "---",
          rating: "RATING",
          ratingdigit: "---",
        },
    ];
    return(
        <>
            {data1.map((d: any, i: any) => (
                <FranchiseDetail specialskill rating arrow arrow1 arrow2 arrow3 data={d} />
            ))}
        </>
    );
}

export default PerformanceHeader;