import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/AuthAction";
import { Button } from "antd";
import Logout1 from "../../../assets/images/common/logout.svg";
import "./LogoutStyle.scss";

const Logout = () => {
  const dispatch = useDispatch();
  const handelLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      <Button type="link" onClick={handelLogout} className="logouticon"  >
        <img src={Logout1} alt="logout" />
      </Button>
    </>
  );
};

export default Logout;
