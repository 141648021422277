import { useState } from "react";
import "./LearnMoreStyle.scss";
import LearnMoreBanner from "./LearnMoreBanner";
import LearnMoreContent from "./LearnMoreContent";
import NavbarWithOutLogin from "components/layout/WithOutLoginNavbar/NavbarWithOutLogin";

const LearnMoreScreen = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <NavbarWithOutLogin login setLoading={setLoading} />
            <LearnMoreBanner setLoading={setLoading} />
            <LearnMoreContent setLoading={setLoading} />
        </>
    )
}

export default LearnMoreScreen;