/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import Title from '../Common/Title/Title';
import Dropdown from '../Common/Form/Dropdown/Dropdown';
import WhiteButton from '../Common/Button/WhiteButton/WhiteButton';
import { Row, Col } from 'react-bootstrap';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Error from '../../assets/images/common/error.svg';
import LeagueAvailableList from './LeagueAvailableList';
import _ from 'lodash';
import { Modal } from 'antd';
import { addLeagueEnterFormData } from '../../redux/actions/TeamAction';
import { SelectFranchise } from '../../redux/actions/GameEngineAction';
import history from 'routes/history';
import HigherTeamRatingModal from './HigherTeamRatingModal';
import BlueButton from '../Common/Button/BlueButton/BlueButton';
import { getPrizePool, isGameStartingInLessThan30Minutes, isTextContainsBadWord } from 'helper/helper';
import { useEnterGame } from './hooks/useEnterGame';
import { useFeeRates } from 'helper/useFeeRates';
import { CreateEliminationModal } from './modals/CreateEliminationModal';
import { useAllGamesData } from 'helper/useAllGamesData';
import { League } from 'components/Common/LeagueHeader/LeagueHeader';
import { useLocation } from 'react-router-dom';
import { getFullTeamName } from './helper';
import { ActiveGame } from 'types';
import { useGameData } from 'helper/useGameData';

const gameFee = [
  {
    id: 1,
    option: 'Select Game Fee',
  },
  {
    id: 2,
    option: 'FREE GAME',
    value: 0,
  },
  {
    id: 3,
    option: '$5',
    value: 5,
  },
  {
    id: 4,
    option: '$10',
    value: 10,
  },
  {
    id: 5,
    option: '$50',
    value: 50,
  },
  {
    id: 6,
    option: '$11 to $50',
    value: '11-50',
  },
  {
    id: 7,
    option: '$51 to $250',
    value: '51-250',
  },
  {
    id: 8,
    option: 'Over 250',
    value: '251-10000000',
  },
];
const gameType = [
  {
    id: 1,
    option: 'Select Game Type',
  },
  {
    id: 2,
    option: 'Head-to-Head',
    value: 'H',
  },
  {
    id: 3,
    option: 'Elimination',
    value: 'E',
  },
  {
    id: 4,
    option: 'Season',
    value: 'S',
  },
];
const gameLevel = [
  {
    id: 1,
    option: 'Select Game Level',
  },
  {
    id: 2,
    option: 'Under 55',
    value: '0-55',
  },
  {
    id: 3,
    option: '56 to 65',
    value: '56-65',
  },
  {
    id: 4,
    option: '66 to 75',
    value: '66-75',
  },
  {
    id: 5,
    option: '76 to 85',
    value: '76-85',
  },
  {
    id: 6,
    option: 'Over 86',
    value: '86-100',
  },
];

const LeagueEnterNewForm: React.FC = () => {
  const { WALLET_TRANSACTION_FEE } = useFeeRates();
  const [isCreateEliminationOpen, setIsCreateEliminationOpen] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const leagueEnterFormData = useSelector((state: RootState) => state?.team?.leagueEnterFormData);

  const userTeamListData = useSelector((state: RootState) => state?.team?.userTeamsList);

  const TeamRating = useSelector((state: RootState) => state?.gameEngine?.SelectFranchise?.ovr);
  const UserLeagueData = useAllGamesData();

  const gamesWithUserTeams = useSelector((state: RootState) => state.gameEngine.TeamActiveGames);

  const allAvailablePlayingTeams: number[] = useMemo(
    () =>
      //@ts-ignore
      gamesWithUserTeams.reduce((acc: any[], game: ActiveGame) => {
        if (
          game?.teams &&
          isGameStartingInLessThan30Minutes(game?.startAt) &&
          (game?.status === 'waitingForStart' || game?.status === 'waitingForPlayers' || game?.status === 'pending')
        ) {
          acc.push(...game?.teams);
        }

        return acc;
      }, []),
    [gamesWithUserTeams],
  );

  const alreadyBusyTeams = useMemo(
    () =>
      userTeamListData
        ?.map((team) => team.engId)
        ?.filter(Boolean)
        ?.filter((id) => allAvailablePlayingTeams.includes(id)),
    [allAvailablePlayingTeams, userTeamListData],
  );

  const route = new URLSearchParams(window?.location?.search);
  const [isInvitation, setIsInvitation] = useState<boolean>(false);

  const JOINCODEWITHTYPE = route.get('joincode');
  const JOINCODE = JOINCODEWITHTYPE ? JOINCODEWITHTYPE.split('-')[1] : '';
  const type = (JOINCODEWITHTYPE ? JOINCODEWITHTYPE.split('-')[0] : '') as League['type'] | '';
  const gid = route.get('gid');

  useEffect(() => {
    if (JOINCODE && gid && type !== '') {
      setenterCode(JOINCODE);
      setReadOnly(true);
      setleagueLable('JOIN LEAGUE');
      setIsInvitation(true);
    }
  }, []);
  const selectedLeaguesData = useGameData(gid, type);

  const data = JOINCODE ? [selectedLeaguesData.data] : UserLeagueData;
  //MODAL 1
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // MODAL 2
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  // MODAL 3
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const showModal3 = () => {
    setIsModalVisible3(true);
  };
  const handleOk3 = () => {
    setIsModalVisible3(false);
  };
  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };

  const leagueprice = data && data[0] && data[0].fee;
  const payoutPct = data && data[0] && data[0].payoutPct ? data[0].payoutPct : WALLET_TRANSACTION_FEE ? 100 - WALLET_TRANSACTION_FEE : 0;
  const prizePool = leagueprice && getPrizePool(leagueprice, payoutPct, data?.[0]?.numberOfTeams);



  const { joinGame } = useEnterGame({
    gid: gid ? Number.isNaN(+gid) ? gid : +gid : undefined,
    engId: leagueEnterFormData?.franchise?.engId,
    type: type !== "" ? type : undefined,
    joinCode: JOINCODE,
  });

  // join the league
  const handleJoinLeague = () => {
    const minRating = data?.map((rating: any) => {
      return rating?.ratingRangeMin;
    });
    const maxRating = data?.map((rating: any) => {
      return rating?.ratingRangeMax;
    });
    const franchiseRating = leagueEnterFormData?.franchise?.teamRating;

    const joinGameCondition =
      minRating?.[0] < franchiseRating &&
      franchiseRating < maxRating?.[0] &&
      leagueprice !== undefined &&
      leagueprice !== null &&
      prizePool !== null &&
      prizePool !== undefined;

    const skipLeaguePayment = joinGameCondition && leagueprice === 0 && type === "H";

    if (!franchiseRating) {
      showModal3();
    } else if (!skipLeaguePayment) {
      history.push('/leaguepayment', {
        ...data?.[0],
        gid,
        type,
        franchise: leagueEnterFormData?.franchise,
        isInvitation,
        leagueprice,
        prizePool,
        JOINCODE,
      });
    } else if (skipLeaguePayment) {
      joinGame();
    } else if (franchiseRating && franchiseRating < minRating?.[0]) {
      showModal1();
    } else if (franchiseRating && franchiseRating > maxRating?.[0]) {
      showModal();
    }
  };

  let teamList: any[] =
    userTeamListData
      ?.filter((data: any) => !alreadyBusyTeams?.includes(data.engId))
      ?.map((data: any, index: number) => {
        let info;
        if (data?.helmet && (data?.approval || !(data?.teamName && isTextContainsBadWord(data?.teamName)))) {
          const fullName = data?.jsonFile?.slice(0, data?.jsonFile?.lastIndexOf('.'))?.replaceAll('_', ' ');
          info = { id: index + 2, option: fullName, utid: data.engId, status: data.status };
        }
        return info;
      })
      ?.filter((data: any) => data?.option !== undefined)
      ?.sort((a: any, b: any) => a?.option.localeCompare(b?.option)) || [];

  const queryTeamId = new URLSearchParams(window.location.search).get('team');
  const queryTeam = queryTeamId && userTeamListData?.find((team: any) => team?.id === +queryTeamId);
  if (queryTeam) {
    teamList = teamList.filter((team) => team?.utid !== queryTeam?.engId);
    teamList.unshift({ ...queryTeam, id: 1, option: getFullTeamName(queryTeam?.jsonFile) });
  } else {
    const defaultTeam = teamList.find((team) => Boolean(team?.status)) ?? null;
    if (Boolean(defaultTeam)) {
      teamList = teamList?.filter((team) => team.utid !== defaultTeam.utid);
      teamList.unshift({ ...defaultTeam, id: 1 });
    } else {
      teamList?.unshift({
        id: 1,
        option: 'Select Game Franchise',
      });
    }
  }

  const [selectFranchise, setSelectFranchise] = useState<any>();
  const [selectFee, setSelectFee] = useState<any>();
  const [selectType, setSelectType] = useState<any>();
  const [selectLevel, setSelectLevel] = useState<any>();
  const [enterCode, setenterCode] = useState<any>();
  const [ReadOnly, setReadOnly] = useState<any>(false);
  const [leagueLable, setleagueLable] = useState<any>('LEAGUE INVITE');
  const [filterValue, setfilterValue] = useState<any>('');
  const [allAvailableLeagues, setAllAvailableLeagues] = useState<any>([]);
  const [filterLeagueData, setFilterLeagueData] = useState<any>([...allAvailableLeagues]);

  useEffect(() => {
    if ((!allAvailableLeagues || allAvailableLeagues?.length === 0) && selectFranchise) {
      setfilterValue('There are no leagues/games.');
      return;
    }
    const filteredLeagues = allAvailableLeagues.filter((data: any) => !!data);

    const filteredByFee = selectFee
      ? filteredLeagues.filter((info: any) => {
          if (selectFee == '11-50' || selectFee == '51-250' || selectFee == '251-10000000') {
            const min = parseInt(selectFee.split('-')[0]);
            const max = parseInt(selectFee.split('-')[1]);
            const check: any = min <= info?.fee && info?.fee <= max;
            return check;
          }

          return info?.fee == selectFee;
        })
      : filteredLeagues;

    const filteredByType = selectType ? filteredByFee.filter((info: any) => info?.type == selectType) : filteredByFee;

    const filteredByLevel = selectLevel
      ? filteredByType.filter((info: any) => {
          const min = parseInt(selectLevel.split('-')[0]);
          const max = parseInt(selectLevel.split('-')[1]);

          const check: any = (info.ratingRangeMin >= min && info.ratingRangeMin <= max) || (info.ratingRangeMax >= min && info.ratingRangeMax <= max);

          return check;
        })
      : filteredByType;

    setFilterLeagueData(filteredByLevel);

    if (filteredByLevel.length === 0 && selectFranchise && allAvailableLeagues && allAvailableLeagues.length) {
      setfilterValue("There are no leagues/games available that match your franchise's rating. Please select another franchise.");
      return;
    }
  }, [selectFee, selectType, selectLevel, allAvailableLeagues, gamesWithUserTeams, selectFranchise]);

  const selectFeeChanged = (e: any) => {
    if (e.target.value === 'Select Game Fee') {
      setSelectFee(null);
      return;
    }
    setSelectFee(e.target.value);
  };

  const selectTypeChanged = (e: any) => {
    if (e.target.value === 'Select Game Type') {
      setSelectType(null);
      return;
    }
    setSelectType(e.target.value);
  };

  const selectLevelChanged = (e: any) => {
    if (e.target.value === 'Select Game Level') {
      setSelectLevel(null);
      return;
    }

    setSelectLevel(e.target.value);
  };

  const selectFranchiseChanged = async (e: any) => {
    if (e?.target?.value === 'Select Game Franchise') {
      setAllAvailableLeagues([]);
      setFilterLeagueData([]);
      setfilterValue('');
      setSelectFranchise(null);
      return;
    }

    const info = userTeamListData?.find((data: any) => {
      const fullName = getFullTeamName(data?.jsonFile);

      return fullName === e?.target?.value;
    });

    if (info?.engId !== undefined) {
      dispatch(SelectFranchise(info?.engId));
    }
    setSelectFranchise(info);
  };

  useEffect(() => {
    if (location.search.includes('team')) {
      const teamId = location.search.split('=')[1];
      const team = userTeamListData?.find((team: any) => +team.id === +teamId);
      if (team) {
        setSelectFranchise(team);
        dispatch(SelectFranchise(team.engId));
        return;
      }
    }
    const defaultTeam = userTeamListData?.filter((team: any) => !alreadyBusyTeams?.includes(team.engId))?.find((team: any) => Boolean(team?.status));

    if (!defaultTeam) return;
    setSelectFranchise(defaultTeam);
    dispatch(SelectFranchise(defaultTeam.engId));
  }, [userTeamListData, location.search]);

  // handle franchise changed
  useEffect(() => {
    if (selectFranchise && selectFranchise.engId) {
      setfilterValue('');

      const teamRating = TeamRating ?? Number(selectFranchise?.teamRating);

      const availableLeagues = UserLeagueData.map((info: any, index: number) => {
        let payoutPct;
        if (info && info.type && info.type === 'E') {
          payoutPct = WALLET_TRANSACTION_FEE ? 100 - WALLET_TRANSACTION_FEE : 0;
        } else {
          payoutPct = info?.payoutPct;
        }

        return {
          ...info,
          id: index + 1,
          leagueprice: info?.fee,
          leaguename: info?.name,
          teamdigit: info?.teams?.length,
          prizePool: getPrizePool(info?.fee, payoutPct, info?.numberOfTeams),
          teamrating: `${info?.ratingRangeMin} TO ${info?.ratingRangeMax}`,
          teamid: '---',
          type: info?.type,
        };
      }).filter((rating?: any) => rating?.ratingRangeMin <= teamRating && rating?.ratingRangeMax >= teamRating);

      if (!JOINCODE) {
        setAllAvailableLeagues([
          {
            type: 'E',
            fee: 'YOUR CHOICE',
            name: 'CUSTOM GAME',
            handleCreate: () => setIsCreateEliminationOpen(true),
            startAt: new Date(),
          },
          ...availableLeagues,
        ]);
      }
    }
  }, [UserLeagueData, TeamRating, JOINCODE, selectFranchise]);

  useEffect(() => {
    dispatch(
      addLeagueEnterFormData({
        fee: selectFee,
        type: selectType,
        level: selectLevel,
        franchise: selectFranchise,
      }),
    );
  }, [selectFee, selectType, selectLevel, selectLevel, selectFranchise]);

  return (
    <>
      <div className="leagueNewForm">
        <Title text="ENTER NEW LEAGUE" />
        <Row className="franchiseDropdown">
          <Col xl={4} lg={4}>
            <Dropdown label="Select Franchise" dropDownOptions={teamList} onChange={selectFranchiseChanged} value={getFullTeamName(selectFranchise?.jsonFile)} />
          </Col>
        </Row>
        <h5 className="selectLeague">Select League</h5>
        <Row>
          <Col xl={4} lg={4} md={4} className="filterdropdown mb-lg-0 mb-sm-3 mb-md-3 mb-xs-3">
            <Dropdown id="select-game-fee" dropDownOptions={gameFee} onChange={selectFeeChanged} />
          </Col>
          <Col xl={4} lg={4} md={4} className="filterdropdown mb-lg-0 mb-sm-3 mb-md-3 mb-xs-3">
            <Dropdown id="select-game-type" dropDownOptions={gameType} onChange={selectTypeChanged} />
          </Col>
          <Col xl={4} lg={4} md={4} className="filterdropdown">
            <Dropdown id="select-game-level" dropDownOptions={gameLevel} onChange={selectLevelChanged} />
          </Col>
        </Row>
        <h6 className="filterResults mt-4">Filter or Selected Results</h6>
        {filterLeagueData?.length !== 0 ? (
          filterLeagueData?.sort((d1: any, d2: any) => d2?.teams?.length - d1?.teams?.length)?.map((d: any, i: any) => <LeagueAvailableList key={i} data={d} />)
        ) : (
          <h5 style={{ color: 'red' }}>{filterValue}</h5>
        )}
        <Divider />
        <Row>
          <div className="leagueInvite">
            <input type="search" placeholder="Enter Code" value={enterCode} readOnly={ReadOnly} />
            <label style={{ cursor: 'pointer' }} onClick={handleJoinLeague}>
              {leagueLable}
            </label>
          </div>
        </Row>
        <Divider />
      </div>
      {/* MODAL 3 */}
      <Modal
        title="Basic Modal"
        className="draftcommon leaguerating"
        open={isModalVisible3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        footer={[<WhiteButton onClick={handleCancel3} text="OK" />]}
        centered
      >
        <div className="startdraftmain">
          <img src={Error} alt="erroricon" />
          <p> Please Select Franchise</p>
        </div>
      </Modal>
      {/* MODAL 2 */}
      <Modal
        title="Basic Modal"
        className="draftcommon leaguerating"
        open={isModalVisible1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[<WhiteButton onClick={handleCancel} text="CANCEL" />, <BlueButton onClick={handleOk1} text="JOIN" />]}
        centered
      >
        <HigherTeamRatingModal />
      </Modal>

      {/* MODAL 3 */}
      <Modal
        title="Basic Modal"
        className="draftcommon leaguerating"
        open={isModalVisible3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        footer={[<WhiteButton onClick={handleCancel3} text="OK" />]}
        centered
      >
        <div className="startdraftmain">
          <img src={Error} alt="erroricon" />
          <p> Please Select Franchise</p>
        </div>
      </Modal>
      <CreateEliminationModal isOpen={isCreateEliminationOpen} onClose={() => setIsCreateEliminationOpen(false)} />
    </>
  );
};

export default LeagueEnterNewForm;
