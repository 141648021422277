import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { Pagination } from "./common";
import { useGroupsList } from "../hooks/groups";
import { GroupListItem } from "../../Group";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const Groups = ({ profileId, isOwn }: { profileId: number, isOwn: boolean }) => {
  const history = useHistory();
  const { groupsList, groupsInvitations, handleUpdatePage } = useGroupsList(profileId);
  const handleRouteToCreateGroup = () => {
    history.push("/group-create");
  };
  const handleRedirectToInvitations = () => {
    history.push(`/profile-invitations/${profileId}`);
  };
  if (!groupsList) return null;

  return (
    <div className="groups section">
      <div className="section__header">
        <h5>GROUPS</h5>
        <div className="right">
          {!!groupsInvitations?.length && isOwn && (
            <PinkButton
              onClick={handleRedirectToInvitations}
              text={`INVITATIONS (${groupsInvitations?.length || 0})`}
            />
          )}
          {isOwn && <PinkButton text="CREATE GROUP" onClick={handleRouteToCreateGroup} />}
          <Pagination
            page={1}
            hasNextPage={!!groupsList.nextPage}
            hasPreviousPage={!!groupsList.prevPage}
            setNextPage={
              groupsList.nextPage
                ? () => handleUpdatePage(groupsList.nextPage!)
                : undefined
            }
            setPreviousPage={
              groupsList.prevPage
                ? () => handleUpdatePage(groupsList.prevPage!)
                : undefined
            }
          />
        </div>
      </div>
      <div className="section__body">
        <Row>
          {groupsList && groupsList.data && groupsList.data.length > 0 ? (
            groupsList.data.map((item) => (
              <Col key={item.id} xs={6} sm={3} md={2}>
                <GroupListItem key={item.id} data={item} />
              </Col>
            ))
          ) : (
            <h5>No groups</h5>
          )}
        </Row>
      </div>
    </div>
  );
};
