import { League } from 'components/Common/LeagueHeader/LeagueHeader';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameByIdAndType } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';

export const useGameData = (gid?: number | string | null, type?: League['type'] | '') => {
  const dispatch = useDispatch();

  const data: League | undefined = useSelector((state: RootState) => state.gameEngine.GameByIdAPI);

  const loadData = useCallback(() => {
    if (gid && type?.length) {
      dispatch(getGameByIdAndType(gid, type as League['type']));
    }
  }, [gid, type]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return { data, loadData };
};
