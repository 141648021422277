import logo from "../../assets/images/news/logo.png";

const NewsHeader: React.FC = () => (
  <div className="franchiseMain newsMain">
    <div className="newscontent">
      <div className="newssubcontent">
        <h6>Press Contact :</h6>
        <p>GM Dynasty</p>
        <p>Barry Resnik, 612-600-8862</p>
        <p>admin@gmdynasty.com</p>
      </div>
      <img src={logo} alt="logo" />
    </div>
  </div>
);

export default NewsHeader;
