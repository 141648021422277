/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LeagueStandingStyle.scss";
import { Tab, Nav } from "react-bootstrap";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { useDispatch } from "react-redux";
import {
  getLeagueWeeklyScores,
  PregameOverview,
} from "./../../redux/actions/GameEngineAction";
import { useEffect } from "react";
import history from "../../routes/history";
import LeagueScoreTabByGid from "../Common/LeagueScoreTab/LeagueScoreByGid";
import LeagueOverviewMain from "components/LeagueHeadToHead/TabDetail/OverviewTab/LeagueOverviewMain";
import LeagueOverviewRating from "components/LeagueHeadToHead/TabDetail/OverviewTab/LeagueOverviewRating";
import LeagueScoreTabMain from "components/Common/LeagueScoreTab/LeagueScoreTabMain";
import ScheduleTabMain from "components/LeagueOverviewElimination/TabDetail/ScheduleTab/ScheduleTabMain";
import { useIsEliminationRound } from "./hooks/useIsEliminationRound";

const LeagueStandingTabMain: React.FC = () => {
  const dispatch = useDispatch();
  const data = history.location.state as { gid?: number };
  const gid = data?.gid;

  const {
    eliminationId,
    leagueType
  } = useIsEliminationRound(gid);

  const handleOverview = () => {
    if (!gid) {
      const currentValues = getLeagueWeeklyScores(2);
      dispatch(currentValues);
    }
  };

  useEffect(() => {
    if (gid) {
      dispatch(PregameOverview(gid));
    }
  }, [gid]);

  if (!gid) {
    return null;
  }

  return (
    <div className="standingTabbarMain mb-4">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={!data?.gid ? "third" : "five"}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div className="standingTabbar">
          <Card>
            <CardBody>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={() => handleOverview()}>
                    OVERVIEW
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="five">SCORES</Nav.Link>
                </Nav.Item>
                {leagueType === "E" && (
                  <Nav.Item>
                    <Nav.Link eventKey="four">SCHEDULE</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </CardBody>
          </Card>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <>
              <LeagueOverviewRating gid={gid} />
              <LeagueOverviewMain />
            </>
          </Tab.Pane>
          {eliminationId && (<Tab.Pane eventKey="four">
            <ScheduleTabMain gid={eliminationId} />
          </Tab.Pane>)}
          <Tab.Pane eventKey="five">
            <LeagueScoreTabByGid />
            <LeagueScoreTabMain />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default LeagueStandingTabMain;
