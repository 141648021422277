import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import Dropdown from 'components/Common/Form/Dropdown/Dropdown';
import { useProgressionDropdown } from 'components/PlayerDetail/hooks/useProgressionDropdown';
import PlayerCareerRating from 'components/PlayerDetail/PlayerCareerRating';
import { getAverageRatingIncrement } from 'components/PlayerDetail/utils/getAverageRatingIncrement';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamLeaguesHistory } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';
import { AdvancedProgressionStats } from './AdvancedProgressionStats';

type Props = {
  engId?: number;
};

export const ProgressionTab: React.FC<Props> = ({ engId }) => {
  const dispatch = useDispatch();
  const teamData = useSelector((state: RootState) => state.gameEngine.teamLeaguesHistory);
  const playerMappedRatingHistory =
    teamData?.ratingHistory?.map((item: { rating: number; date: Date }) => ({
      date: new Date(item.date),
      value: item.rating,
    })) || [];
  const { selectedTimeline, timelineOptions, handleSelectTimeline, ratingsHistory } = useProgressionDropdown(playerMappedRatingHistory);

  const ratingHighPoint = Math.max(...playerMappedRatingHistory.map((record: any) => record?.value));
  const ratingLowPoint = Math.min(...playerMappedRatingHistory.map((record: any) => record?.value));
  const averageIncrease = getAverageRatingIncrement(playerMappedRatingHistory);

  useEffect(() => {
    engId && dispatch(getTeamLeaguesHistory(engId));
  }, [engId]);

  if (!Boolean(playerMappedRatingHistory?.length)) {
    return (
      <Card>
        <CardBody>
          <div className="playerDetailTab">
            <div className="tab__section">
              <h5 className="tab__section_heading">NO RATING HISTORY</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="playerDetailTab">
          <div className="tab__section">
            <h5 className="tab__section_heading">TEAM RATING PROGRESSION</h5>
            <Row className="franchiseDropdown rating-range__selector">
              <Col xl={4} lg={4}>
                <Dropdown value={selectedTimeline} dropDownOptions={timelineOptions} onChange={handleSelectTimeline} />
              </Col>
            </Row>
            <PlayerCareerRating ratingHistory={ratingsHistory} />
          </div>
          <div className="tab__section">
            <h5 className="tab__section_heading">PROGRESSION ADVANCED STATS</h5>
            <AdvancedProgressionStats
              rankings={{ currentRanking: teamData?.ranking }}
              ratingHighPoint={ratingHighPoint}
              ratingLowPoint={ratingLowPoint}
              averageRatingIncrease={averageIncrease}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
