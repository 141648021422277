import { useEffect, useState } from "react";
import { LobbyGroup, getLobbyGroups } from "../helper";

export const useLobbyGroups = (page: number, limit: number) => {
  const [lobbyGroups, setLobbyGroups] = useState<LobbyGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noNextPage, setNoNextPage] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLobbyGroups(page, limit)
      .then((lobbyGroupsRes) => {
        if (lobbyGroupsRes.length === 0) {
          setNoNextPage(true);
        } else {
          setNoNextPage(false);
        }
        setLobbyGroups((prev) => {
          const listCopy = [...prev];
          for (const group of lobbyGroupsRes) {
            if (!prev.some((g) => group.name === g.name)) listCopy.push(group);
          }
          return listCopy;
        });
      })
      .catch((e) => {})
      .finally(() => setIsLoading(false));
  }, [page, limit]);

  return { lobbyGroups, isLoading, noNextPage };
};
