import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';
import { PlayoffInfo } from './types';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import history from 'routes/history';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import cup from '../../../../../../assets/images/leagueElimination/winnercup.svg';

type Props = {
  superBowl: PlayoffInfo['superBowl'];
};

export const SuperBowl: React.FC<Props> = ({ superBowl }) => {
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);

  if (!superBowl) return null;

  if (superBowl instanceof Date || String(new Date(superBowl as unknown as string | Date)) !== "Invalid Date" ) return (
    <div className="playoff-info__super-bowl">
      <div className="playoff-info__super-bowl__top-info">
        <h5>Super Bowl</h5>
      </div>
      <h6>START AT: {new Date(superBowl as Date).toLocaleString()} {Intl.DateTimeFormat().resolvedOptions().timeZone}</h6>
    </div>
  );

  const team1Helmet = superBowl.helmets?.find((h) => h.engId === superBowl.teams[0]);
  const team2Helmet = superBowl.helmets?.find((h) => h.engId === superBowl.teams[1]);

  if (!team1Helmet || !team2Helmet) return null;

  const handleRedirection = () => {
    const isUserTeam = userTeams?.find((t) => t.engId === superBowl.teams[0] || t.engId === superBowl.teams[1]);

    if (isUserTeam && superBowl.status === 'pregame') {
      history.push('/leagueheadtohead', superBowl.gid);
    } else if (['pregame', 'inProgress'].includes(superBowl.status)) {
      history.push(`/gameplay?id=${superBowl.gid}`);
    } else {
      history.push(`/game-results/${superBowl.gid}`);
    }
  };

  const label = superBowl.status === 'complete' ? 'RESULTS' : 'WATCH';

  const winner = superBowl.score && superBowl.status === 'complete' ? +Object.entries(superBowl.score).sort(([id1, pts1], [id2, pts2]) => pts2 - pts1)[0][0] : null;

  return (
    <div className="playoff-info__super-bowl">
      <div className="playoff-info__super-bowl__top-info">
        {team1Helmet.engId === winner ? <img src={cup} alt="cup" /> : <div />}
        <h5>Super Bowl</h5>
        {team2Helmet.engId === winner ? <img src={cup} alt="cup" /> : <div />}
      </div>
      <div className="playoff-info__super-bowl__teams">
        <RenderHelmet {...team1Helmet} size={120} mirror />
        <span>{superBowl?.score ? `${superBowl.score[team1Helmet?.engId]} TO ${superBowl.score[team2Helmet?.engId]}` : 'VS'}</span>
        <RenderHelmet {...team2Helmet} size={120} />
      </div>
      <div className="playoff-info__super-bowl__bottom-info">
        <h6>{team1Helmet?.teamName}</h6>
        <BlueButton text={label} onClick={handleRedirection} />
        <h6>{team2Helmet?.teamName}</h6>
      </div>
    </div>
  );
};
