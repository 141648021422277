import { CareerStats } from "../PlayerCareerStats"

const requiredKeys = ["wins", "loses", "tourneysPlayed"];

export const checkIfCoachCareerStats = (obj: CareerStats) => {
  const keys = [...Object.keys(obj.elimination), ...Object.keys(obj.h2h)];
  if (keys.every((key) => requiredKeys.includes(key)) && keys.length === 5) {
    return true;
  }

  return false;
}