import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Row, Col } from "react-bootstrap";
import "./FranchiseDetailStyle.scss";
import { useHistory } from "react-router-dom";

export interface Props {
  data?: any;
  rating?: any;
  arrow?: any;
  arrow1?: any;
  arrow2?: any;
  arrow3?: any;
  specialskill?: any;
  scoredigit?: any;
}

const FranchiseDetail = ({
  data,
  rating,
  arrow,
  specialskill,
  scoredigit,
  arrow1,
  arrow2,
  arrow3,
}: Props) => {
  const history = useHistory();
  const routeToFranchiseOwnerProfile = (userId: number) => {
    history.push(`/profile/${userId}`);
  };
  return (
    <div className="leagueMain">
      <Row>
        <Col xl={2}>
          <div className="leagueProfile">
            <img src={data.profileimage} alt="profile" width="90px" />
          </div>
        </Col>
        <Col xl={5}>
          <div className="leagueDetail">
            <h5>{data.protfolioname}</h5>
            <Row>
              <Col xl={6} lg={6} className="leagueDetailContent">
                <span>{data.usertitle}</span>
                <span>{data.username}</span>
              </Col>
              <Col xl={6} lg={6} className="leagueDetailContent">
                <span>{data.currentleaguetitle}</span>
                <span>{data.currentleague}</span>
              </Col>
              <Col xl={6} lg={6} className="leagueDetailContent">
                <span>{data.joinedtitle}</span>
                <span>{data.joined}</span>
              </Col>
              {!!data?.franchisestitle && !!data.franchises && (
                <Col xl={6} lg={6} className="leagueDetailContent">
                  <span>{data.franchisestitle}</span>
                  <span>{data.franchises}</span>
                </Col>
              )}
              {!!data?.wallettitle && !!data.wallet && (
                <Col xl={6} lg={6} className="leagueDetailContent">
                  <span>{data.wallettitle}</span>
                  <span>{data.wallet}</span>
                </Col>
              )}
              {!!data?.mintedplayertitle && !!data.mintedplayer && (
                <Col xl={6} lg={6} className="leagueDetailContent">
                  <span>{data.mintedplayertitle}</span>
                  <span>{data.mintedplayer}</span>
                </Col>
              )}
              {!!data?.emailtite && !!data.email && (
                <Col xl={6} lg={6} className="leagueDetailContent">
                  <span>{data.emailtite}</span>
                  <span>{data.email}</span>
                </Col>
              )}
              {!!data?.fundsplaytitle && !!data.fundsplay && (
                <Col xl={6} lg={6} className="leagueDetailContent">
                  <span>{data.fundsplaytitle}</span>
                  <span>{data.fundsplay}</span>
                </Col>
              )}
              <Col xl={6} lg={6} className="leagueDetailContent">
                <span>{data.ownerUserNameLabel}</span>
                <span>
                  <button
                    onClick={() =>
                      routeToFranchiseOwnerProfile(data.ownerUserId)
                    }
                  >
                    {data.ownerUserName}
                  </button>
                </span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={5}>
          <div className="franchiseBoxSection">
            <div className="leagueBoxBack franchiseBoxBack">
              <p>{data.win}</p>
              <div className="rating black">
                <h5>{data.windigit}</h5>
                {arrow1 && <ArrowDownOutlined />}
              </div>
            </div>
            <div className="leagueBoxBack franchiseBoxBack">
              <p>{data.winpercentage}</p>
              <div className="rating black">
                <h5>{data.winpercentagedigit}</h5>
                {arrow2 && <ArrowDownOutlined />}
              </div>
            </div>
            {data?.champs && (
              <div className="leagueBoxBack franchiseBoxBack">
                <p>{data.champs}</p>
                <div className="rating black">
                  <h5>{data.champsdigit}</h5>
                  {arrow3 && <ArrowDownOutlined />}
                </div>
              </div>
            )}
            {rating && (
              <div className="leagueBoxBackSection leaguerating">
                <div className="leagueBoxBack">
                  <p>{data.rating}</p>
                  <div className="rating">
                    <h5>{data.ratingdigit}</h5>
                    {!data.ratingStatus && <ArrowUpOutlined />}
                    {data.ratingStatus === "up" && <ArrowUpOutlined />}
                    {data.ratingStatus === "down" && <ArrowDownOutlined />}
                  </div>
                </div>
              </div>
            )}
            {specialskill && (
              <>
                <div className="specialskill mt-3">
                  <span className="minticon">M</span>
                  <span>SPECIAL SKILL</span>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FranchiseDetail;
