import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetFriends } from "redux/actions/Profile";

export function useFetchFriends(userId?: number) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userId) return;

    dispatch(GetFriends(userId));
  }, [userId, dispatch]);
}
