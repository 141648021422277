import PinkButton from "../Common/Button/PinkButton/PinkButton";
import { Link } from "react-router-dom";
import white_logo from "../../assets/images/homeScreen/whiteIcon.png";
import HomeContainer from "./HomeContainer";

const HomeVideoBanner = () => {
  const videoLink =
    "https://www.youtube.com/watch?v=xyQHMjYJkxg&ab_channel=GMDynasty";
  return (
    <div className="video-banner">
      <HomeContainer isWithoutCorner={true}>
        <div className="video-banner-info">
          <div className="notch-corner"></div>
          <div>
            <h2>
              <img
                src={white_logo}
                alt="gmlogo"
                className="video-banner-logo"
              />
              Calling out all armchair quarterbacks
            </h2>
            <p>think you can do it better, then prove it!</p>
            <a
              href={videoLink}
              target="_blank"
              rel="noopener noreferrer"
              className="vide-banner-btn"
            >
              <PinkButton text="WATCH NOW" />
            </a>
          </div>
        </div>
      </HomeContainer>
    </div>
  );
};
export default HomeVideoBanner;
