import { Card } from 'react-bootstrap';
import CardBody from '../Common/Card/CardBody';
import ResultCard from './ResultCard/ResultCard';
import { useFeeRates } from 'helper/useFeeRates';
import { useLastGameResults } from './hooks/useLastGameResults';
import { UserTeamsList } from 'types';
import { useLastEliminationResults } from './hooks/useLastEliminationResults';
import { PaginationPages } from './PaginationPages';
import { useLastSeasonResults } from './hooks/useLastSeasonResults';
import { AllSeasonGames } from './types';
import SeasonCard from './ResultCard/SeasonCard';

type Props = {
  franchise?: UserTeamsList[0];
};

export const HistoricalResultsBody: React.FC<Props> = ({ franchise }) => {
  const { WALLET_TRANSACTION_FEE } = useFeeRates();
  const { 
    lastGameResults, 
    handleNextPage, 
    handlePrevPage, 
    page, 
    setPage
  } = useLastGameResults(franchise?.engId);

  const {
    handleNextPage: handleNextElimPage,
    handlePrevPage: handlePrevElimPage,
    lastEliminationResults,
    page: elimPage,
    setPage: setElimPage,
  } = useLastEliminationResults(franchise?.engId);

  const { 
    handleNextPage: handleNextSeasonPage,
    handlePrevPage: handlePrevSeasonPage,
    lastSeasonResults,  
    page: seasonPage,
    setPage: setSeasonPage, 
  } = useLastSeasonResults(franchise?.engId);

  const onPageClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, shift = 0) => {
    setPage(+(e.target as HTMLElement).innerText + shift || 1);
  };

  const onElimPageClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, shift = 0) => {
    setElimPage(+(e.target as HTMLElement).innerText + shift || 1);
  };

  const onSeasonPageClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, shift = 0) => {
    setSeasonPage(+(e.target as HTMLElement).innerText + shift || 1);
  };

  if (!franchise) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <h3 className="historical-results-title mb-4">SCORES - HISTORICAL RESULTS</h3>
        <div className="h2h-results-section">
          {!!lastGameResults?.allGames?.length && (
            <>
              <div className="mb-4">
                <h4>HEAD-TO-HEAD</h4>
                {lastGameResults.allGames.map((game: any) => (
                  <ResultCard key={game.gid} game={game} franchise={franchise} />
                ))}
              </div>
              <PaginationPages
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                nextDisabled={lastGameResults?.allGames?.length === 0 || !lastGameResults?.hasNextPage}
                onPageClick={onPageClick}
                page={page}
              />
            </>
          )}
          
          {!!lastEliminationResults?.allGames?.length && (
            <>
              <div className="mb-4">
                <h4>ELIMINATION</h4>
                {lastEliminationResults?.allGames?.map((game: any) => (
                  <ResultCard key={game.gid} game={game} franchise={franchise} WALLET_TRANSACTION_FEE={WALLET_TRANSACTION_FEE} />
                ))}
              </div>
              <PaginationPages
                handleNextPage={handleNextElimPage}
                handlePrevPage={handlePrevElimPage}
                nextDisabled={lastEliminationResults?.allGames?.length === 0 || !lastEliminationResults?.hasNextPage}
                onPageClick={onElimPageClick}
                page={elimPage}
              />
            </>
          )}

          {!!lastSeasonResults?.allGames?.length && (
            <>
              <div className="mb-4">
                <h4>SEASON</h4>
                {lastSeasonResults?.allGames?.map((game: AllSeasonGames) => (
                  <SeasonCard key={game._id} season={game}/>
                ))}
              </div>
              <PaginationPages
                handleNextPage={handleNextSeasonPage}
                handlePrevPage={handlePrevElimPage}
                nextDisabled={lastSeasonResults?.allGames?.length === 0 || !lastSeasonResults?.hasNextPage}
                onPageClick={onSeasonPageClick}
                page={seasonPage}
              />
            </>
          )}
          
          {!lastGameResults?.allGames?.length && !lastEliminationResults?.allGames?.length && (
            <h4>NO RESULTS AVAILABLE</h4>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
