/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Title from '../../../Common/Title/Title';
import { Slider } from 'antd';
import './GamePlanStyle.scss';
import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { TeamGamePlan } from '../../../../types';

const GamePlanDefensivestretegy = ({ DefensiveDataProp, SpecialDataProp, teamGamePlanData, updateGamePlan, selectedSid }: any) => {
  const [dataListSpecial, setDataListSpecial] = useState<any>();
  const [dataListDefensive, setDataListDefensive] = useState<any>();
  const [currentSelect, setCurrentSelect] = useState('LOADING...');
  const [DefensiveData, setDefensiveData] = useState<any>(DefensiveDataProp);
  const [SpecialData, setSpecialData] = useState<any>(SpecialDataProp);
  const [currentDefensive, setCurrentDefensive] = useState<any>();

  useEffect(() => {
    if (selectedSid && DefensiveDataProp) {
      setCurrentSelect(DefensiveDataProp?.find((scheme: any) => scheme?.sid === selectedSid)?.name);
    }
    if (!selectedSid && DefensiveDataProp) {
      setCurrentSelect(DefensiveDataProp[0]?.name);
    }
  }, [selectedSid, DefensiveDataProp]);

  useEffect(() => {
    setDefensiveData(DefensiveDataProp);
    setSpecialData(SpecialDataProp);
  }, [DefensiveDataProp, SpecialDataProp]);

  useEffect(() => {
    if (DefensiveData?.length > 0) {
      const foundIndex = DefensiveDataProp?.findIndex((scheme: any) => scheme?.sid === selectedSid);
      const currentIndex = foundIndex === -1 ? 0 : foundIndex;
      setCurrentDefensive(DefensiveData?.[currentIndex]);
      const info = [
        {
          id: 1,
          sliderName: '1ST DOWN BIAS',
          sliderNameKey: '1stDownRunStop',
          slidervalue: DefensiveData?.[currentIndex]?.strategy['1stDownRunStop'],
          sliderleftname: 'HELP PASS',
          sliderrightname: 'HELP RUN',
        },
        {
          id: 2,
          sliderName: 'DEEP COVERAGE',
          sliderNameKey: 'coverDeep',
          slidervalue: DefensiveData?.[currentIndex]?.strategy['coverDeep'],
          sliderleftname: 'COVER 2',
          sliderrightname: 'COVER 4',
        },
        {
          id: 3,
          sliderName: 'COVERAGE ASSIGNMENT',
          sliderNameKey: 'pctMan',
          slidervalue: DefensiveData?.[currentIndex]?.strategy['pctMan'],
          sliderleftname: 'MAN',
          sliderrightname: 'ZONE',
        },
        {
          id: 4,
          sliderName: 'BLITZ',
          sliderNameKey: 'pctBlitz',
          slidervalue: DefensiveData?.[currentIndex]?.strategy['pctBlitz'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 5,
          sliderName: 'FORCE TURNOVERS',
          sliderNameKey: 'gambleforTO',
          slidervalue: DefensiveData?.[currentIndex]?.strategy['gambleforTO'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
      ];

      setDataListDefensive(info);
    }
  }, [DefensiveData, selectedSid]);

  const handleClickMenuItem = ({ key }: any) => {
    setCurrentSelect(key);
    const sid = DefensiveData?.find((data: any) => data?.name === key)?.sid;
    if (sid) {
      const putDefensiveData: TeamGamePlan['schemes'] = Object.assign(JSON.parse(JSON.stringify(teamGamePlanData?.schemes)), {
        defense: { sets: DefensiveData, selectedSid: sid || 1 },
      });

      updateGamePlan(putDefensiveData);
    }
    const currentOffensiveData = DefensiveData.find((data: any) => data.name == key);

    if (currentOffensiveData) {
      setCurrentDefensive(currentOffensiveData);
      const info = [
        {
          id: 1,
          sliderName: '1ST DOWN BIAS',
          sliderNameKey: '1stDownRunStop',
          slidervalue: currentOffensiveData?.strategy['1stDownRunStop'],
          sliderleftname: 'HELP PASS',
          sliderrightname: 'HELP RUN',
        },
        {
          id: 2,
          sliderName: 'DEEP COVERAGE',
          sliderNameKey: 'coverDeep',
          slidervalue: currentOffensiveData?.strategy['coverDeep'],
          sliderleftname: 'COVER 2',
          sliderrightname: 'COVER 4',
        },
        {
          id: 3,
          sliderName: 'COVERAGE ASSIGNMENT',
          sliderNameKey: 'pctMan',
          slidervalue: currentOffensiveData?.strategy['pctMan'],
          sliderleftname: 'MAN',
          sliderrightname: 'ZONE',
        },
        {
          id: 4,
          sliderName: 'BLITZ',
          sliderNameKey: 'pctBlitz',
          slidervalue: currentOffensiveData?.strategy['pctBlitz'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 5,
          sliderName: 'FORCE TURNOVERS',
          sliderNameKey: 'gambleforTO',
          slidervalue: currentOffensiveData?.strategy['gambleforTO'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
      ];
      setDataListDefensive(info);
    }
  };

  const onChangeDefensive = (value: any, name: any) => {
    const updateStrategy: any = currentDefensive.strategy;

    Object.keys(updateStrategy).forEach((key: any) => (key === name ? (updateStrategy[key] = value) : ''));

    const currentOffensiveDataUpdate: any = {
      ...currentDefensive,
      strategy: updateStrategy,
    };

    if (currentOffensiveDataUpdate) {
      setCurrentDefensive(currentOffensiveDataUpdate);
      const info = [
        {
          id: 1,
          sliderName: '1ST DOWN BIAS',
          sliderNameKey: '1stDownRunStop',
          slidervalue: currentOffensiveDataUpdate?.strategy['1stDownRunStop'],
          sliderleftname: 'HELP PASS',
          sliderrightname: 'HELP RUN',
        },
        {
          id: 2,
          sliderName: 'DEEP COVERAGE',
          sliderNameKey: 'coverDeep',
          slidervalue: currentOffensiveDataUpdate?.strategy['coverDeep'],
          sliderleftname: 'COVER 2',
          sliderrightname: 'COVER 4',
        },
        {
          id: 3,
          sliderName: 'COVERAGE ASSIGNMENT',
          sliderNameKey: 'pctMan',
          slidervalue: currentOffensiveDataUpdate?.strategy['pctMan'],
          sliderleftname: 'MAN',
          sliderrightname: 'ZONE',
        },
        {
          id: 4,
          sliderName: 'BLITZ',
          sliderNameKey: 'pctBlitz',
          slidervalue: currentOffensiveDataUpdate?.strategy['pctBlitz'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 5,
          sliderName: 'FORCE TURNOVERS',
          sliderNameKey: 'gambleforTO',
          slidervalue: currentOffensiveDataUpdate?.strategy['gambleforTO'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
      ];
      setDataListDefensive(info);
    }
  };

  const onAfterChangeDefensive = () => {
    if (teamGamePlanData?.schemes?.offense?.sets !== undefined && DefensiveData !== undefined && teamGamePlanData?.schemes?.special !== undefined) {
      const putDefensiveData: TeamGamePlan['schemes'] = Object.assign(JSON.parse(JSON.stringify(teamGamePlanData?.schemes)), {
        defense: { sets: DefensiveData, selectedSid: teamGamePlanData?.schemes?.defense?.selectedSid || 1 },
      });

      updateGamePlan(putDefensiveData);
    }
  };

  useEffect(() => {
    if (SpecialData) {
      const info = [
        {
          id: 1,
          sliderName: 'FIELD GOAL ATTEMPT - MAXIMUM RANGE',
          sliderNameKey: 'maxFGAtt',
          slidervalue: SpecialData['maxFGAtt'],
          sliderleftname: '45 YARDS',
          sliderrightname: '70 YARDS',
          min: 45,
          max: 70,
        },
        {
          id: 2,
          sliderName: 'KICK-OFF STRATEGY ',
          sliderNameKey: 'koTouchBack',
          slidervalue: SpecialData['koTouchBack'],
          sliderleftname: 'FORCE TOUCH BACK',
          sliderrightname: 'ALLOW RETURN',
        },
        {
          id: 4,
          sliderName: 'TWO POINT CONVERSION - WHEN BEHIND BY',
          sliderNameKey: '2ptConv',
          slidervalue: SpecialData['2ptConv'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 5,
          sliderName: 'FIELD GOAL ATTEMPT - RISKINESS',
          sliderNameKey: 'fgRiskiness',
          slidervalue: SpecialData['fgRiskiness'] || 50,
          sliderleftname: 'ALWAYS TRY',
          sliderrightname: 'HIGH CONFIDENCE',
          min: 10,
          max: 90,
        },
      ];
      setDataListSpecial(info);
    }
  }, [SpecialData]);

  const onChangeSpecial = (value: any, name: any) => {
    SpecialData[name] = value;
    if (SpecialData) {
      const info = [
        {
          id: 1,
          sliderName: 'FIELD GOAL ATTEMPT - MAXIMUM RANGE',
          sliderNameKey: 'maxFGAtt',
          slidervalue: SpecialData['maxFGAtt'],
          sliderleftname: '45 YARDS',
          sliderrightname: '70 YARDS',
          min: 45,
          max: 70,
        },
        {
          id: 2,
          sliderName: 'KICK-OFF STRATEGY ',
          sliderNameKey: 'koTouchBack',
          slidervalue: SpecialData['koTouchBack'],
          sliderleftname: 'FORCE TOUCH BACK',
          sliderrightname: 'ALLOW RETURN',
        },
        {
          id: 4,
          sliderName: 'TWO POINT CONVERSION - WHEN BEHIND BY',
          sliderNameKey: '2ptConv',
          slidervalue: SpecialData['2ptConv'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 5,
          sliderName: 'FIELD GOAL ATTEMPT - RISKINESS',
          sliderNameKey: 'fgRiskiness',
          slidervalue: SpecialData['fgRiskiness'] || 50,
          sliderleftname: 'ALWAYS TRY',
          sliderrightname: 'HIGH CONFIDENCE',
          min: 10,
          max: 90,
        },
      ];
      setDataListSpecial(info);
    }
  };

  const onAfterChangeSpecial = () => {
    if (teamGamePlanData?.schemes?.offense?.sets !== undefined && teamGamePlanData?.schemes?.defense?.sets !== undefined && SpecialData !== undefined) {
      const putSpecialData = {
        offense: {
          sets: teamGamePlanData?.schemes?.offense?.sets,
        },
        defense: {
          sets: teamGamePlanData?.schemes?.defense?.sets,
        },
        special: SpecialData,
      };

      updateGamePlan(putSpecialData);
    }
  };

  // DROPDOWN MENU
  const menu = (
    <Menu className="specialdrop" onClick={handleClickMenuItem}>
      {DefensiveData?.map((data: any) => {
        return <Menu.Item key={data?.name}>{data?.name} </Menu.Item>;
      })}
    </Menu>
  );

  return (
    <div className="gameplan_main_section defensive_plan_main">
      <Title text="DEFENSIVE STRATEGY" />
      <div className="dropdown_gameplan">
        <h5>SELECT DEFAULT DEFENSIVE SCHEME</h5>
        <div className="filterdropdown specialteams">
          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              {currentSelect} <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
      {dataListDefensive?.map((d: any, i: any) => (
        <div className="slider_main" key={`GamePlanDefensiveStrategy-${d.name}-${i}-i`}>
          <h5>{d.sliderName}</h5>
          <Slider
            key={`GamePlanDefensiveStrategy-Slide-${d.name}-${i}-i`}
            defaultValue={d.slidervalue}
            value={d.slidervalue}
            onChange={(e: any) => onChangeDefensive(e, d.sliderNameKey)}
            onAfterChange={onAfterChangeDefensive}
            min={d?.min}
            max={d?.max}
          />
          <span>{d.sliderleftname}</span>
          <span>{d.sliderrightname}</span>
        </div>
      ))}
      <div className="specialteam_main">
        {dataListSpecial && <Title text="SPECIAL TEAM" />}
        {dataListSpecial?.map((d: any, i: any) => (
          <div className="slider_main" key={`GamePlanSpecialStrategy-${d.name}-${i}-i`}>
            <h5>{d.sliderName}</h5>
            <Slider
              key={`GamePlanSpecialStrategy-Slide-${d.name}-${i}-i`}
              defaultValue={d.slidervalue}
              value={d.slidervalue}
              onChange={(e: any) => onChangeSpecial(e, d.sliderNameKey)}
              onAfterChange={onAfterChangeSpecial}
              min={d?.min}
              max={d?.max}
            />
            <span>{d.sliderleftname}</span>
            <span>{d.sliderrightname}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GamePlanDefensivestretegy;
