import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SeasonActions } from "redux/actions/Season";
import { RootState } from "redux/store";

export const useSeasonWeeksWithGames = (seasonId?: string) => {
  const dispatch = useDispatch();
  const weeks = useSelector(
    (state: RootState) => state.gameEngine.seasonWeeksWithGames
  );

  useEffect(() => {
    if (seasonId) {
      dispatch(SeasonActions.getSeasonWeeksWithGames(seasonId));
    }
  }, [seasonId])

  return weeks;
};
