import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import Dropdown from 'components/Common/Form/Dropdown/Dropdown';
import Title from 'components/Common/Title/Title';
import './Scores.scss';
import { useSeasonWeeksWithGames } from 'screens/Season/hooks/useSeasonWeeksWithGames';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';
import { useEffect, useState } from 'react';
import { useScoresForWeek } from './hooks/useScoresForWeek';
import LeagueScoreList from 'components/Common/LeagueScoreTab/LeagueScoreList';
import { mapSeasonScores } from 'helper/helper';
import { LoadingOutlined } from '@ant-design/icons';

export const Scores: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();
  const weeks = useSeasonWeeksWithGames(seasonInfo?.id);
  const [selectedWeekId, setSelectedWeekId] = useState<string>('');
  const { data, loading } = useScoresForWeek(selectedWeekId);

  useEffect(() => {
    if(weeks?.length) {
      setSelectedWeekId(weeks[0].weekId);
    }
  }, [weeks]);

  if (!seasonInfo || !weeks) {
    return null;
  }

  const prepWeeks = weeks.map((w) => {
    return {
      value: w.weekId,
      option: `Week ${w.week_number}`,
    };
  });

  return (
    <Card>
      <CardBody>
        <div className="season-scores">
          <Title text="WEEKS" />
          <Dropdown dropDownOptions={prepWeeks} value={selectedWeekId} onChange={(e) => setSelectedWeekId(e.target.value)} />
          {
            loading
              ? (
                <LoadingOutlined />
              )
              : (
                mapSeasonScores(data || []).map((d: any) => (
                  <LeagueScoreList data1={d} key={d?.leagueid} />
                ))
              )}
        </div>
      </CardBody>
    </Card>
  );
};
