import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { FriendRequestContent } from "./FriendRequestContent";
import { ProfileHeader } from "components/Common/Header/ProfileHeader";

export function FriendRequestMain() {
  const user = useSelector((state: RootState) => state.app?.resData);

  if (!user) return null;

  return (
    <Container style={{ paddingTop: "1.5rem" }}>
      <ProfileHeader userId={user?.id}/>
      <FriendRequestContent />
    </Container>
  );
}
