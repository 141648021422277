import { Tab, Nav } from "react-bootstrap";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { useHistory } from "react-router-dom";

const ReplayTabMain = ({ gid }: { gid: string }) => {
  const history = useHistory();

  const handleRedirectScore = () => {
    history.push(`/game-results/${gid}?tab=SCORE`);
  };
  const handleRedirectDriveChart = () => {
    history.push(`/game-results/${gid}?tab=DRIVE-CHART`);
  };
  const handleRedirectTeamStats = () => {
    history.push(`/game-results/${gid}?tab=TEAM-STATS`);
  };

  return (
    <div className="standingTabbarMain">
      <Tab.Container id="left-tabs-example" defaultActiveKey="REPLAY" >
        <div className="standingTabbar">
          <Card>
            <CardBody>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="REPLAY">REPLAY</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="SCORE"
                    onClick={handleRedirectScore}>
                    SCORE
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="DRIVE CHART"
                    onClick={handleRedirectDriveChart}>
                    DRIVE CHART
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={handleRedirectTeamStats}>
                    TEAM STATS
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </CardBody>
          </Card>
        </div>
      </Tab.Container>
    </div>
  );
};

export default ReplayTabMain;
