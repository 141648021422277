import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    LineSeries,
    Inject,
    DateTime,
} from "@syncfusion/ej2-react-charts";
import Title from "../Common/Title/Title";
import { Col } from "react-bootstrap";

const PlayerRating = () =>{
    return(
        <>
            <div className="playerrating_main" >
                <Title text="PLAYER RATING" />
                <Col xl={12}>
                    <ChartComponent
                    width="100%"
                    primaryXAxis={{
                        valueType: "DateTime",
                        labelFormat: "MMM-yyyy",
                        edgeLabelPlacement: "Shift",
                    }}>
                    <Inject services={[LineSeries, DateTime]}></Inject>
                    <SeriesCollectionDirective>
                        <SeriesDirective
                        type="Line"
                        // dataSource={sampleData}
                        xName="date"
                        yName="value"
                        marker={{ visible: true }}
                        />
                    </SeriesCollectionDirective>
                    </ChartComponent>
                </Col>
            </div>
        </>
    );
}

export default PlayerRating;