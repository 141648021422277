import { Container } from "react-bootstrap"
import bannerImage from "../../assets/images/homeScreen/2.png";
import "./LearnMoreStyle.scss";

const LearnMoreBanner = ({ setLoading }: any) => {
  return (
    <>
      <div className="learnMoreBannerMain">
        <div className="banner">
          <div className="slider">
            <div className="imageWrapper">
              <img src={bannerImage} alt="bannerImage" className="bannerImage" />
              <div className="gradientOverlay"></div>
            </div>
            <div className="bannerInfoMain">
              <Container fluid={"sm"}>
                <div className="bannerInfo">
                  <h2>ROOKIES GUIDE</h2>
                  <p>LEARN THE PLAYBOOK & GET IN THE GAME</p>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LearnMoreBanner