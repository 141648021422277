import { useServerSidePagination } from 'helper/useServerSidePagination';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranscations } from 'redux/actions/AuthAction';
import { RootState } from 'redux/store';

export const useTransactions = () => {
  const { handleNextPage, handlePrevPage, page, setPage } = useServerSidePagination();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const allTransactions = useSelector((state: RootState) => state.app.transactionsData);

  useEffect(() => {
    if (!userId) return;
    dispatch(getTranscations(userId, page, 10, setIsLoading));
  }, [userId, page, setIsLoading]);

  return { allTransactions: allTransactions?.transactions || [], handleNextPage, handlePrevPage, page, setPage, isNextPage: !!allTransactions?.isNextPage, isLoading };
};
