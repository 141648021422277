const GET_SEASON_TEAMS_REQUEST = 'GET_SEASON_TEAMS_REQUEST';
const GET_SEASON_TEAMS_SUCCESS = 'GET_SEASON_TEAMS_SUCCESS';
const GET_SEASON_TEAMS_FAIL = 'GET_SEASON_TEAMS_FAIL';

const GET_SEASON_INFO_REQUEST = 'GET_SEASON_INFO_REQUEST';
const GET_SEASON_INFO_SUCCESS = 'GET_SEASON_INFO_SUCCESS';
const GET_SEASON_INFO_FAIL = 'GET_SEASON_INFO_FAIL';

const GET_SEASON_LEADERS_REQUEST = 'GET_SEASON_LEADERS_REQUEST';
const GET_SEASON_LEADERS_SUCCESS = 'GET_SEASON_LEADERS_SUCCESS';
const GET_SEASON_LEADERS_FAIL = 'GET_SEASON_LEADERS_FAIL';

const GET_SEASON_RESULTS_REQUEST = 'GET_SEASON_RESULTS_REQUEST';
const GET_SEASON_RESULTS_SUCCESS = 'GET_SEASON_RESULTS_SUCCESS';
const GET_SEASON_RESULTS_FAIL = 'GET_SEASON_RESULTS_FAIL';

const GET_SEASON_WEEKS_WITH_GAMES_REQUEST = 'GET_SEASON_WEEKS_WITH_GAMES_REQUEST';
const GET_SEASON_WEEKS_WITH_GAMES_SUCCESS = 'GET_SEASON_WEEKS_WITH_GAMES_SUCCESS';
const GET_SEASON_WEEKS_WITH_GAMES_FAIL = 'GET_SEASON_WEEKS_WITH_GAMES_FAIL';

const GET_SEASON_TEAM_STATS_REQUEST = 'GET_SEASON_CONFERENCE_STATS_REQUEST';
const GET_SEASON_TEAM_STATS_SUCCESS = 'GET_SEASON_CONFERENCE_STATS_SUCCESS';
const GET_SEASON_TEAM_STATS_FAIL = 'GET_SEASON_CONFERENCE_STATS_FAIL';

const GET_SEASON_POWER_RANKINGS_REQUEST = 'GET_SEASON_POWER_RANKINGS_REQUEST';
const GET_SEASON_POWER_RANKINGS_SUCCESS = 'GET_SEASON_POWER_RANKINGS_SUCCESS';
const GET_SEASON_POWER_RANKINGS_FAIL = 'GET_SEASON_POWER_RANKINGS_FAIL';

const GET_SEASON_DIVISION_STANDINGS_REQUEST = 'GET_SEASON_DIVISION_STANDINGS_REQUEST';
const GET_SEASON_DIVISION_STANDINGS_SUCCESS = 'GET_SEASON_DIVISION_STANDINGS_SUCCESS';
const GET_SEASON_DIVISION_STANDINGS_FAIL = 'GET_SEASON_DIVISION_STANDINGS_FAIL';

export default {
  GET_SEASON_TEAMS_REQUEST,
  GET_SEASON_TEAMS_SUCCESS,
  GET_SEASON_TEAMS_FAIL,

  GET_SEASON_INFO_REQUEST,
  GET_SEASON_INFO_SUCCESS,
  GET_SEASON_INFO_FAIL,

  GET_SEASON_LEADERS_REQUEST,
  GET_SEASON_LEADERS_SUCCESS,
  GET_SEASON_LEADERS_FAIL,

  GET_SEASON_RESULTS_REQUEST,
  GET_SEASON_RESULTS_SUCCESS,
  GET_SEASON_RESULTS_FAIL,

  GET_SEASON_WEEKS_WITH_GAMES_REQUEST,
  GET_SEASON_WEEKS_WITH_GAMES_SUCCESS,
  GET_SEASON_WEEKS_WITH_GAMES_FAIL,

  GET_SEASON_TEAM_STATS_REQUEST,
  GET_SEASON_TEAM_STATS_SUCCESS,
  GET_SEASON_TEAM_STATS_FAIL,

  GET_SEASON_POWER_RANKINGS_REQUEST,
  GET_SEASON_POWER_RANKINGS_SUCCESS,
  GET_SEASON_POWER_RANKINGS_FAIL,

  GET_SEASON_DIVISION_STANDINGS_REQUEST,
  GET_SEASON_DIVISION_STANDINGS_SUCCESS,
  GET_SEASON_DIVISION_STANDINGS_FAIL,
};
