import LeagueEnterMain from "../../components/LeagueEnter/LeagueEnterMain";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import "./LeagueEnterScreenStyle.scss";
import {  useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingScreen from "../../components/Common/LoadingScreen/LoadingScreen";

const LeagueEnterScreen: React.FC = () => {
  const gameEngineStateLoader = useSelector(
    (state: RootState) => state.gameEngine.loader
  );

  return (
    <>
      {!!gameEngineStateLoader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <LeagueEnterMain />
      </div>
    </>
  );
};

export default LeagueEnterScreen;
