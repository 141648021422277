import Error from "../../assets/images/common/error.svg";

const DraftSelectionModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>IF YOU EXIT THE DRAFT AND DO NOT COMPLETE THE SELECTION PROCESS WITHIN 2 HOURS SELECTIONS WILL BE MADE ON YOUR BEHALF. BEST AVAILABLE PLAYER WILL BE AUTOMATICALLY SELECTED</p>
            </div> 
        </>
    );
}

export default DraftSelectionModal;