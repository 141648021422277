import { useWeekPlayers } from "../hooks/useWeekPlayers";
import { WeekPlayersLabels } from "redux/actions/Leaderboard/types";
import "./WeekPlayers.styles.scss";
import { WeekPlayerItem } from "./WeekPlayerItem";

const PositionsArr = [
  WeekPlayersLabels.Quarterback,
  WeekPlayersLabels.RunningBacks,
  WeekPlayersLabels.Receivers,
  WeekPlayersLabels.TightEnd,
  WeekPlayersLabels.CornerBacks,
  WeekPlayersLabels.Safeties,
  WeekPlayersLabels.Linebacker,
  WeekPlayersLabels.DeffensiveTackles,
  WeekPlayersLabels.Kicker,
];

export default function WeekPlayers() {
  const { players } = useWeekPlayers();

  if (!players) {
    return <div>Sorry no data</div>;
  }

  return (
    <div className="week-players-row">
      <div className="row">
        {PositionsArr.map((positionLabel) => {
          const player = players![positionLabel];
          if (player) {
            return (
              <div className="col-xl-4  col-md-6 col-sm-12" key={positionLabel}>
                <WeekPlayerItem player={player} positionLabel={positionLabel} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
