import Error from "../../../assets/images/common/error.svg";
import "./ModalStyle.scss";

const ConnectWalletError = () =>{
    return(
        <>
            <div className="startdraftmain connectwallet" >
                <img src={Error} alt="erroricon" />
                <p>In order to purchase a franchise you must first connect a  
                wallet. Please connect a wallet using the selections 
                on the right.</p>
            </div> 
        </>
    );
}

export default ConnectWalletError;