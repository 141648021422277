import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getPlayerLeaders } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { SkillEnum } from "../LeaderboardPlayersLeaders";

export default function useViewMorePlayers() {
  const dispatch = useDispatch();
  const playersType = useSelector((state:RootState) => state.gameEngine.player_leaders_type);
  const playersData = useSelector((state:RootState) => state.gameEngine.player_leaders_type_data);

  useEffect(() => {
    if (playersType) {
      dispatch(getPlayerLeaders(playersType as SkillEnum));
    }
  }, [playersType]);

  return { playersType, playersData };
}