import commentatorImage from '../../assets/images/gamePlay/commentator.png';
import { useCountdown } from 'helper/useCoundown';

type Props = {
  message: string | Date;
}

export const PbPComment: React.FC<Props> = ({ message }) => {
  const [m, s] = useCountdown(message);

  const isString = typeof message === 'string';
  const isPassed = !isString && (+m <= 0 && +s < 0);

  return (
    <div className="pbp-comment">
      <div className="pbp-comment-message-background"></div>
      <div className="pbp-comment-image">
        <img src={commentatorImage} alt="commentator" />
      </div>
      <div className="pbp-comment-message">
        {isString 
          ? message
          : isPassed 
            ? "Players are on their way to the field, the game will start soon."
            : `Head coaches have ${m}:${s} to make their strategy adjustments.`}
      </div>
    </div>
  )
}
