const heightToInches = (hgt: number) => {
  return Math.round(64 + (hgt * (82 - 64)) / 100);
};

const getTextHeight = (height: number) => {
  const inchHgt = heightToInches(height);
  return `${Math.floor(inchHgt / 12)}'${inchHgt % 12}'`;
};

export const preparePlayersDepth = (players: any[]) => {
  try {
    return players.map((player: any) => ({
      ...player,
      heightText: getTextHeight(player.ratings[0].hgt),
      ratings: {
        ...player.ratings[0],
        run: player.ratings[0].elu
      }
    }));
  } catch (e) {
    return [];
  }
};
