import { GroupOverview } from "components/Group";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export function GroupOverviewScreen() {
  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      <GroupOverview />
    </div>
  );
}
