import { useState } from "react";

export const useServerSidePagination = () => {
  const [page, setPage] = useState(1);

  const handleNextPage = () => {
    setPage((current) => current + 1);
  };

  const handlePrevPage = () => {
    setPage((current) => current - 1);
  };

  const setExactPage = (newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  return {
    page,
    handleNextPage,
    handlePrevPage,
    setPage: setExactPage
  }
};
