import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserActiveGamesDetails } from 'redux/actions/GameEngineAction';
import { RootState } from 'redux/store';

export const useActiveGamesDetails = (userId?: number) => {
  const dispatch = useDispatch();
  const games = useSelector((state: RootState) => state.gameEngine.activeGamesDetails);

  useEffect(() => {
    if (!userId) return;

    dispatch(getUserActiveGamesDetails(userId));
  }, [userId]);

  return games;
};
