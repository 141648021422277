export function getAverageRatingIncrement(ratingsHistory: any[]) {
   if (!ratingsHistory || ratingsHistory.length < 2) {
      return 0;
   }
   
   const ratingIncrements: number[] = [];

   ratingsHistory.forEach((currentEntry, index, array) => {
      if (index > 0) {
         const currentRating = currentEntry.value;
         const previousRating = array[index - 1].value;

         const ratingIncrement = currentRating - previousRating;

         ratingIncrements.push(ratingIncrement);
      }
   });

   const averageRatingIncrement =
      ratingIncrements.reduce((sum, increment) => sum + increment, 0) / ratingIncrements.length;

   return averageRatingIncrement;
}
