import { SeasonTeamStats } from 'screens/Season/Tabs/Standings/SubTabs/types';
import types from '../actionTypes';
import { API_URL } from 'config';
import { get } from 'redux/services/Api';

export const getSeasonTeamStats = (seasonId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_TEAM_STATS_REQUEST,
        payload: {},
      });
      const res = await get<{ data: Omit<SeasonTeamStats, "PCT">[] }>(`${API_URL}/engine/leagues/seasons/${seasonId}/conference-stats`);
      res.data = res.data.map(stat => ({
        ...stat,
        PCT: +((stat.W / (stat.W + stat.L)).toFixed(2))
      }))
      dispatch({
        type: types.GET_SEASON_TEAM_STATS_SUCCESS,
        payload: { conferences: res?.data },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_TEAM_STATS_FAIL,
        error: error?.response?.data?.error || error?.message || error,
      });
    }
  };
};
