import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import LeagueEnterNewForm from "./LeagueEnterNewForm";

const LeagueAvailableMain: React.FC = () => (
  <div className="pb-4">
    <Card>
      <CardBody>
        <LeagueEnterNewForm />
      </CardBody>
    </Card>
  </div>
);

export default LeagueAvailableMain;
