import { API_URL } from 'config';
import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { LeagueTeam } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';

export const getEliminationTeams = (gid: number, page = 1, limit = 6) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_ELIMINATION_TEAMS_REQUEST,
        payload: {},
      });

      let res: Pick<LeagueTeam, 'utid' | 'region'>[] = await get(`/engine/games/elimination/${gid}/eliminationTeams?page=${page}&limit=${limit}`);

      const preparedEngIds = res.map((data) => data.utid).join(',');

      let helmetsData: Omit<LeagueTeam, 'utid' | 'region'>[] = await get(
        `${API_URL}/user/get-helmet/${preparedEngIds}`,
        {},
        {
          'Access-Control-Allow-Origin': '*',
        },
      );

      if (helmetsData.length !== res.length) {
        res = [];
        helmetsData = [];
      }

      const result: LeagueTeam[] = [];

      if (helmetsData && helmetsData?.length) {
        helmetsData.forEach((helmetData) => {
          const index = res.findIndex((engineData) => engineData.utid === helmetData.engId);
          if (index !== -1) {
            result[index] = { ...res[index], ...helmetData };
          }
        });
      }

      dispatch({
        type: types.GET_ELIMINATION_TEAMS_SUCCESS,
        payload: { leagueTeams: result },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ELIMINATION_TEAMS_FAIL,
        error: error,
      });
    }
  };
};
