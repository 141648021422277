import Error from "../../../../assets/images/common/error.svg";

const DemoteModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Player has been demoted to the taxi squad. (would be great 
                if pop-up would automatically disappear after 5 seconds). 
                If there is no room on the taxi squad a pop-up on 
                screen 33 will be displayed.</p>
            </div>  
        </>
    );
}

export default DemoteModal;