import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GroupHeader } from "../Common";
import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { GroupRequestContent } from "./GroupRequestContent";
import { useFetchGroup } from "../GroupOverview/hooks/useFetchGroup";

export function GroupRequests() {
  const { groupId } = useParams<{ groupId: string }>();
  useFetchGroup(groupId);

  return (
    <Container style={{ paddingTop: "1.5rem", paddingBottom: "4rem" }}>
      <GroupHeader />
      <Card>
        <CardBody>
          <GroupRequestContent />
        </CardBody>
      </Card>
    </Container>
  );
}
