import { ReactMultiEmail } from "react-multi-email";

type EmailsInputProps = {
  emails: string[];
  handleEmailsChange: (data: string[]) => void;
};

export function EmailsInput({ emails, handleEmailsChange }: EmailsInputProps) {
  return (
    <div className="emails_container">
      <label htmlFor="react_multi_email">To:</label>
      <ReactMultiEmail
        id="react_multi_email"
        placeholder="Your friends emails"
        emails={emails}
        onChange={handleEmailsChange}
        autoFocus={true}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
}
