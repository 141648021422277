/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from "react-redux";
import { Container, Col, Row } from "react-bootstrap";
import { RootState } from "../../redux/store";
import LeagueOverviewRating from "components/LeagueHeadToHead/TabDetail/OverviewTab/LeagueOverviewRating";
import LeagueHeadTabMainMenu from "components/LeagueHeadToHead/LeagueHeadTabMainMenu";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import FullTeamStatHeader from "./FullTeamStatHeader";
import FullTeamAvarageGame from "./FullTeamAvarageGame";
import "./FullTeamStatStyle.scss";

interface Props {
  gid: number;
}

const FullTeamStatMain: React.FC<Props> = ({ gid }) => {
  const teamStats = useSelector(
    (state: RootState) => state.gameEngine.FullTeamStats
  );

  return (
    <Container>
      <FullTeamStatHeader />
      <LeagueHeadTabMainMenu />
      <Col xl={12} className="mb-3">
        <LeagueOverviewRating gid={gid} />
      </Col>
      <div className="mt-3 mb-4">
        <Card>
          <CardBody>
            <Row>
              <Col xl={12}>
                <FullTeamAvarageGame teamStat={teamStats || {}} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default FullTeamStatMain;
