import { get } from "../../../../../redux/services/Api";

export const getGroupGamesPlayedNum = async (groupId?: number) => {
  if (!groupId) return "N/A";
  try {
    const data = await get<{ groupGamesPlayed: number }>(
      `/engine/games/groups/${groupId}/gamesPlayed`,
    );
    return data.groupGamesPlayed;
  } catch (e) {
    console.log(e);
    return "N/A";
  }
}

export const getGroupPrestigePoints = async (groupId?: number) => {
  if (!groupId) return "N/A";
  try {
    const data = await get<{
      userId: number,
      points: number
    }>(
      `/prestige-points/group/${groupId}/prestige`,
    );
    return data.points;
  } catch (e) {
    console.log(e);
    return "N/A";
  }
}
