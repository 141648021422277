import metamask from '../../../../assets/images/signUp/metamask.svg';
import './ConnectNewWalletModalStyles.scss';

type Props = {
  onClick: () => void;
  mode: 'connect' | 'change';
};

const ConnectNewWalletModal: React.FC<Props> = ({ onClick, mode }) => (
  <div className="connect-new-wallet-modal">
    {mode === 'change' && (
      <>
        <h5>CONNECT NEW WALLET</h5>
        <p>
          REMOVING AND CONNECTING A NEW WALLET MAY IMPACT THE AVAILABILITY OF YOUR ROSTER. ALL ASSETS (FRANCHISES, PLAYERS, COACHES GMS) IN GAME PLAY MUST BE CONNECTED
          VIA THE WALLET.
        </p>
      </>
    )}
    {mode === 'connect' && (
      <>
        <h5>CONNECT WALLET</h5>
        <p>
          CONNECT YOUR METAMASK WALLET TO YOUR GMD ACCOUNT
        </p>
      </>
    )}
    <button className="trustwallet metamask" onClick={onClick}>
      <img src={metamask} alt="metamaskLogo" />
      CONNECT WALLET
    </button>
  </div>
);

export default ConnectNewWalletModal;
