
export const alignAllColumnsToCenter = (columns: any[]) => {
  return columns.map((column: any, index) => {
    if (index === 0) return column;

    return {
      ...column,
      align: 'center',
    };
  });
}