import React from 'react';
import { Modal } from 'antd';
import field from "../../../../assets/images/depthChartFormations/clearField.png";
import { F1RB4WR } from './DepthChartFormations/F1RB4WR';
import { F2RB2WR1TE } from './DepthChartFormations/F2RB2WR1TE';
import { F1RB3WR1TE } from './DepthChartFormations/F1RB3WR1TE';
import { F1RB2WR2TE } from './DepthChartFormations/F1RB2WR2TE';
import { F2RB1WR2TE } from './DepthChartFormations/F2RB1WR2TE';
import { F4DL3LB2S2CB } from './DepthChartFormations/F4DL3LB2S2CB';
import { F3DL4LB2S2CB } from './DepthChartFormations/F3DL4LB2S2CB';
import { F4DL2LB2S3CB } from './DepthChartFormations/F4DL2LB2S3CB';
import { F3DL2LB2S4CB } from './DepthChartFormations/F3DL2LB2S4CB';

interface Props {
  visible: boolean;
  formationName: keyof typeof formations;
  onCancel: () => void;
}

export const formations = {
  "1 RB, 4 WR": <F1RB4WR />,
  "2 RB, 2 WR, 1 TE": <F2RB2WR1TE />,
  "1 RB, 3 WR, 1 TE": <F1RB3WR1TE />,
  "1 RB, 2 WR, 2 TE": <F1RB2WR2TE />,
  "2 RB, 1 WR, 2 TE": <F2RB1WR2TE />,
  "4 DL, 3 LB, 2 S, 2 CB": <F4DL3LB2S2CB />,
  "3 DL, 4 LB, 2 S, 2 CB": <F3DL4LB2S2CB />,
  "4 DL, 2 LB, 2 S, 3 CB": <F4DL2LB2S3CB />,
  "3 DL, 2 LB, 2 S, 4 CB": <F3DL2LB2S4CB />,
  //NOT EXISTING FORMATIONS:
  "SPECIAL TEAM": <div/>,
  "ANY": <div/>,
  "4 DL, 1 LB, 2 S, 4 CB": <div/>,
  "3 DL, 3 LB, 2 S, 3 CB": <div/>
  ///////////////////////////
}

export const DepthChartViewFormationModal: React.FC<Props> = ({
  visible,
  formationName,
  onCancel,
}) => {

  return (
    <Modal
      title={`FORMATION ${formationName}`}
      className='depth-chart-view-formation-modal'
      open={visible}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
      centered={true}
      width=""
    >
      <div 
        className="formation-field"
        style={{ backgroundImage: `url(${field})`}}
      >
        {formationName in formations && formations[formationName]}
      </div>
    </Modal>
  );
};
