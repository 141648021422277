import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTeams } from "redux/actions/TeamAction";
import { RootState } from "redux/store";

export const useSelectedUserTeams = (userId?: number) => {
  const dispatch = useDispatch();
  const teams = useSelector(
    (state: RootState) => state.team.requestedUserTeams
  );

  useEffect(() => {
    if (userId) {
      dispatch(getUserTeams(userId))
    }
  }, [userId]);

  return teams || [];
};
