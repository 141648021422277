import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import helmetBlue from "../../../assets/images/helmet/helmetBlue.png";
import helmetRed from "../../../assets/images/helmet/helmetRed.png";
import "./TeamHelmet.scss"

interface HelmetTeamProps {
   playerUtid: number;
   baseSrc: string;
   logoSrc: string;
   franchiseName?: string;
   direction: 'left' | 'right'
   withoutName?: boolean;
}

const TeamHelmet: React.FC<HelmetTeamProps> = memo((
   { playerUtid, baseSrc, logoSrc, franchiseName, direction, withoutName }
) => {

   return (
      <Link
         to={`../../franchisedetail/${playerUtid}`}
         className="a_color franchise-helmet"
      >
         {!withoutName && <div className="franchise-name-container">
            <p className="franchise-name-text">{franchiseName}</p>
         </div>}
         <div className="helmet">
            <img
               className={`helmet__item helmet__item--${direction}`}
               src={baseSrc}
               alt="helmet"
               onError={(e) => {
                  e.currentTarget.src = direction === "left" ? helmetBlue : helmetRed;
               }}
            />
            {logoSrc && (
               <img
                  className="hidden"
                  src={logoSrc}
                  alt="helmet-logo"
                  onError={(e) => {
                     e.currentTarget.className = "hidden";
                  }}
                  onLoad={(e) => {
                     e.currentTarget.className =
                        `helmet__logo helmet__logo--${direction}`;
                  }}
               />
            )}
         </div>
      </Link>
   )
});

export default TeamHelmet
