import React from "react";
import { LobbyListItem } from "./LobbyListItem";
import { LobbyListItemType } from "./types";

type Props = {
  title: string;
  lobbyListItems: LobbyListItemType[];
  squareImage?: boolean;
};

export const LobbyList = React.forwardRef<HTMLDivElement, Props>(
  ({ title, lobbyListItems, squareImage }, ref) => {
    return (
      <div className="section">
        <div className="section__header">
          <h5>{title}</h5>
        </div>
        <div className="section__body lobby_list" ref={ref}>
          {lobbyListItems.map((lobbyListItem, index) => (
            <LobbyListItem
              key={index}
              {...lobbyListItem}
              squareImage={squareImage}
            />
          ))}
        </div>
      </div>
    );
  }
);
