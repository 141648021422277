import { Modal } from "antd"
import { Stack } from "react-bootstrap";

type Props = {
  open: boolean;
  onClose: () => void;
}

export const MintRosterInfo: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      footer={null}
      width={"75vw"}
      onCancel={onClose}
      centered
    >
      <Stack gap={2} className="font18">
        <h4 className="mb-4 text-center">CUSTOMIZATION INFORMATION</h4>
        <p>Minting is the process in which your game asset (player, coach, gm) are
          converted into nfts (non-fungible tokens). Each minted nft is unique
          and has one-of-a-kinds traits. </p>
        <p>Once minted, the NFT is owned by you and can be held in your connected
          wallet for game use or sold on OpenSea. You also have limited commercial
          right to use the image. See terms for full details</p>
        <p>Important Minting Rules :</p>
        <ul>
          <li>Once minted the games asset traits will be locked and no longer impacted
            by game play.</li>
          <li>Minting can’t be reversed. It is up to you to evaluate your game assets
            performance and pick the best time to lock-in the traits.</li>
          <li>Minting can take up to 3 hours upon completing the transaction. The NFT will
            automatically be placed in your connected wallet.</li>
          <li>Pricing is based on asset rating.</li>
        </ul>
      </Stack>
    </Modal>
  )
}