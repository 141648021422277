import { GroupRequests } from "components/Group/GroupRequests";
import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";

export function GroupRequestsScreen() {
  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      <GroupRequests />
    </div>
  )
}