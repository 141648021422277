import { get } from "redux/services/Api";

export type LobbyInProgressGamesResponse = {
  avatarsList: UserTeamAvatarWithHelmet[];
  games: LobbyInProgressGame[];
}

export type LobbyInProgressGame = {
  gid: number;
  teams: number[];
  name: string;
  scores: number[][];
}

export type UserTeamAvatarWithHelmet = UserTeamAvatar & {
  helmetColorName: TeamAvatar['colorName'];
  helmetColorHex: TeamAvatar['name'];
  helmetUrl: TeamAvatar['url'];
  logoUrl: TeamAvatar['url'];
}

type UserTeamAvatar = {
  id: number;
  userName: string;
  userId: number;
  engId: number;
  nftTokenId: string;
  transferStatus: string;
  transactionId: number;
  img: string;
  jsonFile: string;
  bg: number;
  helmet: number;
  logo: number;
  city: number;
  knobs: number;
  teamName: string;
  baseRate: string;
  approval: number;
  rejectMessage: string;
  teamRating: string;
  playerReveal: number;
  updateStatus: number;
  created: Date;
  updated: Date;
  status: number;
};

type TeamAvatar = {
  id: number;
  name: string;
  colorName: string;
  url: string;
  type: string;
  created: Date;
  updated: Date;
};


export const getLobbyInProgressGames = async (page = 1, limit = 5) => {
  try {
    return await get<LobbyInProgressGamesResponse>('/lobby/getGames', { page, limit })
  } catch (e) {
    console.log("Error during loading lobby games", e);
    return {
      avatarsList: [],
      games: [],
    };
  }
}