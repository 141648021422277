import { Popover, Badge } from "antd";
import { EmojiList } from "./EmojiList";

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  sendSelectedEmoji: (emoji: string) => void;
};

export const EmojiPickerPopover: React.FC<Props> = ({
  isOpen,
  children,
  sendSelectedEmoji,
}) => {
  return (
    <Popover
      content={<EmojiList onClick={sendSelectedEmoji} />}
      open={isOpen}
      placement="top"
    >
      <Badge>{children}</Badge>
    </Popover>
  );
};
