import Error from "../../assets/images/common/error.svg";

const HigherTeamRatingModal: React.FC = () => (
    <div className="startdraftmain" >
        <img src={Error} alt="erroricon" />
        <p>The League selected is more competitive than your team rating. 
        League composition will include teams with 
        higher ratings.</p>
    </div> 
);

export default HigherTeamRatingModal;
