/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './NotFoundStyle.scss';
import notfound from '../../assets/images/notFound/notFound.svg';
import { Link } from 'react-router-dom';

const NotFoundMain: React.FC = () => (
  <div className="notfound_main">
    <div className="notfound_content">
      <img src={notfound} alt="notfound" />
      <h5>OOPS! PAGE NOT FOUND</h5>
      <p>Sorry, the page you're looking for doesn't exist. If you think something is broken, report a problem.</p>
      <Link to="/">RETURN HOME</Link>
    </div>
  </div>
);

export default NotFoundMain;
