import { EliminationDetails } from "types";
import { get } from "redux/services/Api";

export const loadEliminationDetails = async(gid: number, utid: number): Promise<EliminationDetails[] | null> => {
  try {
    const data = await get<EliminationDetails[]>(
      `/engine/games/elimination/${gid}/detailsForTeam?utid=${utid}`,
    );
    
    return data;

  } catch (e){
    if (e instanceof Error) {
      console.warn(e);
    }
    return null;
  }
}