import React from 'react';
import { useCountdown } from "../../../helper/useCoundown";
import ShowCounter from "./ShowCounter";

interface Props {
  targetDate: Date | string;
}

export const CountdownTimer = ({ targetDate }: Props) => {
  const [minutes, seconds] = useCountdown(targetDate);

    return (
      <ShowCounter
        minutes={minutes}
        seconds={seconds}
      />
    );
};
