import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import PurchaseFranchiseMain from "../../components/PurchaseFranchise/PurchaseFranchiseMain";
import LoadingScreen from "./../../components/Common/LoadingScreen/LoadingScreen";
import { useLoading } from "components/FranchiseOverview/hooks/use-loading";

const PurchaseFranchiseScreen: React.FC = () => {
  const loader = useLoading();

  return (
    <>
      {loader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <PurchaseFranchiseMain />
      </div>
    </>
  );
};

export default PurchaseFranchiseScreen;
