import Title from "../Common/Title/Title";
import PinkButton from "../Common/Button/PinkButton/PinkButton";
import { Table } from "antd";

const RecentGames = () =>{
    const activeLeaguesColumns = [
        {
            title: "",
            dataIndex: "title",
        },
        {
            title: ".",
            dataIndex: "won",
        },
    ];
    const activeLeaguesData = [
        {
            key: "1",
            title: "9/24/21 | W 21-17",
            won: "GS1234567",
        },
        {
            key: "2",
            title: "9/24/21 | W 21-17",
            won: "GS1234567",
        },
        {
            key: "3",
            title: "9/24/21 | W 21-17",
            won: "GS1234567",
        },
        {
            key: "4",
            title: "9/24/21 | W 21-17",
            won: "GS1234567",
        },
    ];
    return(
        <>
            <div className="recent_game" >
                <div className="carrerstat_header" >
                    <Title text="RECENT GAMES" />
                    <PinkButton text="MINT COACH" />
                </div>
                <div className="carrerstat_table" >
                    <Table
                        columns={activeLeaguesColumns}
                        pagination={false}
                        dataSource={activeLeaguesData}
                        size="small"
                    />
                </div>
            </div>
        </>
    );
}

export default RecentGames;