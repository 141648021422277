import { API_URL } from "config";
import { UserTeamsList } from "types";

type Props = {
  teams: UserTeamsList;
  selectedTeamId: number;
  opponentTeamId: number;
  setSelectedTeamId: (id: number) => void;
};

export const TeamSelector: React.FC<Props> = ({
  teams,
  selectedTeamId,
  opponentTeamId,
  setSelectedTeamId,
}) => {
  return (
    <div className="play-free-team-selector-container">
      {teams.map((team: UserTeamsList[0]) => {
        let containerClassName = "team-image-container";
        if (selectedTeamId === team.engId) {
          containerClassName += " team-image-container-selected";
        }
        if (opponentTeamId === team.engId) {
          containerClassName += " team-image-container-opponent";
        }

        return (
          <div className={containerClassName} key={team.engId} >
            <img
              src={`${API_URL}/images/franchise/userFranchise/${team.img}`}
              alt={`${team.teamName} logo`}
              onClick={() => setSelectedTeamId(team.engId)}
            />
          </div>
        )
      })}
    </div>
  );
};
