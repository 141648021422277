import { alignAllColumnsToCenter } from "components/FranchiseOverview/TabDetail/DepthChartTab/helper";
import { sumObjectsByKeys } from "helper/helper";
import { Table } from "antd";
import { CareerStats } from "./PlayerCareerStats";
import { formatKeyWithSpaces } from "./utils/formatKey";
import { transformObject } from "./utils/transformObject";

type Props = {
  careerStats: CareerStats;
};

export const PlayerOnFieldCareerStats: React.FC<Props> = ({ careerStats }) => {
  const keys = [];
  if (Object.keys(careerStats.elimination).length === 0) {
    keys.push(...Object.keys(careerStats.h2h));
  } else {
    keys.push(...Object.keys(careerStats.elimination));
  }
  const zeroObj = Object.fromEntries(keys.map((key) => [key, 0]));

  const activeLeaguesColumns: any = [
    {
      title: "",
      dataIndex: "title",
      width: 250,
    },
    ...keys.map((key) => ({
      title: formatKeyWithSpaces(key),
      dataIndex: key,
    })),
  ];
  const activeLeaguesData = [
     {
        key: "1",
        title: "SEASON",
        ...transformObject(zeroObj, () => 0),
     },
     {
        key: "2",
        title: "ELIMINATION",
        ...transformObject(
           JSON.stringify(careerStats.elimination) === "{}" ? zeroObj : careerStats.elimination,
           (value) => <span>{isNaN(parseInt(value)) ? value : parseInt(value).toFixed(0)}</span>
        ),
     },
     {
        key: "3",
        title: "HEAD-TO-HEAD",
        ...transformObject(
           JSON.stringify(careerStats.h2h) === "{}" ? zeroObj : careerStats.h2h,
           (value) => <span>{isNaN(parseInt(value)) ? value : parseInt(value).toFixed(0)}</span>
        ),
     },
     {
        key: "4",
        title: "TOTAL",
        ...transformObject(
           sumObjectsByKeys([careerStats.elimination, careerStats.h2h]),
           (value) => (
              <span style={{ fontWeight: "bolder" }}>
                 {isNaN(parseInt(value)) ? value : parseInt(value).toFixed(0)}
              </span>
           )
        ),
     },
  ];
  
  if (
    JSON.stringify(careerStats.elimination) === "{}" &&
    JSON.stringify(careerStats.h2h) === "{}"
  ) {
    return null;
  }

  return (
    <div className="standingmain" style={{ marginBottom: 0 }}>
      <Table
        prefixCls="career-stats-table ant-table"
        columns={alignAllColumnsToCenter(activeLeaguesColumns)}
        pagination={false}
        dataSource={activeLeaguesData}
        size="small"
      />
    </div>
  );
};
