import { Col, Row } from "react-bootstrap"
import history from "routes/history";
import { SeasonGame } from "../types";

type Props = {
  round: SeasonGame;
  franchiseId: number;
}

export const SeasonDetails: React.FC<Props> = ({
  round,
  franchiseId,
}) => {

  const handleEliminationRoundClick = (gid: number) => {
    history.push(`/stand`, { gid, id: +franchiseId });
  };

  return (
    <Row>
      <Col xs="2">
        {round.week}
      </Col>
      <Col xs="3">
        {round.opponent.name} ({round.opponent.w}-{round.opponent.t}-{round.opponent.l})
      </Col>
      <Col xs="3">
        {round.team.status} {round.ptsW}-{round.ptsL} ({round.team.w}-{round.team.t}-{round.team.l})
      </Col>
      <Col xs="2">
        <span
          className="result-card-game-detail-link"
          onClick={() => handleEliminationRoundClick(round.gid)}
        >
          GAME DETAIL
        </span>
      </Col>
    </Row>
  )
}