import { LeagueShortInfo } from 'components/LeagueShortInfo/LeagueShortInfo';
import { getPrizePool } from 'helper/helper';
import { useFeeRates } from 'helper/useFeeRates';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const EliminationLeagueInfo: React.FC = () => {
  const eliminationData = useSelector((state: RootState) => state.gameEngine.eliminationOverview);
  const { WALLET_TRANSACTION_FEE } = useFeeRates();

  if (!eliminationData || WALLET_TRANSACTION_FEE === undefined) {
    return null;
  }

  return (
    <LeagueShortInfo 
      type="ELIMINATION"
      name={eliminationData.name}
      numberOfTeams={eliminationData.numberOfTeams}
      prize={getPrizePool(eliminationData.fee, 100 - WALLET_TRANSACTION_FEE, eliminationData.numberOfTeams)}
      ratingRangeMin={eliminationData.ratingRangeMin}
      ratingRangeMax={eliminationData.ratingRangeMax}
      startAt={eliminationData.startAt}
      trophyId={eliminationData?.trophyId}
      groupId={eliminationData?.groupId}
    />
  )
};
