import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { ViewMoreTypes } from "../LeaderboardContent";
import { useEffect } from "react";

export default function useViewMoreData(page: number | void) {
  const dispatch = useDispatch();
  const viewMoreType = useSelector(
    (state: RootState) => state.gameEngine.teamLeadersViewMoreType
  ) as keyof typeof ViewMoreTypes;
  const viewMoreData = useSelector(
    (state: RootState) => state.gameEngine.teamLeadersViewMoreData
  );
  const viewMoreLoader: boolean = useSelector(
    (state: RootState) => state.gameEngine.teamLeadersViewMoreLoader
  );
  const noNextPage: boolean = useSelector(
    (state: RootState) => state.gameEngine.teamLeadersViewMoreNoNextPage
  );

  useEffect(() => {
    if (viewMoreType) {
      dispatch(ViewMoreTypes[viewMoreType](page));
    }
  }, [viewMoreType, page]);

  return { viewMoreType, viewMoreData, viewMoreLoader, noNextPage };
}
