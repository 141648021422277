import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getWeeklyLeadersWins  } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useWeeklyLeadersWins() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWeeklyLeadersWins());
  }, []);

  const weeklyLeadersWins: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.weeklyLeadersWins);

  return { weeklyLeadersWins };
}