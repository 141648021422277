/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./ImportFranchiseStyle.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Divider } from "antd";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import ImportFranchiseContent from "./Common/ImportFranchiseContent";
import ImportWalletConnected from "./ImportWalletConnected/ImportWalletConnected";
import Footer from "../Common/Footer/Footer";
import AppHeader from "../Common/Header/AppHeader";
import OwnFranchiseHeader from "../OwnFranchise/OwnFranchiseHeader";

const ImportFranchiseMain = () => {
  return (
    <>
      <Container>
        {/* <ImportFranchiseHeader /> */}
        <OwnFranchiseHeader /> 
        <div className="mb-5 pb-2 purfranchisemain">
          <Card>
            <CardBody>
              <Row>
                <Col xl={7}>
                  <ImportFranchiseContent />
                </Col>
                <Col className="verticaldivider">
                  <Divider type="vertical" />
                </Col>
                <Col xl={4}>
                  {/* CONNECT WALLET */}
                  <ImportWalletConnected connectwalletoption />

                  {/* CONNECTED WALLET */}
                  {/* <ImportWalletConnected walletconnected /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default ImportFranchiseMain;
