import LoadingScreen from 'components/Common/LoadingScreen/LoadingScreen';
import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import { useLoading } from 'components/FranchiseOverview/hooks/use-loading';
import { MintRoster } from 'components/MintRoster';
// import MinthPlayerMain from "../../components/MintPlayer/MinthPlayerMain";

const MintingAssetsScreen: React.FC = () => {
  const loader = useLoading();
  return (
    <>
      {loader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <MintRoster />
      </div>
    </>
  );
};

export default MintingAssetsScreen;
