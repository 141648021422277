import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import styles from "./ChangeButton.module.scss";
import { Tooltip } from "antd";

type Props = {
  disabled?: boolean;
  onClick?: () => void;
};

export const ChangeButton: React.FC<Props> = ({ disabled, onClick }) => {
  const button = (
    <BlueButton 
      className={disabled ? "" : styles.animated} 
      text="CHANGE STRATEGY!" 
      disabled={disabled} 
      onClick={onClick}
    />
  );

  return (
    <div className={`${styles.container}`}>
      {disabled
        ? (
          <Tooltip trigger={["hover"]} title="Accessible between quarters" placement="bottom">
            <> {/* tooltip is not opening without fragment*/}
              {button}
            </>
          </Tooltip>
        ) 
        : button}
    </div>
  );
};
