import { createStore, applyMiddleware, compose, Middleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "../reducers";
import sagas from "../sagas";
import { Api } from "libs/ws/Api";
import { getFromLocalStorage } from "libs/storage";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// middleware that logs actions
const loggerMiddleware = createLogger({ predicate: () => true });

// @ts-ignore
const apiMiddleware =
  // @ts-ignore

    ({ dispatch, getState }) =>
    // @ts-ignore
    (next) =>
    // @ts-ignore
    (action) => {
      if (typeof action === "function" && getFromLocalStorage("token")) {
        return action(
          dispatch,
          getState,
          Api.getInstance(process.env.REACT_APP_API_URL_WS)
        );
      }

      return next(action);
    };

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

//@ts-ignore
const store = createStore(
  reducers,
  //@ts-ignore
  composeWithDevTools(
    applyMiddleware(apiMiddleware, loggerMiddleware, thunk, sagaMiddleware)
  )
);

// run the saga
sagaMiddleware.run(sagas);

export default store;

export type RootState = ReturnType<typeof store.getState>;
