import FranchiseDetail from '../Common/FranchiseDetail/FranchiseDetail';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { API_URL } from '../../config';
import React, { useEffect, useMemo } from 'react';
import Stickers from '../../assets/images/league/leagueImage.jpg';
import { getTeamOverview, UserRecordAPI } from '../../redux/actions/GameEngineAction';
import { fetchSelectedAvatar } from 'redux/actions/TeamAction';

type Props = {
  engId?: number;
};

const AnotherTeamHeader: React.FC<Props> = ({ engId }) => {
  const dispatch = useDispatch();

  const teamOverviewData = useSelector((state: RootState) => state?.gameEngine?.teamOverview);
  const HeaderData = useSelector((state: RootState) => state?.gameEngine?.UserRecord);
  const avatar = useSelector((state: RootState) => state.team.fetchedAvatar);

  useEffect(() => {
    if (engId) {
      dispatch(getTeamOverview(1, +engId));
      dispatch(fetchSelectedAvatar(+engId));
      dispatch(UserRecordAPI(+engId));
    }
  }, [engId]);

  const data1 = useMemo(
    () => ({
      id: 1,
      profileimage: avatar ? `${API_URL}/images/franchise/userFranchise/${avatar.img}` : Stickers,
      protfolioname: avatar?.teamName || '---',
      usertitle: 'FRANCHISE :',
      username: avatar?.teamName || '---',
      currentleaguetitle: 'RATING :',
      currentleague: HeaderData?.ovr === undefined ? 0 : HeaderData?.ovr,
      joinedtitle: 'FOUNDED :',
      joined: avatar ? new Date(avatar?.created).toDateString() : '---',
      wallettitle: 'CITY :',
      wallet: teamOverviewData?.region || '---',
      win: 'RECORD',
      windigit:
        HeaderData?.won === undefined && HeaderData?.lost === undefined && HeaderData?.tied === undefined
          ? `0-0-0`
          : `${HeaderData?.won}-${HeaderData?.lost}-${HeaderData?.tied}`,
      winpercentage: 'WIN %',
      winpercentagedigit: HeaderData?.pct === undefined ? `0 %` : HeaderData?.pct,
      rating: 'RATING',
      ownerUserNameLabel: 'OWNER',
      ownerUserName: teamOverviewData?.userName,
      ownerUserId: teamOverviewData?.userId,
      ratingdigit: HeaderData?.ovr === undefined ? 0 : HeaderData?.ovr,
      ratingStatus: teamOverviewData?.ratingStatus ? teamOverviewData?.ratingStatus : '--',
    }),
    [
      HeaderData?.lost,
      HeaderData?.ovr,
      HeaderData?.pct,
      HeaderData?.tied,
      HeaderData?.won,
      avatar,
      teamOverviewData?.ratingStatus,
      teamOverviewData?.region,
      teamOverviewData?.userId,
      teamOverviewData?.userName,
    ],
  );

  return <FranchiseDetail rating arrow data={data1} />;
};

export default AnotherTeamHeader;
