import WhiteButton from "../Button/WhiteButton/WhiteButton";
import BlueButton from "../Button/BlueButton/BlueButton";
import "./PlayNowModal.scss";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import SelectLoginModal from "../SelectLoginModal/SelectLoginModal";
import SignUpStepFormModal from "../SignUpStepFormModal/SignUpStepFormModal";
import { useDispatch } from "react-redux";

type Props = {
  setLoading?: any;
  closeModal: () => void;
  isSignUp?: boolean;
};
const PlayNowModal = ({ 
  setLoading,
  closeModal,
  isSignUp
 }: Props) => {
  const dispatch = useDispatch();
  const [isModalSignInVisible, setIsModalSignInVisible] = useState(false);
  const [isModalCreateAccountVisible, setIsModalCreateAccountVisible] =
    useState(false);
  const [msg, setMsg] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);

  const showSingInModal = () => {
    setIsModalSignInVisible(true);
    closeModal();
  };
  const showCreateAccountModal = () => {
    setIsModalCreateAccountVisible(true);
    closeModal();
  };
  const handleSignInOk = () => {
    setIsModalSignInVisible(false);
  };
  const handleSignInCancel = () => {
    setIsModalSignInVisible(false);
  };
  const handleCreateAccountCancel = () => {
    setIsModalCreateAccountVisible(false);
    setMsg("");
    setCurrentStep(0);
  };

  useEffect(() => {
    if (!!isSignUp) {
      setIsModalCreateAccountVisible(true);
    }
  }, [isSignUp]);

  return (
    <>
      <div className="play-now-wrapper">
        <h5>BE MORE THAN AN ARMCHAIR QUARTERBACK...</h5>
        <h5>GET IN THE GAME!</h5>
        <div className="play-now-login-type-wrapper">
          <div className="play-now-login-type">
            <p>ROOKIE: NEED AN ACCOUNT</p>
            {/* <Link to="/signup"> */}
              <WhiteButton text="GET ONE" onClick={showCreateAccountModal} />
            {/* </Link> */}
          </div>
          <div className="play-now-login-type">
            <p>VETERAN: HAVE AN ACCOUNT</p>
            <BlueButton text="SIGN IN" onClick={() => {
                showCreateAccountModal();
                setCurrentStep(3);
              }} />
          </div>
        </div>
      </div>
      <Modal
        title=""
        width="auto"
        className="metamask_connect"
        open={isModalSignInVisible}
        onOk={handleSignInOk}
        onCancel={handleSignInCancel}
        footer={[]}
        centered
      >
        <SelectLoginModal setLoading={setLoading} />
      </Modal>
      <Modal
        title=""
        width="auto"
        open={isModalCreateAccountVisible}
        onCancel={handleCreateAccountCancel}
        footer={[]}
        centered
      >
        <SignUpStepFormModal
          msg={msg}
          setMsg={setMsg}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      </Modal>
    </>
  );
};

export default PlayNowModal;
