import { Row, Col } from "react-bootstrap";
import "./BoxScoreStyle.scss";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import Team1Main from "./BoxScoreTeam1/Team1Main";

type Props = {
  team1: any;
  team2: any;
};

const BoxScoreMain: React.FC<Props> = ({ team1, team2 }) => (
  <div className="mt-3 pb-4">
    <Card>
      <CardBody>
        <Row>
          <Col xl={6}>
            {team1 && <Team1Main team={team1} />}
          </Col>
          <Col xl={6}>
            {team2 && <Team1Main team={team2} />}
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>
);

export default BoxScoreMain;
