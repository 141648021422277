import * as types from "../actions/settingTypes";
import { saveDecreaseCount } from "../../helper/helper";

export interface settingStateIF {
  loader: number;
  error: string | null;
  success: string | boolean | null;
  setting: any | null;
}

const initialState: settingStateIF = {
  loader: 0,
  error: null,
  success: null,
  setting: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, ...action.payload };

    case types.GETSETTING_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GETSETTING_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        setting: action.payload.setting,
      };
    case types.GETSETTING_FAIL:
      return { ...state, loader: saveDecreaseCount(state.loader), error: action.error };

    default:
      return state;
  }
};

export default reducer;
