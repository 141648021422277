import { notification } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { joinLeagueAPI } from 'redux/actions/GameEngineAction';

type GameData = {
  type?: 'H' | 'E' | 'S';
  gid?: string | number;
  engId?: number;
  joinCode?: string;
};

export function useEnterGame(leagueData: GameData) {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);

  const gid = leagueData.gid;
  const type = leagueData.type;
  const engId = leagueData.engId;

  const joinGame = async () => {
    if (!gid || !engId || !type) return;
    setIsDisabled(true);

    if (!leagueData) {
      notification.error({
        message: 'Error',
        description: 'Unexpected error',
        placement: 'bottomRight',
      });
      setIsDisabled(false);
      return;
    }

    dispatch(
      joinLeagueAPI({
        gid,
        utid: engId,
        type,
        joinCode: leagueData?.joinCode,
        isLastTeam: false,
        leagueData,
        qb: () => setIsDisabled(false),
      }),
    );
  };

  return { isDisabled, joinGame };
}
