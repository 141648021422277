import { ReactNode, useState } from 'react';

type Props = {
  text: string | string[];
  children?: ReactNode;
  containerClassName?: string
}

export const EliminationTeamsTooltip: React.FC<Props> = ({ text, children, containerClassName }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  let classForContainer = 'elimination-teams-tooltip-container';

  if (containerClassName) {
    classForContainer += ` ${containerClassName}`;
  }

  const handleMouseEnter = () => {
    if (Array.isArray(text) && text.length < 3) return;
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={classForContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: (Array.isArray(text) && text.length > 3) ? 'pointer' : 'default' }}
    >
      {children}
      <div
        className='elimination-teams-tooltip-text'
        style={{
          maxHeight: showTooltip ? '100px' : '0',
          opacity: showTooltip ? 1 : 0,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
        }}
      >
        {Array.isArray(text) ? text.join(', ') : text}
      </div>
    </div>
  );
};
