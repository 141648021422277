import { Modal } from "antd";
import { Row, Col } from "react-bootstrap";
import { API_URL } from "config";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

type Props = {
  open: boolean;
  onCancel: () => void;
  team: {
    image: string;
    name: string;
    bg: number;
    helmet: number;
    logo: number;
  };
}

export const PreviewModal: React.FC<Props> = ({
  open,
  onCancel,
  team
}) => {
  const avData = useSelector((state: RootState) => state.team.avatar);
  const helmet = avData?.helmet?.find((h: { id: number }) => h.id === team.helmet);
  const logoURL = avData?.logo?.find((h: { id: number }) => h.id === team.logo)?.url;
  const bg = avData?.bg?.find((h: { id: number }) => h.id === team.bg);

  if (!helmet || !logoURL || !bg) {
    return null;
  }

  const helmetColor = helmet?.name;
  const backgroundColor = bg?.name;

  return (
    <Modal
      open={open}
      className="verification PreviewModal"
      footer={null}
      onCancel={onCancel}
    >
      <div className="" id="captureAvatar">
        <h3>Preview</h3>
        <div className="previewcontent">
          <Col xl={12}>
            <Row>
              <Col sm={7}>
                <div className="previewimage">
                  <div id="imagePreviewDiv">
                    <img src={team?.image} alt="teamImage" />
                  </div>
                </div>
              </Col>
              <Col sm={5}>
                <div className="previewdetail">
                  <h5>Franchise Name</h5>
                  <h6 className="subtitle">{team?.name}</h6>
                  <Row className="align-items-center choosehead">
                    <Col
                      lg={8}
                      sm={6}
                      xs={6}
                      className="d-inline-flex align-items-center"
                    >
                      <h6 className="colorhead">Primary color</h6>
                    </Col>
                    <Col lg={4} sm={6} xs={6}>
                      <div className="color-choose">
                        <div
                          style={{
                            background: `#${backgroundColor}`,
                          }}
                          className="colorview"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-center choosehead">
                    <Col
                      lg={8}
                      sm={6}
                      xs={6}
                      className="d-inline-flex align-items-center"
                    >
                      <h6 className="colorhead">Helmets color</h6>
                    </Col>
                    <Col lg={4} sm={6} xs={6}>
                      <div className="color-choose">
                        <div
                          style={{
                            background: `#${helmetColor}`,
                          }}
                          className="colorview"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-center logo-select">
                    <Col
                      lg={4}
                      sm={6}
                      xs={6}
                      className="d-inline-flex align-items-center"
                    >
                      <h6 className="colorhead">Logo</h6>
                    </Col>
                    <Col lg={4} sm={6} xs={6}>
                      <div className="color-choose">
                        <div className="logo-box">
                          <img
                            src={`${API_URL}${logoURL}`}
                            alt="logo"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </Modal>
  );
}
