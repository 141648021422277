import socketIOClient from "socket.io-client";
import { GAME_ENGINE_API_URL } from "./config";
import store from "./redux/store";
import { getUserActiveGames } from './redux/actions/GameEngineAction';
import history from "../src/routes/history";
import { League } from "components/Common/LeagueHeader/LeagueHeader";

export const socket = socketIOClient(GAME_ENGINE_API_URL as string);

const redirect = (gid: number | string, type: League['type']) => {
    if (type === 'H') {
        history.push("/leagueheadtohead", gid)
    } else if (type === 'E') {
        history.push(`/leagueelimination?gid=${gid}`);
    } else if (type === "S") {
        history.push(`/season/${gid}`);
    }
}

function onGameReady(data: { gid: number, type: League['type'] }) {
    const userId = store.getState().app.resData?.id;

    if (userId) {
        store.dispatch(getUserActiveGames(userId));
    }

    redirect(data.gid, data.type);
}

socket.on("game_is_ready", onGameReady);
