import { Trophies } from "components/Profile/components";
import { getPageSize } from "components/Profile/components/common/Trophies/UserTrophies";
import { useGroupTrophiesPaginated } from "helper/useGroupTrophiesPaginated";
import { useHandleScreenSize } from "helper/useHandleScreenSize";
import { useServerSidePagination } from "helper/useServerSidePagination";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const GroupTrophies: React.FC = () => {
  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const group = useSelector((state: RootState) => state.group.groupInfo);
  const { pageWidth } = useHandleScreenSize();
  const {
    handleNextPage,
    handlePrevPage,
    page
  } = useServerSidePagination();
  const data = useGroupTrophiesPaginated(
    group?.id || 0,
    page,
    getPageSize(pageWidth)
  )
  if (!data) {
    return null;
  }

  const isOwnGroup = group?.members.some(member => member.user.id === userId && member.role.name === 'owner');

  return (
    <Trophies 
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      page={page}
      data={data}
      isOwn={isOwnGroup}
    />
  )
};
