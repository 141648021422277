/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LeagueStandingStyle.scss";
import { Container } from "react-bootstrap";
import LeagueStandingTabMain from "./LeagueStandingTabMain";
import history from "../../routes/history";
import BoxScoreHeader from "components/BoxScore/BoxScoreHeader";
import { useIsEliminationRound } from "./hooks/useIsEliminationRound";
import { useEliminationOverview } from "components/LeagueOverviewElimination/TabDetail/ScheduleTab/hooks/useEliminationOverview";
import LeagueEliminationHeader from "components/LeagueOverviewElimination/LeagueEliminationHeader";

const LeagueStandingMain: React.FC = () => {
  const leagueData = history.location.state as { gid?: number };
  const { eliminationId } = useIsEliminationRound(leagueData?.gid);

  useEliminationOverview(eliminationId || undefined);

  return (
    <Container>
      {!eliminationId 
      ? <BoxScoreHeader gameId={String(leagueData.gid)} />
      : <LeagueEliminationHeader />}
      <LeagueStandingTabMain />
    </Container>
  );
};

export default LeagueStandingMain;
