import HomeNews from "./HomeNews";
import HomeVideoBanner from "./HomeVideoBanner";
import HomeYourTeam from "./HomeYourTeam";
import HomeYourDynasty from "./HomeYourDynasty";

const HomeContent: React.FC = () => (
  <>
    {/*<Container>*/}
    <HomeNews />
    <HomeVideoBanner />
    <HomeYourTeam />
    <HomeYourDynasty />
    {/*</Container>*/}
  </>
);

export default HomeContent;
