import { useState } from 'react';
import EmailLoginForm from '../EmailLogin/EmailLoginForm';
import MetamaskLoginSmall from '../SelectLoginModal/content/Metamask/MetamaskLoginSmall';
import { SignInWithGoogle } from '../SignUpStepFormModal/SignInWithGoogle';

type Props = {
  redirectToSignUp: () => void;
};

export const LogIn: React.FC<Props> = ({ redirectToSignUp }) => {
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeToggle = () => {
    setRememberMe((p) => !p);
  };

  return (
    <>
      <EmailLoginForm isFromModal rememberMe={rememberMe} toggleRememberMe={handleRememberMeToggle} />
      <div className="sign-in-footer">
        <MetamaskLoginSmall rememberMe={rememberMe} />
        <div className="username-or-email" onClick={redirectToSignUp}>
          DON`T HAVE AN ACCOUNT?
        </div>
      </div>
      <SignInWithGoogle rememberMe={rememberMe} />
    </>
  );
};
