import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import PrivacyMain from "../../components/Privacy/PrivacyMain";

const PrivacyScreen: React.FC = () => (
    <div className="pagelayout" >
        <WithLoginNavbar />
        <PrivacyMain />
    </div>
);

export default PrivacyScreen;