import { API_URL } from "config"
import { get } from "redux/services/Api"
import { NotificationSettings } from "types";

export const getNotificationSettings = async(id: number) => {
  try {
    return await get<NotificationSettings | null>(`${API_URL}/user/disabled-notifications?id=${id}`);
  } catch (e) {
    console.log(e);
    return null;
  }
}