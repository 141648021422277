import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginGoogle } from 'redux/actions/AuthAction';

export const useGoogleAuth = (rememberMe = false) => {
  const dispatch = useDispatch();
  const [gUser, setGUser] = useState<Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'> | null>(null);

  const googleLogIn = useGoogleLogin({
    onSuccess: (codeResponse) => setGUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
  });

  useEffect(() => {
    if (gUser) {
      dispatch(loginGoogle(gUser.access_token, rememberMe));
    }
  }, [gUser, rememberMe]);

  return { googleLogIn };
};
