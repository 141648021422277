import { File } from "./file";
import { PaginationResponse } from "./pagination";
import { NetworkStatus, User } from "./user";

export interface GroupEntity {
  id: number;
  name: string;
  description?: string;
  avatar: string | null;
  autoApprove: boolean;
  startedAt: Date;
  leaguaCreation: LeaguaCreationAccess;
  noOfTrophies?: number;
  prestigePoints?: number;
}

export enum GroupMemershipStatus {
  APPROVED = 'approved',
  REQUESTED = 'requested',
  REJECTED = "rejected",
  INVITED = 'invited',
}

export interface Group extends GroupEntity {
  members: GroupMember[];
}

export type GroupListItem = Pick<GroupEntity, "id" | "name" | "avatar"> & {
  role: GroupRoles;
};

export type UpdateGroupListPayload = {
  groupsList: PaginationResponse<GroupListItem> | null;
  groupsInvitations: GroupInvitation[] | null;
}

export type GroupDropdownItem = Pick<GroupEntity, "id" | "name">;

interface GroupMemberEntity {
  status: GroupMemershipStatus;
  userId: number;
  groupId: number;
  roleId: number;
}

export type GroupMemberUser = Pick<User, "avatar" | "userName" | "id">

export interface GroupMemberUserWithNetworkStatus extends GroupMemberUser {
  networkStatus?: NetworkStatus;
};

export type GroupMember = Pick<
  GroupMemberEntity,
  "status" | "groupId"
> & {
  user: GroupMemberUserWithNetworkStatus;
  role: GroupRole;
};

interface GroupRoleEntity {
  id: number;
  name: GroupRoles;
}

export type GroupRole = Pick<GroupRoleEntity, "name"> & {
  permissions: string[];
};

export enum GroupRoles {
  OWNER = "owner",
  ADMIN = "admin",
  MEMBER = "member",
}

export enum LeaguaCreationAccess {
  ADMIN = "admin",
  ALL = "all",
}

export type GroupRequest = Pick<
  GroupEntity,
  "description" | "autoApprove" | "leaguaCreation" | "name"
> & { avatar?: File };

export type GroupUpdateRequest = Partial<GroupRequest>;

export type GroupCreate = Omit<GroupEntity, "id" | "startedAt">;

export interface UpdateGroupMemberNetworkStatusData {
  memberId: number;
  status: NetworkStatus;
}

export type FoundUser = Pick<User, "id" | "userName" | "avatar" | "email">

export type GroupInvitation = Pick<GroupEntity, "id" | "name" | "avatar">

export type GroupName = Pick<GroupEntity, "name" | "id">;
