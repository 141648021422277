import { RenderTrophy } from 'components/Common/RenderTrophy/RenderTrophy';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import history from '../../../../routes/history';
import { useParams } from 'react-router-dom';
import { useTeamTrophies } from './hooks/useTeamTrophies';
import { Fragment } from 'react';

type Props = {
  engId?: number;
};

const OverviewLeagueTrophies: React.FC<Props> = ({ engId }) => {
  const franchiseData: { id: number } = history.location.state as { id: number };
  let { franchiseId }: any = useParams();

  if (!franchiseId) {
    franchiseId = franchiseData?.id;
  }
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);
  const utid = engId || userTeams?.find((team) => team.id === +franchiseId)?.engId || franchiseId;
  const trophies = useTeamTrophies(utid);

  return (
    <div className="mb-5">
      <h5 className="title">TEAM TROPHY & AWARDS</h5>
      {!!trophies.length ? (
        <Row>
          {trophies.map((id) => {
            return (
              <Fragment key={id}>
                <RenderTrophy trophyId={id} mode="full" style={{ width: '200px' }} />
              </Fragment>
            );
          })}
        </Row>
      ) : (
        <Row>
          <h5>No trophies</h5>
        </Row>
      )}
    </div>
  );
};

export default OverviewLeagueTrophies;
