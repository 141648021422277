import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EliminationActions } from 'redux/actions/Elimination';
import { RootState } from 'redux/store';

export const useEliminationResults = (gid: number) => {
  const dispatch = useDispatch();
  const eliminationResults = useSelector((state: RootState) => state.gameEngine.leagueResults);

  useEffect(() => {
    dispatch(EliminationActions.getEliminationResults(gid));
  }, []);

  return eliminationResults;
};
