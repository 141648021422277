import { Table } from "antd";
import { transformObject } from "./utils/transformObject";
import { formatKeyWithSpaces } from "./utils/formatKey";
import { sumObjectsByKeys } from "helper/helper";
import { alignAllColumnsToCenter } from "components/FranchiseOverview/TabDetail/DepthChartTab/helper";
import { checkIfCoachCareerStats } from "./utils/checkIfCoachCareerStats";
import { PlayerOnFieldCareerStats } from "./PlayerOnFieldCareerStats";
import { CoachCareerStats } from "./CoachCareerStats";
import { CoachCareerStatsType } from "./types";

export type CareerStats = {
  elimination: Record<string, number>;
  h2h: Record<string, number>;
};

type Props = {
  careerStats?: CareerStats;
};

const PlayerCareerStats: React.FC<Props> = ({ careerStats }) => {
  if (!careerStats || !careerStats?.elimination || !careerStats?.h2h) return null;
  const isCoachStats = checkIfCoachCareerStats(careerStats);

  if (!isCoachStats) {
    return (
      <PlayerOnFieldCareerStats careerStats={careerStats} />
    )
  }

  return (
    <div className="standingmain" style={{ marginBottom: 0 }}>
      <CoachCareerStats careerStats={careerStats as CoachCareerStatsType}/>
    </div>
  );
};

export default PlayerCareerStats;
