/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Title from "../Common/Title/Title";
import { Divider, Table } from "antd";

const DraftPicks = () => {
  const offensiveLineColumns = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "",
      dataIndex: "ranks",
    },
    {
      title: "",
      dataIndex: "pos",
    },
  ];
  const offensiveLinekData = [
    {
      key: "1",
      title: (
        <>
          <h5 className="logpoint">1.01</h5>
        </>
      ),
      ranks: (
        <>
          <div className="draftplayer">
            <h6>WALTER PAYTON</h6>
            <span>RANK : 3</span>
            <span>RATING : 56</span>
          </div>
        </>
      ),
      pos: (
        <>
          <h5 className="draftposititon">RB</h5>
        </>
      ),
    },
    {
      key: "2",
      title: (
        <>
          <h5 className="logpoint">1.01</h5>
        </>
      ),
      ranks: (
        <>
          <div className="draftplayer">
            <h6>WALTER PAYTON</h6>
            <span>RANK : 3</span>
            <span>RATING : 56</span>
          </div>
        </>
      ),
      pos: (
        <>
          <h5 className="draftposititon">RB</h5>
        </>
      ),
    },
    {
      key: "3",
      title: (
        <>
          <h5 className="logpoint">1.01</h5>
        </>
      ),
      ranks: (
        <>
          <div className="draftplayer">
            <h6>WALTER PAYTON</h6>
            <span>RANK : 3</span>
            <span>RATING : 56</span>
          </div>
        </>
      ),
      pos: (
        <>
          <h5 className="draftposititon">RB</h5>
        </>
      ),
    },
    {
      key: "4",
      title: (
        <>
          <h5 className="logpoint">1.01</h5>
        </>
      ),
      ranks: (
        <>
          <div className="draftplayer">
            <h6>WALTER PAYTON</h6>
            <span>RANK : 3</span>
            <span>RATING : 56</span>
          </div>
        </>
      ),
      pos: (
        <>
          <h5 className="draftposititon">RB</h5>
        </>
      ),
    },
    {
      key: "5",
      title: (
        <>
          <h5 className="logpoint">1.01</h5>
        </>
      ),
      ranks: (
        <>
          <div className="draftplayer">
            <h6>WALTER PAYTON</h6>
            <span>RANK : 3</span>
            <span>RATING : 56</span>
          </div>
        </>
      ),
      pos: (
        <>
          <h5 className="draftposititon">RB</h5>
        </>
      ),
    },
    {
      key: "6",
      title: (
        <>
          <h5 className="logpoint">1.01</h5>
        </>
      ),
      ranks: (
        <>
          <div className="draftplayer">
            <h6>WALTER PAYTON</h6>
            <span>RANK : 3</span>
            <span>RATING : 56</span>
          </div>
        </>
      ),
      pos: (
        <>
          <h5 className="draftposititon">RB</h5>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="standingmain draftlogmain mt-4">
        <Title text="MY DRAFT PICKS" />
        <Table
          columns={offensiveLineColumns}
          pagination={false}
          dataSource={offensiveLinekData}
          size="small"
        />
      </div>
    </>
  );
};

export default DraftPicks;
