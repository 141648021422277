import { Table } from 'antd';
import { getSeasonTableColumns } from '../constants';
import { SeasonInfo } from 'screens/Season/types';
import { prepareTeams } from '../helper';
import { Fragment } from 'react';
import { useSeasonConferenceStats } from 'screens/Season/hooks/useSeasonConferenceStats';
import { defaultStats } from './constants';

type Props = {
  info: SeasonInfo;
};

export const Division: React.FC<Props> = ({ info }) => {
  const { stats } = useSeasonConferenceStats(info?.id);

  return (
    <>
      {info?.conferences?.map(conference => (
        <Fragment key={conference.id}>
          <h5 className="title mb-2">{conference?.title}</h5>
          {conference?.divisions?.map(division => {
            const divisionTitle = division?.title;
            const teams = division?.teams?.map(team => {
              const statsForTeam = stats.find(stat => stat?._id === team?.team?.utid) || defaultStats;
              return {
                utid: team.team?.utid,
                name: team.team?.name,
                ...statsForTeam
              };
            }) || [];

            return (
              <div key={division?.id} className="division-section">
                <h6 className="subtitle mb-2">{divisionTitle}</h6>
                <Table
                  columns={getSeasonTableColumns(divisionTitle)}
                  pagination={false}
                  dataSource={prepareTeams(teams)}
                  size="small"
                  className="ratingsection mb-4"
                />
              </div>
            );
          })}
        </Fragment>
      ))}
    </>
  );
};
