import './StatsTabStyle.scss';
import Card from '../../../Common/Card/Card';
import CardBody from '../../../Common/Card/CardBody';
import { Row, Col } from 'react-bootstrap';
import StatsFranchisePerformance from './StatsFranchisePerformance';
import StatsTeamLeader from './StatsTeamLeader';
import StatsTeamRating from './StatsTeamRating';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const StatsMain: React.FC = () => {
  const { franchiseId }: any = useParams();
  const userTeamList = useSelector((state: RootState) => state.team.userTeamsList);

  const franchise = userTeamList?.find((t) => t.id === +franchiseId);

  return (
    <Row>
      <Col xl={12} className="mb-5">
        <Card>
          <CardBody>
            <StatsFranchisePerformance engId={franchise?.engId} />
            <StatsTeamLeader engId={franchise?.engId} id={franchise?.id} />
            <StatsTeamRating />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default StatsMain;
