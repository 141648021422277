import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { Spinner } from 'react-bootstrap';

type Props = {
  handleDeleteAccount: () => void;
  loading: boolean;
};

export const AccountDeletionContent: React.FC<Props> = ({ handleDeleteAccount, loading }) => (
  <Card>
    <CardBody>
      <div className="d-flex flex-column align-items-center account-deletion-content">
        <p className="fs-4">
          Users may delete their accounts at any time through the app's settings menu. Upon deletion, all personal data associated with the account, including user
          profiles, our age data, and preferences, will be permanently removed from our servers within 90 days of request. As our Privacy Policy outlines, some data may
          be retained for legal, security, or operational purposes. Users may contact our customer support if they wish to retrieve or delete data before the automatic
          deletion process. Note that account deletion is irreversible and users will need to create a new account if they wish to access our services in the future.
        </p>

        <PinkButton className="mt-4 mb-4" onClick={handleDeleteAccount} text={loading ? <Spinner /> : 'CONFIRM ACCOUNT DELETION'} disabled={loading} />
      </div>
    </CardBody>
  </Card>
);
