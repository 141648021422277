import { useInfiniteScroll } from "helper/useInfiniteScroll";
import { useServerSidePagination } from "helper/useServerSidePagination";
import { useRef } from "react";
import { LobbyList } from "../common/LobbyList";
import { LobbyListItemType } from "../common/types";
import fallbackImage from "../../../assets/images/common/socialGroup.svg";
import { useLobbyGroups } from "./hooks/useLobbyGroups";
import { getAzureStorageFileUrl } from "helper/helper";

const GROUPS_PER_PAGE = 3;

export const LobbyGroups = () => {
  const ref = useRef(null);
  const { page, handleNextPage } = useServerSidePagination();
  const { lobbyGroups, isLoading, noNextPage } = useLobbyGroups(page, GROUPS_PER_PAGE);
  useInfiniteScroll(ref, handleNextPage, isLoading, noNextPage);

  const lobbyListItems: LobbyListItemType[] = lobbyGroups.map((group) => ({
    image: group?.avatar ? getAzureStorageFileUrl(group.avatar) || fallbackImage : fallbackImage,
    name: group.name,
    text: `Members | ${group.memberCount}`,
    rightNumber: !Number.isNaN(+String(group?.totalPrestigePoints)) ? `${group.totalPrestigePoints}` : "0",
    id: group.id,
  }));

  return <LobbyList title="ACTIVE GROUPS" lobbyListItems={lobbyListItems} ref={ref} squareImage/>;
}
