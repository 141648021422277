import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PregameOverview, cleanPregameOverview } from "../../redux/actions/GameEngineAction";
import { RootState } from "../../redux/store";
import history from "../../routes/history";

export const JoinGameNavigation: React.FC = () => {
  const { gid } = useParams() as { gid: string | number};
  const dispatch = useDispatch();

  const gameOverview = useSelector((state: RootState) => state.gameEngine.PregameOverview);
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);

  useEffect(() => {
    dispatch(cleanPregameOverview());
    dispatch(PregameOverview(+gid));
  }, []);

  const franchiseId = useMemo(() => {
    const myTeam = userTeams?.find(team => (
      team?.engId === gameOverview?.teams?.[0]?.utid || team?.engId === gameOverview?.teams?.[1]?.utid
    ));

    return myTeam?.id;
  }, [gameOverview, userTeams]);

  useEffect(() => {
    if (gameOverview?.status && gameOverview?.gid === +gid) {
      if (gameOverview.status === 'pregame') {
        history.push('/leagueheadtohead', +gid);
      } else if (gameOverview.status === 'inProgress') {
        history.push(`/gameplay?id=${gid}`);
      } else if (gameOverview.status === 'complete') {
        history.push('/stand', { gid, id: franchiseId });
      }
    }
  }, [gameOverview, franchiseId, gid]);

  return (
    <div style={{ display: 'grid', placeItems: 'center', height: '100vh'}}>
      <h2>{`You will be redirected to the game ${gid} soon`}</h2>
    </div>
  );
};