import { ProfileState, useFetchFriends, useProfile } from "components/Profile/hooks";
import { FriendsHook, useFriends } from "components/Profile/hooks/useFriends";
import { ProfileHeaderProps, ProfileHeaderTemplate } from "./ProfileHeaderTemplate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { SendFriendInvite } from "redux/actions/Profile/Friends";
import { message } from "antd";

type SelftProfileHeaderReturnProps<T extends FriendsHook, K extends ProfileState> = Pick<ProfileHeaderProps, "profile" | "friendsLength"> & Pick<T, "isAddButton"> & Pick<K, "isOwn">;

export const SelfProfileHeader = <T extends FriendsHook, K extends ProfileState>({ useProfile, id, useFriendsSelector, useFetchFriends, renderProfileHeader = ({ isAddButton, ...rest }) => <ProfileHeaderTemplate {...rest} /> }: { id?: number, useFriendsSelector: () => T, useFetchFriends: (id?: number) => void, useProfile: (id?: number) => K, renderProfileHeader?: (data: SelftProfileHeaderReturnProps<T, K>) => React.ReactElement }) => {
  const profileProps = useProfile(id);
  const { acceptedFriends: friends, isAddButton } = useFriendsSelector();
  useFetchFriends(id);
  return profileProps.profile ? renderProfileHeader({ friendsLength: friends ? friends.length : 0, profile: profileProps.profile, isOwn: profileProps.isOwn, isAddButton }) : null;
}


export const ProfileHeader = ({ userId }: { userId: number }) => {
  return (
    <SelfProfileHeader
      id={userId}
      useProfile={useProfile}
      useFriendsSelector={useFriends}
      useFetchFriends={useFetchFriends}
    />
  )
}

export const ProfileHeaderWithFriendButton = ({ userId }: { userId: number }) => {
  const user = useSelector((state: RootState) => state.app.resData);
  const dispatch = useDispatch();
  const handleSendFriendRequest = (profileId: number) => {
    dispatch(
      SendFriendInvite(profileId, {
        onSuccess: (m) => message.success(m),
        onError: (m) => message.error(m),
      })
    );
  };

  if (!user) return null;

  return (
    <SelfProfileHeader
      id={userId}
      useProfile={useProfile}
      useFriendsSelector={useFriends}
      useFetchFriends={useFetchFriends}
      renderProfileHeader={
        ({ isAddButton, profile, friendsLength, isOwn }) =>
          <ProfileHeaderTemplate
            profile={profile}
            friendsLength={friendsLength}
            renderAddFriendButton={
              (profileId) => !isOwn && isAddButton(user.id)
                ? <button onClick={() => handleSendFriendRequest(profileId.id)}>ADD FRIEND</button>
                : null
            }
          />
      }
    />
  )

}