import { Action } from "redux";
import { Profile } from "types";
import { FriendsList } from "types/friendships";
import { GroupListItem } from "types/groups";
import { PaginationResponse } from "types/pagination";
export * from "./Friends";

export enum ActionTypes {
  GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL = "GET_PROFILE_FAIL",
}

export interface GetProfileRequestAction extends Action {
  type: ActionTypes.GET_PROFILE_REQUEST;
}

export interface GetProfileSuccessAction extends Action {
  type: ActionTypes.GET_PROFILE_SUCCESS;
  payload: {
    profile: Profile;
    friends?: FriendsList;
    groups?: PaginationResponse<GroupListItem>;
  };
}

export interface GetProfileFailAction extends Action {
  type: ActionTypes.GET_PROFILE_FAIL;
  error: string;
}

export type ProfileActionTypes =
  | GetProfileRequestAction
  | GetProfileSuccessAction
  | GetProfileFailAction;
