import types from "../actionTypes";
import { getElimination } from "components/LeagueOverviewElimination/TabDetail/ScheduleTab/helper/getElimination";

export function eliminationOverview (gid: number) {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.ELIMINATION_OVERVIEW_REQUEST,
        payload: {},
      });
      const elimination = await getElimination(gid);

      dispatch({
        type: types.ELIMINATION_OVERVIEW_SUCCESS,
        payload: { eliminationOverview: elimination },
      });
    } catch (error: any) {
      dispatch({
        type: types.ELIMINATION_OVERVIEW_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};
