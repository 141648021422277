import "./FooterStyle.scss";
import { Row , Col } from "react-bootstrap";
import footerLogo from "../../../assets/images/common/dynastyLogo.png";
import facebook from "../../../assets/images/footer/facebook-icon.svg";
import instagram from "../../../assets/images/footer/instagram-icon.svg";
import discord from "../../../assets/images/footer/discord-icon.svg";
import twitter from "../../../assets/images/footer/twitter-icon.svg";
import reddit from "../../../assets/images/footer/reddit-icon.svg";
import twitch from "../../../assets/images/footer/twitch-icon.svg";
import youtube from "../../../assets/images/footer/youtube-icon.svg";
import tiktok from "../../../assets/images/footer/tiktok-icon.svg";
import { Link } from "react-router-dom";

const Footer: React.FC = () => (
    <div className="footer">
        <Row className="m-0" style={{ width: '100%' }} >
            <Col xl={6} lg={6}>
                <div className="footerLeft">
                    <img src={footerLogo} alt="footerLogo" />
                    <div className="footerlink">
                        <div className="link" >
                            <span><Link to="/about" target="_blank" >ABOUT US</Link></span>&nbsp; | &nbsp;
                            <span><Link to="/support" target="_blank" >CUSTOMER SUPPORT</Link></span>&nbsp; | &nbsp;
                            <span><Link to="/privacy" target="_blank" >TERMS & PRIVACY</Link></span>&nbsp; | &nbsp;
                            <span><a href="https://gmdbe.azurewebsites.net/files/GMDynasty_WhitePaper.pdf">WHITEPAPER</a></span>&nbsp; | &nbsp;
                        </div>
                        <span>&copy; 2021 Diversify Games, Inc. All Rights Reserved</span>
                    </div>
                </div>
            </Col>
            <Col xl={6} lg={6}>
                <ul className="socialIcon" >
                    <li><a href= {process.env.REACT_APP_DISCORD_URL} target="_blank" rel="noreferrer"><img src={discord} alt="discord" /></a></li>
                    <li><a href= {process.env.REACT_APP_FACEBOOK_URL} target="_blank" rel="noreferrer"><img src={facebook} alt="facebook" /></a></li>
                    <li><a href= {process.env.REACT_APP_INSTAGRAM_URL} target="_blank" rel="noreferrer"><img src={instagram} alt="instagram" /></a></li>
                    <li><a href= {process.env.REACT_APP_TWITCH_URL} target="_blank" rel="noreferrer"><img src={twitch} alt="twitch" /></a></li>
                    <li><a href= {process.env.REACT_APP_TWITTER_URL} target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" /></a></li>
                    <li><a href= {process.env.REACT_APP_YOUTUBE_URL} target="_blank" rel="noreferrer"><img src={youtube} alt="youtube" /></a></li>
                    <li><a href= {process.env.REACT_APP_TIKTOK_URL} target="_blank" rel="noreferrer"><img src={tiktok} alt="tiktok" /></a></li>
                    {/* <li><a><img src={twitch} alt="twitch" /></a></li>
                    <li><a><img src={reddit} alt="reddit" /></a></li> */}
                </ul>
            </Col>
        </Row>
    </div>
);

export default Footer;
