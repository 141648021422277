const TermsContent: React.FC = () => (
  <div className="privacy_content">
    <h5>Terms of Use</h5>
    <div className="privacy_subcontent">
      <h6>Last updated : 24/01/2024</h6>
      <p>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you") and Diversify
        Games Inc. (“we", “us" or “our") concerning your access to and use of
        the www.gmdynasty.com website as well as any other media form, media
        channel, mobile website or mobile application related, linked or
        otherwise connected thereto (collectively, the “Site" and the “App"). GM
        Dynasty is a distributed application that is currently running on the
        Ethereum Network and Polygon Network (the "Blockchains"), using a
        specially developed smart contract to enable users to own, transfer,
        compete and mint unique digital game assets. It also enables users to
        own and transfer other digital assets like franchises and awards. These
        assets can then be visualized on a website that the user can interact
        with the Site. The Smart Contracts and the Site are collectively
        referred to in these Terms as the “GAME”. Using the GAME, users can view
        their assets and use the Smart Contract to acquire, trade and compete
        with other GAME users.
      </p>
    </div>
  </div>
);

export default TermsContent;
