import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import ImportFranchiseMain from "../../components/ImportFranchise/ImportFranchiseMain";

const ImportFranchisesScreen = () =>{
    return(
        <>
            <div className="pagelayout" >
                <WithLoginNavbar />
                <ImportFranchiseMain />
            </div>
        </>
    );
}

export default ImportFranchisesScreen;