/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo } from 'react';
import { Table, Modal } from 'antd';
import BlueButton from '../../Common/Button/BlueButton/BlueButton';
import TransactionSlipModal from './TransactionSlipModal';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { useTransactions } from './hooks/useTransactions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { PaginationPages } from 'components/HistoricalResults/PaginationPages';

type TransactionRow = {
  key: number;
  transaction: JSX.Element;
  description: string;
  date: string;
  amount: string;
  fee: string;
  status: string;
};

const columns: ColumnType<TransactionRow>[] = [
  {
    title: 'TRANSACTION ID',
    dataIndex: 'transaction',
    key: 'transaction',
  },
  {
    title: 'DATE',
    dataIndex: 'date',
    key: 'date',
    sorter: dateComparator,
    showSorterTooltip: false,
  },
  {
    title: 'DESCRIPTION',
    dataIndex: 'description',
    key: 'description',
    sorter: textComparator,
    showSorterTooltip: false,
  },
  {
    title: 'AMOUNT',
    key: 'amount',
    dataIndex: 'amount',
    sorter: numberComparator,
    showSorterTooltip: false,
  },
  {
    title: 'FEE',
    key: 'fee',
    dataIndex: 'fee',
    sorter: numberComparator,
    showSorterTooltip: false,
  },
  {
    title: 'STATUS',
    key: 'status',
    dataIndex: 'status',
    sorter: textComparator,
    showSorterTooltip: false,
  },
];

const SettingTransaction: React.FC = () => {
  const resData = useSelector((state: RootState) => state.app.resData);
  const [receiptData, setReceiptData] = useState<{}>();
  const { allTransactions, handleNextPage, handlePrevPage, page, setPage, isNextPage, isLoading } = useTransactions();

  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = (transaction: any) => {
    setReceiptData(transaction);
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  const rows = useMemo(() => {
    if (!allTransactions?.length) return [];
    const rows = allTransactions.map((transaction: any) => ({
      key: transaction?.id,
      transaction: <a onClick={() => showModal1(transaction)}>{transaction.gmdTransactionId}</a>,
      description: transaction.orderType,
      date: moment(transaction.created).format('DD MMMM YYYY, HH:mm'),
      amount: `$ ${transaction.amount}`,
      fee: transaction.fee ? `$ ${transaction.fee}` : 'NONE',
      status: transaction.status,
    }));

    return rows;
  }, [allTransactions]);

  return (
    <>
      <div className="settingtransaction">
        <h6>Transactions</h6>
        <Table loading={isLoading} columns={columns} dataSource={rows} className="transactiontable mb-4" pagination={false}/>
        <PaginationPages
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          onPageClick={(page) => {
            //@ts-ignore
            setPage(+page.target?.innerText);
          }}
          page={page}
          nextDisabled={!isNextPage}
        />
      </div>

      <Modal
        title=""
        className="connectnewwallet transactionslip"
        open={isModalVisible1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[<BlueButton onClick={handleCancel1} text="CANCEL" />, <BlueButton onClick={handleOk1} text="DONE" />]}
        centered
      >
        <TransactionSlipModal receiptData={receiptData} resData={resData} />
      </Modal>
    </>
  );
};

function dateComparator(a: TransactionRow, b: TransactionRow) {
  const dateA = new Date(a?.date)?.getTime() ?? null;
  const dateB = new Date(b?.date)?.getTime() ?? null;
  if (!dateA || !dateB) return 0;
  return dateA - dateB;
}

function textComparator(a: TransactionRow, b: TransactionRow) {
  return a.status.localeCompare(b.status);
}

function numberComparator(a: TransactionRow, b: TransactionRow) {
  try {
    const amountA = parseFloat(a.amount.replace('$ ', ''));
    const amountB = parseFloat(b.amount.replace('$ ', ''));
    return amountA - amountB;
  } catch (error) {
    return 0;
  }
}

export default SettingTransaction;
