import { Modal } from "antd";
import emailImage from "../../../../../assets/images/common/email.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import SignUpStepFormModal from "components/Common/SignUpStepFormModal/SignUpStepFormModal";

type EmailContentProps = {
  setLoading: (value: boolean) => void;
};

const EmailContent = ({ setLoading }: EmailContentProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [msg, setMsg] = useState("");
  
  const handleEmailLogin = () => { 
    setIsModalVisible(true);
    setCurrentStep(3);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSignUp = () => {
    setCurrentStep(0);
    setIsModalVisible(true);
  }

  return <div className="login-option-component">
    <img src={emailImage} alt="email" className="login-option-image" />
    <div className="login-option-footer">
      <button className="login-with-button" onClick={handleEmailLogin}>
        LOGIN WITH EMAIL
      </button>
      <p>
        If you wish to create an email login account?{" "}
        <span
          className="link-style"
          onClick={handleSignUp}
        >
          Click here!
        </span>
      </p>
    </div>

    <Modal
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[]}
      centered
      width=""
    >
      <SignUpStepFormModal
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        msg={msg}
        setMsg={setMsg}
      />
    </Modal>
  </div>;
};

export default EmailContent;
