import { Modal } from "antd"

type Props = {
  open: boolean;
  onClose: () => void;
}

export const PurchaseTrophyInfo: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      footer={null}
      width={"75vw"}
      onCancel={onClose}
      centered
    >
      <div className="purchasefranchisemain">
        <h4 className="mb-4">CUSTOMIZATION INFORMATION</h4>
        <p className="font18">
          Trophies are optional awards users can create and offer as prizes within game play. 
          Every trophy has Prestige Points and those Prestige Points transfer to the owner of the trophy.
          <br/><br/>
          The trophy customization process starts by selecting the Prestige Points level. 
          As you select a higher amount, at a higher cost, more customization options become available. 
          Please follow these rules when selecting a name.
          <br/><br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; Keep your name to less than 22 characters
          <br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; Refrain from any inappropriate language or trademarked words. 
          <br/><br/>
          If you select minting ($3.00 GMD), the trophy will be minted to the Polygon Blockchain. 
          The NFT will remain in a protected GMD wallet until you request transfer. 
          Transferred Trophies can no longer be used as prizes within game play.
        </p>
      </div>
    </Modal>
  )
}