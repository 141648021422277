import { Table } from "antd";
import { Link } from "react-router-dom";
import history from "../../../../routes/history";

export interface Props {
    gid?: any;
    data?: any;
    ownerId?: any;
    utid?: number;
}

const LeagueOverviewTeamStat = ({ gid, data, ownerId, utid }: Props) => {
    const eastpositionColumns = [
        {
            title: "TEAM STAT",
            dataIndex: "teamstat",
        },
        {
            title: "",
            dataIndex: "w",
        },
    ];


    const eastpositionData = [
        {
            key: "1",
            teamstat: "POINT PER GAME",
            w: data[0].POINT_PER_GAME
        },
        {
            key: "2",
            teamstat: "TOTAL YARDS",
            w: data[0].TOTAL_YARDS
        },
        {
            key: "3",
            teamstat: "YARDS PER PLAY",
            w: data[0].YARDS_PER_PLAY
        },
        {
            key: "4",
            teamstat: "PASSING YARDS",
            w: data[0].PASSING_YARDS
        },
        {
            key: "5",
            teamstat: "COMP ATT",
            w: data[0].COMP_ATT
        },
        {
            key: "6",
            teamstat: "YARD PER PASS",
            w: data[0].YARD_PER_PASS
        },
        {
            key: "7",
            teamstat: "RUSHING YARDS",
            w: data[0].RUSHING_YARDS
        },
        {
            key: "8",
            teamstat: "RUSHING ATTEMPTS",
            w: data[0].RUSHING_ATTEMPTS
        },
        {
            key: "9",
            teamstat: "YARDS PER RUSHING",
            w: data[0].YARDS_PER_RUSHING
        },
    ];
    return (
        <>
            <Table
                columns={eastpositionColumns}
                pagination={false}
                dataSource={eastpositionData}
                size="small"
                className="teamstat"
            />
            <Link
                to={{
                    pathname: `/fullteamstat/${gid}/${utid}`,
                    state: history?.location?.state,
                }}
                className="fullplayerstat"
            >
                Full Team stats
            </Link>
        </>
    );
}

export default LeagueOverviewTeamStat;