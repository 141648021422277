import { TableProps } from 'antd';

const seasonTableColumnsBase: TableProps<any>['columns'] = [
  {
    title: 'W',
    dataIndex: 'won',
  },
  {
    title: 'L',
    dataIndex: 'lost',
  },
  {
    title: 'T',
    dataIndex: 'tied',
  },
  {
    title: "PCT",
    dataIndex: 'pct',
    defaultSortOrder: 'descend',
  },
  {
    title: 'HOME',
    dataIndex: 'home',
  },
  {
    title: 'ROAD',
    dataIndex: 'road',
  },
  {
    title: 'DIV',
    dataIndex: 'division',
  },
  {
    title: 'CONF',
    dataIndex: 'conference',
  },
  {
    title: 'PF',
    dataIndex: 'pointsFor',
  },
  {
    title: 'PA',
    dataIndex: 'pointsAgainst',
  },
  {
    title: 'DIFF',
    dataIndex: 'pointDifferential',
  },
  {
    title: 'STRK',
    dataIndex: 'streak',
  },
];

export const getSeasonTableColumns = (title: string) => [
  {
    title: title,
    dataIndex: 'name',
    width: 200,
  },
  ...seasonTableColumnsBase,
].map((c, i) => i === 0 ? c : ({ ...c, sorter: (a: any, b: any ) => {
  try {
    //@ts-ignore
    return +a[c?.dataIndex] - +b[c?.dataIndex];
  } catch (e) {
    return 0;
  }
} }));
