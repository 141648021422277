import { PayPalButtons, PayPalButtonsComponentProps, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CreateOrderData, CreateOrderActions } from "@paypal/paypal-js/types/components/buttons";
import { PaymentData } from "../../../redux/actions/AppAction";
import { PAYPAL_CLIENT_ID } from "config";
import { usePayPalHandlers } from "helper/usePayPalHandlers";
import { useFeeRates } from "helper/useFeeRates";
import { getFee, getPriceWithFee } from "helper/helper";

export type PayPalButtonProps = {
  product: {
    description: string,
    price: number,
    orderType: PaymentData['orderType'],
    partnerCode?: string,
    city?: number,
    fee?:number,
  };
}

export const PayPalButton: React.FC<PayPalButtonProps> = (props) => {
  const { PAYPAL_FEE_AMOUNT, PAYPAL_FEE_PERCENT} = useFeeRates();
  const { product } = props;
  
  const priceWithFee = (PAYPAL_FEE_PERCENT !== undefined && PAYPAL_FEE_AMOUNT !== undefined) && (
    getPriceWithFee(product.price, PAYPAL_FEE_PERCENT, PAYPAL_FEE_AMOUNT)
  );
  const { handleApprove, handleCancel, handleError } = usePayPalHandlers(product);

  const createOrder = (price: number) => (data: CreateOrderData, actions: CreateOrderActions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: product.description,
          amount: {
            value: String(price),
            currency_code: "USD"
          }
        }
      ],
      intent: "CAPTURE"
    })
  }

  if (!PAYPAL_CLIENT_ID 
      || PAYPAL_FEE_AMOUNT === undefined 
      || PAYPAL_FEE_PERCENT === undefined 
      || !priceWithFee
  ) {
    return null;
  }
  product.fee = getFee(product.price, PAYPAL_FEE_PERCENT, PAYPAL_FEE_AMOUNT);

  return (
    <PayPalScriptProvider options={{ clientId: PAYPAL_CLIENT_ID }}>
      <PayPalButtons 
        createOrder={createOrder(priceWithFee) as PayPalButtonsComponentProps['createOrder']}
        onApprove={handleApprove as PayPalButtonsComponentProps["onApprove"]}
        onError={handleError}
        onCancel={handleCancel}
        forceReRender={[priceWithFee]}
      />
    </PayPalScriptProvider>);
}