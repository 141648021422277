import metamask from "../../../../../assets/images/common/metamaskicon.svg";
import { useMetamaskLogin } from "../../hooks";

type Props = {
  rememberMe: boolean;
}

const MetamaskLoginSmall: React.FC<Props> = ({ rememberMe }) => {
  const { handleMetamaskLogin } = useMetamaskLogin(rememberMe);

  return (
    <div className="login-option-component m-0">
      <img id="metamask-login"src={metamask} alt="metamask" className="login-option-image" />
      <label className="metamask-login-label" htmlFor="metamask-login" onClick={handleMetamaskLogin}>
        LOGIN WITH METAMASK
      </label>
    </div>
  );
};

export default MetamaskLoginSmall;
