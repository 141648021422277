/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import OwnFranchiseHeader from "./OwnFranchiseHeader";
import OwnFranchiseContent from "./OwnFranchiseContent";
import "./OwnFranchiseStyle.scss";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAvatarData } from "./../../redux/actions/TeamAction";

const OwnFranchiseMain: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvatarData());
  }, []);

  return (
    <Container>
      <OwnFranchiseHeader />
      <OwnFranchiseContent />
    </Container>
  );
};

export default OwnFranchiseMain;
