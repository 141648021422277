export type User = {
  id: number;
  userName: string;
  walletBalance: number;
  promoBalance: number;
  email: string;
  password: string;
  updatedAt: Date;
  createdAt: Date;
  isVerified: number;
  token: string;
  metaMaskID: string;
  birthDate: Date | string;
  country: string;
  firstname: string;
  lastname: string;
  portfolioname: string;
  avatar: string;
  state: string;
  status: number;
  disabled_notifications: string | null;
  tutorialDisabled: number | null;
  qr_invitecode?: string | null;
};

export enum NetworkStatus {
  OnLine = "online",
  OffLIne = "offline",
}