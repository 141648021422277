import Error from "../../assets/images/common/error.svg";

const DraftStartModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>ONCE THE DRAFT HAS STARTED IT MUST BE COMPLETED WITHIN 2 HOURS. AFTER 2 HOURS THE BEST AVAILABLE PLAYER WILL BE AUTOMATICALLY SELECTED.</p>
            </div>  
        </>
    );
}

export default DraftStartModal;