import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { WeekPlayersActions } from "redux/actions/Leaderboard";
import { WeekPlayerResponse } from "redux/actions/Leaderboard/types";

export function useWeekPlayers() {
  const dispatch = useDispatch();
  const players: WeekPlayerResponse | null = useSelector(
    (state: RootState) => state.gameEngine.week_players
  );
  const isLoading =
    useSelector((state: RootState) => state.gameEngine.loader) === 1;

  useEffect(() => {
    dispatch(WeekPlayersActions.getWeekPlayers());
  }, []);

  return {
    players,
    isLoading,
  };
}
