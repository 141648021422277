import { ViewMoreStringTypes } from "./LeaderboardContent";
import { LeaderboardTeamLeadersList } from "./LeaderboardTeamLeadersList";
import { useTeamLeadersScored } from "./hooks";

export const LeaderboardTeamLeadersScored = () => {
  const { teamLeadersScored } = useTeamLeadersScored();

  if (!teamLeadersScored) {
    return <div>Sorry, there are no data</div>;
  }

  return (
    <LeaderboardTeamLeadersList
      leadersList={teamLeadersScored}
      viewMoreType={ViewMoreStringTypes["TEAM-LEADERS-SCORED"]}
    />
  );
};
