import { Action } from "redux";
import { Group, GroupDropdownItem, GroupInvitation, GroupListItem, UpdateGroupListPayload, UpdateGroupMemberNetworkStatusData } from "types/groups";
import { PaginatedTrophies } from "../TrophyAssets/types";

export enum GroupActionTypes {
  GET_GROUPS_LIST_REQUEST = "GET_GROUPS_LIST_REQUEST",
  GET_GROUPS_LIST_SUCCESS = "GET_GROUPS_LIST_SUCCESS",
  UPDATE_GROUPS_LIST = "UPDATE_GROUPS_LIST",
  GET_GROUPS_LIST_FAIL = "GET_GROUPS_LIST_FAIL",
  CREATE_GROUP_REQUEST = "CREATE_GROUP_LIST",
  CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS",
  CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL",
  JOIN_GROUP_REQUEST = "JOIN_GROUP_REQUEST",
  JOIN_GROUP_SUCCESS = "JOIN_GROUP_SUCCESS",
  JOIN_GROUP_FAIL = "JOIN_GROUP_FAIL",
  INVITE_GROUP_REQUEST = "INVITE_GROUP_REQUEST",
  INVITE_GROUP_SUCCESS = "INVITE_GROUP_SUCCESS",
  INVITE_GROUP_FAIL = "INVITE_GROUP_FAIL",
  ADD_GROUP_INVITATION = "ADD_GROUP_INVITATION",
  GET_GROUP_INVITATIONS_REQUEST = "GET_GROUP_INVITATIONS_REQUEST",
  GET_GROUP_INVITATIONS_SUCCESS = "GET_GROUP_INVITATIONS_SUCCESS",
  GET_GROUP_INVITATIONS_FAIL = "GET_GROUP_INVITATIONS_FAIL",
  UPDATE_GROUP_INVITATIONS = 'UPDATE_GROUP_INVITATIONS',
  UPDATE_GROUP_IN_PROFILE_GROUPS_LIST = 'UPDATE_GROUP_IN_PROFILE_GROUPS_LIST',
  UPDATE_GROUP_MEMBERSHIP_REQUEST = "UPDATE_GROUP_MEMBERSHIP_REQUEST",
  UPDATE_GROUP_MEMBERSHIP_SUCCESS = "UPDATE_GROUP_MEMBERSHIP_SUCCESS",
  UPDATE_GROUP_MEMBERSHIP_FAIL = "UPDATE_GROUP_MEMBERSHIP_FAIL",
  LEAVE_GROUP_REQUEST = "LEAVE_GROUP_REQUEST",
  LEAVE_GROUP_SUCCESS = "LEAVE_GROUP_SUCCESS",
  LEAVE_GROUP_FAIL = "LEAVE_GROUP_FAIL",
  UPDATE_GROUP = "UPDATE_GROUP",
  GET_GROUP_REQUEST = "GET_GROUP_REQUEST",
  GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS",
  GET_GROUP_FAIL = "GET_GROUP_FAIL",
  GET_ADMIN_GROUPS_LIST_REQUEST = "GET_ADMIN_GROUPS_LIST_REQUEST",
  GET_ADMIN_GROUPS_LIST_SUCCESS = "GET_ADMIN_GROUPS_LIST_SUCCESS",
  GET_ADMIN_GROUPS_LIST_FAIL = "GET_ADMIN_GROUPS_LIST_FAIL",
  UPDATE_ADMIN_GROUPS_LIST = "UPDATE_ADMIN_GROUPS_LIST",
  EDIT_GROUP_REQUEST = "EDIT_GROUP_REQUEST",
  EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS",
  EDIT_GROUP_FAIL = "EDIT_GROUP_FAIL",
  GET_GROUP_TROPHIES_REQUEST = "GET_GROUP_TROPHIES_REQUEST",
  GET_GROUP_TROPHIES_SUCCESS = "GET_GROUP_TROPHIES_SUCCESS",
  GET_GROUP_TROPHIES_FAIL = "GET_GROUP_TROPHIES_FAIL",
  UPDATE_GROUP_TROPHIES = "UPDATE_GROUP_TROPHIES",
  GET_GROUPS_FOR_LEAGUES_REQUEST = "GET_GROUPS_FOR_LEAGUES_REQUEST",
  GET_GROUPS_FOR_LEAGUES_SUCCESS = "GET_GROUPS_FOR_LEAGUES_SUCCESS",
  GET_GROUPS_FOR_LEAGUES_FAIL = "GET_GROUPS_FOR_LEAGUES_FAIL",
  UPDATE_GROUPS_FOR_LEAGUES = "UPDATE_GROUPS_FOR_LEAGUES",
  GET_GROUP_GAMES_REQUEST = "GET_GROUP_GAMES_REQUEST",
  GET_GROUP_GAMES_SUCCESS = "GET_GROUP_GAMES_SUCCESS",
  GET_GROUP_GAMES_FAIL = "GET_GROUP_GAMES_FAIL",
  UPDATE_GROUP_GAMES = "UPDATE_GROUP_GAMES",
  GET_GROUP_PASSED_GAMES_REQUEST = "GET_GROUP_PASSED_GAMES_REQUEST",
  GET_GROUP_PASSED_GAMES_SUCCESS = "GET_GROUP_PASSED_GAMES_SUCCESS",
  GET_GROUP_PASSED_GAMES_FAIL = "GET_GROUP_PASSED_GAMES_FAIL",
  UPDATE_GROUP_PASSED_GAMES = "UPDATE_GROUP_PASSED_GAMES",
  UPDATE_GROUP_MEMBER_NETWORK_STATUS = "UPDATE_GROUP_MEMBER_NETWORK_STATUS",
  GET_GROUP_NAME_REQUEST = "GET_GROUP_NAME_REQUEST",
  GET_GROUP_NAME_SUCCESS = "GET_GROUP_NAME_SUCCESS",
  GET_GROUP_NAME_FAIL = "GET_GROUP_NAME_FAIL",
}

export interface GetGroupsListRequestAction extends Action {
  type: GroupActionTypes.GET_GROUPS_LIST_REQUEST;
}
export interface GetGroupListSuccess extends Action {
  type: GroupActionTypes.GET_GROUPS_LIST_SUCCESS;
}
export interface GetGroupsListFailAction extends Action {
  type: GroupActionTypes.GET_GROUPS_LIST_FAIL;
  error: string;
}
export interface UpdateGroupsListAction extends Action {
  type: GroupActionTypes.UPDATE_GROUPS_LIST;
  payload: UpdateGroupListPayload;
}
export interface CreateGroupRequestAction extends Action {
  type: GroupActionTypes.CREATE_GROUP_REQUEST;
}
export interface CreateGroupSuccessAction extends Action {
  type: GroupActionTypes.CREATE_GROUP_SUCCESS;
}
interface UpdateGroup extends Action {
  type: GroupActionTypes.UPDATE_GROUP;
  payload: Group;
}
interface CreateGroupFailAction extends Action {
  type: GroupActionTypes.CREATE_GROUP_FAIL;
  error: string;
}
interface JoinGroupRequestAction extends Action {
  type: GroupActionTypes.JOIN_GROUP_REQUEST;
}
interface JoinGroupSuccessAction extends Action {
  type: GroupActionTypes.JOIN_GROUP_SUCCESS;
}
interface JoinGroupFailAction extends Action {
  type: GroupActionTypes.JOIN_GROUP_FAIL;
  error: string;
}

interface InviteGroupRequestAction extends Action {
  type: GroupActionTypes.INVITE_GROUP_REQUEST;
}
interface InviteGroupSuccessAction extends Action {
  type: GroupActionTypes.INVITE_GROUP_SUCCESS;
}
interface InviteGroupFailAction extends Action {
  type: GroupActionTypes.INVITE_GROUP_FAIL;
  error: string;
}
interface GetGroupInvitationsRequestAction extends Action {
  type: GroupActionTypes.GET_GROUP_INVITATIONS_REQUEST;
}
interface GetGroupInvitationsSuccessAction extends Action {
  type: GroupActionTypes.GET_GROUP_INVITATIONS_SUCCESS;
}
interface GetGroupInvitationsFailAction extends Action {
  type: GroupActionTypes.GET_GROUP_INVITATIONS_FAIL;
  error: string;
}

interface AddGroupInvitationAction extends Action {
  type: GroupActionTypes.ADD_GROUP_INVITATION;
  payload: GroupInvitation;
}

interface UpdateGroupInvitationActions extends Action {
  type: GroupActionTypes.UPDATE_GROUP_INVITATIONS;
  payload: GroupInvitation[];
}

interface UpdateGroupInProfileGroupsListActions extends Action {
  type: GroupActionTypes.UPDATE_GROUP_IN_PROFILE_GROUPS_LIST;
  payload: Omit<GroupListItem, 'role'>;
}

interface UpdateGroupMembershipRequestAction extends Action {
  type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_REQUEST;
}
interface UpdateGroupMembershipSuccessAction extends Action {
  type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_SUCCESS;
}
interface UpdateGroupMembershipFailAction extends Action {
  type: GroupActionTypes.UPDATE_GROUP_MEMBERSHIP_FAIL;
  error: string;
}

interface LeaveGroupRequestAction extends Action {
  type: GroupActionTypes.LEAVE_GROUP_REQUEST;
}
interface LeaveGroupSuccessAction extends Action {
  type: GroupActionTypes.LEAVE_GROUP_SUCCESS;
}
interface LeaveGroupFailAction extends Action {
  type: GroupActionTypes.LEAVE_GROUP_FAIL;
  error: string;
}

interface GetGroupRequest extends Action {
  type: GroupActionTypes.GET_GROUP_REQUEST;
}

export interface GetGroupSuccess extends Action {
  type: GroupActionTypes.GET_GROUP_SUCCESS;
}

export interface GetGroupFail extends Action {
  type: GroupActionTypes.GET_GROUP_FAIL;
  error: string;
}

export interface GetAdminGroupsListRequest extends Action {
  type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_REQUEST;
}

export interface GetAdminGroupsListSuccess extends Action {
  type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_SUCCESS;
}

export interface GetAdminGroupsListFail extends Action {
  type: GroupActionTypes.GET_ADMIN_GROUPS_LIST_FAIL;
  error: string;
}

export interface UpdateAdminGroupsList extends Action {
  type: GroupActionTypes.UPDATE_ADMIN_GROUPS_LIST;
  payload: GroupDropdownItem[];
}

export interface EditGroupRequest extends Action {
  type: GroupActionTypes.EDIT_GROUP_REQUEST;
}
export interface EditGroupSuccess extends Action {
  type: GroupActionTypes.EDIT_GROUP_SUCCESS;
}

export interface EditGroupFail extends Action {
  type: GroupActionTypes.EDIT_GROUP_FAIL;
  error: string;
}

export interface GetGroupTrophiesRequest extends Action {
  type: GroupActionTypes.GET_GROUP_TROPHIES_REQUEST;
}

export interface GetGroupTrophiesSuccess extends Action {
  type: GroupActionTypes.GET_GROUP_TROPHIES_SUCCESS;
}

export interface GetGroupTrophiesFail extends Action {
  type: GroupActionTypes.GET_GROUP_TROPHIES_FAIL;
  error: string;
}

export interface UpdateGroupTrophies extends Action {
  type: GroupActionTypes.UPDATE_GROUP_TROPHIES;
  payload: PaginatedTrophies;
}

export interface GetGroupsForLeaguesRequest extends Action {
  type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_REQUEST;
}

export interface GetGroupsForLeaguesSuccess extends Action {
  type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_SUCCESS;
}

export interface GetGroupsForLeaguesFail extends Action {
  type: GroupActionTypes.GET_GROUPS_FOR_LEAGUES_FAIL;
  error: string;
}

export interface UpdateGroupsForLeagues extends Action {
  type: GroupActionTypes.UPDATE_GROUPS_FOR_LEAGUES;
  payload: GroupDropdownItem[];
}

export interface GetGroupGamesRequest extends Action {
  type: GroupActionTypes.GET_GROUP_GAMES_REQUEST;
}

export interface GetGroupGamesSuccess extends Action {
  type: GroupActionTypes.GET_GROUP_GAMES_SUCCESS;
}

export interface GetGroupGamesFail extends Action {
  type: GroupActionTypes.GET_GROUP_GAMES_FAIL;
  error: string;
}

export interface UpdateGroupGames extends Action {
  type: GroupActionTypes.UPDATE_GROUP_GAMES;
  payload: any;
}
export interface UpdateGroupMemberNetworkStatusAction extends Action {
  type: GroupActionTypes.UPDATE_GROUP_MEMBER_NETWORK_STATUS;
  payload: UpdateGroupMemberNetworkStatusData;
}

export interface GetGroupPassedGamesRequest extends Action {
  type: GroupActionTypes.GET_GROUP_PASSED_GAMES_REQUEST;
}

export interface GetGroupPassedGamesSuccess extends Action {
  type: GroupActionTypes.GET_GROUP_PASSED_GAMES_SUCCESS;
}

export interface GetGroupPassedGamesFail extends Action {
  type: GroupActionTypes.GET_GROUP_PASSED_GAMES_FAIL;
  error: string;
}

export interface UpdateGroupPassedGames extends Action {
  type: GroupActionTypes.UPDATE_GROUP_PASSED_GAMES;
  payload: any;
}

export interface GetGroupNameRequest extends Action {
  type: GroupActionTypes.GET_GROUP_NAME_REQUEST;
}

export interface GetGroupNameSuccess extends Action {
  type: GroupActionTypes.GET_GROUP_NAME_SUCCESS;
  payload: { id: number, name: string };
}

export interface GetGroupNameFail extends Action {
  type: GroupActionTypes.GET_GROUP_NAME_FAIL;
  error: string;
}

export type GroupActions =
  | GetGroupsListRequestAction
  | GetGroupsListFailAction
  | UpdateGroupsListAction
  | CreateGroupRequestAction
  | CreateGroupSuccessAction
  | CreateGroupFailAction
  | JoinGroupRequestAction
  | JoinGroupSuccessAction
  | JoinGroupFailAction
  | InviteGroupRequestAction
  | InviteGroupSuccessAction
  | InviteGroupFailAction
  | GetGroupInvitationsRequestAction
  | GetGroupInvitationsSuccessAction
  | GetGroupInvitationsFailAction
  | AddGroupInvitationAction
  | UpdateGroupInvitationActions
  | UpdateGroupInProfileGroupsListActions
  | UpdateGroupMembershipRequestAction
  | UpdateGroupMembershipSuccessAction
  | UpdateGroupMembershipFailAction
  | LeaveGroupRequestAction
  | LeaveGroupFailAction
  | LeaveGroupSuccessAction
  | UpdateGroup
  | GetGroupRequest
  | GetGroupSuccess
  | GetGroupFail
  | GetGroupListSuccess
  | GetAdminGroupsListRequest
  | GetAdminGroupsListSuccess
  | GetAdminGroupsListFail
  | UpdateAdminGroupsList
  | EditGroupRequest
  | EditGroupSuccess
  | EditGroupFail
  | GetGroupTrophiesRequest
  | GetGroupTrophiesSuccess
  | GetGroupTrophiesFail
  | UpdateGroupTrophies
  | GetGroupsForLeaguesRequest
  | GetGroupsForLeaguesSuccess
  | GetGroupsForLeaguesFail
  | UpdateGroupsForLeagues
  | GetGroupGamesRequest
  | GetGroupGamesSuccess
  | GetGroupGamesFail
  | UpdateGroupGames
  | GetGroupPassedGamesRequest
  | GetGroupPassedGamesSuccess
  | GetGroupPassedGamesFail
  | UpdateGroupPassedGames
  | UpdateGroupMemberNetworkStatusAction
  | GetGroupNameRequest
  | GetGroupNameSuccess
  | GetGroupNameFail;
