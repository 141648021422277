import { Modal } from "antd";
import { CreateLeague } from "../CreateElimination/CreateLeague";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateEliminationModal: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal open={isOpen} centered width="" onCancel={onClose} footer={null}>
      <CreateLeague />
    </Modal>
  );
};
