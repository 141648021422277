import { GameEvent } from 'components/GamePlay/GamePlayer';
import '../AccordianStyle.scss';
import React from 'react';

export type GameSummaryPbp = Pick<GameEvent, 'quarter' | 'time' | 'text'>[];
export interface Props {
  data?: GameSummaryPbp;
}

const FullGameSummary: React.FC<Props> = ({ data }) => (
  <div className="gamesummary">
    {data?.map((action, i) => (
      <div className="action" key={i}>
        <p className="action__item">{action.quarter}</p>
        <p className="action__item">{action.time}</p>
        <p className="action__item--bold">{action.text}</p>
      </div>
    ))}
  </div>
);

export default FullGameSummary;
