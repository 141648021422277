import { getFromLocalStorage } from "libs/storage";
import { FriendsList, UpdateNetworkStatus } from "types/friendships";
import { Group, GroupInvitation, UpdateGroupMemberNetworkStatusData } from "types/groups";
import { ApiContract } from "./api.types";
import {
  HandleUpdateFriendNetworkStatus,
  HandleUpdateFriends,
  HandleUpdateGroup
} from "../../redux/actions/Profile/Friends";
import { HandleGroupInvitation, HandleUpdateGroupMemberNetworkStatus } from "redux/actions/Groups";
import { notification } from "antd";
import { WSResponseHandler } from "redux/actions/WSResponseHandler/WSResponseHandler";

const transports: Record<
  string,
  (url: string) => (structure: any) => Promise<ApiContract> | ApiContract
> = {};

let socket: WebSocket | null = null;
let retries = 0;
let api: ApiContract = {} as ApiContract;

const connect = (
  url: string,
  structure: any,
  listeners: Record<string, any>
) => {
  try {
    const token = getFromLocalStorage("token");
    socket = new WebSocket(`${url}?token=${token}`);
    if (!socket) throw new Error("Socket is not created");

    socket!.onopen = () => {
      socket!.send(JSON.stringify({ name: 'sys', method: 'ping' }))
    }

    socket!.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (!data) return;
      if (data.event === "friendInvite") {
        listeners.handleUpdateFriends(data.data.friendsOfRecepient);
      }
      if (data.event === "updateFriendship") {
        listeners.handleUpdateFriends(data.data.friendsOfRecepient);
      }
      if (data.event === "friendOffline") {
        listeners.handleUpdateNetworkstatus({
          friendId: data.data,
          status: "offline",
        });
      }
      if (data.event === "friendOnline") {
        listeners.handleUpdateNetworkstatus({
          friendId: data.data,
          status: "online",
        });
      }
      if (data.event === "groupUpdate") {
        listeners.handleUpdateGroup(data.data);
      }
      if (data.event === "groupInvitation") {
        listeners.handleGroupInvitation(data.data);
      }
      if (data.event === "memberOffline") {
        listeners.handleUpdateGroupMemberNetworkStatus({
          memberId: data.data,
          status: "offline",
        });
      }
      if (data.event === "memberOnline") {
        listeners.handleUpdateGroupMemberNetworkStatus({
          memberId: data.data,
          status: "online",
        });
      }
      WSResponseHandler(data);
    };
    const services = Object.keys(structure);
    for (const serviceName of services) {
      // @ts-ignore
      api[serviceName] = {};
      const service = structure[serviceName];
      const methods = Object.keys(service);
      for (const methodName of methods) {
        // @ts-ignore
        // eslint-disable-next-line no-loop-func
        api[serviceName][methodName] = (...args: any[]) => {
          if (socket?.readyState === WebSocket.CLOSED ||
            socket?.readyState === WebSocket.CLOSING)
            return {
              status: "error",
              message: "Cannot send request. Connection closed"
            };
          const packet = { name: serviceName, method: methodName, args };
          socket!.send(JSON.stringify(packet));
          return {
            status: "success",
            message: "Request sent",
          }
        };
      }
    }

    socket.onerror = (error) => {
      console.error("WebSocket Error: ", error);
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log("WebSocket connection closed cleanly");
        return;
      }
      if (event.reason !== "Token not valid" && event.code !== 1006) return;
      if (retries < 5) {
        // limit the number of retries to prevent infinite loops
        retries++;
        setTimeout(() => connect(url, structure, listeners), retries * 1200); // retry after a delay
      } else {
        console.log("Failed to reconnect after 5 attempts, giving up");
      }
    };

  } catch (e) {
    notification.error({
      placement: "bottomRight",
      message: "Error sending request",
    })
    throw new Error("Error sending request");
  }
};

transports.wss =
  (url: string) =>
    (params: { structure: any; listeners: Record<string, any> }) => {
      connect(url, params.structure, params.listeners);
      return new Promise((resolve) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
          resolve(api);
        } else {
          socket!.addEventListener("open", () => resolve(api));
        }
      });
    };

const scaffold = (url: string) => {
  let transportLabel = url.split("://")[0];
  transportLabel = transportLabel === "ws" ? "wss" : transportLabel;
  const transport = transports[transportLabel];
  return transport(url);
};

export class Api {
  private static instance: Api;
  public api: ApiContract;
  public ready: Promise<void>;

  private constructor(url: string) {
    this.api = {} as ApiContract;
    this.ready = this.initiateApi(url);
  }

  public static getInstance(url: string = process.env.API_URL!): Api {
    console.log("get instance ");
    if (!Api.instance) {
      console.log("creating instance");
      Api.instance = new Api(`${url}`);
    }
    return Api.instance;
  }

  private async initiateApi(url: string) {
    this.api = await scaffold(url)({
      structure: {
        friends: {
          sendFriendshipRequest: ["friendId"],
          updateFriendship: ["friendId", "statusToUpdate"],
        },
        groups: {
          joinGroup: ["groupId"],
          inviteGroup: ["groupId", "userId"],
          leaveGroup: ["groupId"],
          getAdminGroupsList: ["userId"],
          getGroupsToCreateLeague: ["userId"],
        },
      },
      listeners: {
        handleUpdateFriends: (friends: FriendsList) => {
          HandleUpdateFriends(friends);
        },
        handleUpdateNetworkstatus: (data: UpdateNetworkStatus) => {
          HandleUpdateFriendNetworkStatus(data);
        },
        handleUploadFriendsOnConnection: () => { },
        handleUpdateGroup: (group: Group) => {
          HandleUpdateGroup(group);
        },
        handleUpdateGroupMemberNetworkStatus: (data: UpdateGroupMemberNetworkStatusData) => {
          HandleUpdateGroupMemberNetworkStatus(data);
        },
        handleGroupInvitation: (data: GroupInvitation) => {
          HandleGroupInvitation(data)
        },
      },
    });
  }
}
