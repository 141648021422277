import Profile from "../../assets/images/common/userName.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LeagueDetailPurchaseFranchise from "../Common/LeagueDetail/LeagueDetailPurchaseFranchise";
import { metaMaskWalletId } from "../../helper/helper";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { getOwnerStats } from "../../redux/actions/GameEngineAction";
import { API_URL } from "../../config";
import { useFranchisesCount } from "helper/useFranchisesCount";

const OwnFranchiseHeader: React.FC = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootState) => state.app.resData?.id);

  useEffect(() => {
    if (userId) {
      dispatch(getOwnerStats(userId));
    }
  }, [userId]);

  const resData = useSelector((state: RootState) => state.app.resData);
  const ownerStats = useSelector(
    (state: RootState) => state?.gameEngine.ownerStats
  );
  let protfolioName = resData?.userName ? resData?.userName : "---";
  let metaMaskID = resData?.metaMaskID
    ? metaMaskWalletId(resData?.metaMaskID)?.toUpperCase()
    : "----";
  let email = resData?.email ? resData?.email : "----";
  let joined = resData?.createdAt
    ? moment(resData?.createdAt).format("L")
    : "----";

  const franchisesCount = useFranchisesCount();
  const data1 = useMemo(
    () => [
      {
        id: resData?.id,
        profileimage: resData?.avatar
          ? `${API_URL}/images/avatar/${resData?.avatar}`
          : Profile,
        protfolioname: protfolioName,
        usertitle: "USER NAME:",
        username: protfolioName,
        currentleaguetitle: "CURRENT LEAGUE:",
        currentleague: "0",
        joinedtitle: "JOINED:",
        joined: joined,
        franchisestitle: "NO. OF FRANCHISES:",
        franchises: franchisesCount,
        wallettitle: "WALLET:",
        wallet: metaMaskID,
        mintedplayertitle: "MINTED PLAYERS:",
        mintedplayer: "0",
        emailtite: "EMAIL:",
        email: email,
        fundsplaytitle: "FUNDS-IN-PLAY:",
        fundsplay: ownerStats?.fundsInPlay !== undefined ? ownerStats?.fundsInPlay : "---",
        win: "TOTAL WINS",
        windigit: ownerStats?.totalWins ?? "---",
        winpercentage: "WIN %",
        winpercentagedigit: ownerStats?.winPercentage
          ? Math.round(ownerStats?.winPercentage * 10) / 10
          : "---",
        champs: "TITLES",
        champsdigit: "---",
      },
    ],
    [
      email,
      franchisesCount,
      joined,
      metaMaskID,
      ownerStats?.totalWins,
      ownerStats?.winPercentage,
      protfolioName,
      resData?.avatar,
      resData?.id,
      ownerStats?.fundsInPlay
    ]
  );
  return (
    <>
      {data1.map((d: any, i: any) => (
        <LeagueDetailPurchaseFranchise data={d} key={i} />
      ))}
    </>
  );
};

export default OwnFranchiseHeader;
