import { message } from "antd";
import { useDispatch } from "react-redux";
import { UpdateFriendInvite } from "redux/actions/Profile";
import { FriendshipsStatus } from "types/friendships";

export function useUpdateFriendsRequest() {
  const dispatch = useDispatch();
  const handleAcceptInvite = (id: number) => {
    dispatch(
      UpdateFriendInvite(id, FriendshipsStatus.ACCEPTED, {
        onSuccess: () => message.success("Friend request accepted"),
      })
    );
  };
  const handleRejectInvite = (id: number) => {
    dispatch(
      UpdateFriendInvite(id, FriendshipsStatus.REJECTED, {
        onSuccess: () => message.success("Friend request rejected"),
      })
    );
  };

  return { handleAcceptInvite, handleRejectInvite };
}
