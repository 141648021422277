import { useGroupName } from 'helper/useGroupName';
import styles from './LeagueShortInfo.module.scss';
import { RenderTrophy } from 'components/Common/RenderTrophy/RenderTrophy';

type Props = {
  type: string;
  name: string;
  numberOfTeams: number;
  prize?: number;
  ratingRangeMin?: number;
  ratingRangeMax?: number;
  startAt: Date | string;
  trophyId?: number;
  groupId?: number;
};

export const LeagueShortInfo: React.FC<Props> = ({ type, name, numberOfTeams, prize, ratingRangeMax, ratingRangeMin, startAt, trophyId, groupId }) => {
  const group = useGroupName(groupId);

  return (
    <div className={styles.container}>
      <div>
        <h5 className="title">
          {type} | {name}
        </h5>
        <span className={styles.info}>
          <h6>TEAMS: {numberOfTeams}</h6>
          {prize !== undefined && (
            <>
              <h6>|</h6>
              <h6>PRIZE: {prize} $</h6>
            </>
          )}
          {ratingRangeMin !== undefined && ratingRangeMax !== undefined && (
            <>
              <h6>|</h6>
              <h6>
                RATING: {ratingRangeMin} TO {ratingRangeMax}
              </h6>
            </>
          )}
          <h6>|</h6>
          <h6>STARTED: {new Date(startAt).toLocaleString()}</h6>
        </span>
        {group && group?.id === groupId && (
          <span>
            <h6>SPONSORED BY: {group.name}</h6>
          </span>
        )}
      </div>
      {trophyId && <RenderTrophy trophyId={trophyId} mode="cup" style={{ maxWidth: '80px' }} />}
    </div>
  );
};
