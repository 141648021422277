import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import history from "../../../routes/history";

export enum TabEnum {
  OVERVIEW = "overview",
  ROSTER = "roster",
  DEPTH_CHART = "depth_chart",
  GAME_PLAN = "game_plan",
  FRONT_OFFICE = "front_office",
  STATS = "stats",
  SCORE = 'score',
  HISTORY = 'history'
}

export function useTabs(franchiseId: string) {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");

  const [tabKey, setTabKey] = useState<TabEnum>(
    validatedTab(tabParam) ?? TabEnum.OVERVIEW
  );

  const handleTabChange = (key: TabEnum) => setTabKey(key);

  useEffect(() => {
    history.replace(`/franchiseoverview/${franchiseId}?tab=${tabKey}`);
  }, [tabKey, franchiseId]);

  return { tab: tabKey, handleTabChange };
}

function validatedTab(queryTab: string | null): TabEnum | null {
  if (!queryTab) return null;
  const validOptions = Object.values(TabEnum) as string[];
  const isValid = validOptions.includes(queryTab);
  return isValid ? (queryTab as TabEnum) : null;
}
