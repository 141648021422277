/* eslint-disable import/no-anonymous-default-export */
/**
 * Wrapper function above Axios lib
 * @author Chintan Prajapati
 */

import { AxiosRequestConfig } from "axios";
import { API_URL } from "../../config";

const axios = require("axios");
/** Set base url for api */
axios.defaults.baseURL = API_URL;

axios.interceptors.request.use((config: AxiosRequestConfig ) => {
  const authToken = localStorage.getItem('token');
  if (authToken && !config?.headers?.Authorization) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});


/**
 * Parse and return HTTP API response
 * @param res
 */
const getResponse = (res?: any) => {
  console.log(res);
  if (res && (String(res.status).startsWith("2"))) {
    return res.data;
  }
  throw new Error("Some error occur");
};

/**
 * Get request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers    Request header
 */
export const get = <T>(path: string, params?: any, headers?: any) => {
  return new Promise<T>((resolve, reject) => {
    try {
      axios
        .get(path, {
          headers,
          params,
        })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Post request
 * @param path      API url path
 * @param params    Request parameters
 */
export const post = <T = any>(path: string, params?: any, headers?: any) => {
  return new Promise<T>((resolve, reject) => {
    try {
      axios
        .post(path, params, { headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Put request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const put = <T = any>(path: string, params?: any, headers?: any) => {
  return new Promise<T>((resolve, reject) => {
    try {
      axios
        .put(path, params, { headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

export const patch = <T = any>(path: string, params?: any, headers?: any) => {
  return new Promise<T>((resolve, reject) => {
    try {
      axios
        .patch(path, params, { headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
}

export default { get, post, put, patch };
