import { useServerSidePagination } from "helper/useServerSidePagination";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonTeams } from "redux/actions/Season/actions/SeasonTeams";
import { RootState } from "redux/store";

export const useSeasonTeams = (gid?: string, limit = 6) => {
  const dispatch = useDispatch();
  const seasonTeams = useSelector((state: RootState) => state.gameEngine.leagueTeams);
  const { handleNextPage, handlePrevPage, page } = useServerSidePagination();

  useEffect(() => {
    if (!gid) return;
    dispatch(getSeasonTeams(gid, page, limit));
  }, [gid, page, limit]);

  return { seasonTeams, handleNextPage, handlePrevPage, page, hasPrevPage: page > 1};
};
