/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./FranchiseOverviewStyle.scss";
import { Tab, Nav } from "react-bootstrap";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import OverviewTabMain from "./TabDetail/OverviewTab/OverviewTabMain";
import RosterTabMain from "./TabDetail/RosterTab/RosterTabMain";
import FrontOfficeMain from "./TabDetail/FrontOfficeTab/FrontOfficeTabMain";
import DepthChartTabMain from "./TabDetail/DepthChartTab/DepthChartTabMain";
import GamePlanMain from "./TabDetail/GamePlan/GamePlanMain";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamDepthChart,
  getTeamGamePlan,
  getTeamLeaguesHistory,
  getTeamRoster,
  getFrontOffice,
  putTeamGamePlan,
  putTeamDepthChart,
  getTeamPlayersReveal,
} from "../../redux/actions/GameEngineAction";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useEffect, useMemo } from "react";
import { TeamDepthChartList, TeamGamePlan } from "../../types";
import StatsMain from "./TabDetail/StatsTab/StatsTabMain";
import { HistoricalResultsBody } from "../HistoricalResults/HistoricalResultsBody";
import { TabEnum, useTabs } from "./hooks/use-tabs";
import LeagueScoreTabMain from "components/Common/LeagueScoreTab/LeagueScoreTabMain";
import { SelectedFormations } from "./TabDetail/DepthChartTab/SelectedFormations";

const FranchiseOverviewTabMain = () => {
  const dispatch = useDispatch();
  const { franchiseId }: any = useParams();

  const userTeamListData = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const franchise = useMemo(() => {
    return userTeamListData?.find((info: any) => info?.id == franchiseId);
  }, [userTeamListData, franchiseId]);

  useEffect(() => {
    if (franchise?.engId) {
      dispatch(getTeamDepthChart(franchise?.engId));
      dispatch(getTeamPlayersReveal(franchise?.engId));
    }
  }, [franchise?.engId]);

  const currentFranchise = userTeamListData?.find(
    (info: any) => info?.id === +franchiseId
  );

  const updateGamePlan = (gamePlan: TeamGamePlan["schemes"]) => {
    console.log("!!! updateGamePlan", currentFranchise, gamePlan);
    if (currentFranchise) {
      dispatch(putTeamGamePlan(currentFranchise.engId, gamePlan));
    }
  };

  const updateDepthChart = (depthCharts: TeamDepthChartList) => {
    console.log("!!! updateDepthChart", currentFranchise, depthCharts);
    if (currentFranchise) {
      dispatch(putTeamDepthChart(currentFranchise.engId, depthCharts));
    }
  };

  const { tab, handleTabChange } = useTabs(franchiseId!);

  useEffect(() => {
    if (!currentFranchise?.engId) return;
    if (tab === TabEnum.FRONT_OFFICE) {
      dispatch(getFrontOffice(currentFranchise?.engId));
    }
    if (tab === TabEnum.ROSTER) {
      dispatch(getTeamRoster(currentFranchise?.engId));
    }
  }, [currentFranchise?.engId, dispatch, tab]);

  return (
    <>
      <div className="standingTabbarMain">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={TabEnum.OVERVIEW}
          activeKey={tab}
          onSelect={(key) =>
            handleTabChange((key as TabEnum) || TabEnum.OVERVIEW)
          }
          mountOnEnter
          unmountOnExit
        >
          <div className="standingTabbar">
            <Card>
              <CardBody>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      eventKey={TabEnum.OVERVIEW}
                      onClick={() => {
                        dispatch(
                          getTeamLeaguesHistory(currentFranchise?.engId)
                        );
                        // dispatch(getTeamLeaders(2, 12));
                      }}
                    >
                      OVERVIEW
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.ROSTER}>ROSTER</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey={TabEnum.DEPTH_CHART}
                      onClick={() => {
                        if (currentFranchise?.engId) {
                          dispatch(getTeamDepthChart(currentFranchise?.engId)); // currentFranchise?.engId));
                          dispatch(getTeamGamePlan(currentFranchise?.engId)); // currentFranchise?.engId));
                        }
                      }}
                    >
                      DEPTH CHART
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey={TabEnum.GAME_PLAN}
                      onClick={
                        () => dispatch(getTeamGamePlan(currentFranchise?.engId)) //currentFranchise?.engId))
                      }
                    >
                      GAME PLAN
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey={TabEnum.FRONT_OFFICE}
                      onClick={() =>
                        dispatch(getFrontOffice(currentFranchise?.engId))
                      }
                    >
                      FRONT OFFICE
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.STATS}>
                      STATS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.SCORE}>SCORE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.HISTORY}>HISTORY</Nav.Link>
                  </Nav.Item>
                </Nav>
              </CardBody>
            </Card>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey={TabEnum.OVERVIEW}>
              <OverviewTabMain />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.ROSTER}>
              <RosterTabMain />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.DEPTH_CHART}>
              <>
                <SelectedFormations />
                <DepthChartTabMain updateDepthChart={updateDepthChart} />
              </>
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.GAME_PLAN}>
              <GamePlanMain updateGamePlan={updateGamePlan} />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.FRONT_OFFICE}>
              <FrontOfficeMain />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.STATS}>
              <StatsMain />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.SCORE}>
              <HistoricalResultsBody franchise={franchise} />
            </Tab.Pane>
            <Tab.Pane eventKey={TabEnum.HISTORY}>
              <LeagueScoreTabMain />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default FranchiseOverviewTabMain;
