import { get } from "redux/services/Api";
import { NewsActionTypes } from "..";
import { API_URL } from "config";

export const getNews = (page = 1, limit = 3) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: NewsActionTypes.GET_NEWS_LIST_REQUEST,
        payload: {},
      });

      const data = await get(
        `${API_URL}/user/get-news-list?page=${page}&limit=${limit}`
      );

      dispatch({
        type: NewsActionTypes.GET_NEWS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: NewsActionTypes.GET_NEWS_LIST_FAIL,
      });
    }
  };
};

