import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGroupInvitations } from "redux/actions/Groups";
import { RootState } from "redux/store";

export function useGroupInvitations(userId?: number) {
  const dispatch = useDispatch();
  const groupInvitations = useSelector(
    (state: RootState) => state.profile.groupsInvitations
  );

  useEffect(() => {
    if (!userId) return;

    dispatch(GetGroupInvitations());
  }, [userId, dispatch]);

  return {
    groupInvitations,
  }
}
