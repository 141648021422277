/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./FrontOfficeTabStyle.scss";
import Card from "../../../Common/Card/Card";
import CardBody from "../../../Common/Card/CardBody";
import { Row, Col } from "react-bootstrap";
import { Modal } from "antd";
import BlueButton from "../../../Common/Button/BlueButton/BlueButton";
import Title from "../../../Common/Title/Title";
import { useEffect, useState } from "react";
import HeadCoachModal from "./Modal/HeadCoachModal";
import GeneralManagerModal from "./Modal/GeneralManagerModal";
import AssistantCoachNotAllowModal from "./Modal/AssistantCoachNotAllowModal";
import GeneralManagerNotAllow from "./Modal/GeneralManagerNotAllow";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { FrontOfficeMember } from "./FrontOfficeMember";

const FrontOfficeMain: React.FC = () => {
  const frontOfficeData = useSelector(
    (state: RootState) => state.gameEngine.frontOffice
  );

  //MODAL 1
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // MODAL 2
  const [visible1, setVisible1] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  // MODAL 3
  const [visible2, setVisible2] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const showModal2 = () => {
    setIsModalVisible2(true);
  };
  const handleOk2 = () => {
    setIsModalVisible2(false);
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  // MODAL 4
  const [visible3, setVisible3] = useState(false);

  const [HC, setHC] = useState<any>();
  const [AC, setAC] = useState<any>();
  const [GM, setGM] = useState<any>();
  const [AG, setAG] = useState<any>();
  const [AH, setAH] = useState<any>();

  useEffect(() => {
    if (frontOfficeData?.length > 0) {
      let HC_Data: any = [];
      let AC_Data: any = [];
      let GM_Data: any = [];
      let AG_Data: any = [];
      let AH_Data: any = [];

      frontOfficeData?.map((data: any) => {
        if (data?.ratings?.[0]?.pos == "HC") {
          HC_Data.push(data);
        }
        if (data?.ratings?.[0]?.pos == "AC") {
          AC_Data.push(data);
        }
        if (data?.ratings?.[0]?.pos == "GM") {
          GM_Data.push(data);
        }
        if (data?.ratings?.[0]?.pos == "AG") {
          AG_Data.push(data);
        }
        if (data?.ratings?.[0]?.pos == "AH") {
          AH_Data.push(data);
        }
      });

      setHC(HC_Data);
      setAC(AC_Data);
      setGM(GM_Data);
      setAG(AG_Data);
      setAH(AH_Data);
    }
  }, [frontOfficeData]);

  return (
    <>
      <Row>
        <Col xl={12} className="mb-5">
          <Card>
            <CardBody>
              <Title text="FRANCHISE STAFF" />
              <FrontOfficeMember dataList={HC} memberType="HEAD COACH"/>
              <FrontOfficeMember dataList={AC} memberType="ASSISTANT COACH"/>
              <FrontOfficeMember dataList={GM} memberType="GENERAL MANAGER"/>
              <FrontOfficeMember dataList={AG} memberType="ASSISTANT GM"/>
              <FrontOfficeMember dataList={AH} memberType="AVAILABLE FOR HIRE"/>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <div className="mb-3" >
        <BlueButton onClick={() => setVisible(true)} text="HEAD COACH NOT ALLOW" />
        <BlueButton onClick={() => setVisible1(true)} text="ASSISTANT HIRE NOE ALLOW" />
        <BlueButton onClick={() => setVisible2(true)} text="GENERAL MANAGER NOT ALLOW" />
        <BlueButton onClick={() => setVisible3(true)} text="GENERAL MANAGER HIRE NOT ALLOW" />
      </div> */}

      {/* HEAD COACH NOT ALLOW */}
      <Modal
        centered
        className="rostererror"
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
        width={450}>
        <HeadCoachModal />
      </Modal>
      {/* HEAD COACH NOT ALLOW */}

      <Modal
        centered
        className="rostererror"
        open={visible1}
        onOk={() => setVisible1(false)}
        onCancel={() => setVisible1(false)}
        footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
        width={450}>
        <AssistantCoachNotAllowModal />
      </Modal>

      <Modal
        centered
        className="rostererror"
        open={visible2}
        onOk={() => setVisible2(false)}
        onCancel={() => setVisible2(false)}
        footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
        width={450}>
        <GeneralManagerNotAllow />
      </Modal>

      <Modal
        centered
        className="rostererror"
        open={visible3}
        onOk={() => setVisible3(false)}
        onCancel={() => setVisible3(false)}
        footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
        width={450}>
        <GeneralManagerModal />
      </Modal>
    </>
  );
};

export default FrontOfficeMain;
