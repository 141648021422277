import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { checkIfGameFinished, deleteTrialGameData, getTrialGameData } from "../helper";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

export const useFreeGame = () => {
  const resData = useSelector((state: RootState) => state.app.resData);
  const [isYouInFreeGame, setIsYouInFreeGame] = useState(false);
  const [gid, setGid] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (resData) {
      deleteTrialGameData();
    }
  }, [resData]);

  useEffect(() => {
    if (location.pathname === "/join-free-game") {
      setIsYouInFreeGame(true);
    } else if (location.pathname.includes('gameplay') && getTrialGameData()?.gid) {
      setIsYouInFreeGame(true);
    } else {
      setIsYouInFreeGame(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const storedGameData = getTrialGameData();
    if (storedGameData) {
      if (Date.now() - Date.parse(storedGameData.timestamp) > 1800000) {
        deleteTrialGameData();
        setGid(0);
      } else {
        checkIfGameFinished(storedGameData.gid).then((isGameFinished) => {
          if (!isGameFinished) {
            setGid(storedGameData.gid);
          } else {
            deleteTrialGameData();
            setGid(0);
          }
        });
      }
    }
  }, []);

  return { isYouInFreeGame, gid };
}