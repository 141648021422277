/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import email from "../../assets/images/contact/email_icon.png";
import location from "../../assets/images/contact/location_icon.png";

const ContactDetail = () => {
  return (
    <>
      <div className="contact_detail">
        <div className="contact_info">
          <img src={email} alt="email_icon" />
          <div className="contact_subinfo">
            <h5>Email Us</h5>
            <a href="#">admin@gmdynasty.com</a>
          </div>
        </div>
        <div className="contact_info">
          <img src={location} alt="email_icon" />
          <div className="contact_subinfo">
            <h5>Address</h5>
            <p>
              US Headquarters <br /> 4900 Morgan Ave South <br /> Minneapolis,
              Mn 55419
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetail;
