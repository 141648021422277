import { Col, Row } from "react-bootstrap";
import ProfileImageBackup from "../../../assets/images/common/userName.png";
import OnlineIndicator from "../../../assets/images/common/wifi.svg";

import { API_URL } from "config";
import { GroupMember } from "types/groups";
import { User } from "types/user";

interface Props {
  member: GroupMember,
  handleRejectInvite: (groupId: GroupMember['groupId'], userId: User['id']) => void;
  handleAcceptInvite: (groupId: GroupMember['groupId'], userId: User['id']) => void;
}
export const GroupRequestMemberItem: React.FC<Props> = ({
  member,
  handleRejectInvite,
  handleAcceptInvite,
}) => {

  const profileImg = (member: GroupMember) =>
    member.user.avatar
      ? `${API_URL}/images/avatar/${member.user.avatar}`
      : ProfileImageBackup;

  return (

    <Col key={member.user.id} sm={4} xs={6} className="g-4">
      <div className="request_card">
        <Row>
          <Col
            sm={12}
            md={5}
            lg={4}
            xl={3}
            className="d-flex justify-content-center"
          >
            <div className="img__container">
              <img src={profileImg(member)} alt="profile" />
              {member.user.networkStatus === "online" && (
                <div className="indicator">
                  <img src={OnlineIndicator} alt="online" />
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} md={7} lg={8} xl={9}>
            <Row>
              <Col className="d-flex justify-content-start">
                <p className="friend-label">{member.user.userName}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md="auto">
                <button
                  className="request_card__reject-btn"
                  onClick={() => handleRejectInvite(member.groupId, member.user.id)}
                >
                  Ignore
                </button>
              </Col>
              <Col xs={12} md="auto">
                <button
                  className="request_card__accept-btn"
                  onClick={() => handleAcceptInvite(member.groupId, member.user.id)}
                >
                  Accept
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
