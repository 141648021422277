import { GameEvent } from '../GamePlayer';

const downName = ['', '1st', '2nd', '3rd', '4th'];

type GameEventWithTitles = GameEvent & { title: string };

export function useGrouppedMessages(quatreMessage: GameEvent[]) {
  const messagesWithTitles = quatreMessage.map((message) => ({
    ...message,
    title: `${downName[message.down]} and ${message.toGo} on ${message.scrimmage < 50 ? message.scrimmage : 100 - message.scrimmage}`,
  }));

  const groupedMessages: GameEventWithTitles[][] = [];

  for (let i = 1; i < messagesWithTitles.length; i++) {
    const prevMessage = messagesWithTitles[i - 1];
    const message = messagesWithTitles[i];

    if (prevMessage.title !== message.title) {
      groupedMessages.push(messagesWithTitles.slice(0, i));
      messagesWithTitles.splice(0, i);
      i = 0;
    } else if (messagesWithTitles.every((m) => m.title === message.title)) {
      groupedMessages.push([...messagesWithTitles]);
      break;
    }
  }

  return { groupedMessages };
}
