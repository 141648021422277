import { Spin, Table } from 'antd';
import { useTeamTendencies } from './hooks/useTeamTendencies';
import { getFormationNameBySidAndType } from './helpers';
import Title from 'components/Common/Title/Title';
import { Col, Row } from 'react-bootstrap';
import { convertCamelCaseToUpperCase } from 'helper/helper';
import { offensiveColumns, defensiveColumns, gameScriptsColumns } from './constants';

type Props = {
  engId?: number;
};

export const TeamTendencies: React.FC<Props> = ({ engId }) => {
  const { loading, tendencies } = useTeamTendencies(engId);

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <Title text="TEAM TENDENCIES" />
      <Row>
        <Col className="statsTable standingmain">
          <Table
            columns={offensiveColumns}
            pagination={false}
            dataSource={
              tendencies?.offensiveFormation
                ? Object.entries(tendencies.offensiveFormation).map(([key, value]) => ({ name: getFormationNameBySidAndType(+key, 'offensive'), value: `${value} %` }))
                : []
            }
            size="small"
          />
        </Col>
        <Col className="statsTable standingmain">
          <Table
            columns={defensiveColumns}
            pagination={false}
            dataSource={
              tendencies?.defensiveFormation
                ? Object.entries(tendencies.defensiveFormation).map(([key, value]) => ({ name: getFormationNameBySidAndType(+key, 'defensive'), value: `${value} %` }))
                : []
            }
            size="small"
          />
        </Col>
        <Col className="statsTable standingmain">
          <Table
            columns={gameScriptsColumns}
            pagination={false}
            dataSource={tendencies?.gameScripts ? Object.entries(tendencies.gameScripts).map(([key, value]) => ({ name: convertCamelCaseToUpperCase(key), value })) : []}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};
