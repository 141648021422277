import { Popover } from "antd";
import { ViewPopoverContent } from "./ViewPopoverContent";

type Props = {
  children: React.ReactNode;
  utid: number;
  teamName: string;
};

export const ViewPopover: React.FC<Props> = ({
  children,
  utid,
  teamName,
}) => {
  return (
    <Popover
      trigger={["hover", "click"]}
      placement="bottom"
      content={<ViewPopoverContent utid={utid} teamName={teamName} />}
    >
      {children}
    </Popover>
  );
};
