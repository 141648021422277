import { ViewMoreStringTypes } from "./LeaderboardContent";
import { LeaderboardTeamLeadersList } from "./LeaderboardTeamLeadersList";
import { useWeeklyLeadersWins } from "./hooks";

export const LeaderboardWeeklyLeadersWins = () => {
  const { weeklyLeadersWins } = useWeeklyLeadersWins();

  if (!weeklyLeadersWins) {
    return <div>Sorry, there are no data</div>;
  }

  return (
    <LeaderboardTeamLeadersList
      leadersList={weeklyLeadersWins}
      viewMoreType={ViewMoreStringTypes["WEEKLY-LEADERS-WINS"]}
    />
  );
};
