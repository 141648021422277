import FranchiseDetail from "../Common/FranchiseDetail/FranchiseDetail";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import { API_URL } from "../../config";
import { useEffect } from "react";
import history from "../../routes/history";
import { getAvatarData } from "../../redux/actions/TeamAction";
import Stickers from "../../assets/images/league/leagueImage.jpg";
import { getTeamOverview, PregameOverview, UserRecordAPI } from "../../redux/actions/GameEngineAction";
import { useParams } from "react-router-dom";

const FullTeamStatHeader = ({gid: overriteGid}: {gid?: number}) => {
  let { gid } = useParams<{gid: string}>();
  const teamOverviewData = useSelector(
    (state: RootState) => state?.gameEngine?.teamOverview
  );
  const TeamData = useSelector(
    (state: RootState) => state?.team?.userTeamsList
  );
  const UserData = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview
  );
  const HeaderData = useSelector(
    (state: RootState) => state?.gameEngine?.UserRecord
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getAvatarData());
    if (overriteGid || gid) {
      dispatch(PregameOverview(overriteGid || gid))
    }
  }, [gid, overriteGid]);

  useEffect(() => {
    if (UserData?.teams?.[0]?.utid) {
      dispatch(getTeamOverview(1, UserData?.teams?.[0]?.utid));
    }
  }, [UserData?.teams?.[0]?.utid]);

  useEffect(() => {
    let userTeam
    if (TeamData && UserData?.teams) {
      userTeam = TeamData.find((data: any) => data?.engId === UserData?.teams[0]?.utid) || TeamData.find((data: any) => data?.engId === UserData?.teams?.[1]?.utid)
    }
    if (userTeam && userTeam.engId) {
      dispatch(UserRecordAPI(userTeam.engId))
    } else if (UserData?.teams?.[0]?.utid) {
      dispatch(UserRecordAPI(UserData?.teams?.[0]?.utid))
    }
  }, [UserData, TeamData])

  const FirstUserTeamData = (TeamData && UserData?.teams)
    ? TeamData.find((data: any) => data?.engId === UserData?.teams?.[0]?.utid)
    : undefined;
  const SecondUserTeamData = (TeamData && UserData?.teams)
    ? TeamData.find((data: any) => data?.engId === UserData?.teams?.[1]?.utid)
    : undefined;

    const data1 = [
    {
      id: 1,
      profileimage: FirstUserTeamData ? `${API_URL}/images/franchise/userFranchise/${FirstUserTeamData.img}` : SecondUserTeamData ? `${API_URL}/images/franchise/userFranchise/${SecondUserTeamData.img}` : Stickers,
      protfolioname: "HEAD-TO-HEAD",
      usertitle: "FRANCHISE :",
      username: FirstUserTeamData ? FirstUserTeamData.teamName : SecondUserTeamData ? SecondUserTeamData.teamName: '---',
      currentleaguetitle: "RATING :",
      currentleague: HeaderData?.ovr === undefined ? 0 : HeaderData?.ovr,
      joinedtitle: "TYPE :",
      joined: "HEAD-TO-HEAD",
      franchisestitle: "ENTRY FEE :",
      franchises: UserData?.fee
        ? `$ ${UserData?.fee}`
        : "$50",
      wallettitle: "LEAGUE ID :",
      wallet: "h2h",
      mintedplayertitle: "PRIZE POOL :",
      mintedplayer: UserData?.payout
        ? `$ ${UserData?.payout}`
        : "$20",
      emailtite: "STARTED :",
      email: UserData?.created
        ? moment(UserData?.created).format("MM/DD/YYYY hh:mm")
        : "---",
      fundsplaytitle: "GAME TIME :",
      fundsplay:UserData?.end ?
        moment(UserData?.end).format("MM/DD/YYYY hh:mm")
        : "---",
      win: "RECORD",
      windigit:
        HeaderData?.won &&
        HeaderData?.lost &&
        HeaderData?.tied === undefined
          ? `0-0-0`
          : `${HeaderData?.won}-${HeaderData?.lost}-${HeaderData?.tied}`,
      winpercentage: "WIN %",
      winpercentagedigit: HeaderData?.pct === undefined
        ? `0 %`
        : HeaderData?.pct,
      champs: "RANK",
      champsdigit: "n/a",
      rating: "RATING",
      ratingdigit: HeaderData?.ovr === undefined ? 0 : HeaderData?.ovr,
      ratingStatus: teamOverviewData?.ratingStatus
        ? teamOverviewData?.ratingStatus
        : "--",
    },
  ];

  return (
    <>
      {data1.map((d: any, i: any) => (
        <FranchiseDetail rating arrow data={d} key={i} />
      ))}
    </>
  );
};

export default FullTeamStatHeader;
