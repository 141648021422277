import { useEffect, useState } from 'react';
import { get } from 'redux/services/Api';
import { Tendencies } from '../types';

export const useTeamTendencies = (engId?: number) => {
  const [tendencies, setTendencies] = useState<Tendencies | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!engId) return;

    (async () => {
      try {
        setLoading(true);
        const response = await get<Tendencies>(`/engine/team/${engId}/tendencies`);
        setTendencies(response);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [engId]);

  return {
    tendencies,
    loading,
  };
};
