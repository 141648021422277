import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import SeasonActions from 'redux/actions/Season/actions';

export const useSeasonInfo = (gid?: string) => {
  const dispatch = useDispatch();
  const seasonInfo = useSelector((state: RootState) => state.gameEngine.seasonInfo);

  const loadSeasonInfo = () => {
    if (!gid) return;
    dispatch(SeasonActions.getSeasonInfo(gid));
  }

  return { seasonInfo, loadSeasonInfo };
};
