import './FranchiseDetailStyle.scss';
import Card from '../Common/Card/Card';
import CardBody from '../Common/Card/CardBody';
import { Container } from 'react-bootstrap';
import AnotherTeamHeader from '../FullTeamStat/AnotherTeamHeader';
import { useParams } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { useState } from 'react';
import { OverviewTab } from './OverviewTab';
import FullTeamAvarageGame from 'components/FullTeamStat/FullTeamAvarageGame';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getFullTeamStats, getTeamGameDepthChart } from 'redux/actions/GameEngineAction';
import { FullPlayerStatContent } from 'components/FullPlayerStat/FullPlayerStatContent';
import { preparePlayersDepth } from 'components/FullPlayerStat/helper';
import { ProgressionTab } from './ProgressionTab';

enum TabEnum {
  'TEAM STATS' = 'TEAM STATS',
  'PLAYER STATS' = 'PLAYER STATS',
  PERFORMANCE = 'PERFORMANCE',
  PROGRESSION = 'PROGRESSION',
}

const FranchiseDetailMain: React.FC = () => {
  const dispatch = useDispatch();
  const { franchiseId }: any = useParams();
  const engId = +franchiseId;
  const [tab, setTab] = useState<TabEnum>(TabEnum.PERFORMANCE);

  const teamStats = useSelector((state: RootState) => state.gameEngine.FullTeamStats);
  const teamDepthChart = useSelector((state: RootState) => state.gameEngine.teamDepthChart);

  return (
    <Container>
      <AnotherTeamHeader engId={engId} />
      <div className="standingTabbarMain">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={TabEnum.PERFORMANCE}
          activeKey={tab}
          onSelect={(key) => setTab((key as TabEnum) || TabEnum.PERFORMANCE)}
          mountOnEnter
          unmountOnExit
        >
          <div className="standingTabbar">
            <Card>
              <CardBody>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.PERFORMANCE}>PERFORMANCE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum['TEAM STATS']} onClick={() => dispatch(getFullTeamStats(engId))}>
                      TEAM STATS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum['PLAYER STATS']} onClick={() => dispatch(getTeamGameDepthChart(engId, 0, 'fullplayerstat'))}>
                      PLAYER STATS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={TabEnum.PROGRESSION}>PROGRESSION</Nav.Link>
                  </Nav.Item>
                </Nav>
              </CardBody>
            </Card>
          </div>
          <Card>
            <CardBody>
              <Tab.Content>
                <Tab.Pane eventKey={TabEnum.PERFORMANCE}>
                  <OverviewTab engId={engId} withRating={false} />
                </Tab.Pane>
                <Tab.Pane eventKey={TabEnum['TEAM STATS']}>
                  <FullTeamAvarageGame teamStat={teamStats || {}} />
                </Tab.Pane>
                <Tab.Pane eventKey={TabEnum['PLAYER STATS']}>
                  <FullPlayerStatContent preparedPlayers={teamDepthChart ? preparePlayersDepth(teamDepthChart) : []} />
                </Tab.Pane>
                <Tab.Pane eventKey={TabEnum.PROGRESSION}>
                  <ProgressionTab engId={engId} />
                </Tab.Pane>
              </Tab.Content>
            </CardBody>
          </Card>
        </Tab.Container>
      </div>
    </Container>
  );
};

export default FranchiseDetailMain;
