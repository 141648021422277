import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ShareAltOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";
import { API_URL } from "config";
import history from "../../../routes/history";
import helmetBlue from "../../../assets/images/helmet/helmetBlue.png";
import helmetRed from "../../../assets/images/helmet/helmetRed.png";
import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import TeamHelmet from "components/Common/TeamHelmet/TeamHelmet";
import { copyLinkToClipboard } from "helper/helper";
import { LobbyInProgressGame, UserTeamAvatarWithHelmet } from "./helper";

type Props = {
  avatarsList: UserTeamAvatarWithHelmet[];
  game: LobbyInProgressGame;
}

export function GameInProgress({ game, avatarsList }: Props) {
  const leftTeam = avatarsList.find((avatar) => avatar.engId === game?.teams?.[0])
  const rightTeam = avatarsList.find((avatar) => avatar.engId === game?.teams?.[1])

  const helmetsData = useMemo(() => {
    return {
      leftHelmetLogo: `${API_URL}${leftTeam?.logoUrl}`,
      rightHelmetLogo: `${API_URL}${rightTeam?.logoUrl}`,
      leftFranchiseName: leftTeam?.teamName ?? "FRANCHISE 1",
      rightFranchiseName: rightTeam?.teamName ?? "FRANCHISE 2",
      leftHelmet: leftTeam?.helmetUrl ? require(`../../../assets${leftTeam?.helmetUrl}`) : helmetBlue,
      rightHelmet: rightTeam?.helmetUrl ? require(`../../../assets${rightTeam?.helmetUrl}`) : helmetRed,
      leftPlayerUtid: leftTeam?.engId ?? 0,
      rightPlayerUtid: rightTeam?.engId ?? 0,
    }
  }, [avatarsList, game]);

  const handleWatch = () => {
    history.push(`/gameplay?id=${game?.gid}`);
  }

  const shareByCopyLinkToClipboard = async () => {
    const link = `${window.location.origin}/gameplay?id=${game?.gid}`
    await copyLinkToClipboard(link);
  };

  return (
    <Row key={game?.gid} className="lobby__game_row">
      <Col md={3} sm={{ span: 3, order: 1 }} xs={{ span: 6, order: 1 }}>
        <Row style={{ alignItems: 'center' }}>
          <Col lg={3} md={12} className="no-padding">
            <TeamHelmet
              withoutName={true}
              playerUtid={helmetsData.leftPlayerUtid}
              baseSrc={helmetsData.leftHelmet}
              logoSrc={helmetsData.leftHelmetLogo}
              franchiseName={helmetsData.leftFranchiseName}
              direction="left"
            />
          </Col>
          <Col lg={9} md={12} className="center">
            <Link
              to={`../../franchisedetail/${helmetsData.leftPlayerUtid}`}
              className="a_color"
            >
              {helmetsData.leftFranchiseName}
            </Link>
          </Col>
        </Row>
      </Col>
      <Col md={3} sm={{ span: 3, order: 2, offset: 0 }} xs={{ span: 8, order: 3, offset: 2 }} className="no-padding score center">
        {game.scores?.[0][0] || 0} to {game.scores?.[1][0] || 0}
      </Col>
      <Col md={3} sm={{ span: 3, order: 3 }} xs={{ span: 6, order: 2 }}>
        <Row>
          <Col lg={3} md={12} className="no-padding">
            <TeamHelmet
              withoutName={true}
              playerUtid={helmetsData.rightPlayerUtid}
              baseSrc={helmetsData.rightHelmet}
              logoSrc={helmetsData.rightHelmetLogo}
              franchiseName={helmetsData.rightFranchiseName}
              direction="right"
            />
          </Col>
          <Col lg={9} md={12} className="center">
            <Link
              to={`../../franchisedetail/${helmetsData.rightPlayerUtid}`}
              className="a_color"
            >
              {helmetsData.rightFranchiseName}
            </Link>
          </Col>
        </Row>
      </Col>
      <Col md={1} sm={{ span: 1, order: 4 }} xs={{ span: 2, order: 4 }} className="center">
        <ShareAltOutlined
          title="Copy link"
          className="share-gameplay__button"
          onClick={shareByCopyLinkToClipboard}
        />
      </Col>
      <Col md={2} sm={{ span: 2, order: 5 }} xs={{ span: 12, order: 5 }} className="lobby__game_row_watch_button_cell no-padding">
        <PinkButton
          disabled={!game?.gid}
          onClick={handleWatch}
          text={`WATCH`}
        />
      </Col>
    </Row>
  );
}
