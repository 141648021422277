import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import { LobbyGamesContent } from "./LobbyGames/LobbyGamesContent";
import { LobbyUsers } from "./LobbyUsers/LobbyUsers";
import Chat from "components/GamePlay/Chat/Chat";
import { LobbyGroups } from "./LobbyGroups/LobbyGroups";
import blueLogo from "../../assets/images/common/gm_blue_logo.png";


export const LobbyContent = () => {
  return (
    <Card>
      <CardBody>
        <div className="lobby__title">
          <img src={blueLogo} alt="logo" />
          <h1>Lobby</h1>
        </div>
        <Row>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <LobbyGamesContent />
            <Chat gameId={1} headerTitle="LOBBY CHAT"/>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <LobbyUsers />
            <LobbyGroups />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
