import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCityList } from 'redux/actions/TeamAction';
import { RootState } from 'redux/store';

export const useCityList = () => {
  const dispatch = useDispatch();
  const cityList = useSelector((state: RootState) => state.team.cityList);

  useEffect(() => {
    dispatch(getCityList());
  }, []);

  return cityList;
};
