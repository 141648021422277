import Card from "components/Common/Card/Card";
import helmetBlue from "../../../assets/images/helmet/helmetBlue.png";
import CardBody from "components/Common/Card/CardBody";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSelectedAvatar,
  fetchSelectedAvatar,
} from "redux/actions/TeamAction";
import { RootState } from "redux/store";
import { API_URL } from "config";
import { Col, Row } from "react-bootstrap";
import { PhysicalTrait } from "../components/physical-trait";
import { getPTCL } from "../utils/getPTCL";
import { SpecialFeature } from "../components/physical-trait/SpecialFeature";
import { commonTraits, traitsPerPos } from "../components/physical-trait/PhysicalTrait";
import { formatKeyWithSpaces } from "../utils/formatKey";

type HelmetInfo = {
  logo: string;
  helmet: string;
};

export function ProfileTab({
  playerTeamId,
  playerStats,
}: {
  playerTeamId?: number;
  playerStats: any;
}) {
  const dispatch = useDispatch();
  const avatarsList = useSelector(
    (state: RootState) => state?.team?.fetchedAllAvatar
  );
  const avData = useSelector((state: RootState) => state.team.avatar);
  const teamData = useSelector((state: RootState) => state.team.fetchedAvatar);
  const hasTransactions = Boolean(playerStats?.transactions?.length);
  const helmetInfo = useMemo<HelmetInfo>(() => {
    const helmetInfo = avatarsList?.find(
      (data: any) => +data.engId === playerTeamId
    );
    const helmet = avData?.helmet?.find(
      (h: { id: number }) => h.id === helmetInfo?.helmet
    );
    const logo = avData?.logo?.find(
      (h: { id: number }) => h.id === helmetInfo?.logo
    );
    return {
      logo: `${API_URL}${logo?.url}`,
      helmet: helmet ? require(`../../../assets${helmet?.url}`) : helmetBlue,
    };
  }, [avData?.helmet, avData?.logo, avatarsList, playerTeamId]);
  useEffect(() => {
    if (!playerTeamId) return;
    dispatch(fetchAllSelectedAvatar(playerTeamId));
    dispatch(fetchSelectedAvatar(playerTeamId));
  }, [playerTeamId, dispatch]);

  const hasPhysicalTraits = Boolean(
    playerStats?.height ||
      playerStats?.weight ||
      playerStats?.strength ||
      playerStats?.speed ||
      playerStats?.explosiveness ||
      playerStats?.agility
  );
  
  return (
     <Card>
        <CardBody>
           <div className="playerDetailTab">
              <div style={{ marginBottom: -40 }} className="tab__section">
                 <h5 className="tab__section_heading">PLAYER OWNERSHIP</h5>
                 <Row>
                    <Col xl={2} lg={2} md={3}>
                       <div className="a_color franchise">
                          <div className="helmet">
                             <img
                                className="helmet__item helmet__item--right"
                                src={helmetInfo?.helmet}
                                alt="helmet"
                                onError={(e) => {
                                   e.currentTarget.src = helmetBlue;
                                }}
                             />
                             {helmetInfo?.logo && (
                                <img
                                   className="hidden"
                                   src={helmetInfo.logo}
                                   alt="helmet-logo"
                                   onError={(e) => {
                                      e.currentTarget.className = "hidden";
                                   }}
                                   onLoad={(e) => {
                                      e.currentTarget.className =
                                         "helmet__logo helmet__logo--right";
                                   }}
                                />
                             )}
                          </div>
                       </div>
                    </Col>
                    <Col
                       style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingBottom: 45,
                       }}
                       xl={2}
                       lg={2}
                       md={3}
                    >
                       <div className="player_ownership__info">
                          <p className="title">{teamData?.teamName}</p>
                          <p>{teamData?.userName}</p>
                       </div>
                    </Col>
                    {/* <Col
                        style={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           paddingBottom: 45,
                        }}
                        xl={5}
                        lg={5}
                        md={6}
                     >
                        {isOwner ? (
                           <div className="player_ownership_buttons__container">
                              <button className="player_ownership__button">MINT</button>
                              <button className="player_ownership__button">
                                 ADD TO TRADE BLOCK
                              </button>
                              <button className="player_ownership__button">OFFER FOR SALE</button>
                           </div>
                        ) : (
                           <div className="player_ownership_buttons__container">
                              <button className="player_ownership__button">TRADE OFFER</button>
                              <button className="player_ownership__button">PURCHASE OFFER</button>
                           </div>
                        )}
                     </Col> */}
                 </Row>
              </div>
              {hasPhysicalTraits && (
                 <div className="tab__section">
                    <h5 className="tab__section_heading">PHYSICAL TRAITS</h5>
                    <div className="physical_traits_container">
                    {commonTraits.map((trait: any) => (
                        (playerStats[trait] !== null && playerStats[trait] !== undefined && (
                           <PhysicalTrait 
                              trait={formatKeyWithSpaces(trait, '_')}
                              value={playerStats[trait]}
                              pctl={getPTCL(playerStats.ptcl, trait)}
                           />
                        ))
                     ))}
                    </div>
                 </div>
              )}
              <div className="tab__section">
                 <div className="two_columns">
                    {(playerStats?.pos && playerStats.pos in traitsPerPos) && (
                       <div>
                          <h5 className="tab__section_heading">POSITIONAL SKILLS</h5>
                          <div className="physical_traits_container">
                              {traitsPerPos[playerStats.pos as keyof typeof traitsPerPos].map((trait: any) => (
                              (playerStats[trait] !== null && playerStats[trait] !== undefined && (
                                 <PhysicalTrait 
                                    trait={formatKeyWithSpaces(trait, '_')}
                                    value={playerStats[trait]}
                                    pctl={getPTCL(playerStats.ptcl, trait)}
                                 />
                              ))
                              ))}
                          </div>
                       </div>
                    )}
                    {Boolean(playerStats?.specialFeature) && (
                       <div>
                          <h5 className="tab__section_heading tab__section_heading-centered">SPECIAL FEATURE</h5>
                          <SpecialFeature specialFeature={playerStats?.specialFeature} withName={true}/>
                        </div>
                    )}
                 </div>
              </div>
              <div className="tab__section">
                 <h5 className="tab__section_heading">PLAYER BIO</h5>
                 <p style={{textAlign: "justify"}}>
                    {playerStats?.bio ? playerStats.bio : "Sorry, Player's bio will appear soon..."}
                 </p>
              </div>
              {hasTransactions && (
                 <div className="tab__section">
                    <h5 className="tab__section_heading">TRANSACTIONS</h5>
                    {/* display transactions */}
                 </div>
              )}
           </div>
        </CardBody>
     </Card>
  );
}
