import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FriendshipsStatus } from "types/friendships";
import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { FriendInviteModal } from "components/Friends/FriendInvite/FriendInviteModal";
import { usePagination } from "helper/usePagination";
import { Pagination } from "./common";
import { Friend } from "./friend";
import { ProfileState } from "../hooks";
import { useFriends } from "../hooks/useFriends";


export function Friends({ isOwn }: Pick<ProfileState, "isOwn">) {
  const history = useHistory();
  const { acceptedFriends: friends, friendsRequests } = useFriends();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const requestCount = friendsRequests?.length || 0;
  const hasRequests = Boolean(requestCount);
  const { paginated, ...paginationOptions } = usePagination(friends || null, 6);
  if (!friends) return <></>;

  const handleRedirectToRequests = () => {
    history.push("/friend-requests");
  };

  return (
    <div className="friends section">
      <div className="section__header">
        <h5>FRIENDS</h5>
        <div className="right">
          {hasRequests && isOwn && (
            <PinkButton
              onClick={handleRedirectToRequests}
              text={`REQUESTS (${requestCount})`}
            />
          )}
          {isOwn && <PinkButton
            onClick={() => setInviteModalOpen(true)}
            text={`INVITE`}
          />}
          <Pagination {...paginationOptions} />
        </div>
      </div>
      <div className="section__body">
        <Row>
          {friends && friends.length ? (
            paginated
              .filter((friend) => friend.status === FriendshipsStatus.ACCEPTED)
              .map((friend) => (
                <Col key={friend.id} xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Friend key={friend.id} data={friend} />
                </Col>
              ))
          ) : (
            <h5>No friends</h5>
          )}
        </Row>
      </div>
      <FriendInviteModal isOpen={inviteModalOpen} onClose={() => setInviteModalOpen(false)} />
    </div>
  );
}
