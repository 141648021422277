/* eslint-disable eqeqeq */
import "./DepthChartTabStyle.scss";
import { Badge } from "react-bootstrap";
import { Button, Divider, Table } from "antd";
import { Link } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Position, TeamDepthChartEntry } from "../../../../types";
import { wrapAllAntdTable } from "./ValueWrapper";

const type = "DraggableBodyRow";

type MyRowProps = React.HTMLAttributes<HTMLElement>;

const DraggableBodyRow = ({
  index,
  pid,
  moveRow,
  className,
  style,
  ...restProps
}: any) => {
  const ref: any = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex }: any = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: any) => {
      moveRow(item.pid, pid);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index, pid },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

type Props = {
  position: Position;
  title: string;
  columns: any[];
  slots: string[];
  players: TeamDepthChartEntry[];
  setDepthChartPositions?: (
    position: Position,
    newPositions: TeamDepthChartEntry[]
  ) => void;
  handleApply?: (position: Position) => void;
  handleReset?: (position: Position) => void;
};

const DepthChartPosition: React.FC<Props> = ({
  position,
  title,
  columns,
  slots,
  players,
  setDepthChartPositions,
  handleApply,
  handleReset,
}) => {
  const [data, setData] = useState<any[]>([]);
  
  useEffect(() => {
    const playersDataFormatted = players.map((e, key) => {
      const posBadge = slots[key] || "B";
      const badgeColorClass = slots[key] ? "badgeRoman" : "badgeSpindle";
      let info = {
        key: e.pid,
        pid: e.pid,
        position: (
          <>
            <Badge className={`${badgeColorClass} badgemar`}>{posBadge}</Badge>
            <Link
              className="a_color"
              to={`/playerdetail/${e.pid}`}
            >{`${e.firstName} ${e.lastName}`}</Link>
          </>
        ),
        ht: e.heightText 
            ? e.heightText 
            : "- -",
        wt: e.weight || e.weight == 0 
          ? e.weight 
          : "- -",
        spd: e.ratings?.spd || e.ratings?.spd == 0 
          ? e.ratings?.spd 
          : "- -",
        str: e.ratings?.stre || e.ratings?.stre == 0 
        ? e.ratings?.stre 
        : "- -",
        agl: e.ratings?.hgt || e.ratings?.hgt == 0 
          ? e.ratings?.hgt 
          : "- -",
        endu: e.ratings?.endu || e.ratings?.endu == 0 
        ? e.ratings?.endu 
        : "- -",
        pass: ((e.ratings?.tha || e.ratings?.tha == 0)
        && (e.ratings?.thp || e.ratings?.thp == 0)
        && (e.ratings?.thv || e.ratings?.thv == 0)) 
          ? (+e.ratings.tha + +e.ratings.thp + +e.ratings.thv) / 3 
          : "- -",
        run: e.ratings?.run || e.ratings?.run == 0 
          ? e.ratings?.run 
          : "- -",
        ovr: e.ratings?.ovr || e.ratings?.ovr == 0 
          ? e.ratings?.ovr 
          : "- -",
        kpw: e.ratings?.kpw || e.ratings?.kpw == 0 
          ? e.ratings?.kpw 
          : "- -",
        kac: e.ratings?.kac || e.ratings?.kac == 0 
          ? e.ratings?.kac 
          : "- -",
        ppw: e.ratings?.ppw || e.ratings?.ppw == 0 
          ? e.ratings?.ppw 
          : "- -",
        pac: e.ratings?.pac || e.ratings?.pac == 0 
          ? e.ratings?.pac 
          : "- -",
        prush: e.ratings?.prs || e.ratings?.prs == 0 
          ? e.ratings?.prs 
          : "- -",
        cov: e.ratings?.pcv || e.ratings?.pcv == 0 
          ? e.ratings?.pcv 
          : "- -",
        rstop: e.ratings?.rns || e.ratings?.rns == 0 
          ? e.ratings?.rns 
          : "- -",
        tackl: e.ratings?.tck || e.ratings?.tck == 0 
          ? e.ratings?.tck 
          : "- -",
        blk: (e.ratings?.pbk && e.ratings?.rbk) 
          ? (+e.ratings.pbk + +e.ratings.rbk) / 2
          : "- -",
        rec: ((e.ratings?.rtr || e.ratings?.rtr == 0 ) && (e.ratings?.hnd || e.ratings?.hnd == 0 )) 
          ? (+e.ratings.rtr + +e.ratings.hnd) / 2 
          : "- -",
        pblk: e.ratings?.pbk || e.ratings?.pbk == 0 
          ? e.ratings?.pbk 
          : "- -",
        rblk: e.ratings?.rbk || e.ratings?.rbk == 0 
          ? e.ratings?.rbk 
          : "- -",
        skill: (e.ratings?.skills && Array.isArray(e.ratings?.skills) && e.ratings.skills?.length) 
          ? e.ratings.skills?.join(', ') 
          : "- -",
      };

      return info;
    });
    setData(playersDataFormatted);
  }, [players, slots]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragPid: any, hoverPid: any) => {
      if (dragPid !== hoverPid) {
        const player1 = players.findIndex((p) => p.pid === dragPid);
        const player2 = players.findIndex((p) => p.pid === hoverPid);
        if (player1 === -1 || player2 === -1 || players?.[player1]?.pos?.[0] !== players?.[player2]?.pos?.[0]) return;
        const min = Math.min(player1, player2);
        const max = Math.max(player1, player2);

        const newPositions = [
          ...players.slice(0, min),
          players[max],
          ...players.slice(min + 1, max),
          players[min],
          ...players.slice(max + 1),
        ];
        setDepthChartPositions && setDepthChartPositions(position, newPositions);
      }
    },
    [players, setDepthChartPositions, data]
  );

  return (
    <>
      <div className="standingmain depthtable mt-4">
        <h5 className="title">
          {title}
          <Divider type="vertical" />
          <Button
            type="link"
            className="resetButton"
            disabled={!handleReset}
            onClick={() => {
              if (handleReset) handleReset(position);
            }}
          >
            RESET
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            className="applyButton"
            disabled={!handleApply}
            onClick={() => {
              if (handleApply) handleApply(position);
            }}
          >
            SAVE
          </Button>
        </h5>
        <DndProvider backend={HTML5Backend}>
          <Table
            size="small"
            className="depthcharttable"
            pagination={false}
            scroll={{ x: 1140 }}
            columns={wrapAllAntdTable(columns)}
            dataSource={data}
            components={components}
            onRow={(record, index) =>
              ({
                index,
                pid: record.pid,
                moveRow,
              } as MyRowProps)
            }
          />
        </DndProvider>
      </div>
    </>
  );
};

export default DepthChartPosition;
