import { useLocation } from "react-router-dom";

export function useInvitationInfo() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteCode = queryParams.get("invite_code");
  let inviteType = queryParams.get("type") as string | null;
  inviteType = !inviteType ? "email" : inviteType;

  return { inviteCode, inviteType };
}
