import QB from "../../assets/images/player/QB.png";
import AC from "../../assets/images/player/AC.png";
import AG from "../../assets/images/player/AG.png";
import CB from "../../assets/images/player/CB.png";
import DL from "../../assets/images/player/DL.png";
import GM from "../../assets/images/player/GM.png";
import HC from "../../assets/images/player/HC.png";
import K from "../../assets/images/player/K.png";
import LB from "../../assets/images/player/LB.png";
import OL from "../../assets/images/player/OL.png";
import P from "../../assets/images/player/P.png";
import RB from "../../assets/images/player/RB.png";
import S from "../../assets/images/player/S.png";
import TE from "../../assets/images/player/TE.png";
import WR from "../../assets/images/player/WR.png";
import {
  Player,
  SkillEnum,
  getFullNameOfPlayer,
} from "./LeaderboardPlayersLeaders";
import { LeaderboardPlayerLeaderList } from "./LeaderboardPlayerLeaderList";
import { setPlayerLeaderType } from "redux/actions/GameEngineAction";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";

type Props = {
  players: Player[];
  skill: SkillEnum;
  title: string;
};

export const LeaderboardLeadersBySkill: React.FC<Props> = ({
  players,
  skill,
  title,
}) => {
  const dispatch = useDispatch();
  const firstOne = players.at(0);

  return (
    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
      <div className="leaderboard__leader">
        {firstOne && (
          <div className="leader__card">
            <img
              src={returnCorrectImage(firstOne.position)}
              alt={getFullNameOfPlayer(firstOne)}
            />
            <div>
              <h4>{title?.toUpperCase()}</h4>
              <h5>{getFullNameOfPlayer(firstOne)}</h5>
              <h5>{firstOne.teamName}</h5>
            </div>
            <div className="value__card">
              <p>{firstOne.value}</p>
              <p>TOTAL</p>
            </div>
          </div>
        )}
        <LeaderboardPlayerLeaderList players={players} />
        <div
          className="view-top-100"
          onClick={() => {
            dispatch(setPlayerLeaderType(skill));
          }}
        >
          VIEW TOP 100
        </div>
      </div>
    </Col>
  );
};

export function returnCorrectImage(position: string) {
  if (position === "QB") return QB;
  if (position === "AC") return AC;
  if (position === "AG") return AG;
  if (position === "CB") return CB;
  if (position === "DL") return DL;
  if (position === "GM") return GM;
  if (position === "HC") return HC;
  if (position === "K") return K;
  if (position === "LB") return LB;
  if (position === "OL") return OL;
  if (position === "P") return P;
  if (position === "RB") return RB;
  if (position === "S") return S;
  if (position === "TE") return TE;
  if (position === "WR") return WR;
  return QB;
}
