import { Modal, Typography } from 'antd';
import Input from 'components/Common/Form/Input/Input';
import { notify } from 'helper/helper';
import { InviteMemberForm } from './InviteMemberForm';
import BlackButton from "../../Common/Button/BlackButton/BlackButton";
import "./GroupInviteModal.style.scss";
import { useGroupHeader } from '../Common/GroupHeader/hooks/useGroupHeader';

interface GroupInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GroupInviteModal = ({ isOpen, onClose }: GroupInviteModalProps) => {
  const {groupName} = useGroupHeader();
  const currentUrl = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentUrl);
    notify('success','Link copied to clipboard')
  };

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null} className='group-invite__modal-wrapper' width="">
      <div>
        <Typography.Title level={3} style={{textTransform:'uppercase'}}>
          {`INVITE OTHER TO ${groupName}`}
        </Typography.Title>
      </div>
      <InviteMemberForm />
      <div>
        <Typography style={{marginBottom:'1rem'}}>
          SEND A SERVER INVITE LINK
        </Typography>
        <Input disabled placeholder={currentUrl} renderButton={()=><BlackButton text="COPY" onClick={copyToClipboard}/>}/>
      </div>
    </Modal>
  );
};
