import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../../assets/images/common/singleLogo.svg';
import './WithLoginNavbarStyle.scss';
import Logout from '../../Common/Logout/Logout';
import { Link } from 'react-router-dom';
import wallet from '../../../assets/images/common/wallet.svg';
import user from '../../../assets/images/common/user.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { RootState } from '../../../redux/store';
import history from './../../../routes/history';
import { getUserActiveGames } from './../../../redux/actions/GameEngineAction';
import TeamActiveGamesDialog from '../../TeamActiveGames/TeamActiveGamesDialog';
import { checkIsPromoUsed } from 'redux/actions/AppAction';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { Badge, Tooltip, notification } from 'antd';
import { getAvatarData } from 'redux/actions/TeamAction';
import ReportPopup from 'components/Common/ReportPopup/ReportPopup';
import { WarningOutlined } from '@ant-design/icons';
import { useInvitedGames } from 'helper/useInvitedGames';
import { useHandleScreenSize } from 'helper/useHandleScreenSize';

const WithLoginNavbar: React.FC = () => {
  const dispatch = useDispatch();
  const resData = useSelector((state?: RootState) => state?.app?.resData);
  const teamActiveGamesCounter = useRef(1);
  const [isReportProblemOpen, setIsReportProblemOpen] = useState(false);

  const { pageWidth } = useHandleScreenSize();

  useEffect(() => {
    if (resData?.id && teamActiveGamesCounter.current < 2) {
      dispatch(getUserActiveGames(resData?.id));
      teamActiveGamesCounter.current++;
    }
  }, [resData]);

  useEffect(() => {
    if (!resData?.id) return;
    dispatch(checkIsPromoUsed(resData.id));
  }, [resData?.id]);

  useEffect(() => {
    dispatch(getAvatarData());
  }, []);

  const { invitedGamesNumber } = useInvitedGames(resData?.id, true);

  useEffect(() => {
    if (pageWidth > 991 && !!resData) {
      notification.open({
        icon: null,
        message: (
          <Tooltip
            trigger={['hover']}
            color="#d1403c"
            title={
              <p style={{ textAlign: 'center' }}>
                Click <br /> to report <br /> a problem
              </p>
            }
          >
            <WarningOutlined />
          </Tooltip>
        ),
        key: 'reportProblem',
        onClick: () => {
          setIsReportProblemOpen(true);
        },
        duration: 10000000,
        placement: 'bottomRight',
        className: 'report-problem',
        closeIcon: null,
      });
    } else {
      notification.close('reportProblem');
    }
  }, [pageWidth, resData]);

  if (!resData) {
    return (
      <div className="withloginmain">
        <Navbar fixed="top" collapseOnSelect expand="lg">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="menubartoggle" />
          <Navbar.Collapse id="responsive-navbar-nav" className="leftmenu" />
          <Navbar.Brand className="logoheader">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav" className="rightmenu">
            <Nav />
            <div className="wallet">
              <PinkButton text="SIGN UP NOW!" onClick={() => history.push('/signup')} />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }

  return (
    <>
      <div className="withloginmain">
        <Navbar fixed="top" collapseOnSelect expand="lg">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="menubartoggle" />
          <Navbar.Collapse id="responsive-navbar-nav" className="leftmenu">
            <Nav>
              <Nav.Link as={Link} to="/ownfranchise">
                TEAMS
              </Nav.Link>
              <Badge className="navbar-badge" count={invitedGamesNumber || 0} style={{ top: '5px' }}>
                <Nav.Link as={Link} to="/league" className="games-link">
                  GAMES
                </Nav.Link>
              </Badge>
              <Nav.Link as={Link} to="#pricing" disabled>
                MINT
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/marketplace">
                MARKETPLACE
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand className="logoheader">
            <Nav.Link as={Link} to="/ownfranchise">
              <img src={logo} alt="logo" className="logo-header-nft" />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav" className="rightmenu">
            <Nav>
              <Nav.Link as={Link} to="/leaderboard">
                LEADERBOARD
              </Nav.Link>
              <Nav.Link href="https://gmdbe.azurewebsites.net/files/GMDynasty_WhitePaper.pdf">LEARN</Nav.Link>
              <Nav.Link as={Link} to="/lobby">
                LOBBY
              </Nav.Link>
            </Nav>
            <div className="wallet">
              <TeamActiveGamesDialog />
              <img src={wallet} alt="wallet" onClick={() => history.push('/setting?tab=wallets')} style={{ cursor: 'pointer' }} />
              <span className="wallet_amount">{resData?.walletBalance ? `$ ${resData?.walletBalance}` : '$ 0.00'}</span>
              <img src={user} alt="user" onClick={() => history.push('/setting?tab=profile')} style={{ cursor: 'pointer' }} />
              <Logout />
            </div>
          </Navbar.Collapse>
          {pageWidth < 992 && (
            <div className="report-problem-button">
              <WarningOutlined onClick={() => setIsReportProblemOpen(true)} />
            </div>
          )}
        </Navbar>
      </div>
      <ReportPopup isOpen={isReportProblemOpen} setIsOpen={setIsReportProblemOpen} />
    </>
  );
};

export default WithLoginNavbar;
