import LeagueDetail from "../Common/LeagueDetail/LeagueDetail";
import Stickers from "../../assets/images/league/leagueImage.jpg";

const DraftHeader = () =>{
    const data1 = [
        {
            id: 1,
            profileimage: Stickers,
            protfolioname: "FRANCHISE NAME",
            ownertitle: "OWNER :",
            ownername: "DAVE BURGER",
            currentleaguetitle: "CURRENT LEAGUE :",
            currentleague: "2",
            foundedtitle: "FOUNDED :",
            founded: "8/22/1990",
            foundplaytitle: "MINTED PLAYER :",
            foundplay: "3",
            citytitle: "CITY :",
            city: "ATLANTA",
            mintedplayertitle: "FUNDS-IN-PLAY :",
            mintedplayer: "$ 5.00",
            wallettitle: "TITLES",
            walletdigit: "1",
            fundstitle: "WINNINGS",
            fundsdigit: "$ 5.00",
            teamrating: "TEAM RATING",
            teamratingdigit: "67",
            fanbase: "WIN %",
            fanbasedigit: "45 %",
            winning: "FANE BASE",
            winningdigit: "88",
            rating: "RATING",
            ratingdigit: "61",
        },
    ]
    return(
        <>
            {data1.map((d: any, i: any) => (
                <LeagueDetail rating arrow data={d} />
            ))}
        </>
    );
}

export default DraftHeader;