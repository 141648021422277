import "./DraftResultScreenStyle.scss";
import DraftResultMain from "../../components/DraftResult/DraftResultMain";

const DraftResultScreen = () =>{
    return(
        <>
            <div className="pagelayout" >
                <DraftResultMain />
            </div>
        </>
    );
}

export default DraftResultScreen;