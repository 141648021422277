import { post } from "redux/services/Api";
import { InGameLeaderBoard, InGameTeamsStats } from "types";

export const setGameStatusToComplete = async (gid: number) => {
  await post(`/engine/games/h2h/${gid}/setStatusToComplete`);
};

export const getInGameLeaderboard = async (gid: string, timestamp: string) => {
  const res = await post<InGameLeaderBoard>(
    `/engine/games/${gid}/inGameLeaders`,
    { timestamp },
  );
  return res;
};

export const getInGameTeamsStats = async (gid: string, timestamp: string) => {
  const res = await post<InGameTeamsStats>(
    `/engine/games/${gid}/inGameTeamsStats`,
    { timestamp },
  );
  return res;
};
