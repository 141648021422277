import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gmdComputerTeams } from "redux/actions/TeamAction";
import { RootState } from "redux/store";
import { UserTeamsList } from "types";

export const useGMDComputerTeams = () => {
  const dispatch = useDispatch();
  const userTeams: UserTeamsList | null = useSelector((state: RootState) => state.team.userTeamsList);

  useEffect(() => {
    dispatch(gmdComputerTeams());
  }, []);

  return userTeams;
}