export const getFormationNameBySidAndType = (sid: number, type: 'offensive' | 'defensive') => {
  if (type === 'offensive') {
    switch (sid) {
      case 1:
        return '1 RB, 4 WR';
      case 2:
        return '2 RB, 2 WR, 1 TE';
      case 3:
        return '1 RB, 3 WR, 1 TE';
      case 4:
        return '1 RB, 2 WR, 2 TE';
      case 5:
        return '2 RB, 1 WR, 2 TE';
      default:
        return '';
    }
  }

  switch (sid) {
    case 1:
      return '4 DL, 3 LB, 2 S, 2 CB';
    case 2:
      return '3 DL, 4 LB, 2 S, 2 CB';
    case 3:
      return '4 DL, 2 LB, 2 S, 3 CB';
    case 4:
      return '3 DL, 2 LB, 2 S, 4 CB';
    default:
      return '';
  }
};
