import { Container } from 'react-bootstrap';
import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import MainContent from './MainContent';
import LoadingScreen from '../../components/Common/LoadingScreen/LoadingScreen';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import LeagueHeader, { League } from '../../components/Common/LeagueHeader/LeagueHeader';
import { useLocation } from 'react-router-dom';
import { useGameData } from 'helper/useGameData';
import { useEffect } from 'react';
import history from 'routes/history';

const EnteredLeague: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gid = queryParams.get('gid');
  const type = queryParams.get('type');

  const gameEngineStateLoader = useSelector((state: RootState) => state.gameEngine.loader);

  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);

  const { data } = useGameData(gid, type as League['type']);

  useEffect(() => {
    if (
      gid 
        && data 
        && (data?.gid === +gid || gid === data?.seasonId) 
        && data.type === type 
        && data?.teams?.length === (data?.numberOfTeams || data?.totalTeams || 2)
    ) {
      if (type === 'E') {
        history.push(`/leagueelimination?gid=${gid}`);
      } else if (type === 'S') {
        history.push(`/season/${gid}`);
      } else if (type === 'H') {
        if (data?.status === 'inProgress') {
          history.push(`/gameplay?id=${gid}`);
        }
        history.push('/leagueheadtohead', +gid);
      }
    }
  }, [data, gid, type]);

  const engId = userTeams?.find((t) => data?.teams?.includes(t.engId))?.engId;

  if (!gid || !type) {
    return null;
  }

  return (
    <>
      {!!gameEngineStateLoader && <LoadingScreen />}
      <Container>
        <div className="pagelayout">
          <WithLoginNavbar />
          <LeagueHeader league={data} franchise={{ engId }} />
          <MainContent gid={gid} type={type as League['type']} />
        </div>
      </Container>
    </>
  );
};

export default EnteredLeague;
