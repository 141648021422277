/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from "antd";

type RevealCompleteModalProps = {
  open: boolean;
  onCancel: () => void;
  handleRedirectToRoster: () => void;
  handleRedirectInvites: () => void;
};

// const RevealCompleteModal = ({ setCloseCounter }: any) => {
// const [counter, setCounter] = React.useState(10);
// useEffect(() => {
//   if (counter > 0) {
//     const timerId = setTimeout(() => setCounter(counter - 1), 1000);

//     return () => {
//       clearTimeout(timerId);
//     };
//   }
// }, [counter]);

//   useEffect(() => {
//     setCloseCounter(counter);
//   }, [counter]);

export function RevealCompleteModal({
  open,
  onCancel,
  handleRedirectToRoster,
  handleRedirectInvites,
}: RevealCompleteModalProps) {
  return (
    <Modal
      title=""
      className="playerreveal_complete"
      open={open}
      onCancel={onCancel}
      centered
    >
      <h5>PLAYER REVEAL COMPLETE</h5>
      <p>
        Congratulations, your team has been revealed. You will now be able to
        manage your roster, set depth charts and develop game strategies.​
      </p>
      {/* <div>
        <p>Redirecting in {counter} seconds</p>
      </div> */}
      <div className="playerreveal_complete__actions-row">
        <button
          type="button"
          className="blueButton"
          onClick={handleRedirectInvites}
        >
          INVITE FRIENDS
        </button>
        <button
          type="button"
          className="blueButton"
          onClick={handleRedirectToRoster}
        >
          VIEW ROSTER
        </button>
      </div>
    </Modal>
  );
}
