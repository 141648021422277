import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getTeamLeadersPlayed } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useTeamLeadersPlayed() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamLeadersPlayed());
  }, []);

  const teamLeadersPlayed: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.teamLeadersPlayed);

  return { teamLeadersPlayed };
}