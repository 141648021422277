import { Modal } from 'antd';
import { API_URL } from 'config';
import { SimpleUserTrophy } from 'redux/actions/TrophyAssets/types';
import { RenderTrophy } from './RenderTrophy';

type Props = {
  open: boolean;
  onCancel: () => void;
  trophy?: SimpleUserTrophy;
};

export const TrophyPreview: React.FC<Props> = ({ open, onCancel, trophy }) => (
  <Modal open={open} className="verification PreviewModal" footer={null} onCancel={onCancel}>
    <div className="trophy-preview-modal-content">
      <h3>Preview</h3>
      <RenderTrophy path={`${API_URL}/images/trophyAssets/userTrophies/${trophy?.imagePath}`}/>
    </div>
  </Modal>
);
