import { useServerSidePagination } from 'helper/useServerSidePagination';
import { useTeamNFTS } from '../hooks/useTeamNFTS';
import { API_URL } from 'config';
import './MarketplaceTeams.scss';
import { PaginationPages } from 'components/HistoricalResults/PaginationPages';
import history from 'routes/history';

export const MarketplaceTeams: React.FC = () => {
  const { handleNextPage, handlePrevPage, page, setPage } = useServerSidePagination();
  const { loading, nfts } = useTeamNFTS(page, 20);

  return (
    <>
      <PaginationPages
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        page={page}
        nextDisabled={loading || nfts.length === 0}
        onPageClick={(e, shift = 0) => setPage(+(e.target as HTMLElement).innerText + shift || 1)}
      />
      <div className="marketplace-list">
        {nfts.map((nft) => (
          <div key={nft.id} className="marketplace-list-team" onClick={() => history.push(`/team-nft-info/${nft.id}`)}>
            <img className="marketplace-list-team__image" src={`${API_URL}/images/franchise/userFranchise/${nft.img}`} alt={nft.teamName} />
            <p className="marketplace-list-team__name">{nft.teamName}</p>
          </div>
        ))}
      </div>
    </>
  );
};
