import { useInView } from 'react-intersection-observer';
import image1 from "../../assets/images/learnMore/image1.png";
import image2 from "../../assets/images/learnMore/image2.png";
import image3 from "../../assets/images/learnMore/image3.png";
import image4 from "../../assets/images/learnMore/image4.png";
import image5 from "../../assets/images/learnMore/image5.gif";
import image6 from "../../assets/images/learnMore/image6.png";
import image7 from "../../assets/images/learnMore/image7.png";
import { Children, useEffect, useState } from 'react';

const AnimatedImage: React.FC<{
  src: string;
  alt: string;
  children?: any
}> = ({
  src,
  alt,
  children
}) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
    });

    return (
      <div className="animatedImageContainer">
        <img
          ref={ref}
          src={inView ? src : ''}
          alt={alt}
          className={inView ? 'active' : ''}></img>
        {children}
      </div>
    );
  };

const AnimatedDecoration: React.FC<{
  long?: boolean,
  short?: boolean;
  tiny?: boolean;
  left: boolean;
  top: boolean;
  horizontal: boolean;
}> = ({
  long,
  short,
  tiny,
  left,
  top,
  horizontal,
}) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
    });

    const size = long ? "long " : short ? "short " : tiny ? "tiny " : "short ";
    const xLocation = left ? "left " : "right ";
    const yLocation = top ? "top " : "bottom ";
    const orientation = horizontal ? "horizontal " : "";
    const style = "learnMoreDecoration " + size + xLocation + yLocation + orientation;
    const [active, setActive] = useState('');

    useEffect(() => {
      setActive(inView ? 'active ' : '');
    }, [inView]);

    return (
      <div
        ref={ref}
        className={style + active}
      />
    );
  };


const LearnMoreContent = ({ setLoading }: any) => {
  return (
    <>
      <section className="banner-corner">
        <div className="learnMoreContentContainer">
          <div className="learnMoreContent">
            <h2>
              UNLEASH YOUR INNER GENERAL MANAGER!
              GM DYNASTY EMPOWERS YOU TO MAKE STRATEGIC DECISIONS,
              BUILD A POWERHOUSE TEAM, AND COMPETE IN A DYNAMIC
              LEAGUES YEAR-ROUND</h2>
            <p>
              Begin building your football dynasty by acquiring a franchise directly from this site.
              All franchises are customizable NFTs with one-of-a-kind performance characteristics. Select team name, team colors and logo.
            </p>

            <AnimatedImage src={image1} alt="news_img">
              <AnimatedDecoration long={true} left={true} top={true} horizontal={true} />
              <AnimatedDecoration tiny={true} left={true} top={true} horizontal={false} />
            </AnimatedImage>
            <p>
              Once you have your franchise, the real fun begins. See your roster being built as
              52 players are revealed, each one with unique performance traits.
            </p>
            <AnimatedImage src={image2} alt="news_img">
              <AnimatedDecoration long={true} left={false} top={true} horizontal={true} />
              <AnimatedDecoration tiny={true} left={false} top={true} horizontal={false} />
            </AnimatedImage>
            <p>
              With your team assembled you can begin participating in league play and compete against other franchises.
              You choose the league type, difficulty and entry fee.
            </p>
            <AnimatedImage src={image3} alt="news_img">
              <AnimatedDecoration tiny={true} left={true} top={true} horizontal={true} />
              <AnimatedDecoration long={true} left={true} top={true} horizontal={false} />
            </AnimatedImage>
            <p>
              Players alone, don’t win championships. You’ll need to prove your coaching prowess out on the field.
              Set depth charts, offensive formations and defensive schemes.
            </p>
            <AnimatedImage src={image4} alt="news_img">
              <AnimatedDecoration tiny={true} left={true} top={true} horizontal={true} />
              <AnimatedDecoration long={true} left={false} top={false} horizontal={true} />
              <AnimatedDecoration short={true} left={false} top={false} horizontal={false} />
            </AnimatedImage>
            <p>
              Game day! Watch the action unfold, as your strategies are put to test.
              Advanced simulation engine mimics real-life football with exacting accuracy
            </p>
            <AnimatedImage src={image5} alt="news_img">
              <AnimatedDecoration long={true} left={true} top={true} horizontal={true} />
              <AnimatedDecoration tiny={true} left={true} top={true} horizontal={false} />
            </AnimatedImage>
            <p>
              And when the game is over and your players are hitting the ice tub, it’s time to evaluate their performance.
              Player ratings are updated based on performance. Progression arcs mimic real-life.
            </p>
            <AnimatedImage src={image6} alt="news_img">
              <AnimatedDecoration short={true} left={true} top={false} horizontal={false} />
              <AnimatedDecoration tiny={true} left={true} top={false} horizontal={true} />
              <AnimatedDecoration tiny={true} left={false} top={true} horizontal={true} />
            </AnimatedImage>
            <p>
              Best of all there is no offseason in GM Dynasty. Your roster is flexible and you have multiple ways to make improvements via drafts or acquisitions.
              You can even sell your player NFTs.
            </p>
            <AnimatedImage src={image7} alt="news_img">
              <AnimatedDecoration long={true} left={true} top={true} horizontal={true} />
              <AnimatedDecoration tiny={true} left={false} top={false} horizontal={true} />
              <AnimatedDecoration short={true} left={true} top={true} horizontal={false} />
            </AnimatedImage>
            <p>
              Begin building your football dynasty by acquiring a franchise directly from this site. All franchises are customizable NFTs with one-of-a-kind performance characteristics.
              Select team name, team colors and logo.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default LearnMoreContent