import { ReactNode, useState } from 'react';

type Props = {
  text: string;
  children: ReactNode;
}

export const InviteTooltip: React.FC<Props> = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && <div className="tooltip-text">{text}</div>}
    </div>
  );
};
