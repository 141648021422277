import Error from "../../../../assets/images/common/error.svg";

const RosterMoveModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Roster move is not allowed. The taxi squad must be under 
                10 players to complete move. Export or release a 
                player to complete roster move.</p>
            </div> 
        </>
    );
}

export default RosterMoveModal; 