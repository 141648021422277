import { usePagination } from "helper/usePagination";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { GroupMemershipStatus } from "types/groups";

export function useGroupMembers() {

  const group = useSelector((state: RootState) => state.group.groupInfo);
  const userData = useSelector((state: RootState) => state.app.resData);

  const approvedMembers = group?.members?.filter(
    (member) => member.status === GroupMemershipStatus.APPROVED
  );
  const { paginated: approvedMembersPaginated, ...paginationOptions } = usePagination(approvedMembers || null, 6);

  const userInGroupData = group?.members?.find(
    (member) => member.user.id === userData?.id
  );

  const requestedMembers = group?.members?.filter(
    (member) => member.status === GroupMemershipStatus.REQUESTED
  );

  const invitedMembers = group?.members?.filter(
    (member) => member.status === GroupMemershipStatus.INVITED
  );

  return {
    group,
    approvedMembers,
    requestedMembers,
    invitedMembers,
    approvedMembersPaginated,
    paginationOptions,
    userInGroupData,
  };
}
