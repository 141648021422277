import { Col, Row } from "react-bootstrap";
import { useHandleRedirect } from "helper/useHandleRedirect";
import { getStringDate } from "helper/helper";
import { GroupAvatar } from "../GroupAvatar";
import { useGroupHeader } from "./hooks/useGroupHeader";
import "./GroupHeader.scss";
import { GroupHeaderCtasButtons } from "./HeaderCtasButtons";
import { useEffect, useState } from "react";
import { getGroupGamesPlayedNum, getGroupPrestigePoints } from "./helpers/GetGroupGamesPlayedNum";

export function GroupHeader() {
  const {
    groupName,
    groupAvatar,
    groupOwner,
    groupStartedAt,
    groupMembersLength,
    groupId,
    isMember,
    isAdmin,
    isOwner,
    membershipStatus,
    noOfTrophies
  } = useGroupHeader();

  const [gamesPlayed, setGamesPlayed] = useState<string | number>("N/A");
  const [prestigePoints, setPrestigePoints] = useState<string | number>("N/A");

  useEffect(() => {
    getGroupGamesPlayedNum(groupId).then(res => setGamesPlayed(res));
    getGroupPrestigePoints(groupId).then(res => setPrestigePoints(res));
  }, [groupId])

  const { handleRedirect } = useHandleRedirect();

  const handleRedirectToProfile = () => {
    handleRedirect(`/profile/${groupOwner!.id}`);
  };

  return (
    <div className="franchiseMain group-header">
      <Row className="align-items-end">
        <Col md={3} xl={2}>
          <div className="franchiseProfile">
            <GroupAvatar file={groupAvatar} />
          </div>
        </Col>
        <Col md xl lg>
          <p className="group-header__title">{groupName || "N/A"}</p>
          <div className="group-header__details">
            <Row className="align-items-center">
              <Col md={7}>
                <div className="franchiseDetail">
                  <Row>
                    <Col xl={6} md={6} className="franchiseDetailContent">
                      <span>Started By:</span>
                      {groupOwner ? (
                        <span>
                          <button onClick={handleRedirectToProfile}>
                            {groupOwner.userName}
                          </button>
                        </span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </Col>
                    <Col xl={6} md={6} className="franchiseDetailContent">
                      <span>NO. OF TROPHIES:</span>
                      <span>{noOfTrophies}</span>
                    </Col>
                    <Col xl={6} md={6} className="franchiseDetailContent">
                      <span>STARTED:</span>
                      {groupStartedAt ? (
                        <span>{getStringDate(new Date(groupStartedAt))}</span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </Col>
                    <Col xl={6} md={6} className="franchiseDetailContent">
                      <span>PRESTIGE VALUE:</span>
                      <span>{prestigePoints}</span>
                    </Col>
                    <Col xl={6} md={6} className="franchiseDetailContent">
                      <span>NO. OF MEMBERS:</span>
                      <span>{groupMembersLength}</span>
                    </Col>
                    <Col xl={6} md={6} className="franchiseDetailContent">
                      <span>GAMES PLAYED:</span>
                      <span>{gamesPlayed}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md>
                <GroupHeaderCtasButtons
                  isAdmin={!!isAdmin}
                  isMember={!!isMember}
                  isOwner={!!isOwner}
                  membershipStatus={membershipStatus}
                  groupId={groupId}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
