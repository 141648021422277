import { Select } from "antd";
import { Stack } from "react-bootstrap";
import { PlayerCreation } from "../PlayerCreation/PlayerCreation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useMemo } from "react";
import { getUserTeams } from "redux/actions/TeamAction";
import { getTeamRoster } from "redux/actions/GameEngineAction";
import { Roster } from "components/MintRoster/interface";
import { UserTeamsList } from "types";

type RosterImageBuilderProps = {
  team: UserTeamsList[0] | null;
  roster: Roster | null;
  setTeam: (value: UserTeamsList[0] | null) => void;
  setRoster: (value: Roster | null) => void;
  setFrontImage: (value: string | undefined) => void;
  setRosterDescription: (value: string) => void;
};

const RosterImageBuilder: React.FC<RosterImageBuilderProps> = ({ team, roster, setTeam, setRoster, setFrontImage, setRosterDescription }) => {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootState) => state.app.resData?.id);
  const teamsData = useSelector(
    (state: RootState) => state.team.requestedUserTeams
  );
  const rosterData = useSelector((state: RootState) => state.gameEngine.teamRoster);

  const teams = useMemo(() => teamsData?.map((team) => ({
    label: `${team.teamName} (ID:${team.engId})`,
    value: team.engId,
  })),
    [teamsData]
  );

  const rosters = useMemo(() => rosterData?.roster?.map((roster: any) => ({
    label: `${roster.pos} ${roster.firstName} ${roster.lastName} (ID:${roster.pid})`,
    value: roster.pid,
  })),
    [rosterData]
  );

  const handleTeamFocus = () => {
    if (userId) {
      dispatch(getUserTeams(userId));
    }
  };

  const handleRosterFocus = () => {
    if (team?.engId) {
      dispatch(getTeamRoster(team.engId));
    }
  };

  const handleTeamChange = (value: number) => {
    const selectedTeam = teamsData?.find((t) => t.engId === value);
    if (selectedTeam) {
      setTeam(selectedTeam);
      setRoster(null);
    }
  };

  const handleRosterChange = (value: number) => {
    setRoster(rosterData?.roster?.find((r: any) => r.pid === value));
    setRosterDescription('');
  };

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Select
          placeholder="Select Team"
          options={teams}
          value={team?.engId}
          onChange={handleTeamChange}
          onFocus={handleTeamFocus}
        />
        <Select
          placeholder="Select Roster"
          options={rosters}
          value={roster?.pid}
          onChange={handleRosterChange}
          disabled={!team}
          onFocus={handleRosterFocus}
        />
      </Stack>
      {team && roster ? (
        <PlayerCreation team={team} roster={roster} setFrontImage={setFrontImage} />
      ) : null}
    </Stack>
  );
};

export default RosterImageBuilder;
