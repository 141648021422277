import { FormationItem } from "./FormationItem";
import { FormationPlaceholder } from "./FormationPlaceholder";
import { useSelectedDepthData } from "./hooks/useSelectedDepthData";

export const F2RB2WR1TE: React.FC = () => {
  const formationData = useSelectedDepthData('2 RB, 2 WR, 1 TE');

  if (
    !formationData 
      || !formationData.WR
      || !formationData.RB
      || !formationData.TE
      || !formationData.OL
      || !formationData.QB) {
    return <div/>;
  }

  return (
    <div className="formation-container">
      <div className="formation-row">
        <FormationItem 
          player={formationData.WR[0]}
          color="grey"
        />
        <FormationPlaceholder/>
        <FormationItem 
          player={formationData.OL[2]}
          color="black"
          position="OT"
        />
        <FormationItem
          player={formationData.OL[0]}
          position="OG"
          color="black"
        />
        <FormationItem
          player={formationData.OL[4]}
          position="C"
          color="black"
        />
        <FormationItem
          player={formationData.OL[1]}
          position="OG"
          color="black"
        />
        <FormationItem 
          player={formationData.OL[3]}
          position="OT"
          color="black"
        />
        <FormationItem 
          player={formationData.TE[0]}
          color="grey"
        />
        <FormationItem 
          player={formationData.WR[1]}
          color="grey"
        />
      </div>
      <div className="formation-row">
        <FormationItem 
          player={formationData.QB[0]}
          color="white"
        />
      </div>
      <div className="formation-row">
        <FormationItem 
          player={formationData.RB[0]}
          color="grey"
          position="HB"
        />
      </div>
      <div className="formation-row">
        <FormationPlaceholder />
        <FormationItem 
          player={formationData.RB[1]}
          color="grey"
          position="FB"
        />
        <FormationPlaceholder />
        <FormationPlaceholder />
      </div>
    </div>
  );
};
