import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import Team1Passing from "./Team1Passing";
import Team1Rushing from "./Team1Rushing";
import Team1Receiving from "./Team1Receiving";
import Team1Defense from "./Team1Defense";
import Team1Returns from "./Team1Returns";
import Team1Kicking from "./Team1Kicking";
import Team1Punting from "./Team1Punting";
import { useEffect, useState } from "react";
export interface Props {
	team?: any;
}

const Team1Main = ({ team }: Props) => {
	const [passingData, setPassingData] = useState();
	const [rushingData, setRushingData] = useState();
	const [receivingData, setReceivingData] = useState();
	const [defenseData, setDefenseData] = useState();
	const [returnsData, setReturnsData] = useState();
	const [kickingData, setKickingData] = useState();
	const [puntingData, setPuntingData] = useState();
	useEffect(() => {
		setPassingData(team?.player?.filter((info: any) => info.stat.pss > 0));
		setRushingData(team?.player?.filter((info: any) => info.stat.rus > 0));
		setReceivingData(
			team?.player?.filter((info: any) => info.stat.rec > 0)
		);
		setDefenseData(
			team?.player?.filter(
				(info: any) =>
					info.stat.defTckSolo > 0 ||
					info.stat.defTckAst > 0 ||
					info.stat.defTckLoss > 0 ||
					info.stat.defPssDef > 0 ||
					info.stat.defInt > 0 ||
					info.stat.defFmbRec > 0
			)
		);
		setReturnsData(
			team?.player?.filter(
				(info: any) => info.stat.kr > 0 || info.stat.pr > 0
			)
		);
		setKickingData(
			team?.player?.filter(
				(info: any) => info.stat.fga50 > 0 || info.stat.xpa > 0
			)
		);
		setPuntingData(team?.player?.filter((info: any) => info.stat.pnt > 0));
	}, [team]);

	return (
		<>
			<div className="team1main">
				<Title text={`${team?.teamName ? team?.teamName : "---"}`} />
				{passingData && <Team1Passing data={passingData} team={team} />}
				{rushingData && <Team1Rushing data={rushingData} team={team} />}
				{receivingData && (
					<Team1Receiving data={receivingData} team={team} />
				)}
				{defenseData && <Team1Defense data={defenseData} team={team} />}
				{returnsData && <Team1Returns data={returnsData} team={team} />}
				{kickingData && <Team1Kicking data={kickingData} team={team} />}
				{puntingData && <Team1Punting data={puntingData} team={team} />}
			</div>
		</>
	);
};

export default Team1Main;
