import { EditOutlined } from "@ant-design/icons";
import { Row, Col, Popover } from "antd";
import { UnselectedPopover } from "components/PurchaseFranchise/Common/UnselectedPopover";
import { getColorFromBgName } from "components/PurchaseTrophy/helper";
import { API_URL } from "config";
import { transparentBase64 } from "helper/helper";
import { ReactNode } from "react";

type Props = {
  currentSelectedId: number | undefined;
  currentSelectedValue: string | number | undefined;
  currentSelectedImgUrl: string | undefined;
  handleSelection: (value: any) => void;

  selectorName: string;

  isUnselectedErrorShown: boolean;
  hideUnselectedError: () => void;
  unselectedText: string;

  SelectorComponent?: ReactNode;
  toggleSelector: () => void;

  showValue?: boolean;
  isColor?: boolean;
  imageBG?: string;
};

export const PropertySelector: React.FC<Props> = ({
  currentSelectedValue,
  currentSelectedImgUrl,
  isUnselectedErrorShown,
  hideUnselectedError,
  unselectedText,
  SelectorComponent,
  toggleSelector,
  selectorName,
  showValue,
  isColor,
  imageBG,
}) => {
  return (
    <Row className="align-items-center justify-content-between logo-select choosehead">
      <Col lg={16} sm={16} xs={12} >
        <h6 className="colorhead d-block w-100">
          <strong>{selectorName}</strong>
          <br />
          {currentSelectedValue
            ? currentSelectedValue.toString().toUpperCase()
            : "NOT SELECTED"}
        </h6>
      </Col>
      <Col lg={4} sm={4} xs={12}>
        <div className="color-choose">
          <div className="logo-box square">
            {(isColor && currentSelectedImgUrl) 
            ? (
              <div style={{ backgroundColor: `#${getColorFromBgName(currentSelectedImgUrl)}`}} className="color-choose-colorview"/>
            )
            : (showValue && currentSelectedValue !== undefined)
            ? (
              <div>{currentSelectedValue ? currentSelectedValue.toString().split(' ')[0] : ""}</div>
            )
            : (
              currentSelectedImgUrl ? (
                  <img
                  src={currentSelectedImgUrl || transparentBase64}
                  alt={currentSelectedValue?.toString() || ""}
                  style={{ backgroundColor: imageBG }}
                />
              ) : (
                <div/>
              )
            )}
            
          </div>
          <UnselectedPopover
            open={isUnselectedErrorShown}
            content={unselectedText}
            handleClose={hideUnselectedError}
          >
            {SelectorComponent ? (
              <Popover
                content={SelectorComponent}
                trigger={["click"]}
                placement="right"
              >
                <EditOutlined className="iconpicker" onClick={toggleSelector} />
              </Popover>
            ) : (
              <EditOutlined className="iconpicker" onClick={toggleSelector} />
            )}
          </UnselectedPopover>
        </div>
      </Col>
    </Row>
  );
};
