import { memo, useMemo, useState, useEffect } from "react";
import { Col } from "antd";
import helmetBlue from "../../assets/images/helmet/helmetBlue.png";
import helmetRed from "../../assets/images/helmet/helmetRed.png";
import { API_URL } from "../../config";
import { useTeamsLogos } from "../BoxScore/hooks/useTeamsLogos";
import Scoreboard from "components/Common/Scoreboard/Scoreboard";
import TeamHelmet from "components/Common/TeamHelmet/TeamHelmet";

type ScoreContainerProps = {
  timer?: string;
  isBallInLeft?: number;
  tolLeft?: number;
  tolRight?: number;
  down?: number;
  toGo?: number;
  ballOn?: number;
  quarterCount?: number;
  scoreSummary: any;
  liveSummary: any;
  isAnimationPlaying: boolean;
}

const ScoreContainer: React.FC<ScoreContainerProps> = memo(({
  scoreSummary,
  liveSummary,
  timer,
  isBallInLeft,
  tolLeft,
  tolRight,
  down,
  toGo,
  ballOn,
  quarterCount,
  isAnimationPlaying,
}) => {
  const { helmets } = useTeamsLogos(
    scoreSummary ? scoreSummary.teams : undefined
  );
  const helmetsData = useMemo(() => {
    return {
      leftHelmet: helmets.leftHelmet ?? helmetBlue,
      leftHelmetLogo: `${API_URL}${helmets.leftHelmetLogo}`,
      rightHelmetLogo: `${API_URL}${helmets.rightHelmetLogo}`,
      leftFranchiseName: scoreSummary?.teams?.[0]?.name ?? "FRANCHISE 1",
      rightFranchiseName: scoreSummary?.teams?.[1]?.name ?? "FRANCHISE 2",
      rightHelmet: helmets.rightHelmet ?? helmetRed,
      leftPlayerUtid: scoreSummary?.teams?.[0]?.utid,
      rightPlayerUtid: scoreSummary?.teams?.[1]?.utid,
    }
  }, [helmets, scoreSummary]);

  const [displayedScores, setDisplayedScores] = useState({
    left: liveSummary?.[0]?.[0] ?? 0,
    right: liveSummary?.[1]?.[0] ?? 0
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isAnimationPlaying) {
      timeout = setTimeout(() => {
        setDisplayedScores({
          left: liveSummary?.[0]?.[0] ?? 0,
          right: liveSummary?.[1]?.[0] ?? 0
        });
      }, 4000);
    }

    return () => clearTimeout(timeout);
  }, [isAnimationPlaying, liveSummary]);

  return (
    <>
      <div className="gameplay__helmets-wrapper">
        <Col>
          <TeamHelmet
            playerUtid={helmetsData.leftPlayerUtid}
            baseSrc={helmetsData.leftHelmet}
            logoSrc={helmetsData.leftHelmetLogo}
            franchiseName={helmetsData.leftFranchiseName}
            direction="left"
          />
        </Col>
        <Col>
          <TeamHelmet
            playerUtid={helmetsData.rightPlayerUtid}
            baseSrc={helmetsData.rightHelmet}
            logoSrc={helmetsData.rightHelmetLogo}
            franchiseName={helmetsData.rightFranchiseName}
            direction="right"
          />
        </Col>
      </div>
      <div className="gameplay__score-wrapper">
        <Scoreboard
          quarterCount={quarterCount}
          timer={timer}
          scoreLeft={displayedScores.left}
          scoreRight={displayedScores.right}
          isBallInLeft={isBallInLeft}
          tolLeft={tolLeft}
          tolRight={tolRight}
          down={quarterCount ? down : undefined}
          toGo={quarterCount ? toGo : undefined}
          ballOn={quarterCount ? ballOn : undefined}
        />
      </div>
    </>
  );
});

export default ScoreContainer;
