import { Action } from "redux";
import { FriendsList, UpdateNetworkStatus } from "types/friendships";

export enum FriendsActionTypes {
  GET_FRIENDS_REQUEST = "GET_FRIENDS_REQUEST",
  GET_FRIENDS_SUCCESS = "GET_FRIENDS_SUCCESS",
  GET_FRIENDS_FAIL = "GET_FRIENDS_FAIL",
  FRIEND_INVITE_REQUEST = "FRIEND_INVITE_REQUEST",
  FRIEND_INVITE_SUCCESS = "FRIEND_INVITE_SUCCESS",
  FRIEND_INVITE_FAIL = "FRIEND_INVITE_FAIL",
  UPDATE_FRIENDS = "UPDATE_FRIENDS",
  UPDATE_FRIEND_INVITE_REQUEST = "UPDATE_FRIEND_INVITE_REQUEST",
  UPDATE_FRIEND_INVITE_FAIL = "UPDATE_FRIEND_INVITE_FAIL",
  UPDATE_FRIEND_INVITE_SUCCESS = "UPDATE_FRIEND_INVITE_SUCCESS",
  UPDATE_FRIEND_NETWORK_STATUS = "UPDATE_FRIEND_NETWORK_STATUS",
}

export interface GetFriendsRequestAction extends Action {
  type: FriendsActionTypes.GET_FRIENDS_REQUEST;
}

export interface GetFriendsSuccessAction extends Action {
  type: FriendsActionTypes.GET_FRIENDS_SUCCESS;
  payload: FriendsList;
}

export interface GetFriendsFailAction extends Action {
  type: FriendsActionTypes.GET_FRIENDS_FAIL;
  error: string;
}

export interface UpdateFriendsAction extends Action {
  type: FriendsActionTypes.UPDATE_FRIENDS;
  payload: FriendsList;
}

export interface UpdateFriendNetworkStatusAction extends Action {
  type: FriendsActionTypes.UPDATE_FRIEND_NETWORK_STATUS;
  payload: UpdateNetworkStatus;
}
export interface SendFriendsInviteRequestAction extends Action {
  type: FriendsActionTypes.FRIEND_INVITE_REQUEST;
}

export interface SendFriendsInviteSuccessAction extends Action {
  type: FriendsActionTypes.FRIEND_INVITE_SUCCESS;
}

export interface SendFriendsInviteFailAction extends Action {
  type: FriendsActionTypes.FRIEND_INVITE_FAIL;
  error: string;
}

export interface UpdateFriendsInviteRequestAction extends Action {
  type: FriendsActionTypes.UPDATE_FRIEND_INVITE_REQUEST;
}

export interface UpdateFriendsInviteFailAction extends Action {
  type: FriendsActionTypes.UPDATE_FRIEND_INVITE_FAIL;
}

export interface UpdateFriendInviteSuccessAction extends Action {
  type: FriendsActionTypes.UPDATE_FRIEND_INVITE_SUCCESS;
}

export type FriendsActions =
  | GetFriendsRequestAction
  | GetFriendsSuccessAction
  | GetFriendsFailAction
  | UpdateFriendsAction
  | UpdateFriendNetworkStatusAction
  | SendFriendsInviteRequestAction
  | SendFriendsInviteFailAction
  | UpdateFriendsInviteRequestAction
  | UpdateFriendsInviteFailAction
  | UpdateFriendInviteSuccessAction;
