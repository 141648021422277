import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EliminationActions } from 'redux/actions/Elimination';
import { RootState } from 'redux/store';
import { useServerSidePagination } from 'helper/useServerSidePagination';

export const useEliminationTeams = (gid: number, limit = 6) => {
  const dispatch = useDispatch();
  const eliminationTeams = useSelector((state: RootState) => state.gameEngine.leagueTeams);
  const { handleNextPage, handlePrevPage, page } = useServerSidePagination();

  useEffect(() => {
    dispatch(EliminationActions.getEliminationTeams(gid, page, limit));
  }, [gid, page, limit]);

  return { eliminationTeams, handleNextPage, handlePrevPage, page, hasPrevPage: page > 1};
};
