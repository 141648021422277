import "./HomeScreenStyle.scss";
import HomeMain from "../../components/Home/HomeMain";

const HomeScreen: React.FC = () => {
    return (
        <>
            <div className="pagelayout" >
                <HomeMain />
            </div>
        </>
    );
}

export default HomeScreen;