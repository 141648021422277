import logo from "../../assets/images/aboutus/leatherlogo.png";

const AboutusFooter: React.FC = () => (
    <div className="aboutus_header aboutus_footer" >
        <p>And make sure you check out the Leather Bucket Heads website. They are a  rambunctious, rousing and rumbling group of 
        randomly generated NFTS. It is art born of cuts and bruises that show the harsh reality of the game.  
        When you own a Leather Bucket Head it also serves as your entry to the exclusive locker room. </p>
        <img src={logo} alt="latherlogo" />
    </div>
);

export default AboutusFooter;