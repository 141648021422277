import { Col, Row } from "react-bootstrap";
import Stickers from "../../../assets/images/player/QB.png";
import { getPositionFullName } from "helper/helper";
import { SpecialFeature } from "components/PlayerDetail/components/physical-trait/SpecialFeature";
import { SpecialFeatureCode } from "types";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

type Props = {
  playerData: {
    height: number;
    weight: number;
    dob: number;
    college: number;
    strength: number;
    speed: number;
    explosiveness: number;
    agility: number;
    run: number;
    pass: number;
    block: number;
    ovr: number;
    firstName: string;
    lastName: string;
    pos: string;
    drafted: number;
    specialFeature?: SpecialFeatureCode;
    gamesPlayed: number;
    rankings: {
      currentRanking: number;
      lowestRanking: number;
      highestRanking: number;
    };
    ratingHistory?: {
      rating: number;
      timestamp: string;
    }[];
  };
};

const PlayerHeader: React.FC<Props> = ({ playerData }) => {
  const arrow =
    playerData?.ratingHistory && playerData?.ratingHistory?.length > 1
      ? playerData.ratingHistory[playerData.ratingHistory.length - 1].rating >
        playerData.ratingHistory[playerData.ratingHistory.length - 2].rating
        ? "up"
        : playerData.ratingHistory[playerData.ratingHistory.length - 1]
            .rating ===
          playerData.ratingHistory[playerData.ratingHistory.length - 2].rating
        ? null
        : "down"
      : null;

  const shouldPhysicalTraitsBeShown = !["GM", "AC", "AG", "HC"].includes(
    playerData?.pos
  );

  return (
    <div className="leagueMain">
      <Row>
        <Col xl={2}>
          <div className="franchiseProfile leagueProfile">
            <img
              src={
                require(`../../../assets/images/player/${
                  playerData.pos || "QB"
                }.png`) || Stickers
              }
              alt="profile"
              width="90px"
            />
          </div>
        </Col>
        <Col xl={4}>
          <div className="leagueDetail">
            <h5>{`${playerData?.firstName || ""} ${
              playerData?.lastName || ""
            }`}</h5>
            <Row>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>POSITION : </span>
                <span>
                  {getPositionFullName(playerData?.pos).toUpperCase()}
                </span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                {shouldPhysicalTraitsBeShown && (
                  <>
                    <span>STRENGTH : </span>
                    <span>{playerData?.strength || 0}</span>
                  </>
                )}
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>HT/WT : </span>
                <span>{`${Math.floor(+playerData?.height / 12) || 0}'${
                  +playerData.height % 12
                }'', ${playerData?.weight || 0} LBS`}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                {shouldPhysicalTraitsBeShown && (
                  <>
                    <span>SPEED : </span>
                    <span>{playerData?.speed || 0}</span>
                  </>
                )}
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>COLLEGE : </span>
                <span>{playerData?.college || 0}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                {shouldPhysicalTraitsBeShown && (
                  <>
                    <span>EXPLOSIVENESS : </span>
                    <span>{playerData?.explosiveness || 0}</span>
                  </>
                )}
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>DRAFTED : </span>
                <span>{playerData?.drafted || 0}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                {shouldPhysicalTraitsBeShown && (
                  <>
                    <span>AGILITY : </span>
                    <span>{playerData?.agility || 0}</span>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={1}>
          <div className="franchiseHeaderFeatureContainer">
            {playerData?.specialFeature && (
              <SpecialFeature specialFeature={playerData?.specialFeature} />
            )}
          </div>
        </Col>
        <Col xl={2} />
        <Col xl={3}>
          <div className="franchiseBoxSection">
            <div className="franchiseBoxBack">
              <p>GAMES</p>
              <h5>{playerData?.gamesPlayed || 0}</h5>
            </div>
            <div className="franchiseBoxBack">
              <p>POS RANK</p>
              <h5>{playerData?.rankings?.currentRanking || 0}</h5>
            </div>
            <div className="leaguerating">
              <div className="leagueBoxBack">
                <p>RATING</p>
                <div className="rating">
                  <h5>{playerData?.ovr || 0}</h5>
                  {arrow &&
                    (arrow === "up" ? (
                      <ArrowUpOutlined style={{ color: "white" }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: "white" }} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PlayerHeader;
