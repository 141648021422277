import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNews } from 'redux/actions/News/actions/getNews';
import { RootState } from 'redux/store';

export const useNews = (page = 1, limit = 3) => {
  const dispatch = useDispatch();
  const news = useSelector((state: RootState) => state.app.news);

  useEffect(() => {
    dispatch(getNews(page, limit));
  }, [page, limit]);

  return news;
};
