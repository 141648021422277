/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LeagueHeaderDetailStyle.scss";
import { Row, Col } from "react-bootstrap";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useFeeRates } from "helper/useFeeRates";
import { getPrizePool } from "helper/helper";

export interface Props {
  data?: any;
  arrow?: "up" | "down";
  rating?: any;
  teamrating?: any;
  profile?: any;
}

const FranchiseHeaderDetail: React.FC<Props> = ({ data, arrow }) => {
  const { WALLET_TRANSACTION_FEE } = useFeeRates();
  const payoutPct =
    data?.payoutPct ||
    (WALLET_TRANSACTION_FEE !== undefined ? 100 - WALLET_TRANSACTION_FEE : 0);

  const numberOfTeams = data?.numberOfTeams || 2;
  const prizePool = getPrizePool(
    typeof data?.fee === "string" ? +data.fee.split(" ")[1] : 0,
    +payoutPct,
    numberOfTeams
  );

  return (
    <div className="leagueMain">
      <Row>
        <Col xl={2}>
          <div className="franchiseProfile leagueProfile">
            <div className="imageContainer">
              <img src={data?.profileimage} alt="profile" width="90px" />
            </div>
          </div>
        </Col>
        <Col xl={5}>
          <div className="leagueDetail">
            <h5>{data?.leagueName}</h5>
            <Row>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>FRANCHISE : </span>
                <span>{data?.franchiseName}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>RATING : </span>
                <span>{`${data?.ratingRange}`}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>TYPE : </span>
                <span>{data?.type}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>ENTRY FEE : </span>
                <span>{data?.fee}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>
                  {data?.typeLetter === "H" ? "GAME ID" : "LEAGUE ID"} :{" "}
                </span>
                <span>{data?.leagueId}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent" key={prizePool}>
                <span>PRIZE POOL : </span>
                <span>{`$ ${Number.isNaN(prizePool) ? 0 : prizePool}`}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>STARTED : </span>
                <span>{data?.started}</span>
              </Col>
              <Col xl={6} lg={6} className="franchiseDetailContent">
                <span>GAME TIME : </span>
                <span>{data?.gameTime}</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={5}>
          <div className="franchiseBoxSection">
            <div className="franchiseBoxBack">
              <p>RECORD</p>
              <h5>{data?.record}</h5>
            </div>
            <div className="franchiseBoxBack">
              <p>WIN %</p>
              <h5>{data?.teamWinningPercentage}</h5>
            </div>
            <div className="franchiseBoxBack">
              <p>FAN BASE</p>
              <h5>{data?.teamFanBase}</h5>
            </div>
            <div className="franchiseRating">
              <div className="franchiseRatingBoxBack">
                <p>RATING</p>
                <div className="rating">
                  <h5>{data?.teamRating}</h5>
                  {arrow &&
                    (arrow === "up" ? (
                      <ArrowUpOutlined style={{ color: "white" }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: "white" }} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FranchiseHeaderDetail;
