import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import SettingMain from "../../components/Setting/SettingMain";

const SettingScreen: React.FC = () => (
    <div className="pagelayout" >
        <WithLoginNavbar />
        <SettingMain />
    </div>
);

export default SettingScreen;