import Title from "../Title/Title";
import { Table } from "antd";
import "./LeagueOverviewStyle.scss";
import { PowerRanking } from "components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types";

const columns = [
  {
    title: "TEAM",
    dataIndex: "team",
  },
  {
    title: "RATINGS",
    dataIndex: "rankings",
  },
  {
    title: "PREV",
    dataIndex: "prev",
  },
  {
    title: "CHANGE",
    dataIndex: "change",
  },
];

type Props = {
  powerRanking?: PowerRanking[] | null;
}

const OverviewLeaguePowerrank: React.FC<Props> = ({ powerRanking }) => (
  <div>
    <Title text="power rankings" />
    <div className="rankStandTable rankingTable">
      {(!!powerRanking?.length) 
      ? (
        <Table
          columns={columns}
          pagination={false}
          dataSource={powerRanking}
          size="small"
        />
      )
      : (
        <span>Sorry, there are no data yet</span>
      )}
    </div>
  </div>
);

export default OverviewLeaguePowerrank;
