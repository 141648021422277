import { useEffect, useState } from 'react';
import { get } from 'redux/services/Api';
import { PlayoffInfo } from '../types';
import { getFullHelmets } from 'helper/helper';

export const usePlayoffInfo = (seasonId: string) => {
  const [playoffInfo, setPlayoffInfo] = useState<PlayoffInfo | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const data = await get<PlayoffInfo>(`/engine/leagues/seasons/season/${seasonId}/playoffInfo`);

        for (let i = 0; i < data.playoffs.length; i++) {
          const playoff = data.playoffs[i];

          if ('teams' in playoff && playoff?.teams && playoff?.teams?.length) {
            const helmetsData = await getFullHelmets(playoff.teams);

            if (helmetsData) {
              playoff.helmets = helmetsData;
            }
          }
        }

        if (!(data?.superBowl instanceof Date) && data?.superBowl && data?.superBowl?.teams && data?.superBowl?.teams?.length) {
          data.superBowl.helmets = await getFullHelmets(data.superBowl.teams);
        }

        setPlayoffInfo(data);
      } catch (e) {
        console.log("Error loading playoff info, ", e)
      }
    })();
  }, [seasonId]);

  return playoffInfo;
};
