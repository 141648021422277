import "./CustomizeFranchiseScreenStyle.scss";
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import CustomizeFranchiseMain from "./../../components/CustomizeFranchise/CustomizeFranchiseMain";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingScreen from "./../../components/Common/LoadingScreen/LoadingScreen";

const CustomizeFranchiseScreen = () => {
  const loadingApp = useSelector((state: RootState) => state.app.loader);
  const loadingTeam = useSelector((state: RootState) => state.team.loader);
  return (
    <>
      {!!loadingTeam && <LoadingScreen />}
      {!!loadingApp && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <CustomizeFranchiseMain />
      </div>
    </>
  );
};

export default CustomizeFranchiseScreen;
