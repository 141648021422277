import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import NewsMain from "../../components/News/NewsMain";

const NewsScreen: React.FC = () => (
  <div className="pagelayout">
    <WithLoginNavbar />
    <NewsMain />
  </div>
);

export default NewsScreen;
