import CircleIcon from "./CircleIcon";
import "./CircleChevronIconStyle.scss";
import CircleChevronSvg from "../../../../assets/images/common/chevron-double-down.svg"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  alt?: string;
}

const CircleChevronIcon: React.FC<Props> = (props) => (
  <CircleIcon {...props} icon={CircleChevronSvg} 
  className="circleChevronIcon"
   />
);

export default CircleChevronIcon;