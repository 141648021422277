import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { GroupInvitationsItem } from "./GroupInvitationsItem";
import { useHandleGroupParticipation } from "../Common/GroupHeader/hooks/useHandleGroupParticipation";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useGroupInvitations } from "components/Profile/hooks/groups/useGroupInvitations";

export function GroupInvitationsContent() {
  const history = useHistory();
  const { handleAcceptInviteGroup, handleRejectInviteGroup } = useHandleGroupParticipation();
  const user = useSelector((state: RootState) => state.app.resData);
  const { groupInvitations } = useGroupInvitations(user?.id);

  const handleRouteToProfilePage = () => {
    history.push(`/profile/${user?.id}`);
  };

  if (!user) return null;

  return (
    <Card>
      <CardBody>
        <div style={{ marginBottom: "1rem" }}>
          <Row>
            <Col md={3}>
              <BlueButton
                onClick={handleRouteToProfilePage}
                text={"Back to profile"}
                icon={<ArrowLeftOutlined />}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <h3>INVITATIONS TO GROUPS</h3>
          </Col>
        </Row>
        <Row>
          {!!groupInvitations?.length ? (
            groupInvitations.map((group) => (
              <GroupInvitationsItem
                key={group.id}
                group={group}
                userId={user.id}
                handleRejectInvite={handleRejectInviteGroup}
                handleAcceptInvite={handleAcceptInviteGroup}
              />
            ))
          ) : (
            <Col className="g-4">
              <h5>You don't have any group invitations</h5>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
