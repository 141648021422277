import "./ScheduleTabStyle.scss";
import {
  EliminationOverview,
  EliminationTreeValue,
  HelmetData,
} from "types";
import { getFilledTree } from "helper/helper";
import history from "routes/history";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { RenderHelmet } from "components/Common/RenderHelmet/RenderHelmet";
import { bottomValuesByRound } from "./helper/BottomValuesForStatus";
import { Fragment } from "react";

type Props = {
  tree: EliminationTreeValue[][];
  teamNames: EliminationOverview["teamNames"];
  winnerText: string;
  cupImage: string | React.ReactElement;
  helmets?: HelmetData[];
  schedule?: Date[]
};

const ScheduleDiagram: React.FC<Props> = ({
  tree,
  teamNames,
  winnerText,
  cupImage,
  helmets,
  schedule
}) => {
  const userTeams = useSelector((state: RootState) => state.team.userTeamsList);
  const preparedTree = getFilledTree(tree, schedule);
  const winnerId = preparedTree.at(-1)?.at(-1)?.winner;
  const winnerName = winnerId ? teamNames[winnerId] : "";

  const handleRedirectToGame = (inTreeGame: EliminationTreeValue) => {
    const isUsersTeamPlayGame = inTreeGame.teams.some(t => userTeams?.some(ut => ut.engId === t));
    const isGameActive = !!inTreeGame?.scores;

    if (isUsersTeamPlayGame && !isGameActive) {
      history.push("/leagueheadtohead", inTreeGame.gid);
      return;
    }

    if (!!inTreeGame.winner) {
      history.push(`/stand`, { gid: inTreeGame.gid });
      return;
    }

    history.push(`/gameplay?id=${inTreeGame.gid}`, inTreeGame.gid);
  };

  return (
    <div className="organizational-chart">
      {preparedTree.map((column, round) => (
          <ul key={round}>
            {column.map((element, gameNum) => {
              const isRediractable = typeof element.gid === "number";

              const onClick = () => {
                if (isRediractable) {
                  handleRedirectToGame(element);
                }
              };

              let gameStatus = "PREGAME";
              
              if (element?.winner) {
                gameStatus = "SCORE DETAIL";
              } else if (element?.scores !== undefined) {
                gameStatus = "IN PROGRESS";
              } else if (element?.startAt) {
                gameStatus = new Date(element.startAt).toLocaleString();
              }

              const teamHelmets = [
                helmets?.find(
                  (helmet) => helmet.engId === element.teams[0]
                ),
                helmets?.find(
                  (helmet) => helmet.engId === element.teams[1]
                )
              ]

              const scoreClassNames = [
                'elimination-chart-item-score',
                'elimination-chart-item-score'
              ]

              if (element?.winner) {
                if (element.teams[0] === element.winner) {
                  scoreClassNames[0] += " elimination-chart-item-winner";
                  scoreClassNames[1] += " elimination-chart-item-loser";
                } else {
                  scoreClassNames[0] += " elimination-chart-item-loser";
                  scoreClassNames[1] += " elimination-chart-item-winner";
                }
              }

              return (
                <Fragment key={gameNum}>
                  {element.teams.map((team, index) => {
                    const containerStyles: any = {};
                    if ((gameNum + 1) % 2 === 0) {
                      containerStyles["backgroundColor"] = "#d7d8d9";
                    }
                    if (!team) {
                      containerStyles["opacity"] = 0;
                    }

                    return (
                      <li
                        className={"elimination-chart-item"}
                        style={containerStyles}
                        key={index}
                      >
                      {(index === 0 && (('gid' in element && typeof element?.gid === 'number') || "startAt" in element)) && (
                        <div 
                          className="elimination-chart-game-status" 
                          onClick={onClick}
                          style={{ bottom: bottomValuesByRound(preparedTree.length === 1 ? 0 : round + 1) }}
                        >
                          {gameStatus}
                        </div>
                      )}
                      <div className="elimination-chart-item-helmet">
                        {teamHelmets[index] && <RenderHelmet {...(teamHelmets[index] as HelmetData)} size={100}/>}
                      </div>
                      <div className="elimination-chart-item-helmet-placeholder"/>

                      {team && team in teamNames
                        ? teamNames[team]
                        : " "}

                      <span className={scoreClassNames[index]}>
                        {team && element?.scores && element.scores[team] !== undefined
                          ? element.scores[team]
                          : ""}
                      </span>
                    </li>
                    )
                  })}
                </Fragment>
              );
            })}
          </ul>
        ))}
      <ul>
        <li className="cupbackground">
          {typeof cupImage === "string" 
          ? (
            <img src={cupImage} alt="winnercup" />
          )
          : cupImage}
          <h6>{winnerText}</h6>
          <h4>{winnerName}</h4>
        </li>
      </ul>
    </div>
  );
};

export default ScheduleDiagram;
