import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSeasonDivisionStats } from 'redux/actions/Season/actions/SeasonDivisionStandings';
import { RootState } from 'redux/store';

export const useSeasonDivisonStats = (divisionId?: string) => {
  const dispatch = useDispatch();
  const divisionStandings = useSelector((state: RootState) => state.gameEngine.divisionStandings);

  useEffect(() => {
    if (!divisionId) return;
    dispatch(getSeasonDivisionStats(divisionId));
  }, [divisionId]);

  return divisionStandings;
};
