import franchise from "../../assets/images/homeScreen/franchiseBuild.gif";
import HomeContainer from "./HomeContainer";
import AnimatedTitle from "../Common/AnimatedTitle/AnimatedTitle";

const HomeYourDynasty = () => {
  return (
    <div className="your-dynasty-wrapper">
      <HomeContainer isWithoutCorner={true}>
        <div className="your-dynasty">
          <img src={franchise} alt="random-city" className="your-dynasty-img" />
          <div className="your-dynasty-info">
            <AnimatedTitle className="your-dynasty-title" text="YOUR DYNASTY" />
            <p>
              Ready to take your football experience to the next level? Start by
              customizing your franchise NFT – select team name, colors and
              logo. Next, build a powerhouse team and compete in year-round
              leagues that connects a community of like-minded football
              enthusiasts.
            </p>
          </div>
        </div>
      </HomeContainer>
    </div>
  );
};

export default HomeYourDynasty;
