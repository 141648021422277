import "./Team1MainStyle.scss";
import Title from "../../Common/Title/Title";
import { Table } from "antd";
import history from './../../../routes/history';
export interface Props {
  data?: any;
  team?: any;
}

const Team1Returns = ({ data , team }: Props) =>{
    const scoreColumns:any = [
        {
          title: "",
          dataIndex: "title",
          fixed: "left",
          width: 150,
        },
        {
          title: "KR",
          dataIndex: "kr",
        },
        {
          title: "YDS",
          dataIndex: "yds",
        },
        {
          title: "AVG",
          dataIndex: "avg",
        },
        {
          title: "PR",
          dataIndex: "pr",
        },
        {
          title: "YDS",
          dataIndex: "yds1",
        },
        {
          title: "AVG",
          dataIndex: "avg1",
        },
    ];
    let tablefooter:any={
      key: 800,
      title: "TEAM",
      kr: team.stat.kr || team.stat.kr === 0 ? team.stat.kr :"---",
      yds: team.stat.krYds || team.stat.krYds === 0 ? team.stat.krYds :"---",
      avg: (team.stat.krYds && team.stat.kr)? team.stat.krYds/ team.stat.kr:"---",
      pr: team.stat.pr || team.stat.pr === 0 ? team.stat.pr :"---",
      yds1: team.stat.prYds || team.stat.prYds === 0? team.stat.prYds :"---",
      avg1: (team.stat.prYds && team.stat.pr)? team.stat.prYds/ team.stat.pr:"---",
      tavg1:0,
      tavg: 0,
    };
      const scoreData = data?.map((d: any,index:any) => {
      const info = {
        key: index,
        title: <><span className="lightcolor" style={{cursor: "pointer" }} onClick={() => history.push(`/playerdetail/${d.id}`)} >{d.name ? d.name : "---"}</span></>,
        kr: d.stat.kr || d.stat.kr === 0 ? d.stat.kr :"---",
        yds: d.stat.krYds || d.stat.krYds === 0 ? d.stat.krYds :"---",
        avg: (d.stat.krYds && d.stat.kr)? d.stat.krYds/ d.stat.kr:"---",
        pr: d.stat.pr || d.stat.pr === 0 ? d.stat.pr :"---",
        yds1: d.stat.prYds || d.stat.prYds === 0? d.stat.prYds :"---",
        avg1: (d.stat.prYds && d.stat.pr)? d.stat.prYds/ d.stat.pr:"---",
    }
    tablefooter.tavg += (info.avg==="---"?0:1);
    tablefooter.tavg1 += (info.avg1==="---"?0:1);
    info.avg = (info.avg==="---"?"---":Number(info.avg).toFixed(2))
    info.avg1 = (info.avg1==="---"?"---":Number(info.avg1).toFixed(2))
    return info;
    })
    if(Array.isArray(scoreData) && scoreData.length){
      tablefooter.avg=(tablefooter.avg/tablefooter.tavg).toFixed(2)
      tablefooter.avg1=(tablefooter.avg1/tablefooter.tavg1).toFixed(2)
      scoreData.push(tablefooter)
    }
    return(
        <>
            <div className="scorecommontable standingmain mt-3" >
                <Title text="RETURNS" />
                <Table
                    columns={scoreColumns}
                    pagination={false}
                    dataSource={scoreData}
                    size="small"
                    scroll={{ x: 500 }}
                />
            </div>
        </>
    );
}

export default Team1Returns;