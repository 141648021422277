import { post } from "redux/services/Api";

export type LobbyUser = {
  username: string;
  avatar: string;
  teamName: string;
  teamRating: string;
  totalPrestigePoints: string;
  id: number;
}

export const getLobbyUsers = async(page = 1, limit = 5) => {
  try {
    return await post<LobbyUser[]>('/lobby/getOnlineUsers', { page, limit})
  } catch (e) {
    console.log("Error during loading lobby users", e);
    return [];
  }
}