import { LeaderboardTeamLeaderItem } from "./LeaderboardTeamLeaderItem";
import { useDispatch } from "react-redux";
import { ViewMoreTypes } from "./LeaderboardContent";
import { setTeamLeadersViewMoreType } from "redux/actions/GameEngineAction";

export interface TeamLeaderItem {
  engId: number;
  value: number | string;
  helmet: number;
  logo: number;
  userName: string;
  teamName: string;
  order: number;
  userId: number;
}

type Props = {
  leadersList: TeamLeaderItem[];
  viewMoreType?: keyof typeof ViewMoreTypes;
};

export const LeaderboardTeamLeadersList: React.FC<Props> = ({
  leadersList,
  viewMoreType,
}) => {
  const dispatch = useDispatch();

  const handleOpenPopup = () => {
    if (viewMoreType) {
      dispatch(setTeamLeadersViewMoreType(viewMoreType));
    }
  };

  return (
    <>
      {leadersList.map((leader) => (
        <LeaderboardTeamLeaderItem leader={leader} key={`${leader.teamName}-${leader.order}`} />
      ))}
      {viewMoreType && (
        <div className="view-top-100" onClick={handleOpenPopup}>
          VIEW TOP 100
        </div>
      )}
    </>
  );
};
