/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Title from '../../../Common/Title/Title'
import { Slider } from 'antd'
import './GamePlanStyle.scss'
import { DownOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { useEffect, useState } from 'react'
import { OffensiveStrategy, TeamGamePlan } from '../../../../types'

const GamePlanOffensiveStrategy = ({
  OffensiveDataProp, 
  teamGamePlanData, 
  updateGamePlan, 
  selectedSid,
}: any) => {

  const [dataList, setDataList] = useState<any>()
  const [currentSelect, setCurrentSelect] = useState('LOADING...')
  const [currentOffensive, setCurrentOffensive] = useState<any>()
  const [OffensiveData, setOffensiveData] = useState(OffensiveDataProp)

  useEffect(() => {
    setOffensiveData(OffensiveDataProp)
  }, [OffensiveDataProp])

  useEffect(() => {
    if (selectedSid && OffensiveDataProp) {
      setCurrentSelect(OffensiveDataProp?.find((scheme: any) => scheme?.sid === selectedSid)?.name);
    }
    if (!selectedSid && OffensiveDataProp) {
      setCurrentSelect(OffensiveDataProp[0]?.name);
    } 
  }, [selectedSid, OffensiveDataProp])

  useEffect(() => {
    if (OffensiveData?.length > 0) {
      const foundIndex = OffensiveData?.findIndex((scheme: any) => scheme?.sid === selectedSid);
      const currentIndex = foundIndex === -1 ? 0 : foundIndex;

      setCurrentOffensive(OffensiveData?.[currentIndex])
      let info = [
        {
          id: 1,
          sliderName: 'DEVIATE FROM BASE SCHEME',
          sliderNameKey: 'deviation',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['deviation'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 2,
          sliderName: '1ST DOWN PLAY SELECTION',
          sliderNameKey: '1stDownRun',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['1stDownRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 3,
          sliderName: 'SHORT PLAY SELECTION',
          sliderNameKey: 'shortYdsRun',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['shortYdsRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 4,
          sliderName: 'LONG PLAY SELECTION',
          sliderNameKey: 'longYdsRun',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['longYdsRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 5,
          sliderName: <>4TH DOWN "GO FOR IT"</>,
          sliderNameKey: 'goOnFourth',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['goOnFourth'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 6,
          sliderName: 'RUSHING PLAYS',
          sliderNameKey: 'insideRuns',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['insideRuns'],
          sliderleftname: 'INTERIOR',
          sliderrightname: 'SWEEPS',
        },
        {
          id: 7,
          sliderName: 'QUARTERBACK SNAP',
          sliderNameKey: 'pctPassGun',
          slidervalue: OffensiveData?.[currentIndex]?.strategy['pctPassGun'],
          sliderleftname: 'UNDER CENTER',
          sliderrightname: 'SHOTGUN',
        },
      ]
      setDataList(info)
    }
  }, [OffensiveData, selectedSid])

  const handleClickMenuItem = ({ key }: any) => {
    setCurrentSelect(key)
    const sid = OffensiveData?.find((data: any) => data?.name === key)?.sid;
    if (sid) {
      const planCopy = JSON.parse(JSON.stringify(teamGamePlanData?.schemes));
      const putOffensiveData: TeamGamePlan["schemes"] = Object.assign(
        planCopy, 
        { offense: { sets: OffensiveData, selectedSid: sid || 1 }}
      )

      updateGamePlan(putOffensiveData);
    }

    const currentOffensiveData = OffensiveData.find((data: any) => data.name == key)

    if (currentOffensiveData) {
      setCurrentOffensive(currentOffensiveData)
      const info = [
        {
          id: 1,
          sliderName: 'DEVIATE FROM BASE SCHEME',
          sliderNameKey: 'deviation',
          slidervalue: currentOffensiveData?.strategy['deviation'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 2,
          sliderName: '1ST DOWN PLAY SELECTION',
          sliderNameKey: '1stDownRun',
          slidervalue: currentOffensiveData?.strategy['1stDownRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 3,
          sliderName: 'SHORT PLAY SELECTION',
          sliderNameKey: 'shortYdsRun',
          slidervalue: currentOffensiveData?.strategy['shortYdsRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 4,
          sliderName: 'LONG PLAY SELECTION',
          sliderNameKey: 'longYdsRun',
          slidervalue: currentOffensiveData?.strategy['longYdsRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 5,
          sliderName: <>4TH DOWN "GO FOR IT"</>,
          sliderNameKey: 'goOnFourth',
          slidervalue: currentOffensiveData?.strategy['goOnFourth'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 6,
          sliderName: 'RUSHING PLAYS',
          sliderNameKey: 'insideRuns',
          slidervalue: currentOffensiveData?.strategy['insideRuns'],
          sliderleftname: 'INTERIOR',
          sliderrightname: 'SWEEPS',
        },
        {
          id: 7,
          sliderName: 'QUARTERBACK SNAP',
          sliderNameKey: 'pctPassGun',
          slidervalue: currentOffensiveData?.strategy['pctPassGun'],
          sliderleftname: 'UNDER CENTER',
          sliderrightname: 'SHOTGUN',
        }
      ]
      setDataList(info)
    }
  }

  const onChange = (value: any, name: keyof OffensiveStrategy) => {
    const updateStrategy: OffensiveStrategy = currentOffensive.strategy;

    updateStrategy[name] = value;

    const currentOffensiveDataUpdate: { strategy: OffensiveStrategy} = {
      ...currentOffensive,
      strategy: updateStrategy,
    }

    if (currentOffensiveDataUpdate) {
      setCurrentOffensive(currentOffensiveDataUpdate)
      const info = [
        {
          id: 1,
          sliderName: 'DEVIATE FROM BASE SCHEME',
          sliderNameKey: 'deviation',
          slidervalue: currentOffensiveDataUpdate?.strategy['deviation'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 2,
          sliderName: '1ST DOWN PLAY SELECTION',
          sliderNameKey: '1stDownRun',
          slidervalue: currentOffensiveDataUpdate?.strategy['1stDownRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 3,
          sliderName: 'SHORT PLAY SELECTION',
          sliderNameKey: 'shortYdsRun',
          slidervalue: currentOffensiveDataUpdate?.strategy['shortYdsRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 4,
          sliderName: 'LONG PLAY SELECTION',
          sliderNameKey: 'longYdsRun',
          slidervalue: currentOffensiveDataUpdate?.strategy['longYdsRun'],
          sliderleftname: 'PASS',
          sliderrightname: 'RUN',
        },
        {
          id: 5,
          sliderName: <>4TH DOWN "GO FOR IT"</>,
          sliderNameKey: 'goOnFourth',
          slidervalue: currentOffensiveDataUpdate?.strategy['goOnFourth'],
          sliderleftname: 'LESS',
          sliderrightname: 'MORE',
        },
        {
          id: 6,
          sliderName: 'RUSHING PLAYS',
          sliderNameKey: 'insideRuns',
          slidervalue: currentOffensiveDataUpdate?.strategy['insideRuns'],
          sliderleftname: 'INTERIOR',
          sliderrightname: 'SWEEPS',
        },
        {
          id: 7,
          sliderName: 'QUARTERBACK SNAP',
          sliderNameKey: 'pctPassGun',
          slidervalue: currentOffensiveDataUpdate?.strategy['pctPassGun'],
          sliderleftname: 'UNDER CENTER',
          sliderrightname: 'SHOTGUN',
        },
      ]
      setDataList(info)
    }
  }

  const onAfterChange = () => {
    if (
      OffensiveData !== undefined &&
      teamGamePlanData?.schemes?.defense?.sets !== undefined &&
      teamGamePlanData?.schemes?.special !== undefined
    ) {
      const putOffensiveData: TeamGamePlan["schemes"] = Object.assign(
        JSON.parse(JSON.stringify(teamGamePlanData?.schemes)), 
        { offense: { sets: OffensiveData, selectedSid: teamGamePlanData?.schemes?.offense?.selectedSid || 1 }}
      )
      console.log('🚀 ~ putOffensiveData', putOffensiveData)

      updateGamePlan(putOffensiveData)
    }
  }

  // DROPDOWN MENU
  const menu = (
    <Menu className='specialdrop' onClick={handleClickMenuItem}>
      {OffensiveData?.map((data: any) => {
        return <Menu.Item key={data?.name}>{data?.name} </Menu.Item>
      })}
    </Menu>
  )

  return (
    <>
      <Title text='OFFENSIVE STRATEGY' />
      <div className='gameplan_main_section'>
        <div className='dropdown_gameplan'>
          <h5>SELECT DEFAULT OFFENSIVE SCHEME</h5>
          <div className='filterdropdown specialteams'>
            <Dropdown overlay={menu}>
              <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                {currentSelect} <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
        {dataList?.map((d: any, i: any) => (
          <div className='slider_main' key={`GamePlanOffensiveStrategy-${d.name}-${i}-i`}>
            <h5>{d.sliderName}</h5>
            <Slider
              key={`GamePlanOffensiveStrategy-Slider-${d.name}-${i}-i`}
              defaultValue={d.slidervalue || 50}
              value={d.slidervalue}
              onChange={(e: any) => onChange(e, d.sliderNameKey)}
              onAfterChange={onAfterChange}
              min={d?.min}
              max={d?.max}
            />
            <span>{d.sliderleftname}</span>
            <span>{d.sliderrightname}</span>
          </div>
        ))}
      </div>
    </>
  )
}

export default GamePlanOffensiveStrategy
