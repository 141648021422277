import Title from "../Common/Title/Title";
import PinkButton from "../Common/Button/PinkButton/PinkButton";
import { Table } from "antd";

const CareerStats: React.FC = () =>{
    const activeLeaguesColumns = [
        {
            title: "",
            dataIndex: "title",
        },
        {
            title: "WON",
            dataIndex: "won",
        },
        {
            title: "LOST",
            dataIndex: "lost",
        },
        {
            title: "TIED",
            dataIndex: "tied",
        },
        {
            title: "WIN%",
            dataIndex: "win",
        },
    ];
    const activeLeaguesData = [
        {
            key: "1",
            title: "SEASON",
            won: "34",
            lost: "4",
            tied: "D",
            win: "76%",
        },
        {
            key: "2",
            title: "ELIMINATION",
            won: "34",
            lost: "4",
            tied: "D",
            win: "76%",
        },
        {
            key: "3",
            title: "HEAD-TO-HEAD",
            won: "34",
            lost: "4",
            tied: "D",
            win: "76%",
        },
        {
            key: "4",
            title: "TOTAL",
            won: "34",
            lost: "4",
            tied: "D",
            win: "76%",
        },
    ];
    return (
        <div className="performance_carrerstat" >
            <div className="carrerstat_header" >
                <Title text="CAREER STATS" />
                <PinkButton text="MINT COACH" />
            </div>
            <div className="carrerstat_table" >
                <Table
                    columns={activeLeaguesColumns}
                    pagination={false}
                    dataSource={activeLeaguesData}
                    size="small"
                />
            </div>
        </div>
    );
}

export default CareerStats;