import { Col, Row } from 'react-bootstrap';
import history from 'routes/history';
import { LeagueResult } from 'types';

type Props = {
  game: LeagueResult;
};

const getRecord = (record: LeagueResult['records'][0]): string => {
  return `(${record.won}-${record.lost}-${record.tied})`;
};

export const LeagueOverviewResultsItem: React.FC<Props> = ({ game }) => {
  const wonString = `${game.won.pts} TO ${game.lost.pts}`;
  let wonRecord = '';
  let lostRecord = '';
  if (game.records[0].utid === game.won.utid) {
    wonRecord = getRecord(game.records[0]);
    lostRecord = getRecord(game.records[1]);
  } else {
    wonRecord = getRecord(game.records[1]);
    lostRecord = getRecord(game.records[0]);
  }

  const onClick = () => {
    history.push('/stand', { gid: game.gid });
  };

  return (
    <div className="result-card-container">
      <div className="h2h-result-card">
        <Row>
          <Col xs="2">
            <div className="game-detail-container">
              <span className="game-detail elimination-overview-game-detail">{game.round}</span>
            </div>
          </Col>
          <div className="vr game-detail-divider"></div>
          <Col xs="3">
            <div className="game-detail-container">
              <span className="game-detail elimination-overview-game-detail">
                {game.teamNames[game.won.utid]} {wonRecord}
              </span>
            </div>
          </Col>
          <div className="vr game-detail-divider"></div>
          <Col xs="2">
            <div className="game-detail-container">
              <span className="game-detail elimination-overview-game-detail">{wonString}</span>
            </div>
          </Col>
          <div className="vr game-detail-divider"></div>
          <Col xs="3">
            <div className="game-detail-container">
              <span className="game-detail elimination-overview-game-detail">
                {game.teamNames[game.lost.utid]} {lostRecord}
              </span>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <button onClick={onClick} className="box-score-button elimination-detail-button">
              GAME
              <br />
              DETAIL
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
