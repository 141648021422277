import Title from "../Common/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import { getTeamLeaguesHistory } from "../../redux/actions/GameEngineAction";
import { useParams } from "react-router-dom";
import PlayerCareerRating from "components/PlayerDetail/PlayerCareerRating";

const FranchiseCareerRating: React.FC = () => {
  const dispatch = useDispatch();
  const { franchiseId }: any = useParams();

  const userTeamListData = useSelector(
    (state: RootState) => state.team.userTeamsList
  );
  const teamData = useSelector(
    (state: RootState) => state.gameEngine.teamLeaguesHistory
  );

  const chartData = teamData?.ratingHistory?.map(
    (item: { rating: number; date: string }) => ({
      date: new Date(item.date),
      value: item.rating,
    })
  ) || [];

  useEffect(() => {
    const team = userTeamListData?.find(
      (team: any) => team?.id === Number(franchiseId)
    );
    dispatch(getTeamLeaguesHistory(team?.engId || franchiseId));
  }, [userTeamListData, franchiseId]);

  return (
    <>
      <Title text="PLAYER RATING" />
      <PlayerCareerRating ratingHistory={chartData} />
    </>
  );
};
export default FranchiseCareerRating;
