import CardBody from "components/Common/Card/CardBody";
import { Card } from "react-bootstrap";
import { GroupForm } from "./GroupForm";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { LeftOutlined } from "@ant-design/icons";
import history from "routes/history";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export function GroupFormArea() {
  const [title, setTitle] = useState("Create group");
  const user = useSelector((state: RootState) => state.app.resData);
  const groupId = useLocation().pathname.split("/")[2];
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setTitle("Edit group");
    } else {
      setTitle("Create group");
    }
  }, [location.pathname]);

  if (!user) return null;
  
  const handleBack = () => {
    if (location.pathname.includes("edit")) {
      history.push(`/group/${groupId}`);
    } else {
      history.push(`/profile/${user.id}`);
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="create-group__title-wrapper">
          <h2 className="create-group__title">{title}</h2>
          <BlueButton
            text={<span><LeftOutlined />
              {location.pathname.includes("edit") ? "Back to group" : "Back to profile"}
            </span>}
            className="mb-4"
            onClick={handleBack}
          />
        </div>
        <GroupForm />
      </CardBody>
    </Card>
  );
}
