/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LeagueDetailStyle.scss";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export interface Props {
  data?: any;
  arrow?: any;
  rating?: any;
  teamrating?: any;
  profile?: any;
}

const LeagueDetail = ({ data, arrow, rating, teamrating, profile }: Props) => {
  console.log(
    "🚀 ~ file: LeagueDetail.tsx ~ line 15 ~ LeagueDetail ~ data",
    data
  );
  return (
    <>
      <div className="leagueMain">
        <Row>
          <Col xl={2}>
            <div className="franchiseProfile leagueProfile">
              <img src={data?.profileimage} alt="profile" width="90px" />
            </div>
          </Col>
          <Col xl={6}>
            <div className="leagueDetail">
              <h5>{data.teamName}</h5>
              <Row>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>USER NAME</span>
                  <span>{data.username}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>NO. OF FRANCHISES : </span>
                  <span>{data.numberOfFranchises}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>Email</span>
                  <span>{data.email}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>MINTED PLAYERS</span>
                  <span>{data.mintedPlayers}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>JOINED</span>
                  <span>{data.joined}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>CURRENT LEAGUE : </span>
                  <span>{data.currentLeague}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>WALLET</span>
                  <span>{data.walletId}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>FUNDS - IN - PLAY</span>
                  <span>{data.walletBalance}</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={4}>
            <div className="franchiseBoxSection">
              {teamrating && (
                <div className="franchiseBoxBack">
                  <p>{data.teamrating}</p>
                  <h5>{data.teamratingdigit}</h5>
                </div>
              )}
              <div className="franchiseBoxBack">
                <p>WIN %</p>
                <h5>{data.teamWinningPercentage}</h5>
              </div>
              <div className="franchiseBoxBack">
                <p>FAN BASE</p>
                <h5>{data.teamFanBase}</h5>
              </div>
                <div className="franchiseBoxBack">
                  <p>RATING</p>
                  <div className="rating">
                    <h5>{data.teamRating}</h5>
                  </div>
                </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeagueDetail;
