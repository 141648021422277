import { Col, Row } from "antd";
import { InGameStatsRow, InGameTeamsStats } from "types";

export const InGameStatsRowsMap: InGameStatsRow = {
  totalPlays: { label: "Total Plays", isMain: true },
  totalYards: { label: "Total Yards", isMain: true },
  passing: { label: "Passing", isMain: true },
  compAtt: { label: "Comp - Att", isMain: false },
  ydsPerPass: { label: "Yards per pass", isMain: false },
  intPass: { label: "Interceptions thrown", isMain: false },
  skYdsLost: { label: "Sack yards lost", isMain: false },
  yardsPerPlay: { label: "Yards per play", isMain: true },
  rushing: { label: "Rushing", isMain: true },
  rusAtmp: { label: "Rush Attempts", isMain: false },
  ydsPerRush: { label: "Yds per rush", isMain: false },
  penalties: { label: "Penalties", isMain: true },
  turnovers: { label: "Turnovers", isMain: true },
  fumbLost: { label: "Fumbles lost", isMain: true },
  spcTeamsTds: { label: "DEF/Spec Teams TDs", isMain: true },
};

interface InGameTeamsStatsProps {
  stats: InGameTeamsStats | null;
  logos: Array<string>;
}

export function GameTeamsStats({ stats, logos }: InGameTeamsStatsProps) {
  return (
    <div className="quarter-stats__wrapper">
      <h5 className="quarter-stats__title">Team Stats</h5>
      <Row className="quarter-stats__logos-row">
        <Col xs={5} offset={14} className="row__t-stat-cell ">
          {
            <img
              className="quarter-stats__logo-img"
              src={logos[0]}
              onError={(e) => {
                e.currentTarget.className = "hidden";
              }}
              onLoad={(e) => {
                e.currentTarget.className = "quarter-stats__logo-img";
              }}
            />
          }
        </Col>
        <Col xs={5} className="row__t-stat-cell ">
          {
            <img
              className="quarter-stats__logo-img"
              src={logos[1]}
              onError={(e) => {
                e.currentTarget.className = "hidden";
              }}
              onLoad={(e) => {
                e.currentTarget.className = "quarter-stats__logo-img";
              }}
            />
          }
        </Col>
      </Row>
      <div className="quarter-stats__rows-wrapper">
        {Object.keys(InGameStatsRowsMap).map((key) => (
          <div
            key={key}
            className={`quarter-stats__row ${
              InGameStatsRowsMap[key as keyof typeof InGameStatsRowsMap].isMain
                ? "main"
                : ""
            }`}
          >
            <Row>
              <Col xs={14}>
                {
                  InGameStatsRowsMap[key as keyof typeof InGameStatsRowsMap]
                    .label
                }
              </Col>
              <Col xs={5} className="row__t-stat-cell">
                {stats ? stats[key as keyof typeof stats][0] : "- - -"}
              </Col>
              <Col xs={5} className="row__t-stat-cell">
                {stats ? stats[key as keyof typeof stats][1] : "- - -"}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
}
