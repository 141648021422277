import Error from "../../../assets/images/common/error.svg";
import "./UnderageModalStyles.scss";

const UnderageModal = () =>{
  return <>
    <div className="underage-component" >
      <img src={Error} alt="error-icon" />
      <p>Unfortunately, we can't complete your registration due to your age. You must be 18 years or older to play GM Dynasty</p>
    </div>
  </>;
}

export default UnderageModal;