/* eslint-disable jsx-a11y/anchor-is-valid */
import './TabContentStyle.scss';
import { Row, Col } from 'react-bootstrap';
import { Modal, Table, Tooltip } from 'antd';
import userDefaultImg from '../../../assets/images/setting/user.svg';
import edit from '../../../assets/images/setting/edit.svg';
import './Modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import React, { useEffect, useState } from 'react';
import { checkEmailOrUsernameAvailable, updateUserProfile } from '../../../redux/actions/AuthAction';
import { API_URL, BASE_URL } from '../../../config';
import { validEmail, validName } from '../../../helper/helper';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { useGameJoinTutorial } from 'components/Tutorials/hooks/useGameJoinTutorial';
import history from 'routes/history';
import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { User } from 'types/user';

const SettingProfile: React.FC = () => {
  const dispatch = useDispatch();
  const { enableTutorials, showTutorial } = useGameJoinTutorial();

  const errorData = useSelector((state: RootState) => state.app.error);

  const [editMode, setEditMode] = useState(false);
  const [showConfirmSaveAvatar, setShowConfirmSaveAvatar] = useState(false);
  const user = useSelector((state: RootState) => state.app.resData);
  const defaultAvatar = user?.avatar === '' || user?.avatar === null || user?.avatar === undefined ? userDefaultImg : `${API_URL}/images/avatar/${user?.avatar}`;

  const [preview, setPreview] = useState(defaultAvatar);

  const [values, setValues] = useState<Pick<
    User,
    'avatar' | 'userName' | 'birthDate' | 'email' | 'portfolioname' | 'firstname' | 'lastname' | 'country' | 'state' | 'id'
  > | null>(null);

  useEffect(() => {
    if (user) {
      setValues({
        avatar: user?.avatar === '' || user?.avatar === null || user?.avatar === undefined ? userDefaultImg : `${BASE_URL}/images/avatar/${user?.avatar}`,
        userName: user?.userName,
        birthDate: user?.birthDate,
        email: user?.email,
        portfolioname: user?.portfolioname || user?.userName,
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        country: user?.country,
        state: user?.state || '',
        id: user?.id,
      });
    }
  }, [user]);

  const [error, setError] = useState('');

  const Columns = [
    {
      title: '',
      dataIndex: 'title',
    },
    {
      title: '',
      dataIndex: 'content',
    },
  ];
  const Data = [
    {
      key: '1',
      title: 'User Name :',
      content: user?.userName,
    },
    {
      key: '2',
      title: 'Date of Birth :',
      content: user?.birthDate,
    },
    {
      key: '3',
      title: 'Email :',
      content: user?.email,
    },
    {
      key: '4',
      title: 'Portfolio Name :',
      content: user?.portfolioname || user?.userName,
    },
    {
      key: '5',
      title: 'Real Name :',
      content: user?.firstname === '' || user?.firstname === null || user?.firstname === undefined ? user?.userName : user?.firstname + ' ' + user?.lastname,
    },
    {
      key: '6',
      title: 'Location :',
      content:
        user?.state && user?.country && user?.country !== 'null'
          ? `${user.state}, ${user.country}`
          : user?.country && user?.country !== 'null'
          ? user.country
          : 'Unknown',
    },
  ];

  if (!user) return null;

  function handleEditMode() {
    setEditMode(true);
  }

  function handleCancelSaveAvatar() {
    setPreview(defaultAvatar);
    setValues((p) => {
      if (p) {
        return { ...p, avatar: defaultAvatar };
      }

      return null;
    });
    setShowConfirmSaveAvatar(false);
  }

  async function handleSaveAvatar() {
    if (!values?.avatar || !user?.id) return;
    const formData = new FormData();
    formData.append('avatar', values.avatar);
    formData.append('id', String(user.id));

    setError('');
    dispatch(updateUserProfile(formData));

    setShowConfirmSaveAvatar(false);
  }

  async function handleSubmit() {
    if (values?.userName !== user?.userName && values?.userName) {
      const isUsernameUnique = await checkEmailOrUsernameAvailable(values.userName);
      if (isUsernameUnique && !isUsernameUnique.success) {
        setError('Username already taken');
        return;
      }
    }
    if (values?.email !== user?.email && values?.email) {
      const isEmailUnique = await checkEmailOrUsernameAvailable(values.email);
      if (isEmailUnique && !isEmailUnique.success) {
        setError('Email already taken');
        return;
      }
    }

    const formData = new FormData();
    if (values) {
      formData.append('avatar', values.avatar);
      formData.append('userName', values.userName);
      formData.append('birthDate', String(values.birthDate));
      formData.append('email', values.email);
      formData.append('portfolioname', values.portfolioname);
      formData.append('firstname', values.firstname);
      formData.append('lastname', values.lastname);
      formData.append('country', values.country);
      formData.append('state', values.state);
      formData.append('id', String(values.id));
    }

    const userName = formData.get('userName')?.toString() || '';

    if (!validName.test(userName)) {
      setError('Only letters and numbers are permitted');
      return;
    }

    if (!validEmail.test(formData.get('email')?.toString() || '')) {
      setError('Please enter a valid email');
      return;
    }

    setError('');
    dispatch(updateUserProfile(formData));

    if (errorData) return;
    setEditMode(false);
  }

  function handleChange(e: any) {
    const { name, value, files } = e.target;
    name !== 'avatar'
      ? setValues((p) => {
          if (!p) return null;

          return {
            ...p,
            [name]: value,
          };
        })
      : handleFileUpload(files);
  }

  function handleChangeAvatar(e: any) {
    const { files } = e.target;
    console.log('files', files);
    handleFileUpload(files);
    setShowConfirmSaveAvatar(true);
  }

  const handleFileUpload = (files: any) => {
    const file = files[0];

    if (file.size > 5242880) {
      setError('File size should be less than 5mb');
      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      setPreview(URL.createObjectURL(file));
      setValues((p) => {
        if (!p) return null;

        return {
          ...p,
          avatar: files[0],
        };
      });
    }
  };

  return (
    <div className="profilemain">
      {!editMode ? (
        <Row>
          <Col md={6} sm={8}>
            <div className="profileImage">
              <h5>Avatar :</h5>
              <div className="uploadavatar">
                <Tooltip title="Click to change avatar" placement="right" trigger={['hover']}>
                  <input
                    name="avatar"
                    onChange={handleChangeAvatar}
                    type="file"
                    // accept="image/png"
                  />
                  <div className="customimage">
                    <img src={preview} alt="upload" />
                    <img className="uploadavatar__edit-icon" src={edit} alt="edit_icon" />
                  </div>
                </Tooltip>
              </div>
            </div>
            <Table columns={Columns} pagination={false} dataSource={Data} size="small" className="depthcharttable" />
          </Col>
          <Col md={6} sm={4}>
            <div className="m-3 m-md-0 text-sm-end profilehead">
              <a href="#top-of-settings" className="" onClick={handleEditMode}>
                <img src={edit} alt="edit_icon" /> Edit Profile
              </a>
            </div>
          </Col>
          <Modal
            title="Save new avatar?"
            open={showConfirmSaveAvatar}
            onCancel={handleCancelSaveAvatar}
            onOk={handleSaveAvatar}
            centered
            width="400px"
            okText="SAVE"
            cancelText="CANCEL"
            className="avatar-confirm-modal"
          />
        </Row>
      ) : (
        <>
          <div className="editsetting_form">
            <div className="row">
              <div className="col-sm-6 order-sm-1 order-2">
                <div className="row mb-2">
                  <div className="col-md-4 col-sm-12">
                    <label>User Name:</label>
                  </div>
                  <div className="col-md-8 col-sm-12 mb-2">
                    <input className="w-100" type="text" name="userName" onChange={handleChange} value={values?.userName} placeholder="Username" />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 col-sm-12">
                    <label>Date of Birth:</label>
                  </div>
                  <div className="col-md-8 col-sm-12 mb-2">
                    <input className="w-100" value={String(values?.birthDate)} name="birthDate" onChange={handleChange} type="date" placeholder="DOB" />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 col-sm-12">
                    <label>Email:</label>
                  </div>
                  <div className="col-md-8 col-sm-12 mb-2">
                    <input className="w-100" value={values?.email} name="email" type="text" onChange={handleChange} placeholder="Email" />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-4 col-sm-12">
                    <label>Portfolio Name:</label>
                  </div>
                  <div className="col-md-8 col-sm-12 mb-2">
                    <input className="w-100" value={values?.portfolioname} name="portfolioname" onChange={handleChange} type="text" placeholder="Portfolio Name" />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center col-sm-6 order-sm-2 order-1">
                <div className="profileImage">
                  <h5>Avatar :</h5>
                  <div className="uploadavatar">
                    <Tooltip title="Click to change avatar" placement="right" trigger={['hover']}>
                      <input
                        name="avatar"
                        onChange={handleChange}
                        type="file"
                        // accept="image/png"
                      />
                      <div className="customimage">
                        <img src={preview} alt="upload" />
                        <img className="uploadavatar__edit-icon" src={edit} alt="edit_icon" />
                      </div>
                      <span>Max File Size : 5MB </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2 col-sm-12">
                <label>Real Name:</label>
              </div>
              <div className="col-md-4 col-sm-6 mb-2">
                <input className="w-100" value={values?.firstname} name="firstname" onChange={handleChange} type="text" placeholder="First Name" />
              </div>
              <div className="col-md-4 col-sm-6 mb-2">
                <input className="w-100" value={values?.lastname} name="lastname" onChange={handleChange} type="text" placeholder="Last Name" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2 col-sm-12">
                <label>Location :</label>
              </div>
              <div className="col-md-4 col-sm-6 mb-2">
                <select className="rounded-pill" style={{ backgroundColor: '#f4f7f7', height: 45 }} value={values?.country} name="country" onChange={handleChange}>
                  <option value="" disabled>
                    Please select a contry
                  </option>
                  <option value="India">India</option>
                  <option value="Usa">USA</option>
                  <option value="UK">UK</option>
                  <option value="Singapoore">Singapoore</option>
                  <option value="Canada">Canada</option>
                </select>
              </div>
              <div className="col-md-4 col-sm-6 mb-2">
                <input className="w-100" value={values?.state} name="state" onChange={handleChange} type="text" placeholder="State" />
              </div>
            </div>
            <br />
            {errorData !== null && <div className="error">{errorData}</div>}
            <div className="error">{error}</div>
            <div className="d-flex flex-row gap-3">
              <button onClick={handleSubmit} className="blueButton">
                Submit Changes
              </button>
              <button onClick={() => setEditMode(false)} className="whiteButton">
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      <div className="d-flex gap-4 mt-4 justify-content-between">
        <div className="d-flex gap-4">
          {!showTutorial && (
            <BlueButton
              text="RESTART TUTORIAL"
              onClick={() => {
                enableTutorials();
                history.push('/ownfranchise');
              }}
            />
          )}
          <BlueButton
            text="PUBLIC PROFILE"
            onClick={() => {
              history.push(`/profile/${user?.id}`);
            }}
          />
        </div>

        <PinkButton text="DELETE ACCOUNT" onClick={() => history.push('/account-deletion')} />
      </div>
    </div>
  );
};

export default SettingProfile;
