import { useGroupsForLeagues } from "helper/useGroupsForLeagues";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const useGroupOptions = () => {
  const userId: number = useSelector((state: RootState) => state.app.resData?.id || 0);
  const groups = useGroupsForLeagues(userId);
  const basicOption = {
    id: 0,
    option: "NONE",
    value: null,
  };

  return [
    basicOption,
    ...groups.map((group) => ({
      id: group.id,
      option: group.name,
      value: group.id,
    })),
  ];
};