import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { GroupRoles } from 'types/groups';

export function useGroupHeader() {
  const user = useSelector((state: RootState) => state.app.resData);
  const group = useSelector((state: RootState) => state.group.groupInfo);
  const groupName = group?.name;
  const groupAvatar = group?.avatar;
  const groupMembersLength = group?.members.length;
  const groupOwner = group?.members.find((member) => member.role.name === GroupRoles.OWNER)?.user;
  const membershipStatus = group?.members.find((member) => member.user.id === user?.id)?.status;
  const isMember = group?.members.some((member) => member.user.id === user?.id);
  const isAdmin = group?.members.filter((member) => member.role.name === GroupRoles.ADMIN).some((member) => member.user.id === user?.id);
  const groupStartedAt = group?.startedAt;

  return {
    groupName,
    groupAvatar,
    groupMembersLength,
    groupOwner,
    groupStartedAt,
    isMember,
    isAdmin,
    membershipStatus,
    groupId: group?.id,
    isOwner: isMember && groupOwner?.id === user?.id,
    noOfTrophies: group?.noOfTrophies || 'N/A',
    prestigePoints: group?.prestigePoints || 'N/A',
    leagueCreation: group?.leaguaCreation,
  };
}
