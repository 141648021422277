import { CountdownTimer } from 'components/Common/Countdown/CountdownTimer';
import { shortenString } from 'helper/helper';
import moment from 'moment';
import { StyledLink } from './StyledLink';
import { UserTeamsList } from 'types';
import { Col, Row } from 'react-bootstrap';
import React from 'react';

const getGameLinkProps = (
  gid: number | string,
  type: 'H' | 'S' | 'E',
  status: string,
  children: React.ReactNode = gid,
): { to: string; params?: any; children: React.ReactNode } => {
  if (type === 'H') {
    if (status === 'complete') {
      return {
        to: '/stand',
        params: { gid },
        children,
      };
    }

    if (status === 'inProgress') {
      return {
        to: `/gameplay?id=${gid}`,
        children,
      };
    }

    if (status === 'pregame') {
      return {
        to: '/leagueheadtohead',
        params: { gid },
        children,
      };
    }

    if (status === '') {
      return {
        to: `/EnteredLeague?gid=${gid}&type=${type}`,
        children,
      };
    }
  }

  if (type === 'E') {
    if (status === 'waitingForPlayers') {
      return {
        to: `/EnteredLeague?gid=${gid}&type=${type}`,
        children,
      };
    }
    return {
      to: `/leagueelimination?gid=${gid}`,
      children,
    };
  }

  if (type === 'S') {
    return {
      to: `/season/${gid}`,
      children,
    };
  }

  return {
    to: '/ownfranchise',
    children: 'N/A',
  };
};

type Activity = UserTeamsList[0]['activity'];

const getLink = (gid: number | string, type: 'H' | 'S' | 'E', status: string, children: React.ReactNode = gid) => {
  return <StyledLink {...getGameLinkProps(gid, type, status, children)} />;
};

const getSeasonStatus = (activity: Activity) => {
  const status = activity?.season?.status;
  if (status === 'pending') {
    return 'PENDING';
  }
  if (status === 'running') {
    return 'IN PROGRESS';
  }

  return 'IDLE';
};

const getSeasonInfo = (activity: Activity) => {
  const gid = activity?.season?.gid;
  const status = activity?.season?.status;
  const teamsJoined = activity?.season?.teamsJoined;
  const numberOfTeams = activity?.season?.numberOfTeams;
  const startAt = activity?.season?.startAt;
  const finishAt = activity?.season?.finishAt;

  let leftStatus = 'LAST';
  let linkText = 'N/A';

  if (!gid || !status) {
    return `${leftStatus} : ${linkText}`;
  }

  if (status === 'pending' && teamsJoined !== numberOfTeams) {
    leftStatus = 'FILLING';
    linkText = `${teamsJoined} / ${numberOfTeams}`;
  }
  if (status === 'pending' && teamsJoined === numberOfTeams) {
    leftStatus = 'NEXT';
    linkText = moment(startAt).format('MM/DD/YYYY hh:mm A');
  }
  if (status === 'running') {
    leftStatus = 'IN PROGRESS';
    linkText = shortenString(gid, 10);
  }
  if (status === 'completed') {
    linkText = moment(finishAt).format('MM/DD/YYYY hh:mm A');
  }

  return (
    <>
      {leftStatus} : {linkText !== 'N/A' ? getLink(gid, 'S', status, linkText) : linkText}
    </>
  );
};

const getEliminationStatus = (activity: Activity) => {
  const status = activity?.elimination?.status;
  const round = activity?.elimination?.round;

  if (status === 'waitingForPlayers') {
    return 'FILLING';
  }
  if (status === 'waitingForStart') {
    return 'PENDING';
  }
  if (status === 'inProgress' && round) {
    return round;
  }

  return 'IDLE';
};

const getEliminationInfo = (activity: Activity) => {
  const gid = activity?.elimination?.gid;
  const status = activity?.elimination?.status;
  const teamsJoined = activity?.elimination?.teamsJoined;
  const numberOfTeams = activity?.elimination?.numberOfTeams;
  const startAt = activity?.elimination?.startAt;

  let leftStatus = 'LAST';
  let linkText = 'N/A';

  if (!gid || !status) {
    return `${leftStatus} : ${linkText}`;
  }

  if (status === 'waitingForPlayers') {
    leftStatus = 'FILLING';
    linkText = `${teamsJoined} / ${numberOfTeams}`;
  }
  if (status === 'waitingForStart') {
    leftStatus = 'NEXT';
    linkText = moment(startAt).format('MM/DD/YYYY hh:mm A');
  }
  if (status === 'inProgress') {
    leftStatus = 'IN PROGRESS';
    linkText = `${gid}`;
  }
  if (status === 'complete') {
    linkText = moment(startAt).format('MM/DD/YYYY hh:mm A');
  }

  return (
    <>
      {leftStatus} : {linkText !== 'N/A' ? getLink(gid, 'E', status, linkText) : linkText}
    </>
  );
};

const getH2HStatus = (activity: Activity) => {
  const status = activity?.H2H?.status;
  const score = activity?.H2H?.score;
  const scoreValues = Object.values(score || {});
  if (['', 'pregame'].includes(String(status))) {
    return 'PENDING';
  }

  if (status === 'inProgress' && score) {
    return `${scoreValues[0]} TO ${scoreValues[1]}`;
  }

  return 'IDLE';
};

const getH2HInfo = (activity: Activity, utid?: number) => {
  const gid = activity?.H2H?.gid;
  const status = activity?.H2H?.status;
  const score = activity?.H2H?.score;
  const pregameEnd = activity?.H2H?.pregameEnd;

  let leftStatus = 'LAST';
  let linkText: string | React.ReactNode = 'N/A';

  if (!gid || !status) {
    return `${leftStatus} : ${linkText}`;
  }

  if (status === 'pregame' && pregameEnd) {
    leftStatus = 'NEXT';
    linkText = <CountdownTimer targetDate={new Date(pregameEnd)} />;
  }
  if (status === 'inProgress') {
    leftStatus = 'IN PROGRESS';
    linkText = `${gid}`;
  }
  if (status === 'complete' && score && utid) {
    let wonOrLost = 'LOST';
    const teams = Object.keys(score).map(Number);
    const opponentUtid = teams.find((t) => t !== utid) as number;
    const myTeamScore: number = score?.[utid];
    const oppTeamScore: number = score?.[opponentUtid];
    if (myTeamScore > oppTeamScore) {
      wonOrLost = 'WON';
    }

    linkText = `${wonOrLost} ${myTeamScore} TO ${oppTeamScore}`;
  }

  return (
    <>
      {leftStatus} : {linkText !== 'N/A' ? getLink(gid, 'H', status, linkText) : linkText}
    </>
  );
};

type Headers = 'SEASON' | 'ELIMINATION' | 'HEAD-TO-HEAD';

const headers: Headers[] = ['SEASON', 'ELIMINATION', 'HEAD-TO-HEAD'];

const methodMap: Record<Headers, ((activity: Activity, engId?: number) => string | React.JSX.Element)[]> = {
  SEASON: [getSeasonStatus, getSeasonInfo],
  ELIMINATION: [getEliminationStatus, getEliminationInfo],
  'HEAD-TO-HEAD': [getH2HStatus, getH2HInfo],
};

type Props = {
  activity: Activity;
  engId: number;
}

export const OwnFranchiseActivity: React.FC<Props> = ({ activity, engId}) => (
  <div className="leagueDetailRight">
    {headers.map((h, i) => (
      <Row key={i}>
        <Col xl={4} lg={4} md={4} sm={4} xs={6}>
          <span>{h}</span>
        </Col>
        <Col xl={3} lg={3} md={4} sm={4} xs={6}>
          <span>{methodMap[h][0](activity, engId)}</span>
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={12}>
          <span>{methodMap[h][1](activity, engId)}</span>
        </Col>
      </Row>
    ))}
  </div>
);
