import { useSelector } from "react-redux";
import "./SelectedFormations.scss";
import Title from "components/Common/Title/Title";
import { Col, Row } from "react-bootstrap";
import { RootState } from "redux/store";
import { RenderHelmet } from "components/Common/RenderHelmet/RenderHelmet";

export const SelectedFormations: React.FC = () => {
  const gameOverview = useSelector(
    (state: any) => state.gameEngine.PregameOverview
  );
  const avatarsList = useSelector(
    (state: RootState) => state?.team?.fetchedAllAvatar
  );

  const firstHelmetData = avatarsList?.find((data: any) => +data?.engId === gameOverview?.teams?.[0]?.utid);
  const secondHelmetData = avatarsList?.find((data: any) => +data?.engId === gameOverview?.teams?.[1]?.utid);

  if (!gameOverview || !gameOverview?.teams || !avatarsList || !firstHelmetData || !secondHelmetData) {
    return <div/>
  }


  return (
    <div className="selected-formations-container mt-4">
      <Title text="BASE FORMATIONS SELECTED" />
      <Row className="selected-formations-row">
        <Col xs="3">
          <RenderHelmet {...firstHelmetData} size={115}/>
        </Col>
        <Col>
          <span className="selected-formations-data"><strong>OFFENSE:</strong> {gameOverview.teams[0].offensiveGamePlan}</span>
        </Col>
        <Col>
          <span className="selected-formations-data"><strong>DEFENSE:</strong> {gameOverview.teams[0].defensiveGamePlan}</span>
        </Col>
      </Row>
      <Row className="selected-formations-row">
        <Col xs="3">
          <RenderHelmet {...secondHelmetData} size={115}/>
        </Col>
        <Col>
          <span className="selected-formations-data"><strong>OFFENSE:</strong> {gameOverview.teams[1].offensiveGamePlan}</span>
        </Col>
        <Col>
          <span className="selected-formations-data"><strong>DEFENSE:</strong> {gameOverview.teams[1].defensiveGamePlan}</span>
        </Col>
      </Row>
    </div>
  )
}