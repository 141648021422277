import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export function useUserData() {
  const userData = useSelector((state: RootState) => state.app.resData);
  
  if (!userData) return null;

  return userData;
}
