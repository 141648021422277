import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";
import { LeaderboardMain } from "components/Leaderboard/LeaderboardMain"
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const LeaderboardScreen = () => {
  const loadingAppState = useSelector((state: RootState) => state.app.loader);
  const loadingTeamState = useSelector((state: RootState) => state.team.loader);
  const loadingGameEngineState = useSelector(
    (state: RootState) => state.gameEngine.loader
  );
  const loadingSettingState = useSelector(
    (state: RootState) => state.setting.loader
  );

  const isLoading = !!loadingAppState || !!loadingTeamState || !!loadingGameEngineState || !!loadingSettingState
  
  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="pagelayout">
        <LeaderboardMain />
      </div>
    </>
  )
}