/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "../PurchaseFranchiseStyle.scss";
import { Row, Col } from "react-bootstrap";
import { useCityList } from "helper/useCityList";
import { City } from "types";
import { Modal } from "antd";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";

type Props = {
  city: City | null;
  setCity: (city: City | null) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  handlePurchaseCity: () => void;
};

const SelectCityModal: React.FC<Props> = ({
  city,
  setCity,
  visible,
  setVisible,
  handlePurchaseCity,
}) => {
  const userTeamList = useSelector((state: RootState) => state.team.userTeamsList);
  const isTeamFirst = userTeamList !== undefined && userTeamList?.length === 0;

  const handleCity = (selectedCity: City) => {
    setCity({
      ...selectedCity,
      price: isTeamFirst ? selectedCity.priceIfTeamIsFirst : selectedCity.price,
    });
  };
  const cityList: City[] | null = useCityList();

  useEffect(() => {
    handlePurchaseCity();
  }, [city])
  if (!cityList) return null;

  return (
    <>
      <Modal
        centered
        className="selectcitymodal"
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        <div className="citymodalmain">
          <div className="citymodalheader">
            <h5>SELECT YOUR TEAM’S HOME CITY</h5>
            <p>
              You can choose a specific city or have your team randomly
              selected. Cities that are more heavily populated have a greater
              probability of a higher Fan Base rating. This has a small impact
              on home field advantage during game play.
            </p>
          </div>
          <div className="citymodalcontent selectcity">
            <Row>
              {cityList.sort((a, b) => a.name.localeCompare(b.name)).map((d, i: any) => (
                <Col xl={3} lg={3} md={6} sm={6} onClick={() => handleCity(d)} key={d.name} >
                  <div className="cityname">
                    <label className="radiomain">
                      <input type="radio" name="radio" />
                      <span className="checkmark"></span>
                      <span className="city">{d.name}</span>
                      <span className="city cityprice">{`$${Number(isTeamFirst ? d.priceIfTeamIsFirst : d.price).toFixed(2)}`}</span>
                    </label>
                  </div>
                </Col>
              ))}
              <Col xl={12} lg={12} md={12} sm={12} onClick={() => handleCity({
                baseRatingFrom: 41,
                BaseRatingTo: 90,
                name: "RANDOM CITY",
                price: isTeamFirst ? 0 : 10,
                priceIfTeamIsFirst: 0,
                cityCount: 0,
                id: -2,
                image: ""
              })}>
                  <div className="cityname random">
                    <label className="radiomain">
                      <input type="radio" name="radio" />
                      <span className="checkmark"></span>
                      <span className="city">RANDOM CITY SELECTION - &nbsp;</span>
                      <span className="city cityprice">{isTeamFirst ? "FREE" : "$10.00"}</span>
                    </label>
                  </div>
                </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectCityModal;
