const seasonColumns = [
  {
    title: "SEASON",
    dataIndex: "season",
  },
  {
    title: "",
    dataIndex: "value",
  },
];

const seasonData = [
  {
    season: "SEASONS PLAYED :",
    value: "N/A",
  },
  {
    season: "REGULAR SEASON RECORD :",
    value: "N/A",
  },
  {
    season: "PLAYOFF RECORD :",
    value: "N/A",
  },
  {
    season: "CHAMPIONSHIPS :",
    value: "N/A",
  },
  {
    season: "BEST RECORD :",
    value: "N/A",
  },
  {
    season: "WORST RECORD :",
    value: "N/A",
  },
  {
    season: "ROI (WINNING-FEES) :",
    value: "N/A",
  },
];

const eliminationColumns = [
  {
    title: "ELIMINATION",
    dataIndex: "elimination",
  },
  {
    title: "",
    dataIndex: "value",
  },
];

const headToheadColumns = [
  {
    title: "HEAD-TO-HEAD",
    dataIndex: "headTohead",
  },
  {
    title: "",
    dataIndex: "value",
  },
];

export { seasonColumns, seasonData, eliminationColumns, headToheadColumns };