import "./CardStyle.scss";

type Props = {
    children?: React.ReactNode;
}

const Card: React.FC<Props> = ({ children }) => (
    <div className="card">
        {children}
    </div>
);

export default Card;