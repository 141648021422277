import { useState } from "react";
import { Row, Col, Container, Stack, Tab } from "react-bootstrap";
import { InfoCircleFilled } from "@ant-design/icons";
import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import OwnFranchiseHeader from "components/OwnFranchise/OwnFranchiseHeader";
import { MintRosterInfo } from "./modals/MintRosterInfo/MintRosterInfo";
import RosterImageBuilder from "./components/RosterImageBuilder";
import { Roster } from "./interface";
import { ProfileCreationModal } from "./modals/ProfileCreationModal/ProfileCreationModal";
import ImageFlip from "./components/ImageFlip";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import GenerateBackImage from "./components/GenerateBackImage";
import MintPlayerConnected from "./components/MintPlayerConnected";
import { RenderHelmet } from "components/Common/RenderHelmet/RenderHelmet";
import { UserTeamsList } from "types";

const tabTitle = "CUSTOMIZE & CREATE YOUR PLAYER";

export const MintRoster: React.FC = () => {
  const [frontImage, setFrontImage] = useState<string | undefined>();
  const [backImage, setBackImage] = useState<string | undefined>();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isProfileCreationModalVisible, setIsProfileCreationModalVisible] = useState(false);
  const [team, setTeam] = useState<UserTeamsList[0] | null>(null);
  const [roster, setRoster] = useState<Roster | null>(null);
  const [rosterDescription, setRosterDescription] = useState<string>('');
  const [activeTab, setActiveTab] = useState('first');

  const isComplete = frontImage && backImage && rosterDescription;

  return (
    <>
      <Container>
        <OwnFranchiseHeader />
        <Card>
          <CardBody>
            <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k || 'first')}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Stack gap={2}>
                    <Stack direction="vertical" gap={4} className="justify-content-center align-items-center">
                      <div className="a_color franchise helmet-nft-card">
                        {team && <RenderHelmet {...team} size={200}/>}
                      </div>

                      <h4 className="purchase-trophy__header mb-4">
                        {tabTitle}{" "}
                        <InfoCircleFilled onClick={() => setIsInfoModalVisible(true)} />
                      </h4>
                    </Stack>
                    <Row>
                      <Col xl={7} lg={7}>
                        <RosterImageBuilder
                          team={team}
                          roster={roster}
                          setTeam={setTeam}
                          setRoster={setRoster}
                          setFrontImage={setFrontImage}
                          setRosterDescription={setRosterDescription}
                        />
                      </Col>
                      <Col xl={5} lg={5} className="d-flex justify-content-center">
                        {team && roster && <ImageFlip imageFront={frontImage} imageBack={backImage} />}
                      </Col>
                    </Row>
                    {team && roster && <GenerateBackImage
                      roster={roster}
                      team={team}
                      rosterDescription={rosterDescription}
                      setBackImage={setBackImage}
                      setIsProfileCreationModalVisible={setIsProfileCreationModalVisible}
                    />}
                    <BlueButton
                      text='Next'
                      disabled={!isComplete}
                      className="w-25 align-self-end"
                      onClick={() => setActiveTab('second')}
                    />
                  </Stack>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Stack gap={2}>
                    <h4 className="purchase-trophy__header mb-4">
                      {tabTitle}
                    </h4>
                    <Row>
                      <Col xl={7} lg={7} className="d-flex justify-content-center">
                        {team && roster && <ImageFlip imageFront={frontImage} imageBack={backImage} />}
                      </Col>
                      <Col xl={5} lg={5} className="d-flex justify-content-center">
                        <MintPlayerConnected />
                      </Col>
                    </Row>
                    <BlueButton
                      text='Back'
                      className="w-25 align-self-end"
                      onClick={() => setActiveTab('first')}
                    />
                  </Stack>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </CardBody>
        </Card>
      </Container>
      <MintRosterInfo
        open={isInfoModalVisible}
        onClose={() => setIsInfoModalVisible(false)}
      />
      <ProfileCreationModal
        roster={roster}
        rosterDescription={rosterDescription}
        setRosterDescription={setRosterDescription}
        open={isProfileCreationModalVisible}
        onClose={() => setIsProfileCreationModalVisible(false)}
      />
    </>
  );
};