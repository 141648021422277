import { getFullHelmets } from 'helper/helper';
import { useState } from 'react';
import { get } from 'redux/services/Api';

type Game =  {
  gid: number | null;
  teams: [number, number];
  status: string;
  score: Record<string, number>;
  helmets?: HelmetData;
};

type WeekDetails = {
  day_number: number;
  scheduledAt: Date | string;
  games: Game[];
}[];

type HelmetData = {
  engId: number;
  helmet: string;
  logo: string;
  userName: string;
  teamName: string;
  userId: number;
}[];

export const useWeekDetails = (weekId?: string) => {
  const [weekDetails, setWeekDetails] = useState<WeekDetails | null>(null);
  const [loading, setLoading] = useState(false);

  const loadWeekDetails = async () => {
    if (weekDetails) {
      setWeekDetails(null);
      return;
    }
    try {
      setLoading(true);
      const response = await get<WeekDetails>(`/engine/leagues/seasons/season/week/${weekId}/weekGames`);
      const preparedEngIds = response
        .map((d) => d.games.map((g) => g.teams))
        .flat().flat();

      if (preparedEngIds.length) {
        const helmetsData = await getFullHelmets(preparedEngIds);
        response.forEach((d) => {
          d.games.forEach((g) => {
            const gameHelmets = helmetsData.filter((h) => g.teams.includes(h.engId));
            const orderedGameHelmets = g.teams
            .map((t) => gameHelmets.find((h) => h.engId === t))
            .filter(Boolean) as HelmetData;
            if (orderedGameHelmets.length === 2) {
              g.helmets = orderedGameHelmets;
            }
          });
        });
      }
      setWeekDetails(response);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return { weekDetails, loadWeekDetails, loading };
};
