import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getTeamOfWeekData } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";

export default function useTeamWeakData() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamOfWeekData());
  }, []);

  const teamWeekData = useSelector((state: RootState) => state.gameEngine.teamOfWeek);

  return { teamWeekData };
}