import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTrophyAssets } from 'redux/actions/TrophyAssets/actions/GetTrophyAssets';
import { RootState } from 'redux/store';

export const useTrophyAssets = ({ doNotLoadData } = { doNotLoadData: false }) => {
  const dispatch = useDispatch();
  const trophyAssets = useSelector((state: RootState) => state.app.trophyAssets);

  useEffect(() => {
    if (!doNotLoadData) {
      dispatch(getTrophyAssets());
    }
  }, [doNotLoadData]);

  return trophyAssets;
};
