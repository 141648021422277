import { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { sendContactUsEmail } from "./../../redux/actions/AuthAction";

const ContactForm = () => {
  const dispatch = useDispatch();
  const appStateLoader = useSelector((state: RootState) => state.app.loader);
  const appStateSuccess = useSelector((state: RootState) => state.app.success);
  const appStateError = useSelector((state: RootState) => state.app.error);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const handleSubmit = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      reason === "" ||
      message === ""
    ) {
      setErrorMsg("All Fields Are Required");
      setShowMsg(false);
    } else {
      setShowMsg(true);
      setErrorMsg("");
      dispatch(sendContactUsEmail(firstName, lastName, email, reason, message));
    }
  };

  useEffect(() => {
    if (!appStateLoader && !appStateError && appStateSuccess) {
      console.log("showMsg=>>>>", showMsg);
      typeof appStateSuccess === 'string' && setSuccessMsg(appStateSuccess);
      setFirstName("");
      setLastName("");
      setEmail("");
      setReason("");
      setMessage("");
    }
  }, [appStateLoader, appStateSuccess, appStateError, showMsg]);

  useEffect(() => {
    if (errorMsg) {
      setShowMsg(false);
    }
  }, [showMsg, errorMsg]);

  return (
    <>
      <div className="contact_form">
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Select
              aria-label="Default select example"
              className="mb-3"
              value={reason}
              onChange={(e: any) => setReason(e.target.value)}>
              <option>Select Reason for Contacting Us</option>
              <option value="Technical Support">Technical Support</option>
              <option value="Minting Issues">Minting Issues</option>
            </Form.Select>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter Message"
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        {errorMsg && <p className="error">{errorMsg}</p>}
        {successMsg && showMsg && <p className="success">{successMsg}</p>}
        <button type="button" className="submitbtn" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default ContactForm;
