import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonPowerRankings } from "redux/actions/Season/actions/SeasonPowerRankings";
import { RootState } from "redux/store";

export const useSeasonPowerRankings = (gid?: string) => {
  const dispatch = useDispatch();
  const seasonPowerRankings = useSelector((state: RootState) => state.gameEngine.powerRankings);

  useEffect(() => {
    if (!gid) return;
    dispatch(getSeasonPowerRankings(gid));
  }, [gid]);

  return seasonPowerRankings;
};
