import { LeagueTeams } from 'components/Common/LeagueTeams/LeagueTeams';
import { useSeasonTeams } from './hooks/useSeasonTeams';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';

const limit = 6;

export const SeasonTeams: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();
  const { seasonTeams, handleNextPage, handlePrevPage, page, hasPrevPage } = useSeasonTeams(seasonInfo?.id, limit);

  if (!seasonInfo) {
    return null;
  }

  const numberOfTeams = seasonInfo.conferences.length === 1 ? 16 : 32;

  const hasNextPage = numberOfTeams > page * limit;

  return (
    <LeagueTeams
      teams={seasonTeams}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      isPrevPage={hasPrevPage}
      isNextPage={hasNextPage}
      numberOfTeams={numberOfTeams}
    />
  );
};
