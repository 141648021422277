import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserTutorialDisabled } from "redux/actions/AuthAction";
import { RootState } from "redux/store";

export const useGameJoinTutorial = () => {
  const dispatch = useDispatch();
  const resData = useSelector((state: RootState) => state.app.resData);

  const setTutorialsDisabled = useCallback((disabled: boolean) => {
    if (resData?.id) {
      dispatch(updateUserTutorialDisabled({ userId: resData?.id, disabled }))
    }
  }, [resData?.id])

  const disableTutorials = useCallback(() => setTutorialsDisabled(true), [setTutorialsDisabled]);
  const enableTutorials = useCallback(() => setTutorialsDisabled(false), [setTutorialsDisabled]);

  const currentValue = resData ? resData.tutorialDisabled : true;

  return { disableTutorials, enableTutorials, showTutorial: !currentValue}
}