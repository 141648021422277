/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import NavbarWithOutLogin from "../layout/WithOutLoginNavbar/NavbarWithOutLogin";
import NewPasswordForm from "./NewPasswordForm";
import Footer from "../Common/Footer/Footer";
import "./NewPasswordStyle.scss";

const NewPasswordMain = () => {
  return (
    <>
      <NavbarWithOutLogin login />
      <NewPasswordForm />
    </>
  );
};

export default NewPasswordMain;
