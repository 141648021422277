import Helmet from "../../assets/images/common/Helmets.png";

const DraftTaxiModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Helmet} alt="erroricon" />
                <p>THE DRAFT HAS CONCLUDED. YOU WILL BE AUTOMATICALLY DIRECTED TO YOUR ROSTER TO OFFICIALLY ADD THESE NEW PLAYERS. ROSTER SPOTS ARE LIMITED TO 53 ACTIVE AND 10 TAXI SQUAD.</p>
            </div>  
        </>
    );
}

export default DraftTaxiModal;