import React from 'react';
import { useImperativeHandle } from 'react';
import { GameEvent } from './GamePlayer';
import { useGrouppedMessages } from './hooks/useGroupedMessages';

interface QuaterProps {
  quatreMessage: Array<GameEvent>;
  quarterCount: number;
  quarterTitle?: string;
  downNum: number;
  ydsToGo: number;
  ydLine: number;
}

const Quater = React.forwardRef<any, QuaterProps>(({ quatreMessage, quarterCount, quarterTitle }, ref: any) => {
  const sendMessage = (eventMsg: any) => {
    console.log(`MESSAGE: ${eventMsg.message}`);
  };
  useImperativeHandle(ref, () => ({
    sendMessage,
  }));

  const { groupedMessages } = useGrouppedMessages(quatreMessage);

  return (
    <div className="quatermain">
      <h3>PLAY BY PLAY</h3>
      <div className="quaterSectionMain">
        {!!groupedMessages?.length &&
          groupedMessages.map((group, index) => (
            <div className="quaterSection" key={group[0].title + index}>
              <h6>{group[0].title}</h6>
              {group.reverse().map((message: any, i: number) => (
                <p key={`${message.text}-${i}`}>{message.text}</p>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
});

export default Quater;
