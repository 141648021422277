import { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getPlayerStats } from "redux/actions/GameEngineAction";
import { CareerTab } from "components/PlayerDetail/tabs/CareerTab";
import logo from "../../../../assets/images/common/gm_vertical_logo.png";
import { Roster } from "components/MintRoster/interface";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { Stack } from "react-bootstrap";
import { UserTeamsList } from "types";

type Props = {
  roster: Roster | null;
  team: UserTeamsList[0] | null;
  rosterDescription: string;
  setBackImage: (value: string | undefined) => void;
  setIsProfileCreationModalVisible: (value: boolean) => void;
};

const GenerateBackImage: React.FC<Props> = ({ roster, team, rosterDescription, setBackImage, setIsProfileCreationModalVisible }) => {
  const hiddenContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const playerData = useSelector(
    (state: RootState) => state.gameEngine.PlayerStats
  );

  const generateBackImage = async () => {
    if (hiddenContainerRef?.current) {
      const containerCanvas = await html2canvas(hiddenContainerRef.current, {
        height: 1100,
        useCORS: true,
      });

      const helmet = await html2canvas(window?.document.querySelector('.render-helmet-container') as HTMLDivElement, {
        height: 252,
        width: 200,
        useCORS: true,
      });

      const ctx = containerCanvas.getContext('2d');
      if (ctx) {
        const finalCanvas = document.createElement('canvas');
        if (finalCanvas) {
          finalCanvas.width = containerCanvas.width;
          finalCanvas.height = containerCanvas.height;
          const finalCtx = finalCanvas.getContext('2d');
          const logoImage = new Image();
          logoImage.src = logo;

          if (finalCtx) {
            finalCtx.fillStyle = '#ffffff';
            finalCtx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);
            finalCtx.drawImage(containerCanvas, 0, 200);
            Promise.resolve(finalCtx.drawImage(logoImage, 50, 25, 111, 98));
            finalCtx.font = 'bold 70px Arial';
            finalCtx.fillStyle = '#384c6c';
            finalCtx.textAlign = 'center';
            finalCtx.fillText(team?.teamName || '', finalCanvas.width / 2, 130);

            // Draw the helmet image at the top right corner
            const helmetX = finalCanvas.width - helmet.width - 50;
            const helmetY = -25;
            const helmetWidth = 350;
            const helmetHeight = 370;
            finalCtx.drawImage(helmet, helmetX, helmetY, helmetWidth, helmetHeight);

            const imageDataUrl = finalCanvas.toDataURL('image/png');
            setBackImage(imageDataUrl);
          }
        }
      }
    }
  };

  useEffect(() => {
    generateBackImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData, rosterDescription]);

  useEffect(() => {
    if (roster?.pid) {
      dispatch(getPlayerStats(roster?.pid?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roster?.pid]);

  return (
    <Stack>
      <Stack className="p-3" ref={hiddenContainerRef}>
        <CareerTab
          careerStats={playerData?.careerStats || {}}
          recentGames={playerData?.recentGames ?? []}
          awards={playerData?.award ?? []}
          statisticalAchievements={
            playerData?.playerStats?.statisticalAchievements || {}
          }
          className="text-center"
        />
        <p className={`${rosterDescription ? 'w-100' : 'd-none'}`}>{rosterDescription}</p>
      </Stack>
      <BlueButton text='Generate Profile' className="w-25" onClick={() => setIsProfileCreationModalVisible(true)} />
    </Stack>
  );
};

export default GenerateBackImage;