import { useLocation } from "react-router-dom";
import SEO from "./SEO";
import { useEffect, useState } from "react";
import { useScrollTop } from "helper/useScrollTop";

const BASE_STRING = "GM Dynasty | ";

const defaultTitle = "Play To Earn Gaming Action";
const defaultDescription =
  "Participate in a play-to-earn (P2E) gaming, where general managerial skills and football strategy are rewarded with real value assets.";

export const AutoSEO: React.FC = () => {
  const location = useLocation();
  const [title, setTitle] = useState(BASE_STRING + defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [path, setPath] = useState("");

  const handleUpdateData = (newTitle: string, description?: string) => {
    setTitle(BASE_STRING + newTitle);
    setDescription(description || defaultDescription);
  };

  useEffect(() => {
    if (location.pathname.indexOf("/") !== location.pathname.lastIndexOf("/")) {
      const pathes = location.pathname.split("/");
      setPath(`/${pathes[1]}`);
    } else {
      setPath(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    switch (path) {
      case "/EnteredLeague":
        handleUpdateData("Entered Game");
        break;

      case "/ownfranchise":
        handleUpdateData("My Teams");
        break;

      case "/league":
        handleUpdateData("Games");
        break;

      case "/leaderboard":
        handleUpdateData("Leaderboard");
        break;

      case "/setting":
        handleUpdateData("Settings");
        break;

      case "/news":
        handleUpdateData("News");
        break;

      case "/leagueelimination":
        handleUpdateData("Tournament");
        break;

      case "/leagueheadtohead":
        handleUpdateData("H2H Pregame");
        break;

      case "/fullplayerstat":
        handleUpdateData("Full Players Stats");
        break;

      case "/fullteamstat":
        handleUpdateData("Full Team Stats");
        break;

      case "/franchisedetail":
        handleUpdateData("Team Details");
        break;

      case "/playerdetail":
        handleUpdateData("Player Details");
        break;

      case "/stand":
        handleUpdateData("League Standings");
        break;

      case "/game-results":
        handleUpdateData("Game Results");
        break;

      case "/replay":
        handleUpdateData("Replay");
        break;

      case "/purchasefranchise":
        handleUpdateData(
          "Acquire A Franchise",
          "Be more than an arm-chair quarterback, own a piece of the action. Acquire a Franchise NFT and get in the game! A play to earn sports ecosystem."
        );
        break;

      case "/franchiseoverview":
        handleUpdateData("Team Overview");
        break;

      case "/customize-franchise":
        handleUpdateData("Customize Your Team");
        break;

      case "/teamcreate":
        handleUpdateData("Reveal Your Players");
        break;

      case "/invites":
        handleUpdateData("Invite A Friend");
        break;

      case "/about":
        handleUpdateData("About Us");
        break;

      case "/contact":
        handleUpdateData("Contact Us");
        break;

      case "/privacy":
        handleUpdateData("Terms And Privacy");
        break;

      case "/join-free-game":
        handleUpdateData("Try A Free Game");
        break;

      case "/learn-more":
        handleUpdateData(
          "Learn More",
          "Learn about becoming a franchise owner in this play to earn sports ecosystem. Get in the game!"
        );
        break;

      case "/gameplay":
        handleUpdateData("Gameplay");
        break;

      case "/purchase-trophy":
        handleUpdateData("Purchase A Trophy");
        break;

      case "/profile":
        handleUpdateData("User Profile");
        break;

      case "/group":
        handleUpdateData("Group Page");
        break;

      case "/friend-requests":
        handleUpdateData("Friend Requests");
        break;

      case "/group-requests":
        handleUpdateData("Group Requests");
        break;

      case "/group-edit":
        handleUpdateData("Group Edit");
        break;

      case "/group-create":
        handleUpdateData("Group Create");
        break;

      case "/":
        const isSignUpOpen =
          new URLSearchParams(location.search).get("signup") === "true";
        if (isSignUpOpen) {
          handleUpdateData("Sign Up");
        } else {
          handleUpdateData(defaultTitle);
        }

        break;
      case "/season":
        handleUpdateData("Season")
        break;

      default:
        handleUpdateData(defaultTitle);
        break;
    }
  }, [path, location.search]);

  useScrollTop([path]);

  return <SEO title={title} description={description} />;
};
