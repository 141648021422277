import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export function useCurrentCreatedTeam() {
  const createFranchiseData = useSelector(
    (state: RootState) => state.team.createFrenchiseData
  );

  const currentCreatedTeamId = createFranchiseData && createFranchiseData[0].id;

  const userTeamListData =
    useSelector((state: RootState) => state.team.userTeamsList);

  const isCreatedTeamRevealed =
    createFranchiseData &&
    createFranchiseData[0].id &&
    !!userTeamListData?.find(
      (team) => team.playerReveal > 0 && team.id === createFranchiseData[0].id
    );

  return {
    currentCreatedTeamId,
    isCreatedTeamRevealed,
  };
}
