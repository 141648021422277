import { notification } from "antd";
import { useEnterGame } from "components/LeagueEnter/hooks/useEnterGame";
import { BASE_URL } from "config";
import { getPrizePool } from "helper/helper";
import { useFeeRates } from "helper/useFeeRates";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailInvitation } from "redux/actions/AuthAction";
import {
  cleanCreateLeagueData,
  createLeague,
} from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import history from "routes/history";

export const useHandleCreateForm = (usersToInvite: string[], onClose?: () => void) => {
  const dispatch = useDispatch();
  const createLeagueData = useSelector(
    (state: RootState) => state.gameEngine.createLeagueRes
  );

  const leagueEnterFormData = useSelector(
    (state: RootState) => state.team.leagueEnterFormData
  );

  const userId = useSelector((state: RootState) => state.app.resData?.id);

  const { WALLET_TRANSACTION_FEE } = useFeeRates();

  const [numberOfTeams, setNumberOfTeams] = useState<number | null>(null);
  const [entryFee, setEntryFee] = useState<number | null>(null);
  const [wholeStartDate, setWholeStartDate] = useState<Moment | null>(null);
  const [paceOfPlay, setPaceOfPlay] = useState<string | null>("BACK-TO-BACK");
  const [pregameTime, setPregameTime] = useState<number | null>(5);
  const [gameMode, setGameMode] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [ratingRangeMin, setRatingRangeMin] = useState<number | null>(1);
  const [ratingRangeMax, setRatingRangeMax] = useState<number | null>(100);
  const [leagueType, setLeagueType] = useState<"H" | "E" | "S" | null>(null);
  const [trophyId, setTrophyId] = useState<number | null>(null);
  const [groupId, setGroupId] = useState<number | null>(null);

  const updateNumberOfTeams = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNumberOfTeams(getNumberValue(e.target.value));
  };

  const updateEntryFee = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEntryFee(getNumberValue(e.target.value));
  };

  const updatePaceOfPlay = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaceOfPlay(getStringValue(e.target.value));
  };

  const updatePregameTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPregameTime(getNumberValue(e.target.value));
  };

  const updateGameMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGameMode(getStringValue(e.target.value));
  };

  const updateRatingRangeMin = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRatingRangeMin(getNumberValue(e.target.value));
  };

  const updateRatingRangeMax = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRatingRangeMax(getNumberValue(e.target.value));
  };

  const updateLeagueType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLeagueType(getStringValue(e.target.value) as "H" | "E" | "S");
  };

  const updateTrophyId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTrophyId(getNumberValue(e.target.value));
  };

  const updateGroupId = (e: React.ChangeEvent<HTMLSelectElement> | number) => {
    if (typeof e === "number") {
      setGroupId(e);
      return;
    }
    setGroupId(getNumberValue(e.target.value));
    setTrophyId(null);
  };

  const notifyError = () => {
    notification.error({
      message: "All fields are required",
      placement: "bottomRight",
    });
  };

  const handleCreateGame = () => {
    switch (leagueType) {
      case "E": {
        if (
          numberOfTeams &&
          entryFee !== null &&
          entryFee !== undefined &&
          wholeStartDate &&
          paceOfPlay &&
          pregameTime &&
          gameMode &&
          name &&
          ratingRangeMin &&
          ratingRangeMax &&
          userId
        ) {
          const dispatchData: any = {
            name,
            fee: entryFee,
            ratingRangeMin,
            ratingRangeMax,
            numberOfTeams,
            startAt: new Date(wholeStartDate.toDate().setSeconds(0, 0)).toISOString(),
            pregameTime,
            gameMode: gameMode || "",
            paceOfPlay,
            type: "E",
            userId: +userId,
          };
          if (trophyId) {
            dispatchData.trophyId = trophyId;
          }
          if (groupId) {
            dispatchData.groupId = groupId;
          }
          dispatch(createLeague(dispatchData));

          return;
        }
        notifyError();

        break;
      }

      case "H": {
        if (
          entryFee !== null &&
          entryFee !== undefined &&
          gameMode &&
          name &&
          ratingRangeMin &&
          ratingRangeMax &&
          userId
        ) {
          const dispatchData: any = {
            name,
            fee: entryFee,
            ratingRangeMin,
            ratingRangeMax,
            gameMode: gameMode || "",
            type: "H",
            userId: +userId,
          };
          if (groupId) {
            dispatchData.groupId = groupId;
          }
          dispatch(createLeague(dispatchData));

          return;
        }
        notifyError();
        break;
      }

      default: {
        notifyError();
        break;
      }
    }
  };

  const dataCopy =
    createLeagueData && createLeagueData.gameData
      ? JSON.parse(JSON.stringify(createLeagueData.gameData))
      : {};

  const { joinGame } = useEnterGame({
    engId: leagueEnterFormData?.franchise?.engId,
    type: dataCopy?.type,
    gid: dataCopy?.gid,
    joinCode: dataCopy.joinCode
  });

  useEffect(() => {
    if (
      createLeagueData &&
      createLeagueData.success &&
      createLeagueData.gameData &&
      WALLET_TRANSACTION_FEE !== null &&
      WALLET_TRANSACTION_FEE !== undefined &&
      dataCopy
    ) {
        const payoutPct =
        dataCopy?.type === "H"
          ? dataCopy?.payoutPct
          : dataCopy?.type === "E"
          ? 100 - WALLET_TRANSACTION_FEE
          : 0;
      const prizePool = getPrizePool(
        dataCopy?.fee,
        payoutPct,
        dataCopy?.numberOfTeams
      );
      const joinCode = dataCopy?.joinCode;
      const joinCodeWithType =
        joinCode && dataCopy?.type ? `${dataCopy?.type}-${joinCode}` : "";
      const SecondUserLink =
        joinCode && dataCopy && dataCopy?.gid
          ? `${BASE_URL}/league?joincode=${joinCodeWithType}&gid=${dataCopy?.gid}`
          : "";

      if (usersToInvite.length) {
        const emailData = {
          USERNAME: leagueEnterFormData?.franchise?.userName,
          LEAGUETYPE: dataCopy?.type,
          LEAGUENAME: dataCopy?.name,
          ENTRYFEE: dataCopy?.fee,
          PRIZEPOOL: prizePool,
          TEAMRATING: `${dataCopy?.ratingRangeMin} to ${dataCopy?.ratingRangeMax}`,
          leagueid: dataCopy?.gid || dataCopy?.seasonId,
          TO: usersToInvite,
          LINK: SecondUserLink,
        };
        dispatch(sendEmailInvitation(emailData));
      }
      if (entryFee === 0) {
        joinGame();
      } else {
        history.push("/leaguepayment", {
          ...dataCopy,
          leagueprice: dataCopy.fee,
          franchise: leagueEnterFormData?.franchise,
          prizePool: prizePool,
          firstUserJoincode: dataCopy.joinCode,
        });
      }

      dispatch(cleanCreateLeagueData());
    }
  }, [createLeagueData, WALLET_TRANSACTION_FEE, leagueEnterFormData?.franchise, onClose, dataCopy, usersToInvite, entryFee, dispatch, joinGame]);

  return {
    numberOfTeams,
    entryFee,
    wholeStartDate,
    paceOfPlay,
    pregameTime,
    gameMode,
    name,
    ratingRangeMin,
    ratingRangeMax,
    leagueType,
    trophyId,
    groupId,
    setName,
    updateNumberOfTeams,
    updateEntryFee,
    updatePaceOfPlay,
    updatePregameTime,
    updateGameMode,
    updateRatingRangeMin,
    updateRatingRangeMax,
    updateLeagueType,
    updateTrophyId,
    updateGroupId,
    handleCreateGame,
    createLeagueData,
    setWholeStartDate
  };
};

function getNumberValue(value: string) {
  const numberValue = Number(value);

  if (Number.isNaN(numberValue)) {
    return null;
  }

  return numberValue;
}

function getStringValue(value: string) {
  if (value === "null") {
    return null;
  }

  return value;
}
