import { specialFeatureImageSources, specialFeatures } from "helper/specialFeatureMapping";
import { SpecialFeatureCode } from "types";

type Props = {
  specialFeature: SpecialFeatureCode;
  withName?: boolean;
}

export const SpecialFeature: React.FC<Props> = ({ specialFeature, withName }) => {
  return (
    <div className="special_feature_container">
      <img
        src={specialFeatureImageSources[specialFeature]}
        alt={specialFeatures[specialFeature]}
      />
      {withName && specialFeatures[specialFeature]}
  </div>
  )
}