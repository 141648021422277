import { API_URL, BASE_URL } from "config";
import defaultCityImage from "../../../assets/images/franchise/cities/notSelected.png";

export type AvatarDataUnit = {
  colorName: string;
  created: string;
  id: number;
  name: string;
  type: "bg" | "city" | "helmet" | "knobs" | "logo";
  url: string;
};

export type PreviewData = {
  bg: AvatarDataUnit;
  city: AvatarDataUnit;
  helmet: AvatarDataUnit;
  knobs: AvatarDataUnit;
  logo: AvatarDataUnit;
};

type Props = {
  imageData: PreviewData;
  teamName: string;
  fanBase?: number | string;
};

export const Preview: React.FC<Props> = ({
  imageData,
  teamName,
  fanBase,
}) => {
  const logoPath = `${API_URL}${imageData.logo.url}`.replace(
    "/logo",
    "/logo/full"
  );
    const order = ["bg", "city", "helmet", "knobs", "logo"];

  return (
    <div className="captureAvatarDivP preview">
      <div id={"captureAvatarDiv"}>
        {order.map((item) => {
          if (item !== "logo") {
            let image = `${API_URL}${
              imageData[item as keyof PreviewData]?.url
            }`;
            if (
              item === "city" &&
              (imageData[item as keyof PreviewData]?.id === -1 ||
                imageData[item as keyof PreviewData]?.id === -2)
            ) {
              image = `${BASE_URL}${defaultCityImage}`;
            }

            return (
              <div className={`${item}-container`} key={item}>
                <img
                  src={image}
                  alt={item}
                  className="captureAvatarDiv-items"
                />
              </div>
            );
          }
        })}
        {imageData.logo.id !== -1 && (
          <img src={logoPath} alt="logo" className="position-absolute" />
        )}
        <h1 className="teamName">{teamName || "TEAM NAME"}</h1>
        <h1
          className={
            imageData.knobs.name === "ffffff"
              ? "teamRatingBlack"
              : "teamRatingWhite"
          }
        >
          {fanBase || "00"}
        </h1>
      </div>
    </div>
  );
};
