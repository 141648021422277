export type Award = {
   date: string
   gameId: string
   award: string
}

type Props = {
   playerAwards: Award[]
}

const PlayerAward = ({ playerAwards }: Props) => {
   if (!playerAwards.length) return <span>Player has not received any awards</span>
   return (
      <>
         {playerAwards.map(award => (
            <div>
               <span>{new Date(award?.date || 0).toLocaleDateString()}</span>
               &nbsp;&nbsp;|&nbsp;&nbsp;
               <span className='red-text'>{award?.gameId || 0}</span>&nbsp;&nbsp;&nbsp;&nbsp;
               <span>{award?.award || ''}</span>
            </div>
         ))}
      </>
   )
}

export default PlayerAward
