import Error from "../../../../../assets/images/common/error.svg";

const GeneralManagerNotAllow = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>Promotion to general manager is not allowed. you are only
                allowed 1 general manager. Demote or release the general
                manager prior to making a promotion.</p>
            </div> 
        </>
    );
}

export default GeneralManagerNotAllow;