import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import PlayerCareerStats, { CareerStats } from "../PlayerCareerStats";
import PlayerCareerRecentGame, { RecentGame } from "../PlayerCareerRecentGame";
import PlayerAward, { Award } from "../PlayerAward";
import { map, StatisticalAchievements } from "../StatisticalAchievements";
import { StatisticalAchievementsType } from "../types";

type Props = {
  careerStats: CareerStats;
  recentGames: RecentGame[];
  awards: Award[];
  statisticalAchievements: StatisticalAchievementsType;
  className?: string;
};

export const CareerTab: React.FC<Props> = ({
  careerStats,
  recentGames,
  awards,
  statisticalAchievements,
  className = '',
}) => {

  const hasAchievements = statisticalAchievements && statisticalAchievements?.type &&
    map[statisticalAchievements.type as keyof typeof map] && Object.keys(map[statisticalAchievements.type as keyof typeof map])?.some(
      key => !!statisticalAchievements[key as keyof typeof statisticalAchievements]
    );

  return (
    <Card>
      <CardBody>
        <div className={`playerDetailTab ${className}`}>
          <div className={`tab__section ${careerStats && Object.keys(careerStats)?.length ? '' : 'd-none'}`}>
            <h5 className="tab__section_heading">career stats</h5>
            <PlayerCareerStats careerStats={careerStats} />
          </div>
          <div className={`tab__section ${recentGames && recentGames?.length ? '' : 'd-none'}`}>
            <h5 className="tab__section_heading">recent games</h5>
            <PlayerCareerRecentGame recentGames={recentGames ?? []} />
          </div>
          <div className={`tab__section ${awards && awards?.length ? '' : 'd-none'}`}>
            <h5 className="tab__section_heading">awards</h5>
            <PlayerAward playerAwards={awards || []} />
          </div>
          <div className={`tab__section ${hasAchievements ? '' : 'd-none'}`}>
            <h5 className="tab__section_heading">statistical achievements</h5>
            <StatisticalAchievements
              statisticalAchievements={statisticalAchievements}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
