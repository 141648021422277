import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetGroup } from "redux/actions/Groups";

export function useFetchGroup(groupId?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (groupId) {
      dispatch(GetGroup(Number(groupId)));
    }
  }, [groupId]);
}
