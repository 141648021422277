import { notification } from "antd";
import { League } from "components/Common/LeagueHeader/LeagueHeader";
import { API_URL } from "config";
import { patch } from "redux/services/Api";

type gameVisibility = "public" | "private";

const setGameVisibility = (
  gid: number | string,
  type: League["type"],
  visibility: gameVisibility
) => {
  return patch(`${API_URL}/engine/games/setGameVisibility`, { gid, visibility, type });
};

export const handlePublicGame = async (gid: number | string, type: League["type"]) => {
  try {
    const response: any = await setGameVisibility(gid, type, "public");
    if (response?.message === "Game has been published!") {
        notification.success({
          message: "Success",
          description: response.message,
          placement: "bottomRight",
        });
    } else {
      throw new Error(response?.message);
    }
  } catch (error: any) {
    notification.error({
      message: "Error",
      description: error?.message || "Something went wrong",
      placement: "bottomRight",
    });
  }
};
