import { Modal } from "antd";
import "./PrestigeLevelAlertModal.scss";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";

type Props = {
  open: boolean;
  onClose: () => void;
  onChangePresigeLevel: () => void;
};

export const PrestigeLevelAlertModal: React.FC<Props> = ({
  open,
  onClose,
  onChangePresigeLevel,
}) => (
  <Modal
    open={open}
    width={"60vw"}
    centered
    footer={null}
    className="prestige-level-alert-modal"
    onCancel={onClose}
  >
    <p className="font18 pink"><strong>SELECTIONS REQUIRE UPDATING</strong></p>
    <p className="font18">
      The features (corners, trophy and/or textures) selected exceed the
      Prestige level you have chosen. You will need to make new feature
      selections or adjust the Prestige level higher. Click on the button
      below to automatically adjust the Prestige to match the level of the
      features selected.
    </p>
    <BlueButton className="mx-auto my-0"text="ADJUST PRESTIGE LEVEL" onClick={onChangePresigeLevel} />
  </Modal>
);
