import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetGroupToCreateLeague } from "redux/actions/Groups";
import { RootState } from "redux/store";

export const useGroupsForLeagues = (userId?: number) => {
  const dispatch = useDispatch();
  const groupsForLeagues = useSelector((state: RootState) => state.group.groupsForLeagues);

  useEffect(() => {
    if (userId) {
      dispatch(GetGroupToCreateLeague(userId));
    }
  }, [userId]);

  return groupsForLeagues || [];
};
