import { ActionsCallbacks } from "types";
import {
  FriendsList,
  FriendshipsStatus,
  UpdateNetworkStatus,
} from "types/friendships";
import { Dispatch } from "redux";
import { Api } from "../../../../libs/ws/Api";
import { FriendsActionTypes, FriendsActions } from "./action.types";
import store, { RootState } from "redux/store";
import { get } from "redux/services/Api";
import { UpdateGroup } from "redux/actions/Groups";
import { Group } from "types/groups";
import { WSResponseHandler } from "redux/actions/WSResponseHandler/WSResponseHandler";

export const GetFriends = (id?: number) => {
  return async (dispatch: Dispatch, getState: () => RootState, api: Api) => {
    try {
      const isFriendsLoading = getState().profile.loaders.friends;
      const ID = id ? id : getState().app?.resData?.id;
      if (!ID || isFriendsLoading) return;
      dispatch({ type: FriendsActionTypes.GET_FRIENDS_REQUEST });
      const response = await get<any>(
        `/user/friends-with-requests/${ID}`,
      );
      WSResponseHandler({
        event: "getFriends",
        ...response.data,
      });
      
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: FriendsActionTypes.GET_FRIENDS_FAIL,
          error: error.message,
        });
      }
      dispatch({
        type: FriendsActionTypes.GET_FRIENDS_FAIL,
        error: "Something went wrong",
      });
      return Promise.reject(error);
    }
  };
};

export const SendFriendInvite = (friendId: number, cbs?: ActionsCallbacks) => {
  return async (
    dispatch: Dispatch<FriendsActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: FriendsActionTypes.FRIEND_INVITE_REQUEST });
      await api.ready;
      api.api.friends.sendFriendshipRequest(friendId);
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: FriendsActionTypes.FRIEND_INVITE_FAIL,
          error: error.message,
        });
        return;
      }
      dispatch({
        type: FriendsActionTypes.FRIEND_INVITE_FAIL,
        error: "Something went wrong",
      });
    }
  };
};

export const UpdateFriendInvite = (
  friendId: number,
  newStatus: FriendshipsStatus,
  cbs?: ActionsCallbacks
) => {
  return async (
    dispatch: Dispatch<FriendsActions>,
    getState: () => RootState,
    api: Api
  ) => {
    try {
      dispatch({ type: FriendsActionTypes.UPDATE_FRIEND_INVITE_REQUEST });

      await api.ready;

      api.api.friends.updateFriendship(
        friendId,
        newStatus
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: FriendsActionTypes.UPDATE_FRIEND_INVITE_FAIL,
          error: error.message,
        });
      }
      dispatch({
        type: FriendsActionTypes.UPDATE_FRIEND_INVITE_FAIL,
        error: "Something went wrong",
      });
    }
  };
};

export const UpdateFriends = (friends: FriendsList) => {
  return async (dispatch: Dispatch<FriendsActions>) => {
    dispatch({
      type: FriendsActionTypes.UPDATE_FRIENDS,
      payload: {
        friends: friends.friends,
        friendsRequests: friends.friendsRequests,
      },
    });
  };
};

export const UpdateFriendNetworkStatus = (data: UpdateNetworkStatus) => {
  return async (dispatch: Dispatch<FriendsActions>) => {
    dispatch({
      type: FriendsActionTypes.UPDATE_FRIEND_NETWORK_STATUS,
      payload: { ...data },
    });
  };
};

export const HandleUpdateFriends = (friends: FriendsList) => {
  store.dispatch(UpdateFriends(friends));
};
export const HandleUpdateFriendNetworkStatus = (data: UpdateNetworkStatus) => {
  store.dispatch(UpdateFriendNetworkStatus(data));
};

export const HandleUpdateGroup = (group: Group) => {
  store.dispatch(UpdateGroup(group));
}

export const HandleUploadFriends = () => {
  store.dispatch(GetFriends());
};
