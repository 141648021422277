import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import FullPlayerStatMain from "../../components/FullPlayerStat/FullPlayerStatMain";

const FullPlayerStatScreen: React.FC = () => (
    <div className="pagelayout" >
        <WithLoginNavbar />
        <FullPlayerStatMain />
    </div>
);

export default FullPlayerStatScreen;