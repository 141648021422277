import { FoundUserProfile } from 'components/Group/GroupInvite/FoundUserProfile';
import Input from 'components/Common/Form/Input/Input';
import { FoundUser } from 'types/groups';
import PinkButton from '../Button/PinkButton/PinkButton';
import { UserSearchTemplateProps } from './UserSearch';
import { Typography } from 'antd';

// @ts-ignore
export const DefaultUserSearchInput = ({ value, onChange }: Pick<RenderUserSearchProps, 'value' | 'onChange'>) => {
  return (
    <div>
      <Input
        type="text"
        label={'SEARCH USER'}
        placeholder="ENTER GM DYNASTY USERNAME"
        value={value}
        onChange={onChange}
        controlId="find-existing-players"
        classNameForm="mb-4"
      />
    </div>
  );
};

export const DefaultInviteUserList = <T extends FoundUser>({
  users,
  onInviteClick,
  invitedIds,
  renderUsersListLabel = () => <Typography className="search_users_list__label">Invite your friends</Typography>,
}: Omit<UserSearchTemplateProps<T>['renderUsersList'], 'render'>) => {
  const getTextIsDisabled = (userId: number) => {
    return { text: invitedIds.includes(userId) ? 'INVITED' : 'INVITE', isDisabled: invitedIds.includes(userId) };
  };

  const onItemClick = (userId: number) => () => {
    return onInviteClick(userId);
  };

  if (!users || users.length === 0) {
    return null;
  }

  return (
    <>
      <div className="search_users_list__label_wrapper">{renderUsersListLabel && renderUsersListLabel()}</div>
      <div className={`search_users_list__items_wrapper search_users_list__items_wrapper--active`}>
        {users.map((user) => (
          <DefaultInviteUserListItem key={user.id} user={user} onInviteClick={onItemClick(user.id)} {...getTextIsDisabled(user.id)} />
        ))}
      </div>
    </>
  );
};

export interface DefaultInviteUserListItemProps<T extends FoundUser> {
  user: T;
  onInviteClick: () => void;
  text: string;
  isDisabled: boolean;
}

export const DefaultInviteUserListItem = <T extends FoundUser>({ user, onInviteClick, text, isDisabled }: DefaultInviteUserListItemProps<T>) => {
  return (
    <div className="search_user__row" key={user.id}>
      <div>
        <FoundUserProfile key={user.id} data={user} />
      </div>
      <div>
        <PinkButton text={text} onClick={onInviteClick} disabled={isDisabled} />
      </div>
    </div>
  );
};
