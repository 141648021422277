import { API_URL } from "config";
import { useTrophyAssets } from "../../hooks/useTrophyAssets";
import "./TrophyPreview.scss";

export const PATH_TO_TROPHY_FOLDER = `${API_URL}/images/trophyAssets/`;

type Props = {
  trophyName: string;
  groupName: string;
  prestigePoints?: number | string;
  bgPath?: string;
  texturePath?: string;
  cupPath?: string;
  cornerPath?: string;
};

export const TrophyPreview: React.FC<Props> = ({
  trophyName,
  groupName,
  prestigePoints,
  bgPath,
  cornerPath,
  cupPath,
  texturePath,
}) => {
  const trophyAssets = useTrophyAssets({ doNotLoadData: true });

  if (!trophyAssets) {
    return null;
  }

  const bgPathToUse = bgPath
    ? `${PATH_TO_TROPHY_FOLDER}${bgPath}`
    : `${PATH_TO_TROPHY_FOLDER}${trophyAssets.bg[0].filename}`;
  const texturePathToUse = texturePath
    ? `${PATH_TO_TROPHY_FOLDER}${texturePath}`
    : `${PATH_TO_TROPHY_FOLDER}${trophyAssets.texture[0].filename}`;
  const cupPathToUse = cupPath
    ? `${PATH_TO_TROPHY_FOLDER}${cupPath}`
    : `${PATH_TO_TROPHY_FOLDER}${trophyAssets.cup[0].filename}`;
  const cornerPathToUse = cornerPath
    ? `${PATH_TO_TROPHY_FOLDER}${cornerPath}`
    : `${PATH_TO_TROPHY_FOLDER}${trophyAssets.corner[4].filename}`;

  return (
    <div className="trophy-preview">
      <img src={bgPathToUse} alt="bg" />
      <img src={texturePathToUse} alt="texture" />
      <img src={cupPathToUse} alt="cup" />
      <img src={cornerPathToUse} alt="corner" />
      <div className="trophy-and-group-name-container">
        <span className="trophy-name">{trophyName}</span>
        <span className="group-name">{groupName}</span>
      </div>
      <div className="prestige-points">{prestigePoints || ""}</div>
    </div>
  );
};
