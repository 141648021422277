type MessageInputProps = {
  message: string;
  handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function MessageInput({
  message,
  handleMessageChange,
}: MessageInputProps) {
  return (
    <div className="msg_input_container">
      <label htmlFor="emails-message-input">Message:</label>
      <input
        value={message}
        onChange={handleMessageChange}
        placeholder="I am going to destroy you!"
        type="text"
        id="emails-message-input"
        autoComplete="off"
      />
    </div>
  );
}
