/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import NavbarWithOutLogin from "../layout/WithOutLoginNavbar/NavbarWithOutLogin";
import HomeBanner from "./HomeBanner";
import HomeContent from "./HomeContent";
import "./HomeStyle.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import LoadingScreen from "../Common/LoadingScreen/LoadingScreen";

const HomeMain: React.FC = () => {
  const loadingState = useSelector((state: RootState) => state.app.loader);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {(!!loadingState || loading) && <LoadingScreen />}
      <NavbarWithOutLogin login setLoading={setLoading} />
      <HomeBanner setLoading={setLoading} />
      <HomeContent />
    </>
  );
};

export default HomeMain;
