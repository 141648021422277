import { useEffect, useState } from "react";

const useCountdown = (targetDate?: Date | string) => {
  const countDownDate = new Date(targetDate || new Date()).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);
  if (!targetDate) return ["0", "00"];

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000).toLocaleString(
    undefined,
    {
      minimumIntegerDigits: 2,
      minimumFractionDigits: 0,
    }
  );

  return [minutes, seconds];
};

export { useCountdown };
