import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import history from '../../../routes/history'

export enum TabEnum {
    CAREER = "career",
    PROGRESSION = "progression",
    PROFILE = "profile"
}

export function useTabs(playerId: string): { tab: TabEnum; handleTabChange: (key: TabEnum) => void } {
   const location = useLocation()
   const queryParams = new URLSearchParams(location.search)
   const tabParam = queryParams.get('tab')
   const [tabKey, setTabKey] = useState<TabEnum>(validatedTab(tabParam) ?? TabEnum.CAREER)
   const handleTabChange = (key: TabEnum) => setTabKey(key)

   useEffect(() => {
      history.replace(`/playerdetail/${playerId}?tab=${tabKey}`)
   }, [tabKey, playerId])

   return { tab: tabKey, handleTabChange }
}

function validatedTab(queryTab: string | null): TabEnum | null {
   if (!queryTab) return null
   const validOptions = Object.values(TabEnum) as string[]
   const isValid = validOptions.includes(queryTab)
   return isValid ? (queryTab as TabEnum) : null
}