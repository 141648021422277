import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { PowerRanking, RawPowerRanking } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';

export const getEliminationPowerRankings = (gid: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_ELIMINATION_POWER_RANKINGS_REQUEST,
        payload: {},
      });

      const res: RawPowerRanking[] = await get(`/engine/games/elimination/${gid}/eliminationPowerRankings`);

      const preparedData: PowerRanking[] = res
        .map((rawData, index) => ({ ...rawData, change: rawData.rankings - rawData.prev, key: index + 1 }))
        .sort((a, b) => b.change - a.change);

      dispatch({
        type: types.GET_ELIMINATION_POWER_RANKINGS_SUCCESS,
        payload: { eliminationPowerRankings: preparedData },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ELIMINATION_POWER_RANKINGS_FAIL,
        error: error,
      });
    }
  };
};
