/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './LeagueOverviewStyle.scss';
import history from '../../../routes/history';

export type LeaderProps = {
  leaguename: string;
  leagueperson: string;
  leaguedigit: string | number;
  leaguedigitname: string;
  leagueimage: string;
  id: number;
};

const LeagueOverviewLeader: React.FC<LeaderProps> = (props) => (
  <div className="leaderlist">
    <div className="leaderlistsection">
      <img src={props.leagueimage} alt="stickers" style={{ cursor: 'pointer' }} onClick={() => history.push(`/playerdetail/${props?.id}`)} />
      <div>
        <h5>{props.leaguename}</h5>
        <a href={`/playerdetail/${props.id}`}>{props.leagueperson}</a>
      </div>
      <div className="leaderlistdigit">
        <div>
          <h5>{Math.round(+props.leaguedigit) || '0'}</h5>
          <h6>{props.leaguedigitname}</h6>
        </div>
      </div>
    </div>
  </div>
);

export default LeagueOverviewLeader;
