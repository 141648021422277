import { EliminationTreeValue } from "types";

export const emptyTree: EliminationTreeValue[][] = [
  [
    {
      gid: null,
      teams: [1, null],
    },
    {
      gid: null,
      teams: [2, 3],
    },
    {
      gid: null,
      teams: [4, 5],
    },
    {
      gid: null,
      teams: [null, 6],
    },
  ],
  [
    {
      gid: null,
      teams: [1, 34],
    },
    {
      gid: null,
      teams: [45, 6],
    },
  ],
  [
    {
      gid: null,
      teams: [134, 456],
    },
  ],
];

export const emptyTreeTeamNames: Record<string, string> = {
  1: 'Team 1',
  2: 'Team 2',
  3: 'Team 3',
  4: 'Team 4',
  5: 'Team 5',
  6: 'Team 6',
  34: 'Team 3 or 4',
  45: 'Team 4 or 5',
  134: 'Team 1 or 3 or 4',
  456: 'Team 4 or 5 or 6',
};
