import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import SeasonActions from 'redux/actions/Season/actions';
import { useEffect } from 'react';

export const useSeasonConferenceStats = (seasonId: string) => {
  const dispatch = useDispatch();
  const stats = useSelector((state: RootState) => state.gameEngine.conferenceStats);

  const loadConferenceStats = () => {
    if (!seasonId) return;
    dispatch(SeasonActions.getSeasonTeamStats(seasonId));
  };

  useEffect(() => {
    loadConferenceStats();
  }, []);

  return { stats: stats || [] };
};
