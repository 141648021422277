import { Props } from "../BlueButton/BlueButton";
import "./PinkButtonStyle.scss";

const PinkButton: React.FC<Props> = ({ text , onClick, disabled, ...props }) => (
    <button 
        {...props} 
        className={`pinkButton ${props.className || ''}`}
        onClick={onClick} 
        disabled={disabled}
    >
        {text}
    </button>
);

export default PinkButton;