import { Modal } from "antd";
import history from "../../../routes/history";
import { useCurrentCreatedTeam } from "../hooks/useCurrentCreatedTeam";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getTeamPlayersReveal } from "redux/actions/GameEngineAction";
import img from "../../../assets/images/ownFranchises/SPECIAL_OFFER.png";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import "../../Common/CreateFranchise/ApprovalModalStyle.scss";

type FranchiseSubmittedModalProps = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  isEmailVerified: boolean;
  resendEmailVerification: () => void;
};

export function FranchiseSubmittedModal({
  open,
  onOk,
  onCancel,
  isEmailVerified,
  resendEmailVerification,
}: FranchiseSubmittedModalProps) {
  const dispatch = useDispatch();

  const teamList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );
  
  const { currentCreatedTeamId, isCreatedTeamRevealed } =
    useCurrentCreatedTeam();

  const teamData = teamList?.find((team: { id: number, engId: number}) => team.id === +currentCreatedTeamId);

  const handleFranchiseReveal = () => {
    dispatch(getTeamPlayersReveal(teamData?.engId));
    history.push(`/teamcreate/${currentCreatedTeamId}`);
  };
  const handleRedirectToRoster = () => {
    history.push(`/franchiseoverview/${currentCreatedTeamId}?tab=roster`);
  };
  const handleInvites = () => {
    history.push(`/invites`);
  };

  const renderRevealButton = () => {
    if (currentCreatedTeamId && !isCreatedTeamRevealed) {
      return (
        <BlueButton text="PLAYER REVEAL" onClick={handleFranchiseReveal} />
      );
    }
    return (
      <BlueButton text="ROSTER" onClick={handleRedirectToRoster} />
    );
  };

  return (
    <Modal
      title="Basic Modal"
      className="draftcommon approvalmain"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      centered
      width="65vw"
    >
      <div className="startdraftmain franchisethankyou">
        <img src={img} alt="SPECIAL OFFER" />
        <p className="mb-4">
          Now that you have customized your team, lets get your players. Click the PLAYER REVEAL button to start the player allocation process. See what superstars are part of your roster! Want to get to the action quickly, click GAME PLAY and get out onto the field.
        </p>
        <p className="mb-4">
          Send out a challenge to your friends and family. Right now, you will receive <span className="pink">$10 in site credits</span> for every invited person who acquires a franchise, up to $100. 
        </p>
        <div
          className="franchisethankyou__buttons"
        >
          {renderRevealButton()}
          <BlueButton text="INVITE" onClick={handleInvites}/>
          <BlueButton text="GAME PLAY" onClick={() => {
            onCancel();
            history.push('/league');
          }} />
        </div>
      </div>
    </Modal>
  );
}
