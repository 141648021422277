import { roundNumberToGivenDigitsAfterDot } from "helper/helper";

export const commonTraits = [
  "height",
  "weight",
  "strength",
  "speed",
  "explosiveness",
  "agility",
];

const WR_RB = ["run", "rec", "block"];
const allDefense = ["pass_rush", "coverage", "run_stop", "tackling"];
const coaches_managers_common = [
  "offence",
  "defence",
  "special_teams",
  "experience",
];

export const traitsPerPos = {
  QB: ["pass", "run"],
  RB: WR_RB,
  WR: WR_RB,
  TE: ["run", "pass", "block"],
  OL: ["run_block", "pass_block"],
  DL: allDefense,
  LB: allDefense,
  CB: allDefense,
  S: allDefense,
  K: ["kick_power", "kick_accuracy"],
  P: ["punt_power", "punt_accuracy"],
  GM: coaches_managers_common,
  AC: coaches_managers_common,
  AG: coaches_managers_common,
  HC: coaches_managers_common,
};

export function PhysicalTrait({
  trait,
  value,
  pctl,
}: {
  trait: string;
  value: number;
  pctl: number;
}) {
  const borderColor =
    pctl > 85
      ? "#384c6c"
      : pctl > 65
      ? "#386c4d"
      : pctl > 35
      ? "#000"
      : pctl > 15
      ? "orange"
      : "red";

  return (
    <div className="physical_trait__container">
      <h5 className="title">{trait}</h5>
      <div className="circle" style={{ borderColor }}>
        {roundNumberToGivenDigitsAfterDot(value, 1)}
      </div>
      {Boolean(pctl) && <p className="subtitle">{pctl}% PCTL</p>}
    </div>
  );
}
