import { useEffect, useState } from "react";
import { useCurrentCreatedTeam } from "./useCurrentCreatedTeam";

export function useNewlyCreatedFranchiseModal() {
  const [isVisible, setIsVisible] = useState(false);

  const { currentCreatedTeamId, isCreatedTeamRevealed } =
    useCurrentCreatedTeam();

  useEffect(() => {
    if (currentCreatedTeamId && !isCreatedTeamRevealed) {
      setIsVisible(true);
    }
  }, [currentCreatedTeamId, isCreatedTeamRevealed]);

  const handleClose = () => setIsVisible(false);

  return { isVisible, handleClose };
}
