import { TrophyAssetsActionTypes } from "..";
import { get } from "../../../services/Api";
import { API_URL } from "../../../../config";
import { SimpleUserTrophy, TrophyAssetsObject } from "../types";

export function getTrophyAssets() {
  return async (dispatch: any) => {
    dispatch({
      type: TrophyAssetsActionTypes.GET_TROPHY_ASSETS_REQUEST,
      payload: {},
    });

    const data = await get<TrophyAssetsObject>(
      `${API_URL}/user/get-trophy-assets`
    );

    dispatch({
      type: TrophyAssetsActionTypes.GET_TROPHY_ASSETS_SUCCESS,
      payload: { trophyAssets: data },
    });
    try {
    } catch (error: any) {
      dispatch({
        type: TrophyAssetsActionTypes.GET_TROPHY_ASSETS_FAIL,
        error: error?.response?.data?.error ?? "Unexpected error",
      });
    }
  };
}

export function getUserTrophyList(userId: number, groupId?: number) {
  return async (dispatch: any) => {
    dispatch({
      type: TrophyAssetsActionTypes.GET_USER_TROPHIES_REQUEST,
      payload: {},
    });

    const data = await get<SimpleUserTrophy[]>(
      `${API_URL}/user/get-user-trophies/${userId}?key=ownerId&groupId=${groupId}`
    );

    dispatch({
      type: TrophyAssetsActionTypes.GET_USER_TROPHIES_SUCCESS,
      payload: { userTrophies: data },
    });
    try {
    } catch (error: any) {
      dispatch({
        type: TrophyAssetsActionTypes.GET_USER_TROPHIES_FAIL,
        error: error?.response?.data?.error ?? "Unexpected error",
      });
    }
  };
}

export function getUserTrophyListWithPagination(
  userId: number,
  page: number,
  limit: number
) {
  return async (dispatch: any) => {
    dispatch({
      type: TrophyAssetsActionTypes.GET_USER_TROPHIES_PAGINATION_REQUEST,
      payload: {},
    });

    const data = await get<{
      trophies: SimpleUserTrophy[];
      hasNextPage: boolean;
    }>(
      `${API_URL}/user/get-trophies-with-pagination/${userId}?page=${page}&limit=${limit}`
    );

    dispatch({
      type: TrophyAssetsActionTypes.GET_USER_TROPHIES_PAGINATION_SUCCESS,
      payload: { userTrophiesPagination: data },
    });
    try {
    } catch (error: any) {
      dispatch({
        type: TrophyAssetsActionTypes.GET_USER_TROPHIES_PAGINATION_FAIL,
        error: error?.response?.data?.error ?? "Unexpected error",
      });
    }
  };
}
