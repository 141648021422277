/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import PlayerRevealCarousel from "./PlayerRevealCarousel";
import { Slider } from "antd";
import { useEffect, useState } from "react";
import { RevealCompleteModal } from "./RevealCompleteModal";
import history from "./../../routes/history";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCompleteRevealModal } from "./hooks/use-complete-modal";
import { playerRevealApproval } from "redux/actions/TeamAction";
import { getTeamPlayersReveal } from "redux/actions/GameEngineAction";

const PlayerReveal: React.FC = () => {
  const dispatch = useDispatch();
  const { franchiseId }: { franchiseId: string | undefined } = useParams();
  const userTeamListData = useSelector(
    (state: RootState) => state.team.userTeamsList
  );
  const gameEngineStateLoader = useSelector(
    (state: RootState) => state.gameEngine.loader
  );
  const teamPlayersReveal = useSelector(
    (state: RootState) => state.gameEngine.teamPlayersReveal
  );
  const [sliderCurrentValue, setSliderCurrentValue] = useState<any>(7);
  const [dataListLength, setDataListLength] = useState(0);
  const [currentActiveIndex, setCurrentActiveIndex] = useState<any>(0);
  const [currentCarousel, setCurrentCarousel] = useState<any>(0);

  const [gmLength, setGmLength] = useState(0);
  const [gmCurrentIndex, setGmCurrentIndex] = useState(0);

  const [hcLength, setHcLength] = useState(0);
  const [hcCurrentIndex, setHcCurrentIndex] = useState(0);

  const handleRedirectToRoster = () => {
    dispatch(playerRevealApproval(franchiseId));
    history.push(`/franchiseoverview/${franchiseId}?tab=roster`);
  };

  const {
    isVisible: isCompleteModalVisible,
    handleOpen: handleCompleteModalOpen,
    handleClose: handleCompleteModalClose,
  } = useCompleteRevealModal(handleRedirectToRoster);

  const handleClose = () => {
    handleCompleteModalClose();
    dispatch(playerRevealApproval(franchiseId));
    history.push(`/franchiseoverview/${franchiseId}`);
  };
  const handleRedirectInvites = () => {
    dispatch(playerRevealApproval(franchiseId));
    history.push(`/invites`);
  };

  function onAfterChange(value: any) {
    setSliderCurrentValue(value);
  }

  useEffect(() => {
    let currentTeam = userTeamListData?.find(
      (info: any) => info.id == franchiseId
    );

    if (currentTeam?.playerReveal === 1) {
      // we redirect to roster if player reveal has been completed
      return handleRedirectToRoster();
    }

    if (currentTeam?.engId) {
      dispatch(getTeamPlayersReveal(currentTeam?.engId));
    }
  }, [franchiseId, userTeamListData]);

  useEffect(() => {
    if (
      dataListLength &&
      currentCarousel &&
      teamPlayersReveal?.length == currentCarousel + 1
    ) {
      handleCompleteModalOpen();
    }
  }, [dataListLength, currentCarousel, teamPlayersReveal]);

  return (
    <>
      <div className="playerreveal-main">
        {/* <img src={Background} alt="background" className="background-image" /> */}
        {!gameEngineStateLoader && (
          <div className="playerreveal-detail">
            <h4>ROSTER BEING ASSEMBLED</h4>
            <p>
              {currentActiveIndex} OF {dataListLength} PLAYERS &nbsp;|&nbsp;
              {hcCurrentIndex} OF {hcLength} COACHES &nbsp;|&nbsp;{" "}
              {gmCurrentIndex} OF {gmLength} GMs
            </p>
            <PlayerRevealCarousel
              speed={sliderCurrentValue}
              sliderCurrentValue={sliderCurrentValue}
              setDataListLength={setDataListLength}
              setCurrentActiveIndex={setCurrentActiveIndex}
              currentActiveIndex={currentActiveIndex}
              setGmLength={setGmLength}
              gmCurrentIndex={gmCurrentIndex}
              setGmCurrentIndex={setGmCurrentIndex}
              setHcLength={setHcLength}
              hcCurrentIndex={hcCurrentIndex}
              setHcCurrentIndex={setHcCurrentIndex}
              setCurrentCarousel={setCurrentCarousel}
            />
          </div>
        )}
        <div className="customplayer_speed">
          <div className="reveal_speed">
            <h6>PLAYER REVEAL SPEED</h6>
            <Slider
              defaultValue={sliderCurrentValue}
              min={2}
              max={10}
              //   tooltipVisible
              onAfterChange={onAfterChange}
              reverse={true}
              tooltip={{ open: false }}
              tipFormatter={null}
            />
          </div>
          <div onClick={handleCompleteModalOpen}>
            <a>SKIP</a>
          </div>
        </div>
      </div>
      <RevealCompleteModal
        handleRedirectToRoster={handleRedirectToRoster}
        handleRedirectInvites={handleRedirectInvites}
        open={isCompleteModalVisible}
        onCancel={handleClose}
      />
      {/* <Modal
        title=""
        className="playerreveal_complete"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        centered>
        <RevealCompleteModal setCloseCounter={setCloseCounter} />
      </Modal> */}
    </>
  );
};

export default PlayerReveal;
