import { Row , Col } from "react-bootstrap";
import "./ModalStyle.scss";
import franchiseimage from "../../../assets/images/importFranchise/franchiseImage.jpg";
import BlueButton from "../../Common/Button/BlueButton/BlueButton";

const SelectFranchise = () =>{
    const data = [
        {
            id: 1,
            franchiseimage: franchiseimage,
        },
        {
            id: 1,
            franchiseimage: franchiseimage,
        },
        {
            id: 1,
            franchiseimage: franchiseimage,
        },
    ]
    return(
        <>
            <div className="selectfranchisemain" >
                <h5 className="mb-3" >Select a franchise to import</h5>
                <Row>
                    {data.map((d: any, i:any) => (
                        <Col xl={4} lg={4} md={4} sm={4} >
                            <div className="franchisesection" >
                                <img src={franchiseimage} alt="franchise" />
                                <BlueButton text="IMPORT" />
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}

export default SelectFranchise;