import { Modal } from "antd";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { YouTubeEmded } from "components/YouTubeEmbed/YouTubeEmded";
import history from "routes/history";

type Props = {
  open: boolean;
}

export const TrialGamePopup: React.FC<Props> = ({
  open
}) => {
  return (
    <Modal
      centered
      open={open}
      footer={null}
      width=""
      closable={false}
    >
      <div className="trial-game-modal-container">
        <h4>Get in the Game!</h4>
        <YouTubeEmded 
          id="xyQHMjYJkxg"
          title="Get in the Game!"
          className="mb-4"
        />
        <BlueButton text="SIGN UP NOW!" onClick={() => history.push('/signup')}/>
      </div>
    </Modal>
  )
}