import { Spinner } from 'react-bootstrap';
import { usePlayoffInfo } from './hooks/usePlayoffInfo';
import ScheduleDiagram from 'components/LeagueOverviewElimination/TabDetail/ScheduleTab/ScheduleDiagram';
import finalistCupImage from '../../../../../../assets/images/leagueElimination/2dplacewinnercup.svg';
import winnerCup from '../../../../../../assets/images/leagueElimination/winnercup.svg';
import './PlayoffBracket.scss';
import { SuperBowl } from './SuperBowl';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';
import { emptyTree, emptyTreeTeamNames } from './constants';

type Props = {
  seasonId: string;
};


export const PlayoffBracket: React.FC<Props> = ({ seasonId }) => {
  const playoffInfo = usePlayoffInfo(seasonId);
  const { seasonInfo } = useSeasonInfo();

  if (playoffInfo === null) return <Spinner />;

  return (
    <div className="playoff-info">
      <div className="playoff-info__brackets">
        {playoffInfo.playoffs.map((p, i, a) => (
          <>
            <h6 className="mt-4">{seasonInfo?.conferences.find((c) => 'conferenceId' in p && c.id === p.conferenceId)?.title || `Conference ${i + 1} bracket`}</h6>
            <ScheduleDiagram
              helmets={'tree' in p ? p?.helmets : undefined}
              teamNames={'tree' in p ? p?.teamNames : emptyTreeTeamNames}
              tree={('tree' in p && p.tree.length) ? p?.tree : emptyTree}
              cupImage={a.length === 1 ? winnerCup : finalistCupImage}
              winnerText={a.length === 1 ? 'Champion' : `Finalist ${i + 1}`}
              schedule={'schedule' in p ? p.schedule : playoffInfo.playoffs[i] as Date[]}
            />
          </>
        ))}
      </div>
      <SuperBowl superBowl={playoffInfo?.superBowl} />
    </div>
  );
};
