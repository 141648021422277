import { ReactNode, useEffect, useRef } from "react";

type Props = {
  children: ReactNode;
};

const PlayersAnimation = ({ children }: Props) => {
  const playerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!playerRef.current) return;
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const moveAmount = scrollPosition * 0.25;
      const startingPosition = 460;
      if (playerRef?.current) {
        playerRef.current.style.transform = `translateY(${
          startingPosition - moveAmount
        }px)`;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <div ref={playerRef}>{children}</div>;
};

export default PlayersAnimation;
