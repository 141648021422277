import { TableProps } from "antd";

export const tableColumns: TableProps<any>['columns'] = [
  {
     title: "LEAGUE TYPE",
     dataIndex: "type",
     fixed: "left",
  },
  {
     title: "DATE",
     dataIndex: "date",
     align: "center",
  },
  {
     title: "FINAL SCORE",
     dataIndex: "finalScore",
     align: "center",
  },
  {
     title: "WINNER",
     dataIndex: "winner",
     align: "center",
  },
  {
     title: "LEAGUE ID",
     dataIndex: "leagueId",
     align: "center",
  },
];
