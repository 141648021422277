import "./BoxScoreRatingStyle.scss";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import { Row, Col, Nav } from "react-bootstrap";
import { Table } from "antd";
import BlueButton from "../Button/BlueButton/BlueButton";
import history from "./../../../routes/history";
import { useState, useEffect, useMemo } from "react";
import helmetBlue from "../../../assets/images/helmet/helmetBlue.png";
import helmetRed from "../../../assets/images/helmet/helmetRed.png";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { STATUS } from "../../LeagueHeadToHead/TabDetail/OverviewTab/useOverviewRating";
import football from "../../../assets/images/gamePlay/football.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export interface Props {
  data?: any;
  chartplan?: any;
  teamstat?: any;
  ratingtable?: any;
  gametime?: any;
  readyButtons?: any;
  watchingButton?: any;
  gameplayRedirect?: boolean;
  setDefaultPlan?: (plan: any) => void;
  isGamePlay?: boolean;
}
const BoxScoreRating = ({
  gameplayRedirect,
  data,
  chartplan,
  teamstat,
  ratingtable,
  gametime,
  readyButtons,
  watchingButton,
  setDefaultPlan,
  isGamePlay,
}: Props) => {
  const historyState: any = history?.location?.state;

  const teamsList = useSelector((state: RootState) => state.team.userTeamsList);

  const [Timmer, setTimmer] = useState<any>("10:00");
  const countDownTime = useMemo(() => {
    return new Date()?.getTime() + Number(data?.remainingTime) * 1000; // 10 minutes
  }, [data?.remainingTime]);

  useEffect(() => {
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownTime - now;
      const minutes = Math?.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math?.floor((distance % (1000 * 60)) / 1000);
      const formattedTime = `${minutes.toString()}:${seconds
        .toString()
        .padStart(2, "0")}`;
      setTimmer(formattedTime);
      if (
        gameplayRedirect &&
        (distance < 0 ||
          (data?.leftUserState &&
            data?.rightUserState &&
            data?.leftUserState === STATUS.READY &&
            data?.rightUserState === STATUS.READY))
      ) {
        clearInterval(x);
        if (data?.GID) history.push(`/gameplay?id=${data?.GID}`);
      }
    }, 1000);

    return () => clearInterval(x);
  }, [countDownTime]);
  const ratingColumns = [
    {
      title: "Q1",
      dataIndex: "q1",
    },
    {
      title: "Q2",
      dataIndex: "q2",
    },
    {
      title: "Q3",
      dataIndex: "q3",
    },
    {
      title: "Q4",
      dataIndex: "q4",
    },
    {
      title: "T",
      dataIndex: "t",
    },
  ];
  const ratingData: any[] = [
    {
      key: "1",
      q1: <>{data.team1q1}</>,
      q2: <>{data.team1q2}</>,
      q3: <>{data.team1q3}</>,
      q4: <>{data.team1q4}</>,
      t: <>{data.team1t}</>,
    },
    {
      key: "2",
      q1: <>{data.team2q1}</>,
      q2: <>{data.team2q2}</>,
      q3: <>{data.team2q3}</>,
      q4: <>{data.team2q4}</>,
      t: <>{data.team2t}</>,
    },
  ];

  if (data?.team1ot !== undefined && data?.team2ot !== undefined) {
    ratingColumns.splice(4, 0, {
      title: "OT",
      dataIndex: "ot",
    });
    ratingData[0].ot = data.team1ot;
    ratingData[1].ot = data.team2ot;
  }

  const leftPlayerUtid =
    data?.currentTeams?.[0]?.utid ??
    data?.currentTeams ??
    data?.leftPlayerUtid ??
    null;
  const rightPlayerUtid =
    data?.currentTeams?.[1]?.utid ??
    data?.currentTeams ??
    data?.rightPlayerUtid ??
    null;

  return (
    <>
      <div className="boxscoremain">
        <Card>
          <CardBody>
            <Row>
              <Col xl={3} lg={3} md={3}>
                <Link
                  to={`../../franchisedetail/${leftPlayerUtid}`}
                  className="a_color franchise"
                >
                  <div className="helmet">
                    <img
                      className="helmet__item helmet__item--left"
                      src={data.leftHelmet}
                      alt="helmet"
                      onError={(e) => {
                        e.currentTarget.src = helmetBlue;
                      }}
                    />
                    {data.leftHelmetLogo && (
                      <img
                        className="hidden"
                        src={data.leftHelmetLogo}
                        alt="helmet-logo"
                        onError={(e) => {
                          e.currentTarget.className = "hidden";
                        }}
                        onLoad={(e) => {
                          e.currentTarget.className =
                            "helmet__logo helmet__logo--left";
                        }}
                      />
                    )}
                  </div>
                  <div className="name-container">
                    <p className="franchise-name">{data.leftFranchiseName}</p>
                    {data?.teamNum == 0 && (
                      <img
                        className="own-football"
                        src={football}
                        alt="football"
                      />
                    )}
                  </div>
                </Link>
              </Col>
              <Col>
                <div className="teamratingmain">
                  <div className="leftsiderating">
                    <h4>{data.leftTeamRating}</h4>
                    <p>{isGamePlay ? "SCORE" : "TEAM RATING"}</p>
                  </div>
                  <div className="ratingtable">
                    {ratingtable && (
                      <Table
                        columns={ratingColumns}
                        pagination={false}
                        dataSource={ratingData}
                        size="small"
                        className="ratingsection"
                      />
                    )}
                    {gametime && (
                      <div className="gametime">
                        <h4>{Timmer}</h4>
                        <p>GAME TIME</p>
                      </div>
                    )}
                    {watchingButton && (
                      <div className="watching-text">
                        <p>
                          IN PROGRESS{" "}
                          <Link
                            className="watching-link"
                            to={`/gameplay?id=${data.GID}`}
                          >
                            WATCH
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="leftsiderating">
                    <h4>{data.rightTeamRating}</h4>
                    <p>{isGamePlay ? "SCORE" : "TEAM RATING"}</p>
                  </div>
                </div>
              </Col>
              <Col xl={3} lg={3} md={3}>
                <Link
                  to={`../../franchisedetail/${rightPlayerUtid}`}
                  className="a_color franchise"
                >
                  <div className="helmet">
                    <img
                      className="helmet__item helmet__item--right"
                      src={data.rightHelmet}
                      alt="helmet"
                      onError={(e) => {
                        e.currentTarget.src = helmetRed;
                      }}
                    />
                    {data.rightHelmetLogo && (
                      <img
                        className="hidden"
                        src={data.rightHelmetLogo}
                        alt="helmet-logo"
                        onError={(e) => {
                          e.currentTarget.className = "hidden";
                        }}
                        onLoad={(e) => {
                          e.currentTarget.className =
                            "helmet__logo helmet__logo--right";
                        }}
                      />
                    )}
                  </div>
                  <div className="name-container">
                    <p className="franchise-name">{data.rightFranchiseName}</p>
                    {data?.teamNum == 1 && (
                      <img
                        className="own-football"
                        src={football}
                        alt="football"
                      />
                    )}
                  </div>
                </Link>
              </Col>
            </Row>
            {chartplan && (
              <>
                <div className="schedulemain">
                  <Row className="justify-content-center">
                    <Col xl={7}>
                      <h5>{data.gameName}</h5>
                      <div className="schedulesection">
                        <Row className="justify-content-between">
                          <Col xl={3} lg={3} md={3}>
                            <div className="navigation-button">
                              <Nav.Link
                                eventKey="DEPTH-CHART"
                                onClick={() =>
                                  history.replace(`/leagueheadtohead?tab=DEPTH-CHART`, historyState)
                                }
                              >
                                <BlueButton text="DEPTH CHART" />
                              </Nav.Link>
                            </div>
                          </Col>
                          <Col>
                            <div className="scheduletimemain">
                              <div className="schedulechart">
                                <span>Depth Chart:</span>
                                <span>Game Plan:</span>
                                {data?.offensivePlanName &&
                                  data?.defensivePlanName && (
                                    <>
                                      <span>
                                        <strong>OFFENSE:</strong>
                                      </span>
                                      <span>
                                        <strong>DEFENSE:</strong>
                                      </span>
                                    </>
                                  )}
                              </div>
                              <div className="scheduleplan">
                                <span>
                                  {data.settingname}, {data.updatedate}
                                </span>
                                <span>
                                  {data.setting2name}, {data.update2date}
                                </span>
                                {data?.offensivePlanName &&
                                  data?.defensivePlanName && (
                                    <>
                                      <span className="plan-name">
                                        {data.offensivePlanName}
                                      </span>
                                      <span className="plan-name">
                                        {data.defensivePlanName}
                                      </span>
                                    </>
                                  )}
                              </div>
                            </div>
                          </Col>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            xl={3}
                            lg={3}
                            md={3}
                          >
                            <div className="navigation-button">
                              <Nav.Link
                                eventKey="GAME-PLAN"
                                onClick={() =>
                                  history.replace(`/leagueheadtohead?tab=GAME-PLAN`, historyState)
                                }
                              >
                                <BlueButton text="GAME PLAN" />
                              </Nav.Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {readyButtons &&
              data.leftButtonState !== undefined &&
              data.rightButtonState !== undefined ? (
              data?.isUserPlayingWithHimself ? (
                <div className="ready-section">
                  <Row className="justify-content-center">
                    <div className="ready-buttons">
                      <button
                        className="ready-button"
                        onClick={data.userReadyHandler}
                      >
                        LET'S PLAY!
                      </button>
                    </div>
                  </Row>
                </div>
              ) : (
                <>
                  <div className="ready-section">
                    <Row className="justify-content-center">
                      <Col xl={7}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="ready-buttons">
                            <button
                              className="ready-button"
                              disabled={data.leftButtonState}
                              onClick={data.userReadyHandler}
                              id={data.leftUserState === "LET'S PLAY" ? "letsplay-button" : ""}
                            >
                              {data.leftUserState || "Loading..."}
                            </button>
                          </div>

                          <div className="ready-buttons">
                            <button
                              className="ready-button"
                              disabled={data.rightButtonState}
                              onClick={data.userReadyHandler}
                              id={data.rightUserState === "LET'S PLAY" ? "letsplay-button" : ""}
                            >
                              {data.rightUserState || "Loading..."}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )
            ) : null}
            {teamstat && (
              <>
                <div className="schedulemain schedulemain1">
                  <Row className="justify-content-center">
                    <Col xl={7}>
                      <h5>{data.gameName}</h5>
                      <div className="teamstadate">
                        <span>{data.gamename12}</span>
                        {data.gamename12 && "|"}
                        <span>{data.gamedate}</span>
                        {data.gamedate && "|"}
                        <span>{data.gametime1}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {setDefaultPlan && (
              <Row className="justify-content-center">
                <Button
                  type="link"
                  onClick={setDefaultPlan}
                  className="linkbutton"
                >
                  Set as default game plan
                </Button>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default BoxScoreRating;
