/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import NavbarWithOutLogin from "../layout/WithOutLoginNavbar/NavbarWithOutLogin";
import Footer from "../Common/Footer/Footer";
import "../Signin/SigninStyle.scss";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordMain = ({ token }: any) => {
  return (
    <>
      <NavbarWithOutLogin login />
      <ResetPasswordForm token={token} />
    </>
  );
};

export default ResetPasswordMain;
