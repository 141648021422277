import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { PowerRanking, RawPowerRanking } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';

export const getSeasonPowerRankings = (gid: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_POWER_RANKINGS_REQUEST,
        payload: {},
      });

      const powerRankings = await get<RawPowerRanking[]>(`/engine/leagues/seasons/season/${gid}/powerRankings`);

      const preparedData: PowerRanking[] = powerRankings
        .map((rawData, index) => ({ ...rawData, change: rawData.rankings - rawData.prev, key: index + 1 }))
        .sort((a, b) => b.change - a.change);

      dispatch({
        type: types.GET_SEASON_POWER_RANKINGS_SUCCESS,
        payload: { powerRankings: preparedData },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_POWER_RANKINGS_FAIL,
        error: error,
      });
    }
  };
};
