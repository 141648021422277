// import "./LeagueOverviewStyle.scss";
import { PlayersStockReportPlayer } from "types";
import history from "../../../routes/history";
import Stickers from "../../../assets/images/franchiseOverview/teamLeaders.png";
import { getPositionFullName } from "helper/helper";


export interface Props {
  data: PlayersStockReportPlayer;
}

const PlayersStockReportPlayerLeader = ({ data }: Props) => {
  const leagueperson =  `${data?.firstName || 'UNKNOWN'} ${data?.lastName || 'UNKNOWN'}`;

  const leagueimage = data?.pos
    ? require(`../../../assets/images/player/${data?.pos}.png`)
    : Stickers;

  const diff = `${data?.diff > 0 ? '+' : ''}${data?.diff || 'N/A'}`;   

  return (
    <>
      <div className="leaderlist">
        <div className="leaderlistsection">
          <img
            src={leagueimage}
            alt="stickers"
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/playerdetail/${data?.pid}`)}
          />
          <div>
            <a href={`/playerdetail/${data?.pid}`}>{leagueperson}</a>
            <p>{getPositionFullName(data?.pos)}</p>
          </div>
          <div className="leaderlistdigit">
            <div>
              <h5>{diff}</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayersStockReportPlayerLeader;
