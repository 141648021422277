import "./ImportWalletConnectedStyle.scss";
import metaMask from "../../../assets/images/importFranchise/metaMask.svg";
import trustWallet from "../../../assets/images/importFranchise/trustWallet.svg";
import metamaskconnect from "../../../assets/images/wallet/metaMaskConnected.png";
import { Link } from "react-router-dom";

export interface Props {
    walletconnected? : any;
    connectwalletoption? : any;
}

const ImportWalletConnected = ({walletconnected , connectwalletoption} : Props) =>{
    return(
        <>
            {/* CONNECT WALLET OPTION START */}
            { connectwalletoption &&
                <div className="walletconnectedmain" >
                    <h5>CONNECT WALLET</h5>
                    <p>BEFORE YOU CAN PURCHASE A FRANCHISE, YOU WILL NEED TO CONNECT A WALLET (EWALLET). </p>
                    <div className="selectmain" >
                        <p>SELECT</p>
                        <div className="selectwallet">
                            <img src={metaMask} alt="metamask" className="metamask"  />
                            <img src={trustWallet} alt="fortmatic" className="trustwallet" />
                        </div>
                    </div>
                    <p className="mt-2" >NEW TO ELECTRONIC WALLETS? <a target="blank" href="https://learn.metamask.io/en-US" >LEARN</a></p>
                </div>
            }
            {/* CONNECT WALLET OPTION END */}

            {/* WALLET CONNECTED  */}
            { walletconnected &&
                <div className="walletconnectedmain" >
                    <h5 className="mb-4">WALLET CONNECTED</h5>
                    <img src={metamaskconnect} alt="metamask" className="mb-3" />
                    <ul>
                        <li className="wallethead" >WALLET ID :</li>
                        <li>0xC74E…30a7</li>    
                    </ul>
                    <ul>
                        <li className="wallethead" >ETH BALANCE :</li>
                        <li>0.300</li>    
                    </ul>
                </div>
            }
            {/* WALLET CONNECTED  */}
        </>
    );
}

export default ImportWalletConnected;
