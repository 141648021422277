/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import HowitWorksMain from "../../components/HowitWorks/HowitWorksMain";
import NavbarWithOutLogin from "../../components/layout/WithOutLoginNavbar/NavbarWithOutLogin";

const HowitWorksScreen = () => {
  return (
    <>
      <div className="pagelayout">
        <NavbarWithOutLogin about />
        <HowitWorksMain />
      </div>
    </>
  );
};

export default HowitWorksScreen;
