import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FoundUser } from 'types/groups';
import { useDebouncedValue } from 'helper/useDebouncedValue';
import './UserSearch.style.scss';
import { DefaultInviteUserListItem, DefaultUserSearchInput } from './DefaultUsersSearch';

interface UseSearchUsers<T extends FoundUser> {
  users: T[];
  handleSearch: (value: string) => void;
}

interface UserSearchProps<T extends FoundUser> {
  useSearchUsers: () => UseSearchUsers<T>;
  onUserClick: (userId: number) => void;
  renderUserRow?: (user: T) => React.ReactElement;
  renderInput?: (value: string, onChange: (value: string) => void) => React.ReactElement;
  renderTitle?: () => React.ReactElement;
  className?: string
  membersListClassName?: string
}

const UserSearch2 = <T extends FoundUser>({
  useSearchUsers,
  onUserClick,
  renderUserRow,
  renderInput,
  renderTitle,
  membersListClassName,
  className,
}: UserSearchProps<T>) => {
  const [userNameString, setUserNameString] = useState('');
  const debouncedSearch = useDebouncedValue(userNameString, 300);
  const { users, handleSearch } = useSearchUsers();

  useEffect(() => {
    handleSearch(debouncedSearch);
  }, [debouncedSearch]);

  const membersListClasses = users && users.length
    ? ['search_user__list', 'search_user__list--active']
    : ['search_user__list'];
  if (membersListClassName) membersListClasses.push(membersListClassName);

  const renderUsersItems = useMemo(() => {
    if (!users || users.length === 0) {
      return null;
    }

    return users.map((user) => {
      if (!renderUserRow) return (
        <DefaultInviteUserListItem
          key={user.id}
          user={user}
          onInviteClick={() => onUserClick(user.id)}
          text={'INVITE'}
          isDisabled={false}
        />
      )

      return renderUserRow(user)
    })
  }, [users]);

  const renderUsersList = useMemo(() => {
    if (!users || users.length === 0) {
      return null;
    }

    return (
      <Col md={12} className="search_user__list_container mb-3">
        <Col xs={12} className={membersListClasses.join(' ')}>
          {renderTitle && <div className="search_users_list__label_wrapper">{renderTitle()}</div>}
          <div className={`search_users_list__items_wrapper search_users_list__items_wrapper--active`}>
            {renderUsersItems}
          </div>
        </Col>
      </Col>
    )
  }, [renderUsersItems, users]);

  return (
    <Row className={className}>
      {renderInput
        ? renderInput(debouncedSearch, setUserNameString)
        : (
          <Col md={12}>
            <DefaultUserSearchInput onChange={setUserNameString} value={debouncedSearch} />
          </Col>
        )
      }
      {renderUsersList}
    </Row>
  )
};

export { type UseSearchUsers, UserSearch2, type UserSearchProps };
