/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./HomeStyle.scss";
import { Link } from "react-router-dom";
import introVideo from "../../assets/images/homeScreen/intro.mp4";
import SelectLoginModal from "../Common/SelectLoginModal/SelectLoginModal";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyEmail } from "../../redux/actions/TeamAction";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import WhiteButton from "components/Common/Button/WhiteButton/WhiteButton";
import { Announcement, getAnnouncements } from "./helpers";
import Marquee from "react-fast-marquee";
import history from "routes/history";
const HomeBanner = ({ setLoading }: any) => {
  const dispatch = useDispatch();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };
  const { token }: any = useParams();

  // Metamask Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (token) {
      showModal();
      dispatch(verifyEmail(token));
    }
  }, [token]);

  useEffect(() => {
    getAnnouncements().then((data) => {
      setAnnouncements(data);
    });
  }, []);

  return (
    <>
      <div className="bannerMain">
        {/*<Slider {...settings}>*/}
        {/* CAROUSEL 1 */}
        <div className="banner">
          <div className="slider1">
            <div className="videoWrapper">
              <video autoPlay muted loop playsInline>
                <source src={introVideo} type="video/mp4" />
                Your browser doesn't support video tag.
              </video>
              <div className="gradientOverlay"></div>
            </div>
            <div className="bannerInfoMain">
              <Container fluid={"sm"}>
                <div className="bannerInfo">
                  <h2>BUILD YOUR</h2>
                  <h2>FOOTBALL DYNASTY</h2>
                  <p>COMPETITIVE GAMING WHERE FOOTBALL STRATEGY</p>
                  <p>AND GENERAL MANAGERIAL SKILLS ARE REWARDED</p>
                  <Link to="/learn-more">
                    <WhiteButton text="LEARN MORE" />
                  </Link>
                </div>
              </Container>
              <div className="marquee">
                <Marquee>
                  {!!announcements?.length &&
                    announcements.map((el) => {
                      return (
                        <span
                          key={el.gid}
                          onClick={() => {
                            history.push(`/replay/${el.gid}`);
                          }}
                        >
                          <span>FINAL:</span> {el.message}
                        </span>
                      );
                    })}
                </Marquee>
              </div>
            </div>
          </div>
        </div>
        {/* CAROUSEL 2 */}
        {/*<div className="banner">*/}
        {/*  <div className="slider1 slider2">*/}
        {/*    <div className="bannerInfoMain">*/}
        {/*      <div className="bannerInfo">*/}
        {/*        <img src={gmlogo} alt="gmlogo" />*/}
        {/*        <h2>CALLING OUT ALL ARMCHAIR QUARTERBACKS</h2>*/}
        {/*        <p>THINK YOU CAN DO BETTER, PROVE IT!</p>*/}
        {/*        <Link to="/works">*/}
        {/*          <PinkButton text="HOW IT WORKS" />*/}
        {/*        </Link>*/}
        {/*        <a*/}
        {/*          href={"https://metamask.app.link/dapp/" + SiteUrl}*/}
        {/*          className="mobile_link"*/}
        {/*        >*/}
        {/*          <PinkButton text="CONNECT METAMASK" />*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* CAROUSEL 3 */}
        {/*<div className="banner">*/}
        {/*  <div className="slider1 slider3">*/}
        {/*    <div className="bannerInfoMain">*/}
        {/*      <div className="bannerInfo">*/}
        {/*        <img src={gmlogo} alt="gmlogo" />*/}
        {/*        <h2>COMPETITIVE PLAY ALL YEAR-ROUND</h2>*/}
        {/*        <p>USE YOUR FOOTBALL EXPERTISE & EARN PRIZES!</p>*/}
        {/*        <Link to="/legend">*/}
        {/*          <PinkButton text="BECOME A FOUNDER" />*/}
        {/*        </Link>*/}
        {/*        <a*/}
        {/*          href={"https://metamask.app.link/dapp/" + SiteUrl}*/}
        {/*          className="mobile_link"*/}
        {/*        >*/}
        {/*          <PinkButton text="CONNECT METAMASK" />*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* CAROUSEL 4 */}
        {/*<div className="banner">*/}
        {/*  <div className="slider1 slider4">*/}
        {/*    <div className="bannerInfoMain">*/}
        {/*      <div className="bannerInfo">*/}
        {/*        <img src={latherlogo} alt="gmlogo" />*/}
        {/*        <h2>LEATHER BUCKET HEADS</h2>*/}
        {/*        <p>OLD SCHOOL FOR THE NEW WORLD</p>*/}
        {/*        <Link to="/market">*/}
        {/*          <PinkButton text="LIMITED EDITION PLAYER NFTS" />*/}
        {/*        </Link>*/}
        {/*        <a*/}
        {/*          href={"https://metamask.app.link/dapp/" + SiteUrl}*/}
        {/*          className="mobile_link">*/}
        {/*          <PinkButton text="CONNECT METAMASK" />*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*</Slider>*/}
      </div>
      {/* DRAFT START */}
      <Modal
        title=""
        width="auto"
        className="metamask_connect"
        open={isModalVisible}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[]}
        centered
      >
        <SelectLoginModal setLoading={setLoading} />
      </Modal>
    </>
  );
};

export default HomeBanner;
