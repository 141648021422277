import { useState } from "react";

export function usePagination<T>(
   data: T[] | null,
   limit = 5
): {
   paginated: T[];
   page: number;
   hasNextPage: boolean;
   hasPreviousPage: boolean;
   setNextPage: () => void;
   setPreviousPage: () => void;
   hasPagination: boolean;
} {
   const hasPagination = !!data && data.length >= limit;
   const [page, setPage] = useState<number>(1);
   const pages = Math.ceil((data?.length ?? 0) / limit);
   const hasNextPage = page < pages;
   const hasPreviousPage = page > 1;
   const paginated = data ? data.slice((page - 1) * limit, page * limit) : [];
   const setNextPage = () => {
      if (hasNextPage) {
         setPage((v) => v + 1);
      }
   };
   const setPreviousPage = () => {
      if (hasPreviousPage) {
         setPage((v) => v - 1);
      }
   };
   return {
      paginated,
      page,
      hasNextPage,
      hasPreviousPage,
      setNextPage,
      setPreviousPage,
      hasPagination,
   };
}
