import { useState } from 'react'

export function useWarningModal(onSuccess?: () => void, onCancel?: () => void) {
   const [isModalVisible, setIsModalVisible] = useState(false)
   const showModal = () => {
      setIsModalVisible(true)
   }
   const handleOk = () => {
      setIsModalVisible(false)
      if (onSuccess) {
         onSuccess()
      }
   }
   const handleCancel = () => {
      if (onCancel) {
         onCancel()
      }
      setIsModalVisible(false)
   }
   return {
      isModalVisible,
      showModal,
      handleOk,
      handleCancel,
   }
}
