import { BASE_URL } from "config";
import { getStringDate } from "helper/helper";

type Key = { id: number, option: string, value: string | null};

const leagueType = [
  {
    id: 1,
    option: "Select league type",
    value: null
  },
  {
    id: 2,
    option: "ELIMINATION",
    value: "E",
  },
  {
    id: 3,
    option: "HEAD-TO-HEAD",
    value: "H",
  }
];


const numberOfTeams = [
  {
    id: 1,
    option: "Select number of teams",
    value: null
  },
  {
    id: 2,
    option: "4 TEAMS",
    value: 4,
  },
  {
    id: 3,
    option: "8 TEAMS",
    value: 8,
  },
  {
    id: 4,
    option: "16 TEAMS",
    value: 16,
  },
  {
    id: 5,
    option: "32 TEAMS",
    value: 32,
  },
];

const gameFee = [
  {
    id: 1,
    option: "Select game entry fee",
    value: null
  },
  {
    id: 2,
    option: "FREE GAME",
    value: 0,
  },
  
];

if (BASE_URL !== "https://www.gmdynasty.com") {
  gameFee.push(...[
    {
      id: 3,
      option: "$ 10",
      value: 10,
    },
    {
      id: 4,
      option: "$ 25",
      value: 25,
    },
    {
      id: 5,
      option: "$ 50",
      value: 50,
    },
    {
      id: 6,
      option: "$ 75",
      value: 75,
    },
    {
      id: 7,
      option: "$ 100",
      value: 100,
    },
  ]);
}

const todayDate = new Date();
const getFutureDate = (days:number)=> new Date(Date.now() + 1000 * 60 * 60 * 24 * days);
const getFormatedDate = (date:Date) => getStringDate(date);

const stringToday = getStringDate(todayDate);

let startDate = Array.from({length: 9}, (_, i) => i + 2).map((element) => {
  const date = getFutureDate(element - 1);
  const formattedDate = getFormatedDate(date);
  return {
    option: formattedDate,
    value: formattedDate,
    id: element + 1,
  }
});

// @ts-ignore
startDate = [{id: 1, option: "Select start date", value: null},{id: 2, option: stringToday, value: stringToday}, ...startDate];


const minutes = ["00", "15", "30", "45"];

const startTime: Key[] = [
  {
    id: 1,
    option: "Select start time",
    value: null
  },
]

for (let hour = 0; hour < 24; hour++) {
  minutes.forEach(minute => {
    startTime.push({
      id: hour + 2,
      option: (hour > 0 && hour < 13) 
        ? `${hour.toString().padStart(2, '0')}:${minute} AM` 
        :  hour === 0 
          ? `${12}:${minute} AM`
          : `${(hour - 12).toString().padStart(2, '0')}:${minute} PM`,
      value: `${hour.toString().padStart(2, '0')}:${minute}`,
    })
  })
}

const paseOfPlay = [
  {
    id: 1,
    option: "Select pace of play",
    value: null
  },
  {
    id: 2,
    option: "BACK-TO-BACK",
    value: "BACK-TO-BACK"
  },
  {
    id: 3,
    option: "10 MINUTES",
    value: 10
  },
  {
    id: 3,
    option: "15 MINUTES",
    value: 15
  },
  {
    id: 3,
    option: "30 MINUTES",
    value: 30
  },
  {
    id: 3,
    option: "60 MINUTES",
    value: 60
  },
];

const pregameTime = [
  {
    id: 1,
    option: "Select pregame duration",
    value: null
  },
  {
    id: 2,
    option: "5 MINUTES",
    value: 5
  },
  {
    id: 3,
    option: "10 MINUTES",
    value: 10
  },
  {
    id: 4,
    option: "15 MINUTES",
    value: 15
  },
  {
    id: 5,
    option: "30 MINUTES",
    value: 30
  },
]


const ratingValues: [{ id: number, option: string, value: number | null}] = [
  {
    id: 1,
    option: "Select range value",
    value: null
  },
];

for (let i = 1; i <= 100; i++) {
  ratingValues.push({
    id: i + 1,
    option: `${i}`,
    value: i
  })
}



const slicedTime = () => {
  const currentUTCDate = new Date();

  const hoursInUTC = currentUTCDate.getUTCHours();
  const minutesInUTC = currentUTCDate.getUTCMinutes();

  const times = startTime.filter(time => {
    if (!time.value) {
      return true;
    }
    const hour = +time.value.slice(0, 2);
    const minute = +time.value.slice(3, 5);

    return (hour > hoursInUTC) || (hour === hoursInUTC && minute > (minutesInUTC));
  });

  return times;
}

const getRatingValuesFrom = (ratingRangeMax: number | null) => {
  return ratingRangeMax 
    ? ratingValues.slice(0, ratingRangeMax) 
    : [...ratingValues];
}

const getRatingValuesTo = (ratingRangeMin: number | null) => {
  return ratingRangeMin 
    ? [...ratingValues.slice(0,1), ...ratingValues.slice(ratingRangeMin + 1)] 
    : [...ratingValues];
}

const getAvailableHours = (date: string | null) => {
  if (date === stringToday) {
    return slicedTime();
  } else {
    return [...startTime];
  }
}

export const dropdownKeys = {
  leagueType,
  numberOfTeams,
  gameFee,
  startDate,
  startTime,
  paseOfPlay,
  pregameTime,
  getAvailableHours,
  getRatingValuesFrom,
  getRatingValuesTo
}