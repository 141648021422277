import { Modal } from 'antd'

type FreeGameWarningModalProps = {
   open: boolean
   onOk: () => void
   onCancel: () => void
}

export function FreeGameWarningModal({ open, onCancel, onOk }: FreeGameWarningModalProps) {
   return (
      <Modal
         title='Basic Modal'
         className='draftcommon leaguerating'
         open={open}
         onOk={onOk}
         onCancel={onCancel}
         footer={false}
         width={500}
         centered
      >
         <div className='startdraftmain'>
            <h4>Get $50 on us!</h4>
            <div className='modal_wrapper'>
               <button className='cancel_button' onClick={onCancel}>
                  remind me later
               </button>
               <button className='success_button' onClick={onOk}>
                  deposit
               </button>
            </div>
         </div>
      </Modal>
   )
}
