import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./font.scss";
import App from "./App";
import { MetamaskStateProvider } from "use-metamask";
import ReactGA from "react-ga4";
import "./socket";

const container = document.getElementById("root")!;
const root = ReactDOM.createRoot(container);
const trackingId: any = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
ReactGA.initialize([
  {
    trackingId,
  }
]);

root.render(
  <React.StrictMode>
    <MetamaskStateProvider>
      <App />
    </MetamaskStateProvider>
  </React.StrictMode>
);
