import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetProfile } from "redux/actions/Profile";
import { RootState } from "redux/store";
import { Profile } from "types";

export interface ProfileState{
  profile:Profile|null;
  isLoading:boolean;
  error:string|null;
  isOwn:boolean;
}
export function useProfile(id?: number) {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.profile);
  const user = useSelector((state: RootState) => state.app.resData);

  useEffect(() => {
    if (!id) return;
    dispatch(GetProfile(id));
  }, [dispatch, id]);

  return {
    profile: state.data,
    isLoading: Boolean(state.loader),
    error: state.error,
    isOwn: user && state && state.data && state.data.id === user.id?true:false,
  };
}
