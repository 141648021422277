import PlayerReveal from './PlayerReveal';
import { Container } from 'react-bootstrap';
import './FranchiseTeamCreationStyle.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LoadingScreen from './../Common/LoadingScreen/LoadingScreen';
import OwnFranchiseHeader from '../OwnFranchise/OwnFranchiseHeader';

const FranchiseTeamCreationMain: React.FC = () => {
  const gameEngineStateLoader = useSelector((state: RootState) => state.gameEngine.loader);
  
  return (
    <>
      {!!gameEngineStateLoader && <LoadingScreen />}
      <Container>
        <OwnFranchiseHeader />
        <PlayerReveal />
      </Container>
    </>
  );
};

export default FranchiseTeamCreationMain;
