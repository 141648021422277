import Card from "components/Common/Card/Card"
import CardBody from "components/Common/Card/CardBody"
import Title from "components/Common/Title/Title";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { TeamGamePlan } from "types";

export const SelectedFormations: React.FC = () => {
  const teamGamePlanData: TeamGamePlan | null = useSelector((state: RootState) => state.gameEngine.teamGamePlan);
  const [offenseFormationName, setOffenseFormationName] = useState<string | null>(null);
  const [defenseFormationName, setDefenseFormationName] = useState<string | null>(null);

  useEffect(() => {
    if (teamGamePlanData && teamGamePlanData?.schemes) {
      const off = teamGamePlanData.schemes.offense.sets.find(set => set.sid === (teamGamePlanData.schemes.offense.selectedSid || 1));
      const def = teamGamePlanData.schemes.defense.sets.find(set => set.sid === (teamGamePlanData.schemes.defense.selectedSid || 1));

      if (off) {
        setOffenseFormationName(off.name);
      }
      if (def) {
        setDefenseFormationName(def.name);
      }
    }

  }, [teamGamePlanData]);

  if (!offenseFormationName || !defenseFormationName) {
    return <div/>;
  }
  
  return (
    <Card>
      <CardBody>
            <div className="franchiseoverview-selected-formations-placeholder"/>
            <div className="franchiseoverview-selected-formations-container">
              <Title text="YOUR DEFAULT FORMATIONS:"/>
              <span>
                <strong>OFFENSE:</strong> {offenseFormationName} <strong>DEFENSE:</strong> {defenseFormationName}
              </span>
            </div>
      </CardBody>
     </Card>
  )
}