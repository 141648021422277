import { Modal, Slider } from "antd";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { useState } from "react";
import "./PrestigePointsSelector.scss";

type Props = {
  open: boolean;
  onCancel: () => void;
  setValue: (value: number) => void;
};

export const PrestigePointsSelectorModal: React.FC<Props> = ({
  open,
  onCancel,
  setValue,
}) => {
  const [sliderValue, setSliderValue] = useState(50);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={"60vw"}
      centered
    >
      <div className="presite-points-selector-modal-content">
        <h4>SELECT TROPHY’S PRESTIGE POINTS</h4>
        <h6>
          All Trophies are unique, but not all are created with the same value.
          The value of a trophy is based on the Prestige Points it bestows on
          the holder. You can choose a specific amount of Prestige Points for
          your trophy. Prestige points are collected by users and can be used to
          unlock added bonuses on GM Dynasty. Collect enough Prestige Points and
          enter the Hall of Fame.
        </h6>
        <div className="slider_main">
          <Slider
            className="value-selector"
            marks={{
              10: (
                <span>
                  10<br />
                  <span>LEVEL 0</span>
                </span>
              ),
              27: <span className="no-style">LEVEL 1</span>,
              50: 50,
              75: <span className="no-style">LEVEL 2</span>,
              100: 100,
            }}
            defaultValue={50}
            value={sliderValue}
            onChange={setSliderValue}
            min={10}
            max={100}
          />
        </div>
        <div className="price-calucalation mb-4">
          $ {(sliderValue * 0.5).toFixed(2)}
        </div>
        <div className="mb-4">
          <strong>Level 1 - </strong> More than 10 Prestige Points
          <br />
          <strong>Level 2 - </strong> More than 50 Prestige Points
        </div>
        <BlueButton
          text="SUBMIT SELECTION"
          onClick={() => {
            setValue(sliderValue);
            onCancel();
          }}
        />
      </div>
    </Modal>
  );
};
