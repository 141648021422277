import { useMemo } from "react";

export default function useTeamChart(teamData: any) {
  const data = useMemo<{ value: number; date: Date }[]>(() => {
    if (teamData && teamData.ratingHistory) {
      return teamData.ratingHistory.map(
        (item: { rating: number; date: string }) => ({
          date: new Date(item.date),
          value: item.rating,
        })
      );
    }
    return [];
  }, [teamData]);

  return { chartData: data };
}
