import NewPasswordMain from "../../components/NewPassword/NewPasswordMain";
import "./NewPasswordScreenStyle.scss";

const NewPasswordScreen = ( ) =>{
    return(
        <>
            <NewPasswordMain />
        </>
    );  
}

export default NewPasswordScreen;