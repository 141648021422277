import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getTeamLeadersPlayoffs } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useTeamLeadersPlayoffs() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamLeadersPlayoffs());
  }, []);

  const teamLeadersPlayoffs: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.teamLeadersPlayoffs);

  return { teamLeadersPlayoffs };
}