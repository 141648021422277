import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { EmailsInput } from "./EmailsInput";
import { FormErrors } from "./FormErrors";
import { MessageInput } from "./MessageInput";
import { FacebookShare } from "./FacebookShare";
import { post } from "redux/services/Api";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getGameSeasonScoresByGid } from "redux/actions/GameEngineAction";
import { SeasonScoresWithHelmets } from "screens/Season/Tabs/Scores/hooks/useScoresForWeek";
import finalScorePostImage from "../../assets/images/finalScore/final-score-post.jpeg";
import { Stack } from "react-bootstrap";

type Props = {
  title?: string;
  emails: string[];
  handleEmailsChange: (data: string[]) => void;
  message: string;
  handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  errors: string[];
  isLoading?: boolean;
  gameId?: number;
};

export const InviteFriendsModalContent: React.FC<Props> = ({
  handleSubmit,
  emails,
  handleEmailsChange,
  message,
  handleMessageChange,
  errors,
  title = "Get your Friends in the game",
  isLoading,
  gameId,
}) => {
  const dispatch = useDispatch();

  const isErrors = (!!errors.length) || isLoading;
  const gameSeasonScoresByGid = useSelector(
    (state: RootState) => state.gameEngine.gameSeasonScoresByGid
  );
  const [image, setImage] = useState<string | null>(null);
  const [finalScorePost, setFinalScorePost] = useState<string>("");

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  const extractTeamInfo = (data: any): { name: string, pts: number, helmet: string, logo: string }[] => {
    const teams: any[] = [];

    data.helmets.forEach((helmet: any) => {
      const isWinner = helmet.engId === data.won.utid;
      const teamInfo = {
        name: helmet.teamName,
        pts: isWinner ? data.won.pts : data.lost.pts,
        helmet: helmet.helmet,
        logo: helmet.logo
      };

      teams.push(teamInfo);
    });

    return teams;
  };

  const generateImageFinalScorePost = async (data: any) => {
    const canvas = document.createElement('canvas');
    canvas.width = 960;
    canvas.height = 1700;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    if (!data.helmets[0] || !data.helmets[1]) {
      return;
    }
    const finalScorePostData = extractTeamInfo(data);
    try {
      const logo1Path = require(`../../assets${finalScorePostData[0].logo}`);
      const logo2Path = require(`../../assets${finalScorePostData[1].logo}`);
      const [backgroundImage, logo1, logo2] = await Promise.all([
        loadImage(finalScorePostImage),
        loadImage(logo1Path),
        loadImage(logo2Path)
      ]);
      // background
      ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

      // logos
      ctx.drawImage(logo1, 110, 970, 250, 250);
      ctx.drawImage(logo2, 620, 970, 250, 250);

      // text
      ctx.font = '30px Arial';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.fillText(finalScorePostData[0].name, 270, 1320);
      ctx.fillText(finalScorePostData[1].name, 750, 1320);

      // score
      ctx.font = '60px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.fillText(finalScorePostData[0].pts.toString(), 230, 1400);
      ctx.fillText(finalScorePostData[1].pts.toString(), 700, 1400);

      setImage(canvas.toDataURL());
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  const handleGenerateFinalScorePost = async (data: SeasonScoresWithHelmets) => {
    try {
      const res = await post<{ message: string }>('/admin/final-score-game-announcement', data);
      if (res.message) {
        setFinalScorePost(res.message || "");
      }
    } catch (error) {
      console.log('Error in handleGeneratePost', error);
      return null;
    }
  };

  const handleCopyTextToClipboard = async () => {
    if (finalScorePost) {
      navigator.clipboard.writeText(finalScorePost);
    }
  };

  const handleCopyImageToClipboard = async () => {
    if (image) {
      try {
        const response = await fetch(image);
        const blob = await response.blob();
        const clipboardItem = new ClipboardItem({ 'image/png': blob });
        await navigator.clipboard.write([clipboardItem]);
      } catch (error) {
        console.error('Failed to copy image to clipboard:', error);
      }
    }
  };

  const handleFinalScorePostChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFinalScorePost(e.target.value);
  };

  useEffect(() => {
    if (gameId) {
      dispatch(getGameSeasonScoresByGid(gameId));
    }
  }, [gameId]);

  useEffect(() => {
    if (gameSeasonScoresByGid?.length) {
      generateImageFinalScorePost(gameSeasonScoresByGid[0]);
      handleGenerateFinalScorePost(gameSeasonScoresByGid[0]);
    }
  }, [gameSeasonScoresByGid]);


  return (
    <div className="modal_content_container">
      <h1 className=" fs-5">{title}</h1>
      <form onSubmit={handleSubmit}>
        <EmailsInput emails={emails} handleEmailsChange={handleEmailsChange} />
        <MessageInput
          message={message}
          handleMessageChange={handleMessageChange}
        />
        {isErrors && <FormErrors errors={errors} />}
        <div className="btn_container gap-2">
          {!!gameId && !!finalScorePost &&
            <>
              <BlueButton
                type="button"
                onClick={handleCopyTextToClipboard}
                text="Copy Text"
              />
              <BlueButton
                type="button"
                onClick={handleCopyImageToClipboard}
                text="Copy Image"
              />
              <FacebookShare
                url={`${window?.location?.host || 'https://gmdynasty.com'}/replay/${gameId}`}
                hashtag="#GMDynasty"
              >
                <BlueButton
                  type="button"
                  className="bg-danger"
                  text="Share on Facebook"
                />
              </FacebookShare>
            </>
          }
          <BlueButton
            disabled={isErrors}
            text="Send"
            isLoading={isLoading}
          >
            Send
          </BlueButton>
        </div>
      </form>
      {finalScorePost &&
        <Stack direction="horizontal" gap={3} className="mt-3">
          <img src={image || ""} alt="Final Score Post" width={250} height={400} />
          <TextArea
            value={finalScorePost}
            onChange={handleFinalScorePostChange}
            style={{
              height: '400px',
              resize: 'none',
              backgroundColor: '#f4f7f7',
              border: 'none',
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 500,
              color: '#333',
              padding: 16,
            }}
          />
        </Stack>
      }
    </div>
  );
};
