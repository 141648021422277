const GET_NEWS_LIST_REQUEST = "GET_NEWS_LIST_REQUEST";
const GET_NEWS_LIST_SUCCESS = "GET_NEWS_LIST_SUCCESS";
const GET_NEWS_LIST_FAIL = "GET_NEWS_LIST_FAIL";
const GET_NEWS_ARTICLE_REQUEST = "GET_NEWS_ARTICLE_REQUEST";
const GET_NEWS_ARTICLE_SUCCESS = "GET_NEWS_ARTICLE_SUCCESS";
const GET_NEWS_ARTICLE_FAIL = "GET_NEWS_ARTICLE_FAIL";

export default {
  GET_NEWS_LIST_REQUEST,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_FAIL,
  GET_NEWS_ARTICLE_REQUEST,
  GET_NEWS_ARTICLE_SUCCESS,
  GET_NEWS_ARTICLE_FAIL
};
