import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { GroupFormArea } from "./GroupFormArea";
import "./CreateGroup.scss";
import { ProfileHeader } from "components/Common/Header/ProfileHeader";

export function GroupFormContainer() {
  const user = useSelector((state: RootState) => state.app?.resData);

  if (!user) return null;

  return (
    <>
      <Container style={{ paddingTop: "4rem", marginBottom: "5rem" }}>
        <ProfileHeader userId={user?.id}/>
        <GroupFormArea />
      </Container>
    </>
  );
}
