import Card from 'components/Common/Card/Card';
import CardBody from 'components/Common/Card/CardBody';
import { Col, Row } from 'react-bootstrap';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useGroupMembers } from 'components/Group/Common/hooks';
import { GroupRequestMemberItem } from './GroupRequestMemberItem';
import { useHandleGroupParticipation } from '../Common/GroupHeader/hooks/useHandleGroupParticipation';
import { GroupMember } from 'types/groups';
import { notify } from 'helper/helper';
import { User } from 'types/user';

export function GroupRequestContent() {
  const { group, requestedMembers } = useGroupMembers();
  const { handleAcceptJoinGroup, handleRejectJoinGroup } = useHandleGroupParticipation();
  const history = useHistory();
  const handleRouteToGroupPage = () => {
    history.push(`/group/${group?.id}`);
  };

  const acceptJoin = (groupId: GroupMember['groupId'], userId: User['id']) => {
    handleAcceptJoinGroup(groupId, userId, {
      onSuccess: () => {
        handleRouteToGroupPage();
        notify('success', 'Member added successfully');
      },
      onError: () => {
        notify('error', 'Something went wrong');
      },
    });
  };

  const rejectJoin = (groupId: GroupMember['groupId'], userId: User['id']) => {
    handleRejectJoinGroup(groupId, userId, {
      onSuccess: () => {
        handleRouteToGroupPage();
        notify('success', 'Member request rejected!');
      },
      onError: () => {
        notify('error', 'Something went wrong');
      },
    });
  };

  return (
    <Card>
      <CardBody>
        <div style={{ marginBottom: '1rem' }}>
          <Row>
            <Col md={3}>
              <BlueButton onClick={handleRouteToGroupPage} text={'Back to group'} icon={<ArrowLeftOutlined />} />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <h3>REQUESTS</h3>
          </Col>
        </Row>
        <Row>
          {requestedMembers && requestedMembers.length ? (
            requestedMembers.map((member) => (
              <GroupRequestMemberItem key={member.user.id} member={member} handleRejectInvite={rejectJoin} handleAcceptInvite={acceptJoin} />
            ))
          ) : (
            <Col className="g-4">
              <h5>You don't have any join group requests</h5>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
