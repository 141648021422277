import Dropdown from "components/Common/Form/Dropdown/Dropdown";
import { getFullTeamName } from "components/LeagueEnter/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import "./TeamSelector.scss";
import { useMemo } from "react";
import { isTextContainsBadWord } from "helper/helper";
import { addLeagueEnterFormData } from "redux/actions/TeamAction";

export const TeamSelector: React.FC = () => {
  const SelectedTeam = useSelector(
    (state: RootState) => state.team.leagueEnterFormData
  );
  const dispatch = useDispatch();
  const teams = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const teamList = useMemo(() => {
    const allTeams = teams
      ?.filter((team) => team.approval || !isTextContainsBadWord(team.teamName))
      ?.filter(t => t?.teamName)
      ?.map((team) => ({
        id: team.id,
        option: getFullTeamName(team.jsonFile),
        value: team.engId,
      }));

    const options = [
      {
        id: 0,
        option: "Select Team",
        value: 0,
      },
    ];
    if (allTeams) {
      options.push(...allTeams);
    }
    return options;
  }, [teams]);

  const handleSelectFranchise = (engId: number) => {
    const team = teams?.find((team) => team.engId === engId);
    if (team) {
      dispatch(
        addLeagueEnterFormData({
          fee: undefined,
          type: undefined,
          level: undefined,
          franchise: team,
        })
      );
    }
  };

  const value = `${SelectedTeam?.region} ${SelectedTeam?.name}`;
  return (
    <div className="team-selector-component">
      <Dropdown
        dropDownOptions={SelectedTeam ? teamList.slice(1) : teamList}
        onChange={(event) => handleSelectFranchise(+event.target.value)}
        value={SelectedTeam?.franchise?.engId}
        key={value}
      />
    </div>
  );
};
