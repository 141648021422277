/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tab } from "react-bootstrap";
import LeagueOverviewMain from "./TabDetail/OverviewTab/LeagueOverviewMain";
import { useDispatch } from "react-redux";
import {
  getTeamGameDepthChart,
  getTeamSpecificGamePlan,
  PregameOverview,
  putTeamGameDepthChart,
  putTeamGameSpecificPlan,
  userReadyState,
  UserRecordAPI,
} from "../../redux/actions/GameEngineAction";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useMemo } from "react";
import history from "../../routes/history";
import { fetchAllSelectedAvatar, getAvatarData } from "../../redux/actions/TeamAction";
import LeagueHeader from "../Common/LeagueHeader/LeagueHeader";
import DefensiveDepthChartMain from "../DefensiveDepthChart/DefensiveDepthChartMain";
import GamePlanMain from "../FranchiseOverview/TabDetail/GamePlan/GamePlanMain";
import { TeamDepthChartList, TeamGamePlan } from "../../types";
import LeagueOverviewRating from "./TabDetail/OverviewTab/LeagueOverviewRating";
import LeagueHeadTabMainMenu, { LeagueHeadTabMainMenuTabs, validatedTab } from "./LeagueHeadTabMainMenu";
import { useLocation } from "react-router-dom";

const LeagueHeadTabMain: React.FC = () => {
  const userNumber: any = history?.location?.state;
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");
  const tabKey = useMemo<LeagueHeadTabMainMenuTabs>(() =>
    validatedTab(tabParam) ?? LeagueHeadTabMainMenuTabs.OVERVIEW
    , [tabParam]);
  const gid = (typeof userNumber === 'object') ? userNumber?.gid : Number(userNumber);

  const UserRecord = useSelector(
    (state: RootState) => userNumber === "second" ? state?.gameEngine?.PregameOverview?.teams?.[1]?.utid : state?.gameEngine?.PregameOverview?.teams?.[0]?.utid);
  const storedGameId = useSelector((state: RootState) => state?.gameEngine?.PregameOverview?.gid)
  const gameId = (gid && !isNaN(+gid)) ? gid : storedGameId

  const pregameOverview = useSelector(
    (state: RootState) => state?.gameEngine?.PregameOverview
  );

  const teamList = useSelector(
    (state: RootState) => state?.team?.userTeamsList
  );

  const teamDepthCharts: TeamDepthChartList | null = useSelector(
    (state: RootState) => state.gameEngine.teamDepthChart
  );

  const teamGamePlan: TeamGamePlan | null = useSelector(
    (state: RootState) => state.gameEngine.teamGamePlan
  );

  useEffect(() => {
    dispatch(getAvatarData());
    dispatch(PregameOverview(gameId));
    dispatch(fetchAllSelectedAvatar([pregameOverview?.teams?.[0]?.utid, pregameOverview?.teams?.[1]?.utid]))
    dispatch(userReadyState(gameId));
    // dispatch((Number(gid)));
  }, []);

  useEffect(() => {
    if (typeof UserRecord === 'number') {
      dispatch(UserRecordAPI(UserRecord));
    };
  }, [UserRecord])

  const ownTeam = useMemo(() => {
    return teamList?.find((team: any) => [
      pregameOverview?.teams?.[0].utid,
      pregameOverview?.teams?.[1].utid,
    ].includes(team?.engId));
  }, [pregameOverview, teamList]);

  const league = useMemo(() => ({
    ...pregameOverview,
    id: pregameOverview?.gid,
    prizePool: pregameOverview?.fee + (pregameOverview?.fee * pregameOverview?.payout) / 100,
    started: pregameOverview?.created,
    gameTime: pregameOverview?.end,
    payoutPct: pregameOverview?.payout,
  }), [pregameOverview]);

  const franchise = useMemo(() => {
    return teamList?.find((info: any) => [
      pregameOverview?.teams?.[0]?.utid,
      pregameOverview?.teams?.[1]?.utid
    ].includes(info.engId));
  }, [teamList, pregameOverview]);

  const updateDepthChart = (depthCharts: TeamDepthChartList) => {
    if (ownTeam && ownTeam.engId && gameId) {
      dispatch(putTeamGameDepthChart(gameId, ownTeam.engId, depthCharts))
    }
  }

  const updateGamePlan = (gamePlanSchemes: TeamGamePlan["schemes"]) => {
    if (ownTeam && ownTeam.engId && gameId) {
      dispatch(putTeamGameSpecificPlan(gameId, ownTeam.engId, gamePlanSchemes))
    }
  }

  useEffect(() => {
    switch (tabKey) {
      case LeagueHeadTabMainMenuTabs["DEPTH-CHART"]:
        dispatch(getTeamGameDepthChart(ownTeam?.engId, Number(gid)));
        dispatch(getTeamSpecificGamePlan(ownTeam?.engId, Number(gid)));
        break;
      case LeagueHeadTabMainMenuTabs["GAME-PLAN"]:
        dispatch(getTeamSpecificGamePlan(ownTeam?.engId, Number(gid)));
        break;

      default:
        break;
    }
  }, [tabKey])

  return (
    <>
      <LeagueHeader league={league} franchise={franchise} />
      <LeagueHeadTabMainMenu defaultActiveKey={LeagueHeadTabMainMenuTabs.OVERVIEW} />
      <div className="standingTabbarMain mb-5">
        <Tab.Container
          id="left-tabs-example"
          mountOnEnter={true}
          unmountOnExit
          defaultActiveKey={LeagueHeadTabMainMenuTabs.OVERVIEW}
          activeKey={tabKey}
        >
          <LeagueOverviewRating gid={gid} />

          <Tab.Content>
            <Tab.Pane eventKey="OVERVIEW">
              <LeagueOverviewMain />
            </Tab.Pane>
            <Tab.Pane eventKey="DEPTH-CHART">
              <div className="mt-3 mb-4">
                <DefensiveDepthChartMain
                  teamDepthCharts={teamDepthCharts}
                  teamGamePlan={teamGamePlan}
                  updateDepthChart={updateDepthChart}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="GAME-PLAN">
              <div className="mt-3 mb-4">
                <GamePlanMain
                  updateGamePlan={updateGamePlan}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default LeagueHeadTabMain;
