

const Coming = () =>{
    return(
        <>
            <h5>COMING FUNCTIONALITY</h5>  
            <p>As we build towards our BETA and full launch certain functionality will be temporarily unavailable. We will post all coming updates and new builds on our Discord server.
</p>
        </>
    );
}

export default Coming;