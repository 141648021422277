import Card from "components/Common/Card/Card"
import CardBody from "components/Common/Card/CardBody"
import { Nav, Tab } from "react-bootstrap"
import { LeaderboardWeeklyStars } from "./LeaderboardWeeklyStars"
import "./Leaderboard.scss"
import { LeaderboardTeamLeaders } from "./LeaderboardTeamLeaders"
import { LeaderboardPlayersLeaders } from "./LeaderboardPlayersLeaders"
import { useCallback, useEffect, useRef, useState } from "react"
import { Modal } from "antd"
import { useDispatch } from "react-redux"
import {
  cleanTeamLeadersViewMoreData,
  getTeamLeadersConsWins, 
  getTeamLeadersPlayed, 
  getTeamLeadersPlayoffs, 
  getTeamLeadersScored, 
  getTeamLeadersWinrate, 
  getTeamLeadersWins, 
  getWeeklyLeadersRatings, 
  getWeeklyLeadersWins, 
  setTeamLeadersViewMoreType
} from "redux/actions/GameEngineAction"
import { useViewMoreData } from "./hooks"
import { ModalContainer } from "./ModalContainer"

export enum ViewMoreStringTypes {
  "WEEKLY-LEADERS-RATINGS" = "WEEKLY-LEADERS-RATINGS",
  "WEEKLY-LEADERS-WINS" = "WEEKLY-LEADERS-WINS",
  "TEAM-LEADERS-WINS" = "TEAM-LEADERS-WINS",
  "TEAM-LEADERS-PLAYED" = "TEAM-LEADERS-PLAYED",
  "TEAM-LEADERS-SCORED" = "TEAM-LEADERS-SCORED",
  "TEAM-LEADERS-CONS-WINS" = "TEAM-LEADERS-CONS-WINS",
  "TEAM-LEADERS-WINRATE" = "TEAM-LEADERS-WINRATE",
  "TEAM-LEADERS-PLAYOFF-WINS" = "TEAM-LEADERS-PLAYOFF-WINS",
}

export const ViewMoreTypes = {
  "WEEKLY-LEADERS-RATINGS": getWeeklyLeadersRatings,
  "WEEKLY-LEADERS-WINS": getWeeklyLeadersWins,
  "TEAM-LEADERS-WINS": getTeamLeadersWins,
  "TEAM-LEADERS-PLAYED": getTeamLeadersPlayed,
  "TEAM-LEADERS-SCORED": getTeamLeadersScored,
  "TEAM-LEADERS-CONS-WINS": getTeamLeadersConsWins,
  "TEAM-LEADERS-WINRATE" : getTeamLeadersWinrate,
  "TEAM-LEADERS-PLAYOFF-WINS": getTeamLeadersPlayoffs
}

const Tabs: Record<string, React.ReactNode> = {
  'WEEKLY-STARS': <LeaderboardWeeklyStars />,
  'TEAM-LEADERS': <LeaderboardTeamLeaders />,
  'PLAYERS-LEADERS': <LeaderboardPlayersLeaders />,
}

export const LeaderboardContent = () => {
  const [selectedTab, setSelectedTab] = useState<string>("WEEKLY-STARS");
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const { viewMoreData, viewMoreType, viewMoreLoader, noNextPage } = useViewMoreData(page);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current 
      && Math.ceil(containerRef.current.scrollTop + containerRef.current.clientHeight) >= containerRef.current.scrollHeight 
      && !noNextPage
      && !viewMoreLoader) {
        setPage(prevPage => (prevPage + 1));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (viewMoreType && container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container && typeof handleScroll === 'function') {
        container.removeEventListener('scroll', handleScroll);
      }
    }
  }, [viewMoreType, page, noNextPage, viewMoreLoader, containerRef.current]);

  const handleModalClose = useCallback(() => {
    dispatch(setTeamLeadersViewMoreType(null));
    dispatch(cleanTeamLeadersViewMoreData());
    setPage(1);
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [containerRef.current]);

  return (
    <div className="standingTabbarMain">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="WEEKLY-STARS"
      >
        <div className="standingTabbar">
        <Card>
          <CardBody>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  eventKey="WEEKLY-STARS"
                  onClick={() => setSelectedTab("WEEKLY-STARS")}
                >
                  WEEKLY STARS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="TEAM-LEADERS"
                  onClick={() => setSelectedTab("TEAM-LEADERS")}
                >
                  TEAM LEADERS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="PLAYERS-LEADERS"
                  onClick={() => setSelectedTab("PLAYERS-LEADERS")}
                >
                  PLAYERS LEADERS
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </CardBody>
        </Card>
        </div>
        
        <Card>
          <CardBody>
            <div className="leaderboard-container">
              {Tabs[selectedTab]}
            </div>
          </CardBody>
        </Card>
      </Tab.Container>

      {viewMoreData && <Modal
          open={!!viewMoreType}
          onCancel={handleModalClose}
          footer={null}
          title="MORE TEAM LEADERS"
          className="view-more-modal"
          width={690}
      >
          <ModalContainer
            ref={containerRef}
            noNextPage={noNextPage}
            viewMoreLoader={viewMoreLoader}
            viewMoreData={viewMoreData}
          />
      </Modal>}
    </div>
  )
}
