import Error from "../../../../../assets/images/common/error.svg";

const GeneralManagerModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>The hire is not allowed. you are only allowed 3 assistant 
                general managers. Export or release an assistant general
                manager to complete the hire.</p>
            </div> 
        </>
    );
}

export default GeneralManagerModal;