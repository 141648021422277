import { GameAnimationsActionsTypes } from "..";
import { get } from "../../../services/Api";
import { API_URL } from "../../../../config";

export function getAllAnimationNames() {
  return async (dispatch: any) => {
    dispatch({
      type: GameAnimationsActionsTypes.GET_GAME_ANIMATION_NAMES_REQUEST,
      payload: {},
    });
    
    const data = await get<{ names: string[] }>(
      `${API_URL}/admin/get-all-animation-names`
    );

    dispatch({
      type: GameAnimationsActionsTypes.GET_GAME_ANIMATION_NAMES_SUCCESS,
      payload: { animationNames: data.names },
    });
    try {
    } catch (error: any) {
      dispatch({
        type: GameAnimationsActionsTypes.GET_GAME_ANIMATION_NAMES_FAIL,
        error: error?.response?.data?.error ?? "Unexpected error",
      });
    }
  };
}
