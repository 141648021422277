import { FeeRates, HelmetData, ShortTransaction } from "types";
import { saveDecreaseCount } from "../../helper/helper";
import * as types from "../actions/types";
import { GameAnimationFile } from "redux/actions/GameAnimations/types";
import { GameAnimationsActionsTypes } from "redux/actions/GameAnimations";
import { InvitationActionTypes } from "redux/actions/Invitation";
import { TrophyAssetsActionTypes } from "redux/actions/TrophyAssets";
import { NewsActionTypes } from "redux/actions/News";
import { NewsRecord, PaginatedNewsRecord } from "types/news";
import { PaginatedTrophies, SimpleUserTrophy, TrophyAssetsObject } from "redux/actions/TrophyAssets/types";
import { User } from "types/user";

export interface InvitePromoCreditsAmounts {
  senderCredits: number;
  senderCreditsLimit: number;
  receiverCredits: number;
}
export interface appStateIF {
  loader: number;
  token: string | null;
  error: string | null;
  signError: string | null;
  forgotError: string | null;
  metaMaskError: string | null;
  success: string | boolean | null;
  resData: User | null;
  metaMaskData: any | null;
  transactionsStatus: any | null;
  transactionsData: { transactions: ShortTransaction[]; isNextPage: boolean } | null;
  urlData: any | null;
  emailOrUsernameAvailable: any | null;
  emailOrUsernameAvailableMessage: any | null;
  savePaymentDataSuccess: any | null;
  savePaymentError: any | null;
  savePaymentDataLoader: boolean;
  createPayoutSuccess: any | null;
  createPayoutError: any | null;
  createPayoutLoader: boolean;
  acquireFranchiseLoader: boolean;
  acquireFranchiseSuccess: any | null;
  acquireFranchiseError: any | null;
  sendInviteEmailError: any | null;
  feeRates: FeeRates | null;
  isPromoUsed: boolean | null;
  minWithdrawalAmount: number | null;
  gameAnimations: Array<GameAnimationFile> | null;
  invitePromoCreditsAmounts: InvitePromoCreditsAmounts | null;
  inviteCredits: number | null;
  news: PaginatedNewsRecord | null;
  newsArticle: NewsRecord | null;
  animationNames: string[] | null;
  trophyAssets: TrophyAssetsObject | null;
  userTrophies: SimpleUserTrophy[] | null;
  userTrophiesPagination: PaginatedTrophies | null;
  helmets: HelmetData[] | null;
}

const initialState: appStateIF = {
  loader: 0,
  token: null,
  error: null,
  signError: null,
  forgotError: null,
  metaMaskError: null,
  success: null,
  resData: null,
  metaMaskData: null,
  transactionsStatus: null,
  transactionsData: null,
  urlData: null,
  emailOrUsernameAvailable: null,
  emailOrUsernameAvailableMessage: null,
  savePaymentDataSuccess: null,
  savePaymentError: null,
  savePaymentDataLoader: false,
  createPayoutError: null,
  createPayoutLoader: false,
  createPayoutSuccess: null,
  acquireFranchiseLoader: false,
  acquireFranchiseSuccess: null,
  acquireFranchiseError: null,
  sendInviteEmailError: null,
  feeRates: null,
  isPromoUsed: null,
  minWithdrawalAmount: null,
  gameAnimations: null,
  invitePromoCreditsAmounts: null,
  inviteCredits: null,
  news: null,
  newsArticle: null,
  animationNames: null,
  trophyAssets: null,
  userTrophies: null,
  userTrophiesPagination: null,
  helmets: null,
};

const reducer = (state = initialState, action: any): appStateIF => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, ...action.payload };

    case types.SET_DATA_REQUEST:
      return {
        ...state,
        success: null,
        error: null,
        resData: null,
      };
    case types.SET_DATA:
      return {
        ...state,
        token: action.payload.token,
        resData: action.payload.resData,
        success: true,
      };

    case types.LOGIN_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        success: null,
        error: null,
        resData: null,
        signError: null,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        token: action.payload.token,
        resData: action.payload.resData,
        signError: null,
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        token: null,
        signError: action.error,
      };
    case types.LOGOUT:
      return { ...initialState, urlData: action.payload.success };

    case types.SIGNUP_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        success: null,
        error: null,
        signError: null,
      };
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: "signUp Success",
        signError: null,
        inviteCredits: action.payload,
      };
    case types.SIGNUP_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        signError: action.error,
      };

    case types.EMAIN_OR_EMAIL_AVAILABILITY_REQUEST:
      return {
        ...state,
        emailOrUsernameAvailable: action.payload.success,
        emailOrUsernameAvailableMessage: action.payload.message
      };
    case types.EMAIN_OR_EMAIL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        emailOrUsernameAvailable: action.payload.success,
        emailOrUsernameAvailableMessage: action.payload.message
      };
    case types.EMAIN_OR_EMAIL_AVAILABILITY_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.RESENDVARITYEMAIL_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.RESENDVARITYEMAIL_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: action.payload.success,
      };
    case types.RESENDVARITYEMAIL_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.FORGOTPASSWORDEMAIL_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.FORGOTPASSWORDEMAIL_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: action.payload.success,
      };
    case types.FORGOTPASSWORDEMAIL_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        forgotError: action.error,
        success: null,
      };

    case types.RESERTPASSWORD_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.RESERTPASSWORD_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: action.payload.success,
      };
    case types.RESERTPASSWORD_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.METAMASKLOGIN_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        success: null,
        error: null,
        resData: null,
      };
    case types.METAMASKLOGIN_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        token: action.payload.token,
        resData: action.payload.resData,
      };
    case types.METAMASKLOGIN_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        token: null,
        error: action.error,
        metaMaskData: action.payload?.metaMaskData,
      };

    case types.CONNECTMETAMASK_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        metaMaskError: null,
      };
    case types.CONNECTMETAMASK_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        metaMaskError: null,
        metaMaskData: action.payload.metaMaskData,
      };
    case types.CONNECTMETAMASK_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        metaMaskError: action.error,
        metaMaskData: null,
      };

    case types.CREATEPAYPALODER_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        success: null,
        error: null,
        transactionsStatus: null,
      };
    case types.CREATEPAYPALODER_SUCCESS:
      if (
        action.payload.transactionsStatus &&
        action.payload.updatedWalletBalance
      ) {
        return {
          ...state,
          loader: saveDecreaseCount(state.loader),
          success: true,
          transactionsStatus: action.payload.transactionsStatus,
          resData: state.resData ? {
            ...state.resData,
            walletBalance: action.payload.updatedWalletBalance,
          } : null,
        };
      }
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
      };

    case types.CREATEPAYPALODER_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error.response.data.message,
        transactionsStatus: null,
      };
      
    case types.CLEAR_TRANSACTION_STATUS:
      return {
        ...state,
        transactionsStatus: null,
      };

    case types.USER_MAIL_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.USER_MAIL_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };
    case types.USER_MAIL_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        sendInviteEmailError: action.error,
      };
    case types.CLEAN_INVITE_EMAIL:
      return { ...state, success: null, sendInviteEmailError: null };
    case types.SENDCONTACTUSEMAIL_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.SENDCONTACTUSEMAIL_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: action.payload.success,
      };
    case types.SENDCONTACTUSEMAIL_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.UPDATE_USER_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: action.payload.success,
      };
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.GET_USER_TRANSACTIONS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        transactionsData: action.payload.success,
      };
    case types.GET_USER_TRANSACTIONS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case types.SAVE_PAYMENT_DATA_REQUEST:
      return {
        ...state,
        savePaymentDataLoader: true,
        savePaymentDataSuccess: null,
        savePaymentError: null,
      };
    case types.SAVE_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        savePaymentDataLoader: false,
        savePaymentDataSuccess: action.payload.message,
        savePaymentError: null,
      };
    case types.SAVE_PAYMENT_DATA_FAIL:
      return {
        ...state,
        savePaymentDataLoader: false,
        savePaymentError: action.error,
        savePaymentDataSuccess: null,
      };

    case types.CREATE_PAYOUT_REQUEST:
      return {
        ...state,
        createPayoutLoader: true,
        createPayoutSuccess: null,
        createPayoutError: null,
      };
    case types.CREATE_PAYOUT_SUCCESS:
      return {
        ...state,
        createPayoutLoader: false,
        createPayoutSuccess: action.payload.message,
        createPayoutError: null,
      };
    case types.CREATE_PAYOUT_FAIL:
      return {
        ...state,
        createPayoutLoader: false,
        createPayoutError: action.error,
        createPayoutSuccess: null,
      };

    case types.ACQUIRE_FRANCHISE_REQUEST:
      return {
        ...state,
        acquireFranchiseLoader: true,
        acquireFranchiseSuccess: null,
        acquireFranchiseError: null,
      };
    case types.ACQUIRE_FRANCHISE_SUCCESS:
      return {
        ...state,
        acquireFranchiseLoader: false,
        acquireFranchiseSuccess: { franchiseId: action.payload.franchiseId },
        acquireFranchiseError: null,
      };
    case types.ACQUIRE_FRANCHISE_FAIL:
      return {
        ...state,
        acquireFranchiseLoader: false,
        acquireFranchiseError: action.error,
        acquireFranchiseSuccess: null,
      };

    case types.CLEAN_FORGOT_ERROR:
      return { ...state, forgotError: null };

    case types.CLEAN_SUCCESS:
      return { ...state, success: null };

    case types.GET_FEE_RATES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_FEE_RATES_SUCCESS:
      return {
        ...state,
        feeRates: action.payload.feeRates,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: null,
      };
    case types.GET_FEE_RATES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: action.error,
      };

    case types.UPDATE_WALLET_BALANCE:
      return {
        ...state,
        resData: state.resData ? {
          ...state.resData,
          walletBalance: action.payload.walletBalance,
        } : null,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: action.error,
      };

    case types.UPDATE_PROMO_BALANCE:
      return {
        ...state,
        resData: state.resData ? {
          ...state.resData,
          promoBalance: action.payload.walletBalance,
        } : null,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: action.error,
      };

    case types.CHECK_IS_PROMO_USED_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };

    case types.CHECK_IS_PROMO_USED_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        isPromoUsed: action.payload.isPromoUsed,
      };

    case types.CHECK_IS_PROMO_USED_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: null,
        error: action.error,
      };

    case types.GET_MIN_WITHDRAWAL_AMOUNT_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_MIN_WITHDRAWAL_AMOUNT_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        success: true,
        minWithdrawalAmount: action.payload.minWithdrawalAmount,
      };
    case types.GET_MIN_WITHDRAWAL_AMOUNT_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case GameAnimationsActionsTypes.GET_ACTIVE_GAME_ANIMATIONS_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        gameAnimations: null,
      };
    case GameAnimationsActionsTypes.GET_ACTIVE_GAME_ANIMATIONS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        gameAnimations: action.payload.animationsFiles,
      };
    case GameAnimationsActionsTypes.GET_ACTIVE_GAME_ANIMATIONS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case GameAnimationsActionsTypes.GET_GAME_ANIMATION_NAMES_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
        animationNames: null,
      };
    case GameAnimationsActionsTypes.GET_GAME_ANIMATION_NAMES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        animationNames: action.payload.animationNames,
      };
    case GameAnimationsActionsTypes.GET_GAME_ANIMATION_NAMES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.GET_INVITE_PROMO_AMOUNTS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_INVITE_PROMO_AMOUNTS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        invitePromoCreditsAmounts: action.payload,
      };
    case types.GET_INVITE_PROMO_AMOUNTS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case InvitationActionTypes.CONFIRM_INVITATION_CODE_REQUEST:
      return {
        ...state,
        loader: state.loader + 1,
      };
    case InvitationActionTypes.CONFIRM_INVITATION_CODE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
      };
    case InvitationActionTypes.CONFIRM_INVITATION_CODE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.SET_INVITE_CREDITS:
      return {
        ...state,
        inviteCredits: action.payload,
      };
    case types.UPDATE_USER_TUTORIAL_DISABLED_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.UPDATE_USER_TUTORIAL_DISABLED_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        resData: state.resData ? {
          ...state.resData,
          tutorialDisabled: action.payload.tutorialDisabled,
        } : null,
      };
    case types.UPDATE_USER_TUTORIAL_DISABLED_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case TrophyAssetsActionTypes.GET_TROPHY_ASSETS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case TrophyAssetsActionTypes.GET_TROPHY_ASSETS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        trophyAssets: action.payload.trophyAssets
      };
    case TrophyAssetsActionTypes.GET_TROPHY_ASSETS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case TrophyAssetsActionTypes.GET_USER_TROPHIES_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case TrophyAssetsActionTypes.GET_USER_TROPHIES_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        userTrophies: action.payload.userTrophies
      };
    case TrophyAssetsActionTypes.GET_USER_TROPHIES_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case TrophyAssetsActionTypes.GET_USER_TROPHIES_PAGINATION_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case TrophyAssetsActionTypes.GET_USER_TROPHIES_PAGINATION_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        userTrophiesPagination: action.payload.userTrophiesPagination
      };
    case TrophyAssetsActionTypes.GET_USER_TROPHIES_PAGINATION_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case NewsActionTypes.GET_NEWS_LIST_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };

    case NewsActionTypes.GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        news: action.payload.news,
      };

    case NewsActionTypes.GET_NEWS_LIST_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };

    case NewsActionTypes.GET_NEWS_ARTICLE_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };

    case NewsActionTypes.GET_NEWS_ARTICLE_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        newsArticle: action.payload.newsArticle,
      };

    case NewsActionTypes.GET_NEWS_ARTICLE_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    case types.GET_HELMETS_REQUEST:
      return { ...state, loader: state.loader + 1, success: null, error: null };
    case types.GET_HELMETS_SUCCESS:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        helmets: action.payload
      };
    case types.GET_HELMETS_FAIL:
      return {
        ...state,
        loader: saveDecreaseCount(state.loader),
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
