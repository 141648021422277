import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { Col, Row } from "react-bootstrap";
import ProfileImageBackup from "../../assets/images/common/userName.png";
import OnlineIndicator from "../../assets/images/common/wifi.svg";
import "./FriendRequest.scss";
import { useFriends } from "components/Profile/hooks/useFriends";
import { useUpdateFriendsRequest } from "./hooks/useUpdateFriendRequest";
import { FriendWithNetworkStatus } from "types/friendships";
import { API_URL } from "config";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

export function FriendRequestContent() {
  const { friendsRequests } = useFriends();
  const { handleAcceptInvite, handleRejectInvite } = useUpdateFriendsRequest();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.app.resData);

  if (!user) return null;
  
  const handleRouteToProfilePage = () => {
    history.push(`/profile/${user.id}`);
  };
  const profileImg = (friend: FriendWithNetworkStatus) =>
    friend.avatar
      ? `${API_URL}/images/avatar/${friend.avatar}`
      : ProfileImageBackup;

  return (
    <Card>
      <CardBody>
        <div style={{ marginBottom: "1rem" }}>
          <Row>
            <Col md={3}>
              <BlueButton
                onClick={handleRouteToProfilePage}
                text={"Back to profile"}
                icon={<ArrowLeftOutlined />}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <h3>FRIENDS REQUESTS</h3>
          </Col>
        </Row>
        <Row className="mb-5">
          {friendsRequests && friendsRequests.length ? (
            friendsRequests.map((friend) => (
              <Col key={friend.id} sm={4} xs={6} className="g-4">
                <div className="request_card">
                  <Row>
                    <Col
                      sm={12}
                      md={5}
                      lg={4}
                      xl={3}
                      className="d-flex justify-content-center"
                    >
                      <div className="img__container">
                        <img src={profileImg(friend)} alt="profile" />
                        {friend.networkStatus === "online" && (
                          <div className="indicator">
                            <img src={OnlineIndicator} alt="online" />
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={7} lg={8} xl={9}>
                      <Row>
                        <Col className="d-flex justify-content-start">
                          <p className="friend-label">{friend.userName}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md="auto">
                          <button
                            className="request_card__reject-btn"
                            onClick={() => handleRejectInvite(friend.id)}
                          >
                            Ignore
                          </button>
                        </Col>
                        <Col xs={12} md="auto">
                          <button
                            className="request_card__accept-btn"
                            onClick={() => handleAcceptInvite(friend.id)}
                          >
                            Accept
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))
          ) : (
            <Col className="g-4">
              <h5>You don't have any friends requests</h5>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
