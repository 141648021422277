import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EliminationActions } from "redux/actions/Elimination";
import { RootState } from "redux/store";

export const useEliminationLeaders = (gid?: number) => {
  const dispatch = useDispatch();
  const eliminationLeaders = useSelector(
    (state: RootState) => state.gameEngine.eliminationLeaders
  );

  useEffect(() => {
    if (gid) {
      dispatch(EliminationActions.getEliminationLeaders(gid));
    }
  }, [gid]);

  return eliminationLeaders;
}