import { Fragment } from 'react';
import styles from './SubTabs.module.scss';

type Props = {
  tabList: string[];
  selectedTab: string;
  setSelectedTab: (tab: any) => void;
};

export const SubTabs: React.FC<Props> = ({ tabList, selectedTab, setSelectedTab }) => (
  <div className={styles.subTabs}>
    {tabList.map((tab, index) => (
      <Fragment key={`${tab}-${index}`}>
        <span className={selectedTab === tab ? styles.selectedTab : styles.tab} onClick={() => setSelectedTab(tab)}>
          {tab}
        </span>
        {index !== tabList.length - 1 && <span className={styles.divider}>|</span>}
      </Fragment>
    ))}
  </div>
);
