import history from '../../../routes/history';
import './ResultCardStyle.scss';
import { useParams } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { DownOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { AllSeasonGames, SeasonGame } from '../types';
import { SeasonDetails } from './SeasonDetails';


type Props = {
  season: AllSeasonGames;
};

const SeasonCard: React.FC<Props> = ({ season }) => {
  const [eliminationDetails, setEliminationDetails] = useState<SeasonGame[] | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { franchiseId }: any = useParams();

  const onClick = (gid:number) => {
    history.push(`/stand`, { gid, id: +franchiseId });
  };


  const handleShowEliminationDetails = async () => {
    if (isLoading) {
      return;
    }

    const isFirstClick = eliminationDetails === null;
    if (isFirstClick) {
      setIsLoading(true);
      setEliminationDetails(season.games);
      setIsLoading(false);
    }

    setIsOpen(p => !p);
  };

  return (
    <div className="result-card-container">
      <div className="h2h-result-card">
        <Row>
          <Col md={6} lg={2} xs={12} sm={12}>
            <p 
              className={`game-detail game-name`} 
              onClick={() => history.push(`/season/${season._id}`)}
            >
              {season.name}
            </p>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-date">{new Date(season.finishAt || '').toLocaleDateString()}</span>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-record"> </span>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-winnings">FEE (${season.fee})</span>
          </Col>
          <Col md={3} lg={2} xs={6} sm={6}>
            <span className="game-detail game-winnings">WON (${season.mon})</span>
          </Col>
          {!!season.games.length && (
            <Col md={3} lg={2} xs={12} sm={12}>
              <DownOutlined
                className={isOpen ? 'result-card-game-detail-arrow result-card-game-detail-arrow-rotated' : 'result-card-game-detail-arrow'}
                style={isLoading ? { opacity: 0.5 } : {}}
                onClick={handleShowEliminationDetails}
              />
            </Col>
          )}
        </Row>

        <Row className={isOpen ? "elimination-details elimination-details-opened" : "elimination-details"}>
          {season?.games?.map((round) => (
            <SeasonDetails round={round} franchiseId={franchiseId} key={round.gid} />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default SeasonCard;
