import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { reportUserProblem} from '../../../redux/actions/GameEngineAction';
import html2canvas from "html2canvas";
import './ReportPopup.scss';
import { Input, Modal, notification } from "antd";
import { useState } from "react";

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const ReportPopup: React.FC<Props> = ({ isOpen, setIsOpen }) => {    
    const userName = useSelector(
        (state: RootState) => (state.app != null && state.app.resData != null) ? state.app.resData.userName : ""
      );

    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();

    const handleOk = () => {
        //creates user comment as separate variable to have access to it after timeout
        const userComment = errorMessage.slice();  
        
        // we can't take a screenshot for a second or so because the dialog will be in the shot
        // if we take it right away
        setTimeout(() => {
            html2canvas(document.getElementsByTagName('body')[0]).then((canvas) => {
                const imageData = canvas.toDataURL("image/png", 1.0);    
                
                // report to server
                dispatch(reportUserProblem(userName, imageData, userComment));

                notification.success({
                    message: "Successfully reported problem",
                    placement: "bottomRight",
                });
            });
        }, 1000);

        setIsOpen(false);
        notification.close('reportProblem');
    };

    return (
        <Modal
            title={(
                <p style={{ textAlign: 'center'}}>
                    <h4>
                        <strong>
                            Report problem
                        </strong>
                    </h4>
                    <h6>
                        Please briefly describe what you were trying to do and what happened.
                    </h6>
                </p>

            )}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            className="report-problem-modal"
            onOk={handleOk}
        >
            <Input.TextArea 
                rows={4}
                value={errorMessage}
                onChange={(event) => setErrorMessage(event.target.value)}
            />
        </Modal>
    );
}
   
export default ReportPopup;