import { API_URL } from "config";
import { Row, Col } from "react-bootstrap";
import { useTeamWeekData } from "./hooks";

export const LeaderboardTeamOfWeek = () => {
  const { teamWeekData } = useTeamWeekData();

  if (!teamWeekData) {
    return <div>Sorry, there are no data</div>;
  }

  return (
     <div className="leagueListMain franchiselist">
        <Row>
           <Col xs={12} lg={6}>
              <div className="leagueDetailLeft">
                 <div className="leagueImage">
                    <div className="imgWithRatingContainer">
                       <img
                          src={`${API_URL}/images/franchise/userFranchise/${teamWeekData.img}`}
                          alt="leagueImage"
                          className="imgFluid"
                       />
                    </div>
                 </div>
                 <div className="leagueDetail" style={{ maxWidth: "66%" }}>
                    <h5>
                       {teamWeekData.teamName ? (
                          <a href={`/franchisedetail/${teamWeekData.utid}`} className="name-link">
                             {teamWeekData.teamName}
                          </a>
                       ) : (
                          "FRANCHISE NAME"
                       )}
                    </h5>
                    <div>
                       <span style={{ whiteSpace: "nowrap" }}>Owner :</span>
                       <span>{teamWeekData.userName}</span>
                    </div>
                    <div>
                       <span style={{ whiteSpace: "nowrap" }}>Established :</span>
                       <span>{new Date(teamWeekData.created).getFullYear()}</span>
                    </div>
                    <div>
                       <span style={{ whiteSpace: "nowrap" }}>Win % :</span>
                       <span>{teamWeekData.allTimeWinRate}</span>
                    </div>
                 </div>
              </div>
           </Col>
           <Col xs={12} lg={5}>
              <Row>
                 <Col xl={12} lg={12}>
                    <div className="leaguedetail_head">
                       <h5 style={{ whiteSpace: "nowrap" }}>HIGHLIGHTS:</h5>
                    </div>
                 </Col>
                 <Col xl={12} lg={12}>
                    <div className="leagueDetailRight">
                       <Row>
                          <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span style={{ whiteSpace: "nowrap" }}>GAMES PLAYED :</span>
                          </Col>
                          <Col style={{ textAlign: "right" }} xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span>{teamWeekData.wins + teamWeekData.loses}</span>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span style={{ whiteSpace: "nowrap" }} className="text-blue">
                                POINTS SCORED :
                             </span>
                          </Col>
                          <Col style={{ textAlign: "right" }} xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span>{teamWeekData.ptsScored || "0"}</span>
                          </Col>
                       </Row>
                       <Row>
                          <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span style={{ whiteSpace: "nowrap" }}>TOTAL WINS : </span>
                          </Col>
                          <Col style={{ textAlign: "right" }} xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span>{teamWeekData.wins}</span>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span style={{ whiteSpace: "nowrap" }} className="text-blue">
                                POINTS ALLOWED :
                             </span>
                          </Col>
                          <Col style={{ textAlign: "right" }} xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span>{teamWeekData.ptsAllowed || "0"}</span>
                          </Col>
                       </Row>
                       <Row>
                          <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span style={{ whiteSpace: "nowrap" }}>WINNING % :</span>
                             <span>{teamWeekData.headtohead}</span>
                          </Col>
                          <Col style={{ textAlign: "right" }} xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span>{teamWeekData.winRate}</span>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span style={{ whiteSpace: "nowrap" }}>BIGGEST BLOWOUT :</span>
                          </Col>
                          <Col style={{ textAlign: "right" }} xl={3} lg={3} md={3} sm={3} xs={6}>
                             <span>{teamWeekData.biggestBlowout}</span>
                          </Col>
                       </Row>
                    </div>
                 </Col>
              </Row>
           </Col>
        </Row>
     </div>
  );
};
