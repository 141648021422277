
type Data = {
  image: string;
  logo: string;
  header: string;
  headerdigit: string;
  team: string;
  teamdigit: string;
  team1: string;
  teamdigit1: string;
  clickable?: boolean;
};
export interface Props {
  data: Data;
}

const AccordianHeader: React.FC<Props> = ({ data }) => (
  <div className="accordianheadermain" style={data?.clickable ? { cursor: 'pointer'} : {}}>
    <div className="accordianleft">
      {data?.image && (
        <div className="helmet-container">
          <img className="helmet" src={data.image} alt="helmetsmall" />
          {data?.logo && <img className="logo" src={data.logo} alt="helmetsmall" />}
        </div>
      )}
      <div>
        <p>{data.header}</p>
        <p>{data.headerdigit}</p>
      </div>
    </div>
    <div className="accordianright">
      <div>
        <p>{data.team}</p>
        <h6>{data.teamdigit}</h6>
      </div>
      <div>
        <p>{data.team1}</p>
        <h6>{data.teamdigit1}</h6>
      </div>
    </div>
  </div>
);

export default AccordianHeader;
