import LeagueHeader from "../Common/LeagueHeader/LeagueHeader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PregameOverview, getTeamOverview } from "../../redux/actions/GameEngineAction";
import { useEffect, useMemo, useState } from "react";

type Props = {
    gameId?: string;
}

const BoxScoreHeader: React.FC<Props> = ({ gameId }) =>{
    let { gid } = useParams<{gid: string}>();
    if (!gid && gameId) {
        gid = gameId;
    }
    const dispatch = useDispatch();

    const [franchise, setFranchise] = useState<any>(null);

    const userTeamList = useSelector(
        (state: RootState) => state?.team?.userTeamsList
    );

    const pregameOverview = useSelector(
        (state: RootState) => state?.gameEngine?.PregameOverview
    );

    useEffect(() => {
        if (pregameOverview) {
            const franchise =
                userTeamList?.find((info: any) => info.engId === pregameOverview?.teams?.[0]?.utid) ||
                userTeamList?.find((info: any) => info.engId === pregameOverview?.teams?.[1]?.utid);
            setFranchise(franchise);
        }
    }, [franchise, userTeamList, pregameOverview])

    useEffect(() => {
        if(franchise){
            dispatch(getTeamOverview(1, franchise?.engId))
        }
    }, [franchise]);

    useEffect(() => {
        if (gid) {
            dispatch(PregameOverview(gid));
        }
    }, [gid]);

    const league = useMemo(() => ({
        name: pregameOverview?.name,
        id: pregameOverview?.gid,
        ratingRangeMin: pregameOverview?.ratingRangeMin,
        ratingRangeMax: pregameOverview?.ratingRangeMax,
        fee: pregameOverview?.fee,
        type: pregameOverview?.type,
        prizePool: pregameOverview?.fee + (pregameOverview?.fee * pregameOverview?.payout) / 100,
        started: pregameOverview?.created,
        gameTime: pregameOverview?.end,
        payoutPct: pregameOverview?.payout,
    }), [pregameOverview]);

    return (
        <LeagueHeader league={league} franchise={franchise} />
    );
}

export default BoxScoreHeader;