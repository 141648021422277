import { Col, Row } from "antd";
import "./QuarterStats.scss";
import { GameLeaders } from "./GameLeaders";
import { useEffect, useState } from "react";
import { useGetTimestamp } from "./hooks/useGetTimestamp";
import { getInGameLeaderboard, getInGameTeamsStats } from "../helper/api";
import { GameSummaryTeam, InGameLeaderBoard, InGameTeamsStats } from "types";
import { GameTeamsStats } from "./GameTeamsStats";
import { useTeamsLogos } from "components/BoxScore/hooks/useTeamsLogos";
import { API_URL } from "config";
export interface InGameStatsProps {
  quarterCount: number;
  quarterTime: string | null;
  eventsStats: any[];
  gameId: string | null;
  scoreSummary: { teams: Array<GameSummaryTeam> } | undefined;
  replayMode?: boolean;
}
export function GamePlayQuarterStats(props: InGameStatsProps) {
  const inGameTimeStamp = useGetTimestamp(props);
  const [inGameLeaders, setInGameLeaders] = useState<InGameLeaderBoard | null>(
    null
  );
  const [inGameStats, setInGameStats] = useState<InGameTeamsStats | null>(null);

  const { helmets } = useTeamsLogos(
    props.scoreSummary ? props.scoreSummary.teams : undefined
  );

  useEffect(() => {
    (async () => {
      try {
        if (!props.gameId || (!inGameTimeStamp && (inGameLeaders && inGameStats)) || (!inGameTimeStamp && props?.replayMode)) return;
        const gameLeaders = await getInGameLeaderboard(
          props.gameId,
          inGameTimeStamp || new Date().toISOString()
        );
        const gameStats = await getInGameTeamsStats(
          props.gameId,
          inGameTimeStamp || new Date().toISOString()
        );
        setInGameLeaders(gameLeaders);
        setInGameStats(gameStats);
      } catch (e) {
        console.log(e);
      }
    })()
  }, [inGameTimeStamp]);

  return (
    <Row gutter={[16, 16]} className="quarter-stats">
      <Col xs={24} md={12}>
        <GameLeaders leaderboard={inGameLeaders} />
      </Col>
      <Col xs={24} md={12}>
        <GameTeamsStats
          stats={inGameStats}
          logos={[
            `${API_URL}${helmets.leftHelmetLogo}`,
            `${API_URL}${helmets.rightHelmetLogo}`,
          ]}
        />
      </Col>
    </Row>
  );
}
