import { Container } from "react-bootstrap";
import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import { GroupInvitationsContent } from "./GroupInvitationsContent";
import { useSelector } from "react-redux";
import { ProfileHeader } from "components/Common/Header/ProfileHeader";
import { RootState } from "redux/store";

export function GroupInvitations() {
  const user = useSelector((state: RootState) => state.app?.resData);

  if (!user) return null;

  return (
    <Container style={{ paddingTop: "1.5rem", paddingBottom: "4rem" }}>
      <ProfileHeader userId={user?.id}/>
      <Card>
        <CardBody>
          <GroupInvitationsContent />
        </CardBody>
      </Card>
    </Container>
  );
}
