export const getFormattedName = (name: string, type: "H" | "S" | "E") => {
  const normalizedName = name.toUpperCase();

  if (
    normalizedName.includes("H2H") ||
    normalizedName.includes("HEAD TO HEAD") ||
    normalizedName.includes("ELIMINATION")
  ) {
    return name;
  } else if (type === "H") {
    return `H2H | ${name}`;
  } else if (type === "E") {
    return `ELIMINATION | ${name}`;
  } else if (type === "S") {
    return `SEASON LEAGUE | ${name}`;
  }
};

export const getFullTeamName = (jsonFileName?: string) => {
  if (jsonFileName) {
     const dotIndex = jsonFileName.lastIndexOf(".");
     return jsonFileName.slice(0, dotIndex).replaceAll("_", " ");
  }
  return "";
};
