import GamePlayer from '../../components/GamePlay/GamePlayer';
import WithLoginNavbar from '../../components/layout/WithLoginNavbar/WithLoginNavbar';
import { useLocation } from 'react-router-dom';

const GamePlay: React.FC = () => {
  const search = useLocation().search;
  const gid: string | null = new URLSearchParams(search).get('id');

  return (
    <div className="pagelayout">
      <WithLoginNavbar />
      {gid && <GamePlayer gameId={+gid} />}
    </div>
  );
};

export default GamePlay;
