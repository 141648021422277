import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { getNotificationSettings } from "../helper/GetNotificationSettings";
import { setNotificationSettings as postNotificationSettings } from "../helper/SetNotificationSettings";
import { NotificationSettings } from "types";

export const useNotificationSettings = () => {
  const resData = useSelector((state: RootState) => state.app.resData);
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettings | null>(null);
  const [initialNotificationSettings, setInitialNotificationSettings] = useState<NotificationSettings | null>(null);
  const isAnyValueChanged = JSON.stringify(initialNotificationSettings) !== JSON.stringify(notificationSettings);
  const [loader, setLoader] = useState(false);

  const resetSettings = () => {
    setNotificationSettings(initialNotificationSettings);
  }

  const loadNotificationSettings = async() => {
    if (resData && resData?.id) {
      setLoader(true);
      getNotificationSettings(resData.id).then(data => {
        if (data) {
          setNotificationSettings(data);
          setInitialNotificationSettings(data);
        }
        setLoader(false);
      })
      .catch((e) => setLoader(false));
    }
  }

  const updateNotificationSettings = async() => {
    if (resData && resData?.id && notificationSettings) {
      setLoader(true);
      await postNotificationSettings(resData?.id, notificationSettings);
      await loadNotificationSettings();
    }
  }

  useEffect(() => {
    loadNotificationSettings();
  }, [resData]);

  return { 
    notificationSettings, 
    setNotificationSettings, 
    isAnyValueChanged, 
    resetSettings,
    loadNotificationSettings,
    updateNotificationSettings,
    loader
  };
}