import { get } from "redux/services/Api";

export type Announcement = {
  message: string;
  gid: number;
};

export const getAnnouncements = async (): Promise<Announcement[]> => {
  try {
    return await get(
      `/engine/games/elimination/announcement`,
    );
  } catch (e) {
    console.log(e);
    return [];
  }
};
