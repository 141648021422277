/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import defaultFranchise from "../../../assets/images/wallet/importFranchise.jpg";
import BlueButton from "../../Common/Button/BlueButton/BlueButton";
import BlackButton from "../../Common/Button/BlackButton/BlackButton";
import ConnectWalletError from "../Modal/ConnectWalletError";
import SelectFranchise from "../Modal/SelectFranchise";
import { Modal } from "antd";
import "./ImportFranchiseStyle.scss";

const ImportFranchiseContent = () => {
  //FIRST CONNECT WALLET ERROR MODAL
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //IMPORT SELECT FRANCHISE MODAL
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };
  return (
    <>
      <div className="purchasefranchisemain">
        <Row>
          <Col xl={3} lg={3}>
            <div className="purchaseimage">
              <img src={defaultFranchise} alt="cardimage" className="mb-3" />
            </div>
          </Col>
          <Col xl={9} lg={9}>
            <div className="purchasefranchisecontent importfranchisecontent">
              <h5>IMPORT A FRANCHISE</h5>
              <p className="timecontent franchisenft pink">
                FRANCHISE NFT CAN BE PURCHASED ON OPENSEA
              </p>
              <p>
                When acquiring a franchise NFT the players, coaches and GM
                assets do not transfer. New players, coaches and GMs will be
                generated. The initial rating will be based on the franchise
                rating. Upon importing you will receive:
              </p>
              <ul>
                <li>
                  Retention of franchise performance criteria and historical
                  record.
                </li>
                <li>A new roster of 52 players.</li>
                <li>A front office staff with coaches and GMs.</li>
              </ul>
              <p>
                You will need a connected wallet to complete the import a
                franchise
              </p>
              <div className="selectcity importopensea">
                <Row style={{rowGap: 10}}>
                  <Col xl={6} lg={6}>
                    <BlueButton
                      onClick={() => setVisible(true)}
                      text="IMPORT FRANCHISE"
                    />
                  </Col>
                  <Col xl={6} lg={6}>
                    <BlackButton onClick={showModal1} text="OPENSEA" />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* FIRST CONNECT WALLET ERROR START */}
      <Modal
        centered
        className="selectcitymodal connecterror"
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
        width={450}>
        <ConnectWalletError />
      </Modal>
      {/* FIRST CONNECT WALLET ERROR END */}

      {/* IMPORT SELECT FRANCHISE START */}
      <Modal
        centered
        className="selectFranchise"
        open={isModalVisible1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[<BlueButton onClick={handleCancel1} text="DONE" />]}>
        <SelectFranchise />
      </Modal>
      {/* IMPORT SELECT FRANCHISE END */}
    </>
  );
};

export default ImportFranchiseContent;
