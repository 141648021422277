import { SeasonInfo } from "screens/Season/types";
import "./SeasonSchedule.scss";
import { SeasonScheduleListItem } from "./SeasonScheduleListItem";

type Props = {
  weeks: SeasonInfo['groupStage']['weeks']
}

export const SeasonScheduleList: React.FC<Props> = ({ weeks }) => (
  <div className="season-schedule-list">
    {weeks.map((week, i) => (
      <SeasonScheduleListItem key={i} week={week} />
    ))}
  </div>
);
