/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import OwnFranchiseList from "./OwnFranchiseList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import {
  createResReset,
  getCityList,
} from "../../redux/actions/TeamAction";
import { resendVarityEmail } from "../../redux/actions/AuthAction";
import { useNewlyCreatedFranchiseModal } from "./hooks/use-newly-created-franchise-modal";
import { FranchiseSubmittedModal } from "./modal";
import NoFranchisesContent from "components/NoFranchises/NoFranchisesContent";
import { PurchaseTeam } from "components/NoFranchises/PurchaseTeam";


const OwnFranchiseContent: React.FC = () => {
  const userTeamList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );
  const userData = useSelector((state: RootState) => state.app.resData);
  const isEmailVerified = userData?.isVerified === 1;

  const dispatch = useDispatch();

  const { isVisible: isModalVisible, handleClose: handleModalClose } =
    useNewlyCreatedFranchiseModal();

  useEffect(() => {
    dispatch(getCityList());
  }, []);


  const handleOk = () => {
    dispatch(createResReset());
    handleModalClose();
  };
  const handleCancel = () => {
    dispatch(createResReset());
    handleModalClose();
  };
  const resendVerification = () => {
    if (!userData?.userName) return;
    dispatch(resendVarityEmail(userData?.userName));
    dispatch(createResReset());
    handleModalClose();
  };

  return (
    <>
      {!!userTeamList?.length 
      ? (
        <div className="ownfranchisemain">
          <Card>
            <CardBody>
              <PurchaseTeam />

              {userTeamList?.map(t => <OwnFranchiseList {...t} key={t.engId} />)}
            </CardBody>
          </Card>
        </div>
      ) : <NoFranchisesContent />}
      
      <FranchiseSubmittedModal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        isEmailVerified={isEmailVerified}
        resendEmailVerification={resendVerification}
      />
    </>
  );
};

export default OwnFranchiseContent;
