import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { useGroupHeader } from "../Common/GroupHeader/hooks/useGroupHeader";
import { CreateGroupLeagueModal } from "./modals/CreateGroupLeagueModal";
import { useState } from "react";
import { useServerSidePagination } from "helper/useServerSidePagination";
import { useGroupGames } from "./hooks/useGroupGames";
import { Pagination } from "components/Profile/components/common";
import LeagueAvailableList from "components/LeagueEnter/LeagueAvailableList";
import { TeamSelector } from "./TeamSelector/TeamSelector";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { notification } from "antd";

const LEAGUES_PER_PAGE = 3;

export const GroupLeagues: React.FC = () => {
  const groupData = useGroupHeader();

  const [isOpen, setIsOpen] = useState(false);
  const { handleNextPage, handlePrevPage, page } = useServerSidePagination();
  const data = useGroupGames(groupData.groupId, page, LEAGUES_PER_PAGE);

  const SelectedTeam = useSelector(
    (state: RootState) => state.team.leagueEnterFormData
  );

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => {
    if (!SelectedTeam?.franchise?.engId) {
      notification.error({
        message: "Please select a team to join created league",
        placement: "bottomRight",
      });
      return;
    }
    
    setIsOpen(true);
  };

  return (
    <div className="section">
      <div className="section__header">
        <h5>UPCOMING LEAGUES</h5>
        <div className="right">
          <TeamSelector />
          {(groupData.isOwner ||
            groupData.isAdmin ||
            (groupData.leagueCreation === "all" && groupData.isMember)) && (
            <PinkButton onClick={handleOpen} text="CREATE LEAGUE" />
          )}
          <Pagination
            page={page}
            hasNextPage={!!data?.hasNextPage}
            hasPreviousPage={page > 1}
            setNextPage={handleNextPage}
            setPreviousPage={handlePrevPage}
          />
        </div>
      </div>
      <div className="section__body">
        {!!data?.games?.length ? (
          data.games.map((game) => (
            <LeagueAvailableList key={game?.gid} data={game} />
          ))
        ) : (
          <div className="no-leagues">
            <p>
              There are no leagues available at the moment, but you can create
              your own one!
            </p>
          </div>
        )}
      </div>
      <CreateGroupLeagueModal
        isOpen={isOpen}
        onClose={handleClose}
        selectedGroupId={groupData.groupId}
      />
    </div>
  );
};
