import React from "react";

export interface GamesTableProps {
  games: any[];
  avatarsList: any[];
  isLoading: boolean;
}

interface SelfGamesTableProps<T extends GamesTableProps, K> {
  useFetchGames: () => T;
  renderGamesTable: (data: T & K) => React.ReactElement;
}

interface GamesTableTemplateProps<T extends GamesTableProps, K> {
  renderHeading: () => React.ReactElement;
  renderContent: (gamesData?: T & K) => React.ReactElement;
  gamesData?: T & K;
};

export const GamesTable = <T extends GamesTableProps>({ useFetchGames, renderGamesTable }: SelfGamesTableProps<T, { noGames: boolean }>) => {
  const gamesData = useFetchGames();
  const noGames = gamesData.games && Array.isArray(gamesData.games) && gamesData.games.length === 0;

  return renderGamesTable({ ...gamesData, noGames });
};

export const GamesTableTemplate = React.forwardRef<
  HTMLDivElement, GamesTableTemplateProps<GamesTableProps, { noGames: boolean }>
>(
  ({ renderContent, renderHeading, gamesData }, ref) => {
    return (
      <div className="section">
        <div className="section__header">{renderHeading()}</div>
        <div ref={ref} className="section__body games_list">
          {renderContent(gamesData)}
        </div>
      </div>
    );
  }
);
