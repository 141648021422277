type Props = {
  title?: string;
  data: {
    message: string;
    value: number | string;
  }[];
}


export const Balances: React.FC<Props> = ({
  title,
  data
}) => {
  return (
    <div className="wallet-balances-info">
        {title && <h5 className="mb-4"><strong>{title}</strong></h5>}
        {data.map(( item, index, arr) => {
          const value = typeof item.value === 'number' ? item.value.toFixed(2) : item.value;
          return (
            <div className="wallet-balances-info__balance" key={index}>
              <div className="wallet-balances-title">
                {index === arr.length - 1 ? <strong>{item.message}</strong> : item.message}
              </div>
              <div className="wallet-balances-balance">
                {index === arr.length - 1 ? <strong>{value}</strong> : value}
              </div>
            </div>
          )
        })}
      </div>
  )
}