import { useEliminationPowerRankings } from "components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/useEliminationPowerRankings";
import OverviewLeaguePowerrank from "./OverviewLeaguePowerrank";

type Props = {
  gid: number;
}

export const EliminationPowerRank: React.FC<Props> = ({ gid }) => {
  const data = useEliminationPowerRankings(gid);
 
  return (
    <OverviewLeaguePowerrank powerRanking={data}/>
  );
};
