import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getTeamLeadersConsWins } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useTeamLeadersConsWins() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamLeadersConsWins());
  }, []);

  const teamLeadersConsWins: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.teamLeadersConsWins);

  return { teamLeadersConsWins };
}