import { Dispatch, FormEvent, useState } from "react";
import { isEmail } from "react-multi-email";
import { createInvites } from "../api/CreateInvites";
import { useUserData } from "./use-user-data";
import { notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";

export function useInviteFriendsForm() {
  const [emails, setEmails] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useUserData();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);

  const handleRedirect = () => {
    if (location.pathname.includes('gameplay')) {
      const id = urlParams.get('id');
      const gid = id ? +id : 0;
      
      history.push('/stand', { gid });
    } else {
      history.push("/ownfranchise");
    }
  };
  

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
    setErrors([]);
  };
  const handleEmailsChange = (data: string[]) => {
    setErrors([]);
    setEmails(() => data);
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!userData) return;
    const isValidForm = validateForm(emails, message, setErrors);
    if (!isValidForm) return;
    setIsLoading(() => true);

    const { success, message: errorMessage } = await createInvites(
      userData?.id,
      emails,
      message
    );
    if (!success) {
      setIsLoading(() => false);
      setErrors((prevErrors) => [
        ...prevErrors,
        errorMessage || "Something went wrong",
      ]);
    }

    if (success) {
      setEmails(() => []);
      setMessage(() => "");
      setErrors(() => []);
      setIsLoading(() => false);
      notification.success({
        message: "Success",
        description: "Invites sent successfully",
        placement: "bottomRight",
      });
      handleRedirect();
    }
  };

  return {
    emails,
    handleEmailsChange,
    message,
    handleSubmit,
    handleMessageChange,
    errors,
    isLoading,
    handleRedirect,
  };
}

function validateForm(
  emails: string[],
  message: string,
  setErrors: Dispatch<React.SetStateAction<string[]>>
) {
  let valid = true;

  if (!emails.length) {
    valid = false;
    setErrors((prevErrors) => [...prevErrors, `We need at least 1 email`]);
  }

  for (const email of emails) {
    if (!isEmail(email)) {
      valid = false;
      setErrors((prevErrors) => [
        ...prevErrors,
        `${email} is not a valid email`,
      ]);
    }
  }

  if (!message.length) {
    valid = false;
    setErrors((prevErrors) => [...prevErrors, `Message can't be empty`]);
  }

  return valid;
}
