import { get } from 'redux/services/Api';
import types from '../actionTypes';
import { DivisionStandings } from 'components/LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';

export const getSeasonDivisionStats = (divisionId: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_SEASON_DIVISION_STANDINGS_REQUEST,
        payload: {},
      });

      const data = await get<DivisionStandings>(`/engine/leagues/seasons/season/${divisionId}/shortDivisionStats`);

      dispatch({
        type: types.GET_SEASON_DIVISION_STANDINGS_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_SEASON_DIVISION_STANDINGS_FAIL,
        error: error,
      });
    }
  };
};
