import { FriendWithNetworkStatus } from "types/friendships";
import ProfileImageBackup from "../../../../assets/images/common/userName.png";
import OnlineIndicator from "../../../../assets/images/common/wifi.svg";
import history from "routes/history";
import { API_URL } from "config";

export function Friend({ data }: { data: Partial<FriendWithNetworkStatus> }) {
  const isOnline = Boolean(data.networkStatus === "online");
  const handleNavigation = () => history.push(`/profile/${data.id}`);

  const profileImg = data?.avatar
    ? `${API_URL}/images/avatar/${data?.avatar}`
    : ProfileImageBackup;

  return (
    <div onClick={handleNavigation} className="friend">
      <div className="img__container">
        <img src={profileImg} alt="profile" />
        {isOnline && (
          <div className="indicator">
            <img src={OnlineIndicator} alt="online" />
          </div>
        )}
      </div>
      <p>{data.userName}</p>
    </div>
  );
}
