import { SeasonTeamStats } from "../types";

export const defaultStats: SeasonTeamStats = {
  _id: 0,
  PCT: 0,
  W: 0,
  L: 0,
  HOME: 0,
  CONF: 0,
  DIV: 0,
  PF: 0,
  PA: 0,
  ROAD: 0,
  DIFF: 0,
  STRK: 0
};
