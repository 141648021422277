/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./FranchiseHeaderDetailStyle.scss";
import { Row, Col } from "react-bootstrap";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export interface Props {
  data?: any;
  arrow?: 'up' | 'down';
  rating?: any;
  teamrating?: any;
  profile?: any;
}

const FranchiseHeaderDetail = ({ data, arrow, rating, teamrating, profile }: Props) => {
  return (
    <>
      <div className="leagueMain">
        <Row>
          <Col xl={2}>
            <div className="franchiseProfile leagueProfile">
              <div className="imageContainer">
                <img src={data?.profileimage} alt="profile" width="90px" />
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div className="leagueDetail">
              <h5>{data?.teamName}</h5>
              <Row>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>OWNER : </span>
                  <span>{data?.ownername}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>CURRENT LEAGUE : </span>
                  <span>{data?.currentLeague}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>FOUNDED : </span>
                  <span>{data?.founded}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>MINTED PLAYERS : </span>
                  <span>{data?.mintedPlayers}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>CITY : </span>
                  <span>{data?.city}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>FUNDS - IN - PLAY : </span>
                  <span>{data?.walletBalance}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>TITLES : </span>
                  <span>{data?.titles}</span>
                </Col>
                <Col xl={6} lg={6} className="franchiseDetailContent">
                  <span>WINNINGS : </span>
                  <span>{data?.winnings}</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={4}>
            <div className="franchiseBoxSection">
              {teamrating && (
                <div className="franchiseBoxBack">
                  <p>{data.teamrating}</p>
                  <h5>{data.teamratingdigit}</h5>
                </div>
              )}
              <div className="franchiseBoxBack">
                <p>WIN %</p>
                <h5>{data.teamWinningPercentage}</h5>
              </div>
              <div className="franchiseBoxBack">
                <p>FAN BASE</p>
                <h5>{data.teamFanBase}</h5>
              </div>
              <div className="leaguerating">
                <div className="leagueBoxBack">
                  <p>RATING</p>
                  <div className="rating">
                    <h5>{data.teamRating}</h5>
                    {arrow && (arrow === 'up' ? <ArrowUpOutlined style={{color: "white"}} /> : <ArrowDownOutlined style={{color: "white"}} />) }
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FranchiseHeaderDetail;
