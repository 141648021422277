import { Col, Row } from "react-bootstrap";
import { GroupInvitation, GroupMember } from "types/groups";
import { User } from "types/user";
import { GroupAvatar } from "../Common";
import "./GroupInvitations.scss"

interface Props {
  group: GroupInvitation,
  userId: number;
  handleRejectInvite: (groupId: GroupMember['groupId'], userId: User['id']) => void;
  handleAcceptInvite: (groupId: GroupMember['groupId'], userId: User['id']) => void;
}
export const GroupInvitationsItem: React.FC<Props> = ({
  group,
  userId,
  handleRejectInvite,
  handleAcceptInvite,
}) => {

  return (

    <Col key={group.id} sm={4} xs={6} className="g-4">
      <div className="invitation_card">
        <Row>
          <Col
            sm={12}
            md={5}
            lg={4}
            xl={3}
            className="d-flex justify-content-center"
          >
            <div className="img__container">
              <GroupAvatar file={group.avatar} />
            </div>
          </Col>
          <Col sm={12} md={7} lg={8} xl={9}>
            <Row>
              <Col className="d-flex justify-content-start">
                <p className="group-label">{group.name}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md="auto">
                <button
                  className="invitation_card__reject-btn"
                  onClick={() => handleRejectInvite(group.id, userId)}
                >
                  Ignore
                </button>
              </Col>
              <Col xs={12} md="auto">
                <button
                  className="invitation_card__accept-btn"
                  onClick={() => handleAcceptInvite(group.id, userId)}
                >
                  Accept
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
