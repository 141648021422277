import { Route, Redirect } from "react-router-dom";

function NoAuthRoute({ component: Component, ...rest }: any) {
  const token: string = localStorage.getItem("token") || "";
  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? <Component {...props} /> : <Redirect to="/nofranchise" />
      }
    />
  );
}

export default NoAuthRoute;
