import { RootState } from "redux/store";
import "./Leaderboard.scss";
import { useSelector } from "react-redux";
import { API_URL } from "config";
import { Col } from "react-bootstrap";
import { HelmetPlaceholder } from "components/Common/Placeholders/HelmetPlaceholder";
import { TeamLeaderItem } from "./LeaderboardTeamLeadersList";
import history from "routes/history";

type Props = {
  leader: TeamLeaderItem;
};

export const LeaderboardTeamLeaderItem: React.FC<Props> = ({ leader }) => {
  const avData = useSelector((state: RootState) => state.team.avatar);
  const helmetURL = avData?.helmet?.find((h: { id: number }) => h.id === leader?.helmet)?.url;
  const logoURL = avData?.logo?.find((h: { id: number }) => h.id === leader?.logo)?.url;

  const renderHelmet = () => {
    if (!helmetURL || !logoURL) {
      return <HelmetPlaceholder />;
    }

    return (
      <div className="helmet-container">
        <img
          className="helmet"
          src={`${API_URL}${helmetURL}`}
          alt="helmetsmall"
        />
        <img className="logo" src={`${API_URL}${logoURL}`} alt="helmetsmall" />
      </div>
    );
  };

  const handleRedirectToTeam = () => {
    history.push(`/franchisedetail/${leader.engId}`);
  };

  const handleRedirectToProfile = () => {
    history.push(`/profile/${leader.userId}`);
  };

  return (
    <div className="team-leader-item-container">
      <div className="team-leader-item-order">{leader.order}</div>
      <Col onClick={handleRedirectToTeam} className="c-pointer">
        {renderHelmet()}
      </Col>
      <Col>
        <div className="team-leader-item-names">
          <div className="name-link link" onClick={handleRedirectToTeam}>
            {leader.teamName}
          </div>
          <div className="link" onClick={handleRedirectToProfile}>{leader.userName}</div>
        </div>
      </Col>
      <Col>
        <div className="team-leader-item-value">{leader.value}</div>
      </Col>
    </div>
  );
};
