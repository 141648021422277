import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { FriendWithNetworkStatus, FriendshipsStatus } from "types/friendships";


export interface FriendsHook{
  acceptedFriends:FriendWithNetworkStatus[]|undefined;
  friendsRequests:FriendWithNetworkStatus[]|null;
  outcomingRequests:FriendWithNetworkStatus[]|undefined;
  isFriendAccepted:(id:number)=>boolean;
  isOutcomeFriendInvite:(id:number)=>boolean;
  isAddButton:(id:number)=>boolean;
}

export function useFriends() {
  const profileId = useSelector((state: RootState) => state.profile.data?.id);
  const friends = useSelector((state: RootState) => state.profile.friends);
  const friendsRequests = useSelector(
    (state: RootState) => state.profile.friendsRequests
  );

  const acceptedFriends = friends?.filter(
    (friend) => friend.status === FriendshipsStatus.ACCEPTED
  );

  const outcomingRequests = friends?.filter(
    (friend) =>
      friend.status === FriendshipsStatus.REQUESTED &&
      !friendsRequests?.find((incomFriend) => incomFriend.id !== friend.id)
  );

  const isFriendAccepted = (id: number) => {
    return !!acceptedFriends?.find((friend) => friend.id === id);
  };

  const isOutcomeFriendInvite = (id: number) => {
    return !!outcomingRequests?.find((friend) => friend.id === id);
  };

  const isAddButton = (id: number) => {
    return !friends?.some((friend: FriendWithNetworkStatus) => friend.id === id || profileId === friend.id);
  }

  return {
    acceptedFriends,
    friendsRequests,
    outcomingRequests,
    isFriendAccepted,
    isOutcomeFriendInvite,
    isAddButton
  };
}
