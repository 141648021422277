import PinkButton from 'components/Common/Button/PinkButton/PinkButton';
import { Pagination } from '..';
import { RenderTrophy } from 'components/Common/RenderTrophy/RenderTrophy';
import { PATH_TO_TROPHY_FOLDER } from 'components/PurchaseTrophy/components/TrophyPreview/TrophyPreview';
import './Trophies.scss';
import history from 'routes/history';
import { SimpleUserTrophy } from 'redux/actions/TrophyAssets/types';
import { TrophyPreview } from 'components/Common/RenderTrophy/TrophyPreview';
import { useState } from 'react';

type Props = {
  data?: {
    trophies: SimpleUserTrophy[];
    hasNextPage: boolean;
  };
  handleNextPage: () => void;
  handlePrevPage: () => void;
  page: number;
  lockerRoom?: boolean;
  isOwn?: boolean;
};

export const Trophies: React.FC<Props> = ({ data, handleNextPage, handlePrevPage, page, lockerRoom, isOwn }) => {
  const [selectedTrophy, setSelectedTrophy] = useState<SimpleUserTrophy | null>(null);

  return (
    <>
      <div className="user-trophies section">
        <div className="section__header">
          <h5>TROPHIES AND AWARDS</h5>
          <div className="right">
            {isOwn && <PinkButton text="ADD TROPHY" onClick={() => history.push('/purchase-trophy')} />}
            {isOwn && lockerRoom && <PinkButton text="LOCKEROOM" disabled />}
            <Pagination page={page} hasNextPage={!!data?.hasNextPage} hasPreviousPage={page !== 1} setNextPage={handleNextPage} setPreviousPage={handlePrevPage} />
          </div>
        </div>
        <div className="section__body">
          {data && data?.trophies?.length ? (
            data.trophies.map((trophy) => (
              <RenderTrophy key={trophy.id} path={`${PATH_TO_TROPHY_FOLDER}userTrophies/${trophy.imagePath}`} onClick={() => setSelectedTrophy(trophy)} />
            ))
          ) : (
            <h5>No trophies</h5>
          )}
        </div>
      </div>
      <TrophyPreview 
        open={!!selectedTrophy}
        onCancel={() => setSelectedTrophy(null)}
        trophy={selectedTrophy as SimpleUserTrophy}
      />
    </>
  );
};
