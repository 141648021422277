import { Badge, Popover } from "antd";
import { ReactElement } from "react";

type Props = {
  popoverContent: ReactElement | string;
  isOpen: boolean;
  children: ReactElement | string;
  forceClose?: boolean;
};

export const UserNamesAutoComplete: React.FC<Props> = ({
  popoverContent,
  isOpen,
  children,
  forceClose,
}) => {
  const open = isOpen && !forceClose;
  return (
    <Popover
      open={open}
      placement="bottom"
      content={popoverContent}
      trigger={[]}
    >
      <Badge size="small" className="w-100">
        {children}
      </Badge>
    </Popover>
  );
};
