export const disabledOptions = {
  QB: [] as string[],
  DL: ['logoImage', 'ballColor'],
  RB: ['logoImage'],
  WR: ['logoImage'],
  OL: ['logoImage', 'ballColor'],
  TE: ['logoImage'],
  CB: ['logoImage', 'ballColor'],
  S: ['ballColor'],
  K: ['ballColor'],
  P: ['ballColor'],
  HC: ['logoImage', 'ballColor'],
  AC: ['logoImage', 'ballColor'],
  GM: ['logoImage', 'ballColor'],
  AG: ['logoImage', 'ballColor'],
};
