import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import CardBody from "components/Common/Card/CardBody";
import { Card } from "react-bootstrap";
import "../GroupForm/CreateGroup.scss";
import { InviteMemberForm } from "./InviteMemberForm";
import { GroupHeader } from "../Common";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { GetGroup } from "redux/actions/Groups";

export function GroupInviteMember() {
  const dispatch = useDispatch();
  const groupInfo = useSelector((state: RootState) => state.group?.groupInfo);
  const { groupId } = useParams<{ groupId: string }>();
  useEffect(() => {
    if (!groupInfo || Number(groupId) !== groupInfo.id) {
      dispatch(GetGroup(Number(groupId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, groupInfo]);

  return (
    <>
      <Container style={{ paddingTop: "1.5rem", paddingBottom: "4rem" }}>
        <GroupHeader />
        <Card>
          <CardBody>
            <h2 className="create-group__title">INVITE TO GROUP</h2>
            <InviteMemberForm />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
