import { Col, Row } from "react-bootstrap"
import AddNewFunds from "./AddNewFunds"

export const AddNewFundsTab: React.FC = () => (
  <div className="notificationmain">
    <h5 className="settinghead">Settings</h5>
    <br />
    <Row>
      <Col xl={5}>
        <AddNewFunds />
      </Col>
    </Row>
  </div>
)