import "./PlayerDetailStyle.scss";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { Container, Nav, Tab } from "react-bootstrap";
import { useEffect } from "react";
import PlayerHeader from "../Common/PlayerHeader/PlayerHeader";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getPlayerStats } from "../../redux/actions/GameEngineAction";
import LoadingScreen from "../Common/LoadingScreen/LoadingScreen";
import { TabEnum, useTabs } from "./hooks/useTabs";
import { CareerTab } from "./tabs/CareerTab";
import { ProgressionTab } from "./tabs/ProgressionTab";
import { ProfileTab } from "./tabs/ProfileTab";

const PlayerDetailMain = () => {
  const dispatch = useDispatch();
  const loader = useSelector((state: RootState) => state.gameEngine.loader);
  const { playerId }: any = useParams();
  const { tab, handleTabChange } = useTabs(playerId);
  const playerData = useSelector(
    (state: RootState) => state.gameEngine.PlayerStats
  );
  const playerMappedRatingHistory =
    playerData?.playerStats?.ratingHistory?.map(
      (item: { rating: number; date: Date }) => ({
        date: new Date(item.date),
        value: item.rating,
      })
    ) || [];

  useEffect(() => {
    dispatch(getPlayerStats(playerId));
  }, [dispatch, playerId]);

  return (
    <>
      {!!loader && <LoadingScreen />}
      <Container className="pb-5">
        <PlayerHeader playerData={playerData?.playerStats || {}} />
        <div className="standingTabbarMain">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={TabEnum.CAREER}
            activeKey={tab}
            onSelect={(key) =>
              handleTabChange((key as TabEnum) || TabEnum.CAREER)
            }
            mountOnEnter={true}
          >
            <div className="standingTabbar">
              <Card>
                <CardBody>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey={TabEnum.CAREER}>CAREER</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={TabEnum.PROGRESSION}>
                        PROGRESSION
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={TabEnum.PROFILE}>PROFILE</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </CardBody>
              </Card>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey={TabEnum.CAREER}>
                <CareerTab
                  careerStats={playerData?.careerStats}
                  recentGames={playerData?.recentGames ?? []}
                  awards={playerData?.award ?? []}
                  statisticalAchievements={
                    playerData?.playerStats?.statisticalAchievements
                  }
                />
              </Tab.Pane>
              <Tab.Pane eventKey={TabEnum.PROGRESSION}>
                <ProgressionTab
                  playerMappedRatingHistory={playerMappedRatingHistory}
                  rankings={playerData?.playerStats?.rankings}
                  position={playerData?.playerStats?.pos}
                />
              </Tab.Pane>
              <Tab.Pane eventKey={TabEnum.PROFILE}>
                <ProfileTab
                  playerTeamId={playerData?.playerStats?.utid}
                  playerStats={playerData?.playerStats}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
};

export default PlayerDetailMain;
