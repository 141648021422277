import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getWeeklyLeadersRatings  } from "redux/actions/GameEngineAction";
import { RootState } from "redux/store";
import { TeamLeaderItem } from "../LeaderboardTeamLeadersList";

export default function useWeeklyLeadersRatings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWeeklyLeadersRatings());
  }, []);

  const weeklyLeadersRatings: TeamLeaderItem[] = useSelector((state: RootState) => state.gameEngine.weeklyLeadersRatings);

  return { weeklyLeadersRatings };
}