import { API_URL } from "config";
import { TrophyAssetsObject } from "redux/actions/TrophyAssets/types";
import { post } from "redux/services/Api";

export const getColorFromBgName = (bgName: string) => {
  const indexOfSlash = bgName.lastIndexOf("/");
  const indexOfDotPng = bgName.lastIndexOf(".png");

  return bgName.slice(indexOfSlash + 2, indexOfDotPng);
}

export const getTrophyPrice = (prestigePoints: number, mint: boolean) => {
  let price = prestigePoints * 0.5;
  if (mint) {
    price += 3;
  }

  return price;
};

export const getTrophyNameAvailability = async(trophyName: string, groupId?: number): Promise<boolean> => {
  try {
    const response = await post(`${API_URL}/user/check-if-trophy-name-available`, { trophyName, groupId });
    return response.isAvailable;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getAssetLevel = (prestigePoints: number | null | undefined) => {
  if (prestigePoints === null || prestigePoints === undefined) {
    return null;
  }

  if (prestigePoints <= 10) {
    return 0;
  } else if (prestigePoints <= 50) {
    return 1;
  } else {
    return 2;
  }
};

export const getAssetsByLevel = (level: number, assets: TrophyAssetsObject['bg']) => {
  return assets.filter(asset => (asset.max_prestige_level && asset.max_prestige_level < level));
}

export const getSelectorTitle = (asset: TrophyAssetsObject['bg'][0] | null | undefined, selectorName: string): string => {
  return asset?.name && asset.max_prestige_level
  ? `${selectorName} #${asset.name} - Level ${getAssetLevel(
    asset.max_prestige_level
    )}`
  : ""
}
