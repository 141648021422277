import { useEliminationTeams } from './hooks/useEliminationTeams';
import { LeagueTeams } from 'components/Common/LeagueTeams/LeagueTeams';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type Props = {
  gid: number;
};

const limit = 6;

export const EliminationLeagueTeams: React.FC<Props> = ({ gid }) => {
  const { eliminationTeams, handleNextPage, handlePrevPage, page, hasPrevPage } = useEliminationTeams(gid, limit);
  const eliminationData = useSelector(
    (state: RootState) => state.gameEngine.eliminationOverview
  );

  if (!eliminationData) {
    return null;
  }

  const hasNextPage = eliminationData.numberOfTeams > page * limit;

  return (
    <LeagueTeams 
      teams={eliminationTeams} 
      handleNextPage={handleNextPage} 
      handlePrevPage={handlePrevPage} 
      isPrevPage={hasPrevPage} 
      isNextPage={hasNextPage}
      numberOfTeams={eliminationData.numberOfTeams}
    />
  );
};
