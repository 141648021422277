import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPaginatedGroupTrophies } from "redux/actions/Groups";
import { RootState } from "redux/store";

export const useGroupTrophiesPaginated = (
  groupId: number,
  page: number,
  limit: number
) => {
  const dispatch = useDispatch();

  const groupTrophies = useSelector(
    (state: RootState) => state.group.groupTrophies
  );

  useEffect(() => {
    if (groupId) {
      dispatch(GetPaginatedGroupTrophies(groupId, page, limit));
    }
  }, [groupId, page, limit]);

  return groupTrophies;
};
