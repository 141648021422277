import React from 'react';
import './CheckboxStyle.scss';
type Props = {
  label: string;
  checked?: boolean;
  onClick?: () => void;
}

const Checkbox = React.forwardRef<HTMLLabelElement, Props>(({ label, checked, onClick }, ref) => (
  <label className="checkbox" ref={ref}>
    {label}
    <input type="checkbox" checked={checked} onClick={onClick}/>
    <span className="checkmark"></span>
  </label>
));

export default Checkbox;
