import Card from '../Common/Card/Card';
import CardBody from '../Common/Card/CardBody';
import { Container, Tab, Nav } from 'react-bootstrap';
import './PrivacyStyle.scss';
import PrivacyContent from './PrivacyContent';
import TermsContent from './TermsContent';

const PrivacyMain: React.FC = () => (
  <div className="privacy-main">
    <Container>
      <Card>
        <CardBody>
          <h5 className="privacy_head">PRIVACY AND TERMS</h5>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">PRIVACY</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">TERMS</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <PrivacyContent />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <TermsContent />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </CardBody>
      </Card>
    </Container>
  </div>
);

export default PrivacyMain;
