import { useDispatch } from 'react-redux';
import { message } from "antd";
import { FoundUser } from 'types/groups';
import { UserSearch2 } from 'components/Common/UserSearch/UserSearch2';
import { SendFriendInvite } from 'redux/actions/Profile';
import { DefaultInviteUserListItem } from 'components/Common/UserSearch/DefaultUsersSearch';
import { useInviteFriends } from './hooks/useInviteFriends';

export const InviteFriendForm = () => {
  const dispatch = useDispatch();
  const { searchUsers, invitedIds } = useInviteFriends();

  const handleSendFriendRequest = (profileId: number) => {
    dispatch(
      SendFriendInvite(profileId, {
        onSuccess: (m) => message.success(m),
        onError: (m) => message.error(m),
      })
    );
  };

  const renderUserRow = <T extends FoundUser>(user: T) => {
    const isInvited = invitedIds.includes(user.id)
    return (
      <DefaultInviteUserListItem
        key={user.id}
        user={user}
        onInviteClick={() => handleSendFriendRequest(user.id)}
        text={isInvited ? 'INVITED' : 'INVITE'}
        isDisabled={isInvited}
      />
    )
  };

  return (
    <UserSearch2
      useSearchUsers={searchUsers}
      onUserClick={handleSendFriendRequest}
      renderUserRow={renderUserRow}
    />
  );
};
