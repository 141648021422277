import "./FranchisePerformanceStyle.scss";
import { Container } from "react-bootstrap";
import PerformanceHeader from "./PerformanceHeader";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { Row , Col } from "react-bootstrap";
import CareerStats from "./CareerStats";
import RecentGames from "./RecentGames";
import Awards from "./Awards";
import PlayerRating from "./PlayerRating";

const FranchisePerformanceMain = () =>{
    return(
        <>
            <Container className="pb-5" >
                <PerformanceHeader />
                <Card>
                    <CardBody>
                        <Row>
                            <Col xl={8} lg={8} >
                                <CareerStats />
                            </Col>
                            <Col xl={4} lg={4} >
                                <RecentGames />
                            </Col>
                            <Col xl={12} lg={12} >
                                <PlayerRating />
                            </Col>
                            <Col xl={6} lg={6} >
                                <Awards />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

export default FranchisePerformanceMain;