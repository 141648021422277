import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import LoadingScreen from "components/Common/LoadingScreen/LoadingScreen";
import { useLoading } from "components/FranchiseOverview/hooks/use-loading";
import { SeasonMain } from "./SeasonMain";

export const SeasonScreen: React.FC = () => {
  const loader = useLoading();

  return (
    <>
      {!!loader && <LoadingScreen />}
      <div className="pagelayout">
        <WithLoginNavbar />
        <SeasonMain />
      </div>
    </>
  );
};
