/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./DraftStyle.scss";
import { Container, Row, Col } from "react-bootstrap";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import WithLoginNavbar from "../layout/WithLoginNavbar/WithLoginNavbar";
import DraftHeader from "./DraftHeader";
import DraftPlayerDetail from "./DraftPlayerDetail";
import DraftProspect from "./DraftProspect";
import DraftLog from "./DraftLog";
import DraftPicks from "./DraftPicks";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import { Modal } from "antd";
import { useState } from "react";
import DraftStartModal from "./DraftStartModal";
import DraftSelectionModal from "./DraftSelectionModal";
import DraftTaxiModal from "./DraftTaxiModal";

const DraftMain = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // MODAL 2
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  // MODAL 3
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const showModal2 = () => {
    setIsModalVisible2(true);
  };
  const handleOk2 = () => {
    setIsModalVisible2(false);
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };
  return (
     <>
        <Container>
           <WithLoginNavbar />
           <DraftHeader />
           <div className="pb-5">
              <Card>
                 <CardBody>
                    <DraftPlayerDetail />
                    <Row>
                       <Col xl={8}>
                          <DraftProspect />
                          <Row>
                             <Col style={{ marginBottom: 10 }}>
                                <BlueButton onClick={showModal} text="modal1" />
                             </Col>
                             <Col style={{ marginBottom: 10 }}>
                                <BlueButton onClick={showModal1} text="modal2" />
                             </Col>
                             <Col style={{ marginBottom: 10 }}>
                                <BlueButton onClick={showModal2} text="modal3" />
                             </Col>
                          </Row>
                       </Col>
                       <Col xl={4}>
                          <DraftLog />
                          <DraftPicks />
                       </Col>
                    </Row>
                 </CardBody>
              </Card>
           </div>
        </Container>

        {/* DRAFT START */}
        <Modal
           title="Basic Modal"
           className="draftcommon"
           open={isModalVisible}
           onOk={handleOk}
           onCancel={handleCancel}
           footer={[]}
           centered
        >
           <DraftStartModal />
        </Modal>

        {/* DRAFT SELECTION */}
        <Modal
           title="Basic Modal"
           className="draftcommon"
           open={isModalVisible1}
           onOk={handleOk1}
           onCancel={handleCancel1}
           footer={[<BlueButton onClick={handleCancel1} text="DONE" />]}
           centered
        >
           <DraftSelectionModal />
        </Modal>

        {/* DRAFT TAXI MODAL */}
        <Modal
           title="Basic Modal"
           className="draftcommon"
           open={isModalVisible2}
           onOk={handleOk2}
           onCancel={handleCancel2}
           footer={[<BlueButton onClick={handleCancel2} text="DONE" />]}
           centered
        >
           <DraftTaxiModal />
        </Modal>
     </>
  );
};

export default DraftMain;
