import loadingImg from "../../../assets/images/common/loading.gif";

type Props = {
  additionCN?: string;
};

export const Loading: React.FC<Props> = ({ additionCN }) => {
  let cn = "loadingImg";
  if (additionCN) {
    cn += ` ${additionCN}`;
  }

  return <img className={cn} src={loadingImg} alt="loadingImg" />;
};
