import { motion, useScroll, useTransform } from "framer-motion";
import { ReactNode, useRef } from "react";

type Props = {
  children: ReactNode;
};
const FadeInWhenVisible = ({ children }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "center center"],
  });

  const scale = useTransform(scrollYProgress, [0, 0.7], [0.5, 1]);

  return (
    <motion.div
      ref={scrollRef}
      style={{
        scale: scale,
      }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInWhenVisible;
