import { Modal } from "antd"

type Props = {
  open: boolean;
  onClose: () => void;
}

export const InfoModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      footer={null}
      width={"75vw"}
      onCancel={onClose}
      centered
    >
      <div className="purchasefranchisemain">
        <h4 className="mb-4">CUSTOMIZATION INFORMATION</h4>
        <p className="font18">
          In order to compete and play games you must acquire a team. Your initial team is FREE and includes the following:
          <br/><br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; A minted NFT – Polygon blockchain
          <br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; A complete roster with 53 unique players.
          <br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; A front office staff with coaches and GMs.
          <br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; Ability to enter leagues and play against other team owners.
          <br/><br/>
          The customization process includes choosing a city, name, colors and logo. A few rules, so you don’t draw a penalty flag:
          <br/><br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; Keep your name to less than 22 characters
          <br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; Refrain from any inappropriate language or trademarked words.
          <br/>
          ●&nbsp;&nbsp;&nbsp;&nbsp; Finally, we don’t allow exact duplicate names for the same city.
          <br/><br/>

          There are no rules, just endless possibilities to meet your liking in selecting color and logo.
        </p>
      </div>
    </Modal>
  )
}