import { Container } from 'react-bootstrap';
import OwnFranchiseHeader from '../OwnFranchise/OwnFranchiseHeader';
import LoadingScreen from '../Common/LoadingScreen/LoadingScreen';
import { AccountDeletionContent } from './AccountDeletionContent';
import { useDeleteAccount } from './hooks/useDeleteAccount';

export const AccountDeletionMain: React.FC = () => {
  const { isLoading, handleDeleteAccountRequest } = useDeleteAccount();

  return (
    <>
      {isLoading && <LoadingScreen />}
      <Container>
        <OwnFranchiseHeader />
        <span className="settinghead d-block text-center w-100">DELETE ACCOUNT</span>

        <AccountDeletionContent handleDeleteAccount={handleDeleteAccountRequest} loading={isLoading} />
      </Container>
    </>
  );
};
