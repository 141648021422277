import { useMemo, useRef } from "react";
import { InGameStatsProps } from "../GamePlayInGameStats";

export function useGetTimestamp({
  quarterCount,
  quarterTime,
  eventsStats,
}: InGameStatsProps) {
  const timeRef = useRef(0);
  const data = useRef<string | null>(null);

  const res = useMemo(() => {
    if (quarterTime) {
      if (quarterTime === "15" && quarterCount === 1) return null;
      const minutes = +quarterTime.split(":")[0];
      if (minutes > 0 && minutes % 3 === 0 && minutes !== timeRef.current) {
        const statEvent = eventsStats.find(
          (item) =>
            item.quarter === `Q${quarterCount}` && item.time === quarterTime
        );
        if (!statEvent) return null;
        timeRef.current = minutes;
        data.current = statEvent.rtStamp;
        return statEvent.rtStamp;
      }
      return data.current;
    }
    return null;
  }, [quarterTime]);

  return res;
}
