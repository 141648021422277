/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LeagueOverviewStyle.scss";
import { useEliminationOverview } from "components/LeagueOverviewElimination/TabDetail/ScheduleTab/hooks/useEliminationOverview";

const LeagueOverviewStanding = () => {
  const eliminationData = useEliminationOverview();

  const remainingTeams = eliminationData 
    ? eliminationData.tree.length === 0
      ? eliminationData.teams
      : eliminationData.tree[eliminationData.tree.length - 1].reduce((acc: number[], record) => {
        if (record.winner) {
          acc.push(record.winner);
        } else if (record.teams && record.teams.every(team => team !== ' ' && typeof team === 'number')){
          //@ts-ignore
          acc.push(...record.teams);
        }
        return acc;
      }, [])
    : [];

  const eliminatedTeams = (remainingTeams.length && eliminationData && eliminationData.teams) 
    ? eliminationData.teams.filter(team => !remainingTeams.includes(team))
    : [];

  return (
    <>
      {(!!remainingTeams.length && (
        <div className="resultsmain mt-3">
          <h6>REMAINING TEAMS</h6>
          <p>{remainingTeams.map(id => eliminationData?.teamNames[id]).filter(d => d).join(', ')}</p>
        </div>
      ))}
      {!!eliminatedTeams.length && (
        <div className="resultsmain mt-3">
          <h6>ELIMINATED TEAMS</h6>
          <p>
            {eliminatedTeams.map(id => eliminationData?.teamNames[id]).filter(d => d).join(', ')}
          </p>
        </div>
      )}
    </>
  );
};

export default LeagueOverviewStanding;
