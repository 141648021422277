/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form } from "react-bootstrap";
import "./DropdownStyle.scss";
import React from "react";

export interface Props {
  label?: string;
  dropDownOptions?: {
    value?: string | number | null;
    option: string | number;
    disabled?: boolean;
  }[];
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: any;
  isOutlinedRed?: boolean;
  disabled?: boolean;
}

const Dropdown: React.FC<Props> = ({
  label,
  dropDownOptions,
  id,
  onChange,
  value,
  isOutlinedRed,
  disabled,
}) => {
  let className = "form-control mb-3";
  if (isOutlinedRed) {
    className += " form-select-outlined";
  }
  return (
    <Form.Group>
      {!!label?.length && <Form.Label>{label}</Form.Label>}
      <Form.Select
        className={className}
        id={id}
        onChange={onChange}
        value={value}
        style={
          disabled ? { opacity: 0.5 } : { backgroundColor: "black !important" }
        }
        disabled={disabled}
      >
        {dropDownOptions?.length &&
          dropDownOptions.map((d: any, i: any) => {
            return (
              <option key={i} value={d.value} disabled={!!d?.disabled}>
                {d.option}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
};

export default Dropdown;
