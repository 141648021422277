import LeagueHeader from "components/Common/LeagueHeader/LeagueHeader";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { UserTeamsList } from "types";
import { useEliminationOverview } from "./TabDetail/ScheduleTab/hooks/useEliminationOverview";

const LeagueEliminationHeader: React.FC = () => {
    const teamList: UserTeamsList | null | undefined = useSelector(
        (state: RootState) => state.team.userTeamsList
      );
      const eliminationData = useEliminationOverview();
    
      const league = useMemo(
        () => ({
          ...eliminationData,
          id: eliminationData?.gid,
          started: eliminationData?.startAt,
          gameTime: new Date(),
        }),
        [eliminationData]
      );
    
      const franchise = useMemo(() => {
        return teamList?.find((info: any) =>
          eliminationData?.teams?.includes(info.engId)
        );
      }, [teamList, eliminationData]);
    
    return (
        <LeagueHeader league={league} franchise={franchise} />
    );
}

export default LeagueEliminationHeader;