import { ViewMoreStringTypes } from "./LeaderboardContent";
import { LeaderboardTeamLeadersList } from "./LeaderboardTeamLeadersList";
import { useTeamLeadersPlayoffs } from "./hooks";

export const LeaderboardTeamLeadersPlayoffs = () => {
  const { teamLeadersPlayoffs } = useTeamLeadersPlayoffs();

  if (!teamLeadersPlayoffs) {
    return <div>Sorry, there are no data</div>;
  }

  return (
    <LeaderboardTeamLeadersList
      leadersList={teamLeadersPlayoffs}
      viewMoreType={ViewMoreStringTypes["TEAM-LEADERS-PLAYOFF-WINS"]}
    />
  );
};
